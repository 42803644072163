import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

export const getRecommendedTowns = async (countryCode = 'KR') => {
  try {
    const docRef = doc(db, 'recommendedTowns', countryCode);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return Array.isArray(data.towns) ? data.towns : [];
    } else {
      console.warn(`No recommended towns found for country: ${countryCode}`);
      return [];
    }
  } catch (error) {
    console.error('🔥 Failed to fetch recommended towns:', error);
    return [];
  }
};
