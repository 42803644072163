import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  runTransaction,
  collection,
  getDoc,
  getDocs,
  updateDoc,
  increment,
  query,
  where,
  addDoc,
  doc,
  db,
} from '../../firebaseConfig';
import '../css/temp.css';

function StoreDashboard() {
  const [orders, setOrders] = useState([]);
  const [cancellations, setCancellations] = useState([]);
  const [shipments, setShipments] = useState([]);
  const { storeId } = useParams();

  if (!storeId) {
    throw new Error('storeId is undefined!');
  }
  const ordersCollection = collection(db, 'stores', storeId, 'orders');

  useEffect(() => {
    const fetchData = async () => {
      const orderQuery = query(
        ordersCollection,
        where('orderStatus', '==', 'pending')
      );
      const orderSnapshot = await getDocs(orderQuery);
      setOrders(
        orderSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );

      const cancellationQuery = query(
        ordersCollection,
        where('orderStatus', '==', 'cancel')
      );
      const cancellationsSnapshot = await getDocs(cancellationQuery);
      setCancellations(
        cancellationsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );

      const shipmentsQuery = query(
        ordersCollection,
        where('orderStatus', '==', 'shipping')
      );
      const shipmentsSnapshot = await getDocs(shipmentsQuery);
      setShipments(
        shipmentsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };

    fetchData();
  }, []);

  //유저별 주문 통계:
  async function getUserOrdersStats(storeId, userId) {
    const ordersRef = collection(db, 'stores', storeId, 'orders');
    const userOrdersQuery = query(ordersRef, where('userId', '==', userId));
    const userOrdersSnapshot = await getDocs(userOrdersQuery);

    const orders = userOrdersSnapshot.docs.map((doc) => doc.data());
    // 여기서 통계나 다른 처리를 수행할 수 있습니다.
    return orders;
  }

  //제품별 주문 통계:
  async function getProductOrdersStats(storeId, productId) {
    const ordersRef = collection(db, 'stores', storeId, 'orders');
    const productOrdersQuery = query(
      ordersRef,
      where('productId', '==', productId)
    );
    const productOrdersSnapshot = await getDocs(productOrdersQuery);

    const orders = productOrdersSnapshot.docs.map((doc) => doc.data());
    // 여기서 통계나 다른 처리를 수행할 수 있습니다.
    return orders;
  }

  //스토어별 주문 통계:
  async function getStoreOrdersStats(storeId) {
    const ordersRef = collection(db, 'stores', storeId, 'orders');
    const storeOrdersQuery = query(ordersRef, where('storeId', '==', storeId));
    const storeOrdersSnapshot = await getDocs(storeOrdersQuery);

    const orders = storeOrdersSnapshot.docs.map((doc) => doc.data());
    // 여기서 통계나 다른 처리를 수행할 수 있습니다.
    return orders;
  }
  return (
    <div className="container">
      <h2>주문 정보</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>제품 이름</th>
            <th>원래 가격</th>
            <th>할인 금액</th>
            <th>사용된 포인트</th>
            <th>최종 가격</th>
            <th>주문 날짜</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.orderId}>
              <td>{order.orderId}</td>
              <td>{order.productDetails.name}</td>
              <td>{order.originalPrice}</td>
              <td>{order.discountAmount}</td>
              <td>{order.pointsUsed}</td>
              <td>{order.finalPrice}</td>
              <td>{order.dateTime}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>취소 정보</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>취소 사유</th>
            <th>취소 날짜</th>
          </tr>
        </thead>
        <tbody>
          {cancellations.map((cancellation) => (
            <tr key={cancellation.id}>
              <td>{cancellation.id}</td>
              <td>{cancellation.reason}</td>
              <td>{cancellation.cancelDate}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>배송 정보</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>배송 상태</th>
            <th>예상 도착일</th>
          </tr>
        </thead>
        <tbody>
          {shipments.map((shipment) => (
            <tr key={shipment.id}>
              <td>{shipment.id}</td>
              <td>{shipment.status}</td>
              <td>{shipment.estimatedArrival}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default StoreDashboard;
