import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Box,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Favorite as FavoriteIcon,
  Visibility as VisibilityIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { useGlobalContext } from './useGlobalContext';
import { formatState } from './ProductStatus';
import { formatPrice } from './Utils/PriceFormatter';
import productDefaultImage from './assets/images/productDefaultImage.png';
import WeatherEventDisplay from './Weather/WeatherEventDisplay';
import WeatherPriceDisplay from './Weather/WeatherPriceDisplay';

const ProductCard = ({
  product,
  weatherEvent,
  isEventActive,
  horizontal = false,
  onClick,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const {
    pcAspectRatio,
    mobileAspectRatio,
    padAspectRatio,
    isMobile,
    isPad,
    isPc,
  } = useGlobalContext();
  const imageUrl =
    product?.icon_images && product?.icon_images.length > 0
      ? product.icon_images[0]
      : productDefaultImage;
  const dateObj = product?.createDate?.toDate();
  const formattedDate = moment(dateObj).fromNow();
  const productState = formatState(product?.state, t);
  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  /** 🔹 모바일 버전 렌더링 */
  const renderMobileView = () => (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        height: { xs: 'auto', sm: '20vh', md: '22vh' }, // 화면 크기에 따라 동적 조절
        minHeight: '120px', // 최소 높이 보장
        maxHeight: '220px', // 너무 크지 않도록 제한
      }}
    >
      {/* 이미지 영역 */}
      <Box
        sx={{
          width: '40%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CardMedia
          component="img"
          src={imageUrl}
          alt={product.productName}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            aspectRatio: mobileAspectRatio,
            borderRadius: theme.shape.borderRadius,
          }}
        />
      </Box>
      {/* 텍스트 정보 */}
      <CardContent
        sx={{
          width: '60%',
          padding: theme.spacing(1),
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          color: theme.palette.text.primary,
        }}
      >
        <Typography
          variant="h6"
          color="text.secondary"
          noWrap
          sx={{ textAlign: 'left' }}
        >
          {product.title}
        </Typography>
        {/* <Typography
          variant="body2"
          color="text.secondary"
          noWrap
          sx={{ textAlign: 'center' }}
        >
          {product.content}
        </Typography> */}
        {/* <Typography variant="body2" noWrap sx={{ textAlign: 'left' }}>
          {product.stock} {t('left')}
        </Typography> */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body2">{formattedDate}</Typography>
          <Chip
            icon={productState.icon}
            label={productState.text}
            color={productState.color}
            size="small"
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <WeatherEventDisplay
            weatherEvent={weatherEvent}
            isEventActive={isEventActive}
          />
        </Box>

        {renderProductStats()}
      </CardContent>
    </Box>
  );

  /** 🔹 PC 버전 렌더링 */
  const renderProductCard = () => (
    <>
      <CardMedia
        component={RouterLink}
        // to={`/product_detail/${product.uid}/${product.id}`}
        // to={`/detail/${product.id}`}
        sx={{
          //height: 0,
          //paddingTop: '56.25%',
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          objectFit: 'cover',
          m: theme.spacing(0), //사진과 박스의 간격
          // aspectRatio: isPad
          //   ? padAspectRatio
          //   : isMobile
          //   ? mobileAspectRatio
          //   : pcAspectRatio,
          aspectRatio: padAspectRatio,

          borderRadius: theme.shape.borderRadius,
        }}
        alt={product.title}
      />
      <CardContent
        sx={{
          padding: theme.spacing(1),
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            variant="h6"
            color="text.secondary"
            noWrap
            sx={{ textAlign: 'center' }}
          >
            {product.title}
          </Typography>
          {/* <Typography
            variant="body2"
            color="text.secondary"
            noWrap
            sx={{ textAlign: 'center' }}
          >
            {product.content}
          </Typography> */}
        </Box>
        {/* <Typography variant="body2" noWrap sx={{ textAlign: 'left' }}>
          {product.stock} {t('left')}
        </Typography> */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 0,
            mb: 0,
          }}
        >
          <Typography variant="body2">{formattedDate}</Typography>
          <Chip
            icon={productState.icon}
            label={productState.text}
            color={productState.color}
            size="small"
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <WeatherEventDisplay
            weatherEvent={weatherEvent}
            isEventActive={isEventActive}
          />
        </Box>
        {renderProductStats()}
      </CardContent>
    </>
  );
  const renderProductStats = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: 0.1,
      }}
    >
      <WeatherPriceDisplay
        product={product}
        weatherEvent={weatherEvent}
        isEventActive={isEventActive}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={t('likes')}>
          <IconButton aria-label="add to favorites" size="small">
            <FavoriteIcon />
            {/* <FavoriteIcon color="error" /> */}
            <Typography variant="caption" sx={{ ml: 0.5 }}>
              {product.likes || 0}
            </Typography>
          </IconButton>
        </Tooltip>

        <Tooltip title={t('views')}>
          <IconButton aria-label="view count" size="small">
            <VisibilityIcon color="action" />
            <Typography variant="caption" sx={{ ml: 0.5 }}>
              {product.views || 0}
            </Typography>
          </IconButton>
        </Tooltip>

        <Tooltip title={t('chat')}>
          <IconButton aria-label="chat count" size="small">
            <ChatIcon color="primary" />
            <Typography variant="caption" sx={{ ml: 0.5 }}>
              {product.chats || 0}
            </Typography>
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
  // ✅ 이벤트 배지 표시
  const renderEventBadge = () =>
    isEventActive && weatherEvent ? (
      <Box
        sx={{
          position: 'absolute',
          top: theme.spacing(1),
          left: theme.spacing(1),
          backgroundColor: '#FF5722',
          color: theme.palette.common.white,
          borderRadius: theme.shape.borderRadius,
          px: 0.5,
          py: 0.2,
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <img
          src={weatherEvent.iconUrl}
          alt={weatherEvent.name}
          style={{ width: 20, height: 20, borderRadius: '50%' }}
        />
        <Typography variant="body2">{weatherEvent.discount}% OFF</Typography>
      </Box>
    ) : null;

  return (
    <Card
      sx={{
        maxWidth: '100%',
        boxShadow: theme.shadows[3],
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        overflow: 'hidden',
        m: theme.spacing(1),
        p: theme.spacing(0),
      }}
      onClick={onClick}
    >
      <CardActionArea
        component={RouterLink}
        // to={`/detail/${product.id}`}
      >
        {renderEventBadge()}
        {renderProductCard()}
        {/* {isMobile ? renderMobileView() : renderPCView()} */}
      </CardActionArea>
    </Card>
  );
};

export default ProductCard;
