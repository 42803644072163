// import React, { useState, useRef, useEffect } from 'react';
// import {
//   firebaseApp,
//   db,
//   auth,
//   analytics,
//   storage,
//   functions,
//   collection,
//   query,
//   where,
//   orderBy,
//   doc,
//   setDoc,
//   updateProfile,
//   getAuth,
//   GoogleAuthProvider,
//   signInWithPopup,
//   onAuthStateChanged,
//   signInWithEmailAndPassword,
//   signOut,
//   serverTimestamp,
// } from './firebaseConfig';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   TextField,
//   Button,
//   IconButton,
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import CloseIcon from '@mui/icons-material/Close';
// import KakaoLogin from 'react-kakao-login';
// import kakao_login from './assets/images/kakao_login.png';
// import naver_login from './assets/images/naver_login.png';
// import google_login_icon from './assets/images/google_login_icon.png';
// import kakao_login_icon from './assets/images/kakao/kakaotalk_sharing_btn/kakaotalk_sharing_btn_medium.png';
// import naver_login_icon from './assets/images/naver_login_icon.png';
// import './css/login.css';

// function Login() {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   const [open, setOpen] = useState(true);
//   const [error, setError] = useState('');
//   const googleProvider = new GoogleAuthProvider();
//   const navigate = useNavigate();

//   const handleLogin = async () => {
//     try {
//       await signInWithEmailAndPassword(auth, email, password);
//       updateLastLogin(auth.currentUser.uid);
//       navigate('/');
//     } catch (error) {
//       console.error('Error logging in', error);
//       setError('로그인실패:' + error);
//     }
//   };
//   const handleLogout = async () => {
//     try {
//       await signOut(auth);
//       navigate('/');
//     } catch (error) {
//       console.error('Error logging out', error);
//       setError('로그아웃 실패:' + error);
//     }
//   };

//   const handleGoogleLogin = async () => {
//     try {
//       const result = await signInWithPopup(auth, googleProvider);
//       if (result.user) {
//         const email = result.user.email;
//         const name = result.user.displayName;

//         console.log('이메일:', email);
//         console.log('이름:', name);
//         setError('구글계정 :' + email + ' 로 로그인 되었습니다.');
//         //// collection(db, 'users').doc(result.user.uid).set({ email, name });
//         const userDoc = doc(db, 'users', result.user.uid);
//         await setDoc(userDoc, { email, name });
//         updateLastLogin(result.user.uid);
//         // 사용자 프로필 업데이트
//         await updateProfile(result.user, { displayName: name }).then(() => {
//           console.log('이름 저장 성공');
//           navigate('/');
//         });
//       }
//     } catch (error) {
//       console.error('구글 로그인 오류:', error.message);
//       setError('구글 로그인 오류:' + error.message);
//     }
//   };

//   const handleKakaoLogin = () => {
//     const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
//     window.location.href = kakaoURL;
//   };

//   const handleNaverLogin = () => {
//     const state =
//       Math.random().toString(36).substring(2, 15) +
//       Math.random().toString(36).substring(2, 15);
//     sessionStorage.setItem('naverOAuthState', state);
//     const naverURL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=${state}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}`;
//     window.location.href = naverURL;
//   };

//   const SocialKakao = () => {
//     const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
//     const handleKakaoLogin = () => {
//       window.location.href = kakaoURL;
//     };
//     return (
//       <>
//         <button
//           onClick={handleKakaoLogin}
//           variant="contained"
//           color="secondary"
//           fullWidth
//           style={{ boxShadow: '0px 2px 5px rgba(0,0,0,0.1)' }}
//         >
//           카카오 로그인
//         </button>
//       </>
//     );
//   };

//   function updateLastLogin(userId) {
//     const userDocRef = doc(db, 'users', userId);

//     setDoc(userDocRef, { lastLogin: serverTimestamp() }, { merge: true })
//       .then(() => {
//         console.log('Last login time updated');
//       })
//       .catch((error) => {
//         console.error('Error updating last login time:', error);
//       });
//   }
//   // 다이얼로그를 닫을 때 상태를 변경하지 않도록 수정
//   const handleClose = () => {
//     navigate('/');
//     setOpen(false);
//   };
//   const handleFocus = () => {};
//   return (
//     <Dialog
//       open={open}
//       onClose={handleFocus}
//       PaperProps={{
//         style: {
//           padding: '20px',
//           backgroundColor: '#f6f6f6',
//           width: '60%', // 너비를 80%로 설정
//           height: '600px', // 높이를 600px로 설정
//           maxWidth: '100%', // 최대 너비 제한 없음
//         },
//       }}
//     >
//       <DialogTitle>
//         로그인
//         <IconButton
//           style={{ position: 'absolute', right: '8px', top: '8px' }}
//           onClick={handleClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       </DialogTitle>
//       <DialogContent>
//         <TextField
//           variant="outlined"
//           margin="normal"
//           fullWidth
//           label="Email"
//           type="email"
//           value={email || ''}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <TextField
//           variant="outlined"
//           margin="normal"
//           fullWidth
//           label="Password"
//           type={showPassword ? 'text' : 'password'}
//           value={password || ''}
//           onChange={(e) => setPassword(e.target.value)}
//           endAdornment={
//             <IconButton
//               edge="end"
//               onClick={() => setShowPassword(!showPassword)}
//             >
//               {showPassword ? <Visibility /> : <VisibilityOff />}
//             </IconButton>
//           }
//         />
//         {error && <div style={{ color: 'red' }}>{error}</div>}
//         <br />
//         <br />
//         <div className="bottom-buttons">
//           <Button
//             onClick={handleLogin}
//             variant="contained"
//             color="primary"
//             fullWidth
//             style={{
//               marginTop: '10px',
//               boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//             }}
//           >
//             로그인
//           </Button>

//           {/* <Button onClick={handleGoogleLogin} variant="contained" color="secondary" fullWidth style={{ marginTop: '10px', boxShadow: '0px 2px 5px rgba(0,0,0,0.1)' }}>
//             구글 로그인
//           </Button>
//           <Button onClick={handleKakaoLogin} variant="contained" color="secondary" fullWidth style={{
//             backgroundImage: `url(${kakao_login})`,
//             backgroundSize: 'cover',       // scales the image to cover the button
//             backgroundRepeat: 'no-repeat', // ensures the image doesn't repeat
//             backgroundPosition: 'center',  // centers the image within the button
//             marginTop: '10px', boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
//           }}>
//             카카오 로그인
//           </Button>

//           <Button onClick={handleNaverLogin} variant="contained" color="secondary" fullWidth style={{
//             backgroundImage: `url(${naver_login})`,
//             backgroundSize: 'cover',       // scales the image to cover the button
//             backgroundRepeat: 'no-repeat', // ensures the image doesn't repeat
//             backgroundPosition: 'center',  // centers the image within the button
//             marginTop: '10px', boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
//           }}>
//             네이버 로그인
//           </Button> */}

//           <span>
//             <Button
//               onClick={handleGoogleLogin}
//               variant="contained"
//               color="secondary"
//               style={{
//                 width: '70px',
//                 height: '60px',
//                 backgroundImage: `url(${google_login_icon})`,
//                 backgroundSize: 'cover', // scales the image to cover the button
//                 backgroundRepeat: 'no-repeat', // ensures the image doesn't repeat
//                 backgroundPosition: 'center', // centers the image within the button
//                 margin: '5px',
//                 boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//               }}
//             ></Button>
//             <Button
//               onClick={handleKakaoLogin}
//               variant="contained"
//               color="secondary"
//               style={{
//                 width: '70px',
//                 height: '60px',
//                 backgroundImage: `url(${kakao_login_icon})`,
//                 backgroundSize: 'cover', // scales the image to cover the button
//                 backgroundRepeat: 'no-repeat', // ensures the image doesn't repeat
//                 backgroundPosition: 'center', // centers the image within the button
//                 margin: '5px',
//                 boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//               }}
//             ></Button>
//             <Button
//               onClick={handleNaverLogin}
//               variant="contained"
//               color="secondary"
//               style={{
//                 width: '70px',
//                 height: '60px',
//                 backgroundImage: `url(${naver_login_icon})`,
//                 backgroundSize: 'cover', // scales the image to cover the button
//                 backgroundRepeat: 'no-repeat', // ensures the image doesn't repeat
//                 backgroundPosition: 'center', // centers the image within the button
//                 margin: '5px',
//                 boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//               }}
//             ></Button>
//           </span>
//         </div>
//       </DialogContent>
//     </Dialog>
//   );
// }

// export default Login;

// import React, { useState, useEffect } from 'react';
// import {
//   firebaseApp,
//   db,
//   auth,
//   analytics,
//   storage,
//   functions,
//   doc,
//   setDoc,
//   updateProfile,
//   getAuth,
//   GoogleAuthProvider,
//   signInWithPopup,
//   signInWithEmailAndPassword,
//   signOut,
//   serverTimestamp,
//   onAuthStateChanged,
// } from './firebaseConfig';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   TextField,
//   Button,
//   IconButton,
//   InputAdornment,
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import CloseIcon from '@mui/icons-material/Close';
// import google_login_icon from './assets/images/google_login_icon.png';
// import kakao_login_icon from './assets/images/kakao/kakaotalk_sharing_btn/kakaotalk_sharing_btn_medium.png';
// import naver_login_icon from './assets/images/naver_login_icon.png';
// import './css/login.css';

// function Login() {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   const [open, setOpen] = useState(true);
//   const [error, setError] = useState('');
//   const googleProvider = new GoogleAuthProvider();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         navigate('/');
//       }
//     });

//     return () => unsubscribe();
//   }, [navigate]);

//   const handleLogin = async () => {
//     try {
//       await signInWithEmailAndPassword(auth, email, password);
//       updateLastLogin(auth.currentUser.uid);
//       navigate('/');
//     } catch (error) {
//       console.error('Error logging in', error);
//       setError('로그인 실패: ' + error.message);
//     }
//   };

//   const handleGoogleLogin = async () => {
//     try {
//       const result = await signInWithPopup(auth, googleProvider);
//       if (result.user) {
//         const email = result.user.email;
//         const name = result.user.displayName;

//         const userDoc = doc(db, 'users', result.user.uid);
//         await setDoc(userDoc, { email, name });
//         updateLastLogin(result.user.uid);
//         await updateProfile(result.user, { displayName: name });
//         navigate('/');
//       }
//     } catch (error) {
//       console.error('구글 로그인 오류:', error.message);
//       setError('구글 로그인 오류: ' + error.message);
//     }
//   };

//   const handleKakaoLogin = () => {
//     const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
//     window.location.href = kakaoURL;
//   };

//   const handleNaverLogin = () => {
//     const state = Math.random().toString(36).substring(2, 15);
//     sessionStorage.setItem('naverOAuthState', state);
//     const naverURL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=${state}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}`;
//     window.location.href = naverURL;
//   };

//   function updateLastLogin(userId) {
//     const userDocRef = doc(db, 'users', userId);

//     setDoc(userDocRef, { lastLogin: serverTimestamp() }, { merge: true })
//       .then(() => {
//         console.log('Last login time updated');
//       })
//       .catch((error) => {
//         console.error('Error updating last login time:', error);
//       });
//   }

//   const handleClose = () => {
//     navigate('/');
//     setOpen(false);
//   };

//   return (
//     <Dialog
//       open={open}
//       onClose={handleClose}
//       PaperProps={{
//         style: {
//           padding: '20px',
//           backgroundColor: '#f6f6f6',
//           width: '80%',
//           maxWidth: '600px',
//           height: 'auto',
//         },
//       }}
//     >
//       <DialogTitle>
//         로그인
//         <IconButton
//           style={{ position: 'absolute', right: '8px', top: '8px' }}
//           onClick={handleClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       </DialogTitle>
//       <DialogContent>
//         <TextField
//           variant="outlined"
//           margin="normal"
//           fullWidth
//           label="Email"
//           type="email"
//           value={email || ''}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <TextField
//           variant="outlined"
//           margin="normal"
//           fullWidth
//           label="Password"
//           type={showPassword ? 'text' : 'password'}
//           value={password || ''}
//           onChange={(e) => setPassword(e.target.value)}
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton
//                   edge="end"
//                   onClick={() => setShowPassword(!showPassword)}
//                 >
//                   {showPassword ? <Visibility /> : <VisibilityOff />}
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//         />
//         {error && (
//           <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>
//         )}
//         <Button
//           onClick={handleLogin}
//           variant="contained"
//           color="primary"
//           fullWidth
//           style={{
//             marginTop: '20px',
//             boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//           }}
//         >
//           로그인
//         </Button>
//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'space-around',
//             marginTop: '20px',
//           }}
//         >
//           <Button
//             onClick={handleGoogleLogin}
//             variant="contained"
//             color="secondary"
//             style={{
//               width: '70px',
//               height: '60px',
//               backgroundImage: `url(${google_login_icon})`,
//               backgroundSize: 'cover',
//               backgroundRepeat: 'no-repeat',
//               backgroundPosition: 'center',
//               margin: '5px',
//               boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//             }}
//           ></Button>
//           <Button
//             onClick={handleKakaoLogin}
//             variant="contained"
//             color="secondary"
//             style={{
//               width: '70px',
//               height: '60px',
//               backgroundImage: `url(${kakao_login_icon})`,
//               backgroundSize: 'cover',
//               backgroundRepeat: 'no-repeat',
//               backgroundPosition: 'center',
//               margin: '5px',
//               boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//             }}
//           ></Button>
//           <Button
//             onClick={handleNaverLogin}
//             variant="contained"
//             color="secondary"
//             style={{
//               width: '70px',
//               height: '60px',
//               backgroundImage: `url(${naver_login_icon})`,
//               backgroundSize: 'cover',
//               backgroundRepeat: 'no-repeat',
//               backgroundPosition: 'center',
//               margin: '5px',
//               boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//             }}
//           ></Button>
//         </div>
//       </DialogContent>
//     </Dialog>
//   );
// }

// export default Login;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  auth,
  db,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  googleAuthProvider,
  onAuthStateChanged,
  setDoc,
  getDoc,
  updateDoc,
  doc,
  serverTimestamp,
  updateProfile,
  OAuthProvider,
} from './firebaseConfig';
import { reauthenticateWithPopup } from 'firebase/auth';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import google_login_icon from './assets/images/google_login_icon.png';
import kakao_login_icon from './assets/images/kakao/kakaotalk_sharing_btn/kakaotalk_sharing_btn_medium.png';
import naver_login_icon from './assets/images/naver_login_icon.png';
import './css/login.css';
import UserSignUpFlow from './SignUp/UserSignUpFlow';
import StoreSignUpFlow from './SignUp/StoreSignUpFlow';
import PopupBlockedNotification from './PopupBlockedNotification';

function Login() {
  const { t } = useTranslation();
  const theme = useTheme();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(true);
  const [error, setError] = useState('');
  const [popupBlocked, setPopupBlocked] = useState(false);

  const naverProvider = new OAuthProvider('naver.com');
  const kakaoProvider = new OAuthProvider('kakao.com');

  const [isUserModal, setIsUserModal] = useState(false);
  const [isStoreModal, setIsStoreModal] = useState(false);

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       navigate('/');
  //     }
  //   });

  //   return () => unsubscribe();
  // }, [navigate]);

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      updateLastLogin(auth.currentUser.uid);
      navigate('/');
    } catch (error) {
      console.error('Error logging in', error);
      setError('로그인 실패: ' + error.message);
    }
  };
  const clearAuthState = async () => {
    await signOut(auth);
    // Clear local storage or any other storage you are using to save authentication state
    localStorage.clear();
    sessionStorage.clear();
    // Optionally, clear cookies if used
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    // Introduce a delay to ensure sign out is processed completely
    return new Promise((resolve) => setTimeout(resolve, 1000));
  };
  const handleGoogleLogin = async () => {
    try {
      await clearAuthState();

      googleAuthProvider.setCustomParameters({
        prompt: 'select_account',
      });
      const result = await signInWithPopup(auth, googleAuthProvider);
      if (result.user) {
        await handleUserLogin(result.user, 'google.com');
      }
    } catch (error) {
      console.error('구글 로그인 오류:', error.message);
      setError('구글 로그인 오류: ' + error.message);
      if (error.code === 'auth/popup-blocked') {
        setPopupBlocked(true);
      }
    }
  };

  const handleKakaoLogin = async () => {
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
    window.location.href = kakaoURL;
  };

  const handleNaverLogin = async () => {
    const state = Math.random().toString(36).substring(2, 15);
    sessionStorage.setItem('naverOAuthState', state);
    const naverURL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=${state}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}`;
    window.location.href = naverURL;
  };

  async function updateLastLogin(userId) {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    const updates = { lastLogin: serverTimestamp() };
    if (userDoc.exists()) {
      updateDoc(userRef, updates)
        .then(() => {
          console.log('Last login time updated');
        })
        .catch((error) => {
          console.error('Error updating last login time:', error);
        });
    } else {
      setDoc(userRef, updates, { merge: true })
        .then(() => {
          console.log('Last login time updated');
        })
        .catch((error) => {
          console.error('Error updating last login time:', error);
        });
    }
  }

  const handleClose = () => {
    navigate('/');
    setOpen(false);
  };

  const navigateToUserRegister = () => {
    setIsUserModal(true);
    // dispatch(setEditStep(1));
  };
  const navigateToStoreRegister = () => {
    setIsStoreModal(true);
    // dispatch(setEditStep(1));
  };

  const closeUserModal = () => {
    setIsUserModal(false);
    // dispatch(setEditStep(1));
  };

  const closeStoreModal = () => {
    setIsStoreModal(false);
  };

  const handleUserLogin = async (user, providerId) => {
    const email = user.email;
    const name = user.displayName;

    const userRef = doc(db, 'users', user.uid);
    const updates = { email: email, providerId: providerId };

    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      await updateDoc(userRef, updates);
    } else {
      await setDoc(userRef, updates, { merge: true });
    }
    await updateProfile(user, { displayName: name });
    updateLastLogin(user.uid);
    // Dispatch user data to Redux store
    // dispatch(setUser(user));
    navigate('/');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          p: 3,
          backgroundColor: theme.palette.background.default,
          width: '80%',
          maxWidth: '600px',
          borderRadius: theme.shape.borderRadius,
        },
      }}
    >
      {/* 🔹 Dialog Title */}
      <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
        {t('login')}
        <IconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[600],
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* 🔹 Dialog Content */}
      <DialogContent>
        {/* 🔹 이메일 입력 필드 */}
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ bgcolor: theme.palette.background.paper, borderRadius: 1 }}
        />

        {/* 🔹 비밀번호 입력 필드 */}
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ bgcolor: theme.palette.background.paper, borderRadius: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* 🔹 로그인 버튼 */}
        <Button
          onClick={handleLogin}
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            mt: 2,
            py: 1.5,
            boxShadow: theme.shadows[3],
          }}
        >
          {t('login')}
        </Button>

        {/* 🔹 소셜 로그인 버튼 */}
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
          {[
            { onClick: handleGoogleLogin, icon: google_login_icon },
            { onClick: handleKakaoLogin, icon: kakao_login_icon },
            { onClick: handleNaverLogin, icon: naver_login_icon },
          ].map((btn, index) => (
            <Button
              key={index}
              onClick={btn.onClick}
              variant="contained"
              sx={{
                width: 70,
                height: 60,
                backgroundImage: `url(${btn.icon})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: 1,
                boxShadow: theme.shadows[3],
              }}
            />
          ))}
        </Box>

        {/* 🔹 팝업 차단 알림 */}
        {popupBlocked && (
          <Box mt={2} display="flex" justifyContent="center">
            <PopupBlockedNotification
              popupBlocked={popupBlocked}
              handleLogin={handleLogin}
            />
          </Box>
        )}

        {/* 🔹 회원가입 버튼 */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, gap: 2 }}>
          <Button
            onClick={navigateToUserRegister}
            variant="text"
            color="primary"
            sx={{ textTransform: 'none' }}
          >
            {t('register.registerUser')}
          </Button>
          <Button
            onClick={navigateToStoreRegister}
            variant="text"
            color="primary"
            sx={{ textTransform: 'none' }}
          >
            {t('register.registerBusiness')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default Login;
//   return (
//     <Dialog
//       open={open}
//       onClose={handleClose}
//       PaperProps={{
//         style: {
//           padding: '20px',
//           backgroundColor: '#f6f6f6',
//           width: '80%',
//           maxWidth: '600px',
//           height: 'auto',
//         },
//       }}
//     >
//       <DialogTitle>
//         {t('login')}
//         <IconButton
//           style={{ position: 'absolute', right: '8px', top: '8px' }}
//           onClick={handleClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       </DialogTitle>
//       <DialogContent>
//         <TextField
//           variant="outlined"
//           margin="normal"
//           fullWidth
//           label="Email"
//           type="email"
//           value={email || ''}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <TextField
//           variant="outlined"
//           margin="normal"
//           fullWidth
//           label="Password"
//           type={showPassword ? 'text' : 'password'}
//           value={password || ''}
//           onChange={(e) => setPassword(e.target.value)}
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton
//                   edge="end"
//                   onClick={() => setShowPassword(!showPassword)}
//                 >
//                   {showPassword ? <Visibility /> : <VisibilityOff />}
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//         />
//         {error && (
//           <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>
//         )}
//         <Button
//           onClick={handleLogin}
//           variant="contained"
//           color="primary"
//           fullWidth
//           style={{
//             marginTop: '20px',
//             boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//           }}
//         >
//           {t('login')}
//         </Button>
//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'space-around',
//             marginTop: '20px',
//           }}
//         >
//           <Button
//             onClick={handleGoogleLogin}
//             variant="contained"
//             color="secondary"
//             style={{
//               width: '70px',
//               height: '60px',
//               backgroundImage: `url(${google_login_icon})`,
//               backgroundSize: 'cover',
//               backgroundRepeat: 'no-repeat',
//               backgroundPosition: 'center',
//               margin: '5px',
//               boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//             }}
//           ></Button>
//           <Button
//             onClick={handleKakaoLogin}
//             variant="contained"
//             color="secondary"
//             style={{
//               width: '70px',
//               height: '60px',
//               backgroundImage: `url(${kakao_login_icon})`,
//               backgroundSize: 'cover',
//               backgroundRepeat: 'no-repeat',
//               backgroundPosition: 'center',
//               margin: '5px',
//               boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//             }}
//           ></Button>
//           <Button
//             onClick={handleNaverLogin}
//             variant="contained"
//             color="secondary"
//             style={{
//               width: '70px',
//               height: '60px',
//               backgroundImage: `url(${naver_login_icon})`,
//               backgroundSize: 'cover',
//               backgroundRepeat: 'no-repeat',
//               backgroundPosition: 'center',
//               margin: '5px',
//               boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
//             }}
//           ></Button>
//         </div>
//         {popupBlocked && (
//           <Box mt={2} display="flex" justifyContent="center">
//             <PopupBlockedNotification
//               popupBlocked={popupBlocked}
//               handleLogin={handleLogin}
//             />
//           </Box>
//         )}
//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'center',
//             marginTop: '20px',
//           }}
//         >
//           <Button
//             onClick={navigateToUserRegister}
//             variant="text"
//             color="primary"
//             style={{ textTransform: 'none' }}
//           >
//             {t('register.registerUser')}
//           </Button>
//           <Button
//             onClick={navigateToStoreRegister}
//             variant="text"
//             color="primary"
//             style={{ textTransform: 'none' }}
//           >
//             {t('register.registerBusiness')}
//           </Button>
//         </div>

//         {isUserModal && <UserSignUpFlow mode={1} closeModal={closeUserModal} />}

//         {isStoreModal && (
//           <StoreSignUpFlow mode={1} closeModal={closeStoreModal} />
//         )}
//       </DialogContent>
//     </Dialog>
//   );
// }

// export default Login;
