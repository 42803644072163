// import React, { useState } from 'react';
// import ProductInfoTab from './ProductInfoTab';
// import ProductReviewsTab from './ProductReviewsTab';
// import ProductQnaTab from './ProductQnaTab';
// import ProductSellerInfoTab from './ProductSellerInfoTab';
// import './css/ProductTabs.css';

// const ProductTabs = ({ productData }) => {
//   const [activeTab, setActiveTab] = useState('productInfo');

//   const handleTabClick = (tabName) => {
//     setActiveTab(tabName);
//   };

//   return (
//     <div className="product-tabs">
//       <div className="tab-buttons">
//         <button
//           className={activeTab === 'productInfo' ? 'active' : ''}
//           onClick={() => handleTabClick('productInfo')}
//         >
//           상품정보
//         </button>
//         <button
//           className={activeTab === 'reviews' ? 'active' : ''}
//           onClick={() => handleTabClick('reviews')}
//         >
//           상품평
//         </button>
//         <button
//           className={activeTab === 'qna' ? 'active' : ''}
//           onClick={() => handleTabClick('qna')}
//         >
//           상품문의
//         </button>
//         <button
//           className={activeTab === 'sellerInfo' ? 'active' : ''}
//           onClick={() => handleTabClick('sellerInfo')}
//         >
//           배송/교환/반품 안내
//         </button>
//       </div>
//       <div className="tab-content">
//         <ProductInfoTab productInfo={productData} />
//         {activeTab === 'reviews' && (
//           <ProductReviewsTab reviews={productData.reviews} />
//         )}
//         {activeTab === 'qna' && <ProductQnaTab qnaList={productData.qnaList} />}
//         {activeTab === 'sellerInfo' && (
//           <ProductSellerInfoTab seller={productData.seller} />
//         )}
//       </div>
//     </div>
//   );
// };

// export default ProductTabs;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import ProductInfoTab from './ProductInfoTab';
import ProductReviewsTab from './ProductReviewsTab';
import ProductQnaTab from './ProductQnaTab';
import ProductSellerInfoTab from './ProductSellerInfoTab';
import ProductReviewRating from './ProductReviewRating';
import ProductReviewList from './ProductReviewList';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

const StoreProductTabs = ({ storeId, productData }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="product tabs">
          <Tab label="상품정보" {...a11yProps(0)} />
          {/* <Tab label="상품평" {...a11yProps(1)} /> */}
          <Tab label="상품별점" {...a11yProps(1)} />
          <Tab label="상품리뷰" {...a11yProps(2)} />
          <Tab label="상품문의" {...a11yProps(3)} />
          <Tab label="배송/교환/반품 안내" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ProductInfoTab productInfo={productData} />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <ProductReviewsTab reviews={productData.reviews} />
      </TabPanel> */}
      <TabPanel value={value} index={1}>
        <ProductReviewRating storeId={storeId} productId={productData?.id} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ProductReviewList storeId={storeId} productId={productData?.id} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ProductQnaTab qnaList={productData?.qnaList} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ProductSellerInfoTab seller={productData?.seller} />
      </TabPanel>
    </Box>
  );
};

// <Grid item className="flex-end">
//   <ProductReviewsTab reviews={product.reviews} />
// </Grid>
// <Grid item style={{ flex: 1, margin: '30px' }}>
//   <ProductReviewRating storeId={storeId} productId={productId} />
// </Grid>
// <Grid item style={{ flex: 1, margin: '30px' }}>
//   <ProductReviewList storeId={storeId} productId={productId} />
// </Grid>

StoreProductTabs.propTypes = {
  productData: PropTypes.shape({
    productInfo: PropTypes.object,
    reviews: PropTypes.array,
    qnaList: PropTypes.array,
    seller: PropTypes.object,
  }).isRequired,
};

export default StoreProductTabs;
