// import React, { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
// import {
//   Box,
//   Typography,
//   Link,
//   List,
//   ListItem,
//   Tabs,
//   Tab,
//   Button,
//   Select,
//   MenuItem,
//   useMediaQuery,
//   IconButton,
//   ListItemText,
//   ListItemSecondaryAction,
// } from '@mui/material';

// const SearchTabBar = ({ onSelectTab, activeTab }) => {
//   const { t } = useTranslation();

//   return (
//     <Box display="flex">
//       <Button
//         onClick={() => onSelectTab(1)}
//         sx={{
//           flex: 1,
//           fontSize: '0.8rem',
//           textAlign: 'center',
//           color: 'black',
//           backgroundColor: activeTab === 1 ? 'lightgray' : 'white',
//           padding: '10px',
//           border: 'none',
//           cursor: 'pointer',
//         }}
//       >
//         {t('rank1To10')}
//       </Button>
//       <Button
//         onClick={() => onSelectTab(2)}
//         sx={{
//           flex: 1,
//           fontSize: '0.8rem',
//           textAlign: 'center',
//           color: 'black',
//           backgroundColor: activeTab === 2 ? 'lightgray' : 'white',
//           padding: '10px',
//           border: 'none',
//           cursor: 'pointer',
//         }}
//       >
//         {t('rank11To20')}
//       </Button>
//     </Box>
//   );
// };
// export default SearchTabBar;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, useTheme } from '@mui/material';

const SearchTabBar = ({ onSelectTab, activeTab }) => {
  const { t } = useTranslation();
  const theme = useTheme(); // ✅ MUI 테마 적용

  return (
    <Box
      display="flex"
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: theme.shadows[1],
      }}
    >
      {[
        { id: 1, label: t('rank1To10') },
        { id: 2, label: t('rank11To20') },
      ].map(({ id, label }) => (
        <Button
          // onMouseDown={(e) => e.preventDefault()} // 포커스 전환 방지
          key={id}
          onClick={() => onSelectTab(id)}
          sx={{
            flex: 1,
            minWidth: 120,
            fontSize: { xs: '0.75rem', sm: '0.9rem' },
            fontWeight: activeTab === id ? 'bold' : 'normal',
            textAlign: 'center',
            color:
              activeTab === id
                ? theme.palette.primary.contrastText
                : theme.palette.text.primary,
            backgroundColor:
              activeTab === id
                ? theme.palette.primary.main
                : theme.palette.background.default,
            padding: '10px',
            borderRadius: 0,
            transition: 'background 0.3s ease-in-out',
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          }}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
};

export default SearchTabBar;
