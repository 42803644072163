import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import ImageSlider from '../ImageSlider';
import StoreProduct from './StoreProduct';
import PromotionManagement from './Promotion/PromotionManagement';
import CouponManagement from './Promotion/Coupon/CouponManagement';
import DiscountManagement from './Promotion/Discount/DiscountManagement';
import WMSApiKeyInput from './StoreAdmin/WMSApiKeyInput';
import {
  db,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  doc,
  setDoc,
  Timestamp,
} from '../firebaseConfig';
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  CircularProgress,
  Paper,
  Tabs,
  Tab,
  Fab,
  Link,
  TextField,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker from 'react-datepicker';

const StyledButton = styled(Button)({
  backgroundColor: '#ffffff',
  color: '#333',
  boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});

const TimePickerContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1rem',
  width: '100%',
});

const TimePickerLabel = styled(Typography)({
  marginRight: '1rem',
  width: '30%',
});

const MyStore = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { storeId } = useParams();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState([]);
  const [activeMainImageIndex, setActiveMainImageIndex] = useState(0);
  const [activeSubImageIndex, setActiveSubImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [weekdayStartTime, setWeekdayStartTime] = useState(new Date());
  const [weekdayEndTime, setWeekdayEndTime] = useState(new Date());
  const [weekendStartTime, setWeekendStartTime] = useState(new Date());
  const [weekendEndTime, setWeekendEndTime] = useState(new Date());

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const storeRef = doc(db, 'stores', storeId);
        const storeDoc = await getDoc(storeRef);
        if (!storeDoc.exists()) {
          setError('Store not found');
          setLoading(false);
          return;
        }
        const storeData = storeDoc.data();
        setStore(storeData);
        setWeekdayStartTime(
          storeData.weekdayStartTime && storeData.weekdayStartTime.toDate
            ? storeData.weekdayStartTime.toDate()
            : new Date()
        );
        setWeekdayEndTime(
          storeData.weekdayEndTime && storeData.weekdayEndTime.toDate
            ? storeData.weekdayEndTime.toDate()
            : new Date()
        );
        setWeekendStartTime(
          storeData.weekendStartTime && storeData.weekendStartTime.toDate
            ? storeData.weekendStartTime.toDate()
            : new Date()
        );
        setWeekendEndTime(
          storeData.weekendEndTime && storeData.weekendEndTime.toDate
            ? storeData.weekendEndTime.toDate()
            : new Date()
        );
      } catch (err) {
        setError(`Error fetching store: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchStore();
  }, [storeId]);

  useEffect(() => {
    if (!store) return;
    const productsCollection = collection(db, 'stores', storeId, 'products');
    const q = query(productsCollection, where('uid', '==', storeId));
    getDocs(q)
      .then((querySnapshot) => {
        const productsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsData);
      })
      .catch((error) => {
        console.error('Error getting documents: ', error);
      });
  }, [store]);

  const handleSaveProfile = async () => {
    if (!store) return;
    const updatedStoreData = {
      ...store,
      weekdayStartTime,
      weekdayEndTime,
      weekendStartTime,
      weekendEndTime,
    };
    const storeRef = doc(db, 'stores', storeId);
    await setDoc(storeRef, updatedStoreData, { merge: true });
    setStore(updatedStoreData);
  };

  const mainImagesClick = (index) => {
    setActiveMainImageIndex(index);
    const metaFiles = store.mainImages.map((url, index) => ({
      index,
      fileUrl: url,
      senderName: store?.storeName,
      date: store.updateDate,
    }));
    navigate('/imageviewer', {
      state: { files: metaFiles, index: index },
    });
  };

  const subImagesClick = (index) => {
    setActiveSubImageIndex(index);
    const metaFiles = store.subImages.map((url, index) => ({
      index,
      fileUrl: url,
      senderName: store?.storeName,
      date: store.updateDate,
    }));
    navigate('/imageviewer', {
      state: { files: metaFiles, index: index },
    });
  };

  const onMainIndexChange = (index) => {
    setActiveMainImageIndex(index);
  };

  const onSubIndexChange = (index) => {
    setActiveSubImageIndex(index);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleStoreProductClick = (product) => {
    window.open(
      `/product_detail/${product.uid}/${product.id}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3} sx={{ minHeight: '100vh', color: theme.palette.error.main }}>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Box p={3} sx={{ backgroundColor: theme.palette.background.default }}>
      <Container
        maxWidth="lg"
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '1rem',
          borderRadius: '16px',
        }}
      >
        <Box
          sx={{
            display: 'inline-block',
            //borderRadius: '12px',
            padding: '8px 16px',
            marginBottom: '16px',
            //backgroundColor: theme.palette.primary.light,
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.02)',
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              paddingBottom: theme.spacing(2),
              color: theme.palette.primary.main,
            }}
          >
            {t('storehome.myStore')}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={3}
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(2),
              }}
            >
              {store.mainImages && store.mainImages.length > 0 && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: theme.spacing(2),
                  }}
                >
                  <ImageSlider
                    activeIndex={activeMainImageIndex}
                    images={store.mainImages}
                    fileClick={mainImagesClick}
                    onIndexChange={onMainIndexChange}
                  />
                </Grid>
              )}
              {store.subImages && store.subImages.length > 0 && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: theme.spacing(2),
                  }}
                >
                  <ImageSlider
                    activeIndex={activeSubImageIndex}
                    images={store.subImages}
                    fileClick={subImagesClick}
                    onIndexChange={onSubIndexChange}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: theme.spacing(2),
            }}
          >
            {/* 버튼 그룹 */}
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: theme.spacing(1),
                '& a': {
                  textDecoration: 'none',
                },
              }}
            >
              <Link component={RouterLink} to={`/store_orders/${storeId}`}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  }}
                >
                  {t('storehome.myStoreOrders')}
                </Button>
              </Link>

              <Link component={RouterLink} to={`/store_edit/${storeId}`}>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                    '&:hover': {
                      borderColor: theme.palette.primary.dark,
                      color: theme.palette.primary.dark,
                    },
                  }}
                >
                  {t('editInformation')}
                </Button>
              </Link>
            </Box>

            {/* 좋아요, 조회수, 채팅 아이콘 */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(2),
              }}
            >
              <Typography sx={{ color: theme.palette.text.secondary }}>
                💖 {store.likes}
              </Typography>
              <Typography sx={{ color: theme.palette.text.secondary }}>
                👁️ {store.views}
              </Typography>
              <Typography sx={{ color: theme.palette.text.secondary }}>
                💬 {store.chats}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                mt: 2,
                p: 3,
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius,
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: theme.palette.text.primary, mb: 2 }}
              >
                {t('storehome.businessHours')}
              </Typography>

              {/* 주중 영업시간 */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(2),
                  }}
                >
                  <Typography
                    sx={{ width: '40%', color: theme.palette.text.secondary }}
                  >
                    {t('storehome.weekdayStartTime')}
                  </Typography>
                  <ReactDatePicker
                    selected={weekdayStartTime}
                    onChange={(date) => setWeekdayStartTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    customInput={
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{
                          backgroundColor: theme.palette.background.default,
                          borderRadius: theme.shape.borderRadius,
                        }}
                      />
                    }
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(2),
                  }}
                >
                  <Typography
                    sx={{ width: '40%', color: theme.palette.text.secondary }}
                  >
                    {t('storehome.weekdayEndTime')}
                  </Typography>
                  <ReactDatePicker
                    selected={weekdayEndTime}
                    onChange={(date) => setWeekdayEndTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    customInput={
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{
                          backgroundColor: theme.palette.background.default,
                          borderRadius: theme.shape.borderRadius,
                        }}
                      />
                    }
                  />
                </Box>
              </Box>

              {/* 주말 영업시간 */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(2),
                  }}
                >
                  <Typography
                    sx={{ width: '40%', color: theme.palette.text.secondary }}
                  >
                    {t('storehome.weekendStartTime')}
                  </Typography>
                  <ReactDatePicker
                    selected={weekendStartTime}
                    onChange={(date) => setWeekendStartTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    customInput={
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{
                          backgroundColor: theme.palette.background.default,
                          borderRadius: theme.shape.borderRadius,
                        }}
                      />
                    }
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(2),
                  }}
                >
                  <Typography
                    sx={{ width: '40%', color: theme.palette.text.secondary }}
                  >
                    {t('storehome.weekendEndTime')}
                  </Typography>
                  <ReactDatePicker
                    selected={weekendEndTime}
                    onChange={(date) => setWeekendEndTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    customInput={
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{
                          backgroundColor: theme.palette.background.default,
                          borderRadius: theme.shape.borderRadius,
                        }}
                      />
                    }
                  />
                </Box>
              </Box>

              {/* 저장 버튼 */}
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
                onClick={handleSaveProfile}
              >
                {t('saveProfile')}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              centered
              variant="fullWidth"
              TabIndicatorProps={{
                style: {
                  height: '3px',
                  backgroundColor: theme.palette.primary.main,
                },
              }}
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: '8px',
              }}
            >
              <Tab label={t('storehome.products')} sx={{ flex: 1 }} />
              <Tab label={t('storehome.promotions')} sx={{ flex: 1 }} />
              <Tab label={t('storehome.coupons')} sx={{ flex: 1 }} />
              <Tab label={t('storehome.discounts')} sx={{ flex: 1 }} />
              <Tab label={'WMS Key'} sx={{ flex: 1 }} />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {activeTab === 0 && (
              <Grid container spacing={2}>
                {products.length === 0 ? (
                  <Grid item xs={12}>
                    <Typography textAlign="center">
                      {t('storehome.noProductsFound')}
                    </Typography>
                  </Grid>
                ) : isMobile ? (
                  <Box
                    id="parentScrollBox"
                    sx={{
                      marginTop: '20px',
                      height: 'calc(80vh - 70px)',
                      overflow: 'auto',
                      width: '100%',
                    }}
                  >
                    <InfiniteScroll
                      dataLength={products.length}
                      next={() => {}}
                      hasMore={false}
                      loader={<Typography>{t('loading')}</Typography>}
                      endMessage={
                        <Typography variant="body2">
                          {t('storehome.noMoreProducts')}
                        </Typography>
                      }
                      scrollableTarget="parentScrollBox"
                    >
                      <Grid container spacing={2}>
                        {products.map((product) => (
                          <Grid item xs={12} key={product.id}>
                            <StoreProduct
                              product={product}
                              onClick={() => handleStoreProductClick(product)}
                              horizontal={isMobile} // pass the prop for mobile view
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </InfiniteScroll>
                  </Box>
                ) : (
                  products.map((product) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                      <StoreProduct product={product} />
                    </Grid>
                  ))
                )}
                <Grid item xs={12}>
                  <Fab
                    variant="extended"
                    color="primary"
                    aria-label="upload"
                    component={RouterLink}
                    to="/product_upload"
                    sx={{
                      position: 'fixed',
                      bottom: 80,
                      right: 20,
                      zIndex: 1000,
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                      padding: '0 16px',
                      borderRadius: '50px',
                    }}
                  >
                    <AddIcon sx={{ mr: 1 }} />
                    {t('uploadStoreProduct')}
                  </Fab>
                </Grid>
              </Grid>
            )}
            {activeTab === 1 && (
              <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
                <PromotionManagement storeId={storeId} />
              </Paper>
            )}
            {activeTab === 2 && (
              <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
                <CouponManagement storeId={storeId} />
              </Paper>
            )}
            {activeTab === 3 && (
              <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
                <DiscountManagement storeId={storeId} />
              </Paper>
            )}
            {activeTab === 4 && (
              <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
                <WMSApiKeyInput storeId={storeId} />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default MyStore;
