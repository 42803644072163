// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, Route, Routes } from 'react-router-dom';
// import {
//   db,
//   addDoc,
//   setDoc,
//   updateDoc,
//   doc,
//   getDoc,
//   arrayUnion,
//   increment,
//   runTransaction,
// } from '../firebaseConfig';
// import moment from 'moment';
// import '../css/product.css';
// import { formatPrice } from '../Utils/PriceFormatter';
// import StoreProductDetail from './StoreProductDetail';
// import ProductContext from '../Context/productContext';

// moment.locale('ko');

// export const StoreProduct = ({ product }) => {
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [isOrderSaved, setIsOrderSaved] = useState(false);

//   const orderDetails = useSelector((state) => state.order.details);
//   const orderType = useSelector((state) => state.order.type);

//   useEffect(() => {
//     if (orderType === 'PLACE_ORDER') {
//       console.log('effect:' + orderType);
//       placeOrder(orderDetails);
//     } else if (orderType === 'CANCEL_ORDER') {
//       cancelOrder(orderDetails.id);
//     } else if (orderType === 'RETURN_ORDER') {
//       returnOrder(orderDetails.id);
//     }
//   }, [orderType]);

//   // useEffect(() => {
//   //     if (!orderDetails || isOrderSaved || !currentUser) return;

//   //     console.log(`주문자: ${orderDetails.userId}`);
//   //     console.log(`스토어 아이디: ${orderDetails.storeId}`);
//   //     console.log(`제품 아이디: ${orderDetails.productId}`);
//   //     console.log(`주문 수량: ${orderDetails.quantity}`);
//   //     console.log(`가격: ${orderDetails.price}`);

//   //     // 데이터 유효성 검사
//   //     if (!orderDetails.userId || !orderDetails.storeId || !orderDetails.productId || !orderDetails.quantity || !orderDetails.price) {
//   //         console.error("주문 데이터가 완전하지 않습니다.");
//   //         return;
//   //     }
//   //     const orderData = {
//   //         userId: orderDetails.userId,   // 주문자의 사용자 ID
//   //         storeId: orderDetails.storeId,
//   //         productId: orderDetails.productId, // 선택된 제품의 ID
//   //         quantity: orderDetails.quantity,
//   //         selectedCoupon: orderDetails.selectedCoupon,
//   //         dateTime: new Date().toISOString(),  // 주문 일시
//   //         originalPrice: orderDetails.price,      // 할인 및 적립금 사용 전 원래 제품 가격
//   //         discountAmount: 5000,      // 적용된 할인 금액
//   //         pointsUsed: 3000,          // 사용된 적립금
//   //         pointsBefore: 8000,        // 적립금 사용 전 잔액
//   //         pointsAfter: 5000,         // 적립금 사용 후 잔액
//   //         finalPrice: 42000,         // 최종 지불 금액
//   //         productDetails: {          // 선택한 제품의 상세 정보
//   //             name: "productName",
//   //             description: "productDescription",
//   //             imageUrl: "productImageUrl",
//   //         },
//   //         shippingDetails: {         // 배송 관련 정보
//   //             address: "userAddress",
//   //             phoneNumber: "userPhoneNumber",
//   //             deliveryDate: "preferredDeliveryDate",
//   //         },
//   //         paymentMethod: "creditCard", // 지불 방식 (예: 신용카드, PayPal 등)
//   //         orderStatus: "pending",    // 주문 상태 (예: 대기 중, 처리 중, 배송 중, 완료 등)
//   //     }

//   //     // Firestore에 주문 저장
//   //     placeOrder(currentUser.uid, orderDetails.productId, orderDetails.storeId, orderData)
//   //         .then(() => {
//   //             console.log("Order placed successfully!");
//   //             setIsOrderSaved(true);
//   //         })
//   //         .catch((error) => {
//   //             console.error(`Error placing order: ${error}`);
//   //         });

//   // }, [currentUser, orderDetails, isOrderSaved]);

//   const dateObj = product.createDate.toDate();
//   const formattedDate = moment(dateObj).fromNow();
//   const formatState = (state) => {
//     switch (state) {
//       case 0:
//         return '판매중';
//       case 1:
//         return '예약중';
//       case 2:
//         return '판매완료';
//       default:
//         return '';
//     }
//   };

//   const getProductStateClass = (state) => {
//     switch (state) {
//       case 0:
//         return 'on-sale';
//       case 1:
//         return 'reserved';
//       case 2:
//         return 'sold-out';
//       default:
//         return '';
//     }
//   };

//   function generateRandomOrderId() {
//     return Date.now().toString(36) + Math.random().toString(36).slice(2);
//   }

//   // 주문 함수
//   async function placeOrder(orderData) {
//     const userId = currentUser.uid;
//     const {
//       productId,
//       storeId,
//       usedPoints,
//       selectedCoupon,
//       totalPrice,
//       finalPrice,
//       discountedPrice,
//       quantity,
//     } = orderData;

//     const storeUserRef = doc(db, 'stores', storeId, 'users', userId);
//     const productRef = doc(db, 'stores', storeId, 'products', productId);
//     const couponDocRef = doc(
//       db,
//       'stores',
//       storeId,
//       'coupons',
//       selectedCoupon.couponId
//     );
//     try {
//       await runTransaction(db, async (transaction) => {
//         // 1. All Reads First
//         const storeUserDoc = await transaction.get(storeUserRef);
//         const productDoc = await transaction.get(productRef);
//         const couponData = (await transaction.get(couponDocRef)).data();

//         // Ensure documents exist
//         if (!storeUserDoc.exists()) {
//           throw new Error('User document does not exist.');
//         }
//         if (!productDoc.exists()) {
//           throw new Error('Product document does not exist.');
//         }
//         if (selectedCoupon && !couponData) {
//           throw new Error('Coupon data not found.');
//         }

//         // 2. Logic based on the data read
//         let userPoints = storeUserDoc.data().points || 0;
//         userPoints += Number(finalPrice) * 0.01;

//         const orderStats = productDoc.data()?.orderStats;
//         const orderRef = doc(
//           db,
//           'stores',
//           storeId,
//           'orders',
//           generateRandomOrderId()
//         );

//         let productIndex = -1;
//         if (selectedCoupon) {
//           if (Array.isArray(couponData.applicableProducts)) {
//             productIndex = couponData.applicableProducts.findIndex(
//               (p) => p.id === productId
//             );
//             if (productIndex === -1) {
//               throw new Error('Applicable product not found in coupon.');
//             }
//           } else {
//             console.error(
//               'applicableProducts is not an array',
//               couponData.applicableProducts
//             );
//           }
//         }

//         // 3. All Writes
//         transaction.set(storeUserRef, { points: userPoints }, { merge: true });

//         if (orderStats) {
//           transaction.update(productRef, {
//             'orderStats.totalOrders': increment(1),
//             'orderStats.lastOrderedDate': new Date(),
//           });
//         } else {
//           transaction.set(
//             productRef,
//             {
//               orderStats: {
//                 totalOrders: 1,
//                 lastOrderedDate: new Date(),
//               },
//             },
//             { merge: true }
//           );
//         }

//         transaction.set(orderRef, orderData, { merge: true });

//         if (selectedCoupon) {
//           transaction.update(storeUserRef, {
//             [`downloadedCoupons.${selectedCoupon.id}.${productId}.used`]: true,
//           });
//           transaction.update(couponDocRef, {
//             [`applicableProducts.${productIndex}.usersWhoUsedCoupons`]:
//               arrayUnion(userId),
//           });
//         }
//       });

//       console.log('Order placed successfully!');
//     } catch (error) {
//       console.error('Error placing order:', error);
//       // TODO: Inform the user about the error, perhaps via a popup or toast notification
//     }
//   }

//   //주문 취소
//   async function cancelOrder(orderData) {
//     const userId = currentUser.uid;
//     const { productId, storeId, orderId, usedPoints, selectedCoupon } =
//       orderData;

//     const storeUserRef = doc(db, 'stores', storeId, 'users', userId);
//     const userOrderRef = doc(
//       db,
//       'stores',
//       storeId,
//       'users',
//       userId,
//       'orders',
//       orderId
//     );
//     const productRef = doc(db, 'stores', storeId, 'products', productId);
//     const storeOrderRef = doc(db, 'stores', storeId, 'orders', orderId);

//     try {
//       await runTransaction(db, async (transaction) => {
//         // Fetch the current data
//         const storeUserData = await getDoc(storeUserRef);
//         const productData = await getDoc(productRef);

//         // Check if the user data exists before trying to access its properties
//         if (storeUserData.exists()) {
//           const currentPoints = storeUserData.data().points || 0;
//           transaction.update(storeUserRef, {
//             points: currentPoints + usedPoints,
//           });
//         }

//         // Check if the product data exists and has orderStats before trying to access its properties
//         if (productData.exists() && productData.data().orderStats) {
//           const currentTotalOrders =
//             productData.data().orderStats.totalOrders || 0;
//           transaction.update(productRef, {
//             'orderStats.totalOrders': Math.max(0, currentTotalOrders - 1), // Ensure never goes below 0
//           });
//         }

//         // Delete order references
//         transaction.delete(userOrderRef);
//         transaction.delete(storeOrderRef);
//       });

//       console.log('Order canceled successfully!');
//     } catch (error) {
//       console.error('Error canceling order:', error);
//       // Consider throwing the error or handling it further as per your app's requirements
//     }
//   }

//   async function returnOrder(orderData) {
//     const userId = currentUser.uid;
//     const { productId, storeId, orderId } = orderData;

//     const userOrderRef = doc(
//       db,
//       'stores',
//       storeId,
//       'users',
//       userId,
//       'orders',
//       orderId
//     );
//     const productOrderStatsRef = doc(
//       db,
//       'stores',
//       storeId,
//       'products',
//       productId,
//       'orderStats'
//     );
//     const storeOrderRef = doc(db, 'stores', storeId, 'orders', orderId);

//     try {
//       await runTransaction(db, async (transaction) => {
//         // Fetch the current data for user's order, product order stats, and store's order
//         const userOrderData = await getDoc(userOrderRef);
//         const productOrderStatsData = await getDoc(productOrderStatsRef);
//         const storeOrderData = await getDoc(storeOrderRef);

//         // Update the user's order status to 'returned'
//         if (userOrderData.exists()) {
//           transaction.update(userOrderRef, { status: 'returned' });
//         }

//         // Increment the total returns for the product
//         if (productOrderStatsData.exists()) {
//           const currentTotalReturns =
//             productOrderStatsData.data().totalReturns || 0;
//           transaction.update(productOrderStatsRef, {
//             totalReturns: currentTotalReturns + 1,
//           });
//         }

//         // Update the store's order status to 'returned'
//         if (storeOrderData.exists()) {
//           transaction.update(storeOrderRef, { status: 'returned' });
//         }

//         // For arrays, you can add checks with Array.isArray() where needed.
//         // e.g., if you need to check an array in the fetched data:
//         // if (Array.isArray(userOrderData.data().someArrayProperty)) {
//         //     // Handle the array-specific operations
//         // }
//       });

//       console.log('Order returned successfully!');
//     } catch (error) {
//       console.error('Error processing return:', error);
//       // Consider throwing the error or handling it further as per your app's requirements
//     }
//   }

//   return (
//     <div className="products">
//       <Link
//         to={`/product_detail/${product.uid}/${product.id}`}
//         className="custom-link"
//       >
//         <div>
//           <div
//             className="thumbnail"
//             style={{ backgroundImage: `url(${product.images[0]})` }}
//           ></div>
//           <div className="flex-grow-1">
//             <h5 className="title">{product.productName}</h5>
//             {/* <p className="title">{product.description}</p> */}
//             <p className="date">{formattedDate}</p>
//             <p className="stock">{product.stock} 개 남음</p>
//             <p className="price">{formatPrice(product.price)} 원</p>
//             <p className={`state ${getProductStateClass(product.state)}`}>
//               {formatState(product.state)}
//             </p>

//             <div className="actions">
//               <span>
//                 <span className="icon-heart">좋아요</span>
//                 {product.likes}
//               </span>
//               <span>
//                 <span className="icon-views">조회수</span>
//                 {product.views}
//               </span>
//               <span>
//                 <span className="icon-chat">채팅</span>
//                 {product.chats}
//               </span>
//             </div>
//           </div>
//         </div>
//       </Link>
//     </div>
//   );
// };
// export default StoreProduct;

// import React from 'react';
// import { useSelector } from 'react-redux';
// import { Link as RouterLink } from 'react-router-dom';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   CardActions,
//   IconButton,
//   Tooltip,
// } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import { formatPrice } from '../Utils/PriceFormatter';

// export const StoreProduct = ({ product, onClick }) => {
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const formattedDate = product.createDate.toDate().toLocaleDateString('ko-KR');

//   const formatState = (state) => {
//     switch (state) {
//       case 0:
//         return '판매중';
//       case 1:
//         return '예약중';
//       case 2:
//         return '판매완료';
//       default:
//         return '';
//     }
//   };

//   const getProductStateClass = (state) => {
//     switch (state) {
//       case 0:
//         return 'on-sale';
//       case 1:
//         return 'reserved';
//       case 2:
//         return 'sold-out';
//       default:
//         return '';
//     }
//   };

//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         borderRadius: '8px', // 모든 모서리 라운드 처리
//         m: 0,
//         margin: '5px',
//         padding: '5px',
//         boxShadow: 3,
//         overflow: 'visible',
//       }}
//       onClick={onClick}
//     >
//       {/* <CardActionArea
//         component={RouterLink}
//         to={`/product_detail/${product.uid}/${product.id}`}
//       > */}
//       <CardActionArea>
//         <CardMedia
//           component={RouterLink}
//           to={`/product_detail/${product.uid}/${product.id}`}
//           // image={
//           //   product?.large_images?.length > 0 ? product?.large_images[0] : null
//           // }
//           sx={{
//             height: 0,
//             paddingTop: '56.25%', // 16:9 비율 유지
//             backgroundImage: `url(${product.large_images[0]})`,
//             backgroundSize: 'cover',
//             backgroundPosition: 'center',
//             borderRadius: '8px', // 모든 모서리 라운드 처리
//           }}
//           alt={product.productName}
//         />
//         <CardContent>
//           <Typography gutterBottom variant="h5" component="div">
//             {product.productName}
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             {product.description}
//           </Typography>
//           <Typography variant="body2" color="text.primary">
//             {formattedDate}
//           </Typography>
//           <Typography variant="body2" color="text.primary">
//             {product.stock} 개 남음
//           </Typography>
//           <Typography variant="body2" color="text.primary">
//             {formatPrice(product.price)} 원
//           </Typography>
//           <Typography
//             className={getProductStateClass(product.state)}
//             variant="body2"
//             color="primary"
//           >
//             {formatState(product.state)}
//           </Typography>
//         </CardContent>
//       </CardActionArea>
//       <CardActions disableSpacing>
//         <Tooltip title="Likes">
//           <IconButton aria-label="add to favorites">
//             <FavoriteIcon />
//             <Typography variant="caption">{product.likes}</Typography>
//           </IconButton>
//         </Tooltip>
//         <Tooltip title="Views">
//           <IconButton aria-label="share">
//             <VisibilityIcon />
//             <Typography variant="caption">{product.views}</Typography>
//           </IconButton>
//         </Tooltip>
//         <Tooltip title="Chat">
//           <IconButton>
//             <ChatIcon />
//             <Typography variant="caption">{product.chats}</Typography>
//           </IconButton>
//         </Tooltip>
//       </CardActions>
//     </Card>
//   );
// };

// export default StoreProduct;

// import React from 'react';
// import { useSelector } from 'react-redux';
// import { Link as RouterLink } from 'react-router-dom';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   CardActions,
//   IconButton,
//   Tooltip,
//   Box,
//   Chip,
// } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
// import { formatPrice } from '../Utils/PriceFormatter';

// export const StoreProduct = ({ product, onClick }) => {
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const formattedDate = product.createDate.toDate().toLocaleDateString('ko-KR');

//   const formatState = (state) => {
//     switch (state) {
//       case 0:
//         return { icon: <CheckCircleIcon />, text: '판매중', color: 'success' };

//       case 1:
//         return '예약중';
//       case 2:
//         return { icon: <CancelIcon />, text: '판매완료', color: 'error' };
//       default:
//         return '';
//     }
//   };

//   const getProductStateClass = (state) => {
//     switch (state) {
//       case 0:
//         return 'on-sale';
//       case 1:
//         return 'reserved';
//       case 2:
//         return 'sold-out';
//       default:
//         return '';
//     }
//   };

//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         borderRadius: '8px', // 모든 모서리 라운드 처리
//         margin: '5px',
//         padding: '5px',
//         boxShadow: 3,
//         overflow: 'visible',
//       }}
//       onClick={onClick}
//     >
//       {/* <CardActionArea
//         component={RouterLink}
//         to={`/product_detail/${product.uid}/${product.id}`}
//       > */}
//       <CardActionArea>
//         <CardMedia
//           component={RouterLink}
//           to={`/product_detail/${product.uid}/${product.id}`}
//           // image={
//           //   product?.large_images?.length > 0 ? product?.large_images[0] : null
//           // }
//           sx={{
//             height: 0,
//             paddingTop: '56.25%', // 16:9 비율 유지
//             backgroundImage: `url(${product.icon_images[0]})`,
//             backgroundSize: 'cover',
//             backgroundPosition: 'center',
//             borderRadius: '8px', // 모든 모서리 라운드 처리
//           }}
//           alt={product.productName}
//         />
//         <CardContent>
//           <Typography
//             gutterBottom
//             variant="h6"
//             component="div"
//             noWrap
//             sx={{
//               overflow: 'hidden',
//               textOverflow: 'ellipsis',
//               whiteSpace: 'nowrap',
//             }}
//           >
//             {product.productName}
//           </Typography>

//           <Typography
//             gutterBottom
//             variant="body2"
//             color="text.secondary"
//             component="div"
//             noWrap
//             sx={{
//               overflow: 'hidden',
//               textOverflow: 'ellipsis',
//               whiteSpace: 'nowrap',
//             }}
//           >
//             {product.description}
//           </Typography>

//           <Typography variant="body2" color="text.primary">
//             {product.stock} 개 남음
//           </Typography>

//           <Box
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'space-between',
//               mt: 1,
//               mb: 2,
//             }}
//           >
//             <Typography variant="body2" color="text.secondary" component="span">
//               {formattedDate}
//             </Typography>
//             <Chip
//               icon={formatState(product.state).icon}
//               label={formatState(product.state).text}
//               color={formatState(product.state).color}
//               size="small"
//               variant="outlined"
//             />
//           </Box>

//           <Box
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'space-between',
//               mt: 2,
//             }}
//           >
//             <Typography variant="body1" component="span" sx={{ flexGrow: 1 }}>
//               {formatPrice(product.price)} 원
//             </Typography>
//             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//               <Tooltip title="Likes">
//                 <IconButton aria-label="add to favorites" size="small">
//                   <FavoriteIcon />
//                   <Typography variant="caption" sx={{ ml: 0.5 }}>
//                     {product.likes}
//                   </Typography>
//                 </IconButton>
//               </Tooltip>
//               <Tooltip title="Views">
//                 <IconButton aria-label="view count" size="small">
//                   <VisibilityIcon />
//                   <Typography variant="caption" sx={{ ml: 0.5 }}>
//                     {product.views}
//                   </Typography>
//                 </IconButton>
//               </Tooltip>
//               <Tooltip title="Chat">
//                 <IconButton aria-label="chat count" size="small">
//                   <ChatIcon />
//                   <Typography variant="caption" sx={{ ml: 0.5 }}>
//                     {product.chats}
//                   </Typography>
//                 </IconButton>
//               </Tooltip>
//             </Box>
//           </Box>
//         </CardContent>
//       </CardActionArea>
//       <CardActions disableSpacing></CardActions>
//     </Card>
//   );
// };

// export default StoreProduct;

// StoreProduct.js
// import React from 'react';
// import { useSelector } from 'react-redux';
// import { Link as RouterLink } from 'react-router-dom';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   CardActions,
//   IconButton,
//   Tooltip,
//   Box,
//   Chip,
//   useMediaQuery,
// } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
// import { formatPrice } from '../Utils/PriceFormatter';

// const StoreProduct = ({ product, onClick, horizontal }) => {
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

//   const formattedDate = product.createDate.toDate().toLocaleDateString('ko-KR');

//   const formatState = (state) => {
//     switch (state) {
//       case 0:
//         return { icon: <CheckCircleIcon />, text: '판매중', color: 'success' };

//       case 1:
//         return '예약중';
//       case 2:
//         return { icon: <CancelIcon />, text: '판매완료', color: 'error' };
//       default:
//         return '';
//     }
//   };

//   const getProductStateClass = (state) => {
//     switch (state) {
//       case 0:
//         return 'on-sale';
//       case 1:
//         return 'reserved';
//       case 2:
//         return 'sold-out';
//       default:
//         return '';
//     }
//   };

//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         borderRadius: '8px',
//         margin: '5px',
//         padding: '5px',
//         boxShadow: 3,
//         overflow: 'visible',
//       }}
//       onClick={onClick}
//     >
//       <CardActionArea>
//         {horizontal && isMobile ? (
//           <Box display="flex" flexDirection="row">
//             <CardMedia
//               component={RouterLink}
//               to={`/product_detail/${product.uid}/${product.id}`}
//               sx={{
//                 width: '40%',
//                 objectFit: 'cover',
//                 backgroundImage: `url(${product.icon_images[0]})`,
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center',
//                 borderRadius: '8px',
//               }}
//               alt={product.productName}
//             />
//             <CardContent sx={{ width: '60%', padding: '16px' }}>
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                   whiteSpace: 'nowrap',
//                 }}
//               >
//                 {product.productName}
//               </Typography>
//               <Typography
//                 gutterBottom
//                 variant="body2"
//                 color="text.secondary"
//                 component="div"
//                 noWrap
//                 sx={{
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                   whiteSpace: 'nowrap',
//                 }}
//               >
//                 {product.description}
//               </Typography>
//               <Typography variant="body2" color="text.primary">
//                 {product.stock} 개 남음
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                   mb: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={formatState(product.state).icon}
//                   label={formatState(product.state).text}
//                   color={formatState(product.state).color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{ flexGrow: 1 }}
//                 >
//                   {formatPrice(product.price)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Box>
//         ) : (
//           <>
//             <CardMedia
//               component={RouterLink}
//               to={`/product_detail/${product.uid}/${product.id}`}
//               sx={{
//                 height: 0,
//                 paddingTop: '56.25%',
//                 backgroundImage: `url(${product.icon_images[0]})`,
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center',
//                 borderRadius: '8px',
//               }}
//               alt={product.productName}
//             />
//             <CardContent>
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                   whiteSpace: 'nowrap',
//                 }}
//               >
//                 {product.productName}
//               </Typography>
//               <Typography
//                 gutterBottom
//                 variant="body2"
//                 color="text.secondary"
//                 component="div"
//                 noWrap
//                 sx={{
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                   whiteSpace: 'nowrap',
//                 }}
//               >
//                 {product.description}
//               </Typography>
//               <Typography variant="body2" color="text.primary">
//                 {product.stock} 개 남음
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                   mb: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={formatState(product.state).icon}
//                   label={formatState(product.state).text}
//                   color={formatState(product.state).color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{ flexGrow: 1 }}
//                 >
//                   {formatPrice(product.price)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </>
//         )}
//       </CardActionArea>
//       <CardActions disableSpacing></CardActions>
//     </Card>
//   );
// };

// export default StoreProduct;

// import React from 'react';
// import { useSelector } from 'react-redux';
// import { Link as RouterLink } from 'react-router-dom';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   IconButton,
//   Tooltip,
//   Box,
//   Chip,
//   useMediaQuery,
// } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
// import { formatPrice } from '../Utils/PriceFormatter';

// const StoreProduct = ({ product, onClick, horizontal }) => {
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

//   const formattedDate = product.createDate.toDate().toLocaleDateString('ko-KR');

//   const formatState = (state) => {
//     switch (state) {
//       case 0:
//         return { icon: <CheckCircleIcon />, text: '판매중', color: 'success' };
//       case 1:
//         return { icon: <FavoriteIcon />, text: '예약중', color: 'info' };
//       case 2:
//         return { icon: <CancelIcon />, text: '판매완료', color: 'error' };
//       default:
//         return { icon: <CancelIcon />, text: '알수없음', color: 'error' };
//     }
//   };

//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         borderRadius: '8px',
//         margin: '5px',
//         padding: '5px',
//         boxShadow: 3,
//         overflow: 'visible',
//         height: isMobile && horizontal ? 'auto' : '100%',
//       }}
//       onClick={onClick}
//     >
//       <CardActionArea>
//         {horizontal && isMobile ? (
//           <Box display="flex" flexDirection="row" sx={{ height: '150px' }}>
//             <Box
//               sx={{
//                 width: '40%',
//                 height: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <CardMedia
//                 component={RouterLink}
//                 to={`/product_detail/${product.uid}/${product.id}`}
//                 sx={{
//                   width: '100%',
//                   height: '100%',
//                   backgroundImage: `url(${product.icon_images[0]})`,
//                   backgroundSize: 'cover',
//                   backgroundPosition: 'center',
//                   borderRadius: '8px',
//                 }}
//                 alt={product.productName}
//               />
//             </Box>
//             <CardContent
//               sx={{
//                 width: '60%',
//                 padding: '2px',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',

//                 marginTop: 'auto',
//                 marginBottom: 'auto',
//               }}
//             >
//               <Typography gutterBottom variant="h6" component="div">
//                 {product.productName}
//               </Typography>
//               <Typography variant="body2" color="text.secondary">
//                 {product.description}
//               </Typography>
//               <Typography variant="body2" color="text.primary">
//                 {product.stock} 개 남음
//               </Typography>

//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={formatState(product.state).icon}
//                   label={formatState(product.state).text}
//                   color={formatState(product.state).color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{ flexGrow: 1 }}
//                   noWrap
//                 >
//                   {formatPrice(product.price)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Box>
//         ) : (
//           <>
//             <CardMedia
//               component={RouterLink}
//               to={`/product_detail/${product.uid}/${product.id}`}
//               sx={{
//                 height: 0,
//                 paddingTop: '56.25%',
//                 backgroundImage: `url(${product.icon_images[0]})`,
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center',
//                 borderRadius: '8px',
//               }}
//               alt={product.productName}
//             />
//             <CardContent
//               sx={{
//                 paddingTop: '16px',
//                 paddingBottom: '16px',
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 sx={{
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//                 noWrap
//               >
//                 {product.productName}
//               </Typography>
//               <Typography
//                 gutterBottom
//                 variant="body2"
//                 color="text.secondary"
//                 component="div"
//                 sx={{
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//                 noWrap
//               >
//                 {product.description}
//               </Typography>
//               <Typography variant="body2" color="text.primary" noWrap>
//                 {product.stock} 개 남음
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                   mb: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={formatState(product.state).icon}
//                   label={formatState(product.state).text}
//                   color={formatState(product.state).color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{ flexGrow: 1 }}
//                   noWrap
//                 >
//                   {formatPrice(product.price)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </>
//         )}
//       </CardActionArea>
//     </Card>
//   );
// };

// export default StoreProduct;

// import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   IconButton,
//   Tooltip,
//   Box,
//   Chip,
//   useMediaQuery,
// } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
// import { formatPrice } from '../Utils/PriceFormatter';

// const StoreProduct = ({ product, onClick, horizontal }) => {
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

//   const formattedDate = product.createDate.toDate().toLocaleDateString('ko-KR');

//   const formatState = (state) => {
//     switch (state) {
//       case 0:
//         return { icon: <CheckCircleIcon />, text: '판매중', color: 'success' };
//       case 1:
//         return '예약중';
//       case 2:
//         return { icon: <CancelIcon />, text: '판매완료', color: 'error' };
//       default:
//         return '';
//     }
//   };

//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         m: '5px',
//         p: '5px',
//         boxShadow: 3,
//         borderRadius: 2,
//         overflow: 'hidden',
//         '@media (max-width: 600px)': {
//           m: '2px',
//           p: '2px',
//         },
//       }}
//       onClick={onClick}
//     >
//       <CardActionArea>
//         {horizontal && isMobile ? (
//           <Box display="flex" flexDirection="row" sx={{ height: '150px' }}>
//             <Box
//               sx={{
//                 width: '40%',
//                 height: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <CardMedia
//                 component={RouterLink}
//                 to={`/product_detail/${product.uid}/${product.id}`}
//                 sx={{
//                   width: '100%',
//                   height: '100%',
//                   backgroundImage: `url(${product.icon_images[0]})`,
//                   backgroundSize: 'cover',
//                   backgroundPosition: 'center',
//                 }}
//                 alt={product.productName}
//               />
//             </Box>
//             <CardContent
//               sx={{
//                 width: '60%',
//                 padding: '8px',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 marginTop: 'auto',
//                 marginBottom: 'auto',
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em', // Two lines at most
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.productName}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.secondary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em', // Two lines at most
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.description}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.primary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em', // Two lines at most
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.stock} 개 남음
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 0,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={formatState(product.state).icon}
//                   label={formatState(product.state).text}
//                   color={formatState(product.state).color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 0,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{
//                     flexGrow: 1,
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                   }}
//                 >
//                   {formatPrice(product.price)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Box>
//         ) : (
//           <>
//             <CardMedia
//               component={RouterLink}
//               to={`/product_detail/${product.uid}/${product.id}`}
//               sx={{
//                 height: 0,
//                 paddingTop: '56.25%',
//                 backgroundImage: `url(${product.icon_images[0]})`,
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center',
//                 borderRadius: '8px',
//               }}
//               alt={product.productName}
//             />
//             <CardContent
//               sx={{
//                 paddingTop: '16px',
//                 paddingBottom: '16px',
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em', // Two lines at most
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.productName}
//               </Typography>
//               <Typography
//                 gutterBottom
//                 variant="body2"
//                 color="text.secondary"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em', // Two lines at most
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.description}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.primary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em', // Two lines at most
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.stock} 개 남음
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                   mb: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={formatState(product.state).icon}
//                   label={formatState(product.state).text}
//                   color={formatState(product.state).color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{
//                     flexGrow: 1,
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                   }}
//                 >
//                   {formatPrice(product.price)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </>
//         )}
//       </CardActionArea>
//     </Card>
//   );
// };

// export default StoreProduct;

// import React, { useEffect } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   IconButton,
//   Tooltip,
//   Box,
//   Chip,
//   useMediaQuery,
// } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
// import { formatPrice } from '../Utils/PriceFormatter';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';
// import productDefaultImage from '../assets/images/productDefaultImage.png';

// const StoreProduct = ({ product, onClick, horizontal }) => {
//   const { t, i18n } = useTranslation();
//   useEffect(() => {
//     moment.locale(i18n.language);
//   }, [i18n.language]);

//   const formattedDate = moment(product.createDate.toDate()).fromNow();
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

//   const formatState = (state) => {
//     switch (state) {
//       case 0:
//         return {
//           icon: <CheckCircleIcon />,
//           text: t('selling'),
//           color: 'success',
//         };
//       case 1:
//         return { icon: <FavoriteIcon />, text: t('reserved'), color: 'info' };
//       case 2:
//         return { icon: <CancelIcon />, text: t('sold-out'), color: 'error' };
//       default:
//         return { icon: <CancelIcon />, text: t('unknown'), color: 'error' };
//     }
//   };

//   const imageUrl =
//     product.icon_images && product.icon_images.length > 0
//       ? product.icon_images[0]
//       : productDefaultImage;

//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         m: '5px',
//         p: '5px',
//         boxShadow: 3,
//         borderRadius: 2,
//         overflow: 'hidden',
//         '@media (max-width: 600px)': {
//           m: '2px',
//           p: '2px',
//         },
//       }}
//       onClick={onClick}
//     >
//       <CardActionArea>
//         {horizontal && isMobile ? (
//           <Box display="flex" flexDirection="row" sx={{ height: '150px' }}>
//             <Box
//               sx={{
//                 width: '40%',
//                 height: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <CardMedia
//                 component={RouterLink}
//                 to={`/product_detail/${product.uid}/${product.id}`}
//                 sx={{
//                   width: '100%',
//                   height: '100%',
//                   backgroundImage: `url(${imageUrl})`,
//                   backgroundSize: 'cover',
//                   backgroundPosition: 'center',
//                 }}
//                 alt={product.productName}
//               />
//             </Box>
//             <CardContent
//               sx={{
//                 width: '60%',
//                 padding: '8px',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 marginTop: 'auto',
//                 marginBottom: 'auto',
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.productName}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.secondary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.description}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.primary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.stock} {t('left')}
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 0,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={formatState(product.state).icon}
//                   label={formatState(product.state).text}
//                   color={formatState(product.state).color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 0,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{
//                     flexGrow: 1,
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                   }}
//                 >
//                   {formatPrice(product.price)} {t('currency')}
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Box>
//         ) : (
//           <>
//             <CardMedia
//               component={RouterLink}
//               to={`/product_detail/${product.uid}/${product.id}`}
//               sx={{
//                 height: 0,
//                 paddingTop: '56.25%',
//                 backgroundImage: `url(${imageUrl})`,
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center',
//                 borderRadius: '8px',
//               }}
//               alt={product.productName}
//             />
//             <CardContent
//               sx={{
//                 paddingTop: '16px',
//                 paddingBottom: '16px',
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.productName}
//               </Typography>
//               <Typography
//                 gutterBottom
//                 variant="body2"
//                 color="text.secondary"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.description}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.primary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.stock} {t('left')}
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                   mb: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={formatState(product.state).icon}
//                   label={formatState(product.state).text}
//                   color={formatState(product.state).color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{
//                     flexGrow: 1,
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                   }}
//                 >
//                   {formatPrice(product.price)} {t('currency')}
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </>
//         )}
//       </CardActionArea>
//     </Card>
//   );
// };

// export default StoreProduct;

// import React, { useEffect } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   IconButton,
//   Tooltip,
//   Box,
//   Chip,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
// import { formatPrice } from '../Utils/PriceFormatter';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';
// import productDefaultImage from '../assets/images/productDefaultImage.png';

// const StoreProduct = ({ product, onClick, horizontal }) => {
//   const { t, i18n } = useTranslation();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   useEffect(() => {
//     moment.locale(i18n.language);
//   }, [i18n.language]);

//   const formattedDate = moment(product.createDate.toDate()).fromNow();

//   const formatState = (state) => {
//     switch (state) {
//       case 0:
//         return {
//           icon: <CheckCircleIcon />,
//           text: t('selling'),
//           color: 'success',
//         };
//       case 1:
//         return { icon: <FavoriteIcon />, text: t('reserved'), color: 'info' };
//       case 2:
//         return { icon: <CancelIcon />, text: t('sold-out'), color: 'error' };
//       default:
//         return { icon: <CancelIcon />, text: t('unknown'), color: 'error' };
//     }
//   };

//   const imageUrl =
//     product.icon_images && product.icon_images.length > 0
//       ? product.icon_images[0]
//       : productDefaultImage;

//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         m: '5px',
//         p: '5px',
//         boxShadow: 3,
//         borderRadius: 2,
//         overflow: 'hidden',
//         '@media (max-width: 600px)': {
//           m: '2px',
//           p: '2px',
//         },
//       }}
//       onClick={onClick}
//     >
//       <CardActionArea>
//         {horizontal && isMobile ? (
//           <Box display="flex" flexDirection="row" sx={{ height: '170px' }}>
//             <Box
//               sx={{
//                 width: '40%',
//                 height: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <CardMedia
//                 component={RouterLink}
//                 // to={`/product_detail/${product.uid}/${product.id}`}
//                 sx={{
//                   width: '100%',
//                   height: '100%',
//                   backgroundImage: `url(${imageUrl})`,
//                   backgroundSize: 'cover',
//                   backgroundPosition: 'center',
//                 }}
//                 alt={product.productName}
//               />
//             </Box>
//             <CardContent
//               sx={{
//                 width: '60%',
//                 padding: '8px',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 marginTop: 'auto',
//                 marginBottom: 'auto',
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.productName}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.secondary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.description}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.primary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.stock} {t('left')}
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 0,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={formatState(product.state).icon}
//                   label={formatState(product.state).text}
//                   color={formatState(product.state).color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 0,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{
//                     flexGrow: 1,
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                   }}
//                 >
//                   {formatPrice(product.price)} {t('currency')}
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Box>
//         ) : (
//           <>
//             <CardMedia
//               component={RouterLink}
//               to={`/product_detail/${product.uid}/${product.id}`}
//               sx={{
//                 height: 0,
//                 paddingTop: '56.25%',
//                 backgroundImage: `url(${imageUrl})`,
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center',
//                 borderRadius: '8px',
//                 '@media (max-width: 600px)': {
//                   height: '150px',
//                   paddingTop: 0,
//                 },
//               }}
//               alt={product.productName}
//             />
//             <CardContent
//               sx={{
//                 paddingTop: '16px',
//                 paddingBottom: '16px',
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.productName}
//               </Typography>
//               <Typography
//                 gutterBottom
//                 variant="body2"
//                 color="text.secondary"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.description}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="text.primary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {product.stock} {t('left')}
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                   mb: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={formatState(product.state).icon}
//                   label={formatState(product.state).text}
//                   color={formatState(product.state).color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{
//                     flexGrow: 1,
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                   }}
//                 >
//                   {formatPrice(product.price)} {t('currency')}
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </>
//         )}
//       </CardActionArea>
//     </Card>
//   );
// };

// export default StoreProduct;

import React, { useEffect, useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Chip,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Use your custom ThemeContext
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { orange } from '@mui/material/colors';
import { formatPrice } from '../Utils/PriceFormatter';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import productDefaultImage from '../assets/images/productDefaultImage.png';
import { useWeather } from '../Weather/WeatherProvider';
import { useWeatherEvent } from '../Weather/useWeatherEvent';
import { useWeatherEvents } from '../Weather/useWeatherEvents';
import { isWeatherEventActive } from '../Weather/WeatherEventRules';
import WeatherPriceDisplay from '../Weather/WeatherPriceDisplay';
import WeatherEventDisplay from '../Weather/WeatherEventDisplay';
import { useGlobalContext } from '../useGlobalContext';
import StoreProductCard from './StoreProductCard';

const StoreProduct = ({ product, onClick, horizontal }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme(); // Access custom theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { defaultAspectRatio, pcAspectRatio } = useGlobalContext();
  const { weatherData } = useWeather();
  const { weatherEvents } = useWeatherEvents();
  const { weatherEvent } = useWeatherEvent(product?.weatherEvent);
  const [discount, setDiscount] = useState(product?.discount);

  const isEventActive = useMemo(
    () => weatherEvent && isWeatherEventActive(weatherData, weatherEvent),
    [weatherData, weatherEvent]
  );

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const formattedDate = moment(product.createDate.toDate()).fromNow();

  const formatState = (state) => {
    switch (state) {
      case 0:
        return {
          icon: <CheckCircleIcon />,
          text: t('selling'),
          color: 'success',
        };
      case 1:
        return { icon: <FavoriteIcon />, text: t('reserved'), color: 'info' };
      case 2:
        return { icon: <CancelIcon />, text: t('sold-out'), color: 'error' };
      default:
        return { icon: <CancelIcon />, text: t('unknown'), color: 'error' };
    }
  };

  const imageUrl =
    product.icon_images && product.icon_images.length > 0
      ? product.icon_images[0]
      : productDefaultImage;

  return (
    <StoreProductCard
      product={product}
      weatherEvent={weatherEvent}
      isWeatherEventActive={isEventActive}
      onClick={onClick}
    />
    // <Card
    //   sx={{
    //     maxWidth: '100%',
    //     m: theme.spacing(1),
    //     p: theme.spacing(1),
    //     boxShadow: theme.shadows[3],
    //     borderRadius: theme.shape.borderRadius,
    //     backgroundColor: theme.palette.background.paper,
    //     overflow: 'hidden',
    //     color: theme.palette.text.primary,
    //     '@media (max-width: 600px)': {
    //       m: theme.spacing(1),
    //       p: theme.spacing(1),
    //     },
    //   }}
    //   onClick={onClick}
    // >
    //   <CardActionArea>
    //     {/* 🔹 이벤트 활성 상태일 때 배지 표시 */}
    //     {isEventActive && (
    //       <Box
    //         sx={{
    //           position: 'absolute',
    //           top: theme.spacing(1),
    //           left: theme.spacing(1),
    //           backgroundColor: '#FF5722 !important', //theme.palette.secondary.main,
    //           color: theme.palette.common.white,
    //           borderRadius: theme.shape.borderRadius,
    //           px: 1.5,
    //           py: 0.5,
    //           display: 'flex',
    //           alignItems: 'center',
    //           gap: 0.5,
    //         }}
    //       >
    //         <img
    //           src={weatherEvent.iconUrl}
    //           alt={weatherEvent.name[i18n.language]}
    //           style={{ width: 20, height: 20, borderRadius: '50%' }}
    //         />

    //         <Typography variant="body2">
    //           {weatherEvent.discount}% OFF
    //         </Typography>
    //       </Box>
    //     )}
    //     {horizontal && isMobile ? (
    //       <Box display="flex" flexDirection="row" sx={{ height: '170px' }}>
    //         <Box
    //           sx={{
    //             width: '40%',
    //             height: '100%',
    //             display: 'flex',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //           }}
    //         >
    //           <CardMedia
    //             component="img"
    //             src={imageUrl}
    //             alt={product.productName}
    //             sx={{
    //               width: '100%',
    //               height: '100%',
    //               objectFit: 'cover',
    //               aspectRatio: pcAspectRatio,
    //               borderRadius: 0, //theme.shape.borderRadius,
    //             }}
    //           />
    //         </Box>
    //         <CardContent
    //           sx={{
    //             width: '60%',
    //             padding: '8px',
    //             height: '100%',
    //             display: 'flex',
    //             flexDirection: 'column',
    //             justifyContent: 'center',
    //             marginTop: 'auto',
    //             marginBottom: 'auto',
    //             color: theme.palette.text.primary,
    //           }}
    //         >
    //           <Typography gutterBottom variant="h6" component="div" noWrap>
    //             {product.productName}
    //           </Typography>
    //           <Typography variant="body2" color="text.secondary" noWrap>
    //             {product.description}
    //           </Typography>
    //           <Typography variant="body2" noWrap>
    //             {product.stock} {t('left')}
    //           </Typography>
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'space-between',
    //               mt: 0,
    //             }}
    //           >
    //             <Typography variant="body2" component="span">
    //               {formattedDate}
    //             </Typography>
    //             <Chip
    //               icon={formatState(product.state).icon}
    //               label={formatState(product.state).text}
    //               color={formatState(product.state).color}
    //               size="small"
    //               variant="outlined"
    //             />
    //           </Box>
    //           <WeatherEventDisplay
    //             weatherEvent={weatherEvent}
    //             isEventActive={isEventActive}
    //           />
    //           <WeatherPriceDisplay
    //             product={product}
    //             weatherEvent={weatherEvent}
    //             isEventActive={isEventActive}
    //           />
    //         </CardContent>
    //       </Box>
    //     ) : (
    //       <>
    //         <CardMedia
    //           component={RouterLink}
    //           to={`/product_detail/${product.uid}/${product.id}`}
    //           sx={{
    //             height: 0,
    //             // paddingTop: '56.25%',
    //             backgroundImage: `url(${imageUrl})`,
    //             backgroundSize: 'cover',
    //             backgroundPosition: 'center',
    //             aspectRatio: pcAspectRatio,
    //             borderRadius: theme.shape.borderRadius,
    //           }}
    //           alt={product.productName}
    //         />
    //         <CardContent
    //           sx={{ padding: '16px', color: theme.palette.text.primary }}
    //         >
    //           <Typography gutterBottom variant="h6" component="div" noWrap>
    //             {product.productName}
    //           </Typography>
    //           <Typography variant="body2" color="text.secondary" noWrap>
    //             {product.description}
    //           </Typography>
    //           <Typography variant="body2" noWrap>
    //             {product.stock} {t('left')}
    //           </Typography>
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'space-between',
    //               mt: 1,
    //               mb: 2,
    //             }}
    //           >
    //             <Typography variant="body2" component="span">
    //               {formattedDate}
    //             </Typography>
    //             <Chip
    //               icon={formatState(product.state).icon}
    //               label={formatState(product.state).text}
    //               color={formatState(product.state).color}
    //               size="small"
    //               variant="outlined"
    //             />
    //           </Box>
    //           <WeatherEventDisplay
    //             weatherEvent={weatherEvent}
    //             isEventActive={isEventActive}
    //           />
    //           <WeatherPriceDisplay
    //             product={product}
    //             weatherEvent={weatherEvent}
    //             isEventActive={isEventActive}
    //           />
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'space-between',
    //               mt: 2,
    //             }}
    //           >
    //             <Typography variant="body1" component="span">
    //               {formatPrice(product.price)} {t('currency')}
    //             </Typography>
    //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //               <Tooltip title="Likes">
    //                 <IconButton aria-label="add to favorites" size="small">
    //                   <FavoriteIcon />
    //                   <Typography variant="caption" sx={{ ml: 0.5 }}>
    //                     {product.likes}
    //                   </Typography>
    //                 </IconButton>
    //               </Tooltip>
    //               <Tooltip title="Views">
    //                 <IconButton aria-label="view count" size="small">
    //                   <VisibilityIcon />
    //                   <Typography variant="caption" sx={{ ml: 0.5 }}>
    //                     {product.views}
    //                   </Typography>
    //                 </IconButton>
    //               </Tooltip>
    //               <Tooltip title="Chat">
    //                 <IconButton aria-label="chat count" size="small">
    //                   <ChatIcon />
    //                   <Typography variant="caption" sx={{ ml: 0.5 }}>
    //                     {product.chats}
    //                   </Typography>
    //                 </IconButton>
    //               </Tooltip>
    //             </Box>
    //           </Box>
    //         </CardContent>
    //       </>
    //     )}
    //   </CardActionArea>
    // </Card>
  );
};

export default StoreProduct;
