// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate } from "react-router-dom";
// import { getMediaType, isImageURL, isVideoURL, MEDIA_TYPES } from './Utils/FileHelper';
// import './css/ImageSlider.css';

// // function ImageSlider({ images }) {
// //     const [currentIndex, setCurrentIndex] = useState(0);
// //     const sliderRef = useRef(null);

// //     useEffect(() => {
// //         if (sliderRef.current) {
// //             sliderRef.current.style.transition = 'transform 0.5s';
// //             sliderRef.current.style.transform = `translateX(-${currentIndex * 100}%)`;
// //         }
// //     }, [currentIndex]);

// //     const nextSlide = () => {
// //         setCurrentIndex((prev) => (prev + 1) % images.length);
// //     };

// //     const prevSlide = () => {
// //         setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
// //     };

// //     return (
// //         <div className="slider"
// //             ref={sliderRef} >
// //             <button onClick={prevSlide} className="slider-button prev">&#10094;</button>
// //             <div className="image-slider">
// //                 <img src={images[currentIndex]} alt={`Image ${currentIndex}`} className="slider-image" draggable="false" />
// //             </div>
// //             <button onClick={nextSlide} className="slider-button next">&#10095;</button>
// //             <div className="dots">
// //                 {images.map((_, index) => (
// //                     <span
// //                         key={index}
// //                         className={index === currentIndex ? 'dot active' : 'dot'}
// //                     ></span>
// //                 ))}
// //             </div>
// //         </div>

// //     );
// // }

// // function ImageSlider({ images }) {
// //     const [currentIndex, setCurrentIndex] = useState(0);

// //     const nextSlide = () => {
// //         setCurrentIndex((currentIndex + 1) % images.length);
// //     };

// //     const prevSlide = () => {
// //         setCurrentIndex((currentIndex - 1 + images.length) % images.length);
// //     };

// //     return (
// //         <div className="slider">
// //             <button onClick={prevSlide} className="slider-button prev">&#10094;</button>
// //             <div className="image-slider">
// //                 <img
// //                     src={images[(currentIndex - 1 + images.length) % images.length]}
// //                     alt={`Image ${currentIndex - 1}`}
// //                     className={`slider-image ${currentIndex === 0 ? 'active' : ''}`}
// //                 />
// //                 <img
// //                     src={images[currentIndex]}
// //                     alt={`Image ${currentIndex}`}
// //                     className={`slider-image active`}
// //                 />
// //                 <img
// //                     src={images[(currentIndex + 1) % images.length]}
// //                     alt={`Image ${currentIndex + 1}`}
// //                     className={`slider-image ${currentIndex === images.length - 1 ? 'active' : ''}`}
// //                 />
// //             </div>
// //             <button onClick={nextSlide} className="slider-button next">&#10095;</button>
// //             <div className="dots">
// //                 {images.map((_, index) => (
// //                     <span
// //                         key={index}
// //                         className={index === currentIndex ? 'dot active' : 'dot'}
// //                     ></span>
// //                 ))}
// //             </div>
// //         </div>
// //     );
// // }
// function ImageSlider({ activeIndex, images, fileClick }) {
//     const [currentIndex, setCurrentIndex] = useState(activeIndex || 0);
//     const length = images.length;
//     const navigate = useNavigate();

//     useEffect(() => {
//         setCurrentIndex(activeIndex || 0);
//     }, [activeIndex]);

//     const nextSlide = () => {
//         setCurrentIndex(currentIndex === length - 1 ? 0 : currentIndex + 1);
//     };

//     const prevSlide = () => {
//         setCurrentIndex(currentIndex === 0 ? length - 1 : currentIndex - 1);
//     };

//     const handleFileClick = (url) => {
//         const idx = images.findIndex(file => file === url);
//         fileClick(idx);
//     };

//     return (
//         <div className="slider">
//             <button onClick={prevSlide} className="slider-button prev">&#10094;</button>
//             <div className="image-slider" style={{
//                 // transform: `translateX(-${currentIndex * 100}%)`,
//                 transition: 'transform 1.5s ease',whiteSpace: 'nowrap'}}>

//                 {images.map((image, index) => (
//                     <div className={index === currentIndex ? 'slide active' : 'slide'} key={index}>
//                         {index === currentIndex && (
//                             <img
//                                 key={index}
//                                 src={image}
//                                 alt={`Image ${index}`}
//                                 className="slider-image"
//                                 onClick={() => handleFileClick(image)}
//                             />
//                         )}
//                     </div>
//                 ))}

//             </div>
//             <button onClick={nextSlide} className="slider-button next">&#10095;</button>
//             <div className="dots">
//                 {images.map((_, index) => (
//                     <span
//                         key={index}
//                         className={index === currentIndex ? 'dot active' : 'dot'}
//                         onClick={() => setCurrentIndex(index)}
//                     ></span>
//                 ))}
//             </div>
//         </div>
//     );
// }

// export default ImageSlider;

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Box, IconButton } from '@mui/material';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'; // Corrected icon name
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import './css/ImageSlider.css'; // 필요하다면 추가 CSS를 여기에 작성

// function ImageSlider({ activeIndex, images, fileClick, onIndexChange }) {
//   const [currentIndex, setCurrentIndex] = useState(activeIndex || 0);
//   const length = images.length;
//   const navigate = useNavigate();

//   useEffect(() => {
//     setCurrentIndex(activeIndex || 0);
//   }, [activeIndex]);

//   const nextSlide = () => {
//     const newIndex = currentIndex === length - 1 ? 0 : currentIndex + 1;
//     setCurrentIndex(newIndex);
//     onIndexChange(newIndex);
//   };

//   const prevSlide = () => {
//     const newIndex = currentIndex === 0 ? length - 1 : currentIndex - 1;
//     setCurrentIndex(newIndex);
//     onIndexChange(newIndex);
//   };

//   const handleFileClick = (url) => {
//     const idx = images.findIndex((file) => file === url);
//     fileClick(idx);
//   };

//   return (
//     <Box className="slider" sx={{ position: 'relative' }}>
//       <IconButton
//         onClick={prevSlide}
//         sx={{
//           position: 'absolute',
//           left: 16,
//           top: '50%',
//           transform: 'translateY(-50%)',
//           zIndex: 2,
//           color: 'white', // Change as needed
//           fontSize: '2rem', // Make the icon larger
//         }}
//       >
//         <ArrowBackIosIcon sx={{ fontSize: '3.5rem' }} />
//       </IconButton>
//       <Box
//         className="image-slider"
//         sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
//       >
//         {images.map((image, index) => (
//           <Box
//             key={index}
//             sx={{
//               display: 'inline-block',
//               width: '100%',
//               height: '100%',
//               verticalAlign: 'middle',
//             }}
//             className={index === currentIndex ? 'slide active' : 'slide'}
//           >
//             {index === currentIndex && (
//               <img
//                 src={image}
//                 alt={`Image ${index}`}
//                 className="slider-image"
//                 onClick={() => handleFileClick(image)}
//                 style={{ width: '100%', height: 'auto', display: 'block' }}
//               />
//             )}
//           </Box>
//         ))}
//       </Box>
//       <IconButton
//         onClick={nextSlide}
//         sx={{
//           position: 'absolute',
//           right: 16,
//           top: '50%',
//           transform: 'translateY(-50%)',
//           zIndex: 2,
//           color: 'white', // Change as needed
//           fontSize: '2rem', // Make the icon larger
//         }}
//       >
//         <ArrowForwardIosIcon sx={{ fontSize: '3.5rem' }} />
//       </IconButton>
//       <Box className="dots" sx={{ textAlign: 'center', pt: 2 }}>
//         {images.map((_, index) => (
//           <span
//             key={index}
//             className={index === currentIndex ? 'dot active' : 'dot'}
//             onClick={() => setCurrentIndex(index)}
//             style={{ cursor: 'pointer' }}
//           ></span>
//         ))}
//       </Box>
//     </Box>
//   );
// }

// export default ImageSlider;

// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Box, IconButton } from '@mui/material';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import './css/ImageSlider.css'; // Ensure your CSS is appropriately applied

// function ImageSlider({ activeIndex, images, fileClick, onIndexChange }) {
//   const [currentIndex, setCurrentIndex] = useState(activeIndex || 0);
//   const length = images.length;
//   const navigate = useNavigate();
//   const touchStartX = useRef(0);
//   const touchEndX = useRef(0);

//   useEffect(() => {
//     setCurrentIndex(activeIndex || 0);
//   }, [activeIndex]);

//   const nextSlide = () => {
//     const newIndex = currentIndex === length - 1 ? 0 : currentIndex + 1;
//     setCurrentIndex(newIndex);
//     onIndexChange(newIndex);
//   };

//   const prevSlide = () => {
//     const newIndex = currentIndex === 0 ? length - 1 : currentIndex - 1;
//     setCurrentIndex(newIndex);
//     onIndexChange(newIndex);
//   };

//   const handleFileClick = (url) => {
//     const idx = images.findIndex((file) => file === url);
//     fileClick(idx);
//   };

//   const handleDotClick = (index) => {
//     setCurrentIndex(index);
//     onIndexChange(index);
//   };
//   const handleTouchStart = (e) => {
//     touchStartX.current = e.touches[0].clientX;
//   };

//   const handleTouchMove = (e) => {
//     touchEndX.current = e.touches[0].clientX;
//   };

//   const handleTouchEnd = () => {
//     if (touchStartX.current - touchEndX.current > 50) {
//       nextSlide();
//     } else if (touchStartX.current - touchEndX.current < -50) {
//       prevSlide();
//     }
//   };
//   return (
//     <Box
//       className="slider"
//       sx={{
//         position: 'relative',
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//       }}
//       onTouchStart={handleTouchStart}
//       onTouchMove={handleTouchMove}
//       onTouchEnd={handleTouchEnd}
//     >
//       <IconButton
//         onClick={prevSlide}
//         sx={{
//           position: 'absolute',
//           left: 16,
//           top: '50%',
//           transform: 'translateY(-50%)',
//           zIndex: 2,
//           color: 'common.white', // Better visibility
//           fontSize: '2rem',
//         }}
//       >
//         <ArrowBackIosIcon sx={{ fontSize: 'inherit' }} />
//       </IconButton>
//       <Box className="image-slider" sx={{ overflow: 'hidden', width: '100%' }}>
//         {images.map((image, index) => (
//           <Box
//             key={index}
//             sx={{
//               display: 'inline-block',
//               width: '100%',
//               verticalAlign: 'middle',
//             }}
//             className={index === currentIndex ? 'slide active' : 'slide'}
//           >
//             {index === currentIndex && (
//               <img
//                 src={image}
//                 alt={`slide ${index + 1}`}
//                 className="slider-image"
//                 onClick={() => handleFileClick(image)}
//                 style={{ width: '100%', display: 'block' }}
//               />
//             )}
//           </Box>
//         ))}
//       </Box>
//       <IconButton
//         onClick={nextSlide}
//         sx={{
//           position: 'absolute',
//           right: 16,
//           top: '50%',
//           transform: 'translateY(-50%)',
//           zIndex: 2,
//           color: 'common.white', // Better visibility
//           fontSize: '2rem',
//         }}
//       >
//         <ArrowForwardIosIcon sx={{ fontSize: 'inherit' }} />
//       </IconButton>
//       <Box className="dots" sx={{ textAlign: 'center', pt: 2 }}>
//         {images.map((_, index) => (
//           <span
//             key={index}
//             className={index === currentIndex ? 'dot active' : 'dot'}
//             onClick={() => handleDotClick(index)}
//             style={{ cursor: 'pointer' }}
//           ></span>
//         ))}
//       </Box>
//     </Box>
//   );
// }

// export default ImageSlider;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './css/ImageSlider.css'; // Ensure your CSS is appropriately applied
import { useGlobalContext } from './useGlobalContext';

function ImageSlider({ activeIndex, images, fileClick, onIndexChange }) {
  const [currentIndex, setCurrentIndex] = useState(activeIndex || 0);
  const theme = useTheme();
  const [dragShift, setDragShift] = useState(0); // Track drag offset
  const touchStartX = useRef(0);
  const isDragging = useRef(false);

  const length = images.length;
  const navigate = useNavigate();
  const startPosition = useRef(0);
  const endPosition = useRef(0);
  const sliderRef = useRef(null);

  const {
    defaultAspectRatio,
    pcAspectRatio,
    padAspectRatio,
    mobileAspectRatio,
    isMobile,
    isPad,
    isPc,
  } = useGlobalContext();

  useEffect(() => {
    setCurrentIndex(activeIndex || 0);
    setDragShift(0);
  }, [activeIndex]);

  const nextSlide = () => {
    const newIndex = currentIndex === length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    onIndexChange(newIndex);
  };

  const prevSlide = () => {
    const newIndex = currentIndex === 0 ? length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    onIndexChange(newIndex);
  };

  // Touch handlers
  const handleTouchStart = (e) => {
    startPosition.current = e.touches[0].clientX;
    isDragging.current = false;
  };

  const handleTouchMove = (e) => {
    endPosition.current = e.touches[0].clientX;
    isDragging.current = true;
  };

  const handleTouchEnd = () => {
    if (isDragging.current) {
      handleGesture();
    }
  };
  const handleFileClick = (url) => {
    const idx = images.findIndex((file) => file === url);
    fileClick(idx);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    onIndexChange(index);
  };

  // Mouse handlers
  const handleMouseDown = (e) => {
    if (
      e.target === sliderRef.current ||
      sliderRef.current.contains(e.target)
    ) {
      startPosition.current = e.clientX;
      isDragging.current = false;
    }
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 1) {
      // Check if the left mouse button is pressed
      if (Math.abs(e.clientX - startPosition.current) > 5) {
        // Ensure some minimal movement before flagging as drag
        isDragging.current = true;
        endPosition.current = e.clientX;
      }
    }
  };
  const handleMouseUp = (e) => {
    if (
      isDragging.current &&
      (e.target === sliderRef.current || sliderRef.current.contains(e.target))
    ) {
      handleGesture();
    }
    isDragging.current = false;
  };

  const handleGesture = () => {
    if (Math.abs(startPosition.current - endPosition.current) > 50) {
      if (startPosition.current > endPosition.current) {
        nextSlide();
      } else {
        prevSlide();
      }
    }
  };

  const handleDragStart = (e) => {
    touchStartX.current =
      typeof e.clientX === 'undefined' ? e.touches[0].clientX : e.clientX;
    // isDragging.current = true;
    e.preventDefault();
  };

  const handleDragging = (e) => {
    isDragging.current = true;
    if (isDragging.current) {
      const currentX =
        typeof e.clientX === 'undefined' ? e.touches[0].clientX : e.clientX;
      const shift = currentX - touchStartX.current;
      setDragShift(shift);
    }
  };

  const handleDragEnd = () => {
    if (isDragging.current) {
      if (Math.abs(dragShift) > window.innerWidth / 4) {
        // Threshold to decide if it should snap to next/prev image
        if (dragShift > 0) {
          prevSlide(); // Show previous image if drag left to right
        } else {
          nextSlide(); // Show next image if drag right to left
        }
      }
      setDragShift(0); // Reset drag shift
    }
    isDragging.current = false;
  };
  return (
    <Box
      className="slider"
      ref={sliderRef}
      sx={{
        position: 'relative',
        width: '100%',
        maxWidth: '100%',
        margin: 'auto',

        overflow: 'hidden',
        userSelect: 'none',
        cursor: 'grab',
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      // onMouseLeave={handleMouseUp}
      onMouseLeave={() => (isDragging.current = false)}
      // onMouseDown={handleDragStart}
      // onMouseMove={handleDragging}
      // onMouseUp={handleDragEnd}
      // onMouseLeave={handleDragEnd}
      // onTouchStart={handleDragStart}
      // onTouchMove={handleDragging}
      // onTouchEnd={handleDragEnd}
    >
      <IconButton
        onClick={prevSlide}
        sx={{
          position: 'absolute',
          left: 16,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2,
          color: 'common.white', // Better visibility
          fontSize: '2rem',
        }}
      >
        <ArrowBackIosIcon sx={{ fontSize: 'inherit' }} />
      </IconButton>
      {/* <Box className="image-slider" sx={{ overflow: 'hidden', width: '100%' }}>
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              display: 'inline-block',
              width: '100%',
              verticalAlign: 'middle',
            }}
            className={index === currentIndex ? 'slide active' : 'slide'}
          >
            {index === currentIndex && (
              <img
                src={image}
                alt={`slide ${index + 1}`}
                className="slider-image"
                onClick={() => handleFileClick(image)}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  aspectRatio: mobileAspectRatio, // 예: "3 / 4"
                  display: 'block',
                }}
                draggable="false"
              />
            )}
          </Box>
        ))}
      </Box> */}
      <Box
        className="image-slider"
        sx={{
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          aspectRatio: isPad
            ? padAspectRatio
            : isMobile
            ? mobileAspectRatio
            : pcAspectRatio,
          // borderRadius: theme.shape.borderRadius,
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              display: index === currentIndex ? 'block' : 'none',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            className={index === currentIndex ? 'slide active' : 'slide'}
          >
            <Box
              component="img"
              src={image}
              alt={`slide ${index + 1}`}
              onClick={() => handleFileClick(image)}
              draggable={false}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                display: 'block',
              }}
              className="slider-image"
            />
          </Box>
        ))}
      </Box>
      <IconButton
        onClick={nextSlide}
        sx={{
          position: 'absolute',
          right: 16,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2,
          color: 'common.white', // Better visibility
          fontSize: '2rem',
        }}
      >
        <ArrowForwardIosIcon sx={{ fontSize: 'inherit' }} />
      </IconButton>
      <Box className="dots" sx={{ textAlign: 'center', pt: 2 }}>
        {images.map((_, index) => (
          <span
            key={index}
            className={index === currentIndex ? 'dot active' : 'dot'}
            onClick={() => handleDotClick(index)}
            style={{ cursor: 'pointer' }}
          ></span>
        ))}
      </Box>
    </Box>
  );
}

export default ImageSlider;
