const serverUrl =
  'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/chatFilterServerAPI';

export const getMyIP = async () => {
  try {
    // AbortController로 요청 타임아웃 설정 (5초)
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${serverUrl}/api/getMyIP`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal: controller.signal,
    });

    // 타임아웃 클리어
    clearTimeout(timeoutId);

    // 응답 확인
    if (!response.ok) {
      console.error(
        `Failed to fetch IP. Status: ${response.status} - ${response.statusText}`
      );
      return null; // 적절한 기본값 반환
    }

    const data = await response.json();

    // 응답 형식 검증
    if (
      !data ||
      typeof data.userIP !== 'string' ||
      !/^\d{1,3}(\.\d{1,3}){3}$/.test(data.userIP)
    ) {
      console.error('Invalid response format or IP address:', data);
      return null; // 기본값 반환
    }

    return data.userIP;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.error('Request timed out while fetching IP');
    } else {
      console.error('Error fetching IP:', error.message || error);
    }
    return null; // 기본값 반환
  }
};
