import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './AutoCompleteSearch.css'; // Ensure to create and import your CSS file

function AutoCompleteSearch({ query }) {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (query.length > 0) {
      // Fetch autocomplete suggestions based on the query
      // Replace this with your actual API call or logic to fetch suggestions
      fetchSuggestions(query);
    } else {
      setSuggestions([]);
    }
  }, [query]);

  const fetchSuggestions = async (query) => {
    // Simulating API call with a timeout
    // Replace with actual API call
    const fakeSuggestions = [
      'apple',
      'banana',
      'orange',
      'grape',
      'pineapple',
      'mango',
    ].filter((item) => item.includes(query));

    setSuggestions(fakeSuggestions);
  };

  return (
    <div className="autocomplete-search">
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} className="suggestion-item">
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

AutoCompleteSearch.propTypes = {
  query: PropTypes.string.isRequired,
};

export default AutoCompleteSearch;
