// import React, { useState, useEffect } from 'react';
// import { GoogleMap, useLoadScript, Circle, Polygon, DrawingManager, Marker } from "@react-google-maps/api";
// import GoogleMapReact from 'google-map-react';
// // import "./css/upload.css";
// import '../Stores/css/SignUpFlow.css'
// const libraries = ["geometry", "drawing", "places"];

// const PolygonOverlay = ({ vertices }) => {
//     if (!Array.isArray(vertices)) return null;
//     const pathData = vertices.map((vertex, index) => {
//         const command = (index === 0) ? 'M' : 'L'; // MoveTo for the first point, LineTo for the rest
//         return `${command} ${vertex.lat},${vertex.lng}`;
//     }).join(' ') + 'Z'; // Close the path

//     return (
//         <path d={pathData} fill="blue" fillOpacity="0.3" stroke="black" strokeWidth="2" />
//     );
// };

// const Region = ({ onSave, selectedArea, mode }) => {
//     console.log("Mode in Region:", mode);
//     const [map, setMap] = useState(null);

//     const [center, setCenter] = useState(null);
//     const [drawingManager, setDrawingManager] = useState(null);
//     const [polygonObject, setPolygonObject] = useState(null);
//     const [polygonCoords, setPolygonCoords] = useState(Array.isArray(selectedArea) ? selectedArea : []);
//     const [markerPosition, setMarkerPosition] = useState({ lat: 37.5642135, lng: 127.0016985 }); // Default to Seoul
//     const [message, setMessage] = useState('');
//     const [error, setError] = useState('');

//     const onMarkerPlace = (event) => {
//         if (mode === 'marker') {
//             const coords = event.latLng.toJSON();
//             setPolygonCoords([coords]);
//             onSave([coords]);
//         }
//     };

//     // Load the Google Maps scripts
//     const { isLoaded, loadError } = useLoadScript({
//         googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//         libraries,
//     });

//     // useEffect(() => {
//     //     // 맵 초기화

//     //     return () => {
//     //         // 컴포넌트가 언마운트 될 때 실행됨
//     //         const mapDiv = document.getElementById("map-container");
//     //         mapDiv && mapDiv.parentNode.removeChild(mapDiv);
//     //     };
//     // }, []);

//     useEffect(() => {

//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(position => {

//                 const newPosition = {
//                     lat: position.coords.latitude,
//                     lng: position.coords.longitude
//                 };
//                 console.log("User position retrieved:", newPosition);
//                 setCenter(newPosition);
//                 setMarkerPosition(newPosition);
//                 // onSave(newPosition);
//             }, error => {
//                 console.error("Error retrieving user's location:", error);
//                 setCenter({ lat: 37.5642135, lng: 127.0016985 }); // default to Seoul if error occurs
//             });
//         } else {
//             // Geolocation is not supported by this browser.
//             console.warn("Geolocation is not supported by this browser.");
//             setCenter({ lat: 37.5642135, lng: 127.0016985 }); // default to Seoul
//         }
//         // setMessage('위치를 지정한 후 스토어설정 버튼을 클릭하여 완료해주세요.')
//         // return () => {
//         //     // 컴포넌트가 언마운트 될 때 실행됨
//         //     console.log('Google Map is Unmounted');
//         //     const mapDiv = document.getElementById("map-container");
//         //     mapDiv && mapDiv.parentNode.removeChild(mapDiv);
//         // };
//     }, []);

//     // 사용자의 위치를 가져와 markerPosition에 저장
//     useEffect(() => {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(position => {
//                 const newPosition = {
//                     lat: position.coords.latitude,
//                     lng: position.coords.longitude
//                 };
//                 console.log("User position retrieved:", newPosition);
//                 if (map && mode === 'marker') {
//                     setMarkerPosition(newPosition);
//                     // onSave(newPosition); // 바로 여기에서 newPosition을 전달합니다.
//                 }
//             }, error => {
//                 console.error("Error retrieving user's location:", error);
//             });
//         }
//     }, [mode]);

//     useEffect(() => {
//         if (map) {

//             const clickListener = map.addListener('click', (event) => {
//                 const newPosition = event.latLng.toJSON();
//                 if (mode === 'marker') {

//                     setMarkerPosition(newPosition);
//                     onSave(newPosition);
//                     setMessage('완료 버튼을 클릭하여 위치 지정을 완료해주세요.')

//                 } else if (mode === 'region') {
//                     setPolygonCoords(prevCoords => [...prevCoords, newPosition]);

//                 }
//             });

//             return () => {
//                 window.google.maps.event.removeListener(clickListener);
//             };

//         }

//     }, [map, mode]);

//     // useEffect(() => {
//     //     if (map) {
//     //         const clickListener = map.addListener('click', (event) => {
//     //             setPolygonCoords(prevCoords => [...prevCoords, event.latLng.toJSON()]);
//     //         });

//     //         return () => {
//     //             window.google.maps.event.removeListener(clickListener);
//     //         };
//     //     }
//     // }, [map]);

//     // Inside your Region component
//     useEffect(() => {
//         if (map && polygonCoords.length) {
//             const polygon = new window.google.maps.Polygon({
//                 paths: polygonCoords,
//                 map: map,
//                 fillColor: "blue",
//                 fillOpacity: 0.3,
//                 strokeColor: "black",
//                 strokeWeight: 2,
//             });

//             // Cleanup: Remove the polygon from the map when the component unmounts or if polygonCoords changes.
//             return () => {
//                 polygon.setMap(null);
//             };
//         }
//     }, [map, polygonCoords]);

//     const onDrawingManagerLoad = (drawingMgr) => {
//         setDrawingManager(drawingMgr);
//         // 여기에서 그리기 모드를 강제로 설정
//         drawingMgr.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
//     };

//     const handleUserSelectRegion = () => {
//         drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
//     }
//     const handleNeighborhoodSelection = (level) => {
//         const R = 6371; // Earth radius in km
//         const options = {
//             timeout: 5000,
//             maximumAge: 0,
//             enableHighAccuracy: true
//         };

//         const onSuccess = (position) => {
//             const lat = parseFloat(position.coords.latitude);
//             const lng = parseFloat(position.coords.longitude);
//             const distance = level; // distance in km

//             // Calculating the bounding box
//             const deltaLat = (distance / R) * (180 / Math.PI);
//             const deltaLng = (distance / R) * (180 / Math.PI) / Math.cos(lat * Math.PI / 180);

//             // Define polygon vertices
//             const vertices = [
//                 { lat: lat + deltaLat, lng: lng - deltaLng },  // North-West
//                 { lat: lat + deltaLat, lng: lng + deltaLng },  // North-East
//                 { lat: lat - deltaLat, lng: lng + deltaLng },  // South-East
//                 { lat: lat - deltaLat, lng: lng - deltaLng }   // South-West
//             ];

//             setPolygonCoords(vertices);
//         };

//         const onError = (error) => {
//             console.error("Error retrieving the position:", error);
//             alert("We couldn't retrieve your location. Please ensure you have location services enabled and try again.");
//         };

//         navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
//     };

//     const onPolygonComplete = (newPolygon) => {
//         // 기존의 영역이 있으면 삭제
//         if (polygonCoords && polygonCoords.length > 0) {
//             removeExistingPolygon();
//         }

//         const newCoords = newPolygon.getPath().getArray().map(coord => {
//             return { lat: coord.lat(), lng: coord.lng() };
//         });
//         setPolygonCoords(newCoords);

//         onSave(newCoords);

//         setMessage('영역 지정이 되었습니다.')
//     };

//     // 기존 폴리곤 삭제 함수
//     const removeExistingPolygon = () => {
//         if (polygonObject) { // polygonInstance는 google.maps.Polygon 객체입니다.
//             polygonObject.setMap(null);
//         }
//         setPolygonObject(null)
//         setPolygonCoords([]); // 상태 초기화
//     };

//     if (loadError) return "Error loading Google Maps";
//     if (!isLoaded) return "Loading Google Maps";

//     if (!center) {
//         return <div>Loading...</div>;
//     }
//     return (
//         <>

//             {
//                 <div className='signup-flow'>
//                     {message && <p className="message">{message}</p>}
//                     {error && <div style={{ color: 'red' }}>{error}</div>}
//                     <div className="map-container">
//                         {/* { <GoogleMapReact
//                         id="map"
//                         defaultCenter={center}
//                         defaultZoom={15}
//                         mapContainerClassName="map-container"
//                         center={polygonCoords[0] || center}
//                         onLoad={setMap}
//                         mapContainerStyle={{ width: "100%", height: "80%" }}
//                     >
//                         {mode === 'region' && (
//                             <>
//                                 <Polygon paths={polygonCoords} />
//                                 <DrawingManager
//                                     onLoad={onDrawingManagerLoad}
//                                     onPolygonComplete={onPolygonComplete}
//                                     options={{
//                                         drawingControl: true,
//                                         drawingControlOptions: {
//                                             drawingModes: ["polygon"],
//                                         },
//                                         polygonOptions: {
//                                             editable: true,
//                                             draggable: true,
//                                         },
//                                     }}
//                                 />
//                             </>
//                         )}
//                         {mode === 'marker' && markerPosition && <Marker position={markerPosition} title='User Marker'/>}
//                         <PolygonOverlay vertices={polygonCoords} />
//                     </GoogleMapReact>} */}

//                         <GoogleMap
//                             id="map"
//                             mapContainerClassName="map-container"
//                             center={polygonCoords[0] || center}
//                             zoom={14}
//                             onLoad={setMap}
//                             mapContainerStyle={{ width: "100%", height: "80%" }}
//                         >
//                             {mode === 'region' && (
//                                 <>
//                                     {polygonCoords && <Polygon paths={polygonCoords} />}

//                                     <DrawingManager
//                                         onLoad={onDrawingManagerLoad}
//                                         onPolygonComplete={onPolygonComplete}
//                                         options={{
//                                             drawingControl: true,
//                                             drawingControlOptions: {
//                                                 drawingModes: ["polygon"],
//                                             },
//                                             polygonOptions: {
//                                                 editable: true,
//                                                 draggable: true,
//                                             },
//                                         }}
//                                     />

//                                     {polygonCoords && <PolygonOverlay vertices={polygonCoords} />}
//                                 </>
//                             )}
//                             {mode === 'marker' && markerPosition && <Marker position={markerPosition} title='User Marker' />}
//                         </GoogleMap>

//                         {/* {<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
//                         <div>
//                             <button className="btn btn-secondary mt-1" onClick={() => handleUserSelectRegion}>직접 영역 지정</button>
//                             <button className="btn btn-info mt-1" onClick={() => handleNeighborhoodSelection(1)}>가까운 동네</button>
//                             <button className="btn btn-info mt-1" onClick={() => handleNeighborhoodSelection(2)}>조금 먼 동네</button>
//                             <button className="btn btn-info mt-1" onClick={() => handleNeighborhoodSelection(3)}>더 먼 동네</button>
//                             <button className="btn btn-info mt-1" onClick={() => handleNeighborhoodSelection(4)}>제일 먼 동네</button>
//                         </div>
//                         <button onClick={handleSave} className="btn btn-primary mt-1"> 동네선택완료</button>
//                     </div>} */}

//                     </div>
//                 </div>
//             }
//         </>
//     );
// };

// export default Region;

// import React, { useState, useEffect } from 'react';
// import {
//   GoogleMap,
//   Marker,
//   Polygon,
//   DrawingManager,
// } from '@react-google-maps/api';
// import { Box, Button, Typography } from '@mui/material';
// import { useLoadScript } from '@react-google-maps/api';

// const libraries = ['places', 'geometry', 'drawing'];

// const Region = ({ onSave, mode }) => {
//   const [map, setMap] = useState(null);
//   const [center, setCenter] = useState({ lat: 37.5642135, lng: 127.0016985 }); // Default center Seoul
//   const [polygonCoords, setPolygonCoords] = useState([]);
//   const [message, setMessage] = useState('');

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//     libraries,
//   });

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         setCenter({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         });
//       },
//       () => {
//         console.error('Error: Unable to retrieve your location');
//       }
//     );
//   }, []);

//   const onMapLoad = (mapInstance) => {
//     setMap(mapInstance);
//   };

//   const onPolygonComplete = (polygon) => {
//     const path = polygon
//       .getPath()
//       .getArray()
//       .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));
//     setPolygonCoords(path);
//     onSave(path);
//     polygon.setMap(null); // Optionally remove the polygon after selection
//   };

//   if (loadError)
//     return <Typography variant="body1">Error loading maps</Typography>;
//   if (!isLoaded)
//     return <Typography variant="body1">Loading Maps...</Typography>;

//   return (
//     <Box sx={{ height: 400, width: '100%' }}>
//       <GoogleMap
//         mapContainerStyle={{ width: '100%', height: '100%' }}
//         center={center}
//         zoom={15}
//         onLoad={onMapLoad}
//       >
//         {mode === 'marker' && (
//           <Marker
//             position={center}
//             draggable={true}
//             onDragEnd={(e) =>
//               onSave({ lat: e.latLng.lat(), lng: e.latLng.lng() })
//             }
//           />
//         )}
//         {mode === 'region' && (
//           <DrawingManager
//             onPolygonComplete={onPolygonComplete}
//             options={{
//               drawingControl: true,
//               drawingControlOptions: {
//                 position: window.google.maps.ControlPosition.TOP_CENTER,
//                 drawingModes: ['polygon'],
//               },
//               polygonOptions: {
//                 fillColor: '#1a73e8',
//                 fillOpacity: 0.1,
//                 strokeWeight: 2,
//                 strokeColor: '#1a73e8',
//                 clickable: true,
//                 editable: true,
//                 draggable: true,
//                 zIndex: 1,
//               },
//             }}
//           />
//         )}
//       </GoogleMap>
//       <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
//         <Button onClick={() => setPolygonCoords([])} variant="outlined">
//           Reset Area
//         </Button>
//         <Typography>{message}</Typography>
//       </Box>
//     </Box>
//   );
// };

// export default Region;

// import React, { useState, useEffect } from 'react';
// import {
//   GoogleMap,
//   Marker,
//   Polygon,
//   DrawingManager,
// } from '@react-google-maps/api';
// import {
//   Box,
//   Button,
//   Typography,
//   ToggleButton,
//   ToggleButtonGroup,
//   Stack,
// } from '@mui/material';
// import { useLoadScript } from '@react-google-maps/api';

// const libraries = ['places', 'geometry', 'drawing'];

// const Region = ({ onSave, mode }) => {
//   const [map, setMap] = useState(null);
//   const [center, setCenter] = useState({ lat: 37.5642135, lng: 127.0016985 });
//   const [currentMode, setCurrentMode] = useState('polygon');
//   const [polygonCoords, setPolygonCoords] = useState([]);
//   const [activePolygon, setActivePolygon] = useState(null);
//   const [drawingManager, setDrawingManager] = useState(null);
//   const [message, setMessage] = useState('');

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//     libraries,
//   });

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         setCenter({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         });
//       },
//       () => console.error('Error: Unable to retrieve your location'),
//       { enableHighAccuracy: true }
//     );
//   }, []);

//   const onMapLoad = (mapInstance) => {
//     setMap(mapInstance);
//   };

//   const onPolygonComplete = (polygon) => {
//     // Remove the previous polygon if it exists
//     resetPolygon();
//     setActivePolygon(polygon);
//     const path = polygon
//       .getPath()
//       .getArray()
//       .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));
//     setPolygonCoords(path);
//     onSave(path);

//     setMessage('영역 지정이 완료되었습니다.');
//   };

//   const handleModeChange = (event, newMode) => {
//     if (newMode !== null) {
//       setCurrentMode(newMode);
//       if (newMode === 'normal' && drawingManager) {
//         drawingManager.setMap(null); // Hide DrawingManager when not in polygon mode
//       }
//     }
//   };
//   const toggleDrawingManager = () => {
//     if (currentMode === 'polygon' && map) {
//       if (!drawingManager) {
//         const manager = new window.google.maps.drawing.DrawingManager({
//           drawingControl: true,
//           drawingControlOptions: {
//             position: window.google.maps.ControlPosition.TOP_CENTER,
//             drawingModes: ['polygon'],
//           },
//           polygonOptions: {
//             fillColor: '#1a73e8',
//             fillOpacity: 0.1,
//             strokeWeight: 2,
//             clickable: true,
//             editable: true,
//             draggable: true,
//           },
//         });
//         manager.setMap(map);
//         manager.addListener('polygoncomplete', onPolygonComplete);
//         setDrawingManager(manager);
//       } else {
//         drawingManager.setMap(map); // Re-enable DrawingManager if it was previously instantiated
//       }
//     } else {
//       if (drawingManager) {
//         drawingManager.setMap(null); // 폴리곤 모드가 아닐 때 드로잉 매니저를 맵에서 제거
//       }
//     }
//   };

//   useEffect(() => {
//     toggleDrawingManager();
//   }, [currentMode, map]); // Recheck when mode changes or map loads

//   const resetPolygon = () => {
//     if (activePolygon) {
//       activePolygon.setMap(null);
//     }
//     setPolygonCoords([]);
//     setActivePolygon(null);

//     setMessage('영역이 초기화되었습니다.');
//   };

//   if (loadError) {
//     return <Typography variant="body1">Error loading maps</Typography>;
//   }

//   if (!isLoaded) {
//     return <Typography variant="body1">Loading Maps...</Typography>;
//   }

//   return (
//     <Box sx={{ height: '50vh', width: '100%' }}>
//       {/* <ToggleButtonGroup
//         color="primary"
//         value={currentMode}
//         exclusive
//         onChange={handleModeChange}
//         fullWidth
//         sx={{ marginBottom: 2 }}
//       >
//         <ToggleButton value="normal">Normal Mode</ToggleButton>
//         <ToggleButton value="polygon">Polygon Mode</ToggleButton>
//       </ToggleButtonGroup> */}

//       <GoogleMap
//         mapContainerStyle={{ width: '100%', height: '100%' }}
//         center={center}
//         zoom={15}
//         onLoad={onMapLoad}
//       >
//         {mode === 'marker' && (
//           <Marker
//             position={center}
//             draggable={true}
//             onDragEnd={(e) =>
//               onSave({ lat: e.latLng.lat(), lng: e.latLng.lng() })
//             }
//           />
//         )}
//         {mode === 'region' && (
//           <DrawingManager
//             onPolygonComplete={onPolygonComplete}
//             options={{
//               drawingControl: true,
//               drawingControlOptions: {
//                 position: window.google.maps.ControlPosition.TOP_CENTER,
//                 drawingModes: ['polygon'],
//               },
//               polygonOptions: {
//                 fillColor: '#1a73e8',
//                 fillOpacity: 0.1,
//                 strokeWeight: 2,
//                 strokeColor: '#1a73e8',
//                 clickable: true,
//                 editable: true,
//                 draggable: true,
//                 zIndex: 1,
//               },
//             }}
//           />
//         )}
//       </GoogleMap>
//       <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
//         <Button onClick={resetPolygon} variant="outlined">
//           Reset Area
//         </Button>
//         <Typography>{message}</Typography>
//       </Box>
//     </Box>
//   );
// };

// export default Region;

// import React, { useState, useEffect } from 'react';
// import {
//   GoogleMap,
//   Marker,
//   Polygon,
//   DrawingManager,
// } from '@react-google-maps/api';
// import {
//   Box,
//   Button,
//   Typography,
//   ToggleButton,
//   ToggleButtonGroup,
// } from '@mui/material';
// import { useLoadScript } from '@react-google-maps/api';

// const libraries = ['places', 'geometry', 'drawing'];

// const Region = ({ onSave, mode }) => {
//   const [map, setMap] = useState(null);
//   const [center, setCenter] = useState({ lat: 37.5642135, lng: 127.0016985 });
//   const [drawingMode, setDrawingMode] = useState('marker'); // region | marker
//   const [polygonCoords, setPolygonCoords] = useState([]);
//   const [activePolygon, setActivePolygon] = useState(null);
//   const [drawingManager, setDrawingManager] = useState(null);
//   const [message, setMessage] = useState('');

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//     libraries,
//   });

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         setCenter({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         });
//       },
//       () => console.error('Error: Unable to retrieve your location'),
//       { enableHighAccuracy: true }
//     );
//   }, []);

//   const onMapLoad = (mapInstance) => {
//     setMap(mapInstance);
//   };

//   const onPolygonComplete = (polygon) => {
//     // Remove the previous polygon if it exists
//     resetPolygon();
//     setActivePolygon(polygon);
//     const path = polygon
//       .getPath()
//       .getArray()
//       .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));
//     setPolygonCoords(path);
//     onSave(path);

//     setMessage('영역 지정이 완료되었습니다.');
//   };

//   const handleDrawingModeChange = (event, newDrawingMode) => {
//     if (newDrawingMode !== null) {
//       setDrawingMode(newDrawingMode);
//       if (newDrawingMode === 'marker' && drawingManager) {
//         drawingManager.setMap(null); // Hide DrawingManager when not in polygon mode
//       }
//     }
//   };

//   const toggleDrawingManager = () => {
//     if (drawingMode === 'region' && map) {
//       if (!drawingManager) {
//         const manager = new window.google.maps.drawing.DrawingManager({
//           drawingControl: true,
//           drawingControlOptions: {
//             position: window.google.maps.ControlPosition.TOP_CENTER,
//             drawingModes: ['polygon'],
//           },
//           polygonOptions: {
//             fillColor: '#1a73e8',
//             fillOpacity: 0.1,
//             strokeWeight: 2,
//             clickable: true,
//             editable: true,
//             draggable: true,
//           },
//         });
//         manager.setMap(map);
//         manager.addListener('polygoncomplete', onPolygonComplete);
//         setDrawingManager(manager);
//       } else {
//         drawingManager.setMap(map); // Re-enable DrawingManager if it was previously instantiated
//       }
//     } else {
//       if (drawingManager) {
//         drawingManager.setMap(null); // Remove DrawingManager from map when not in polygon mode
//       }
//     }
//   };

//   useEffect(() => {
//     toggleDrawingManager();
//   }, [drawingMode, map]); // Recheck when mode changes or map loads

//   const resetPolygon = () => {
//     if (activePolygon) {
//       activePolygon.setMap(null);
//     }
//     setPolygonCoords([]);
//     setActivePolygon(null);

//     setMessage('영역이 초기화되었습니다.');
//   };

//   if (loadError) {
//     return <Typography variant="body1">Error loading maps</Typography>;
//   }

//   if (!isLoaded) {
//     return <Typography variant="body1">Loading Maps...</Typography>;
//   }

//   return (
//     <Box sx={{ height: '60vh', width: '100%' }}>
//       <ToggleButtonGroup
//         color="primary"
//         value={drawingMode}
//         exclusive
//         onChange={handleDrawingModeChange}
//         fullWidth
//         sx={{ marginBottom: 2 }}
//       >
//         <ToggleButton value="normal">Normal Mode</ToggleButton>
//         <ToggleButton value="polygon">Polygon Mode</ToggleButton>
//       </ToggleButtonGroup>

//       <GoogleMap
//         mapContainerStyle={{ width: '100%', height: '100%' }}
//         center={center}
//         zoom={15}
//         onLoad={onMapLoad}
//       >
//         {drawingMode === 'marker' && (
//           <Marker
//             position={center}
//             draggable={true}
//             onDragEnd={(e) =>
//               onSave({ lat: e.latLng.lat(), lng: e.latLng.lng() })
//             }
//           />
//         )}
//         {drawingMode === 'region' && (
//           <DrawingManager
//             onPolygonComplete={onPolygonComplete}
//             options={{
//               drawingControl: true,
//               drawingControlOptions: {
//                 position: window.google.maps.ControlPosition.TOP_CENTER,
//                 drawingModes: ['polygon'],
//               },
//               polygonOptions: {
//                 fillColor: '#1a73e8',
//                 fillOpacity: 0.1,
//                 strokeWeight: 2,
//                 strokeColor: '#1a73e8',
//                 clickable: true,
//                 editable: true,
//                 draggable: true,
//                 zIndex: 1,
//               },
//             }}
//           />
//         )}
//       </GoogleMap>
//       {drawingMode === 'region' && (
//         <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
//           <Button onClick={resetPolygon} variant="outlined">
//             Reset Area
//           </Button>
//           <Typography>{message}</Typography>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default Region;

// import React, { useState, useEffect } from 'react';
// import { GoogleMap, Marker, DrawingManager } from '@react-google-maps/api';
// import {
//   Box,
//   Button,
//   Typography,
//   ToggleButton,
//   ToggleButtonGroup,
// } from '@mui/material';
// import { useLoadScript } from '@react-google-maps/api';

// const libraries = ['places', 'geometry', 'drawing'];

// const containerStyle = {
//   width: '100%',
//   height: '400px',
// };

// const GoogleMapRegion = ({ onSave, mode }) => {
//   const [map, setMap] = useState(null);
//   const [center, setCenter] = useState({ lat: 37.5642135, lng: 127.0016985 });
//   const [drawingMode, setDrawingMode] = useState('polygon');
//   const [polygonCoords, setPolygonCoords] = useState([]);
//   const [activePolygon, setActivePolygon] = useState(null);
//   const [drawingManager, setDrawingManager] = useState(null);
//   const [message, setMessage] = useState('');

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//     libraries,
//   });

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const newCenter = {
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         };
//         setCenter(newCenter);
//       },
//       () => console.error('Error: Unable to retrieve your location'),
//       { enableHighAccuracy: true }
//     );
//   }, []);

//   const onMapLoad = (mapInstance) => {
//     setMap(mapInstance);
//   };

//   const onPolygonComplete = (polygon) => {
//     resetPolygon();
//     setActivePolygon(polygon);
//     const path = polygon
//       .getPath()
//       .getArray()
//       .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));
//     setPolygonCoords(path);
//     onSave(path);

//     setMessage('영역 지정이 완료되었습니다.');
//   };

//   const handleDrawingModeChange = (event, newMode) => {
//     if (newMode !== null) {
//       setDrawingMode(newMode);
//       if (newMode === 'normal' && drawingManager) {
//         drawingManager.setMap(null);
//       }
//     }
//   };

//   const toggleDrawingManager = () => {
//     if (drawingMode === 'polygon' && map) {
//       if (!drawingManager) {
//         const manager = new window.google.maps.drawing.DrawingManager({
//           drawingControl: true,
//           drawingControlOptions: {
//             position: window.google.maps.ControlPosition.TOP_CENTER,
//             drawingModes: ['polygon'],
//           },
//           polygonOptions: {
//             fillColor: '#1a73e8',
//             fillOpacity: 0.1,
//             strokeWeight: 2,
//             clickable: true,
//             editable: true,
//             draggable: true,
//           },
//         });
//         manager.setMap(map);
//         manager.addListener('polygoncomplete', onPolygonComplete);
//         setDrawingManager(manager);
//       } else {
//         drawingManager.setMap(map);
//       }
//     } else {
//       if (drawingManager) {
//         drawingManager.setMap(null);
//       }
//     }
//   };

//   useEffect(() => {
//     toggleDrawingManager();
//   }, [drawingMode, map]);

//   const resetPolygon = () => {
//     if (activePolygon) {
//       activePolygon.setMap(null);
//     }
//     setPolygonCoords([]);
//     setActivePolygon(null);

//     setMessage('영역이 초기화되었습니다.');
//   };

//   if (loadError) {
//     return <Typography variant="body1">Error loading maps</Typography>;
//   }

//   if (!isLoaded) {
//     return <Typography variant="body1">Loading Maps...</Typography>;
//   }

//   return (
//     <Box sx={{ height: '60vh', width: '100%' }}>
//       {/* <ToggleButtonGroup
//         color="primary"
//         value={drawingMode}
//         exclusive
//         onChange={handleDrawingModeChange}
//         fullWidth
//         sx={{ marginBottom: 2 }}
//       >
//         <ToggleButton value="marker">Marker Mode</ToggleButton>
//         <ToggleButton value="polygon">Polygon Mode</ToggleButton>
//       </ToggleButtonGroup> */}

//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={15}
//         onLoad={onMapLoad}
//       >
//         {drawingMode === 'polygon' && (
//           <DrawingManager
//             onPolygonComplete={onPolygonComplete}
//             options={{
//               drawingControl: true,
//               drawingControlOptions: {
//                 position: window.google.maps.ControlPosition.TOP_CENTER,
//                 drawingModes: ['polygon'],
//               },
//               polygonOptions: {
//                 fillColor: '#1a73e8',
//                 fillOpacity: 0.1,
//                 strokeWeight: 2,
//                 strokeColor: '#1a73e8',
//                 clickable: true,
//                 editable: true,
//                 draggable: true,
//                 zIndex: 1,
//               },
//             }}
//           />
//         )}
//       </GoogleMap>

//       <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
//         <Button onClick={resetPolygon} variant="outlined">
//           Reset Area
//         </Button>
//         <Typography>{message}</Typography>
//       </Box>
//     </Box>
//   );
// };

// export default GoogleMapRegion;

// import React, { useState, useEffect } from 'react';
// import { GoogleMap, DrawingManager } from '@react-google-maps/api';
// import { Box, Button, Typography } from '@mui/material';
// import { useLoadScript } from '@react-google-maps/api';

// const libraries = ['places', 'geometry', 'drawing'];
// const containerStyle = {
//   width: '100%',
//   height: '400px',
// };

// const GoogleMapRegion = ({ onSave }) => {
//   const [map, setMap] = useState(null);
//   const [center, setCenter] = useState({ lat: 37.5642135, lng: 127.0016985 });
//   const [activePolygon, setActivePolygon] = useState(null);
//   const [message, setMessage] = useState('');

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//     libraries,
//   });

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const newCenter = {
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         };
//         setCenter(newCenter);
//       },
//       () => console.error('Error: Unable to retrieve your location'),
//       { enableHighAccuracy: true }
//     );
//   }, []);

//   useEffect(() => {
//     let drawingManager = null;
//     if (map) {
//       drawingManager = new window.google.maps.drawing.DrawingManager({
//         drawingControl: true,
//         drawingControlOptions: {
//           position: window.google.maps.ControlPosition.TOP_CENTER,
//           drawingModes: ['polygon'],
//         },
//         polygonOptions: {
//           fillColor: '#1a73e8',
//           fillOpacity: 0.1,
//           strokeWeight: 2,
//           strokeColor: '#1a73e8',
//           clickable: true,
//           editable: true,
//           draggable: true,
//           zIndex: 1,
//         },
//       });
//       drawingManager.setMap(map);
//       drawingManager.addListener('polygoncomplete', onPolygonComplete);
//     }

//     return () => {
//       if (drawingManager) {
//         window.google.maps.event.clearInstanceListeners(drawingManager);
//         drawingManager.setMap(null);
//       }
//     };
//   }, [map]); // Run only when the map instance changes

//   const onMapLoad = (mapInstance) => {
//     setMap(mapInstance);
//   };

//   const onPolygonComplete = (polygon) => {
//     if (activePolygon) {
//       activePolygon.setMap(null);
//     }
//     setActivePolygon(polygon);
//     const path = polygon
//       .getPath()
//       .getArray()
//       .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));
//     onSave(path);
//     setMessage('Region defined successfully.');
//   };

//   const resetPolygon = () => {
//     if (activePolygon) {
//       activePolygon.setMap(null);
//       setActivePolygon(null);
//     }
//     setMessage('Region has been reset.');
//   };

//   if (loadError) {
//     return <Typography variant="body1">Error loading maps</Typography>;
//   }

//   if (!isLoaded) {
//     return <Typography variant="body1">Loading Maps...</Typography>;
//   }

//   return (
//     <Box sx={{ height: '60vh', width: '100%' }}>
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={15}
//         onLoad={onMapLoad}
//       />
//       <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
//         <Button onClick={resetPolygon} variant="outlined">
//           Reset Region
//         </Button>
//         <Typography>{message}</Typography>
//       </Box>
//     </Box>
//   );
// };

// export default GoogleMapRegion;

// import React, { useState, useEffect } from 'react';
// import { GoogleMap, DrawingManager } from '@react-google-maps/api';
// import { Box, Button, Typography } from '@mui/material';
// import { useLoadScript } from '@react-google-maps/api';

// const libraries = ['places', 'geometry', 'drawing'];
// const containerStyle = {
//   width: '100%',
//   height: '400px',
// };

// const GoogleMapRegion = ({ initialRegion, onSave }) => {
//   const [map, setMap] = useState(null);
//   const [center, setCenter] = useState(
//     initialRegion?.center || { lat: 37.5642135, lng: 127.0016985 }
//   );
//   const [activePolygon, setActivePolygon] = useState(null);
//   const [message, setMessage] = useState('');
//   const [drawingManager, setDrawingManager] = useState(null);

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//     libraries,
//   });

//   useEffect(() => {
//     if (!initialRegion) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const newCenter = {
//             lat: position.coords.latitude,
//             lng: position.coords.longitude,
//           };
//           setCenter(newCenter);
//         },
//         () => console.error('Error: Unable to retrieve your location'),
//         { enableHighAccuracy: true }
//       );
//     } else {
//       setCenter(initialRegion.center);
//     }
//   }, [initialRegion]);

//   useEffect(() => {
//     if (map && drawingManager) {
//       drawingManager.setMap(map);
//     }
//   }, [map, drawingManager]);

//   const onMapLoad = (mapInstance) => {
//     setMap(mapInstance);
//     const drawingManagerInstance =
//       new window.google.maps.drawing.DrawingManager({
//         drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
//         drawingControl: true,
//         drawingControlOptions: {
//           position: window.google.maps.ControlPosition.TOP_CENTER,
//           drawingModes: ['polygon'],
//         },
//         polygonOptions: {
//           fillColor: '#1a73e8',
//           fillOpacity: 0.1,
//           strokeWeight: 2,
//           strokeColor: '#1a73e8',
//           clickable: true,
//           editable: true,
//           draggable: true,
//           zIndex: 1,
//         },
//       });
//     drawingManagerInstance.setMap(mapInstance);
//     drawingManagerInstance.addListener('polygoncomplete', onPolygonComplete);
//     setDrawingManager(drawingManagerInstance);

//     if (initialRegion && initialRegion.coordinates) {
//       const polygon = new window.google.maps.Polygon({
//         paths: initialRegion.coordinates,
//         fillColor: '#1a73e8',
//         fillOpacity: 0.1,
//         strokeWeight: 2,
//         strokeColor: '#1a73e8',
//         clickable: true,
//         editable: true,
//         draggable: true,
//       });
//       polygon.setMap(mapInstance);
//       setActivePolygon(polygon);
//       setMessage('Region loaded successfully.');
//     }
//   };

//   const onPolygonComplete = (polygon) => {
//     if (activePolygon) {
//       activePolygon.setMap(null);
//     }
//     setActivePolygon(polygon);
//     const path = polygon
//       .getPath()
//       .getArray()
//       .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));
//     onSave({ center, coordinates: path }); // Provide the object with center and coordinates
//     setMessage('Region defined successfully.');
//   };

//   const resetPolygon = () => {
//     if (activePolygon) {
//       activePolygon.setMap(null);
//       setActivePolygon(null);
//     }
//     setMessage('Region has been reset.');
//     onSave(null);
//   };

//   if (loadError) {
//     return <Typography variant="body1">Error loading maps</Typography>;
//   }

//   if (!isLoaded) {
//     return <Typography variant="body1">Loading Maps...</Typography>;
//   }

//   return (
//     <Box sx={{ height: '60vh', width: '100%' }}>
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={15}
//         onLoad={onMapLoad}
//       />
//       <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
//         <Button onClick={resetPolygon} variant="outlined">
//           Reset Region
//         </Button>
//         <Typography>{message}</Typography>
//       </Box>
//     </Box>
//   );
// };

// export default GoogleMapRegion;

import React, { useState, useEffect } from 'react';
import { GoogleMap, DrawingManager } from '@react-google-maps/api';
import { Box, Button, Typography } from '@mui/material';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places', 'geometry', 'drawing'];
const containerStyle = {
  width: '100%',
  height: '400px',
};

const GoogleMapRegion = ({ initialRegion, onSave }) => {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(
    initialRegion?.center || { lat: 37.5642135, lng: 127.0016985 }
  );
  const [activePolygon, setActivePolygon] = useState(null);
  const [message, setMessage] = useState('');
  const [drawingManager, setDrawingManager] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    if (!initialRegion) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(newCenter);
        },
        () => console.error('Error: Unable to retrieve your location'),
        { enableHighAccuracy: true }
      );
    } else {
      setCenter(initialRegion.center);
    }
  }, [initialRegion]);

  useEffect(() => {
    if (map && drawingManager) {
      drawingManager.setMap(map);
    }
  }, [map, drawingManager]);

  const onMapLoad = (mapInstance) => {
    setMap(mapInstance);
    const drawingManagerInstance =
      new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ['polygon'],
        },
        polygonOptions: {
          fillColor: '#1a73e8',
          fillOpacity: 0.1,
          strokeWeight: 2,
          strokeColor: '#1a73e8',
          clickable: true,
          editable: true,
          draggable: true,
          zIndex: 1,
        },
      });
    drawingManagerInstance.setMap(mapInstance);
    drawingManagerInstance.addListener('polygoncomplete', onPolygonComplete);
    setDrawingManager(drawingManagerInstance);

    if (initialRegion && initialRegion.coordinates) {
      const polygon = new window.google.maps.Polygon({
        paths: initialRegion.coordinates,
        fillColor: '#1a73e8',
        fillOpacity: 0.1,
        strokeWeight: 2,
        strokeColor: '#1a73e8',
        clickable: true,
        editable: true,
        draggable: true,
      });
      polygon.setMap(mapInstance);
      setActivePolygon(polygon);
      setMessage('Region loaded successfully.');
    }
  };

  const onPolygonComplete = (polygon) => {
    if (activePolygon) {
      activePolygon.setMap(null);
    }
    setActivePolygon(polygon);
    const path = polygon
      .getPath()
      .getArray()
      .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));

    // Calculate the center of the polygon
    const bounds = new window.google.maps.LatLngBounds();
    path.forEach((point) => bounds.extend(point));
    const newCenter = bounds.getCenter().toJSON();

    setCenter(newCenter); // Update the center state
    onSave({ center: newCenter, coordinates: path }); // Provide the object with center and coordinates
    setMessage('Region defined successfully.');
  };

  const resetPolygon = () => {
    if (activePolygon) {
      activePolygon.setMap(null);
      setActivePolygon(null);
    }
    setMessage('Region has been reset.');
    onSave(null);
  };

  if (loadError) {
    return <Typography variant="body1">Error loading maps</Typography>;
  }

  if (!isLoaded) {
    return <Typography variant="body1">Loading Maps...</Typography>;
  }

  return (
    <Box sx={{ height: '60vh', width: '100%' }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onLoad={onMapLoad}
      />
      <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={resetPolygon} variant="outlined">
          Reset Region
        </Button>
        <Typography>{message}</Typography>
      </Box>
    </Box>
  );
};

export default GoogleMapRegion;
