import { db } from '../firebaseConfig';
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  orderBy,
} from 'firebase/firestore';
import { getBoundingBox, haversineDistance } from './Haversine';
import { fetchProductsByUserIds, fetchUsersByGeoQuery } from '../api/GeoAPI';

const handleFirestoreError = (error, functionName) => {
  if (error.code === 'failed-precondition') {
    console.error(
      `${functionName}: Firestore index required - ${error.message}`
    );
  } else {
    console.error(`${functionName}: Error fetching data - ${error.message}`);
  }
};
export const exportFetchUserProductsByNotLoggedIn = async (
  pageSize,
  lastDoc = null
) => {
  try {
    // Firestore에서 'products' 컬렉션을 쿼리
    let q = query(
      collection(db, 'products'),
      orderBy('createDate', 'desc'),
      limit(pageSize)
    );

    // 마지막 문서가 제공되면 페이징을 위해 startAfter를 추가
    if (lastDoc) {
      q = query(q, startAfter(lastDoc));
    }

    // 쿼리 실행
    const productsSnapshot = await getDocs(q);

    // 문서 데이터 배열로 변환
    const products = productsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // 마지막 문서를 반환하여 다음 페이지 쿼리할 때 사용할 수 있도록 함
    const lastVisibleDoc =
      productsSnapshot.docs[productsSnapshot.docs.length - 1];

    return { products, lastDoc: lastVisibleDoc };
  } catch (error) {
    handleFirestoreError(
      error,
      'exportFetchUserProductsByLastDocAsMapNotLoggedIn'
    );
    return { products: [], lastDoc: null };
  }
};
export const exportFetchStoresByNotLoggedIn = async (
  pageSize,
  lastDoc = null
) => {
  try {
    // Firestore에서 'stores' 컬렉션을 쿼리
    let q = query(
      collection(db, 'stores'),
      orderBy('createDate', 'desc'),
      limit(pageSize)
    );

    // 마지막 문서가 제공되면 페이징을 위해 startAfter를 추가
    if (lastDoc) {
      q = query(q, startAfter(lastDoc));
    }

    // 쿼리 실행
    const storesSnapshot = await getDocs(q);

    // 문서 데이터 배열로 변환
    const stores = storesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // 마지막 문서를 반환하여 다음 페이지 쿼리할 때 사용할 수 있도록 함
    const lastVisibleDoc = storesSnapshot.docs[storesSnapshot.docs.length - 1];

    return { stores, lastDoc: lastVisibleDoc };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchStoresByNotLoggedIn');
    return { stores: [], lastDoc: null };
  }
};
// 사용자 제품을 페이지 단위로 가져오는 함수 (Map 형태로 반환)
export const exportFetchUserProductsByLastDocAsMap = async (
  pageSize,
  lastDoc = null,
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const usersSnapshot = await exportFetchUsersByCoordinates(
      latitude,
      longitude,
      radius,
      currentUserId
    );
    if (!usersSnapshot.length) {
      return { products: {}, lastDoc: null, totalCount: 0 };
    }

    const userIds = usersSnapshot.map((user) => user.id);
    let productsQuery;

    if (lastDoc) {
      productsQuery = query(
        collection(db, 'products'),
        where('uid', 'in', userIds),
        startAfter(lastDoc),
        limit(pageSize)
      );
    } else {
      productsQuery = query(
        collection(db, 'products'),
        where('uid', 'in', userIds),
        limit(pageSize)
      );
    }

    const productsSnapshot = await getDocs(productsQuery);
    const products = productsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const totalProductsQuery = query(
      collection(db, 'products'),
      where('uid', 'in', userIds)
    );
    const totalProductsSnapshot = await getDocs(totalProductsQuery);

    return {
      products,
      lastDoc: productsSnapshot.docs[productsSnapshot.docs.length - 1] || null,
      totalCount: totalProductsSnapshot.size,
    };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchUserProductsByLastDocAsMap');
    return {
      products: {},
      lastDoc: null,
      totalCount: 0,
    };
  }
};

// 스토어 제품을 페이지 단위로 가져오는 함수 (Map 형태로 반환)
export const exportFetchStoresByLastDocAsMap = async (
  pageSize,
  lastDoc = null,
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const storesSnapshot = await exportFetchStoresByCoordinates(
      latitude,
      longitude,
      radius,
      currentUserId
    );
    if (!storesSnapshot.stores.length) {
      return { products: {}, lastDoc: null, totalCount: 0 };
    }

    const storeIds = storesSnapshot.stores.map((store) => store.id);
    let storeProducts = [];
    let lastProductDoc = null;

    for (const storeId of storeIds) {
      let storeProductsQuery;

      if (lastDoc) {
        storeProductsQuery = query(
          collection(db, 'stores', storeId, 'products'),
          startAfter(lastDoc),
          limit(pageSize)
        );
      } else {
        storeProductsQuery = query(
          collection(db, 'stores', storeId, 'products'),
          limit(pageSize)
        );
      }

      const storeProductsSnapshot = await getDocs(storeProductsQuery);
      storeProductsSnapshot.docs.forEach((doc) => {
        storeProducts.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      if (storeProductsSnapshot.docs.length > 0) {
        lastProductDoc =
          storeProductsSnapshot.docs[storeProductsSnapshot.docs.length - 1];
      }
    }

    const totalStoreProductsSnapshot = await Promise.all(
      storeIds.map((storeId) =>
        getDocs(collection(db, 'stores', storeId, 'products'))
      )
    );

    const totalCount = totalStoreProductsSnapshot.reduce(
      (acc, snapshot) => acc + snapshot.size,
      0
    );

    return {
      products: storeProducts,
      lastDoc: lastProductDoc,
      totalCount,
    };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchStoresByLastDocAsMap');
    return {
      products: {},
      lastDoc: null,
      totalCount: 0,
    };
  }
};

// 페이지별 스토어를 Map 형태로 반환
export const exportFetchStoresPerPage = async (
  pageSize,
  page,
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    let storesQuery;
    let lastDoc = null;

    if (page > 0) {
      const previousPageDocCount = pageSize * page;
      const previousQuery = query(
        collection(db, 'stores'),
        orderBy('name'),
        limit(previousPageDocCount)
      );
      const previousSnapshot = await getDocs(previousQuery);
      lastDoc = previousSnapshot.docs[previousSnapshot.docs.length - 1];

      storesQuery = query(
        collection(db, 'stores'),
        orderBy('name'),
        startAfter(lastDoc),
        limit(pageSize)
      );
    } else {
      storesQuery = query(
        collection(db, 'stores'),
        orderBy('name'),
        limit(pageSize)
      );
    }

    const storesSnapshot = await getDocs(storesQuery);
    const stores = storesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const filteredStores = stores.filter((store) => {
      const { storePosition } = store;
      if (storePosition && storePosition.lat && storePosition.lng) {
        const distance = haversineDistance(
          { latitude, longitude },
          {
            latitude: storePosition.lat,
            longitude: storePosition.lng,
          }
        );
        return distance <= radius && store.id !== currentUserId;
      }
      return false;
    });

    const totalCount = filteredStores.length;

    return { stores: filteredStores, totalCount, lastDoc };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchStoresPerPage');
    return { stores: [], totalCount: 0, lastDoc: null };
  }
};

// 좌표에 따른 사용자 제품을 배열 형태로 반환

export const exportFetchUserProductsByCoordinates = async (
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const users = await exportFetchUsersByCoordinates(
      latitude,
      longitude,
      radius,
      currentUserId
    );
    if (!users.length) {
      return [];
    }

    const userIds = users.map((user) => user.id);
    const products = await exportFetchUserProductsFromUsers(userIds);

    const sortedProducts = products.sort((a, b) => {
      return b.createDate.seconds - a.createDate.seconds;
    });

    return Array.isArray(sortedProducts) ? sortedProducts : [];
  } catch (error) {
    handleFirestoreError(error, 'exportFetchUserProductsByCoordinates');
    return [];
  }
};

// 좌표에 따른 사용자 배열을 반환
const exportFetchUsersByCoordinates = async (
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const { minLat, maxLat, minLng, maxLng } = getBoundingBox(
      latitude,
      longitude,
      radius
    );
    // const usersSnapshot = await getDocs(
    //   collection(db, 'users'),
    //   where('location.latitude', '>=', minLat),
    //   where('location.latitude', '<=', maxLat),
    //   where('location.longitude', '>=', minLng),
    //   where('location.longitude', '<=', maxLng)
    // );
    const usersSnapshot = await getDocs(collection(db, 'users'));
    const users = usersSnapshot.docs
      .map((doc) => {
        const userData = doc.data();
        const { selectedTown, town1, town2 } = userData;
        let userLocation = null;

        if (selectedTown === 1 && town1) {
          userLocation = { latitude: town1.lat, longitude: town1.lng };
        } else if (selectedTown === 2 && town2) {
          userLocation = { latitude: town2.lat, longitude: town2.lng };
        }

        if (userLocation) {
          const distance = haversineDistance(
            { latitude, longitude },
            userLocation
          );
          return {
            id: doc.id,
            ...userData,
            distance,
          };
        }
        return null;
      })
      .filter(
        (user) => user !== null && user.distance <= radius
        // && user.id !== currentUserId
      )
      .sort((a, b) => a.distance - b.distance);

    return users;
  } catch (error) {
    handleFirestoreError(error, 'exportFetchUsersByCoordinates');
    return [];
  }
};

// 사용자로부터 제품 배열을 반환
const exportFetchUserProductsFromUsers = async (userIds) => {
  try {
    const productPromises = userIds.map(async (userId) => {
      const q = query(collection(db, 'products'), where('uid', '==', userId));
      const productsSnapshot = await getDocs(q);
      return productsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });

    const productsArrays = await Promise.all(productPromises);
    return productsArrays.flat();
  } catch (error) {
    handleFirestoreError(error, 'exportFetchUserProductsFromUsers');
    return [];
  }
};
// 사용자 ID로 선택된 마을을 반환
export const exportFetchSelectedTownByUserId = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const { selectedTown, town1, town2 } = userData;

      if (selectedTown === 1 && town1) {
        return { lat: town1.lat, lng: town1.lng, radius: town1.radius };
      } else if (selectedTown === 2 && town2) {
        return { lat: town2.lat, lng: town2.lng, radius: town2.radius };
      }
    }
    return null;
  } catch (error) {
    handleFirestoreError(error, 'exportFetchSelectedTownByUserId');
    return null;
  }
};

// 좌표에 따른 모든 스토어 제품을 배열로 반환
export const exportFetchStoreAllProductsByCoordinatesAsArray = async (
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const stores = await exportFetchStoresByCoordinates(
      latitude,
      longitude,
      radius,
      currentUserId
    );
    if (!stores.stores.length) {
      return [];
    }

    const storeIds = stores.stores.map((store) => store.id);
    const storeProducts = [];

    for (const storeId of storeIds) {
      const productsQuery = query(
        collection(db, 'stores', storeId, 'products')
      );
      const productsSnapshot = await getDocs(productsQuery);
      productsSnapshot.docs.forEach((doc) => {
        storeProducts.push({
          id: doc.id,
          ...doc.data(),
        });
      });
    }

    return storeProducts;
  } catch (error) {
    handleFirestoreError(
      error,
      'exportFetchStoreAllProductsByCoordinatesAsArray'
    );
    return [];
  }
};

// 좌표에 따른 스토어 제품을 배열로 반환
export const exportFetchStoreProductsByCoordinates = async (
  latitude,
  longitude,
  radius,
  currentUserId
) => {
  try {
    const { stores, totalCount, lastDoc } =
      await exportFetchStoresByCoordinates(
        latitude,
        longitude,
        radius,
        currentUserId
      );

    if (!stores.length) {
      return { products: {}, lastDoc: null, totalCount: 0 };
    }

    const storeIds = stores.map((store) => store.id);
    const promotionProducts = {};

    for (const storeId of storeIds) {
      const productsQuery = query(
        collection(db, 'stores', storeId, 'products')
      );

      const productsSnapshot = await getDocs(productsQuery);
      productsSnapshot.docs.forEach((doc) => {
        promotionProducts[doc.id] = { id: doc.id, ...doc.data() };
      });
    }

    return {
      products: promotionProducts,
      lastDoc,
      totalCount: Object.keys(promotionProducts).length,
    };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchStoreProductsByCoordinates');
    return { products: {}, lastDoc: null, totalCount: 0 };
  }
};

// 좌표에 따른 스토어을 Map으로 반환
export const exportFetchStoresByCoordinates = async (
  latitude,
  longitude,
  radius,
  currentUserId,
  lastDoc = null,
  limitCount = 10
) => {
  try {
    let storesQuery = query(
      collection(db, 'stores'),
      orderBy('storePosition.lat'),
      limit(limitCount)
    );

    if (lastDoc) {
      storesQuery = query(
        collection(db, 'stores'),
        orderBy('storePosition.lat'),
        startAfter(lastDoc),
        limit(limitCount)
      );
    }

    const storesSnapshot = await getDocs(storesQuery);
    const stores = storesSnapshot.docs
      .map((doc) => {
        const storeData = doc.data();
        if (
          storeData.storePosition &&
          storeData.storePosition.lat &&
          storeData.storePosition.lng
        ) {
          const distance = haversineDistance(
            { latitude, longitude },
            {
              latitude: storeData.storePosition.lat,
              longitude: storeData.storePosition.lng,
            }
          );
          return {
            id: doc.id,
            ...storeData,
            distance,
          };
        }
        return null;
      })
      .filter(
        (store) =>
          store !== null &&
          store.distance <= radius &&
          store.id !== currentUserId
      )
      .sort((a, b) => a.distance - b.distance);

    const totalStoresSnapshot = await getDocs(collection(db, 'stores'));
    const totalCount = totalStoresSnapshot.docs.filter((doc) => {
      const storeData = doc.data();
      if (
        storeData.storePosition &&
        storeData.storePosition.lat &&
        storeData.storePosition.lng
      ) {
        const distance = haversineDistance(
          { latitude, longitude },
          {
            latitude: storeData.storePosition.lat,
            longitude: storeData.storePosition.lng,
          }
        );
        return distance <= radius && doc.id !== currentUserId;
      }
      return false;
    }).length;

    return {
      stores,
      lastDoc: storesSnapshot.docs[storesSnapshot.docs.length - 1],
      totalCount,
    };
  } catch (error) {
    handleFirestoreError(error, 'exportFetchStoresByCoordinates');
    return { stores: [], lastDoc: null, totalCount: 0 };
  }
};

export const exportFetchUserProductsWithCurrentPosition = async (radius) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        return exportFetchUserProductsByCoordinates(
          latitude,
          longitude,
          radius
        );
      },
      (error) => {
        console.error('Error getting location:', error);
        return [];
      }
    );
  } else {
    console.error('Geolocation is not supported by this browser.');
    return [];
  }
};

export const exportFetchStoreProductsWithCurrentPosition = async (
  radius,
  currentUserId
) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        return exportFetchStoreProductsByCoordinates(
          latitude,
          longitude,
          radius,
          currentUserId
        );
      },
      (error) => {
        console.error('Error getting location:', error);
        return [];
      }
    );
  } else {
    console.error('Geolocation is not supported by this browser.');
    return [];
  }
};

// export const exportFilterAndSortProducts = (
//   products,
//   sortBy = 'createDate',
//   filterFn = null
// ) => {
//   let filteredProducts = products;
//   if (filterFn) {
//     filteredProducts = products.filter(filterFn);
//   }

//   return filteredProducts.sort((a, b) => {
//     if (sortBy === 'createDate') {
//       return b.createDate.seconds - a.createDate.seconds;
//     } else if (sortBy === 'popularity') {
//       return b.likes - a.likes;
//     }
//     return 0;
//   });
// };
// exportFilterAndSortProducts.js

// ✅ 상품 상태 문자열 → 숫자로 변환
const mapStatesToNumbers = (states = []) => {
  const map = {
    selling: 0,
    reserved: 1,
    'sold-out': 2,
  };
  return states.map((s) => map[s]).filter((s) => s !== undefined);
};

const applyFilters = (
  items,
  {
    keyword = '',
    categoryId,
    brandId,
    weatherId,
    states = [],
    minPrice,
    maxPrice,
  }
) => {
  const keywordLower = keyword.toLowerCase();
  const allowedStates = mapStatesToNumbers(states);

  return items.filter((item) => {
    const titleMatch = item.title?.toLowerCase().includes(keywordLower);
    const contentMatch = item.content?.toLowerCase().includes(keywordLower);
    const descMatch = item.description?.toLowerCase().includes(keywordLower);

    const categoryMatch = categoryId ? item.categoryId === categoryId : true;
    const brandMatch = brandId ? item.brandId === brandId : true;
    const weatherMatch = weatherId
      ? item.weatherEvent === weatherId || item.weatherId === weatherId
      : true;
    const priceMatch =
      (!minPrice || item.price >= minPrice) &&
      (!maxPrice || item.price <= maxPrice);
    const stateMatch = allowedStates.length
      ? allowedStates.includes(item.state)
      : true;

    return (
      (titleMatch || contentMatch || descMatch) &&
      categoryMatch &&
      brandMatch &&
      weatherMatch &&
      priceMatch &&
      stateMatch
    );
  });
};

const sortItems = (items, sort = 'latest') => {
  const sorted = [...items];
  switch (sort) {
    case 'latest':
      sorted.sort((a, b) => b.createDate - a.createDate);
      break;
    case 'lowPrice':
      sorted.sort((a, b) => a.price - b.price);
      break;
    case 'highPrice':
      sorted.sort((a, b) => b.price - a.price);
      break;
    case 'popularity':
      sorted.sort((a, b) => b.likes - a.likes);
      break;
    default:
      break;
  }
  return sorted;
};

export const exportFilterAndSortProducts = async ({
  uid,
  type = 'user',
  keyword,
  categoryId,
  brandId,
  weatherId,
  states,
  minPrice,
  maxPrice,
  location,
  sort,
}) => {
  try {
    const { lat, lng, radius } = location;

    const raw =
      type === 'user'
        ? await exportFetchUserProductsByCoordinates(lat, lng, radius, uid)
        : await exportFetchStoreProductsByCoordinates(lat, lng, radius, uid);

    const items = type === 'store' ? Object.values(raw.products || {}) : raw;
    const filtered = applyFilters(items, {
      keyword,
      categoryId,
      brandId,
      weatherId,
      states,
      minPrice,
      maxPrice,
    });

    return sortItems(filtered, sort);
  } catch (err) {
    console.error('🔥 exportFilterAndSortProducts 오류:', err);
    return [];
  }
};
