// components/SearchBarWithKeyword.tsx
import React from 'react';
import { Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { StyledInputBase, SearchContainer, SearchIconWrapper } from '../NavBar/Search';
import { CustomIconButton } from '../Common/CumtomIconButton';
import SearchBox from '../NavBar/SearchBox';

interface SearchBarWithKeywordProps {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  onSearch: (keyword: string) => void;
  onFocus: () => void;
  showSearchBox: boolean;
  setShowSearchBox: (value: boolean) => void;
  townId: string;
  popupRef: React.RefObject<HTMLInputElement>;
  theme: any;
}

const SearchBarWithKeyword: React.FC<SearchBarWithKeywordProps> = ({
  searchQuery,
  setSearchQuery,
  onSearch,
  onFocus,
  showSearchBox,
  setShowSearchBox,
  townId,
  popupRef,
  theme,
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearch(searchQuery);
    }
  };

  return (
    <SearchContainer theme={theme} sx={{ width: '100%', minHeight: '55px' }}>
      <StyledInputBase
        placeholder="검색어를 입력하세요"
        inputProps={{ 'aria-label': 'search' }}
        value={searchQuery}
        onFocus={onFocus}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <CustomIconButton
        type="button"
        edge="end"
        sx={{ p: '10px', mr: '10px', backgroundColor: 'transparent' }}
        aria-label="search"
        onClick={() => onSearch(searchQuery)}
      >
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      </CustomIconButton>
      <SearchBox
        inputValue={searchQuery}
        setInputValue={setSearchQuery}
        showSearchBox={showSearchBox}
        setShowSearchBox={setShowSearchBox}
        townId={townId}
        popupRef={popupRef}
      />
    </SearchContainer>
  );
};

export default SearchBarWithKeyword;
