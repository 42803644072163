import React, { useState, useEffect, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Typography,
  Box,
  InputBase,
  Divider,
  Avatar,
  TextField,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

export const CustomNavLink = styled(NavLink)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  textDecoration: 'none',
  // padding: theme.spacing(0.5, 1), // ✅ 테마 기반 padding 설정
  transition: theme.transitions.create(['background-color', 'box-shadow'], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }), // ✅ MUI 기본 트랜지션 활용
  backgroundColor: 'transparent',
  // borderRadius: theme.shape.borderRadius, // ✅ 테마 기반 border-radius 적용
  color: theme.palette.text.primary, // ✅ 기본 텍스트 색상 적용
  '&.active': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2), // ✅ 활성 상태에서 부드러운 배경 강조
    // boxShadow: `0 0 5px ${alpha(theme.palette.primary.main, 0.4)}`, // ✅ 그림자 효과 (강하지 않게)
    borderBottom: `2px solid ${theme.palette.primary.main}`, // ✅ 밑줄 강조
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1), // ✅ 호버 시 배경 강조
  },
}));
export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  transition: theme.transitions.create(['background-color', 'box-shadow'], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }),
  '&.active': {
    backgroundColor: alpha(theme.palette.primary.main, 0.3), // ✅ active 시 배경 강조
    boxShadow: `0 0 5px ${alpha(theme.palette.primary.main, 0.5)}`,
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}));
