// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   collection,
//   getDocs,
//   query,
//   where,
//   orderBy,
//   doc,
//   getDoc,
// } from '../../firebaseConfig';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   Typography,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// const TermsCheck = () => {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const [showAgreementAlert, setShowAgreementAlert] = useState(false);
//   const [alertMessage, setAlertMessage] = useState('');

//   useEffect(() => {
//     if (currentUser) {
//       checkUserAgreementStatus();
//     }
//   }, [currentUser]);

//   const checkUserAgreementStatus = async () => {
//     if (currentUser && currentUser.uid) {
//       const termsCollection = collection(db, 'terms');
//       const termsSnapshot = await getDocs(termsCollection);
//       const allVersions = termsSnapshot.docs.map((doc) => doc.data().version);
//       const latestVersion = Math.max(...allVersions);

//       const latestTermsDoc = termsSnapshot.docs.find(
//         (doc) => doc.data().version === latestVersion
//       );

//       if (!latestTermsDoc) {
//         console.error('No latest terms document found');
//         return;
//       }

//       const latestTermsDocId = latestTermsDoc.id;

//       const latestUserTermsCollection = collection(
//         db,
//         'users',
//         currentUser.uid,
//         'terms',
//         latestTermsDocId,
//         'items'
//       );
//       const latestUserTermsSnapshot = await getDocs(latestUserTermsCollection);

//       if (latestUserTermsSnapshot.empty) {
//         setAlertMessage(t('newTerms'));
//         setShowAgreementAlert(true);
//       } else {
//         const agreements = {};
//         latestUserTermsSnapshot.docs.forEach((itemDoc) => {
//           const itemId = itemDoc.id;
//           agreements[`${latestTermsDocId}_${itemId}`] = true;
//         });

//         const latestTermsData = {
//           ...latestTermsDoc.data(),
//           id: latestTermsDoc.id,
//           items: latestTermsDoc.data().items.map((item, index) => ({
//             ...item,
//             id: `${latestTermsDoc.id}_${index}`,
//           })),
//         };

//         const allRequiredAgreed = latestTermsData.items
//           .filter((item) => item.type === 'required')
//           .every((item) => agreements[`${latestTermsData.id}_${item.id}`]);

//         if (!allRequiredAgreed) {
//           setAlertMessage(t('agreeToTerms'));
//           setShowAgreementAlert(true);
//         }
//       }
//     }
//   };

//   const handleAgreeClick = () => {
//     setShowAgreementAlert(false);
//     navigate('/terms_agreement');
//   };

//   return (
//     <Dialog
//       open={showAgreementAlert}
//       onClose={() => setShowAgreementAlert(false)}
//     >
//       <DialogTitle>{t('alert')}</DialogTitle>
//       <DialogContent>
//         <Typography>{alertMessage}</Typography>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleAgreeClick} color="primary">
//           {t('confirm')}
//         </Button>
//         <Button onClick={() => setShowAgreementAlert(false)} color="secondary">
//           {t('cancel')}
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default TermsCheck;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { db, collection, getDocs, doc } from '../../firebaseConfig';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const TermsCheck = ({ onAgreementComplete }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [showAgreementAlert, setShowAgreementAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (currentUser) {
      checkUserAgreementStatus();
    }
  }, [currentUser]);

  const checkUserAgreementStatus = async () => {
    if (currentUser && currentUser.uid) {
      const termsCollection = collection(db, 'terms');
      const termsSnapshot = await getDocs(termsCollection);
      const allVersions = termsSnapshot.docs.map((doc) => doc.data().version);
      const latestVersion = Math.max(...allVersions);

      const latestTermsDoc = termsSnapshot.docs.find(
        (doc) => doc.data().version === latestVersion
      );

      if (!latestTermsDoc) {
        console.error('No latest terms document found');
        return;
      }

      const latestTermsDocId = latestTermsDoc.id;

      const latestUserTermsCollection = collection(
        db,
        'users',
        currentUser.uid,
        'terms',
        latestTermsDocId,
        'items'
      );
      const latestUserTermsSnapshot = await getDocs(latestUserTermsCollection);

      if (latestUserTermsSnapshot.empty) {
        setAlertMessage(t('newTerms'));
        setShowAgreementAlert(true);
      } else {
        const agreements = {};
        latestUserTermsSnapshot.docs.forEach((itemDoc) => {
          const itemId = itemDoc.id;
          agreements[`${latestTermsDocId}_${itemId}`] = true;
        });

        const latestTermsData = {
          ...latestTermsDoc.data(),
          id: latestTermsDoc.id,
          items: latestTermsDoc.data().items.map((item, index) => ({
            ...item,
            id: `${latestTermsDoc.id}_${index}`,
          })),
        };

        const allRequiredAgreed = latestTermsData.items
          .filter((item) => item.type === 'required')
          .every((item) => agreements[`${latestTermsData.id}_${item.id}`]);

        if (!allRequiredAgreed) {
          setAlertMessage(t('agreeToTerms'));
          setShowAgreementAlert(true);
        } else {
          onAgreementComplete();
        }
      }
    }
  };

  const handleAgreeClick = () => {
    setShowAgreementAlert(false);
    navigate('/terms_agreement');
  };

  return (
    <Dialog
      open={showAgreementAlert}
      onClose={() => setShowAgreementAlert(false)}
    >
      <DialogTitle>{t('alert')}</DialogTitle>
      <DialogContent>
        <Typography>{alertMessage}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAgreeClick} color="primary">
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TermsCheck.propTypes = {
  // onAgreementComplete: PropTypes.func.isRequired,
  onAgreementComplete: () => {},
};

export default TermsCheck;
