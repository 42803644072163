import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, IconButton, Collapse, useTheme } from '@mui/material';
import ProductCategorySelector from './Stores/ProductCategorySelector';

const StoreCategorySelector = ({ onSelectCategory, selectedCategory }) => {
  const searchCategory = useSelector((state) => state.search.searchCategory);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (searchCategory) {
      onSelectCategory(searchCategory);
      navigate('/category_search_results');
    }
  }, [searchCategory]);

  const handleSelectCategory = (category) => {
    onSelectCategory(category);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Box
        sx={{
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          py: 2,
          mt: 2,
          borderRadius: 2, // 모서리를 둥글게 만드는 속성 추가
        }}
      >
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} md={8} lg={10}>
            <ProductCategorySelector
              categoryId={selectedCategory?.id}
              onSelectCategory={handleSelectCategory}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StoreCategorySelector;
