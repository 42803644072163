// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   firebaseApp,
//   db,
//   auth,
//   analytics,
//   storage,
//   functions,
//   collection,
//   doc,
//   getDoc,
//   setDoc,
//   updateDoc,
//   query,
//   where,
//   orderBy,
//   getDocs
// } from './firebaseConfig';

// import { useNavigate } from 'react-router-dom';
// import Keyword from './Keyword';
// import NavBar from './NavBar';

// function MyKeyword() {
//   const [userKeywords, setUserKeywords] = useState([]);
//   const navigate = useNavigate();
//   const currentUser = useSelector(state => state.auth.currentUser);

//     useEffect(() => {
//       // 초기 로딩 시 사용자의 키워드를 가져옵니다.
//       const fetchKeywords = async () => {
//           const userRef = doc(db, 'users', currentUser.uid);
//           const userDoc = await getDoc(userRef);

//           if (userDoc.exists()) {
//               const data = userDoc.data();
//               if (data.keywords) {
//                   setUserKeywords(data.keywords);
//               }
//           } else {
//               // 문서가 없을 때 초기 keywords 값을 설정하여 새 문서를 생성합니다.
//               const initialKeywords = [];  // 초기 값 설정 (또는 원하는 값으로 변경)
//               await setDoc(userRef, { keywords: initialKeywords });
//               setUserKeywords(initialKeywords);
//           }
//       };

//       fetchKeywords();
//   }, [currentUser, db]);

//   const handleUpdateKeywords = async (updatedKeywords) => {
//       setUserKeywords(updatedKeywords);

//       // Firebase에 변경된 키워드를 업데이트합니다.
//       const userId = auth.currentUser.uid;
//       const userRef = doc(db, 'users', userId);

//       await updateDoc(userRef, {
//           keywords: updatedKeywords
//       });
//   }

//   return (

//     <div className="container clear-fix">
//       <button onClick={() => navigate('/settings')} style={{
//         background: 'none',
//         border: 'none',
//         fontSize: '20px',
//         cursor: 'pointer',
//         color: '#333'  // <- 텍스트 색상. 필요에 따라 변경 가능
//       }}>&larr;</button>
//       <br />

//       <div className="d-flex justify-content-between align-items-center mb-4">
//         <h3>알림키워드설정</h3>
//       </div>
//       <Keyword initialKeywords={userKeywords} onUpdateKeywords={handleUpdateKeywords} />
//     </div>

//   );
// }

// export default MyKeyword;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  firebaseApp,
  db,
  auth,
  analytics,
  storage,
  functions,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  query,
  where,
  orderBy,
  getDocs,
} from './firebaseConfig';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Button, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Keyword from './Keyword';

function MyKeyword() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [userKeywords, setUserKeywords] = useState([]);
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        if (!currentUser || !db) {
          console.warn('⚠️ currentUser 또는 db가 존재하지 않습니다.');
          return;
        }

        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          if (data.keywords) {
            setUserKeywords(data.keywords);
          } else {
            console.warn('⚠️ 사용자의 keywords 필드가 존재하지 않습니다.');
            setUserKeywords([]);
          }
        } else {
          console.log('🔹 사용자 문서가 존재하지 않아 초기화 중...');
          const initialKeywords = [];
          await setDoc(userRef, { keywords: initialKeywords });
          setUserKeywords(initialKeywords);
        }
      } catch (error) {
        console.error('❌ 키워드 데이터를 가져오는 중 오류 발생:', error);
      }
    };

    fetchKeywords();
  }, [currentUser, db]);

  const handleUpdateKeywords = async (updatedKeywords) => {
    setUserKeywords(updatedKeywords);

    const userId = auth.currentUser.uid;
    const userRef = doc(db, 'users', userId);

    await updateDoc(userRef, {
      keywords: updatedKeywords,
    });
  };

  return (
    // <Container
    //   maxWidth={false} // 화면 전체 너비로 확장
    //   sx={{
    //     backgroundColor: theme.palette.background.default,
    //     width: '100%',
    //     minHeight: '100vh', // 화면 전체 높이 설정
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     paddingTop: 3,
    //     paddingBottom: 3,
    //   }}
    // >
    //   <Box mt={2} mb={4}>
    //     <IconButton
    //       onClick={() => navigate('/settings')}
    //       sx={{
    //         color: theme.palette.primary.main,
    //         backgroundColor: theme.palette.background.paper,
    //         '&:hover': {
    //           backgroundColor: theme.palette.action.hover,
    //         },
    //       }}
    //     >
    //       <ArrowBackIcon />
    //     </IconButton>
    //   </Box>
    //   <Typography
    //     variant="h5"
    //     sx={{
    //       fontWeight: 600,
    //       paddingBottom: theme.spacing(2),
    //       color: theme.palette.primary.main,
    //     }}
    //   >
    <Box
      sx={{
        width: '100vw', // ✅ 전체 너비
        minHeight: '100vh', // ✅ 전체 높이
        backgroundColor: theme.palette.background.default, // ✅ 전체 배경색 적용 (다크모드 지원)
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', // 중앙 정렬
      }}
    >
      <Container
        maxWidth="lg"
        sx={{ backgroundColor: theme.palette.background.paper, py: 4 }}
      >
        {/* 🔹 타이틀 박스 */}
        {/* <Box
          sx={{
            borderRadius: '12px',
            padding: '12px 2px',
            mb: 3,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              transform: 'scale(1.02)',
              boxShadow: theme.shadows[4],
            },
            textAlign: 'left',
          }}
        > */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            paddingBottom: theme.spacing(2),
            color: theme.palette.primary.main,
          }}
        >
          {t('settings.notificationKeyword')}
        </Typography>
        <Keyword
          initialKeywords={userKeywords}
          onUpdateKeywords={handleUpdateKeywords}
        />
        {/* </Box> */}
      </Container>
    </Box>
  );
}

export default MyKeyword;
