// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, getDoc, doc } from './firebaseConfig';

// import Product from './Product'; // <-- Importing the Product component
// import styled from './css/home.module.css';

// export default function HeartList() {
//   const [products, setProducts] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const fetchProduct = async (id) => {
//     const productRef = doc(db, 'products', id);
//     const productDoc = await getDoc(productRef);
//     return {
//       id: productDoc.id,
//       ...productDoc.data(),
//     };
//   };

//   useEffect(() => {
//     const fetchMyLikesProducts = async () => {
//       if (!currentUser) return [];

//       const userLikesRef = doc(db, 'userLikes', currentUser.uid);
//       const userLikesDoc = await getDoc(userLikesRef);

//       if (!userLikesDoc.exists()) return [];

//       const myLikesProductIds = userLikesDoc.data().products;

//       // 모든 좋아요 누른 제품 가져오기
//       const products = await Promise.all(myLikesProductIds.map(fetchProduct));
//       return products;
//     };

//     fetchMyLikesProducts()
//       .then(setProducts)
//       .catch((err) => {
//         console.error('Error fetching myLikes products:', err);
//       });
//   }, [currentUser]);

//   return (
//     <div className={styled.container}>
//       <div>
//         <h3>관심리스트</h3>
//       </div>
//       <div className={styled['grid-container']}>
//         {products.length === 0 ? (
//           <p style={{ textAlign: 'center' }}>관심등록 된 물건이 없습니다</p>
//         ) : (
//           products.map((product) => (
//             <Product key={product.id} product={product} />
//           ))
//         )}
//       </div>
//     </div>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, getDoc, doc } from './firebaseConfig';
// import Product from './Product';
// import { Grid, Typography, Container, Box } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// export default function HeartList() {
//   const { t, i18n } = useTranslation();
//   const [products, setProducts] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const fetchProduct = async (id) => {
//     const productRef = doc(db, 'products', id);
//     const productDoc = await getDoc(productRef);
//     if (productDoc.exists()) {
//       return {
//         id: productDoc.id,
//         ...productDoc.data(),
//       };
//     } else {
//       return null;
//     }
//   };

//   useEffect(() => {
//     const fetchMyLikesProducts = async () => {
//       if (!currentUser) return [];

//       const userLikesRef = doc(db, 'userLikes', currentUser.uid);
//       const userLikesDoc = await getDoc(userLikesRef);
//       if (!userLikesDoc.exists()) return [];

//       const myLikesProductIds = userLikesDoc.data().products;
//       const products = await Promise.all(myLikesProductIds.map(fetchProduct));
//       return products.filter((product) => product !== null); // Filter out null values if any product data is missing
//     };

//     fetchMyLikesProducts()
//       .then(setProducts)
//       .catch((err) => {
//         console.error('Error fetching myLikes products:', err);
//       });
//   }, [currentUser]);
//   const handleProductClick = (product) => {
//     //setSelectedProduct(product);
//     window.open(`/detail/${product.id}`, '_blank', 'noopener,noreferrer');
//   };
//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '80%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 그림자 효과
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('watchlist')}
//         </Typography>
//       </Box>
//       <Grid container spacing={2}>
//         {products.length === 0 ? (
//           <Grid item xs={12}>
//             <Typography textAlign="center">
//               관심등록 된 물건이 없습니다
//             </Typography>
//           </Grid>
//         ) : (
//           products.map((product) => (
//             <Grid item xs={12} sm={6} md={3} key={product.id}>
//               <Product
//                 key={product.id}
//                 product={product}
//                 onClick={() => handleProductClick(product)}
//               />
//             </Grid>
//           ))
//         )}
//       </Grid>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   getDoc,
//   doc,
//   collection,
//   getDocs,
//   query,
//   where,
// } from './firebaseConfig';
// import Product from './Product';
// import { Grid, Typography, Container, Box } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// export default function HeartList() {
//   const { t, i18n } = useTranslation();
//   const [likedProducts, setLikedProducts] = useState([]);
//   const [sellingProducts, setSellingProducts] = useState([]);
//   const [buyingProducts, setBuyingProducts] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const fetchProduct = async (id) => {
//     const productRef = doc(db, 'products', id);
//     const productDoc = await getDoc(productRef);
//     if (productDoc.exists()) {
//       return {
//         id: productDoc.id,
//         ...productDoc.data(),
//       };
//     } else {
//       return null;
//     }
//   };

//   const fetchMySellingProducts = async () => {
//     if (!currentUser) return [];
//     const sellingProductsQuery = query(
//       collection(db, 'products'),
//       where('uid', '==', currentUser.uid)
//     );
//     const sellingProductsSnapshot = await getDocs(sellingProductsQuery);
//     return sellingProductsSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//   };

//   const fetchMyBuyingProducts = async () => {
//     if (!currentUser) return [];
//     const buyingProductsQuery = query(
//       collection(db, 'products'),
//       where('buyerId', '==', currentUser.uid)
//     );
//     const buyingProductsSnapshot = await getDocs(buyingProductsQuery);
//     return buyingProductsSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//   };

//   useEffect(() => {
//     const fetchMyLikesProducts = async () => {
//       if (!currentUser) return [];

//       const userLikesRef = doc(db, 'userLikes', currentUser.uid);
//       const userLikesDoc = await getDoc(userLikesRef);
//       if (!userLikesDoc.exists()) return [];

//       const myLikesProductIds = userLikesDoc.data().products;
//       const products = await Promise.all(myLikesProductIds.map(fetchProduct));
//       return products.filter((product) => product !== null); // Filter out null values if any product data is missing
//     };

//     fetchMyLikesProducts()
//       .then(setLikedProducts)
//       .catch((err) => {
//         console.error('Error fetching myLikes products:', err);
//       });

//     fetchMySellingProducts()
//       .then(setSellingProducts)
//       .catch((err) => {
//         console.error('Error fetching selling products:', err);
//       });

//     fetchMyBuyingProducts()
//       .then(setBuyingProducts)
//       .catch((err) => {
//         console.error('Error fetching buying products:', err);
//       });
//   }, [currentUser]);

//   const handleProductClick = (product) => {
//     window.open(`/detail/${product.id}`, '_blank', 'noopener,noreferrer');
//   };

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '80%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//           my: '30px',
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('watchlist')}
//         </Typography>
//       </Box>
//       <Grid container spacing={2}>
//         {likedProducts.length === 0 ? (
//           <Grid item xs={12}>
//             <Typography textAlign="center">{t('noWatchlistItems')}</Typography>
//           </Grid>
//         ) : (
//           likedProducts.map((product) => (
//             <Grid item xs={12} sm={6} md={3} key={product.id}>
//               <Product
//                 key={product.id}
//                 product={product}
//                 onClick={() => handleProductClick(product)}
//               />
//             </Grid>
//           ))
//         )}
//       </Grid>

//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//           my: '30px',
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('mySellingProducts')}
//         </Typography>
//       </Box>
//       <Grid container spacing={2}>
//         {sellingProducts.length === 0 ? (
//           <Grid item xs={12}>
//             <Typography textAlign="center">{t('noSellingProducts')}</Typography>
//           </Grid>
//         ) : (
//           sellingProducts.map((product) => (
//             <Grid item xs={12} sm={6} md={3} key={product.id}>
//               <Product
//                 key={product.id}
//                 product={product}
//                 onClick={() => handleProductClick(product)}
//               />
//             </Grid>
//           ))
//         )}
//       </Grid>

//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//           my: '30px',
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('myBuyingProducts')}
//         </Typography>
//       </Box>
//       <Grid container spacing={2}>
//         {buyingProducts.length === 0 ? (
//           <Grid item xs={12}>
//             <Typography textAlign="center">{t('noBuyingProducts')}</Typography>
//           </Grid>
//         ) : (
//           buyingProducts.map((product) => (
//             <Grid item xs={12} sm={6} md={3} key={product.id}>
//               <Product
//                 key={product.id}
//                 product={product}
//                 onClick={() => handleProductClick(product)}
//               />
//             </Grid>
//           ))
//         )}
//       </Grid>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   getDoc,
//   doc,
//   collection,
//   getDocs,
//   query,
//   where,
// } from './firebaseConfig';
// import Product from './Product';
// import { Grid, Typography, Container, Box } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// export default function HeartList() {
//   const { t, i18n } = useTranslation();
//   const [likedProducts, setLikedProducts] = useState([]);
//   const [sellingProducts, setSellingProducts] = useState([]);
//   const [buyingProducts, setBuyingProducts] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const fetchProduct = async (id) => {
//     const productRef = doc(db, 'products', id);
//     const productDoc = await getDoc(productRef);
//     if (productDoc.exists()) {
//       return {
//         id: productDoc.id,
//         ...productDoc.data(),
//       };
//     } else {
//       return null;
//     }
//   };

//   const fetchMySellingProducts = async () => {
//     if (!currentUser) return [];
//     const sellingProductsQuery = query(
//       collection(db, 'products'),
//       where('uid', '==', currentUser.uid)
//     );
//     const sellingProductsSnapshot = await getDocs(sellingProductsQuery);
//     return sellingProductsSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//   };

//   // const fetchMyBuyingProducts = async () => {
//   //   if (!currentUser) return [];
//   //   const buyingProductsQuery = query(
//   //     collection(db, 'products'),
//   //     where('buyerId', '==', currentUser.uid)
//   //   );
//   //   const buyingProductsSnapshot = await getDocs(buyingProductsQuery);
//   //   return buyingProductsSnapshot.docs.map((doc) => ({
//   //     id: doc.id,
//   //     ...doc.data(),
//   //   }));
//   // };

//   const fetchMyBuyingProducts = async () => {
//     if (!currentUser) return [];
//     const buyingProductsQuery = query(
//       collection(db, 'products'),
//       where('endUsers.take', '==', currentUser.uid)
//     );
//     const buyingProductsSnapshot = await getDocs(buyingProductsQuery);
//     return buyingProductsSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//   };

//   useEffect(() => {
//     const fetchMyLikesProducts = async () => {
//       if (!currentUser) return [];

//       const userLikesRef = doc(db, 'userLikes', currentUser.uid);
//       const userLikesDoc = await getDoc(userLikesRef);
//       if (!userLikesDoc.exists()) return [];

//       const myLikesProductIds = userLikesDoc.data().products;
//       const products = await Promise.all(myLikesProductIds.map(fetchProduct));
//       return products.filter((product) => product !== null); // Filter out null values if any product data is missing
//     };

//     fetchMyLikesProducts()
//       .then(setLikedProducts)
//       .catch((err) => {
//         console.error('Error fetching myLikes products:', err);
//       });

//     fetchMySellingProducts()
//       .then(setSellingProducts)
//       .catch((err) => {
//         console.error('Error fetching selling products:', err);
//       });

//     fetchMyBuyingProducts()
//       .then(setBuyingProducts)
//       .catch((err) => {
//         console.error('Error fetching buying products:', err);
//       });
//   }, [currentUser]);

//   const handleProductClick = (product) => {
//     window.open(`/detail/${product.id}`, '_blank', 'noopener,noreferrer');
//   };

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//           my: '30px',
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('watchlist')}
//         </Typography>
//       </Box>
//       <Grid container spacing={2}>
//         {likedProducts.length === 0 ? (
//           <Grid item xs={12}>
//             <Typography textAlign="center">{t('noWatchlistItems')}</Typography>
//           </Grid>
//         ) : (
//           likedProducts.map((product) => (
//             <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id}>
//               <Product
//                 key={product.id}
//                 product={product}
//                 onClick={() => handleProductClick(product)}
//               />
//             </Grid>
//           ))
//         )}
//       </Grid>

//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//           my: '30px',
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('mySellingProducts')}
//         </Typography>
//       </Box>
//       <Grid container spacing={2}>
//         {sellingProducts.length === 0 ? (
//           <Grid item xs={12}>
//             <Typography textAlign="center">{t('noSellingProducts')}</Typography>
//           </Grid>
//         ) : (
//           sellingProducts.map((product) => (
//             <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id}>
//               <Product
//                 key={product.id}
//                 product={product}
//                 onClick={() => handleProductClick(product)}
//               />
//             </Grid>
//           ))
//         )}
//       </Grid>

//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//           my: '30px',
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('myBuyingProducts')}
//         </Typography>
//       </Box>
//       <Grid container spacing={2}>
//         {buyingProducts.length === 0 ? (
//           <Grid item xs={12}>
//             <Typography textAlign="center">{t('noBuyingProducts')}</Typography>
//           </Grid>
//         ) : (
//           buyingProducts.map((product) => (
//             <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id}>
//               <Product
//                 key={product.id}
//                 product={product}
//                 onClick={() => handleProductClick(product)}
//               />
//             </Grid>
//           ))
//         )}
//       </Grid>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, getDoc, doc } from './firebaseConfig';
// import Product from './Product';
// import { Grid, Typography, Box } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// export default function HeartList() {
//   const { t, i18n } = useTranslation();
//   const [likedProducts, setLikedProducts] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const fetchProduct = async (id) => {
//     const productRef = doc(db, 'products', id);
//     const productDoc = await getDoc(productRef);
//     if (productDoc.exists()) {
//       return {
//         id: productDoc.id,
//         ...productDoc.data(),
//       };
//     } else {
//       return null;
//     }
//   };

//   useEffect(() => {
//     const fetchMyLikesProducts = async () => {
//       if (!currentUser) return [];

//       const userLikesRef = doc(db, 'userLikes', currentUser.uid);
//       const userLikesDoc = await getDoc(userLikesRef);
//       if (!userLikesDoc.exists()) return [];

//       const myLikesProductIds = userLikesDoc.data().products;
//       const products = await Promise.all(myLikesProductIds.map(fetchProduct));
//       return products.filter((product) => product !== null); // Filter out null values if any product data is missing
//     };

//     fetchMyLikesProducts()
//       .then(setLikedProducts)
//       .catch((err) => {
//         console.error('Error fetching myLikes products:', err);
//       });
//   }, [currentUser]);

//   const handleProductClick = (product) => {
//     window.open(`/detail/${product.id}`, '_blank', 'noopener,noreferrer');
//   };

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//           my: '30px',
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('watchlist')}
//         </Typography>
//       </Box>
//       <Grid container spacing={2}>
//         {likedProducts.length === 0 ? (
//           <Grid item xs={12}>
//             <Typography textAlign="center">{t('noWatchlistItems')}</Typography>
//           </Grid>
//         ) : (
//           likedProducts.map((product) => (
//             <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id}>
//               <Product
//                 key={product.id}
//                 product={product}
//                 onClick={() => handleProductClick(product)}
//               />
//             </Grid>
//           ))
//         )}
//       </Grid>
//     </Box>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, getDoc, doc } from './firebaseConfig';
// import Product from './Product';
// import { Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import InfiniteScroll from 'react-infinite-scroll-component';

// export default function HeartList() {
//   const { t, i18n } = useTranslation();
//   const [likedProducts, setLikedProducts] = useState([]);
//   const [hasMore, setHasMore] = useState(true);
//   const [currentPage, setCurrentPage] = useState(0);
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   const fetchProduct = async (id) => {
//     const productRef = doc(db, 'products', id);
//     const productDoc = await getDoc(productRef);
//     if (productDoc.exists()) {
//       return {
//         id: productDoc.id,
//         ...productDoc.data(),
//       };
//     } else {
//       return null;
//     }
//   };

//   const fetchMyLikesProducts = async (page = 0, limit = 10) => {
//     if (!currentUser) return [];

//     const userLikesRef = doc(db, 'userLikes', currentUser.uid);
//     const userLikesDoc = await getDoc(userLikesRef);
//     if (!userLikesDoc.exists()) return [];

//     const myLikesProductIds = userLikesDoc
//       .data()
//       .products.slice(page * limit, (page + 1) * limit);
//     const products = await Promise.all(myLikesProductIds.map(fetchProduct));
//     return products.filter((product) => product !== null); // Filter out null values if any product data is missing
//   };

//   useEffect(() => {
//     fetchMyLikesProducts()
//       .then((products) => {
//         setLikedProducts(products);
//         setHasMore(products.length > 0);
//       })
//       .catch((err) => {
//         console.error('Error fetching myLikes products:', err);
//       });
//   }, [currentUser]);

//   const fetchMoreData = () => {
//     fetchMyLikesProducts(currentPage + 1)
//       .then((products) => {
//         setLikedProducts((prevProducts) => [...prevProducts, ...products]);
//         setHasMore(products.length > 0);
//         setCurrentPage((prevPage) => prevPage + 1);
//       })
//       .catch((err) => {
//         console.error('Error fetching more products:', err);
//       });
//   };

//   const handleProductClick = (product) => {
//     window.open(`/detail/${product.id}`, '_blank', 'noopener,noreferrer');
//   };

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//       }}
//     >
//       <Box
//         sx={{
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//           my: '30px',
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('watchlist')}
//         </Typography>
//       </Box>
//       {isMobile ? (
//         <Box
//           id="parentScrollBox"
//           sx={{
//             marginTop: '20px',
//             height: 'calc(80vh - 70px)',
//             overflowX: 'auto',
//             whiteSpace: 'nowrap',
//           }}
//         >
//           <InfiniteScroll
//             dataLength={likedProducts.length}
//             next={fetchMoreData}
//             hasMore={hasMore}
//             loader={<h4>{t('loading')}</h4>}
//             endMessage={
//               <Typography textAlign="center">{t('noMoreProducts')}</Typography>
//             }
//             scrollableTarget="parentScrollBox"
//             horizontal={true}
//           >
//             {likedProducts.length === 0 ? (
//               <Typography textAlign="center">
//                 {t('noWatchlistItems')}
//               </Typography>
//             ) : (
//               <Grid container spacing={2}>
//                 {likedProducts.map((product) => (
//                   <Grid item xs={12} key={product.id}>
//                     <Product
//                       product={product}
//                       onClick={() => handleProductClick(product)}
//                       horizontal={isMobile} // pass the prop for mobile view
//                     />
//                   </Grid>
//                 ))}
//               </Grid>
//             )}
//           </InfiniteScroll>
//         </Box>
//       ) : (
//         <Grid container spacing={2} wrap="nowrap" sx={{ overflowX: 'auto' }}>
//           {likedProducts.length === 0 ? (
//             <Grid item xs={12}>
//               <Typography textAlign="center">
//                 {t('noWatchlistItems')}
//               </Typography>
//             </Grid>
//           ) : (
//             likedProducts.map((product) => (
//               <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id}>
//                 <Product
//                   key={product.id}
//                   product={product}
//                   onClick={() => handleProductClick(product)}
//                 />
//               </Grid>
//             ))
//           )}
//         </Grid>
//       )}
//     </Box>
//   );
// }

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db, getDoc, doc } from './firebaseConfig';
import Product from './Product';
import {
  Grid,
  Typography,
  Box,
  Container,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

function HeartList() {
  const { t, i18n } = useTranslation();
  const [likedProducts, setLikedProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const itemsPerPage = 10; // 페이지 당 아이템 수

  const fetchProduct = async (id) => {
    const productRef = doc(db, 'products', id);
    const productDoc = await getDoc(productRef);
    if (productDoc.exists()) {
      return {
        id: productDoc.id,
        ...productDoc.data(),
      };
    } else {
      return null;
    }
  };

  const fetchMyLikesProducts = async (page = 0, limit = itemsPerPage) => {
    if (!currentUser) return [];

    const userLikesRef = doc(db, 'userLikes', currentUser.uid);
    const userLikesDoc = await getDoc(userLikesRef);
    if (!userLikesDoc.exists()) return [];

    const myLikesProductIds = userLikesDoc
      .data()
      .products.slice(page * limit, (page + 1) * limit);
    const products = await Promise.all(myLikesProductIds.map(fetchProduct));
    return products.filter((product) => product !== null); // Filter out null values if any product data is missing
  };

  useEffect(() => {
    fetchMyLikesProducts()
      .then((products) => {
        setLikedProducts(products);
        setHasMore(products.length === itemsPerPage && products.length !== 0);
      })
      .catch((err) => {
        console.error('Error fetching myLikes products:', err);
      });
  }, [currentUser]);

  const fetchMoreData = () => {
    fetchMyLikesProducts(currentPage + 1)
      .then((products) => {
        setLikedProducts((prevProducts) => [...prevProducts, ...products]);
        setHasMore(products.length === itemsPerPage && products.length !== 0);
        setCurrentPage((prevPage) => prevPage + 1);
      })
      .catch((err) => {
        console.error('Error fetching more products:', err);
      });
  };

  const handleProductClick = (product) => {
    window.open(`/detail/${product.id}`, '_blank', 'noopener,noreferrer');
  };

  //   return (
  //     <Box
  //       p={3}
  //       sx={{
  //         width: '100%',
  //         maxWidth: '100%',
  //         margin: 'auto',
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           border: '0px solid #d3d3d3', // 연한 회색 테두리
  //           borderRadius: '16px', // 둥근 테두리
  //           padding: '8px 16px', // 안쪽 여백
  //           marginBottom: '16px', // 아래쪽 여백
  //           backgroundColor: 'transparent', // 연한 회색 배경색
  //           transition: 'transform 0.2s', // 호버 시 애니메이션
  //           '&:hover': {
  //             transform: 'scale(1.02)', // 호버 시 크기 증가
  //           },
  //           my: '30px',
  //         }}
  //       >
  //         <Typography variant="h4" component="h3" gutterBottom>
  //           {t('watchlist')}
  //         </Typography>
  //       </Box>
  //       {isMobile ? (
  //         <Box
  //           id="parentScrollBox"
  //           sx={{
  //             marginTop: '20px',
  //             height: 'calc(80vh - 70px)',
  //             overflowX: 'auto',
  //             whiteSpace: 'nowrap',
  //           }}
  //         >
  //           <InfiniteScroll
  //             dataLength={likedProducts.length}
  //             next={fetchMoreData}
  //             hasMore={hasMore}
  //             loader={<h4>{t('loading')}</h4>}
  //             endMessage={
  //               <Typography textAlign="center">{t('noMoreProducts')}</Typography>
  //             }
  //             scrollableTarget="parentScrollBox"
  //             horizontal={true}
  //           >
  //             {likedProducts.length === 0 ? (
  //               <Typography textAlign="center">
  //                 {t('noWatchlistItems')}
  //               </Typography>
  //             ) : (
  //               likedProducts.map((product) => (
  //                 <Grid item xs={12} key={product.id}>
  //                   <Product
  //                     product={product}
  //                     onClick={() => handleProductClick(product)}
  //                     horizontal={isMobile} // pass the prop for mobile view
  //                   />
  //                 </Grid>
  //               ))
  //             )}
  //           </InfiniteScroll>
  //         </Box>
  //       ) : (
  //         <Grid container spacing={2} wrap="nowrap" sx={{ overflowX: 'auto' }}>
  //           {likedProducts.length === 0 ? (
  //             <Grid item xs={12}>
  //               <Typography textAlign="center">
  //                 {t('noWatchlistItems')}
  //               </Typography>
  //             </Grid>
  //           ) : (
  //             likedProducts.map((product) => (
  //               <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id}>
  //                 <Product
  //                   key={product.id}
  //                   product={product}
  //                   onClick={() => handleProductClick(product)}
  //                 />
  //               </Grid>
  //             ))
  //           )}
  //         </Grid>
  //       )}
  //     </Box>
  //   );
  // }
  return (
    <Box
      sx={{
        width: '100vw', // ✅ 전체 너비
        minHeight: '100vh', // ✅ 전체 높이
        backgroundColor: theme.palette.background.default, // ✅ 전체 배경색 적용 (다크모드 지원)
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', // 중앙 정렬
        p: 3,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          // display: 'inline-block',
          //borderRadius: '12px',
          display: 'flex', // flexbox 사용
          justifyContent: 'center', // 가로 중앙 정렬
          flexDirection: 'column',
          // alignItems: 'center', // 세로 중앙 정렬 (필요한 경우)
          padding: '8px 16px',
          marginBottom: '16px',
          backgroundColor: theme.palette.background.paper,
          // transition: 'transform 0.2s',
          // '&:hover': {
          //   transform: 'scale(1.02)',
          // },
        }}
      >
        {/* 🔹 타이틀 박스 */}
        {/* <Box
          sx={{
            borderRadius: '12px',
            padding: '12px 2px',
            mb: 3,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              transform: 'scale(1.02)',
              boxShadow: theme.shadows[4],
            },
            textAlign: 'left',
          }}
        > */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            paddingBottom: theme.spacing(2),
            color: theme.palette.primary.main,
          }}
        >
          {t('watchlist')}
        </Typography>
        {/* </Box> */}

        {/* 🔹 모바일 & 데스크탑 반응형 */}
        {isMobile ? (
          <Box
            id="parentScrollBox"
            sx={{
              mt: 2,
              height: 'calc(80vh - 70px)',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <InfiniteScroll
              dataLength={likedProducts.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<Typography>{t('loading')}</Typography>}
              endMessage={
                <Typography
                  textAlign="center"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {t('noMoreProducts')}
                </Typography>
              }
              scrollableTarget="parentScrollBox"
              horizontal
            >
              {likedProducts.length === 0 ? (
                <Typography
                  textAlign="center"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {t('noWatchlistItems')}
                </Typography>
              ) : (
                <Grid container spacing={2} wrap="nowrap">
                  {likedProducts.map((product) => (
                    <Grid item xs={12} key={product.id}>
                      <Product
                        product={product}
                        onClick={() => handleProductClick(product)}
                        horizontal
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </InfiniteScroll>
          </Box>
        ) : (
          <Grid container spacing={2} wrap="nowrap" sx={{ overflowX: 'auto' }}>
            {likedProducts.length === 0 ? (
              <Grid item xs={12}>
                <Typography
                  textAlign="center"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {t('noWatchlistItems')}
                </Typography>
              </Grid>
            ) : (
              likedProducts.map((product) => (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={product.id}>
                  <Product
                    product={product}
                    onClick={() => handleProductClick(product)}
                  />
                </Grid>
              ))
            )}
          </Grid>
        )}
      </Container>
    </Box>
  );
}

export default HeartList;
