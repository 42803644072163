// import React, { useEffect, useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import {
//   Card,
//   CardActionArea,
//   CardContent,
//   CardMedia,
//   Box,
//   Typography,
//   Chip,
//   Tooltip,
//   IconButton,
//   useTheme,
//   useMediaQuery,
// } from '@mui/material';
// import {
//   Favorite as FavoriteIcon,
//   Visibility as VisibilityIcon,
//   Chat as ChatIcon,
//   Storefront as StorefrontIcon,
//   Group as GroupIcon,
//   Star as StarIcon,
//   ShoppingCart as ShoppingCartIcon,
// } from '@mui/icons-material';
// import { useTranslation } from 'react-i18next';
// import StoreRatings from './StoreRatings';
// import { useGlobalContext } from '../useGlobalContext';

// const StoreCard = ({ store, horizontal = false, onClick }) => {
//   const theme = useTheme();
//   const { t } = useTranslation();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const { pcAspectRatio, mobileAspectRatio, storeDefaultImage } =
//     useGlobalContext();
//   const [statusLabel, setStatusLabel] = useState('');

//   // ✅ 이미지 URL 설정 (기본 이미지 적용)
//   const imageUrl = store?.mainImages?.[0] || storeDefaultImage;

//   useEffect(() => {
//     if (!store) return;

//     // 현재 요일과 시간에 따른 스토어 운영 여부 계산
//     const now = new Date();
//     const day = now.getDay(); // 0: 일요일, 6: 토요일
//     const open =
//       day === 0 || day === 6
//         ? isOpen(store.weekendStartTime, store.weekendEndTime)
//         : isOpen(store.weekdayStartTime, store.weekdayEndTime);

//     setStatusLabel(open ? t('storeOpen') : t('storeClosed'));
//   }, [store, t]);

//   // ✅ 스토어 운영 상태 체크
//   const isOpen = (startTime, endTime) => {
//     if (!startTime || !endTime) return false;
//     const now = new Date();
//     const start = new Date(startTime.toDate ? startTime.toDate() : startTime);
//     const end = new Date(endTime.toDate ? endTime.toDate() : endTime);
//     start.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
//     end.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());

//     return now >= start && now <= end;
//   };

//   // ✅ 좋아요, 조회수, 채팅 정보 표시
//   const renderStoreStats = () => (
//     <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//       <Tooltip title={t('likes')}>
//         <IconButton size="small">
//           <FavoriteIcon fontSize="small" color="error" />
//           <Typography variant="caption" sx={{ ml: 0.5 }}>
//             {store.likes}
//           </Typography>
//         </IconButton>
//       </Tooltip>
//       <Tooltip title={t('views')}>
//         <IconButton size="small">
//           <VisibilityIcon fontSize="small" color="action" />
//           <Typography variant="caption" sx={{ ml: 0.5 }}>
//             {store.views}
//           </Typography>
//         </IconButton>
//       </Tooltip>
//       <Tooltip title={t('chat')}>
//         <IconButton size="small">
//           <ChatIcon fontSize="small" color="primary" />
//           <Typography variant="caption" sx={{ ml: 0.5 }}>
//             {store.chats}
//           </Typography>
//         </IconButton>
//       </Tooltip>
//     </Box>
//   );

//   // ✅ 모바일 버전 렌더링
//   const renderMobileView = () => (
//     <Box
//       display="flex"
//       flexDirection="row"
//       sx={{ height: { xs: 'auto', sm: '20vh', md: '22vh' } }}
//     >
//       {/* 이미지 섹션 */}
//       <CardMedia
//         component="img"
//         image={imageUrl}
//         alt={store.storeName}
//         sx={{
//           width: '40%',
//           height: '100%',
//           objectFit: 'cover',
//           aspectRatio: mobileAspectRatio,
//           borderRadius: '8px 0 0 8px',
//         }}
//       />

//       {/* 텍스트 & 정보 섹션 */}
//       <CardContent
//         sx={{
//           width: '60%',
//           padding: 2,
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//         }}
//       >
//         <Typography
//           variant="h6"
//           sx={{
//             fontWeight: 600,
//             textAlign: 'left',
//             whiteSpace: 'nowrap',
//             overflow: 'hidden',
//             textOverflow: 'ellipsis',
//           }}
//         >
//           {store.storeName}
//         </Typography>
//         <Typography
//           variant="body2"
//           sx={{
//             textAlign: 'left',
//             color: theme.palette.text.secondary,
//             whiteSpace: 'nowrap',
//             overflow: 'hidden',
//             textOverflow: 'ellipsis',
//           }}
//         >
//           {store.description}
//         </Typography>
//         {renderStoreStats()}
//       </CardContent>
//     </Box>
//   );

//   // ✅ PC 버전 렌더링
//   const renderPCView = () => (
//     <>
//       <CardMedia
//         component="img"
//         image={imageUrl}
//         alt={store.storeName}
//         sx={{
//           backgroundImage: `url(${imageUrl})`,
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           objectFit: 'cover',
//           m: theme.spacing(0), //사진과 박스의 간격
//           aspectRatio: pcAspectRatio,
//           borderRadius: theme.shape.borderRadius,
//         }}
//       />

//       <CardContent sx={{ paddingTop: 2, paddingBottom: 2 }}>
//         <Typography
//           variant="h6"
//           sx={{
//             fontWeight: 600,
//             textAlign: 'left',
//             whiteSpace: 'nowrap',
//             overflow: 'hidden',
//             textOverflow: 'ellipsis',
//           }}
//         >
//           {store.storeName}
//         </Typography>
//         <Typography
//           variant="body2"
//           sx={{
//             textAlign: 'left',
//             color: theme.palette.text.secondary,
//             whiteSpace: 'nowrap',
//             overflow: 'hidden',
//             textOverflow: 'ellipsis',
//           }}
//         >
//           {store.description}
//         </Typography>
//         <StoreRatings storeId={store.id} />
//         {renderStoreStats()}
//       </CardContent>
//     </>
//   );

//   return (
//     <Card
//       sx={{
//         width: '100%',
//         backgroundColor: theme.palette.background.paper,
//         color: theme.palette.text.primary,
//         overflow: 'hidden',
//         borderRadius: 2,
//         boxShadow: theme.shadows[3],
//         m: 1,
//       }}
//     >
//       <CardActionArea
//         component={RouterLink}
//         to={`/store_home/${store.id}`}
//         onClick={onClick}
//       >
//         {isMobile ? renderMobileView() : renderPCView()}
//       </CardActionArea>
//     </Card>
//   );
// };

// export default StoreCard;

import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Box,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Favorite as FavoriteIcon,
  Visibility as VisibilityIcon,
  Chat as ChatIcon,
  Storefront as StorefrontIcon,
  Group as GroupIcon,
  Star as StarIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import StoreRatings from './StoreRatings';
import { useGlobalContext } from '../useGlobalContext';

const StoreCard = ({ store, horizontal = false, onClick }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { pcAspectRatio, mobileAspectRatio, storeDefaultImage } =
    useGlobalContext();
  const [statusLabel, setStatusLabel] = useState('');

  // ✅ 이미지 URL 설정 (기본 이미지 적용)
  const imageUrl = store?.mainImages?.[0] || storeDefaultImage;
  const userAvatar = store?.ownerImage || storeDefaultImage;

  useEffect(() => {
    if (!store) return;

    // 현재 요일과 시간에 따른 스토어 운영 여부 계산
    const now = new Date();
    const day = now.getDay(); // 0: 일요일, 6: 토요일
    const open =
      day === 0 || day === 6
        ? isOpen(store.weekendStartTime, store.weekendEndTime)
        : isOpen(store.weekdayStartTime, store.weekdayEndTime);

    setStatusLabel(open ? t('storeOpen') : t('storeClosed'));
  }, [store, t]);

  // ✅ 스토어 운영 상태 체크
  const isOpen = (startTime, endTime) => {
    if (!startTime || !endTime) return false;
    const now = new Date();
    const start = new Date(startTime.toDate ? startTime.toDate() : startTime);
    const end = new Date(endTime.toDate ? endTime.toDate() : endTime);
    start.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
    end.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());

    return now >= start && now <= end;
  };

  // ✅ 좋아요, 조회수, 채팅 정보 표시
  const renderStoreStats = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Tooltip title={t('likes')}>
        <IconButton size="small">
          <FavoriteIcon fontSize="small" color="error" />
          <Typography variant="caption" sx={{ ml: 0.5 }}>
            {store.likes}
          </Typography>
        </IconButton>
      </Tooltip>
      <Tooltip title={t('views')}>
        <IconButton size="small">
          <VisibilityIcon fontSize="small" color="action" />
          <Typography variant="caption" sx={{ ml: 0.5 }}>
            {store.views}
          </Typography>
        </IconButton>
      </Tooltip>
      <Tooltip title={t('chat')}>
        <IconButton size="small">
          <ChatIcon fontSize="small" color="primary" />
          <Typography variant="caption" sx={{ ml: 0.5 }}>
            {store.chats}
          </Typography>
        </IconButton>
      </Tooltip>
    </Box>
  );

  // ✅ 모바일 버전 렌더링
  const renderMobileView = () => (
    <Box
      display="flex"
      flexDirection="row"
      sx={{ height: { xs: 'auto', sm: '20vh', md: '22vh' } }}
    >
      {/* 이미지 섹션 */}
      <CardMedia
        component="img"
        image={imageUrl}
        alt={store.storeName}
        sx={{
          width: '40%',
          height: '100%',
          objectFit: 'cover',
          aspectRatio: mobileAspectRatio,
          borderRadius: theme.shape.borderRadius,
        }}
      />

      {/* 텍스트 & 정보 섹션 */}
      <CardContent
        sx={{
          width: '60%',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {/* 🔹 스토어 이름 & 평점 */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <Avatar
              src={userAvatar}
              alt={store.storeName}
              sx={{ width: 32, height: 32 }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {store.storeName}
            </Typography>
          </Box>
          <StoreRatings storeId={store.id} />
        </Box>

        {/* 🔹 스토어 설명 */}
        <Typography
          variant="body2"
          sx={{
            textAlign: 'left',
            color: theme.palette.text.secondary,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {store.description}
        </Typography>

        {/* 🔹 구독자 수 & 좋아요, 조회수, 채팅 정보 */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={1}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <GroupIcon fontSize="small" color="primary" />
            <Typography variant="body2">
              {store.subscribersCount} {t('subscribers')}
            </Typography>
          </Box>
          {renderStoreStats()}
        </Box>
      </CardContent>
    </Box>
  );

  // ✅ PC 버전 렌더링
  const renderPCView = () => (
    <>
      <CardMedia
        component="img"
        image={imageUrl}
        alt={store.storeName}
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          objectFit: 'cover',
          aspectRatio: pcAspectRatio,
          borderRadius: theme.shape.borderRadius,
        }}
      />

      <CardContent
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        {/* 🔹 스토어 이름 & 평점 */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <Avatar
              src={userAvatar}
              alt={store.storeName}
              sx={{ width: 40, height: 40 }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {store.storeName}
            </Typography>
          </Box>
          <StoreRatings storeId={store.id} />
        </Box>

        {/* 🔹 스토어 설명 */}
        <Typography
          variant="body2"
          sx={{
            textAlign: 'left',
            color: theme.palette.text.secondary,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {store.description}
        </Typography>

        {/* 🔹 구독자 수 & 좋아요, 조회수, 채팅 정보 */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={1}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <GroupIcon fontSize="small" color="primary" />
            <Typography variant="body2">
              {store.subscribersCount} {t('subscribers')}
            </Typography>
          </Box>
          {renderStoreStats()}
        </Box>
      </CardContent>
    </>
  );

  return (
    <Card
      sx={{
        m: theme.spacing(0),
        p: theme.spacing(0),
        boxShadow: theme.shadows[3],
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <CardActionArea
        component={RouterLink}
        to={`/store_home/${store.id}`}
        onClick={onClick}
      >
        {/* {isMobile ? renderMobileView() : renderPCView()} */}
        {renderPCView()}
      </CardActionArea>
    </Card>
  );
};

export default StoreCard;
