// store.ts

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer.js';
import searchKeywordReducer from './reducers/searchReducer.js';
import editUserReducer from './reducers/editUserReducer.js';
import orderReducer from './reducers/orderReducer.js';
import floatingSidebarReducer from './reducers/floatingSidebarReducer.js';
import cartReducer from './reducers/cartReducer.js';
import appReducer from './reducers/appReducer';
import weatherReducer from './reducers/weatherReducer';
import townReducer from './reducers/townReducer';

const store = configureStore({
  reducer: {
    app: appReducer,
    weather: weatherReducer,
    auth: authReducer,
    search: searchKeywordReducer,
    edit: editUserReducer,
    order: orderReducer,
    floatingSidebar: floatingSidebarReducer,
    cart: cartReducer,
    town: townReducer,
  },
});
export type AppStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
