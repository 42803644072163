// import React, { useEffect, useState } from 'react';
// import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import {
//   Box,
//   Typography,
//   CircularProgress,
//   Grid,
//   Button,
//   Container,
//   useTheme,
//   Tabs,
//   Tab,
//   useMediaQuery,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import Product from './Product';
// import StoreProduct from './Stores/StoreProduct';
// import NoUserProducts from './NoUserProducts';
// import NoStoreProducts from './NoStoreProducts';
// import LeftSideMenu from './LeftSideMenu';
// import ListHeader from './Common/ListHeader';
// import useUserActivity from './useUserActivity';
// import useStoreUserActivity from './Stores/useStoreUserActivity';
// import {
//   exportFetchSelectedTownByUserId,
//   exportFetchUserProductsByCoordinates,
//   exportFetchStoreProductsByCoordinates,
// } from './Utils/LocationStoresAndProducts';
// import { JSONRPC_ERR_DISCONNECTED } from 'web3';

// const PAGE_SIZE = 12;

// const ProductSearchResults = () => {
//   const theme = useTheme();
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
//   const searchKeyword = searchParams.get('keyword') || '';
//   const weatherEventId = searchParams.get('weather') || null;
//   const categoryId = searchParams.get('category') || null;
//   const brandId = searchParams.get('brand') || null;
//   const latitude = parseFloat(searchParams.get('latitude')) || null;
//   const longitude = parseFloat(searchParams.get('longitude')) || null;
//   const radius = parseFloat(searchParams.get('radius')) || 3;
//   const initialSort = searchParams.get('sort') || 'latest';
//   const stateStrings = searchParams.get('state')?.split(',') || ['selling'];

//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const [products, setProducts] = useState([]);
//   const [storeProducts, setStoreProducts] = useState([]);
//   const [userLastDoc, setUserLastDoc] = useState(null);
//   const [storeLastDoc, setStoreLastDoc] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [tab, setTab] = useState(0);
//   const [sort, setSort] = useState(initialSort);

//   const { logViewedProduct, logSearchedKeyword } = useUserActivity(
//     currentUser?.uid
//   );
//   const { logViewedStoreProduct } = useStoreUserActivity(currentUser?.uid);

//   // useEffect(() => {
//   //   if (searchKeyword || categoryId || brandId || weatherEventId) {
//   //     fetchProducts(true);
//   //     if (searchKeyword) logSearchedKeyword(searchKeyword);
//   //   }
//   // }, [
//   //   searchKeyword,
//   //   weatherEventId,
//   //   categoryId,
//   //   brandId,
//   //   latitude,
//   //   longitude,
//   //   radius,
//   //   sort,
//   // ]);

//   useEffect(() => {
//     const keyword = searchParams.get('keyword') || '';
//     const categoryId = searchParams.get('category') || null;
//     const brandId = searchParams.get('brand') || null;
//     const weatherId = searchParams.get('weather') || null;
//     const sort = searchParams.get('sort') || 'latest';
//     const stateList = searchParams.get('state')?.split(',') || [];

//     const minPrice = searchParams.get('minPrice');
//     const maxPrice = searchParams.get('maxPrice');

//     const radius = searchParams.get('radius');
//     const latitude = searchParams.get('latitude');
//     const longitude = searchParams.get('longitude');

//     fetchFilteredProducts({
//       keyword,
//       categoryId,
//       brandId,
//       weatherId,
//       sort,
//       states: stateList,
//       minPrice,
//       maxPrice,
//       radius,
//       latitude,
//       longitude,
//     });

//     if (keyword) {
//       logSearchedKeyword(keyword);
//     }
//   }, [searchParams.toString()]);

//   const fetchProducts = async (reset = false) => {
//     setLoading(true);

//     const keyword = searchParams.get('keyword') || '';
//     const weatherEventId = searchParams.get('weather');
//     const categoryId = searchParams.get('category');
//     const brandId = searchParams.get('brand');
//     const minPrice = parseFloat(searchParams.get('minPrice')) || null;
//     const maxPrice = parseFloat(searchParams.get('maxPrice')) || null;
//     const radius = parseFloat(searchParams.get('radius')) || 5000;
//     const lat = parseFloat(searchParams.get('latitude'));
//     const lng = parseFloat(searchParams.get('longitude'));

//     const locationInfo =
//       lat && lng
//         ? { lat, lng, radius }
//         : await exportFetchSelectedTownByUserId(currentUser?.uid);

//     try {
//       const userResult = await fetchUserProducts(
//         keyword,
//         categoryId,
//         brandId,
//         weatherEventId,
//         minPrice,
//         maxPrice,
//         locationInfo,
//         userLastDoc
//       );

//       const storeResult = await fetchStoreProducts(
//         keyword,
//         categoryId,
//         brandId,
//         weatherEventId,
//         minPrice,
//         maxPrice,
//         locationInfo,
//         storeLastDoc
//       );

//       if (reset) {
//         setProducts(userResult.products);
//         setStoreProducts(storeResult.products);
//       } else {
//         setProducts((prev) => [...prev, ...userResult.products]);
//         setStoreProducts((prev) => [...prev, ...storeResult.products]);
//       }

//       setUserLastDoc(userResult.lastDoc);
//       setStoreLastDoc(storeResult.lastDoc);
//     } catch (err) {
//       console.error('검색 오류:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // const fetchUserProducts = async (keyword, location, lastDoc) => {
//   //   const result = await exportFetchUserProductsByCoordinates(
//   //     location?.lat,
//   //     location?.lng,
//   //     location?.radius,
//   //     currentUser?.uid,
//   //     PAGE_SIZE,
//   //     lastDoc
//   //   );

//   //   const filtered = result.filter(
//   //     (item) =>
//   //       item.title?.toLowerCase().includes(keyword.toLowerCase()) ||
//   //       item.content?.toLowerCase().includes(keyword.toLowerCase())
//   //   );

//   //   const sorted = [...filtered];
//   //   switch (sort) {
//   //     case 'latest':
//   //       sorted.sort((a, b) => b.createdAt - a.createdAt);
//   //       break;
//   //     case 'lowPrice':
//   //       sorted.sort((a, b) => a.price - b.price);
//   //       break;
//   //     case 'highPrice':
//   //       sorted.sort((a, b) => b.price - a.price);
//   //       break;
//   //     default:
//   //       break;
//   //   }

//   //   return {
//   //     products: sorted,
//   //     lastDoc: result.lastDoc || null,
//   //   };
//   // };

//   // const fetchStoreProducts = async (keyword, location, lastDoc) => {
//   //   const result = await exportFetchStoreProductsByCoordinates(
//   //     location?.lat,
//   //     location?.lng,
//   //     location?.radius,
//   //     PAGE_SIZE,
//   //     lastDoc
//   //   );

//   //   const filtered = Object.values(result.products || {}).filter(
//   //     (item) =>
//   //       item.title?.toLowerCase().includes(keyword.toLowerCase()) ||
//   //       item.description?.toLowerCase().includes(keyword.toLowerCase())
//   //   );

//   //   const sorted = [...filtered];
//   //   switch (sort) {
//   //     case 'latest':
//   //       sorted.sort((a, b) => b.createdAt - a.createdAt);
//   //       break;
//   //     case 'lowPrice':
//   //       sorted.sort((a, b) => a.price - b.price);
//   //       break;
//   //     case 'highPrice':
//   //       sorted.sort((a, b) => b.price - a.price);
//   //       break;
//   //     default:
//   //       break;
//   //   }

//   //   return {
//   //     products: sorted,
//   //     lastDoc: result.lastDoc || null,
//   //   };
//   // };

//   const fetchUserProducts = async (
//     keyword,
//     categoryId,
//     brandId,
//     weatherEventId,
//     minPrice,
//     maxPrice,
//     location,
//     lastDoc
//   ) => {
//     const result = await exportFetchUserProductsByCoordinates(
//       location?.lat,
//       location?.lng,
//       location?.radius,
//       currentUser?.uid,
//       PAGE_SIZE,
//       lastDoc
//     );

//     const filtered = result.filter((product) => {
//       const titleMatch = product.title
//         ?.toLowerCase()
//         .includes(keyword.toLowerCase());
//       const contentMatch = product.content
//         ?.toLowerCase()
//         .includes(keyword.toLowerCase());

//       const categoryMatch = categoryId
//         ? product.categoryId === categoryId
//         : true;
//       const brandMatch = brandId ? product.brandId === brandId : true;
//       const weatherMatch = weatherEventId
//         ? product.weatherEvent === weatherEventId
//         : true;
//       const priceMatch =
//         (!minPrice || product.price >= minPrice) &&
//         (!maxPrice || product.price <= maxPrice);

//       const allowedStates = stateStrings.map((s) =>
//         s === 'selling' ? 0 : s === 'reserved' ? 1 : s === 'sold-out' ? 2 : null
//       );
//       const sellingStateMatch = allowedStates.includes(product.state);

//       return (
//         (titleMatch || contentMatch) &&
//         categoryMatch &&
//         brandMatch &&
//         weatherMatch &&
//         priceMatch &&
//         sellingStateMatch
//       );
//     });

//     return {
//       products: filtered,
//       lastDoc: result.lastDoc || null,
//     };
//   };

//   const fetchStoreProducts = async (
//     keyword,
//     categoryId,
//     brandId,
//     weatherId,
//     minPrice,
//     maxPrice,
//     location,
//     lastDoc
//   ) => {
//     const result = await exportFetchStoreProductsByCoordinates(
//       location?.lat,
//       location?.lng,
//       location?.radius,
//       PAGE_SIZE,
//       lastDoc
//     );

//     const filtered = Object.values(result.products || {}).filter((product) => {
//       const titleMatch = product.title
//         ?.toLowerCase()
//         .includes(keyword.toLowerCase());
//       const descMatch = product.description
//         ?.toLowerCase()
//         .includes(keyword.toLowerCase());

//       const categoryMatch = categoryId
//         ? product.categoryId === categoryId
//         : true;
//       const brandMatch = brandId ? product.brandId === brandId : true;
//       const weatherMatch = weatherId ? product.weatherId === weatherId : true;
//       const priceMatch =
//         (!minPrice || product.price >= minPrice) &&
//         (!maxPrice || product.price <= maxPrice);

//       const allowedStates = stateStrings.map((s) =>
//         s === 'selling' ? 0 : s === 'reserved' ? 1 : s === 'sold-out' ? 2 : null
//       );
//       const sellingStateMatch = allowedStates.includes(product.state);
//       return (
//         (titleMatch || descMatch) &&
//         categoryMatch &&
//         brandMatch &&
//         weatherMatch &&
//         priceMatch &&
//         sellingStateMatch
//       );
//     });

//     return {
//       products: filtered,
//       lastDoc: result.lastDoc || null,
//     };
//   };

//   // const handleSelectFilter = (key, value) => {
//   //   const params = new URLSearchParams(searchParams.toString());

//   //   if (key === 'keyword' && value) {
//   //     params.set(key, value);
//   //     //alert(key + ' ' + value);
//   //   } else if (key === 'priceRange') {
//   //     params.set('minPrice', value[0]);
//   //     params.set('maxPrice', value[1]);
//   //     //alert(value[0] + ' ' + value[1]);
//   //   } else if (key === 'radius' && value?.location) {
//   //     params.set('radius', value.radius);
//   //     params.set('latitude', value.location.lat);
//   //     params.set('longitude', value.location.lng);
//   //     //alert(
//   //     //  value.radius + ' 1111 ' + value.location.lat + ' ' + value.location.lng
//   //     //);
//   //   } else if (key === 'radius' && value) {
//   //     params.set(key, value);
//   //     //alert(key + ' ' + value);
//   //   } else if (key === 'category' && value?.id) {
//   //     params.set(key, value.id);
//   //     //alert(key + ' ' + value.id);
//   //   } else if (key === 'brand' && value?.id) {
//   //     params.set(key, value.id);
//   //     //alert(key + ' ' + value.id);
//   //   } else if (key === 'weather' && value?.id) {
//   //     params.set(key, value.id);
//   //     //alert(key + ' ' + value.id);
//   //   } else if (key === 'state') {
//   //     params.set(key, value);
//   //     //alert(key + ' ' + value);
//   //   } else if (typeof value === 'boolean') {
//   //     params.set(key, value);
//   //     //alert(key + ' 22222 ' + value);
//   //   } else {
//   //     params.delete(key);
//   //     //alert(key + ' 33333 ' + value);
//   //   }

//   //   setSearchParams(params);
//   // };
//   const handleSelectFilter = (key, value) => {
//     const params = new URLSearchParams(searchParams.toString());

//     const filterHandlers = {
//       keyword: (val) => val && params.set('keyword', val),

//       priceRange: ([min, max]) => {
//         params.set('minPrice', min);
//         params.set('maxPrice', max);
//       },

//       radius: (val) => {
//         if (val?.location) {
//           params.set('radius', val.radius);
//           params.set('latitude', val.location.lat);
//           params.set('longitude', val.location.lng);
//         } else if (val) {
//           params.set('radius', val);
//         }
//       },

//       category: (val) => val?.id && params.set('category', val.id),

//       brand: (val) => val?.id && params.set('brand', val.id),

//       weather: (val) => val?.id && params.set('weather', val.id),

//       state: (val) => {
//         if (Array.isArray(val) && val.length > 0) {
//           params.set('state', val.join(','));
//         } else {
//           params.delete('state');
//         }
//       },

//       sort: (val) => params.set('sort', val),

//       default: (val) => {
//         if (typeof val === 'boolean') {
//           params.set(key, val);
//         } else if (val === null || val === undefined || val === '') {
//           params.delete(key);
//         } else {
//           params.set(key, val);
//         }
//       },
//     };

//     const handler = filterHandlers[key] || filterHandlers.default;
//     handler(value);

//     setSearchParams(params);
//   };

//   const handleTabChange = (event, newValue) => {
//     setTab(newValue);
//   };

//   const handleSortChange = (value) => {
//     setSort(value);
//     const params = new URLSearchParams(searchParams.toString());
//     params.set('sort', value);
//     setSearchParams(params);
//   };
//   const handleUserProductClick = (product) => {
//     navigate(`/detail/${product.id}`);
//     logViewedProduct(product.id);
//   };

//   const handleStoreProductClick = (product) => {
//     navigate(`/product_detail/${product.uid}/${product.id}`);
//     logViewedProduct(product.id);
//   };

//   return (
//     <Box display="flex">
//       {!isMobile && <LeftSideMenu onSelectFilter={handleSelectFilter} />}
//       <Box flex={1} p={2} bgcolor={theme.palette.background.default}>
//         <Typography
//           variant="h5"
//           fontWeight="bold"
//           mb={3}
//           sx={{ color: theme.palette.text.secondary }}
//         >
//           🔍 {`"${searchKeyword}"`}
//         </Typography>

//         <Box
//           display="flex"
//           alignItems="center"
//           justifyContent="space-between"
//           mb={2}
//         >
//           <Tabs
//             value={tab}
//             onChange={handleTabChange}
//             sx={{ flexGrow: 1 }}
//             variant="fullWidth"
//             TabIndicatorProps={{
//               style: {
//                 height: 3,
//                 backgroundColor: theme.palette.primary.main,
//               },
//             }}
//           >
//             <Tab
//               label={`${t('searchResults.userProducts')} (${products.length})`}
//               sx={{ flex: 1, textTransform: 'none' }}
//             />
//             <Tab
//               label={`${t('searchResults.storeProducts')} (${
//                 storeProducts.length
//               })`}
//               sx={{ flex: 1, textTransform: 'none' }}
//             />
//           </Tabs>
//         </Box>

//         <ListHeader
//           // title={t(
//           //   tab === 0
//           //     ? 'searchResults.userProducts'
//           //     : 'searchResults.storeProducts'
//           // )}
//           title=""
//           showFilter
//           sortOption={sort}
//           handleSortChange={handleSortChange}
//           type={tab === 0 ? 'userProducts' : 'storeProducts'}
//         />

//         {loading ? (
//           <Box textAlign="center" mt={4}>
//             <CircularProgress />
//           </Box>
//         ) : tab === 0 ? (
//           <>
//             <Grid container spacing={2} wrap="wrap">
//               {products.length > 0 ? (
//                 products.map((product) => (
//                   <Grid
//                     item
//                     // xs={12}
//                     // sm={6}
//                     // md={4}
//                     // lg={3}
//                     // xl={2}
//                     sx={{
//                       flex: '0 0 16.66%', // 6개 (100 ÷ 6)
//                       maxWidth: '16.66%',
//                       '@media (max-width:2700px)': {
//                         flex: '0 0 20%', // 5개
//                         maxWidth: '20%',
//                       },
//                       '@media (max-width:1899px)': {
//                         flex: '0 0 25%', // 4개
//                         maxWidth: '25%',
//                       },
//                       '@media (max-width:1279px)': {
//                         flex: '0 0 33.33%', // 3개
//                         maxWidth: '33.33%',
//                       },
//                       '@media (max-width:899px)': {
//                         flex: '0 0 50%', // 2개
//                         maxWidth: '50%',
//                       },
//                       '@media (max-width:599px)': {
//                         flex: '0 0 100%', // 1개
//                         maxWidth: '100%',
//                       },
//                     }}
//                     key={product.id}
//                   >
//                     <Product
//                       product={product}
//                       onClick={() => handleUserProductClick(product)}
//                     />
//                   </Grid>
//                 ))
//               ) : (
//                 <Grid item xs={12} sx={{ height: '100%' }}>
//                   <NoUserProducts />
//                 </Grid>
//               )}
//             </Grid>
//             {userLastDoc && (
//               <Button
//                 variant="contained"
//                 onClick={() => fetchProducts(false)}
//                 sx={{ mt: 2 }}
//               >
//                 {t('loadMore')}
//               </Button>
//             )}
//           </>
//         ) : (
//           <>
//             <Grid container spacing={2} wrap="wrap">
//               {storeProducts.length > 0 ? (
//                 storeProducts.map((product) => (
//                   <Grid
//                     item
//                     // xs={12}
//                     // sm={6}
//                     // md={4}
//                     // lg={3}
//                     // xl={2}
//                     sx={{
//                       flex: '0 0 16.66%', // 6개 (100 ÷ 6)
//                       maxWidth: '16.66%',
//                       '@media (max-width:2700px)': {
//                         flex: '0 0 20%', // 5개
//                         maxWidth: '20%',
//                       },
//                       '@media (max-width:1899px)': {
//                         flex: '0 0 25%', // 4개
//                         maxWidth: '25%',
//                       },
//                       '@media (max-width:1279px)': {
//                         flex: '0 0 33.33%', // 3개
//                         maxWidth: '33.33%',
//                       },
//                       '@media (max-width:899px)': {
//                         flex: '0 0 50%', // 2개
//                         maxWidth: '50%',
//                       },
//                       '@media (max-width:599px)': {
//                         flex: '0 0 100%', // 1개
//                         maxWidth: '100%',
//                       },
//                     }}
//                     key={product.id}
//                   >
//                     <StoreProduct
//                       product={product}
//                       onClick={() => handleStoreProductClick(product)}
//                     />
//                   </Grid>
//                 ))
//               ) : (
//                 <NoStoreProducts />
//               )}
//             </Grid>
//             {/* {storeLastDoc && (
//               <Button
//                 variant="contained"
//                 onClick={() => fetchProducts(false)}
//                 sx={{ mt: 2 }}
//               >
//                 {t('loadMore')}
//               </Button>
//             )} */}
//           </>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default ProductSearchResults;

// Refactored ProductSearchResults.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Button,
  useTheme,
  Tabs,
  Tab,
  useMediaQuery,
  Fade,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Product from './Product';
import StoreProduct from './Stores/StoreProduct';
import NoUserProducts from './NoUserProducts';
import NoStoreProducts from './NoStoreProducts';
import LeftSideMenu from './LeftSideMenu';
import ListHeader from './Common/ListHeader';
import useUserActivity from './useUserActivity';
import useStoreUserActivity from './Stores/useStoreUserActivity';
import {
  exportFetchSelectedTownByUserId,
  exportFetchUserProductsByCoordinates,
  exportFetchStoreProductsByCoordinates,
} from './Utils/LocationStoresAndProducts';
import useSearchFilters from './useSearchFilters';
import { SkeletonProductGrid } from './Skeleton/SkeletonProductGrid';

const PAGE_SIZE = 12;

const ProductSearchResults = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { logViewedProduct, logSearchedKeyword } = useUserActivity(
    currentUser?.uid
  );
  const { logViewedStoreProduct } = useStoreUserActivity(currentUser?.uid);

  const { searchParams, handleSelectFilter, getFilterParams } =
    useSearchFilters();

  const [products, setProducts] = useState([]);
  const [storeProducts, setStoreProducts] = useState([]);
  const [userLastDoc, setUserLastDoc] = useState(null);
  const [storeLastDoc, setStoreLastDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [sort, setSort] = useState(searchParams.get('sort') || 'latest');

  const filters = getFilterParams();
  const stableFilters = JSON.stringify(filters);

  useEffect(() => {
    fetchFilteredProducts(true);
    if (filters.keyword) logSearchedKeyword(filters.keyword);
  }, [stableFilters]);

  const filterKey = React.useMemo(() => {
    const {
      keyword,
      categoryId,
      brandId,
      weatherId,
      sort,
      states,
      priceRange,
      latitude,
      longitude,
      radius,
    } = getFilterParams();

    return JSON.stringify({
      keyword,
      categoryId,
      brandId,
      weatherId,
      sort,
      states,
      priceRange,
      latitude,
      longitude,
      radius,
    });
  }, [searchParams.toString()]);

  const fetchFilteredProducts = async (reset = false) => {
    setLoading(true);
    const {
      keyword,
      categoryId,
      brandId,
      weatherId,
      minPrice,
      maxPrice,
      radius,
      latitude,
      longitude,
      states,
    } = getFilterParams();

    const location =
      latitude && longitude
        ? { lat: latitude, lng: longitude, radius }
        : await exportFetchSelectedTownByUserId(currentUser?.uid);

    try {
      const userResult = await fetchUserProducts(
        keyword,
        categoryId,
        brandId,
        weatherId,
        minPrice,
        maxPrice,
        location,
        userLastDoc,
        states
      );

      const storeResult = await fetchStoreProducts(
        keyword,
        categoryId,
        brandId,
        weatherId,
        minPrice,
        maxPrice,
        location,
        storeLastDoc,
        states
      );

      if (reset) {
        if (
          JSON.stringify(userResult.products) !== JSON.stringify(products) ||
          JSON.stringify(storeResult.products) !== JSON.stringify(storeProducts)
        ) {
          setProducts(userResult.products);
          setStoreProducts(storeResult.products);
        }
      } else {
        setProducts((prev) => [...prev, ...userResult.products]);
        setStoreProducts((prev) => [...prev, ...storeResult.products]);
      }

      setUserLastDoc(userResult.lastDoc);
      setStoreLastDoc(storeResult.lastDoc);
    } catch (err) {
      console.error('검색 오류:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserProducts = async (
    keyword,
    categoryId,
    brandId,
    weatherId,
    minPrice,
    maxPrice,
    location,
    lastDoc,
    states
  ) => {
    const result = await exportFetchUserProductsByCoordinates(
      location?.lat,
      location?.lng,
      location?.radius,
      currentUser?.uid,
      PAGE_SIZE,
      lastDoc
    );

    return {
      products: result.filter((product) => {
        const matches = {
          title: product.title?.toLowerCase().includes(keyword.toLowerCase()),
          content: product.content
            ?.toLowerCase()
            .includes(keyword.toLowerCase()),
          category: !categoryId || product.categoryId === categoryId,
          brand: !brandId || product.brandId === brandId,
          weather: !weatherId || product.weatherEvent === weatherId,
          price:
            (!minPrice || product.price >= minPrice) &&
            (!maxPrice || product.price <= maxPrice),
          state: states
            .map((s) => (s === 'selling' ? 0 : s === 'reserved' ? 1 : 2))
            .includes(product.state),
        };
        return Object.values(matches).every(Boolean);
      }),
      lastDoc: result.lastDoc || null,
    };
  };

  const fetchStoreProducts = async (
    keyword,
    categoryId,
    brandId,
    weatherId,
    minPrice,
    maxPrice,
    location,
    lastDoc,
    states
  ) => {
    const result = await exportFetchStoreProductsByCoordinates(
      location?.lat,
      location?.lng,
      location?.radius,
      PAGE_SIZE,
      lastDoc
    );

    return {
      products: Object.values(result.products || {}).filter((product) => {
        const matches = {
          title: product.title?.toLowerCase().includes(keyword.toLowerCase()),
          description: product.description
            ?.toLowerCase()
            .includes(keyword.toLowerCase()),
          category: !categoryId || product.categoryId === categoryId,
          brand: !brandId || product.brandId === brandId,
          weather: !weatherId || product.weatherId === weatherId,
          price:
            (!minPrice || product.price >= minPrice) &&
            (!maxPrice || product.price <= maxPrice),
          state: states
            .map((s) => (s === 'selling' ? 0 : s === 'reserved' ? 1 : 2))
            .includes(product.state),
        };
        return Object.values(matches).every(Boolean);
      }),
      lastDoc: result.lastDoc || null,
    };
  };

  const handleSortChange = (value) => {
    setSort(value);
    handleSelectFilter('sort', value);
  };

  const handleTabChange = (e, newValue) => setTab(newValue);
  const handleUserProductClick = (product) => {
    navigate(`/detail/${product.id}`);
    logViewedProduct(product.id);
  };
  const handleStoreProductClick = (product) => {
    navigate(`/product_detail/${product.uid}/${product.id}`);
    logViewedStoreProduct(product.id);
  };

  return (
    <Box display="flex">
      {!isMobile && (
        <Box sx={{ flexShrink: 0 }}>
          <LeftSideMenu key={filterKey} onSelectFilter={handleSelectFilter} />
        </Box>
      )}
      <Box flex={1} p={2} bgcolor={theme.palette.background.default}>
        <Typography
          variant="h5"
          fontWeight="bold"
          mb={3}
          sx={{ color: theme.palette.text.secondary }}
        >
          🔍 {`"${searchParams.get('keyword') || ''}"`}
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Tabs
            value={tab}
            onChange={handleTabChange}
            sx={{ flexGrow: 1 }}
            variant="fullWidth"
            TabIndicatorProps={{
              style: { height: 3, backgroundColor: theme.palette.primary.main },
            }}
          >
            <Tab
              label={`${t('searchResults.userProducts')} (${products.length})`}
              sx={{ flex: 1, textTransform: 'none' }}
            />
            <Tab
              label={`${t('searchResults.storeProducts')} (${
                storeProducts.length
              })`}
              sx={{ flex: 1, textTransform: 'none' }}
            />
          </Tabs>
        </Box>

        <ListHeader
          title=""
          showFilter
          sortOption={sort}
          handleSortChange={handleSortChange}
          type={tab === 0 ? 'userProducts' : 'storeProducts'}
        />

        {loading ? (
          <Box
            display="flex"
            width="100%"
            minHeight="80vh"
            bgcolor={theme.palette.background.default}
            justifyContent="center"
            alignItems="center"
          >
            <SkeletonProductGrid />
          </Box>
        ) : (
          <Fade in={!loading} timeout={300}>
            <Grid container spacing={2} wrap="wrap">
              {(tab === 0 ? products : storeProducts).length > 0 ? (
                (tab === 0 ? products : storeProducts).map((product) => (
                  <Grid
                    item
                    key={product.id}
                    sx={{
                      flex: '0 0 16.66%',
                      maxWidth: '16.66%',
                      '@media (max-width:2700px)': {
                        flex: '0 0 20%',
                        maxWidth: '20%',
                      },
                      '@media (max-width:1899px)': {
                        flex: '0 0 25%',
                        maxWidth: '25%',
                      },
                      '@media (max-width:1279px)': {
                        flex: '0 0 33.33%',
                        maxWidth: '33.33%',
                      },
                      '@media (max-width:899px)': {
                        flex: '0 0 50%',
                        maxWidth: '50%',
                      },
                      '@media (max-width:599px)': {
                        flex: '0 0 100%',
                        maxWidth: '100%',
                      },
                    }}
                  >
                    {tab === 0 ? (
                      <Product
                        product={product}
                        onClick={() => handleUserProductClick(product)}
                      />
                    ) : (
                      <StoreProduct
                        product={product}
                        onClick={() => handleStoreProductClick(product)}
                      />
                    )}
                  </Grid>
                ))
              ) : tab === 0 ? (
                <Grid item xs={12} sx={{ height: '100%' }}>
                  <NoUserProducts />
                </Grid>
              ) : (
                <Grid item xs={12} sx={{ height: '100%' }}>
                  <NoStoreProducts />
                </Grid>
              )}
            </Grid>
          </Fade>
        )}

        {(tab === 0 ? userLastDoc : storeLastDoc) && (
          <Button
            variant="contained"
            onClick={() => fetchFilteredProducts(false)}
            sx={{ mt: 2 }}
          >
            {t('loadMore')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ProductSearchResults;
