// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
// import '../css/navbar.css';

// function MiniNavBar(currentUser) {
//   const { t, i18n } = useTranslation();
//   const navigate = useNavigate();
//   const handleCartClick = () => {
//     // '장바구니' 클릭 이벤트 핸들러
//     navigate(`/allcartlist/${currentUser.uid}`);
//   };
//   const handleMyPageClick = () => {
//     // '장바구니' 클릭 이벤트 핸들러
//     navigate(`/mypage/${currentUser.uid}/${currentUser.uid}`);
//   };
//   return (
//     <div className="mini-navbar">
//       {currentUser ? (
//         <>
//           <a href="#" onClick={handleCartClick}>
//             {t('cart')}
//           </a>
//           <a href="#" onClick={handleMyPageClick}>
//             {t('myPage')}
//           </a>
//           {/* <LanguageSwitcher /> */}
//         </>
//       ) : (
//         <>{/* <LanguageSwitcher /> */}</>
//       )}
//     </div>
//   );
// }
// export default MiniNavBar;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function MiniNavBar({ currentUser }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme(); // ✅ 테마 적용

  const handleCartClick = () => {
    navigate(`/allcartlist/${currentUser?.uid}`);
  };

  const handleMyPageClick = () => {
    navigate(`/mypage/${currentUser?.uid}/${currentUser?.uid}`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '4px', // ✅ 아이템 간격 최소화
        backgroundColor: theme.palette.background.paper, // ✅ 테마 적용
        color: theme.palette.text.primary, // ✅ 글자색 테마 적용
        padding: '1px 2px',
        mt: '5px',
        boxShadow: theme.shadows[1], // ✅ 살짝 그림자 추가
        borderRadius: '8px',
      }}
    >
      {currentUser && (
        <>
          <Typography
            component="a"
            onClick={handleCartClick}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none',
              fontSize: { xs: '8px', sm: '9px', md: '10px' }, // ✅ 크기 최소화 (반응형)
              fontWeight: '500',
              color: theme.palette.mode === 'dark' ? '#fff' : '#000', // ✅ 다크모드 흰색 / 라이트모드 검정
              '&:hover': {
                textDecoration: 'underline',
              },
              minWidth: '40px', // ✅ 너무 작아지는 현상 방지
            }}
          >
            {t('cart')}
          </Typography>

          <Typography
            component="a"
            onClick={handleMyPageClick}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none',
              fontSize: { xs: '8px', sm: '9px', md: '10px' }, // ✅ 크기 최소화 (반응형)
              fontWeight: '500',
              color: theme.palette.mode === 'dark' ? '#fff' : '#000', // ✅ 다크모드 흰색 / 라이트모드 검정
              '&:hover': {
                textDecoration: 'underline',
              },
              minWidth: '40px', // ✅ 최소 크기 지정
            }}
          >
            {t('myPage')}
          </Typography>
        </>
      )}
    </Box>
  );
}

export default MiniNavBar;
