import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import SearchListContainer from './SearchListContainer';
import AutoCompleteSearch from '../AutoCompleteSearch';
import { setSearchKeyword } from '../Redux/reducers/searchReducer';
import { getTop20SearchRanks } from '../api/SearchRankAPI';
import useOutsideClick from '../useOutsideClick';

const SearchBox = ({
  inputValue,
  setInputValue,
  showSearchBox,
  setShowSearchBox,
  townId,
  popupRef,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const blurTimeoutRef = useRef(null);
  const [activeTab, setActiveTab] = useState(1);
  const [recentSearches, setRecentSearches] = useState([]);
  const [realTimeSearches, setRealTimeSearches] = useState([]);

  // useOutsideClick(popupRef, () => setShowSearchBox(false));

  useEffect(() => {
    const savedSearches = localStorage.getItem('recentSearches');
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
    const interval = setInterval(updateTopSearchRanks, 300000);
    updateTopSearchRanks();
    return () => clearInterval(interval);
  }, [townId]);

  function updateTopSearchRanks() {
    getTop20SearchRanks(townId).then((topSearchRanks) => {
      const realTimeSearches = topSearchRanks.map((rank) => ({
        currentRank: rank.currentRank,
        keyword: rank.keyword,
        status: rank.status,
      }));

      setRealTimeSearches(realTimeSearches);
    });
  }

  const handleSelectSearchKeyword = (searchTerm) => {
    setInputValue(searchTerm);
    dispatch(setSearchKeyword(searchTerm));
    setRecentSearches((prevSearches) => {
      const updatedSearches = [
        searchTerm,
        ...prevSearches.filter((item) => item !== searchTerm),
      ];
      localStorage.setItem(
        'recentSearches',
        JSON.stringify(updatedSearches.slice(0, 20))
      );
      return updatedSearches.slice(0, 20);
    });
    blurTimeoutRef.current = setTimeout(() => {
      setShowSearchBox(false);
    }, 100);
  };

  return (
    showSearchBox && (
      <div
        ref={popupRef}
        style={{
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[4],
          color: theme.palette.text.primary,
          borderRadius: '8px',
          marginTop: '8px',
          // width: '80%', // 기존 width 유지
          // maxWidth: '600px', // 최대 너비 제한
          //minWidth: '350px', // 최소 너비 설정
        }}
      >
        <SearchListContainer
          recentSearches={recentSearches}
          realTimeSearches={realTimeSearches}
          setRecentSearches={setRecentSearches}
          onSelectSearchKeyword={handleSelectSearchKeyword}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <AutoCompleteSearch query={inputValue} />
      </div>
    )
  );
};

export default SearchBox;
