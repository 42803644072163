// import React, { useState, useEffect } from 'react';

// import './css/keyword.css';

// function Keyword({ initialKeywords, onUpdateKeywords }) {
//   const [keyword, setKeyword] = useState('');
//   const validInitialKeywords = initialKeywords || [];
//   const [keywordsList, setKeywordsList] = useState(validInitialKeywords);

//   useEffect(() => {
//     if (initialKeywords) {
//       setKeywordsList(initialKeywords);
//     }
//   }, [initialKeywords]);

//   const handleInputChange = (event) => {
//     setKeyword(event.target.value);
//   }

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     if (keywordsList.length < 30 && keyword && !keywordsList.includes(keyword)) {
//         const newKeywordsList = [...keywordsList, keyword];
//         setKeywordsList(prevList => [...prevList, keyword]);
//         if (onUpdateKeywords) {
//             onUpdateKeywords(newKeywordsList);
//         }
//     }
//     setKeyword('');
//   }

//   const handleRemoveKeyword = (index) => {
//     const newKeywordsList = [...keywordsList];
//     newKeywordsList.splice(index, 1);
//     setKeywordsList(newKeywordsList);
//     if (onUpdateKeywords) {
//         onUpdateKeywords(newKeywordsList);
//       }
//   }

//   return (

//         <div className='container'>
//         <div className="keyword-input-container">
//         <form onSubmit={handleSubmit}>
//             <input
//             type="text"
//             value={keyword}
//             onChange={handleInputChange}
//             placeholder="키워드를 입력해 주세요..."
//             />
//             <button type="submit">등록</button>
//         </form>
//         <div className="keywords-count">
//             등록한 키워드 {keywordsList.length}/30
//         </div>
//         <div className="keywords-list">
//             {keywordsList.map((kw, index) => (
//             <div className="keyword-item" key={index}>
//                 {kw} <span onClick={() => handleRemoveKeyword(index)}>x</span>
//             </div>
//             ))}
//         </div>

//         </div>
//     </div>
//   );
// }

// export default Keyword;

// import React, { useState, useEffect } from 'react';
// import {
//   TextField,
//   Button,
//   Chip,
//   Container,
//   Typography,
//   Box,
//   Paper,
// } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import './css/keyword.css';

// function Keyword({ initialKeywords, onUpdateKeywords }) {
//   const [keyword, setKeyword] = useState('');
//   const validInitialKeywords = initialKeywords || [];
//   const [keywordsList, setKeywordsList] = useState(validInitialKeywords);

//   useEffect(() => {
//     if (initialKeywords) {
//       setKeywordsList(initialKeywords);
//     }
//   }, [initialKeywords]);

//   const handleInputChange = (event) => {
//     setKeyword(event.target.value);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     if (
//       keywordsList.length < 30 &&
//       keyword &&
//       !keywordsList.includes(keyword)
//     ) {
//       const newKeywordsList = [...keywordsList, keyword];
//       setKeywordsList((prevList) => [...prevList, keyword]);
//       if (onUpdateKeywords) {
//         onUpdateKeywords(newKeywordsList);
//       }
//     }
//     setKeyword('');
//   };

//   const handleRemoveKeyword = (index) => {
//     const newKeywordsList = [...keywordsList];
//     newKeywordsList.splice(index, 1);
//     setKeywordsList(newKeywordsList);
//     if (onUpdateKeywords) {
//       onUpdateKeywords(newKeywordsList);
//     }
//   };

//   return (
//     <Container maxWidth="sm">
//       <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
//         <Typography variant="h5" component="h1" gutterBottom align="center">
//           알림키워드설정
//         </Typography>
//         <Box mt={2} mb={4}>
//           <form onSubmit={handleSubmit}>
//             <TextField
//               fullWidth
//               variant="outlined"
//               value={keyword || ''}
//               onChange={handleInputChange}
//               placeholder="키워드를 입력해 주세요..."
//               size="small"
//               margin="normal"
//             />
//             <Button
//               variant="contained"
//               color="primary"
//               type="submit"
//               fullWidth
//               sx={{ mt: 2, py: 1, display: 'flex', alignItems: 'center' }}
//               startIcon={<AddIcon />}
//             >
//               등록
//             </Button>
//           </form>

//           <Typography variant="body2" color="textSecondary" mt={2}>
//             등록한 키워드 {keywordsList.length}/30
//           </Typography>
//           <Box mt={2} display="flex" flexWrap="wrap">
//             {keywordsList.map((kw, index) => (
//               <Chip
//                 key={index}
//                 label={kw}
//                 onDelete={() => handleRemoveKeyword(index)}
//                 color="primary"
//                 variant="outlined"
//                 sx={{ margin: '4px', transition: 'all 0.3s' }}
//               />
//             ))}
//           </Box>
//         </Box>
//       </Paper>
//     </Container>
//   );
// }

// export default Keyword;

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Chip,
  Container,
  Typography,
  Box,
  Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

function Keyword({ initialKeywords, onUpdateKeywords }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [keyword, setKeyword] = useState('');
  const validInitialKeywords = initialKeywords || [];
  const [keywordsList, setKeywordsList] = useState(validInitialKeywords);

  useEffect(() => {
    if (initialKeywords) {
      setKeywordsList(initialKeywords);
    }
  }, [initialKeywords]);

  const handleInputChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      keywordsList.length < 30 &&
      keyword &&
      !keywordsList.includes(keyword)
    ) {
      const newKeywordsList = [...keywordsList, keyword];
      setKeywordsList((prevList) => [...prevList, keyword]);
      if (onUpdateKeywords) {
        onUpdateKeywords(newKeywordsList);
      }
    }
    setKeyword('');
  };

  const handleRemoveKeyword = (index) => {
    const newKeywordsList = [...keywordsList];
    newKeywordsList.splice(index, 1);
    setKeywordsList(newKeywordsList);
    if (onUpdateKeywords) {
      onUpdateKeywords(newKeywordsList);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          marginTop: 4,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}
      >
        {/* <Typography variant="h5" component="h1" gutterBottom align="center">
          {t('settings.notificationKeyword')}
        </Typography> */}
        <Box mt={2} mb={4}>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              value={keyword || ''}
              onChange={handleInputChange}
              placeholder={t('settings.enterKeyword')}
              size="small"
              margin="normal"
              sx={{
                backgroundColor: theme.palette.background.default,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: theme.palette.divider },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.dark,
                  },
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              sx={{ mt: 2, py: 1, display: 'flex', alignItems: 'center' }}
              startIcon={<AddIcon />}
            >
              {t('settings.register')}
            </Button>
          </form>

          <Typography variant="body2" color="textSecondary" mt={2}>
            {t('settings.registeredKeywords', { count: keywordsList.length })}
          </Typography>
          <Box mt={2} display="flex" flexWrap="wrap">
            {keywordsList.map((kw, index) => (
              <Chip
                key={index}
                label={kw}
                onDelete={() => handleRemoveKeyword(index)}
                color="primary"
                variant="outlined"
                sx={{
                  margin: '4px',
                  transition: 'all 0.3s',
                  '&:hover': { backgroundColor: theme.palette.action.hover },
                }}
              />
            ))}
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default Keyword;
