// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import './SearchListContainer.css';
// import RecentSearchList from './RecentSearchList';
// import RealTimeSearchList from './RealTimeSearchList';
// import SearchTabBar from './SearchTabBar';

// const SearchListContainer = ({
//   recentSearches,
//   realTimeSearches,
//   setRecentSearches,
//   onSelectSearchKeyword,
// }) => {
//   const { t, i18n } = useTranslation();
//   const [activeTab, setActiveTab] = useState(1);
//   // 개별 검색어 삭제
//   const handleDeleteItem = (index) => {
//     const newRecentSearches = recentSearches.filter((_, i) => i !== index);
//     setRecentSearches(newRecentSearches);
//   };

//   // 전체 검색어 삭제
//   const handleClearAll = () => {
//     setRecentSearches([]);
//   };

//   const handleSelectRecentSearch = (keyword) => {
//     console.log('Selected recent keyword:', keyword);
//     onSelectSearchKeyword(keyword);
//   };

//   const handleSelectRealTimeSearch = (keyword) => {
//     console.log('Selected realtime keyword:', keyword);
//     onSelectSearchKeyword(keyword);
//   };

//   return (
//     <div>
//       <div
//         style={{
//           position: 'absolute',
//           top: '100%',
//           left: 0,
//           right: 0,
//           backgroundColor: 'white',
//           zIndex: 100,
//           display: 'flex',
//         }}
//       >
//         <div style={{ flex: 1, padding: '10px' }}>
//           <h4>{t('recentSearches')}</h4>
//           <RecentSearchList
//             items={recentSearches}
//             onDeleteItem={(index) => handleDeleteItem(index)}
//             onClearAll={() => handleClearAll()}
//             onSelectRecentSearch={(searchTerm) =>
//               handleSelectRecentSearch(searchTerm)
//             }
//           />
//         </div>
//         <div style={{ flex: 1, padding: '10px' }}>
//           <h4>{t('realTimeSearches')}</h4>
//           <SearchTabBar onSelectTab={setActiveTab} activeTab={activeTab} />
//           <RealTimeSearchList
//             items={realTimeSearches}
//             activeTab={activeTab}
//             onSelectRealTimeSearch={handleSelectRealTimeSearch}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };
// export default SearchListContainer;

// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import './SearchListContainer.css';
// import RecentSearchList from './RecentSearchList';
// import RealTimeSearchList from './RealTimeSearchList';
// import SearchTabBar from './SearchTabBar';

// const SearchListContainer = ({
//   recentSearches,
//   realTimeSearches,
//   setRecentSearches,
//   onSelectSearchKeyword,
//   activeTab,
//   setActiveTab,
// }) => {
//   const { t, i18n } = useTranslation();

//   // 개별 검색어 삭제
//   const handleDeleteItem = (index) => {
//     const newRecentSearches = recentSearches.filter((_, i) => i !== index);
//     setRecentSearches(newRecentSearches);
//   };

//   // 전체 검색어 삭제
//   const handleClearAll = () => {
//     setRecentSearches([]);
//   };

//   const handleSelectRecentSearch = (keyword) => {
//     console.log('Selected recent keyword:', keyword);
//     onSelectSearchKeyword(keyword);
//   };

//   const handleSelectRealTimeSearch = (keyword) => {
//     console.log('Selected realtime keyword:', keyword);
//     onSelectSearchKeyword(keyword);
//   };

//   return (
//     <div>
//       <div
//         style={{
//           position: 'absolute',
//           top: '100%',
//           left: 0,
//           right: 0,
//           backgroundColor: 'white',
//           zIndex: 100,
//           display: 'flex',
//         }}
//       >
//         <div style={{ flex: 1, padding: '10px' }}>
//           <h4>{t('recentSearches')}</h4>
//           <RecentSearchList
//             items={recentSearches}
//             onDeleteItem={(index) => handleDeleteItem(index)}
//             onClearAll={() => handleClearAll()}
//             onSelectRecentSearch={(searchTerm) =>
//               handleSelectRecentSearch(searchTerm)
//             }
//           />
//         </div>
//         <div style={{ flex: 1, padding: '10px' }}>
//           <h4>{t('realTimeSearches')}</h4>
//           <SearchTabBar onSelectTab={setActiveTab} activeTab={activeTab} />
//           <RealTimeSearchList
//             items={realTimeSearches}
//             activeTab={activeTab}
//             onSelectRealTimeSearch={handleSelectRealTimeSearch}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SearchListContainer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography, useTheme, useMediaQuery } from '@mui/material';
import RecentSearchList from './RecentSearchList';
import RealTimeSearchList from './RealTimeSearchList';
import SearchTabBar from './SearchTabBar';

const SearchListContainer = ({
  recentSearches,
  realTimeSearches,
  setRecentSearches,
  onSelectSearchKeyword,
  activeTab,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // 개별 검색어 삭제
  const handleDeleteItem = (index) => {
    const newRecentSearches = recentSearches.filter((_, i) => i !== index);
    setRecentSearches(newRecentSearches);
  };

  // 전체 검색어 삭제
  const handleClearAll = () => {
    setRecentSearches([]);
  };

  return (
    <Paper
      elevation={13}
      sx={{
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 100,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        borderRadius: '8px',
        boxShadow: theme.shadows[3],
        overflow: 'hidden',
      }}
    >
      {/* 🔎 최근 검색어 */}
      <Box
        sx={{
          flex: 1,
          padding: '16px',
          borderRight: isMobile ? 'none' : `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography
          variant="h6"
          sx={{ mb: 1, color: theme.palette.text.primary }}
        >
          {t('recentSearches')}
        </Typography>
        <RecentSearchList
          items={recentSearches}
          onDeleteItem={handleDeleteItem}
          onClearAll={handleClearAll}
          onSelectRecentSearch={onSelectSearchKeyword}
        />
      </Box>

      {/* 🔥 실시간 검색어 */}
      <Box sx={{ flex: 1, padding: '16px' }}>
        <Typography
          variant="h6"
          sx={{ mb: 1, color: theme.palette.text.primary }}
        >
          {t('realTimeSearches')}
        </Typography>
        <SearchTabBar onSelectTab={setActiveTab} activeTab={activeTab} />
        <RealTimeSearchList
          items={realTimeSearches}
          activeTab={activeTab}
          onSelectRealTimeSearch={onSelectSearchKeyword}
        />
      </Box>
    </Paper>
  );
};

export default SearchListContainer;
