// import React from 'react';
// import { useTheme } from '@mui/material/styles';

// const FriendonLogo = () => {
//   const theme = useTheme();
//   const bgColor = theme.palette.background.default;
//   const textColor = theme.palette.text.primary;
//   const highlightColor = theme.palette.mode === 'dark' ? '#FFD700' : '#FF8C00';
//   const iconStroke = theme.palette.mode === 'dark' ? '#CCCCCC' : '#333333';
//   const iconFill = theme.palette.mode === 'dark' ? '#00AFFF' : '#007ACC';

//   return (
//     <svg
//       width="300"
//       height="70"
//       viewBox="0 0 350 70"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <style>
//         @import
//         url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@700&display=swap');
//       </style>

//       <rect width="300" height="70" fill={bgColor} />

//       {/* Futuristic 'F' Symbol with Circular Connection */}
//       <g transform="translate(10, 15)">
//         <circle
//           cx="20"
//           cy="20"
//           r="20"
//           stroke={iconStroke}
//           strokeWidth="3"
//           fill="none"
//         />
//         <line
//           x1="5"
//           y1="20"
//           x2="35"
//           y2="20"
//           stroke={iconStroke}
//           strokeWidth="3"
//         />
//         <line
//           x1="20"
//           y1="5"
//           x2="20"
//           y2="40"
//           stroke={iconStroke}
//           strokeWidth="3"
//         />
//         <circle cx="20" cy="20" r="4" fill={iconFill} />
//       </g>

//       {/* FriendON Text with Emphasis on 'ON' */}
//       <text
//         x="65"
//         y="55"
//         fontFamily="'Nanum Gothic', sans-serif"
//         fontSize="50"
//         fontWeight="900"
//         fill={textColor}
//       >
//         Friend
//         <tspan fill={highlightColor} fontWeight="900" filter="url(#glow)">
//           ON
//         </tspan>
//       </text>

//       {/* Glow Effect for 'ON' */}
//       {/* <defs>
//         <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
//           <feGaussianBlur stdDeviation="2.5" result="coloredBlur" />
//           <feMerge>
//             <feMergeNode in="coloredBlur" />
//             <feMergeNode in="SourceGraphic" />
//           </feMerge>
//         </filter>
//       </defs> */}
//       <defs>
//         <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
//           <feGaussianBlur stdDeviation="1.5" result="coloredBlur" />
//           <feSpecularLighting
//             result="specOut"
//             specularExponent="20"
//             lighting-color="white"
//           >
//             <fePointLight x="-5000" y="-10000" z="20000" />
//           </feSpecularLighting>
//           <feComposite
//             in="specOut"
//             in2="SourceAlpha"
//             operator="in"
//             result="specOut"
//           />
//           <feMerge>
//             <feMergeNode in="coloredBlur" />
//             <feMergeNode in="specOut" />
//             <feMergeNode in="SourceGraphic" />
//           </feMerge>
//         </filter>
//       </defs>
//     </svg>
//   );
// };

// export default FriendonLogo;

// import React from 'react';
// import { useSelector } from 'react-redux';
// import { useTheme } from '@mui/material/styles';

// const FriendonLogo = () => {
//   const theme = useTheme();
//   const isDarkMode = useSelector((state) => state.app.darkMode);
//   const bgColor = theme.palette.background.default;
//   const textColor = theme.palette.text.primary;
//   const highlightColor = isDarkMode
//     ? theme.palette.secondary.light
//     : theme.palette.primary.dark;
//   const iconStroke = isDarkMode ? '#CCCCCC' : '#333333';
//   const iconFill = isDarkMode ? '#00AFFF' : '#007ACC';

//   return (
//     <svg
//       width="300"
//       height="70"
//       viewBox="0 0 350 70"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <style>
//         @import
//         url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@700&display=swap');
//       </style>

//       <rect width="300" height="70" fill={bgColor} />

//       {/* Futuristic 'F' Symbol with Circular Connection */}
//       <g transform="translate(10, 15)">
//         <circle
//           cx="20"
//           cy="20"
//           r="20"
//           stroke={iconStroke}
//           strokeWidth="3"
//           fill="none"
//         />
//         <line
//           x1="5"
//           y1="20"
//           x2="35"
//           y2="20"
//           stroke={iconStroke}
//           strokeWidth="3"
//         />
//         <line
//           x1="20"
//           y1="5"
//           x2="20"
//           y2="40"
//           stroke={iconStroke}
//           strokeWidth="3"
//         />
//         <circle cx="20" cy="20" r="4" fill={iconFill} />
//       </g>

//       {/* FriendON Text with Individual Letter Transformations */}
//       <g
//         fontFamily="'Nanum Gothic', sans-serif"
//         fontSize="55"
//         fontWeight="900"
//         fill={textColor}
//       >
//         <text
//           x="60"
//           y="55"
//           fill={textColor}
//           fontWeight="900"
//           letterSpacing="1"
//           //   stroke="blue" /* 테두리 추가 */
//           //   strokeWidth="2"
//           //   filter="url(#textOutline) url(#deepShadow) url(#softNeon) url(#realisticMetal)"
//         >
//           F
//         </text>

//         {/* <text x="60" y="55">
//           F
//         </text> */}
//         {/* <text x="95" y="55" transform="rotate(8, 60, 55)"> */}
//         <text x="95" y="55">
//           r
//         </text>
//         <text x="120" y="55">
//           i
//         </text>
//         <text x="135" y="55">
//           e
//         </text>
//         <text x="165" y="55">
//           n
//         </text>
//         <text x="195" y="55">
//           d
//         </text>

//         <defs>
//           {/* 실버-메탈 크롬 그라데이션 */}
//           <linearGradient
//             id="futuristicMetal"
//             x1="0%"
//             y1="0%"
//             x2="100%"
//             y2="100%"
//           >
//             <stop offset="0%" stop-color="#e0f7ff" />{' '}
//             {/* 밝은 하늘색 하이라이트 */}
//             <stop offset="30%" stop-color="#a8d8ff" /> {/* 소프트 블루 메탈 */}
//             <stop offset="50%" stop-color="#88c8ff" /> {/* 스카이 블루 */}
//             <stop offset="70%" stop-color="#60b0ff" /> {/* 깊이 있는 푸른빛 */}
//             <stop offset="100%" stop-color="#3a92ff" /> {/* 다크 블루 크롬 */}
//           </linearGradient>

//           {/* 블랙 테두리 (화이트 배경에서도 강조) */}
//           <filter id="textOutline">
//             <feMorphology
//               operator="dilate"
//               radius="1.5"
//               in="SourceAlpha"
//               result="outline"
//             />
//             <feMerge>
//               <feMergeNode in="outline" />
//               <feMergeNode in="SourceGraphic" />
//             </feMerge>
//           </filter>

//           {/* 깊이 있는 그림자 (더 강한 대비) */}
//           <filter id="deepShadow">
//             <feDropShadow
//               dx="3"
//               dy="3"
//               stdDeviation="3"
//               flood-color="rgba(0,0,0,0.7)"
//             />
//           </filter>

//           {/* 부드러운 네온 빛 강조 */}
//           <filter id="softNeon">
//             <feGaussianBlur in="SourceAlpha" stdDeviation="4" result="blur" />
//             <feMerge>
//               <feMergeNode in="blur" />
//               <feMergeNode in="SourceGraphic" />
//             </feMerge>
//           </filter>

//           {/* 메탈 표면 반사광 */}
//           <filter id="realisticMetal">
//             <feSpecularLighting
//               in="SourceAlpha"
//               surfaceScale="3"
//               specularConstant="1.5"
//               specularExponent="40"
//               result="specOut"
//             >
//               <fePointLight x="-5000" y="-5000" z="10000" />
//             </feSpecularLighting>
//             <feComposite in="specOut" in2="SourceAlpha" operator="in" />
//           </filter>
//         </defs>

//         {/* ON 텍스트 */}
//         <text
//           x="230"
//           y="55"
//           fill={highlightColor}
//           fontWeight="1000"
//           letterSpacing="1"
//           //   stroke="blue" /* 테두리 추가 */
//           //   strokeWidth="2"
//           // filter="url(#textOutline) url(#deepShadow)  url(#softNeon) url(#realisticMetal)"
//         >
//           ON
//         </text>
//       </g>

//       {/* Glow Effect for 'ON' */}
//       <defs>
//         <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
//           <feGaussianBlur stdDeviation="1.5" result="coloredBlur" />
//           <feSpecularLighting
//             result="specOut"
//             specularExponent="20"
//             lighting-color="white"
//           >
//             <fePointLight x="-5000" y="-10000" z="20000" />
//           </feSpecularLighting>
//           <feComposite
//             in="specOut"
//             in2="SourceAlpha"
//             operator="in"
//             result="specOut"
//           />
//           <feMerge>
//             <feMergeNode in="coloredBlur" />
//             <feMergeNode in="specOut" />
//             <feMergeNode in="SourceGraphic" />
//           </feMerge>
//         </filter>
//       </defs>
//     </svg>
//   );
// };

// export default FriendonLogo;

// import React from 'react';
// import { useSelector } from 'react-redux';
// import { useTheme } from '@mui/material/styles';
// import lightLogoImage from './assets/images/friendon-logo.png'; // 라이트 모드 로고
// import darkLogoImage from './assets/images/friendon-logo.png'; // 다크 모드 로고
// import F from './assets/images/F.png';
// import R from './assets/images/R.png';
// import I from './assets/images/I.png';
// import E from './assets/images/E.png';
// import N from './assets/images/N.png';
// import D from './assets/images/D.png';
// import O from './assets/images/O.png';
// import N2 from './assets/images/N2.png';

// const FriendonLogo = () => {
//   const theme = useTheme();
//   const isDarkMode = useSelector((state) => state.app.darkMode);
//   const bgColor = theme.palette.background.default;
//   const textColor = theme.palette.text.primary;
//   const highlightColor = isDarkMode
//     ? theme.palette.secondary.light
//     : theme.palette.primary.dark;
//   const iconStroke = isDarkMode ? '#CCCCCC' : '#333333';
//   const iconFill = isDarkMode ? '#00AFFF' : '#007ACC';

//   // 다크 모드와 라이트 모드에 따른 로고 이미지 경로 설정
//   const logoSrc = isDarkMode ? darkLogoImage : lightLogoImage;
//   const imgF = isDarkMode ? F : F;
//   const imgR = isDarkMode ? R : R;
//   const imgI = isDarkMode ? I : I;
//   const imgE = isDarkMode ? E : E;
//   const imgN = isDarkMode ? N : N;
//   const imgD = isDarkMode ? D : D;
//   const imgO = isDarkMode ? O : O;
//   const imgN2 = isDarkMode ? N2 : N2;
//   return (
//     <svg
//       width="480"
//       height="60"
//       viewBox="0 0 480 60"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <rect width="480" height="60" fill={bgColor} />
//       <g transform="translate(5, 8)">
//         <image href={imgF} x="0" y="0" width="60" height="60" />
//         <image href={imgR} x="60" y="0" width="60" height="60" />
//         <image href={imgI} x="120" y="0" width="60" height="60" />
//         <image href={imgE} x="180" y="0" width="60" height="60" />
//         <image href={imgN} x="240" y="0" width="60" height="60" />
//         <image href={imgD} x="300" y="0" width="60" height="60" />
//         <image href={imgO} x="360" y="0" width="60" height="60" />
//         <image href={imgN2} x="420" y="0" width="60" height="60" />
//       </g>
//     </svg>

//     // <svg
//     // width="400"
//     // height="50"
//     // viewBox="0 0 400 50"
//     // fill="none"
//     // xmlns="http://www.w3.org/2000/svg"
//     // >
//     // <rect width="400" height="50" fill={bgColor} />
//     // <g transform="translate(5, 8)">
//     //   <image href={imgF} x="0" y="0" width="50" height="50" />
//     //   <image href={imgR} x="50" y="0" width="50" height="50" />
//     //   <image href={imgI} x="100" y="0" width="50" height="50" />
//     //   <image href={imgE} x="150" y="0" width="50" height="50" />
//     //   <image href={imgN} x="200" y="0" width="50" height="50" />
//     //   <image href={imgD} x="250" y="0" width="50" height="50" />
//     //   <image href={imgO} x="300" y="0" width="50" height="50" />
//     //   <image href={imgN2} x="350" y="0" width="50" height="50" />

//     // </g>
//     // </svg>
//   );
// };

// export default FriendonLogo;

// import React from 'react';
// import { useSelector } from 'react-redux';
// import { useTheme } from '@mui/material/styles';
// import lightLogoImage from './assets/images/friendon-logo.png'; // 라이트 모드 로고
// import darkLogoImage from './assets/images/friendon-logo.png'; // 다크 모드 로고
// import F from './assets/images/F.png';
// import R from './assets/images/R.png';
// import I from './assets/images/I.png';
// import E from './assets/images/E.png';
// import N from './assets/images/N.png';
// import D from './assets/images/D.png';
// import O from './assets/images/O.png';
// import N2 from './assets/images/N2.png';

// const FriendonLogo = ({ isMainWindow = false }) => {
//   const theme = useTheme();
//   const isDarkMode = useSelector((state) => state.app.darkMode);
//   const bgColor = theme.palette.background.default;

//   const imgF = F;
//   const imgR = R;
//   const imgI = I;
//   const imgE = E;
//   const imgN = N;
//   const imgD = D;
//   const imgO = O;
//   const imgN2 = N2;

//   return (
//     <svg
//       width={isMainWindow ? '480' : '400'}
//       height={isMainWindow ? '60' : '50'}
//       viewBox={isMainWindow ? '0 0 480 60' : '0 0 400 50'}
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <rect
//         width={isMainWindow ? '480' : '400'}
//         height={isMainWindow ? '60' : '50'}
//         fill={bgColor}
//       />
//       <g transform="translate(5, 8)">
//         <image
//           href={imgF}
//           x="0"
//           y="0"
//           width={isMainWindow ? '60' : '50'}
//           height={isMainWindow ? '60' : '50'}
//         />
//         <image
//           href={imgR}
//           x={isMainWindow ? '60' : '50'}
//           y="0"
//           width={isMainWindow ? '60' : '50'}
//           height={isMainWindow ? '60' : '50'}
//         />
//         <image
//           href={imgI}
//           x={isMainWindow ? '120' : '100'}
//           y="0"
//           width={isMainWindow ? '60' : '50'}
//           height={isMainWindow ? '60' : '50'}
//         />
//         <image
//           href={imgE}
//           x={isMainWindow ? '180' : '150'}
//           y="0"
//           width={isMainWindow ? '60' : '50'}
//           height={isMainWindow ? '60' : '50'}
//         />
//         <image
//           href={imgN}
//           x={isMainWindow ? '240' : '200'}
//           y="0"
//           width={isMainWindow ? '60' : '50'}
//           height={isMainWindow ? '60' : '50'}
//         />
//         <image
//           href={imgD}
//           x={isMainWindow ? '300' : '250'}
//           y="0"
//           width={isMainWindow ? '60' : '50'}
//           height={isMainWindow ? '60' : '50'}
//         />
//         <image
//           href={imgO}
//           x={isMainWindow ? '360' : '300'}
//           y="0"
//           width={isMainWindow ? '60' : '50'}
//           height={isMainWindow ? '60' : '50'}
//         />
//         <image
//           href={imgN2}
//           x={isMainWindow ? '420' : '350'}
//           y="0"
//           width={isMainWindow ? '60' : '50'}
//           height={isMainWindow ? '60' : '50'}
//         />
//       </g>
//     </svg>
//   );
// };

// export default FriendonLogo;

import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import F from './assets/images/F.png';
import R from './assets/images/R.png';
import I from './assets/images/I.png';
import E from './assets/images/E.png';
import N from './assets/images/N.png';
import D from './assets/images/D.png';
import O from './assets/images/O.png';
import N2 from './assets/images/N2.png';

const FriendonLogo = ({ isMainWindow = false }) => {
  const theme = useTheme();
  const isDarkMode = useSelector((state) => state.app.darkMode);
  const isMobile = useMediaQuery('(max-width:800px)');
  const size = isMainWindow ? (isMobile ? 55 : 80) : 50; // 글자 크기 조정
  const width = size * 8; // 전체 너비 자동 계산
  const height = size;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Friendon 로고를 구성하는 개별 이미지 */}
      {[F, R, I, E, N, D, O, N2].map((img, index) => (
        <image
          key={index}
          href={img} // 최신 브라우저 대응
          xlinkHref={img} // SVG 1.1 호환성
          x={index * size} // X 위치 자동 조정
          y="0"
          width={size}
          height={size}
        />
      ))}
    </svg>
  );
};

export default FriendonLogo;
