import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  collection,
  query,
  doc,
  getDocs,
  orderBy,
  startAfter,
  limit,
  getDoc,
} from '../firebaseConfig'; // Assuming you've updated the firebaseConfig file to v9

import {
  Container,
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Button,
  Box,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  Breadcrumbs,
  Link,
  Link as MuiLink,
  Paper,
  Fab,
  TablePagination,
  useMediaQuery,
} from '@mui/material';
import {
  exportFetchUserProductsByCoordinates,
  exportFetchStoresByCoordinates,
  exportFetchStoreProductsByCoordinates,
  exportFilterAndSortProducts,
  exportFetchSelectedTownByUserId,
  exportFetchUserProductsByLastDocAsMap,
  exportFetchStoresByLastDocAsMap,
  exportFetchStoresPerPage,
} from '../Utils/LocationStoresAndProducts';

import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useMessageBox from '../Common/useMessageBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import Lottie from 'lottie-react';
import PromotionDisplay from './Promotion/PromotionDisplay';
import StoreProduct from './StoreProduct';
import { useParams, Link as RouterLink } from 'react-router-dom';
import FilterOptions from '../Search/FilterOptions';
import SortOptions from '../Search/SortOptions';
import useStoreUserActivity from '../Stores/useStoreUserActivity';
import noStoreProductResults from '../assets/lottie/no-storeproduct-results.json';
import { current } from '@reduxjs/toolkit';

function StoreProductList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const [sortOption, setSortOption] = useState('relevance');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState([]); // Initialize as an empty array
  const { id: storeId } = useParams();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { logViewedStoreProduct } = useStoreUserActivity(currentUser?.uid);
  const [store, setStore] = useState(null);
  const [storeProducts, setStoreProducts] = useState([]);
  const [storeProductsPage, setStoreProductsPage] = useState(0);
  const [storeProductsTotalCount, setStoreProductsTotalCount] = useState(0);
  const [lastStoreProduct, setLastStoreProduct] = useState(null);
  const [hasMoreStoreProducts, setHasMoreStoreProducts] = useState(true);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const itemsPerPage = 10;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPad = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const storeRef = doc(db, 'stores', storeId);
        const storeDoc = await getDoc(storeRef);

        if (storeDoc.exists()) {
          const storeData = storeDoc.data();
          setStore(storeData);
        } else {
          setError((prevErrors) => [...prevErrors, 'Store not found']);
        }
      } catch (err) {
        setError((prevErrors) => [
          ...prevErrors,
          `Error fetching store: ${err.message}`,
        ]);
      }
    };

    fetchStore();
  }, [storeId]);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        console.log(
          `[StoreProductList] Fetching products with filters: ${JSON.stringify(
            filters
          )} and sort: ${sortOption}`
        );
        const productQuery = query(
          collection(doc(db, 'stores', storeId), 'products'),
          orderBy('createDate', 'asc')
        );
        const productSnapshot = await getDocs(productQuery);
        const productList = productSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setStoreProducts(productList);
      } catch (err) {
        if (err.code === 'failed-precondition' || err.code === 'unavailable') {
          console.error('Firestore index error:', err);
          setError((prevErrors) => [
            ...prevErrors,
            '필요한 인덱스가 없습니다. Firestore 콘솔에서 인덱스를 생성하세요.',
          ]);
        } else {
          console.error('Error fetching products:', err);
          setError((prevErrors) => [
            ...prevErrors,
            `Error fetching products: ${err.message}`,
          ]);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [storeId, filters, sortOption]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // 데이터 로딩 시작
        if (!currentUser) {
          const storeProductsResponse = await exportFetchStoresByLastDocAsMap(
            itemsPerPage,
            storeProductsPage
          );

          if (
            !storeProductsResponse ||
            !Array.isArray(storeProductsResponse.products)
          ) {
            throw new Error('Invalid response format');
          }

          setStoreProducts((prevProducts) => [
            ...prevProducts,
            ...storeProductsResponse.products,
          ]);
          setStoreProductsTotalCount(storeProductsResponse.totalCount);
          setHasMoreStoreProducts(
            storeProductsResponse.products.length === itemsPerPage &&
              storeProductsResponse.products.length !== 0
          );
        }
      } catch (error) {
        setError((prevErrors) => [
          ...prevErrors,
          `Error fetching products: ${error.message}`,
        ]);
      } finally {
        setLoading(false); // 데이터 로딩 완료
      }
    };

    fetchData();
  }, [currentUser, itemsPerPage, storeProductsPage]);

  useEffect(() => {
    const loadProducts = async () => {
      setLoading(true); // 데이터 로딩 시작

      if (currentUser) {
        try {
          await Promise.all([loadStoreProducts()]);
        } catch (error) {
          console.error('Error loading data:', error);
          setError((prevErrors) => [...prevErrors, error.message]);
        } finally {
          setLoading(false); // 데이터 로딩 완료
        }
      }
    };

    loadProducts();
  }, [currentUser]);

  const loadStoreProducts = async () => {
    try {
      const location = await exportFetchSelectedTownByUserId(currentUser.uid);
      if (!location) {
        const isConfirmed = await MessageBoxConfirm(
          t('neighborhoodNotification'),
          t('neighborhoodNotDesignatedMessage')
        );
        if (!isConfirmed) {
          throw new Error('Failed to fetch location.');
        }
        navigate('/settings/mytown');
      }
      const storeProducts = await exportFetchStoreProductsByCoordinates(
        location.lat,
        location.lng,
        location.radius,
        currentUser.uid
      );
      console.log('Fetched Store products:', storeProducts);
      // Ensure storeProducts.products is always an array
      const productsArray = Object.values(storeProducts.products || {});
      setLastStoreProduct(storeProducts.lastDoc);
      setStoreProducts(productsArray);
      setStoreProductsTotalCount(storeProducts.totalCount);
      setHasMoreStoreProducts(
        productsArray.length === itemsPerPage && productsArray.length !== 0
      );
    } catch (error) {
      setError((prevErrors) => [...prevErrors, error.message]);
    }
  };

  const loadMoreStoreProducts = async () => {
    if (!hasMoreStoreProducts) return;

    try {
      const location = await exportFetchSelectedTownByUserId(currentUser.uid);
      if (!location) {
        const isConfirmed = await MessageBoxConfirm(
          t('neighborhoodNotification'),
          t('neighborhoodNotDesignatedMessage')
        );
        if (!isConfirmed) {
          throw new Error('Failed to fetch location.');
        }
        navigate('/settings/mytown');
      }

      const storeProducts = await exportFetchStoreProductsByCoordinates(
        location.lat,
        location.lng,
        location.radius,
        currentUser.uid
      );
      const productsArray = Object.values(storeProducts.products || {});
      setStoreProducts((prevProducts) => [...prevProducts, ...productsArray]);
      setLastStoreProduct(storeProducts.lastDoc);
      setHasMoreStoreProducts(
        productsArray.length === itemsPerPage && productsArray.length !== 0
      );
    } catch (error) {
      console.error('Error loading more store products:', error);
      setError((prevErrors) => [...prevErrors, error.message]);
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleSortChange = (sortKey) => {
    const sortedStoreProducts = [...storeProducts];
    switch (sortKey) {
      case 'latest':
        sortedStoreProducts.sort(
          (a, b) => new Date(b.createDate) - new Date(a.createDate)
        );
        break;
      case 'popularity':
        sortedStoreProducts.sort((a, b) => b.likes - a.likes);
        break;
      case 'lowPrice':
        sortedStoreProducts.sort((a, b) => a.price - b.price);
        break;
      case 'highPrice':
        sortedStoreProducts.sort((a, b) => b.price - a.price);
        break;
      default:
        break;
    }
    setStoreProducts(sortedStoreProducts);
  };

  const handleStoreProductClick = (product) => {
    logViewedStoreProduct(storeId, product.id);
    window.open(
      `/product_detail/${product.uid}/${product.id}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
        <Typography variant="h6" ml={2}>
          {t('loadingProducts')}
        </Typography>
      </Box>
    );
  }

  if (error.length > 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">
          {t('errorFetchingProducts', { error: error.join(', ') })}
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography
        variant="h5"
        gutterBottom
        noWrap
        sx={{
          lineHeight: '1.5',
          maxHeight: '3em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {store?.storeName}
      </Typography>
      <Grid container spacing={2}>
        {storeProducts.length > 0 && (
          <Grid item xs={12}>
            <Breadcrumbs separator=" | " aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => handleSortChange('latest')}
                sx={{ cursor: 'pointer' }}
              >
                {t('latest')}
              </Link>
              <Link
                color="inherit"
                onClick={() => handleSortChange('popularity')}
                sx={{ cursor: 'pointer' }}
              >
                {t('popularity')}
              </Link>
              <Link
                color="inherit"
                onClick={() => handleSortChange('lowPrice')}
                sx={{ cursor: 'pointer' }}
              >
                {t('lowPrice')}
              </Link>
              <Link
                color="inherit"
                onClick={() => handleSortChange('highPrice')}
                sx={{ cursor: 'pointer' }}
              >
                {t('highPrice')}
              </Link>
            </Breadcrumbs>
          </Grid>
        )}
      </Grid>
      {MessageBoxRender()}
      <Grid item xs={12}>
        {storeProducts.length === 0 ? (
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Lottie
              animationData={noStoreProductResults}
              style={{ height: 300 }}
            />
            <Typography textAlign="center" sx={{ width: '100%' }}>
              {t('noStoreProducts')}
            </Typography>
          </Paper>
        ) : isMobile ? (
          <Box
            id="parentScrollBox"
            sx={{
              marginTop: '20px',
              height: 'calc(80vh - 70px)',
              overflow: 'auto',
            }}
          >
            <InfiniteScroll
              dataLength={storeProducts.length}
              next={loadMoreStoreProducts}
              hasMore={hasMoreStoreProducts}
              loader={<Typography>{t('loading')}</Typography>}
              endMessage={
                <Typography variant="body2">{t('noMoreProducts')}</Typography>
              }
              scrollableTarget="parentScrollBox"
            >
              <Grid container spacing={0}>
                {storeProducts.map((product) => (
                  <Grid item xs={12} key={product.id}>
                    <StoreProduct
                      product={product}
                      onClick={() => handleStoreProductClick(product)}
                      horizontal={isMobile} // pass the prop for mobile view
                    />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              {storeProducts.map((product) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id}>
                  <StoreProduct
                    product={product}
                    onClick={() => handleStoreProductClick(product)}
                    horizontal={isMobile}
                  />
                </Grid>
              ))}
            </Grid>
            <TablePagination
              component="div"
              count={storeProductsTotalCount}
              page={storeProductsPage}
              onPageChange={(e, newPage) => setStoreProductsPage(newPage)}
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
            />
          </>
        )}
      </Grid>
    </Box>
  );
}

export default StoreProductList;
