import React, { useState, useEffect, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Typography,
  Box,
  InputBase,
  Divider,
  Avatar,
  TextField,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  setSearchCategory,
  setSearchCategoryPath,
  setSearchKeyword,
} from '../Redux/reducers/searchReducer';
import useOutsideClick from '../useOutsideClick';
import SearchListContainer from './SearchListContainer';
import AutoCompleteSearch from '../Search/AutoCompleteSearch';
import UserMenu from '../UserMenu';
import MiniNavBar from './MiniNavBar';
import friendonDarkIcon from '../assets/images/friendon-logo-dark.svg';
import friendonLightIcon from '../assets/images/friendon-logo-light.svg';
import categoryIcon from '../assets/images/category.png';
import recycleIcon from '../assets/images/recycle.png';
import uploadIcon from '../assets/images/upload.png';
import chatIcon from '../assets/images/chat.png';
import watchListIcon from '../assets/images/watchlist.png';
import micIcon from '../assets/images/mic.png';
import myPageIcon from '../assets/images/header-mypage.svg';
import cartIcon from '../assets/images/header-cart.svg';
import signinIcon from '../assets/images/signin.png';
import signupIcon from '../assets/images/signup.png';
import settingIcon from '../assets/images/settings.png';
import '../css/navbar.css';
import UserSignUpFlow from '../SignUp/UserSignUpFlow';
import StoreSignUpFlow from '../SignUp/StoreSignUpFlow';
import { setEditStep } from '../Redux/reducers/editUserReducer';
import {
  db,
  signOut,
  auth,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  where,
} from '../firebaseConfig';
import { useTheme } from '@mui/material/styles';
import useMessageBox from '../Common/useMessageBox';
import ProductCategories from '../Stores/ProductCategories';
import ProductBrandSelector from '../Stores/ProductBrandSelector';
import useUserActivity from '../useUserActivity';
import useStoreUserActivity from '../Stores/useStoreUserActivity';
import FriendonLogo from '../FriendonLogo';
import SearchBox from './SearchBox';
import { CustomIconButton, CustomNavLink } from '../Common/CumtomIconButton';
import {
  SearchContainer,
  SearchIconWrapper,
  StyledInputBase,
} from './Search.js';
import {
  updateSearchFrequency,
  calculateNewRank,
  updateSearchRank,
  updateAllRanks,
  getTop20SearchRanks,
} from '../api/SearchRankAPI';
// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: '22px', // 라운드 처리
//   backgroundColor: 'transparent', // 내부 컬러 제거
//   border: `1px solid ${alpha(theme.palette.common.black, 0.5)}`, // 테두리 스타일 추가
//   '&:hover': {
//     border: `1px solid ${alpha(theme.palette.common.black, 0.75)}`, // 호버 시 테두리 스타일
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: '40%',
//   },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 1),
//   height: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));
// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: '22px', // 라운드 처리
//   backgroundColor: 'transparent', // 내부 컬러 제거
//   border: `1px solid ${
//     theme.palette.mode === 'dark'
//       ? alpha(theme.palette.common.white, 0.5)
//       : alpha(theme.palette.common.black, 0.5)
//   }`, // 테두리 스타일
//   '&:hover': {
//     border: `1px solid ${
//       theme.palette.mode === 'dark'
//         ? alpha(theme.palette.common.white, 0.75)
//         : alpha(theme.palette.common.black, 0.75)
//     }`, // 호버 시 테두리 스타일
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: '40%',
//   },
// }));

// const Search = styled('div')(({ theme }) => {
//   const borderColor = theme?.searchInput?.borderColor || '#ccc'; // 기본값 설정
//   const hoverBorderColor = theme?.searchInput?.hoverBorderColor || '#999'; // 기본값 설정

//   return {
//     position: 'relative',
//     borderRadius: '22px', // 라운드 처리
//     backgroundColor: 'transparent', // 내부 컬러 제거
//     border: `1px solid ${
//       theme.palette.mode === 'dark' ? borderColor : alpha(borderColor, 0.5)
//     }`,
//     '&:hover': {
//       border: `1px solid ${
//         theme.palette.mode === 'dark'
//           ? hoverBorderColor
//           : alpha(hoverBorderColor, 0.75)
//       }`,
//     },
//     marginRight: theme.spacing(2),
//     marginLeft: 0,
//     width: '100%',
//     display: 'flex',
//     alignItems: 'center',
//     [theme.breakpoints.up('sm')]: {
//       marginLeft: theme.spacing(3),
//       width: '40%',
//     },
//   };
// });

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 1),
//   height: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color:
//     theme.palette.mode === 'dark'
//       ? theme?.searchInput?.placeholderColor || '#fff' // 다크 모드: 기본 흰색
//       : theme?.searchInput?.placeholderColor || '#000', // 라이트 모드: 기본 텍스트 색상
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: theme.palette.text.primary || '#000', // 기본 텍스트 색상
//   flexGrow: 1,
//   '& .MuiInputBase-input': {
//     color: theme?.searchInput?.color || '#727E8E', // 입력 텍스트 색상 기본값
//     padding: theme.spacing(1),
//     paddingLeft: `calc(1em + ${theme.spacing(2)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     '&::placeholder': {
//       color: theme?.searchInput?.placeholderColor || '#ccc', // Placeholder 기본 색상
//     },
//     [theme.breakpoints.up('md')]: {
//       width: '100%',
//     },
//   },
// }));

// const CustomIconButton = styled(CustomIconButton)(({ theme, isactive }) => ({
//   backgroundColor:
//     isactive === 'true'
//       ? theme.palette.mode === 'dark'
//         ? theme.palette.primary.main // 다크 모드의 선택된 배경색
//         : theme.palette.secondary.light // 라이트 모드의 선택된 배경색
//       : 'transparent', // 기본 배경색
//   borderRadius: '50%',
//   '&:hover': {
//     backgroundColor: theme.palette.action.hover, // 호버 시 배경색
//   },
// }));

function NavBar({ children, setActiveMenu }) {
  const theme = useTheme();
  // const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const isDarkMode = useSelector((state) => state.app.darkMode); // Redux에서 다크 모드 상태 가져오기
  const friendonIcon = isDarkMode ? friendonDarkIcon : friendonLightIcon;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [isUserModal, setIsUserModal] = useState(false);
  const [isStoreModal, setIsStoreModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const [recentSearches, setRecentSearches] = useState([]);
  const [realTimeSearches, setRealTimeSearches] = useState([]);
  const [mode, setMode] = useState(1);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [showCategories, setShowCategories] = useState(false);
  const [showBrands, setShowBrands] = useState(false);
  const [categoryButtonPosition, setCategoryButtonPosition] = useState({
    top: 0,
    left: 0,
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation();
  const searchRef = useRef();
  const popupRef = useRef();
  const dropdownRef = useRef(null);
  const blurTimeoutRef = useRef();
  const categoryButtonRef = useRef(null);
  const categoryRef = useRef();

  const {
    logViewedProduct,
    logPurchasedProduct,
    logSearchedKeyword,
    logSearchedCategory,
  } = useUserActivity(currentUser?.uid);

  const {
    logViewedStoreProduct,
    logPurchasedStoreProduct,
    logSearchedStoreKeyword,
    logSearchedStoreCategory,
  } = useStoreUserActivity(currentUser?.uid);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };
  const handleDropdownMenuClick = (event) => {
    // setAnchorEl(event.currentTarget);
    // setAnchorEl(dropdownRef.current);
    if (dropdownRef.current) {
      setAnchorEl(dropdownRef.current); // ✅ 버튼 위치를 기준으로 메뉴 표시
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFocus = () => {
    setShowSearchBox(true);
    if (blurTimeoutRef.current) {
      clearTimeout(blurTimeoutRef.current);
    }
  };

  const handleBlur = () => {
    blurTimeoutRef.current = setTimeout(() => {
      // setShowSearchBox(false);
    }, 100);
  };

  useOutsideClick(popupRef, () => {
    setShowSearchBox(false);
  });

  useOutsideClick(searchRef, () => {
    // setShowSearchBox(false);
  });

  const handleSearchKeyword = async (searchTerm) => {
    dispatch(setSearchKeyword(searchTerm));

    const searchParams = new URLSearchParams(location.search);

    searchParams.set('keyword', searchTerm); // 기존 파라미터 유지 + keyword 갱신

    navigate(`/product_search_results?${searchParams.toString()}`);

    // navigate(`/product_search_results?keyword=${searchTerm}`);
    setRecentSearches((prevSearches) => {
      const updatedSearches = [
        searchTerm,
        ...prevSearches.filter((item) => item !== searchTerm),
      ];
      const slicedSearches = updatedSearches.slice(0, 20);
      localStorage.setItem('recentSearches', JSON.stringify(slicedSearches));
      return slicedSearches;
    });
    //실시간 검색어 순위 기록
    await updateSearchFrequency(searchTerm);
    await updateAllRanks();
    const newRank = calculateNewRank(searchTerm); // 새로운 순위 계산 함수
    updateSearchRank(searchTerm, newRank);
    //
    logSearchedKeyword(searchTerm);
    logSearchedStoreKeyword(currentUser?.uid, searchTerm);
  };

  const openUserModal = () => {
    setIsUserModal(true);
    dispatch(setEditStep(1));
  };

  const openStoreModal = () => {
    setIsStoreModal(true);
    dispatch(setEditStep(1));
  };

  const handleUserProfile = async () => {
    const userId = currentUser?.uid;
    if (userId) {
      navigate(`my_profile/${userId}`);
    } else {
      console.error('User ID not found');
    }
  };

  const handleGoTerms = async () => {
    const userId = currentUser?.uid;
    if (userId) {
      navigate('/terms_list');
    } else {
      console.error('User ID not found');
    }
  };

  const handleGoPrivacy = async () => {
    const userId = currentUser?.uid;
    if (userId) {
      navigate('/privacy_list');
    } else {
      console.error('User ID not found');
    }
  };

  const clearAuthState = async () => {
    await signOut(auth);
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    return new Promise((resolve) => setTimeout(resolve, 1000));
  };

  const handleLogout = async () => {
    const isConfirmed = await MessageBoxConfirm(
      t('logout'),
      t('settings.logout-confirm')
    );
    if (isConfirmed) {
      try {
        await clearAuthState();
        navigate('/');
      } catch (error) {
        console.error('Error during sign out:', error);
      }
    }
  };

  // const getTop20SearchRanks = async () => {
  //   const searchRanksRef = collection(db, 'searchRanks');
  //   const q = query(searchRanksRef, orderBy('currentRank'), limit(20));
  //   const querySnapshot = await getDocs(q);

  //   const topSearchRanks = querySnapshot.docs.map((docSnapshot) => {
  //     const data = docSnapshot.data();
  //     let status;
  //     const rankChange = data.previousRank - data.currentRank;

  //     if (rankChange >= 3) {
  //       status = '↑↑';
  //     } else if (rankChange >= 1) {
  //       status = '↑';
  //     } else if (rankChange === 0) {
  //       status = '-';
  //     } else {
  //       status = '↓';
  //     }

  //     return {
  //       keyword: data.keyword,
  //       currentRank: data.currentRank,
  //       previousRank: data.previousRank,
  //       status: status,
  //     };
  //   });

  //   return topSearchRanks;
  // };

  const handleDropdownPosition = (element) => {
    const rect = element.getBoundingClientRect();
    if (rect.bottom > window.innerHeight) {
      element.style.top = 'auto';
      element.style.bottom = '100%';
    } else {
      element.style.top = '100%';
      element.style.bottom = 'auto';
    }
  };

  useEffect(() => {
    const dropdowns = document.querySelectorAll('.dropdown-content');
    dropdowns.forEach((dropdown) => handleDropdownPosition(dropdown));
  }, [activeDropdown]);

  useEffect(() => {
    const savedSearches = localStorage.getItem('recentSearches');
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
    setInterval(updateTopSearchRanks, 300000);
    updateTopSearchRanks();
  }, []);

  function updateTopSearchRanks() {
    getTop20SearchRanks().then((topSearchRanks) => {
      const realTimeSearches = topSearchRanks.map((rank) => ({
        currentRank: rank.currentRank,
        keyword: rank.keyword,
        status: rank.status,
      }));
      setRealTimeSearches(realTimeSearches);
      console.log('Updated search ranks:', realTimeSearches);
    });
  }

  useOutsideClick(categoryRef, () => setShowCategories(false));

  const onSelectCategory = async (category) => {
    handleSearchCategory(category);
  };
  const onSelectedCategoryPath = async (categoryPath) => {
    handleSearchCategoryPath(categoryPath);
  };

  const handleSearchCategory = (category) => {
    dispatch(setSearchCategory(category));
    logSearchedCategory(category.id);
  };

  const handleSearchCategoryPath = (categoryPath) => {
    dispatch(setSearchCategoryPath(categoryPath));
    setShowCategories(false);
  };

  const handleSelectSearchKeyword = (searchTerm) => {
    setInputValue(searchTerm); // 선택한 검색어를 입력창에 표시
    handleSearchKeyword(searchTerm);
    dispatch(setSearchKeyword(searchTerm));
    setRecentSearches((prevSearches) => {
      const updatedSearches = [
        searchTerm,
        ...prevSearches.filter((item) => item !== searchTerm),
      ];
      localStorage.setItem(
        'recentSearches',
        JSON.stringify(updatedSearches.slice(0, 20))
      ); // 최근 검색어를 로컬 스토리지에 저장
      return updatedSearches.slice(0, 20);
    });
    blurTimeoutRef.current = setTimeout(() => {
      setShowSearchBox(false);
    }, 100);
  };
  // 기존 스타일 정의 (스타일을 유지하기 위해 필요하면 추가할 수 있음)
  const navLinkStyle = {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  };

  const activeStyle = {
    borderBottom: '2px solid #000', // underline 효과
  };
  // const CustomNavLink = styled(NavLink)(({ theme }) => ({
  //   '&.active': {
  //     backgroundColor: 'transparent', // 배경 투명
  //     borderBottom: `2px solid ${theme.palette.primary.main}`, // 활성 상태에서 테두리 추가
  //     borderRadius: '50%', // 둥근 효과
  //     padding: '5px', // 아이콘 주위 여백
  //     transition: 'all 0.3s ease', // 전환 효과
  //     boxShadow: `0 0 10px ${theme.palette.primary.main}`, // 선택 시 강조 그림자 효과
  //   },
  //   display: 'inline-flex',
  //   alignItems: 'center',
  //   textDecoration: 'none',
  // }));
  // const CustomNavLink = styled(NavLink)(({ theme }) => ({
  //   '&.active': {
  //     textDecoration: 'underline', // 활성 상태에서 밑줄 추가
  //     textUnderlineOffset: '5px', // 밑줄과 텍스트 간격
  //     textDecorationThickness: '2px', // 밑줄 두께
  //     textDecorationColor: theme.palette.primary.main, // 밑줄 색상
  //     borderBottom: `2px solid ${theme.palette.primary.main}`,
  //     paddingBottom: '2px', // Optional: Adjust space between icon and underline
  //   },
  //   display: 'inline-flex',
  //   alignItems: 'center',
  //   textDecoration: 'none', // 기본 상태에서 밑줄 제거
  //   color: 'inherit', // 텍스트 색상 유지
  // }));

  // const CustomNavLink = styled(NavLink)(({ theme }) => ({
  //   '&.active': {
  //     borderBottom: `2px solid ${theme.palette.primary.main}`,
  //     paddingBottom: '2px', // Optional: Adjust space between icon and underline
  //   },
  //   display: 'inline-flex',
  //   alignItems: 'center',
  //   textDecoration: 'none', // Remove default underline from NavLink
  // }));
  // const CustomNavLink = styled(NavLink)(({ theme }) => ({
  //   display: 'inline-flex',
  //   alignItems: 'center',
  //   textDecoration: 'none',
  //   // padding: theme.spacing(0.5, 1), // ✅ 테마 기반 padding 설정
  //   transition: theme.transitions.create(['background-color', 'box-shadow'], {
  //     duration: theme.transitions.duration.short,
  //     easing: theme.transitions.easing.easeInOut,
  //   }), // ✅ MUI 기본 트랜지션 활용
  //   backgroundColor: 'transparent',
  //   // borderRadius: theme.shape.borderRadius, // ✅ 테마 기반 border-radius 적용
  //   color: theme.palette.text.primary, // ✅ 기본 텍스트 색상 적용
  //   '&.active': {
  //     backgroundColor: alpha(theme.palette.primary.main, 0.2), // ✅ 활성 상태에서 부드러운 배경 강조
  //     // boxShadow: `0 0 5px ${alpha(theme.palette.primary.main, 0.4)}`, // ✅ 그림자 효과 (강하지 않게)
  //     borderBottom: `2px solid ${theme.palette.primary.main}`, // ✅ 밑줄 강조
  //   },
  //   '&:hover': {
  //     backgroundColor: alpha(theme.palette.primary.main, 0.1), // ✅ 호버 시 배경 강조
  //   },
  // }));
  // const CustomIconButton = styled(IconButton)(({ theme }) => ({
  //   backgroundColor: 'transparent',
  //   transition: theme.transitions.create(['background-color', 'box-shadow'], {
  //     duration: theme.transitions.duration.short,
  //     easing: theme.transitions.easing.easeInOut,
  //   }),
  //   '&.active': {
  //     backgroundColor: alpha(theme.palette.primary.main, 0.3), // ✅ active 시 배경 강조
  //     boxShadow: `0 0 5px ${alpha(theme.palette.primary.main, 0.5)}`,
  //   },
  //   '&:hover': {
  //     backgroundColor: alpha(theme.palette.primary.main, 0.1),
  //   },
  // }));

  const iconStyle = {
    height: 40,
    width: 40,
    filter: isDarkMode ? 'invert(1)' : 'invert(0)', // ✅ 다크모드: 하얀색, 라이트모드: 검정색
  };

  const activeButtonStyle = {
    // borderBottom: `2px solid ${isDarkMode ? 'white' : 'black'}`, // ✅ 선택된 버튼 언더바
    // paddingBottom: '2px',
    // backgroundColor: `${isDarkMode ? 'black' : 'white'}`, // ✅ 배경 제거
    backgroundColor: isDarkMode
      ? theme.palette.background.default
      : theme.palette.background.paper,
    border: 'none', // ✅ 테두리 제거
    borderRadius: 0, // ✅ 원형 제거
    minWidth: 'auto', // ✅ 크기 자동 조정
    // transition: 'background-color 0.3s ease',
  };

  return (
    <Box>
      <AppBar
        position="static"
        style={{
          backgroundColor: `${isDarkMode ? 'black' : 'white'}`,
          // backgroundColor: isDarkMode
          //   ? theme.palette.background.default
          //   : theme.palette.background.paper,
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[2],
        }}
        sx={{ height: '100px', display: 'flex', justifyContent: 'center' }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '10px' }}
        >
          <MiniNavBar currentUser={currentUser} />
        </Box>
        <Toolbar
          sx={{
            padding: '20px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomIconButton
              edge="start"
              color="inherit"
              aria-label="categories"
              sx={activeButtonStyle}
              onClick={() => setShowCategories(!showCategories)}
              ref={categoryButtonRef}
            >
              <img src={categoryIcon} alt="Categories" style={iconStyle} />
            </CustomIconButton>
            <Typography
              variant="h6"
              component={Link}
              to="/"
              sx={{
                flexGrow: 1,
                textDecoration: 'none', // 언더바 제거
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightBold,
                // color: 'inherit',
                marginRight: 'auto',
                fontSize: '1.8rem', // 폰트 크기 설정
                // fontWeight: 'bold', // 폰트 두께 설정
                ml: '20px',
              }}
              onClick={(event) => {
                navigate('/');
              }}
            >
              {t('appTitle')}
            </Typography>
          </Box> */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CustomIconButton
              edge="start"
              color="inherit"
              aria-label="categories"
              sx={activeButtonStyle}
              onClick={() => setShowCategories(!showCategories)}
              ref={categoryButtonRef}
            >
              <img src={categoryIcon} alt="Categories" style={iconStyle} />
            </CustomIconButton>

            <Box
              component={Link}
              to="/"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textDecoration: 'none',
                ml: { xs: 1, sm: 2 },
                mr: { xs: 1, sm: 2 },
              }}
              onClick={(event) => {
                navigate('/');
              }}
            >
              <FriendonLogo />
            </Box>
          </Box>
          {MessageBoxRender()}
          {showCategories && (
            <div
              ref={categoryRef}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100vh',
                width: '300px',
                backgroundColor: theme.palette.background.default,
                overflowY: 'auto',
                boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
                zIndex: 1000,
              }}
            >
              <ProductCategories
                showMode="multiLine"
                onSelectedCategoryPath={onSelectedCategoryPath}
                onSelectCategory={onSelectCategory}
                onClose={() => setShowCategories(false)}
              />
            </div>
          )}

          <SearchContainer theme={theme}>
            <StyledInputBase
              placeholder={t('search')}
              inputProps={{ 'aria-label': 'search' }}
              value={inputValue}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => setInputValue(e.target.value)}
              sx={{
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.text.primary, // 입력 텍스트 색상
                '&::placeholder': {
                  color:
                    theme.palette.mode === 'dark'
                      ? alpha(theme.palette.common.white, 0.7)
                      : alpha(theme.palette.text.primary, 0.7), // placeholder 색상
                },
              }}
            />

            <CustomIconButton
              type="button"
              edge="end"
              sx={{
                p: '10px',
                mr: '10px',
                backgroundColor: 'transparent',
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.text.primary, // 돋보기 아이콘 색상
              }}
              // sx={activeButtonStyle}
              aria-label="search"
              onClick={() => handleSearchKeyword(inputValue)}
            >
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
            </CustomIconButton>

            {/* 검색 팝업 (최근 검색어 + 실시간 검색어) */}
            <SearchBox
              inputValue={inputValue}
              setInputValue={setInputValue}
              showSearchBox={showSearchBox}
              setShowSearchBox={setShowSearchBox}
              popupRef={popupRef}
            />
          </SearchContainer>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'transparent',
            }}
          >
            <CustomNavLink
              component={NavLink}
              to="/home"
              activeClassName="active"
            >
              <CustomIconButton
                theme={theme}
                color="inherit"
                onClick={() => handleMenuClick('/home')}
                // sx={{
                //   backgroundColor: 'transparent', // 배경색 투명
                //   '&:hover': {
                //     backgroundColor: alpha(theme.palette.primary.main, 0.1), // 호버 시 약간의 강조 효과
                //   },
                //   transition: 'background-color 0.3s ease', // 배경색 전환 효과
                // }}
                sx={activeButtonStyle}
                // className={({ isActive }) => (isActive ? 'active' : undefined)}
              >
                <img
                  src={recycleIcon}
                  alt={t('productList')}
                  // style={{
                  //   height: 50,
                  //   width: 50,
                  //   backgroundColor: 'transparent',
                  //   filter:
                  //     theme.palette.mode === 'dark' ? 'invert(1)' : 'none',
                  // }}
                  style={iconStyle}
                />
              </CustomIconButton>
            </CustomNavLink>

            {!currentUser ? (
              <>
                <CustomIconButton
                  component={NavLink}
                  to="/login"
                  color="inherit"
                  // className={({ isActive }) =>
                  //   isActive ? 'active' : undefined
                  // }
                  sx={activeButtonStyle}
                  onClick={() => handleMenuClick('/login')}
                >
                  <img
                    src={signinIcon}
                    alt={t('login')}
                    // style={{
                    //   height: 50,
                    //   width: 50,
                    // }}
                    style={iconStyle}
                  />
                </CustomIconButton>
                <CustomIconButton
                  color="inherit"
                  sx={activeButtonStyle}
                  onClick={() => setActiveDropdown(!activeDropdown)}
                >
                  <img
                    src={signupIcon}
                    alt={t('uploadUsedProduct')}
                    // style={{
                    //   height: 50,
                    //   width: 50,
                    // }}
                    style={iconStyle}
                  />
                </CustomIconButton>
                {activeDropdown && (
                  <Box
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: '100%',
                      zIndex: 2000,
                      //backgroundColor: 'white',
                      boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        openUserModal();
                        setActiveDropdown(false);
                      }}
                    >
                      {t('userSignUp')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        openStoreModal();
                        setActiveDropdown(false);
                      }}
                    >
                      {t('storeSignUp')}
                    </MenuItem>
                  </Box>
                )}
              </>
            ) : (
              <>
                <CustomNavLink
                  component={NavLink}
                  to="/upload"
                  activeClassName="active"
                >
                  <CustomIconButton
                    color="inherit"
                    sx={activeButtonStyle}
                    onClick={() => handleMenuClick('/upload')}
                  >
                    <img
                      src={uploadIcon}
                      alt={t('uploadUsedProduct')}
                      style={iconStyle}
                    />
                  </CustomIconButton>
                </CustomNavLink>

                <CustomNavLink
                  component={NavLink}
                  to="/heartlist"
                  activeClassName="active"
                >
                  <CustomIconButton
                    color="inherit"
                    sx={activeButtonStyle}
                    onClick={() => handleMenuClick('/heartlist')}
                  >
                    <img
                      src={watchListIcon}
                      alt={t('watchlist')}
                      style={iconStyle}
                    />
                  </CustomIconButton>
                </CustomNavLink>

                <CustomNavLink
                  component={NavLink}
                  to="/chatlist"
                  activeClassName="active"
                >
                  <CustomIconButton
                    color="inherit"
                    sx={activeButtonStyle}
                    onClick={() => handleMenuClick('/chatlist')}
                  >
                    <img src={chatIcon} alt={t('chat')} style={iconStyle} />
                  </CustomIconButton>
                </CustomNavLink>

                <CustomNavLink
                  component={NavLink}
                  to={`/allcartlist/${currentUser.uid}`}
                  activeClassName="active"
                >
                  <CustomIconButton
                    color="inherit"
                    sx={activeButtonStyle}
                    onClick={() => handleMenuClick('/allcartlist')}
                  >
                    <img src={cartIcon} alt={t('cart')} style={iconStyle} />
                  </CustomIconButton>
                </CustomNavLink>

                <CustomNavLink
                  component={NavLink}
                  to="/mypage/${currentUser.uid}/${currentUser.uid}"
                  activeClassName="active"
                >
                  <CustomIconButton
                    // component={NavLink}
                    ref={dropdownRef}
                    color="inherit"
                    sx={activeButtonStyle}
                    // onClick={handleDropdownMenuClick}
                    onClick={() =>
                      handleMenuClick(
                        `/mypage/${currentUser.uid}/${currentUser.uid}`
                      )
                    }
                  >
                    <Avatar
                      src={myPageIcon}
                      alt={t('mypage')}
                      style={iconStyle}
                    />
                  </CustomIconButton>
                </CustomNavLink>
                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // ✅ 버튼 바로 아래에 위치
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }} // ✅ 버튼 아래쪽에서 펼쳐짐
                  // anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  // transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    component={NavLink}
                    to={`/mypage/${currentUser.uid}/${currentUser.uid}`}
                    // className={({ isActive }) =>
                    //   isActive ? 'active' : undefined
                    // }
                    onClick={() => handleMenuClick('/mypage')}
                  >
                    {t('mypage')}
                  </MenuItem>
                  <MenuItem
                    component={NavLink}
                    to="/storelist"
                    // className={({ isActive }) =>
                    //   isActive ? 'active' : undefined
                    // }
                    onClick={() => handleMenuClick('/storelist')}
                  >
                    {t('storelist')}
                  </MenuItem>
                  <MenuItem
                    component={NavLink}
                    to={`/mystore/${currentUser.uid}`}
                    // className={({ isActive }) =>
                    //   isActive ? 'active' : undefined
                    // }
                    onClick={() => handleMenuClick('/mystore')}
                  >
                    {t('mystore')}
                  </MenuItem>
                  <Divider />
                </Menu>

                <CustomNavLink
                  component={NavLink}
                  to="/settings"
                  activeClassName="active"
                >
                  <CustomIconButton
                    color="inherit"
                    sx={activeButtonStyle}
                    onClick={() => handleMenuClick('/settings')}
                  >
                    <Avatar
                      src={settingIcon}
                      alt={t('settings.title')}
                      style={iconStyle}
                    />
                  </CustomIconButton>
                </CustomNavLink>
                <UserMenu
                  user={currentUser}
                  handleUserProfile={handleUserProfile}
                  handleGoTerms={handleGoTerms}
                  handleGoPrivacy={handleGoPrivacy}
                  handleLogout={handleLogout}
                />
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <main>{children}</main>
      {isUserModal && (
        <UserSignUpFlow mode={mode} closeModal={() => setIsUserModal(false)} />
      )}
      {isStoreModal && (
        <StoreSignUpFlow
          mode={mode}
          closeModal={() => setIsStoreModal(false)}
        />
      )}
    </Box>
  );
}

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         alignItems: 'center',
//         backgroundColor: 'transparent',
//       }}
//     >
//       <CustomNavLink component={NavLink} to="/" activeClassName="active">
//         <CustomIconButton
//           color="inherit"
//           onClick={() => handleMenuClick('/home')}
//           sx={activeButtonStyle}
//         >
//           <img src={recycleIcon} alt={t('productList')} style={iconStyle} />
//         </CustomIconButton>
//       </CustomNavLink>

//       {!currentUser ? (
//         <>
//           <CustomIconButton
//             component={NavLink}
//             to="/login"
//             color="inherit"
//             sx={activeButtonStyle}
//             onClick={() => handleMenuClick('/login')}
//           >
//             <img src={signinIcon} alt={t('login')} style={iconStyle} />
//           </CustomIconButton>

//           <CustomIconButton
//             color="inherit"
//             onClick={() => setActiveDropdown(!activeDropdown)}
//             sx={activeButtonStyle}
//           >
//             <img
//               src={signupIcon}
//               alt={t('uploadUsedProduct')}
//               style={iconStyle}
//             />
//           </CustomIconButton>
//         </>
//       ) : (
//         <>
//           <CustomNavLink
//             component={NavLink}
//             to="/upload"
//             activeClassName="active"
//           >
//             <CustomIconButton
//               color="inherit"
//               sx={activeButtonStyle}
//               onClick={() => handleMenuClick('/upload')}
//             >
//               <img
//                 src={uploadIcon}
//                 alt={t('uploadUsedProduct')}
//                 style={iconStyle}
//               />
//             </CustomIconButton>
//           </CustomNavLink>

//           <CustomNavLink
//             component={NavLink}
//             to="/heartlist"
//             activeClassName="active"
//           >
//             <CustomIconButton
//               color="inherit"
//               sx={activeButtonStyle}
//               onClick={() => handleMenuClick('/heartlist')}
//             >
//               <img src={watchListIcon} alt={t('watchlist')} style={iconStyle} />
//             </CustomIconButton>
//           </CustomNavLink>

//           <CustomNavLink
//             component={NavLink}
//             to="/chatlist"
//             activeClassName="active"
//           >
//             <CustomIconButton
//               color="inherit"
//               sx={activeButtonStyle}
//               onClick={() => handleMenuClick('/chatlist')}
//             >
//               <img src={chatIcon} alt={t('chat')} style={iconStyle} />
//             </CustomIconButton>
//           </CustomNavLink>

//           <CustomNavLink
//             component={NavLink}
//             to={`/allcartlist/${currentUser.uid}`}
//             activeClassName="active"
//           >
//             <CustomIconButton
//               color="inherit"
//               sx={activeButtonStyle}
//               onClick={() => handleMenuClick('/allcartlist')}
//             >
//               <img src={cartIcon} alt={t('cart')} style={iconStyle} />
//             </CustomIconButton>
//           </CustomNavLink>

//           <CustomIconButton
//             component={NavLink}
//             color="inherit"
//             onClick={handleDropdownMenuClick}
//             sx={activeButtonStyle}
//           >
//             <Avatar
//               src={myPageIcon}
//               alt={t('mypage')}
//               sx={{ height: 50, width: 50 }}
//             />
//           </CustomIconButton>

//           <CustomNavLink
//             component={NavLink}
//             to="/settings"
//             activeClassName="active"
//           >
//             <CustomIconButton
//               color="inherit"
//               sx={activeButtonStyle}
//               onClick={() => handleMenuClick('/settings')}
//             >
//               <Avatar
//                 src={settingIcon}
//                 alt={t('settings.title')}
//                 sx={{ height: 50, width: 50 }}
//               />
//             </CustomIconButton>
//           </CustomNavLink>
//         </>
//       )}
//     </Box>
//   );
// }
export default NavBar;
