import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  Box,
  Grid,
  Typography,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; // 🛒 중고 물품 아이콘
import StorefrontIcon from '@mui/icons-material/Storefront'; // 🏪 스토어 아이콘
import Product from './Product';
import Store from './Stores/Store';
import NoUserProducts from './NoUserProducts';
import NoStores from './NoStores';
import ListHeader from './Common/ListHeader';

const HomeTabs = ({
  userProducts,
  stores,
  sortOption,
  handleSortChange,
  loadMoreUserProducts,
  loadMoreStores,
  hasMoreUserProducts,
  hasMoreStores,
  handleUserProductClick,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // ✅ 탭 상태 (0: 사용자 상품, 1: 로컬 스토어)
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box sx={{ width: '100%' }}>
      {/* ✅ 탭 네비게이션 */}
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            height: '3px',
            backgroundColor: theme.palette.primary.main,
          },
        }}
        centered
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          mb: 2,
          '& .MuiTab-root': {
            minHeight: 66, // 탭 높이 증가
            fontSize: '1.0rem', // 글자 크기 증가
            padding: '12px 16px', // 패딩 조정
            textTransform: 'none',
          },
          '& .MuiTab-iconWrapper': {
            fontSize: '1.2rem', // 아이콘 크기 증가
          },
        }}
      >
        <Tab
          label={t('userProducts')}
          icon={<ShoppingCartIcon color="inherit" />}
          iconPosition="start"
          sx={{ flex: 1 }}
        />
        <Tab
          label={t('localStores')}
          icon={<StorefrontIcon color="inherit" />}
          iconPosition="start"
          sx={{ flex: 1 }}
        />
      </Tabs>

      {/* ✅ 사용자 상품 탭 */}
      {activeTab === 0 && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end', // 👉 오른쪽 정렬
              alignItems: 'center',
              width: '100%', // 부모 요소를 기준으로 전체 너비 설정
            }}
          >
            <ListHeader
              title={t('')}
              showFilter={userProducts.length > 0}
              sortOption={sortOption}
              handleSortChange={handleSortChange}
              type="userProducts"
            />
          </Box>
          {userProducts.length === 0 ? (
            <NoUserProducts />
          ) : isMobile ? (
            <Box
              id="parentScrollBox"
              sx={{ height: 'calc(80vh - 70px)', overflow: 'auto' }}
            >
              <InfiniteScroll
                dataLength={userProducts.length}
                next={loadMoreUserProducts}
                hasMore={hasMoreUserProducts}
                loader={
                  <CircularProgress
                    sx={{ color: theme.palette.primary.main }}
                  />
                }
                endMessage={
                  <Typography variant="body2">{t('noMoreProducts')}</Typography>
                }
                scrollableTarget="parentScrollBox"
              >
                <Grid container spacing={2}>
                  {userProducts.map((product) => (
                    <Grid item xs={12} key={product.id}>
                      <Product
                        product={product}
                        onClick={() => handleUserProductClick(product)}
                        horizontal={isMobile}
                      />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {userProducts.map((product) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                  <Product
                    product={product}
                    onClick={() => handleUserProductClick(product)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}

      {/* ✅ 로컬 스토어 탭 */}
      {activeTab === 1 && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end', // 👉 오른쪽 정렬
              alignItems: 'center',
              width: '100%', // 부모 요소를 기준으로 전체 너비 설정
            }}
          >
            <ListHeader
              title={t('')}
              showFilter={stores.length > 0}
              sortOption={sortOption}
              handleSortChange={handleSortChange}
              type="stores"
            />
          </Box>
          {stores.length === 0 ? (
            <NoStores />
          ) : isMobile ? (
            <Box
              id="storesScrollBox"
              sx={{ height: 'calc(80vh - 70px)', overflow: 'auto' }}
            >
              <InfiniteScroll
                dataLength={stores.length}
                next={loadMoreStores}
                hasMore={hasMoreStores}
                loader={
                  <CircularProgress
                    sx={{ color: theme.palette.primary.main }}
                  />
                }
                endMessage={
                  <Typography variant="body2">{t('noMoreStores')}</Typography>
                }
                scrollableTarget="storesScrollBox"
              >
                <Grid container spacing={0}>
                  {stores.map((store) => (
                    <Grid item xs={12} key={store.id}>
                      <Store store={store} horizontal={isMobile} />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {stores.map((store) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={store.id}>
                  <Store store={store} horizontal={isMobile} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default HomeTabs;
