// import React, { useEffect } from 'react';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   IconButton,
//   Box,
//   Chip,
//   Tooltip,
//   useMediaQuery,
// } from '@mui/material';
// import { Link as RouterLink } from 'react-router-dom';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
// import { formatPrice } from './Utils/PriceFormatter';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';
// import productDefaultImage from './assets/images/productDefaultImage.png';
// import { formatState } from './Utils/ProductState';

// const Product = ({ product, onClick, horizontal }) => {
//   const { t, i18n } = useTranslation();
//   useEffect(() => {
//     moment.locale(i18n.language);
//   }, [i18n.language]);
//   const dateObj = product?.createDate?.toDate();
//   const formattedDate = moment(dateObj).fromNow();

//   // const formatState = (state) => {
//   //   switch (state) {
//   //     case 0:
//   //       return {
//   //         icon: <CheckCircleIcon />,
//   //         text: t('selling'),
//   //         color: 'success',
//   //       };
//   //     case 1:
//   //       return { icon: <FavoriteIcon />, text: t('reserved'), color: 'info' };
//   //     case 2:
//   //       return { icon: <ChatIcon />, text: t('sold-out'), color: 'secondary' };
//   //     default:
//   //       return { icon: <CancelIcon />, text: t('unknown'), color: 'error' };
//   //   }
//   // };
//   const imageUrl =
//     product.icon_images && product.icon_images.length > 0
//       ? product.icon_images[0]
//       : productDefaultImage;

//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

//   const productState = formatState(product.state, t);
//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         m: '5px',
//         p: '5px',
//         boxShadow: 3,
//         borderRadius: 2,
//         overflow: 'hidden',
//         '@media (max-width: 600px)': {
//           m: '2px',
//           p: '2px',
//         },
//       }}
//       onClick={onClick}
//     >
//       <CardActionArea>
//         {horizontal && isMobile ? (
//           <Box display="flex" flexDirection="row" sx={{ height: '170px' }}>
//             <Box
//               sx={{
//                 width: '40%',
//                 height: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <CardMedia
//                 component="img"
//                 image={imageUrl}
//                 alt={product.name}
//                 sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
//               />
//             </Box>
//             <CardContent
//               sx={{
//                 width: '60%',
//                 padding: '8px',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 marginTop: 'auto',
//                 marginBottom: 'auto',
//               }}
//             >
//               <Typography gutterBottom variant="h6" component="div" noWrap>
//                 {product.title}
//               </Typography>
//               <Typography variant="body2" color="text.secondary" noWrap>
//                 {product.content}
//               </Typography>

//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 0,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={productState.icon}
//                   label={productState.text}
//                   color={productState.color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{ flexGrow: 1 }}
//                 >
//                   {formatPrice(product.price)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Box>
//         ) : (
//           <>
//             {/* <CardMedia
//               component="img"
//               height="200"
//               image={imageUrl}
//               alt={product.name}
//               sx={{ objectFit: 'cover' }}
//             /> */}
//             <CardMedia
//               component={RouterLink}
//               // to={`/product_detail/${product.uid}/${product.id}`}
//               sx={{
//                 height: 0,
//                 paddingTop: '56.25%',
//                 backgroundImage: `url(${imageUrl})`,
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center',
//                 borderRadius: '8px',
//                 '@media (max-width: 600px)': {
//                   height: '150px',
//                   paddingTop: 0,
//                 },
//               }}
//               alt={product.productName}
//             />
//             <CardContent sx={{ padding: '8px' }}>
//               <Typography gutterBottom variant="h6" component="div" noWrap>
//                 {product.title}
//               </Typography>
//               <Typography variant="body2" color="text.secondary" noWrap>
//                 {product.content}
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                   mb: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={productState.icon}
//                   label={productState.text}
//                   color={productState.color}
//                   size="small"
//                   variant="outlined"
//                   sx={{ fontSize: '0.75rem', height: '20px' }}
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{ flexGrow: 1 }}
//                 >
//                   {formatPrice(product.price)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </>
//         )}
//       </CardActionArea>
//     </Card>
//   );
// };

// export default Product;

// import React, { useEffect, useState } from 'react';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   IconButton,
//   Box,
//   Chip,
//   Tooltip,
//   useMediaQuery,
// } from '@mui/material';
// import { Link as RouterLink } from 'react-router-dom';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
// import { formatPrice } from './Utils/PriceFormatter';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';
// import productDefaultImage from './assets/images/productDefaultImage.png';
// import { formatState } from './Utils/ProductState';
// import Ribbon from './Weather/Ribbon'; // Assume we have a ribbon component for display
// import { useSelector } from 'react-redux';
// import { AppStore } from './Redux/store';
// import { fetchWeatherEvent } from './api/WeatherAPI'; // Firestore에서 이벤트를 가져오는 함수

// const Product = ({ product, onClick, horizontal }) => {
//   const { t, i18n } = useTranslation();
//   const currentWeather = useSelector((state) => state.weather.weatherData);
//   const [eventData, setEventData] = useState([]);

//   useEffect(() => {
//     moment.locale(i18n.language);
//     const fetchData = async () => {
//       const events = await fetchWeatherEvent();
//       setEventData(events);
//     };
//     fetchData();
//   }, [i18n.language]);

//   const dateObj = product?.createDate?.toDate();
//   const formattedDate = moment(dateObj).fromNow();
//   const imageUrl =
//     product.icon_images && product.icon_images.length > 0
//       ? product.icon_images[0]
//       : productDefaultImage;

//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
//   const productState = formatState(product.state, t);

//   // Check for matching weather-based event from Firestore eventData
//   const activeEvent = eventData.find(
//     (event) =>
//       checkEventCondition(event, currentWeather) &&
//       product.event?.id === event.id
//   );

//   const discountedPrice = activeEvent
//     ? product.price * (1 - activeEvent.discount / 100)
//     : product.price;

//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         m: '5px',
//         p: '5px',
//         boxShadow: 3,
//         borderRadius: 2,
//         overflow: 'hidden',
//         '@media (max-width: 600px)': {
//           m: '2px',
//           p: '2px',
//         },
//       }}
//       onClick={onClick}
//     >
//       <CardActionArea>
//         {activeEvent && (
//           <Ribbon
//             text={`${t(activeEvent.name)} ${activeEvent.discount}% OFF`}
//             color="secondary"
//           />
//         )}
//         {horizontal && isMobile ? (
//           <Box display="flex" flexDirection="row" sx={{ height: '170px' }}>
//             <Box
//               sx={{
//                 width: '40%',
//                 height: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <CardMedia
//                 component="img"
//                 image={imageUrl}
//                 alt={product.name}
//                 sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
//               />
//             </Box>
//             <CardContent
//               sx={{
//                 width: '60%',
//                 padding: '8px',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 marginTop: 'auto',
//                 marginBottom: 'auto',
//               }}
//             >
//               <Typography gutterBottom variant="h6" component="div" noWrap>
//                 {product.title}
//               </Typography>
//               <Typography variant="body2" color="text.secondary" noWrap>
//                 {product.content}
//               </Typography>

//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 0,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={productState.icon}
//                   label={productState.text}
//                   color={productState.color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{ flexGrow: 1 }}
//                 >
//                   {formatPrice(discountedPrice)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Box>
//         ) : (
//           <>
//             <CardMedia
//               component={RouterLink}
//               to={`/product_detail/${product.uid}/${product.id}`}
//               sx={{
//                 height: 0,
//                 paddingTop: '56.25%',
//                 backgroundImage: `url(${imageUrl})`,
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center',
//                 borderRadius: '8px',
//                 '@media (max-width: 600px)': {
//                   height: '150px',
//                   paddingTop: 0,
//                 },
//               }}
//               alt={product.productName}
//             />
//             <CardContent sx={{ padding: '8px' }}>
//               <Typography gutterBottom variant="h6" component="div" noWrap>
//                 {product.title}
//               </Typography>
//               <Typography variant="body2" color="text.secondary" noWrap>
//                 {product.content}
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                   mb: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={productState.icon}
//                   label={productState.text}
//                   color={productState.color}
//                   size="small"
//                   variant="outlined"
//                   sx={{ fontSize: '0.75rem', height: '20px' }}
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{ flexGrow: 1 }}
//                 >
//                   {formatPrice(discountedPrice)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </>
//         )}
//       </CardActionArea>
//     </Card>
//   );
// };

// export default Product;

import React, { useEffect, useState, useMemo } from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Box,
  Chip,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import { orange } from '@mui/material/colors';
import { formatPrice } from './Utils/PriceFormatter';
import { formatState } from './ProductStatus';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import productDefaultImage from './assets/images/productDefaultImage.png';
import Ribbon from './Weather/Ribbon'; // Custom ribbon component
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles'; // Custom ThemeContext
import { fetchWeatherEvents } from './api/WeatherAPI'; // Fetch events from Firestore
import { useWeather } from './Weather/WeatherProvider';
import { useWeatherEvent } from './Weather/useWeatherEvent';
import { useWeatherEvents } from './Weather/useWeatherEvents';
import { isWeatherEventActive } from './Weather/WeatherEventRules';
import WeatherPriceDisplay from './Weather/WeatherPriceDisplay';
import WeatherEventDisplay from './Weather/WeatherEventDisplay';
import { useGlobalContext } from './useGlobalContext';
import ProductCard from './ProductCard';

const Product = ({ product, onClick, horizontal }) => {
  const { t, i18n } = useTranslation();
  const currentWeather = useSelector((state) => state.weather.weatherData);
  // const [eventData, setEventData] = useState([]);
  const theme = useTheme(); // Access custom theme from ThemeContext
  const { defaultAspectRatio, pcAspectRatio, mobileAspectRatio } =
    useGlobalContext();
  const { weatherData } = useWeather();
  const { weatherEvents } = useWeatherEvents();
  const { weatherEvent } = useWeatherEvent(product.weatherEvent);

  const isEventActive = useMemo(
    () => weatherEvent && isWeatherEventActive(weatherData, weatherEvent),
    [weatherData, weatherEvent]
  );
  // useEffect(() => {
  //   moment.locale(i18n.language);
  // }, [i18n.language]);

  const dateObj = product?.createDate?.toDate();
  const formattedDate = moment(dateObj).fromNow();
  const imageUrl =
    product.icon_images && product.icon_images.length > 0
      ? product.icon_images[0]
      : productDefaultImage;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const productState = formatState(product.state, t);

  // const activeEvent = weatherEvents?.find(
  //   (event) =>
  //     checkEventCondition(event, currentWeather) &&
  //     product.event?.id === event.id
  // );

  // const discountedPrice = activeEvent
  //   ? product.price * (1 - activeEvent.discount / 100)
  //   : product.price;

  // 할인된 가격 계산 (이벤트 활성 상태일 경우)
  const discountedPrice = isEventActive
    ? Math.round(product.price * (1 - weatherEvent.discount / 100))
    : product.price;

  return (
    <ProductCard
      product={product}
      weatherEvent={weatherEvent}
      isEventActive={isEventActive}
      t={t}
      onClick={onClick}
    />

    // <Card
    //   sx={{
    //     maxWidth: '100%',
    //     m: theme.spacing(0),
    //     p: theme.spacing(0),
    //     boxShadow: theme.shadows[3],
    //     borderRadius: theme.shape.borderRadius,
    //     backgroundColor: theme.palette.background.default,

    //     overflow: 'hidden',
    //   }}
    //   onClick={onClick}
    // >
    //   <CardActionArea>
    //     {/* 🔹 이벤트 활성 상태일 때 배지 표시 */}
    //     {isEventActive && (
    //       <Box
    //         sx={{
    //           position: 'absolute',
    //           top: theme.spacing(0),
    //           left: theme.spacing(0),
    //           backgroundColor: '#FF5722 !important', //theme.palette.secondary.main,
    //           color: theme.palette.common.white,
    //           borderRadius: theme.shape.borderRadius,
    //           px: 0,
    //           py: 0,
    //           display: 'flex',
    //           alignItems: 'center',
    //           gap: 0,
    //         }}
    //       >
    //         <img
    //           src={weatherEvent.iconUrl}
    //           alt={weatherEvent.name[i18n.language]}
    //           style={{ width: 20, height: 20, borderRadius: '50%' }}
    //         />

    //         <Typography variant="body2">
    //           {weatherEvent.discount}% OFF
    //         </Typography>
    //       </Box>
    //     )}
    //     {horizontal && isMobile ? (
    //       <Box display="flex" flexDirection="row">
    //         <Box
    //           sx={{
    //             width: '40%',
    //             height: '100%',
    //             display: 'flex',
    //             alignItems: 'center',
    //             justifyContent: 'flex-start',
    //           }}
    //         >
    //           <CardMedia
    //             component="img"
    //             image={imageUrl}
    //             alt={product.name}
    //             sx={{
    //               width: '100%',
    //               height: '100%',
    //               p: theme.spacing(1),
    //               objectFit: 'cover',
    //               aspectRatio: mobileAspectRatio,
    //               borderRadius: theme.shape.borderRadius,
    //             }}
    //           />
    //         </Box>
    //         <CardContent
    //           sx={{
    //             width: '60%',
    //             padding: theme.spacing(1),
    //             height: '100%',
    //             display: 'flex',
    //             flexDirection: 'column',
    //             justifyContent: 'flex-start',
    //           }}
    //         >
    //           <Typography
    //             variant="h5"
    //             sx={{
    //               fontWeight: 600,
    //               //paddingBottom: theme.spacing(2),
    //               color: theme.palette.text.secondary,
    //               textAlign: 'left',
    //             }}
    //           >
    //             {product.title}
    //           </Typography>
    //           <Typography
    //             variant="body2"
    //             sx={{ color: theme.palette.text.secondary, textAlign: 'left' }}
    //             noWrap
    //           >
    //             {product.content}
    //           </Typography>
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'space-between',
    //               mt: theme.spacing(1),
    //             }}
    //           >
    //             <Typography
    //               variant="body2"
    //               sx={{
    //                 color: theme.palette.text.secondary,
    //                 textAlign: 'left',
    //               }}
    //             >
    //               {formattedDate}
    //             </Typography>
    //             <Chip
    //               icon={productState.icon}
    //               label={productState.text}
    //               color={productState.color}
    //               size="small"
    //               variant="outlined"
    //             />
    //           </Box>
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'flex-start',
    //             }}
    //           >
    //             <WeatherEventDisplay
    //               weatherEvent={weatherEvent}
    //               isEventActive={isEventActive}
    //             />
    //           </Box>
    //           <WeatherPriceDisplay
    //             product={product}
    //             weatherEvent={weatherEvent}
    //             isEventActive={isEventActive}
    //           />
    //         </CardContent>
    //       </Box>
    //     ) : (
    //       <>
    //         <CardMedia
    //           component={RouterLink}
    //           to={`/product_detail/${product.uid}/${product.id}`}
    //           sx={{
    //             //height: 0,
    //             //paddingTop: '56.25%',
    //             backgroundImage: `url(${imageUrl})`,
    //             backgroundSize: 'cover',
    //             backgroundPosition: 'center',
    //             objectFit: 'cover',
    //             m: theme.spacing(0), //사진과 박스의 간격
    //             aspectRatio: pcAspectRatio,
    //             borderRadius: theme.shape.borderRadius,
    //           }}
    //           alt={product.productName}
    //         />
    //         <CardContent
    //           sx={{
    //             backgroundColor: theme.palette.background.default,
    //             padding: theme.spacing(1),
    //           }}
    //         >
    //           <Typography
    //             variant="h6"
    //             sx={{
    //               fontWeight: 600,
    //               paddingBottom: theme.spacing(0),
    //               color: theme.palette.text.primary,
    //               textAlign: 'left',
    //             }}
    //           >
    //             {product.title}
    //           </Typography>
    //           <Typography
    //             variant="body2"
    //             sx={{ color: theme.palette.text.secondary, textAlign: 'left' }}
    //             noWrap
    //           >
    //             {product.content}
    //           </Typography>
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'space-between',
    //               mt: theme.spacing(0),
    //             }}
    //           >
    //             <Typography
    //               variant="body2"
    //               sx={{
    //                 color: theme.palette.text.secondary,
    //                 textAlign: 'left',
    //               }}
    //             >
    //               {formattedDate}
    //             </Typography>
    //             <Chip
    //               icon={productState.icon}
    //               label={productState.text}
    //               color={productState.color}
    //               size="small"
    //               variant="outlined"
    //             />
    //           </Box>
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'flex-start',
    //             }}
    //           >
    //             <WeatherEventDisplay
    //               weatherEvent={weatherEvent}
    //               isEventActive={isEventActive}
    //             />
    //           </Box>
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'space-between',
    //               //mt: theme.spacing(2),
    //             }}
    //           >
    //             <WeatherPriceDisplay
    //               product={product}
    //               weatherEvent={weatherEvent}
    //               isEventActive={isEventActive}
    //             />
    //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //               <Tooltip title="Likes">
    //                 <IconButton aria-label="add to favorites" size="small">
    //                   <FavoriteIcon />
    //                   <Typography variant="caption" sx={{ ml: 0.5 }}>
    //                     {product.likes}
    //                   </Typography>
    //                 </IconButton>
    //               </Tooltip>
    //               <Tooltip title="Views">
    //                 <IconButton aria-label="view count" size="small">
    //                   <VisibilityIcon />
    //                   <Typography variant="caption" sx={{ ml: 0.5 }}>
    //                     {product.views}
    //                   </Typography>
    //                 </IconButton>
    //               </Tooltip>
    //               <Tooltip title="Chat">
    //                 <IconButton aria-label="chat count" size="small">
    //                   <ChatIcon />
    //                   <Typography variant="caption" sx={{ ml: 0.5 }}>
    //                     {product.chats}
    //                   </Typography>
    //                 </IconButton>
    //               </Tooltip>
    //             </Box>
    //           </Box>
    //         </CardContent>
    //       </>
    //     )}
    //   </CardActionArea>
    // </Card>
  );
};

export default Product;
