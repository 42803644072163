import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudIcon from '@mui/icons-material/Cloud';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import AirIcon from '@mui/icons-material/Air';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import FireIcon from '@mui/icons-material/LocalFireDepartment';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import { useTranslation } from 'react-i18next';
import { useWeather } from './WeatherProvider';
import { useWeatherEvents } from './useWeatherEvents';
import { isWeatherEventActive } from './WeatherEventRules';

const WeatherEventDetails = ({ open, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { weatherData } = useWeather();
  const { weatherEvents } = useWeatherEvents();

  if (!weatherData || !weatherEvents) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" color={theme.palette.primary.main}>
          {t('weather.weatherEventTitle')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {weatherEvents.map((weatherEvent) => {
            const isActive = isWeatherEventActive(weatherData, weatherEvent);

            return (
              <React.Fragment key={weatherEvent.id}>
                <ListItem
                  sx={{
                    bgColor: isActive
                      ? theme.palette.success.light
                      : theme.palette.background.paper,
                    border: `2px solid ${
                      isActive
                        ? theme.palette.success.main
                        : theme.palette.divider
                    }`,
                    borderRadius: 2,
                    mb: 2,
                    boxShadow: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    p: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: isActive
                        ? theme.palette.success.main
                        : theme.palette.text.primary,
                    }}
                  >
                    {weatherEvent.name}
                  </Typography>

                  {/* 🔹 공통 필드 */}
                  <CommonFields
                    weatherData={weatherData}
                    descKey={weatherEvent.descKey}
                    t={t}
                  />

                  {/* 🔹 추가 필드 */}
                  <AdditionalFields
                    weatherData={weatherData}
                    descKey={weatherEvent.descKey}
                    t={t}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

// 🔹 공통 필드 렌더링
const CommonFields = ({ weatherData, descKey, t }) => {
  return (
    <Box mt={1}>
      <Typography variant="subtitle1" color="primary">
        {t('공통 정보')}
      </Typography>
      <Condition
        icon={<CloudIcon />}
        label={t('weather.current')}
        value={weatherData.weather?.[0]?.main}
        condition={`(${descKey.weather?.join(', ') ?? 'N/A'})`}
        status={descKey.weather?.includes(weatherData.weather?.[0]?.main)}
      />
      {(descKey.minTemp !== undefined || descKey.maxTemp !== undefined) && (
        <Condition
          icon={<ThermostatIcon />}
          label={t('weather.temp')}
          value={`${weatherData.main?.temp}°C`}
          condition={`(${descKey.minTemp}°C ~ ${descKey.maxTemp}°C)`}
          status={
            weatherData.main?.temp >= descKey.minTemp &&
            weatherData.main?.temp <= descKey.maxTemp
          }
        />
      )}
      {(descKey.minHumidity !== undefined ||
        descKey.maxHumidity !== undefined) && (
        <Condition
          icon={<WaterDropIcon />}
          label={t('weather.humidity')}
          value={`${weatherData.main?.humidity}%`}
          condition={`(${descKey.minHumidity}% ~ ${descKey.maxHumidity}%)`}
          status={
            weatherData.main?.humidity >= descKey.minHumidity &&
            weatherData.main?.humidity <= descKey.maxHumidity
          }
        />
      )}
    </Box>
  );
};

// 🔹 추가 필드 렌더링
const AdditionalFields = ({ weatherData, descKey, t }) => {
  return (
    <Box mt={1}>
      <Typography variant="subtitle1" color="primary">
        {t('추가 조건')}
      </Typography>
      {descKey.activeHours && descKey.activeHours.length === 2 && (
        <Condition
          icon={<AccessTimeIcon />}
          label={t('weather.activeHours')}
          value={`${descKey.activeHours[0]}시 ~ ${descKey.activeHours[1]}시`}
          status={isActiveDuringHours(descKey)}
        />
      )}
      {descKey.minWindSpeed !== undefined &&
        descKey.maxWindSpeed !== undefined && (
          <Condition
            icon={<AirIcon />}
            label={t('weather.windSpeed')}
            value={`${weatherData.wind?.speed} m/s`}
            condition={`(${descKey.minWindSpeed} ~ ${descKey.maxWindSpeed} m/s)`}
            status={
              weatherData.wind?.speed >= descKey.minWindSpeed &&
              weatherData.wind?.speed <= descKey.maxWindSpeed
            }
          />
        )}
      {/* ☔️ 강수량 */}
      {descKey.minPrecipitation !== undefined &&
        descKey.maxPrecipitation !== undefined && (
          <Condition
            icon={<WaterDropIcon />}
            label={t('weather.precipitation')}
            value={`${weatherData.rain?.['1h'] ?? 0} mm`}
            condition={`(${descKey.minPrecipitation} mm ~ ${descKey.maxPrecipitation} mm)`}
            status={
              (weatherData.rain?.['1h'] ?? 0) >= descKey.minPrecipitation &&
              (weatherData.rain?.['1h'] ?? 0) <= descKey.maxPrecipitation
            }
          />
        )}
      {/* ☔️ 강설량 */}
      {descKey.minSnowfall !== undefined &&
        descKey.maxSnowfall !== undefined && (
          <Condition
            icon={<AcUnitIcon />}
            label={t('weather.snowfall')}
            value={`${weatherData.snow?.['1h'] ?? 0} mm`}
            condition={`(${descKey.minSnowfall} mm ~ ${descKey.maxSnowfall} mm)`}
            status={
              (weatherData.snow?.['1h'] ?? 0) >= descKey.minSnowfall &&
              (weatherData.snow?.['1h'] ?? 0) <= descKey.maxSnowfall
            }
          />
        )}
      {/* ☁️ 미세먼지 PM10 */}
      {descKey.minPM10 !== undefined && descKey.maxPM10 !== undefined && (
        <Condition
          icon={<CloudQueueIcon />}
          label={t('weather.PM10')}
          value={`${weatherData.airQuality?.pm10 ?? 0} µg/m³`}
          range={`${descKey.minPM10} µg/m³ ~ ${descKey.maxPM10} µg/m³`}
          status={
            weatherData.airQuality?.pm10 >= descKey.minPM10 &&
            weatherData.airQuality?.pm10 <= descKey.maxPM10
          }
        />
      )}

      {/* ☁️ 초미세먼지 PM2.5 */}
      {descKey.minPM25 !== undefined && descKey.maxPM25 !== undefined && (
        <Condition
          icon={<CloudQueueIcon />}
          label={t('weather.PM2.5')}
          value={`${weatherData.airQuality?.pm2_5 ?? 0} µg/m³`}
          range={`${descKey.minPM25} µg/m³ ~ ${descKey.maxPM25} µg/m³`}
          status={
            weatherData.airQuality?.pm2_5 >= descKey.minPM25 &&
            weatherData.airQuality?.pm2_5 <= descKey.maxPM25
          }
        />
      )}

      {/* 🌡️ 온도 */}
      {/* {descKey.minTemp !== undefined && (
        <Condition
          icon={<ThermostatIcon />}
          label={t('weather.Temperature')}
          value={`${weatherData.main?.temp}°C`}
          range={`${descKey.minTemp}°C 이상`}
          status={weatherData.main?.temp >= descKey.minTemp}
        />
      )} */}

      {/* 🔥 체감온도 */}
      {descKey.minFeelsLike !== undefined && (
        <Condition
          icon={<FireIcon />}
          label={t('weather.Feels Like')}
          value={`${weatherData.main?.feels_like}°C`}
          range={`${descKey.minFeelsLike}°C 이상`}
          status={weatherData.main?.feels_like >= descKey.minFeelsLike}
        />
      )}

      {/* ☀️ UV Index */}
      {descKey.minUVIndex !== undefined && (
        <Condition
          icon={<WbSunnyIcon />}
          label={t('weather.UV Index')}
          value={`${weatherData.uvi ?? 0}`}
          range={`${descKey.minUVIndex} 이상`}
          status={weatherData.uvi >= descKey.minUVIndex}
        />
      )}

      {/* 🌙 밤 최저 온도 */}
      {descKey.nightMinTemp !== undefined && (
        <Condition
          icon={<NightsStayIcon />}
          label={t('weather.Night Min Temp')}
          value={`${weatherData.main?.temp_min}°C`}
          range={`${descKey.nightMinTemp}°C 이상`}
          status={weatherData.main?.temp_min >= descKey.nightMinTemp}
        />
      )}
    </Box>
  );
};

// 🔹 시간 조건 확인
const isActiveDuringHours = (descKey) => {
  if (!descKey.activeHours || descKey.activeHours.length !== 2) return true;
  const currentHour = new Date().getHours();
  return (
    currentHour >= descKey.activeHours[0] &&
    currentHour <= descKey.activeHours[1]
  );
};

// 🔹 조건 렌더링 컴포넌트
const Condition = ({ icon, label, value, condition, status }) => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center" mt={1}>
      {icon}
      <Typography variant="body2" sx={{ ml: 1, fontWeight: 500 }}>
        {label}:
      </Typography>
      <Typography
        variant="body2"
        sx={{ ml: 1, fontWeight: 'bold', color: theme.palette.text.primary }}
      >
        {value ?? 'N/A'}
      </Typography>
      {condition && (
        <Typography
          variant="body2"
          sx={{
            ml: 1,
            fontStyle: 'italic',
            color: theme.palette.text.secondary,
          }}
        >
          {condition}
        </Typography>
      )}
      <Typography
        variant="body2"
        sx={{
          ml: 1,
          color: status ? theme.palette.success.main : theme.palette.error.main,
        }}
      >
        {status ? '✅' : '❌'}
      </Typography>
    </Box>
  );
};

export default WeatherEventDetails;
