import React from 'react';
import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const SkeletonFriendonHome = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: isMobile ? '10vh' : '20vh',
        px: 2,
      }}
    >
      {/* FRIENDON 로고 영역 */}
      <Skeleton
        variant="rectangular"
        width={160}
        height={40}
        sx={{ borderRadius: 2, mb: 3 }}
      />

      {/* 위치 & 날씨 이벤트 제목 */}
      <Skeleton
        variant="text"
        width={isMobile ? 240 : 400}
        height={36}
        sx={{ mb: 4 }}
      />

      {/* 필터 3개 (위치/날씨/검색) */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center', // ✅ 수평 가운데 정렬
          alignItems: 'center', // ✅ 수직 가운데 정렬 (column일 경우 유용)
          gap: 2,
          width: '100%',
          maxWidth: isMobile ? '100%' : 1000,
          mb: 4,
          mx: 'auto', // ✅ 수평 마진 자동 → 부모 기준 가운데 정렬
        }}
      >
        <Skeleton
          variant="rectangular"
          height={56}
          width={isMobile ? '100%' : 230}
          sx={{ borderRadius: 2 }}
        />
        <Skeleton
          variant="rectangular"
          height={56}
          width={isMobile ? '100%' : 230}
          sx={{ borderRadius: 2 }}
        />
        {!isMobile && (
          <Skeleton
            variant="rectangular"
            height={56}
            width={300}
            sx={{ borderRadius: 2 }}
          />
        )}
      </Box>

      {/* 실시간 검색어 영역 */}
      <Skeleton
        variant="text"
        width={isMobile ? '90%' : 480}
        height={28}
        sx={{ mt: 1 }}
      />
    </Box>
  );
};
