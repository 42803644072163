// components/WeatherEventSelect.tsx
import React from 'react';
import {
  Box,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';

interface WeatherEvent {
  id: string;
  iconUrl?: string;
  name: Record<string, string>;
}

interface WeatherEventSelectProps {
  events: WeatherEvent[];
  selectedEventId: string;
  onSelect: (eventId: string) => void;
  language: string;
  selectRef?: React.RefObject<HTMLSelectElement>;
  height?: number;
  minWidth?: number | string;
}

const WeatherEventSelect: React.FC<WeatherEventSelectProps> = ({
  events,
  selectedEventId,
  onSelect,
  language,
  selectRef,
  height = 60,
  minWidth = 180,
}) => {
  return (
    <Box sx={{ minWidth }}>
      <Select
        ref={selectRef}
        value={selectedEventId}
        onChange={(e) => onSelect(e.target.value)}
        displayEmpty
        fullWidth
        sx={{
          borderRadius: 16,
          backgroundColor: 'background.paper',
          color: 'text.primary',
          height,
        }}
      >
        {events.map((event) => (
          <MenuItem key={event.id} value={event.id}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <img
                src={event.iconUrl || ''}
                alt="event icon"
                style={{ width: 24, height: 24 }}
              />
              <Typography variant="body1">
                {event.name[language] || '이벤트'}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default WeatherEventSelect;
