import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useLocation,
  useNavigate,
  Link as RouterLink,
  useSearchParams,
} from 'react-router-dom';
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Button,
  Box,
  Container,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  Breadcrumbs,
  Link,
  Paper,
  Fab,
  TablePagination,
  useMediaQuery,
  Fade,
  IconButton,
  Grow,
  Stack,
} from '@mui/material';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Upload,
  Chat,
  Store as StoreIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import Product from './Product';
import Store from './Stores/Store';
import ProductCategorySelector from './Stores/ProductCategorySelector';
import ProductBrandSelector from './Stores/ProductBrandSelector';
import moment from 'moment';
import { incrementVisitorCount } from './Utils/VisitorCounter';
import {
  db,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  orderBy,
  doc,
  limit,
  startAfter,
} from './firebaseConfig';
import {
  setSearchCategory,
  setSearchCategoryPath,
  setSearchKeyword,
  resetSearchKeyword,
  resetSearchCategory,
  resetSearchCategoryPath,
  setSearchBrand,
  resetSearchBrand,
  setSearchBrandPath,
  resetSearchBrandPath,
} from './Redux/reducers/searchReducer';

import { useTranslation } from 'react-i18next';
import { googleTranslate } from './Utils/GoogleTranslation';
import { fetchUserAgreementStatus } from './Utils/Agreement';
import { GeoPoint } from 'firebase/firestore';
import { getUserLocation } from './Utils/LocationUtils';
import {
  exportFetchUserProductsByCoordinates,
  exportFetchUserProductsByLastDocAsMap,
  exportFetchStoreProductsByCoordinates,
  exportFetchStoresByCoordinates,
  exportFetchStoresByLastDocAsMap,
  exportFetchStoresPerPage,
  exportFilterAndSortProducts,
  exportFetchSelectedTownByUserId,
  exportFetchUserProductsByNotLoggedIn,
  exportFetchStoresByNotLoggedIn,
} from './Utils/LocationStoresAndProducts';

import useSearchFilters from './useSearchFilters';
import useMessageBox from './Common/useMessageBox';
import useUserActivity from './useUserActivity';
import ConcertStadium from './Concert/ConcertStadium';
import ConcertStadium3D from './Concert/ConcertStadium3D';
import Stadium from './Concert/Stadium';
import StoreProduct from './Stores/StoreProduct';
import Lottie from 'lottie-react';
import noUserProductResults from './assets/lottie/no-userproduct-results.json';
import noStoreProductResults from './assets/lottie/no-storeproduct-results.json';
import noStoreResults from './assets/lottie/no-store-results.json';
import TermsCheck from './Stores/Admin/TermsCheck';
import PrivacyCheck from './Stores/Admin/PrivacyCheck';
import MobileNavBar from './NavBar/MobileNavBar';
import MobileMenuAppBar from './NavBar/MobileMenuAppBar';
import NotificationBanner from './NotificationBanner';
import UserRatings from './UserRating';
import RatingForm from './UserRatingForm';
import UserRatingForm from './UserRatingForm';
import MyTownWeather from './Weather/MyTownWeather';
import WeatherBanner from './Weather/WeatherBanner';
import NoUserProducts from './NoUserProducts';
import NoStoreProducts from './NoStoreProducts';
import NoStores from './NoStores';
import HomeFloatingMenu from './HomeFloatingMenu';
import InfiniteScrollContainer from './Common/InfiniteScrollContainer';
import ListHeader from './Common/ListHeader';
import HomeTabs from './HomeTabs';
import LeftSideMenu from './LeftSideMenu';
import { SkeletonProductGrid } from './Skeleton/SkeletonProductGrid';

function Home() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const { searchParams, handleSelectFilter, getFilterParams } =
    useSearchFilters();
  const [userProducts, setUserProducts] = useState([]);
  const [lastUserProduct, setLastUserProduct] = useState(null);
  const [hasMoreUserProducts, setHasMoreUserProducts] = useState(true);
  const [userProductsPage, setUserProductsPage] = useState(0);
  const [userProductsTotalCount, setUserProductsTotalCount] = useState(0);
  const [storeProducts, setStoreProducts] = useState([]);
  const [lastStoreProduct, setLastStoreProduct] = useState(null);
  const [hasMoreStoreProducts, setHasMoreStoreProducts] = useState(true);
  const [storeProductsPage, setStoreProductsPage] = useState(0);
  const [storeProductsTotalCount, setStoreProductsTotalCount] = useState(0);
  const [stores, setStores] = useState([]);
  const [lastStore, setLastStore] = useState(null);
  const [hasMoreStores, setHasMoreStores] = useState(true);
  const [storesPage, setStoresPage] = useState(0);
  const [storesTotalCount, setStoresTotalCount] = useState(0);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [sortOption, setSortOption] = useState('latest');
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const itemsPerPage = 10;
  const searchKeyword = useSelector((state) => state.search.searchKeyword);
  const searchCategory = useSelector((state) => state.search.searchCategory);
  const searchCategoryPath = useSelector(
    (state) => state.search.searchCategoryPath
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [error, setError] = useState([]);

  const theme = useTheme();

  const [needsTermsCheck, setNeedsTermsCheck] = useState(false);
  const [needsPrivacyCheck, setNeedsPrivacyCheck] = useState(false);

  const filterKey = React.useMemo(() => {
    const {
      keyword,
      categoryId,
      brandId,
      weatherId,
      sort,
      states,
      priceRange,
      latitude,
      longitude,
      radius,
    } = getFilterParams();

    return JSON.stringify({
      keyword,
      categoryId,
      brandId,
      weatherId,
      sort,
      states,
      priceRange,
      latitude,
      longitude,
      radius,
    });
  }, [searchParams.toString()]);

  useEffect(() => {
    const fetchFilteredHomeData = async () => {
      const {
        keyword,
        categoryId,
        brandId,
        weatherId,
        sort,
        states,
        priceRange: [minPrice, maxPrice],
        latitude,
        longitude,
        radius,
      } = getFilterParams();

      try {
        setUserLoading(true);
        setStoreLoading(true);

        if (!currentUser) {
          // 🔹 비로그인 유저: 페이지네이션 기반 단순 로딩
          if (userProductsPage === 0) setUserProducts([]);
          if (storesPage === 0) setStores([]);

          const [userResult, storeResult] = await Promise.all([
            exportFetchUserProductsByNotLoggedIn(
              itemsPerPage,
              userProductsPage
            ),
            exportFetchStoresByNotLoggedIn(itemsPerPage, storesPage),
          ]);

          if (Array.isArray(userResult.products)) {
            setUserProducts((prev) => [...prev, ...userResult.products]);
            setHasMoreUserProducts(userResult.products.length === itemsPerPage);
            setUserProductsTotalCount(userResult.totalCount);
          }

          if (Array.isArray(storeResult.stores)) {
            setStores((prev) => [...prev, ...storeResult.stores]);
            setHasMoreStores(storeResult.stores.length === itemsPerPage);
            setStoresTotalCount(storeResult.totalCount);
          }

          return;
        }

        // 🔹 로그인 유저: 위치 기반 필터 처리
        const location =
          latitude && longitude
            ? {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
                radius: parseFloat(radius) || 3,
              }
            : await exportFetchSelectedTownByUserId(currentUser.uid);

        if (!location) {
          console.warn('[Home] 유저 위치 정보가 없습니다.');
          return;
        }

        const [userFiltered, storeFiltered] = await Promise.all([
          exportFilterAndSortProducts({
            uid: currentUser.uid,
            type: 'user',
            keyword,
            categoryId,
            brandId,
            weatherId,
            states,
            minPrice,
            maxPrice,
            location,
            sort,
          }),
          exportFilterAndSortProducts({
            uid: currentUser.uid,
            type: 'store',
            keyword,
            categoryId,
            brandId,
            weatherId,
            states,
            minPrice,
            maxPrice,
            location,
            sort,
          }),
        ]);

        if (JSON.stringify(userFiltered) !== JSON.stringify(userProducts)) {
          setUserProducts(userFiltered);
        }
        if (JSON.stringify(storeFiltered) !== JSON.stringify(storeProducts)) {
          setStoreProducts(storeFiltered);
        }
      } catch (err) {
        console.error('[Home] 필터 기반 데이터 로딩 오류:', err);
        setError((prev) => [...prev, err.message]);
      } finally {
        setUserLoading(false);
        setStoreLoading(false);
      }
    };

    fetchFilteredHomeData();
  }, [filterKey, currentUser]);

  useEffect(() => {
    const updateVisitorCounts = async () => {
      await incrementVisitorCount();
    };

    updateVisitorCounts();
  }, []);

  useEffect(() => {
    const checkAgreements = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists() && userDoc.data().step === 'signupCompleted') {
          const { needsTerms, needsPrivacy } = await fetchUserAgreementStatus(
            currentUser.uid
          );
          setNeedsTermsCheck(needsTerms);
          setNeedsPrivacyCheck(needsPrivacy);
        }
      }
      setLoading(false); // 로딩 완료
    };

    checkAgreements();
  }, [currentUser]);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const {
    logViewedProduct,
    logPurchasedProduct,
    logSearchedKeyword,
    logSearchedCategory,
    logSearchedBrand,
  } = useUserActivity(currentUser?.uid);

  useEffect(() => {
    const fetchUserProducts = async () => {
      try {
        setLoading(true); // 데이터 로딩 시작
        if (!currentUser) {
          if (userProductsPage === 0) {
            setUserProducts([]);
          }
          const userProductsResponse =
            await exportFetchUserProductsByNotLoggedIn(
              itemsPerPage,
              userProductsPage
            );

          if (
            userProductsResponse &&
            Array.isArray(userProductsResponse.products)
          ) {
            setUserProducts((prevProducts) => [
              ...prevProducts,
              ...userProductsResponse.products,
            ]);

            setHasMoreUserProducts(
              userProductsResponse.products.length === itemsPerPage &&
                userProductsResponse.products.length !== 0
            );
            setUserProductsTotalCount(userProductsResponse.totalCount);
          } else {
            throw new Error('Unexpected response format');
          }
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setError((prevErrors) => [
          ...prevErrors,
          `Error fetching products: ${error.message}`,
        ]);
      } finally {
        setLoading(false); // 데이터 로딩 완료
      }
    };

    fetchUserProducts();
  }, [userProductsPage, currentUser]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        setLoading(true); // 데이터 로딩 시작
        if (!currentUser) {
          if (storesPage === 0) {
            setStoreProducts([]);
          }
          const storesResponse = await exportFetchStoresByNotLoggedIn(
            itemsPerPage,
            storesPage
          );

          if (storesResponse && Array.isArray(storesResponse.stores)) {
            setStores((prevStores) => [
              ...prevStores,
              ...storesResponse.stores,
            ]);
            setHasMoreStores(storesResponse.stores.length === itemsPerPage);
            setStoresTotalCount(storesResponse.totalCount);
          } else {
            throw new Error('Unexpected response format');
          }
        }
      } catch (error) {
        console.error('Error fetching stores:', error);
        setError((prevErrors) => [
          ...prevErrors,
          `Error fetching stores: ${error.message}`,
        ]);
      } finally {
        setLoading(false); // 데이터 로딩 완료
      }
    };

    fetchStores();
  }, [storesPage, currentUser]);

  useEffect(() => {
    const fetchStoreProducts = async () => {
      try {
        setLoading(true); // 데이터 로딩 시작
        if (currentUser) {
          const location = await exportFetchSelectedTownByUserId(
            currentUser.uid
          );
          if (!location) {
            throw new Error('Failed to fetch location.');
          }
          const storeProductsResponse =
            await exportFetchStoreProductsByCoordinates(
              location.lat,
              location.lng,
              location.radius,
              currentUser.uid
            );

          if (storeProductsResponse && storeProductsResponse.products) {
            setStoreProducts(Object.values(storeProductsResponse.products));
            setHasMoreStoreProducts(storeProductsResponse.products.length > 0);
            setUserProductsTotalCount(storeProductsResponse.totalCount);
          } else {
            throw new Error('Unexpected response format');
          }
        }
      } catch (error) {
        console.error('Error fetching store products:', error);
        setError((prevErrors) => [
          ...prevErrors,
          `Error fetching store products: ${error.message}`,
        ]);
      } finally {
        setLoading(false); // 데이터 로딩 완료
      }
    };

    fetchStoreProducts();
  }, [storeProductsPage, currentUser]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        setLoading(true); // 데이터 로딩 시작
        if (currentUser) {
          const location = await exportFetchSelectedTownByUserId(
            currentUser.uid
          );
          if (!location) {
            throw new Error('Failed to fetch location.');
          }
          const storesResponse = await exportFetchStoresByCoordinates(
            location.lat,
            location.lng,
            location.radius,
            currentUser.uid,
            storesPage,
            itemsPerPage
          );

          if (storesResponse && Array.isArray(storesResponse.stores)) {
            setStores((prevStores) => [
              ...prevStores,
              ...storesResponse.stores,
            ]);
            setHasMoreStores(storesResponse.stores.length === itemsPerPage);
            setStoresTotalCount(storesResponse.totalCount);
          } else {
            throw new Error('Unexpected response format');
          }
        }
      } catch (error) {
        console.error('Error fetching stores:', error);
        setError((prevErrors) => [
          ...prevErrors,
          `Error fetching stores: ${error.message}`,
        ]);
      } finally {
        setLoading(false); // 데이터 로딩 완료
      }
    };

    fetchStores();
  }, [storesPage, currentUser]);

  const handleUpload = () => {
    setOpen((prev) => !prev);
  };
  // 외부 클릭 감지하여 메뉴 닫기
  const handleClickAway = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickAway);
    } else {
      document.removeEventListener('mousedown', handleClickAway);
    }
    return () => document.removeEventListener('mousedown', handleClickAway);
  }, [open]);

  const loadUserProducts = async () => {
    try {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        const location = await exportFetchSelectedTownByUserId(currentUser.uid);
        if (!location && userDoc.data().step === 'signupCompleted') {
          console.log(t('neighborhoodNotification')); // Debugging
          console.log(t('neighborhoodNotDesignatedMessage')); // Debugging
          const isConfirmed = await MessageBoxConfirm(
            t('neighborhoodNotification'),
            t('neighborhoodNotDesignatedMessage')
          );
          if (!isConfirmed) {
            throw new Error('Failed to fetch location.');
          }
          navigate('/settings/mytown');
        }
        const fetchedUserProducts = await exportFetchUserProductsByCoordinates(
          location.lat,
          location.lng,
          location.radius,
          currentUser.uid
        );

        setUserProducts(fetchedUserProducts);
        setHasMoreUserProducts(
          fetchedUserProducts.length === itemsPerPage &&
            fetchedUserProducts.length !== 0
        );
        console.log('Fetched products:', userProducts);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const loadMoreUserProducts = async () => {
    if (!hasMoreUserProducts) return;

    try {
      const userProductsQuery = query(
        collection(db, 'products'),
        orderBy('createDate', 'desc'),
        startAfter(lastUserProduct || 0),
        limit(10)
      );
      const querySnapshot = await getDocs(userProductsQuery);
      const newUserProducts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUserProducts((prevProducts) => [...prevProducts, ...newUserProducts]);
      setLastUserProduct(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMoreUserProducts(
        newUserProducts.length === 10 && !querySnapshot.empty
      );
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error(
          'Firestore index error: Please create the required index in the Firestore console.',
          error
        );
      } else {
        console.error('Error loading more user products:', error);
      }
    }
  };

  const loadStoreProducts = async () => {
    try {
      if (currentUser) {
        const location = await exportFetchSelectedTownByUserId(currentUser.uid);
        if (!location) {
          throw new Error('Failed to fetch location.');
        }
        const storeProductsResponse =
          await exportFetchStoreProductsByCoordinates(
            location.lat,
            location.lng,
            location.radius,
            currentUser.uid
          );

        if (storeProductsResponse && storeProductsResponse.products) {
          setStoreProducts(Object.values(storeProductsResponse.products));
          setHasMoreStoreProducts(storeProductsResponse.products.length > 0);
        } else {
          throw new Error('Unexpected response format');
        }
      }
    } catch (error) {
      console.error('Error fetching store products:', error);
      setError((prevErrors) => [
        ...prevErrors,
        `Error fetching store products: ${error.message}`,
      ]);
    }
  };

  const loadMoreStoreProducts = async () => {
    try {
      if (!hasMoreStoreProducts) return;

      const location = await exportFetchSelectedTownByUserId(currentUser.uid);
      if (!location) {
        throw new Error('Failed to fetch location.');
      }

      const storeProductsResponse = await exportFetchStoreProductsByCoordinates(
        location.lat,
        location.lng,
        location.radius,
        currentUser.uid,
        lastStoreProduct
      );

      if (storeProductsResponse && storeProductsResponse.products) {
        setStoreProducts((prevProducts) => [
          ...prevProducts,
          ...Object.values(storeProductsResponse.products),
        ]);
        setHasMoreStoreProducts(storeProductsResponse.products.length > 0);
        setLastStoreProduct(
          storeProductsResponse.lastDoc
            ? storeProductsResponse.lastDoc.id
            : null
        );
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error loading more store products:', error);
      setError((prevErrors) => [
        ...prevErrors,
        `Error loading more store products: ${error.message}`,
      ]);
    }
  };

  const loadStores = async () => {
    try {
      if (currentUser) {
        const location = await exportFetchSelectedTownByUserId(currentUser.uid);
        if (!location) {
          throw new Error('Failed to fetch location.');
        }
        const storesResponse = await exportFetchStoresByCoordinates(
          location.lat,
          location.lng,
          location.radius,
          currentUser.uid
        );

        if (storesResponse && storesResponse.stores) {
          setStores(storesResponse.stores);
          setHasMoreStores(storesResponse.stores.length > 0);
          setLastStore(storesResponse.lastDoc);
        } else {
          throw new Error('Unexpected response format');
        }
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      setError((prevErrors) => [
        ...prevErrors,
        `Error fetching stores: ${error.message}`,
      ]);
    }
  };

  const loadMoreStores = async () => {
    try {
      if (!hasMoreStores) return;

      const location = await exportFetchSelectedTownByUserId(currentUser.uid);
      if (!location) {
        throw new Error('Failed to fetch location.');
      }

      const storesResponse = await exportFetchStoresByCoordinates(
        location.lat,
        location.lng,
        location.radius,
        currentUser.uid,
        lastStore
      );

      if (storesResponse && storesResponse.stores) {
        setStores((prevStores) => [...prevStores, ...storesResponse.stores]);
        setHasMoreStores(storesResponse.stores.length > 0);
        setLastStore(storesResponse.lastDoc ? storesResponse.lastDoc.id : null);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error loading more stores:', error);
      setError((prevErrors) => [
        ...prevErrors,
        `Error loading more stores: ${error.message}`,
      ]);
    }
  };

  const handleSelectCategory = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };
  const handleUserProductClick = (product) => {
    navigate(`/detail/${product.id}`);
    logViewedProduct(product.id);
  };

  const handleStoreProductClick = (product) => {
    navigate(`/product_detail/${product.uid}/${product.id}`);
    logViewedProduct(product.id);
  };

  const handleStoreClick = (store) => {
    navigate(`/store_home/${store.id}`);
  };

  const handleSortChange = (sortKey) => {
    setSortOption(sortKey);
    const sortedUserProducts = [...userProducts];
    const sortedStores = [...stores];
    switch (sortKey) {
      case 'latest':
        sortedUserProducts.sort(
          (a, b) => new Date(b.createDate) - new Date(a.createDate)
        );
        sortedStores.sort(
          (a, b) => new Date(b.createDate) - new Date(a.createDate)
        );
        break;
      case 'popularity':
        sortedUserProducts.sort((a, b) => b.likes - a.likes);
        sortedStores.sort((a, b) => b.likes - a.likes);
        break;
      case 'lowPrice':
        sortedUserProducts.sort((a, b) => a.price - b.price);
        break;
      case 'highPrice':
        sortedUserProducts.sort((a, b) => b.price - a.price);
        break;
      case 'distance': // 거리순 추가
        sortedUserProducts.sort((a, b) => a.distance - b.distance);
        sortedStores.sort((a, b) => a.distance - b.distance);
        break;
      default:
        break;
    }
    setUserProducts(sortedUserProducts);
  };

  if (loading || userLoading || storeLoading) {
    return (
      <LoadingContainer>
        <SkeletonProductGrid />
      </LoadingContainer>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        pt: 3,
        pb: 10,
      }}
    >
      {!isMobile && (
        <Box sx={{ flexShrink: 0 }}>
          <LeftSideMenu key={filterKey} onSelectFilter={handleSelectFilter} />
        </Box>
      )}

      <Box
        flex={1}
        p={2}
        sx={{
          alignItems: 'center',
          textAlign: 'center',
          width: '95%',
          margin: '0 auto',
          color: theme.palette.primary.main,
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            letterSpacing: '0.5px',
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
          }}
        >
          {t('todaysRecommendations')}
        </Typography>
        <WeatherBanner
          sx={{
            border: '2px solid #FF5722',
            borderRadius: '8px',
            padding: 2,
            boxShadow: 1,
          }}
        />
        {needsTermsCheck && (
          <TermsCheck onAgreementComplete={() => setNeedsTermsCheck(false)} />
        )}
        {!needsTermsCheck && needsPrivacyCheck && (
          <PrivacyCheck
            onAgreementComplete={() => setNeedsPrivacyCheck(false)}
          />
        )}
        {MessageBoxRender()}
        {/* Only list area uses Fade */}
        <Fade in={!userLoading && !storeLoading} timeout={500}>
          <Box>
            <HomeTabs
              userProducts={userProducts}
              stores={stores}
              sortOption={sortOption}
              handleSortChange={handleSortChange}
              loadMoreUserProducts={loadMoreUserProducts}
              loadMoreStores={loadMoreStores}
              hasMoreUserProducts={hasMoreUserProducts}
              hasMoreStores={hasMoreStores}
              handleUserProductClick={handleUserProductClick}
            />
            <HomeFloatingMenu
              open={open}
              handleUpload={handleUpload}
              menuRef={menuRef}
            />
          </Box>
        </Fade>
      </Box>
    </Box>
  );
}
const LoadingContainer = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        pt: 3,
        pb: 10,
      }}
    >
      {!isMobile && (
        <Box sx={{ flexShrink: 0 }}>
          <LeftSideMenu />
        </Box>
      )}
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          minHeight: 'calc(100vh - 64px)', // subtract approximate height of header
          px: 2,
          py: 3,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
export default Home;
