import { createSlice } from '@reduxjs/toolkit';

// 🔹 로컬 스토리지에서 기존 선택된 동네 불러오기 (없으면 기본값 '1')
const initialTown = localStorage.getItem('selectedTown') || '1';

const townSlice = createSlice({
  name: 'town',
  initialState: {
    selectedTown: initialTown, // 🔹 기본값 설정
  },
  reducers: {
    setSelectedTown: (state, action) => {
      state.selectedTown = action.payload;
      localStorage.setItem('selectedTown', action.payload); // 🔹 변경 시 로컬 스토리지 저장
    },
  },
});

// 액션 & 리듀서 내보내기
export const { setSelectedTown } = townSlice.actions;
export default townSlice.reducer;
