// import React, { useEffect, useState } from 'react';
// import {
//   Box,
//   List,
//   ListItem,
//   ListItemText,
//   Collapse,
//   Typography,
//   useTheme,
//   ListItemIcon,
//   Checkbox,
//   FormControlLabel,
//   Slider,
//   Chip,
//   Button,
// } from '@mui/material';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import { fetchBrands, fetchCategories } from './api/SearchAPI';
// import { fetchWeatherEvents } from './api/WeatherAPI';
// import { useTranslation } from 'react-i18next';
// import { useSearchParams } from 'react-router-dom';
// import ProductStatusFilter from './ProductStatusFilter';

// const LeftSideMenu = ({ onSelectFilter }) => {
//   const theme = useTheme();
//   const { t, i18n } = useTranslation();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const keyword = searchParams.get('keyword') || '';
//   const weatherEventId = searchParams.get('weatherEventId') || null;
//   const categoryId = searchParams.get('category') || null;
//   const brandId = searchParams.get('brand') || null;
//   const latitude = parseFloat(searchParams.get('latitude')) || null;
//   const longitude = parseFloat(searchParams.get('longitude')) || null;
//   const radius = parseFloat(searchParams.get('radius')) || 3;
//   const sort = searchParams.get('sort') || 'latest';

//   const [categories, setCategories] = useState([]);
//   const [subCategoriesMap, setSubCategoriesMap] = useState({});
//   const [weatherEvents, setWeatherEvents] = useState([]);
//   const [brands, setBrands] = useState([]);
//   const [openMenus, setOpenMenus] = useState(['category', 'brand', 'weather']);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [selectedCategoryPath, setSelectedCategoryPath] = useState([]);
//   const [userLocation, setUserLocation] = useState(null);
//   const productStates = [
//     { label: t('selling'), value: 'selling' },
//     { label: t('reserved'), value: 'reserved' },
//     { label: t('sold-out'), value: 'sold-out' },
//   ];

//   const [filters, setFilters] = useState({
//     keyword: keyword || '',
//     category: null,
//     brand: null,
//     weather: null,
//     priceRange: [0, 100000],
//     radius: 3,
//     state: ['selling'],
//   });

//   useEffect(() => {
//     const minPrice = parseInt(searchParams.get('minPrice')) || 0;
//     const maxPrice = parseInt(searchParams.get('maxPrice')) || 100000;
//     const radiusParam = parseFloat(searchParams.get('radius')) || 3;
//     const lat = parseFloat(searchParams.get('latitude'));
//     const lng = parseFloat(searchParams.get('longitude'));
//     if (!isNaN(lat) && !isNaN(lng)) {
//       setUserLocation({ lat, lng });
//     }
//     const selectedStates = searchParams.get('state')
//       ? searchParams.get('state').split(',')
//       : ['selling'];
//     const newFilters = {
//       keyword: searchParams.get('keyword') || '',
//       category: searchParams.get('category'),
//       brand: searchParams.get('brand'),
//       weather: searchParams.get('weather'),
//       state: selectedStates,
//       priceRange: [minPrice, maxPrice],
//       radius: radiusParam,
//     };

//     setFilters(newFilters);

//     // 필요한 경우 각 필터 change 이벤트로 onSelectFilter도 호출
//     Object.entries(newFilters).forEach(([key, value]) => {
//       onSelectFilter(key, value);
//     });
//   }, []);

//   const updateFilter = (key, value) => {
//     setFilters((prev) => {
//       const updated = { ...prev, [key]: value };
//       onSelectFilter(key, value);
//       syncQueryParams(updated);
//       return updated;
//     });
//   };

//   const syncQueryParams = (updatedFilters) => {
//     const params = new URLSearchParams();
//     Object.entries(updatedFilters).forEach(([key, value]) => {
//       if (!value || value === false) {
//         params.delete(key);
//         return;
//       }
//       if (key === 'keyword') {
//         params.set(key, value);
//       } else if (key === 'priceRange') {
//         params.set('minPrice', value[0]);
//         params.set('maxPrice', value[1]);
//       } else if (key === 'radius' && userLocation) {
//         params.set('radius', value);
//         params.set('latitude', userLocation?.lat || latitude);
//         params.set('longitude', userLocation?.lng || longitude);
//       } else if (key === 'state' && Array.isArray(value)) {
//         params.set('state', value.join(','));
//       } else if (typeof value === 'object' && value.id) {
//         params.set(key, value.id);
//       } else {
//         params.set(key, value);
//       }
//     });
//     setSearchParams(params);
//   };

//   const removeFilter = (key) => {
//     const defaultValues = {
//       keyword: '',
//       category: null,
//       brand: null,
//       weather: null,
//       priceRange: [0, 100000],
//       radius: 3,
//     };
//     updateFilter(key, defaultValues[key]);
//   };

//   const resetFilters = () => {
//     const defaultFilters = {
//       keyword: '',
//       category: null,
//       brand: null,
//       weather: null,
//       onlySelling: false,
//       priceRange: [0, 100000],
//       radius: 3,
//       state: ['selling'],
//     };
//     setFilters(defaultFilters);
//     Object.entries(defaultFilters).forEach(([key, value]) => {
//       onSelectFilter(key, value);
//     });
//     setSearchParams({});
//   };

//   const handleToggle = (menuKey) => {
//     setOpenMenus((prev) =>
//       prev.includes(menuKey)
//         ? prev.filter((key) => key !== menuKey)
//         : [...prev, menuKey]
//     );
//   };

//   const handleItemClick = async (key, item, level = 0) => {
//     setSelectedItem(item.id);
//     const updatedPath = selectedCategoryPath.slice(0, level + 1);
//     updatedPath[level] = item;
//     setSelectedCategoryPath(updatedPath);
//     updateFilter(key, item);

//     if (key === 'category') {
//       const subcats = await fetchCategories(item.id, i18n.language);
//       if (subcats.length > 0) {
//         setSubCategoriesMap((prev) => ({
//           ...prev,
//           [item.id]: subcats,
//         }));
//       }
//     }
//   };

//   const renderCategoryTree = (items, level = 0) => (
//     <List component="div" disablePadding>
//       {items.map((item) => {
//         const subCategories = subCategoriesMap[item.id] || [];
//         const isExpanded = selectedCategoryPath[level]?.id === item.id;

//         return (
//           <React.Fragment key={item.id}>
//             <ListItem
//               button
//               selected={selectedItem === item.id}
//               onClick={() => handleItemClick('category', item, level)}
//               sx={{
//                 pl: 2 + level * 2,
//                 color: theme.palette.text.primary,
//                 '&.Mui-selected': {
//                   backgroundColor: theme.palette.action.selected,
//                   fontWeight: 'bold',
//                 },
//                 '&:hover': {
//                   backgroundColor: theme.palette.action.hover,
//                 },
//               }}
//             >
//               {item.iconUrl && (
//                 <ListItemIcon>
//                   <img
//                     src={item.iconUrl}
//                     alt="icon"
//                     style={{ width: 20, height: 20, marginRight: 8 }}
//                   />
//                 </ListItemIcon>
//               )}
//               <ListItemText
//                 primary={
//                   item.name
//                   // typeof item.name === 'object'
//                   //   ? item.name[i18n.language] || item.name['en']
//                   //   : item.name
//                 }
//               />
//               {subCategories.length > 0 &&
//                 (isExpanded ? <ExpandLess /> : <ExpandMore />)}
//             </ListItem>

//             <Collapse in={isExpanded} timeout="auto" unmountOnExit>
//               {renderCategoryTree(subCategories, level + 1)}
//             </Collapse>
//           </React.Fragment>
//         );
//       })}
//     </List>
//   );

//   const renderBrandList = () => (
//     <List component="div" disablePadding>
//       {brands.map((brand) => (
//         <ListItem
//           key={brand.id}
//           button
//           selected={filters.brand?.id === brand.id}
//           onClick={() => updateFilter('brand', brand)}
//           sx={{
//             pl: 2,
//             color: theme.palette.text.primary,
//             '&.Mui-selected': {
//               backgroundColor: theme.palette.action.selected,
//               fontWeight: 'bold',
//             },
//             '&:hover': {
//               backgroundColor: theme.palette.action.hover,
//             },
//           }}
//         >
//           {brand.iconUrl && (
//             <ListItemIcon>
//               <img
//                 src={brand.iconUrl}
//                 alt="brand-icon"
//                 style={{ width: 20, height: 20, marginRight: 8 }}
//               />
//             </ListItemIcon>
//           )}
//           <ListItemText
//             primary={
//               brand.name
//               // typeof brand.name === 'object'
//               //   ? brand.name[i18n.language] || brand.name['en']
//               //   : brand.name
//             }
//           />
//         </ListItem>
//       ))}
//     </List>
//   );
//   const renderWeatherEventList = () => {
//     return (
//       <List component="div" disablePadding>
//         {weatherEvents.map((item) => (
//           <ListItem
//             key={item.id}
//             button
//             selected={filters.weather?.id === item.id}
//             onClick={() => updateFilter('weather', item)}
//             sx={{
//               pl: 2,
//               color: theme.palette.text.primary,
//               borderBottom: 'none',
//               '&.Mui-selected': {
//                 backgroundColor: theme.palette.action.selected,
//                 fontWeight: 'bold',
//               },
//               '&:hover': {
//                 backgroundColor: theme.palette.action.hover,
//               },
//             }}
//           >
//             {item.iconUrl && (
//               <ListItemIcon>
//                 <img
//                   src={item.iconUrl}
//                   alt="icon"
//                   style={{ width: 20, height: 20, marginRight: 8 }}
//                 />
//               </ListItemIcon>
//             )}
//             <ListItemText
//               primary={
//                 typeof item.name === 'object'
//                   ? item.name[i18n.language] || item.name['en']
//                   : item.name
//               }
//             />
//           </ListItem>
//         ))}
//       </List>
//     );
//   };
//   useEffect(() => {
//     const fetchAll = async () => {
//       try {
//         const [cats, bnds, events] = await Promise.all([
//           fetchCategories('', i18n.language),
//           fetchBrands('', i18n.language),
//           fetchWeatherEvents(),
//         ]);
//         setCategories(cats);
//         setBrands(bnds);
//         setWeatherEvents(events);

//         // URL 쿼리로부터 초기 필터 복원
//         const query = Object.fromEntries(searchParams.entries());
//         const restored = {
//           keyword: query.keyword || '',
//           category: cats.find((c) => c.id === query.category) || null,
//           brand: bnds.find((b) => b.id === query.brand) || null,
//           weather: events.find((w) => w.id === query.weatherEventId) || null,

//           priceRange: [
//             parseInt(query.minPrice) || 0,
//             parseInt(query.maxPrice) || 100000,
//           ],
//           radius: parseInt(query.radius) || 3,
//         };

//         if (query.lat && query.lng) {
//           setUserLocation({
//             lat: parseFloat(query.lat),
//             lng: parseFloat(query.lng),
//           });
//         }

//         setFilters(restored);
//         Object.entries(restored).forEach(([key, value]) => {
//           if (value) onSelectFilter(key, value);
//         });
//       } catch (err) {
//         console.error('Left menu fetch error:', err);
//       }
//     };
//     fetchAll();
//   }, [i18n.language, searchParams.toString()]);

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setUserLocation({
//             lat: position.coords.latitude,
//             lng: position.coords.longitude,
//           });
//         },
//         (error) => {
//           console.error('Geolocation error:', error);
//         }
//       );
//     }
//   }, []);

//   return (
//     <Box
//       sx={{
//         width: {
//           xs: '100%', // 모바일
//           sm: 280,
//           md: 300,
//           lg: 320,
//           xl: 360,
//         },
//         maxWidth: 400,
//         bgcolor: theme.palette.background.default,
//         color: theme.palette.text.primary,
//         borderRight: `1px solid ${theme.palette.divider}`,
//         height: '100%', // ✅ 이걸로 고정
//         overflowY: 'auto',
//         // overflowX: 'hidden', // ✅ 가로 스크롤 방지
//         // boxSizing: 'border-box', // ✅ border 포함한 높이 계산
//       }}
//     >
//       <Typography
//         variant="h6"
//         sx={{
//           p: 0,
//           fontWeight: 'bold',
//           color: theme.palette.text.primary,
//           borderBottom: `1px solid ${theme.palette.divider}`,
//         }}
//       >
//         {t('filter_section_title')}
//       </Typography>

//       {/* 필터 요약 + 초기화 */}
//       <Box
//         sx={{
//           display: 'flex',
//           flexWrap: 'wrap',
//           p: 1,
//           gap: 1,
//         }}
//       >
//         {Object.entries(filters).map(([key, value]) => {
//           if (!value || (Array.isArray(value) && value.length === 0))
//             return null;

//           let label = '';
//           let resolvedKey = key;

//           // 이름 가져오기 (카테고리, 브랜드, 날씨 이벤트)
//           if (key === 'keyword') {
//             label = value;
//           } else if (key === 'category') {
//             const found = categories.find(
//               (c) => c.id === value || c.id === value?.id
//             );
//             label = found?.name || value;
//           } else if (key === 'brand') {
//             const found = brands.find(
//               (b) => b.id === value || b.id === value?.id
//             );
//             label = found?.name || value;
//           } else if (key === 'weather') {
//             const found = weatherEvents.find(
//               (w) => w.id === value || w.id === value?.id
//             );
//             label =
//               found?.name?.[i18n.language] || found?.name?.['en'] || value;
//           } else if (key === 'priceRange') {
//             label = `${value[0]} ~ ${value[1]}`;
//           } else if (key === 'radius') {
//             label = `${value} km`;
//           } else if (key === 'state' && Array.isArray(value)) {
//             const stateLabelMap = {
//               selling: t('selling'),
//               reserved: t('reserved'),
//               'sold-out': t('sold-out'),
//             };
//             label = value.map((v) => stateLabelMap[v] || v).join(', ');
//           } else {
//             label = t(value.toString());
//           }

//           const labelKeyMap = {
//             keyword: t('search_keyword'),
//             category: t('category'),
//             brand: t('brand'),
//             weather: t('weatherEvent'),
//             priceRange: t('price_range'),
//             radius: t('search_radius'),
//             state: t('productStatus'),
//           };

//           const labelKey = labelKeyMap[resolvedKey] || t(resolvedKey);

//           return (
//             <Chip
//               key={key}
//               label={`${labelKey}: ${label}`}
//               onDelete={() => removeFilter(key)}
//               sx={{ bgcolor: theme.palette.action.selected }}
//             />
//           );
//         })}

//         <Button
//           variant="outlined"
//           size="small"
//           onClick={resetFilters}
//           sx={{ ml: 'auto', height: 32 }}
//         >
//           {t('reset_filters')}
//         </Button>
//       </Box>

//       <ProductStatusFilter
//         selectedStates={filters.state || []}
//         onChange={(newStates) => updateFilter('state', newStates)}
//       />
//       <ListItem>
//         <Typography variant="body2">{t('price_range')}</Typography>
//         <Slider
//           value={filters.priceRange}
//           onChange={(e, newValue) => updateFilter('priceRange', newValue)}
//           valueLabelDisplay="auto"
//           min={0}
//           max={100000}
//           step={1000}
//           sx={{ mx: 2 }}
//         />
//       </ListItem>

//       <ListItem>
//         <Typography variant="body2" sx={{ mr: 2 }}>
//           {t('search_radius')}
//         </Typography>
//         <Slider
//           value={filters.radius}
//           onChange={(e, newValue) => updateFilter('radius', newValue)}
//           onChangeCommitted={() =>
//             onSelectFilter('radius', {
//               radius: filters.radius,
//               location: userLocation,
//             })
//           }
//           min={1}
//           max={10}
//           step={1}
//           marks
//           valueLabelDisplay="auto"
//           sx={{ width: '70%' }}
//         />
//       </ListItem>
//       <ListItem button onClick={() => handleToggle('weather')}>
//         <ListItemText primary={t('weather.weatherEventList')} />
//         {openMenus.includes('weather') ? <ExpandLess /> : <ExpandMore />}
//       </ListItem>
//       {openMenus.includes('weather') && renderWeatherEventList()}

//       <List component="nav">
//         <ListItem button onClick={() => handleToggle('category')}>
//           <ListItemText primary={t('category')} />
//           {openMenus.includes('category') ? <ExpandLess /> : <ExpandMore />}
//         </ListItem>
//         {openMenus.includes('category') && renderCategoryTree(categories, 0)}

//         <ListItem button onClick={() => handleToggle('brand')}>
//           <ListItemText primary={t('brand')} />
//           {openMenus.includes('brand') ? <ExpandLess /> : <ExpandMore />}
//         </ListItem>
//         {openMenus.includes('brand') && renderBrandList()}
//       </List>
//     </Box>
//   );
// };

// export default LeftSideMenu;

// ✅ 리팩토링된 LeftSideMenu.js
// import React, { useEffect, useState } from 'react';
// import {
//   Box,
//   List,
//   ListItem,
//   ListItemText,
//   Collapse,
//   Typography,
//   useTheme,
//   ListItemIcon,
//   Slider,
//   Chip,
//   Button,
// } from '@mui/material';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import { fetchBrands, fetchCategories } from './api/SearchAPI';
// import { fetchWeatherEvents } from './api/WeatherAPI';
// import { useTranslation } from 'react-i18next';
// import useSearchFilters from './useSearchFilters';
// import ProductStatusFilter from './ProductStatusFilter';

// const LeftSideMenu = () => {
//   const theme = useTheme();
//   const { t, i18n } = useTranslation();
//   const { searchParams, handleSelectFilter, getFilterParams, setSearchParams } =
//     useSearchFilters();

//   const [categories, setCategories] = useState([]);
//   const [subCategoriesMap, setSubCategoriesMap] = useState({});
//   const [weatherEvents, setWeatherEvents] = useState([]);
//   const [brands, setBrands] = useState([]);
//   const [openMenus, setOpenMenus] = useState(['category', 'brand', 'weather']);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [selectedCategoryPath, setSelectedCategoryPath] = useState([]);
//   const [userLocation, setUserLocation] = useState(null);

//   const filters = getFilterParams();

//   const removeFilter = (key) => {
//     const defaultValues = {
//       keyword: '',
//       category: null,
//       brand: null,
//       weather: null,
//       priceRange: [0, 100000],
//       radius: 3,
//       state: ['selling'],
//     };
//     handleSelectFilter(key, defaultValues[key]);
//   };

//   const updateFilter = (key, value) => {
//     handleSelectFilter(key, value); // 쿼리 반영도 자동
//   };

//   const resetFilters = () => {
//     setSearchParams({});
//   };

//   const handleToggle = (menuKey) => {
//     setOpenMenus((prev) =>
//       prev.includes(menuKey)
//         ? prev.filter((key) => key !== menuKey)
//         : [...prev, menuKey]
//     );
//   };

//   const handleItemClick = async (key, item, level = 0) => {
//     setSelectedItem(item.id);
//     const updatedPath = selectedCategoryPath.slice(0, level + 1);
//     updatedPath[level] = item;
//     setSelectedCategoryPath(updatedPath);
//     handleSelectFilter(key, item);

//     if (key === 'category') {
//       const subcats = await fetchCategories(item.id, i18n.language);
//       if (subcats.length > 0) {
//         setSubCategoriesMap((prev) => ({ ...prev, [item.id]: subcats }));
//       }
//     }
//   };

//   const renderCategoryTree = (items, level = 0) => (
//     <List component="div" disablePadding>
//       {items.map((item) => {
//         const subCategories = subCategoriesMap[item.id] || [];
//         const isExpanded = selectedCategoryPath[level]?.id === item.id;

//         return (
//           <React.Fragment key={item.id}>
//             <ListItem
//               button
//               selected={
//                 filters.category === item.id || filters.category === item?.id
//               }
//               onClick={() => handleItemClick('category', item, level)}
//               sx={{
//                 pl: 2 + level * 2,
//                 color: theme.palette.text.primary,
//                 '&.Mui-selected': {
//                   backgroundColor: theme.palette.action.selected,
//                   fontWeight: 'bold',
//                 },
//                 '&:hover': {
//                   backgroundColor: theme.palette.action.hover,
//                 },
//               }}
//             >
//               {item.iconUrl && (
//                 <ListItemIcon>
//                   <img
//                     src={item.iconUrl}
//                     alt="icon"
//                     style={{ width: 20, height: 20 }}
//                   />
//                 </ListItemIcon>
//               )}
//               <ListItemText primary={item.name} />
//               {subCategories.length > 0 &&
//                 (isExpanded ? <ExpandLess /> : <ExpandMore />)}
//             </ListItem>
//             <Collapse in={isExpanded} timeout="auto" unmountOnExit>
//               {renderCategoryTree(subCategories, level + 1)}
//             </Collapse>
//           </React.Fragment>
//         );
//       })}
//     </List>
//   );
//   const renderBrandList = () => (
//     <List component="div" disablePadding>
//       {brands.map((brand) => (
//         <ListItem
//           key={brand.id}
//           button
//           selected={filters.brand?.id === brand.id}
//           onClick={() => updateFilter('brand', brand)}
//           sx={{
//             pl: 2,
//             color: theme.palette.text.primary,
//             '&.Mui-selected': {
//               backgroundColor: theme.palette.action.selected,
//               fontWeight: 'bold',
//             },
//             '&:hover': {
//               backgroundColor: theme.palette.action.hover,
//             },
//           }}
//         >
//           {brand.iconUrl && (
//             <ListItemIcon>
//               <img
//                 src={brand.iconUrl}
//                 alt="brand-icon"
//                 style={{ width: 20, height: 20, marginRight: 8 }}
//               />
//             </ListItemIcon>
//           )}
//           <ListItemText
//             primary={
//               brand.name
//               // typeof brand.name === 'object'
//               //   ? brand.name[i18n.language] || brand.name['en']
//               //   : brand.name
//             }
//           />
//         </ListItem>
//       ))}
//     </List>
//   );
//   const renderWeatherEventList = () => {
//     return (
//       <List component="div" disablePadding>
//         {weatherEvents.map((item) => (
//           <ListItem
//             key={item.id}
//             button
//             selected={filters.weather?.id === item.id}
//             onClick={() => updateFilter('weather', item)}
//             sx={{
//               pl: 2,
//               color: theme.palette.text.primary,
//               borderBottom: 'none',
//               '&.Mui-selected': {
//                 backgroundColor: theme.palette.action.selected,
//                 fontWeight: 'bold',
//               },
//               '&:hover': {
//                 backgroundColor: theme.palette.action.hover,
//               },
//             }}
//           >
//             {item.iconUrl && (
//               <ListItemIcon>
//                 <img
//                   src={item.iconUrl}
//                   alt="icon"
//                   style={{ width: 20, height: 20, marginRight: 8 }}
//                 />
//               </ListItemIcon>
//             )}
//             <ListItemText
//               primary={
//                 typeof item.name === 'object'
//                   ? item.name[i18n.language] || item.name['en']
//                   : item.name
//               }
//             />
//           </ListItem>
//         ))}
//       </List>
//     );
//   };
//   useEffect(() => {
//     const fetchAll = async () => {
//       try {
//         const [cats, bnds, events] = await Promise.all([
//           fetchCategories('', i18n.language),
//           fetchBrands('', i18n.language),
//           fetchWeatherEvents(),
//         ]);
//         setCategories(cats);
//         setBrands(bnds);
//         setWeatherEvents(events);
//       } catch (err) {
//         console.error('LeftSideMenu data fetch error:', err);
//       }
//     };
//     fetchAll();
//   }, [i18n.language]);

//   return (
//     <Box
//       sx={{
//         width: 300,
//         borderRight: `1px solid ${theme.palette.divider}`,
//         height: '100%',
//       }}
//     >
//       <Typography variant="h6" sx={{ p: 1 }}>
//         {t('filter_section_title')}
//       </Typography>

//       <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, p: 1 }}>
//         {Object.entries(filters).map(([key, value]) => {
//           if (
//             !value ||
//             (Array.isArray(value) && value.length === 0) ||
//             (typeof value === 'string' && value.trim() === '')
//           )
//             return null;

//           let label = '';
//           let resolvedKey = key;

//           // 이름 가져오기 (카테고리, 브랜드, 날씨 이벤트)
//           if (key === 'keyword') {
//             label = value;
//           } else if (key === 'category') {
//             const found = categories.find(
//               (c) => c.id === value || c.id === value?.id
//             );
//             label = found?.name || value;
//           } else if (key === 'brand') {
//             const found = brands.find(
//               (b) => b.id === value || b.id === value?.id
//             );
//             label = found?.name || value;
//           } else if (key === 'weather') {
//             const found = weatherEvents.find(
//               (w) => w.id === value || w.id === value?.id
//             );
//             label =
//               found?.name?.[i18n.language] || found?.name?.['en'] || value;
//           } else if (key === 'priceRange') {
//             label = `${value[0]} ~ ${value[1]}`;
//           } else if (key === 'radius') {
//             label = `${value} km`;
//           } else if (key === 'state' && Array.isArray(value)) {
//             const stateLabelMap = {
//               selling: t('selling'),
//               reserved: t('reserved'),
//               'sold-out': t('sold-out'),
//             };
//             label = value.map((v) => stateLabelMap[v] || v).join(', ');
//           } else {
//             label = t(value.toString());
//           }

//           const labelKeyMap = {
//             keyword: t('search_keyword'),
//             category: t('category'),
//             brand: t('brand'),
//             weather: t('weatherEvent'),
//             priceRange: t('price_range'),
//             radius: t('search_radius'),
//             state: t('productStatus'),
//           };

//           const labelKey = labelKeyMap[resolvedKey] || t(resolvedKey);

//           return (
//             <Chip
//               key={key}
//               label={`${labelKey}: ${label}`}
//               onDelete={() => removeFilter(key)}
//               sx={{ bgcolor: theme.palette.action.selected }}
//             />
//           );

//           // return (
//           //   <Chip
//           //     key={key}
//           //     label={`${key}: ${
//           //       Array.isArray(value) ? value.join(',') : value
//           //     }`}
//           //     onDelete={() => removeFilter(key)}
//           //   />
//           // );
//         })}
//         <Button onClick={resetFilters} size="small" sx={{ ml: 'auto' }}>
//           {t('reset_filters')}
//         </Button>
//       </Box>

//       {/* <ProductStatusFilter
//         selectedStates={filters.state || []}
//         onChange={(newStates) => handleSelectFilter('state', newStates)}
//       /> */}
//       <ProductStatusFilter
//         selectedStates={filters.state || []}
//         onChange={(newStates) => {
//           // ✅ 의미 차이 처리
//           if (newStates.length === 0) {
//             handleSelectFilter('state', []); // 아무것도 선택 안 한 의미 (제외 처리용)
//           } else if (newStates.length === 3) {
//             handleSelectFilter('state', ['selling', 'reserved', 'sold-out']); // 전체 선택 시 전체 포함 의미
//           } else {
//             handleSelectFilter('state', newStates);
//           }
//         }}
//       />
//       <ListItem>
//         <Typography>{t('price_range')}</Typography>
//         <Slider
//           value={[
//             parseInt(filters.minPrice) || 0,
//             parseInt(filters.maxPrice) || 100000,
//           ]}
//           onChange={(e, val) => handleSelectFilter('priceRange', val)}
//           valueLabelDisplay="auto"
//           min={0}
//           max={100000}
//         />
//       </ListItem>

//       <ListItem>
//         <Typography>{t('search_radius')}</Typography>
//         <Slider
//           value={parseInt(filters.radius) || 3}
//           onChange={(e, val) => handleSelectFilter('radius', val)}
//           valueLabelDisplay="auto"
//           min={1}
//           max={10}
//         />
//       </ListItem>

//       <ListItem button onClick={() => handleToggle('weather')}>
//         <ListItemText primary={t('weather.weatherEventList')} />
//         {openMenus.includes('weather') ? <ExpandLess /> : <ExpandMore />}
//       </ListItem>
//       {openMenus.includes('weather') && renderWeatherEventList()}

//       <ListItem button onClick={() => handleToggle('category')}>
//         <ListItemText primary={t('category')} />
//         {openMenus.includes('category') ? <ExpandLess /> : <ExpandMore />}
//       </ListItem>
//       {openMenus.includes('category') && renderCategoryTree(categories)}

//       <ListItem button onClick={() => handleToggle('brand')}>
//         <ListItemText primary={t('brand')} />
//         {openMenus.includes('brand') ? <ExpandLess /> : <ExpandMore />}
//       </ListItem>
//       {openMenus.includes('brand') && renderBrandList()}
//     </Box>
//   );
// };

// export default LeftSideMenu;

import React, { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
  useTheme,
  ListItemIcon,
  Slider,
  Chip,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { fetchBrands, fetchCategories } from './api/SearchAPI';
import { fetchWeatherEvents } from './api/WeatherAPI';
import { useTranslation } from 'react-i18next';
import useSearchFilters from './useSearchFilters';
import ProductStatusFilter from './ProductStatusFilter';

const LeftSideMenu = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { searchParams, handleSelectFilter, getFilterParams, setSearchParams } =
    useSearchFilters();

  const [categories, setCategories] = useState([]);
  const [subCategoriesMap, setSubCategoriesMap] = useState({});
  const [weatherEvents, setWeatherEvents] = useState([]);
  const [brands, setBrands] = useState([]);
  const [openMenus, setOpenMenus] = useState(['category', 'brand', 'weather']);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategoryPath, setSelectedCategoryPath] = useState([]);
  const [userLocation, setUserLocation] = useState(null);

  const filters = getFilterParams();
  const selectedStates = (() => {
    const raw = filters.states;
    if (Array.isArray(raw)) return raw;
    if (typeof raw === 'string') return raw.split(',').filter(Boolean); // ← 핵심!
    return [];
  })();
  const chipVisibleKeys = [
    'keyword',
    'category',
    'brand',
    'weather',
    'priceRange',
    'radius',
    'states',
  ];
  const alwaysVisibleKeys = ['priceRange', 'radius'];
  const removeFilter = (key) => {
    const defaultValues = {
      keyword: '',
      category: null,
      brand: null,
      weather: null,
      priceRange: [0, 100000],
      radius: 3,
      states: ['selling'],
    };
    handleSelectFilter(key, defaultValues[key]);
  };

  const updateFilter = (key, value) => {
    handleSelectFilter(key, value);
  };

  const resetFilters = () => {
    setSearchParams({});
  };

  const handleToggle = (menuKey) => {
    setOpenMenus((prev) =>
      prev.includes(menuKey)
        ? prev.filter((key) => key !== menuKey)
        : [...prev, menuKey]
    );
  };

  const handleItemClick = async (key, item, level = 0) => {
    setSelectedItem(item.id);
    const updatedPath = selectedCategoryPath.slice(0, level + 1);
    updatedPath[level] = item;
    setSelectedCategoryPath(updatedPath);
    handleSelectFilter(key, item);

    if (key === 'category') {
      const subcats = await fetchCategories(item.id, i18n.language);
      if (subcats.length > 0) {
        setSubCategoriesMap((prev) => ({ ...prev, [item.id]: subcats }));
      }
    }
  };

  const renderCategoryTree = (items, level = 0) => (
    <List component="div" disablePadding>
      {items.map((item) => {
        const subCategories = subCategoriesMap[item.id] || [];
        const isExpanded = selectedCategoryPath[level]?.id === item.id;

        return (
          <React.Fragment key={item.id}>
            <ListItem
              button
              selected={
                filters.category === item.id || filters.category === item?.id
              }
              onClick={() => handleItemClick('category', item, level)}
              sx={{
                pl: 2 + level * 2,
                color: theme.palette.text.primary,
                '&.Mui-selected': {
                  backgroundColor: theme.palette.action.selected,
                  fontWeight: 'bold',
                },
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              {item.iconUrl && (
                <ListItemIcon>
                  <img
                    src={item.iconUrl}
                    alt="icon"
                    style={{ width: 20, height: 20 }}
                  />
                </ListItemIcon>
              )}
              <ListItemText primary={item.name} />
              {subCategories.length > 0 &&
                (isExpanded ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              {renderCategoryTree(subCategories, level + 1)}
            </Collapse>
          </React.Fragment>
        );
      })}
    </List>
  );

  const renderBrandList = () => (
    <List component="div" disablePadding>
      {brands.map((brand) => (
        <ListItem
          key={brand.id}
          button
          selected={filters.brand?.id === brand.id}
          onClick={() => updateFilter('brand', brand)}
          sx={{
            pl: 2,
            color: theme.palette.text.primary,
            '&.Mui-selected': {
              backgroundColor: theme.palette.action.selected,
              fontWeight: 'bold',
            },
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          {brand.iconUrl && (
            <ListItemIcon>
              <img
                src={brand.iconUrl}
                alt="brand-icon"
                style={{ width: 20, height: 20, marginRight: 8 }}
              />
            </ListItemIcon>
          )}
          <ListItemText primary={brand.name} />
        </ListItem>
      ))}
    </List>
  );

  const renderWeatherEventList = () => (
    <List component="div" disablePadding>
      {weatherEvents.map((item) => (
        <ListItem
          key={item.id}
          button
          selected={filters.weather?.id === item.id}
          onClick={() => updateFilter('weather', item)}
          sx={{
            pl: 2,
            color: theme.palette.text.primary,
            borderBottom: 'none',
            '&.Mui-selected': {
              backgroundColor: theme.palette.action.selected,
              fontWeight: 'bold',
            },
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          {item.iconUrl && (
            <ListItemIcon>
              <img
                src={item.iconUrl}
                alt="icon"
                style={{ width: 20, height: 20, marginRight: 8 }}
              />
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              typeof item.name === 'object'
                ? item.name[i18n.language] || item.name['en']
                : item.name
            }
          />
        </ListItem>
      ))}
    </List>
  );

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const [cats, bnds, events] = await Promise.all([
          fetchCategories('', i18n.language),
          fetchBrands('', i18n.language),
          fetchWeatherEvents(),
        ]);
        setCategories(cats);
        setBrands(bnds);
        setWeatherEvents(events);
      } catch (err) {
        console.error('LeftSideMenu data fetch error:', err);
      }
    };
    fetchAll();
  }, [i18n.language]);

  return (
    <Box
      sx={{
        width: 300,
        borderRight: `1px solid ${theme.palette.divider}`,
        height: '100%',
        bgcolor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 'bold', p: 1, mb: 2 }}>
        {t('filter_section_title')}
      </Typography>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, p: 1 }}>
        {Object.entries(filters).map(([key, value]) => {
          // ❌ 사용자에게 보여주지 않아도 되는 필터
          if (!chipVisibleKeys.includes(key)) return null;

          // ❌ 기본 조건: 값이 없거나 비어 있으면 제외
          if (
            !alwaysVisibleKeys.includes(key) &&
            (value === null ||
              value === undefined ||
              (typeof value === 'string' && value.trim() === '') ||
              (Array.isArray(value) && value.length === 0))
          ) {
            return null;
          }

          // let label = '';
          // let resolvedKey = key;

          // if (key === 'keyword') {
          //   label = value;
          // } else if (key === 'category' || key === 'categoryId') {
          //   const found = categories.find(
          //     (c) => c.id === value || c.id === value?.id
          //   );
          //   label = found?.name || value;
          //   resolvedKey = 'categoryId';
          // } else if (key === 'brand' || key === 'brandId') {
          //   const found = brands.find(
          //     (b) => b.id === value || b.id === value?.id
          //   );
          //   label = found?.name || value;
          //   resolvedKey = 'brandId';
          // } else if (key === 'weather' || key === 'weatherId') {
          //   const found = weatherEvents.find(
          //     (w) => w.id === value || w.id === value?.id
          //   );
          //   label =
          //     typeof found?.name === 'object'
          //       ? found.name[i18n.language] || found.name['en']
          //       : found?.name || value;
          //   resolvedKey = 'weatherId';
          // } else if (key === 'priceRange') {
          //   label = `${value[0]} ~ ${value[1]}`;
          // } else if (key === 'radius') {
          //   label = `${value} km`;
          //   resolvedKey = 'radius'; // ✅ 명시적 지정
          // } else if (key === 'state' && Array.isArray(value)) {
          //   const stateLabelMap = {
          //     selling: t('selling'),
          //     reserved: t('reserved'),
          //     'sold-out': t('sold-out'),
          //   };
          //   label = value.map((v) => stateLabelMap[v] || v).join(', ');
          // } else {
          //   label = t(value.toString());
          // }

          // const labelKeyMap = {
          //   keyword: t('search_keyword'),
          //   categoryId: t('category'),
          //   brandId: t('brand'),
          //   weatherId: t('weatherEvent'),
          //   priceRange: t('price_range'),
          //   radius: t('search_radius'),
          //   state: t('productStatus'),
          // };

          // const labelKey = labelKeyMap[resolvedKey] || t(resolvedKey);

          let label = '';
          if (key === 'keyword') label = value;
          else if (key === 'category') {
            const found = categories.find(
              (c) => c.id === value || c.id === value?.id
            );
            label = found?.name || value;
          } else if (key === 'brand') {
            const found = brands.find(
              (b) => b.id === value || b.id === value?.id
            );
            label = found?.name || value;
          } else if (key === 'weather') {
            const found = weatherEvents.find(
              (w) => w.id === value || w.id === value?.id
            );
            label =
              typeof found?.name === 'object'
                ? found.name[i18n.language] || found.name['en']
                : found?.name || value;
          } else if (key === 'priceRange') label = `${value[0]} ~ ${value[1]}`;
          else if (key === 'radius') label = `${value} km`;
          else if (key === 'states') {
            const stateLabelMap = {
              selling: t('selling'),
              reserved: t('reserved'),
              'sold-out': t('sold-out'),
            };
            label = value.map((v) => stateLabelMap[v] || v).join(', ');
          } else label = t(value?.['en']);

          const labelKeyMap = {
            keyword: t('search_keyword'),
            category: t('category'),
            brand: t('brand'),
            weather: t('weatherEvent'),
            priceRange: t('price_range'),
            radius: t('search_radius'),
            states: t('productStatus'),
          };

          const labelKey = labelKeyMap[key] || t(key);

          return (
            <Chip
              key={key}
              label={`${labelKey}: ${label}`}
              onDelete={() => removeFilter(key)}
              sx={{ bgcolor: theme.palette.action.selected }}
              // size="small"
              variant="outlined"
            />
          );
        })}
        <Button onClick={resetFilters} size="small" sx={{ ml: 'auto' }}>
          {t('reset_filters')}
        </Button>
      </Box>

      <Typography variant="subtitle2" sx={{ px: 2, pt: 1, mb: 1 }}>
        {t('productStatus')}
      </Typography>
      <ProductStatusFilter
        selectedStates={selectedStates}
        onChange={(newStates) => {
          if (newStates.length === 0) {
            handleSelectFilter('states', []);
          } else if (newStates.length === 3) {
            handleSelectFilter('states', ['selling', 'reserved', 'sold-out']);
          } else {
            handleSelectFilter('states', newStates);
          }
        }}
      />

      <ListItem>
        <Typography>{t('price_range')}</Typography>

        <Slider
          value={filters.priceRange}
          onChange={(e, val) => handleSelectFilter('priceRange', val)}
          valueLabelDisplay="auto"
          sx={{
            mx: 2,
            color: theme.palette.primary.main,
            '& .MuiSlider-thumb': {
              borderRadius: 1,
            },
            mb: 1,
          }}
          min={0}
          max={100000}
        />
      </ListItem>

      <ListItem>
        <Typography>{t('search_radius')}</Typography>
        <Slider
          value={Number(filters.radius) || 3}
          onChange={(e, val) => handleSelectFilter('radius', val)}
          valueLabelDisplay="auto"
          sx={{
            mx: 2,
            color: theme.palette.primary.main,
            '& .MuiSlider-thumb': {
              borderRadius: 1,
            },
            mb: 1,
          }}
          min={1}
          max={10}
        />
      </ListItem>

      <ListItem button onClick={() => handleToggle('weather')}>
        <ListItemText primary={t('weather.weatherEventList')} />
        {openMenus.includes('weather') ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {openMenus.includes('weather') && renderWeatherEventList()}

      <ListItem button onClick={() => handleToggle('category')}>
        <ListItemText primary={t('category')} />
        {openMenus.includes('category') ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {openMenus.includes('category') && renderCategoryTree(categories)}

      <ListItem button onClick={() => handleToggle('brand')}>
        <ListItemText primary={t('brand')} />
        {openMenus.includes('brand') ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {openMenus.includes('brand') && renderBrandList()}
    </Box>
  );
};

export default LeftSideMenu;
