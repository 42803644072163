// const RealTimeSearchList = ({ items, activeTab, onSelectRealTimeSearch }) => {
//   const startIndex = activeTab === 1 ? 0 : 10;

//   return (
//     <div
//       style={{
//         padding: '10px',
//         backgroundColor: '#f5f5f5',
//         borderRadius: '5px',
//       }}
//     >
//       {items.slice(startIndex, startIndex + 10).map((item, index) => (
//         <div
//           key={index}
//           style={{
//             padding: '5px 0',
//             borderBottom: '1px solid #ddd',
//             display: 'flex',
//             justifyContent: 'space-between',
//             cursor: 'pointer',
//           }}
//           onClick={() => onSelectRealTimeSearch(item.keyword)}
//         >
//           <span>
//             {startIndex + index + 1}. {item.keyword}
//           </span>
//           <span>{item.status}</span>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default RealTimeSearchList;

import React from 'react';
import { Box, Paper, Typography, useTheme, useMediaQuery } from '@mui/material';

const RealTimeSearchList = ({ items, activeTab, onSelectRealTimeSearch }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const startIndex = activeTab === 1 ? 0 : 10;

  return (
    <Paper
      elevation={2}
      sx={{
        padding: 2,
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {items.slice(startIndex, startIndex + 10).map((item, index) => (
        <Box
          key={index}
          sx={{
            paddingY: 1,
            borderBottom: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'background-color 0.2s',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
          onClick={() => onSelectRealTimeSearch(item.keyword)}
        >
          <Typography
            variant={isMobile ? 'body2' : 'body1'}
            sx={{ fontWeight: 'bold' }}
          >
            {startIndex + index + 1}. {item.keyword}
          </Typography>
          <Typography
            variant={isMobile ? 'body2' : 'body1'}
            sx={{ color: theme.palette.text.secondary }}
          >
            {item.status}
          </Typography>
        </Box>
      ))}
    </Paper>
  );
};

export default RealTimeSearchList;
