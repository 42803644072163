import { Paper, Typography, Button, Box } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const LoginRequired = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        minHeight: 250,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 4,
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
      }}
    >
      {/* 🔒 로그인 아이콘 */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 80,
          height: 80,
          backgroundColor: theme.palette.grey[200],
          borderRadius: '50%',
          mb: 2,
        }}
      >
        <LockOutlinedIcon
          sx={{ fontSize: 40, color: theme.palette.grey[700] }}
        />
      </Box>

      {/* 📝 로그인 필요 메시지 */}
      <Typography
        variant="h6"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        {t('loginRequired')}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: theme.palette.text.secondary, mb: 2 }}
      >
        {t('pleaseLoginToContinue')}
      </Typography>

      {/* 🔹 로그인 버튼 */}
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2, width: '80%' }}
        onClick={() => navigate('/login')}
      >
        {t('login')}
      </Button>
    </Paper>
  );
};

export default LoginRequired;
