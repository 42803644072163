// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Box,
//   Grid,
//   Typography,
//   CircularProgress,
//   AppBar,
//   Toolbar,
//   IconButton,
//   Paper,
//   Button,
// } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { useTranslation } from 'react-i18next';
// import Product from './Product';
// import useUserActivity from './useUserActivity';
// import useStoreUserActivity from './Stores/useStoreUserActivity';
// import {
//   exportFetchSelectedTownByUserId,
//   exportFetchUserProductsByCoordinates,
//   exportFetchStoreProductsByCoordinates,
//   exportFetchUserProductsByLastDocAsMap,
//   exportFetchStoresByLastDocAsMap,
// } from './Utils/LocationStoresAndProducts';
// import StoreProduct from './Stores/StoreProduct';
// import Lottie from 'lottie-react';
// import noUserProductResults from './assets/lottie/no-userproduct-results.json';
// import noStoreProductResults from './assets/lottie/no-storeproduct-results.json';

// const PAGE_SIZE = 12;

// const ProductBrandSearchResults = () => {
//   const { t } = useTranslation();
//   const [products, setProducts] = useState([]);
//   const [storeProducts, setStoreProducts] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const searchBrand = useSelector((state) => state.search.searchBrand);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [userLastDoc, setUserLastDoc] = useState(null);
//   const [storeLastDoc, setStoreLastDoc] = useState(null);
//   const [userTotalProducts, setUserTotalProducts] = useState(0);
//   const [storeTotalProducts, setStoreTotalProducts] = useState(0);
//   const navigate = useNavigate();

//   const handleNavigateHome = () => {
//     navigate('/');
//   };

//   const { logViewedProduct } = useUserActivity(currentUser?.uid);
//   const { logViewedStoreProduct } = useStoreUserActivity(currentUser?.uid);

//   useEffect(() => {
//     if (searchBrand) {
//       handleSearchBrand(searchBrand, null, null, true);
//     }
//   }, [searchBrand]);

//   async function handleSearchBrand(
//     brand,
//     userLastDoc,
//     storeLastDoc,
//     reset = false
//   ) {
//     setLoading(true);
//     setError(null);

//     try {
//       let location = null;
//       if (currentUser) {
//         location = await exportFetchSelectedTownByUserId(currentUser?.uid);
//       }

//       if (!currentUser || !location) {
//         const allUserProducts = await searchAllUserProducts(brand, userLastDoc);
//         const allStoreProducts = await searchAllStoreProducts(
//           brand,
//           storeLastDoc
//         );

//         if (reset) {
//           setProducts(allUserProducts.products);
//           setStoreProducts(allStoreProducts.products);
//         } else {
//           setProducts((prevProducts) => [
//             ...prevProducts,
//             ...allUserProducts.products,
//           ]);
//           setStoreProducts((prevStoreProducts) => [
//             ...prevStoreProducts,
//             ...allStoreProducts.products,
//           ]);
//         }

//         setUserTotalProducts(allUserProducts.totalCount);
//         setStoreTotalProducts(allStoreProducts.totalCount);
//         setUserLastDoc(allUserProducts.lastDoc);
//         setStoreLastDoc(allStoreProducts.lastDoc);
//       } else {
//         const productResults = await searchUserProducts(
//           brand,
//           location,
//           userLastDoc
//         );
//         const storeProductResults = await searchStoreProducts(
//           brand,
//           location,
//           storeLastDoc
//         );

//         if (reset) {
//           setProducts(productResults.products);
//           setStoreProducts(storeProductResults.products);
//         } else {
//           setProducts((prevProducts) => [
//             ...prevProducts,
//             ...productResults.products,
//           ]);
//           setStoreProducts((prevStoreProducts) => [
//             ...prevStoreProducts,
//             ...storeProductResults.products,
//           ]);
//         }

//         setUserTotalProducts(productResults.totalCount);
//         setStoreTotalProducts(storeProductResults.totalCount);
//         setUserLastDoc(productResults.lastDoc);
//         setStoreLastDoc(storeProductResults.lastDoc);
//       }
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   }

//   async function searchUserProducts(brand, location, lastDoc) {
//     const userProductsArray = await exportFetchUserProductsByCoordinates(
//       location.lat,
//       location.lng,
//       location.radius,
//       currentUser.uid,
//       PAGE_SIZE,
//       lastDoc
//     );

//     const filteredProducts = userProductsArray.filter(
//       (product) => product.brandId === brand.id
//     );

//     return {
//       products: filteredProducts,
//       lastDoc: products.lastDoc,
//       total: filteredProducts.length,
//     };
//   }

//   async function searchStoreProducts(brand, location, lastDoc) {
//     try {
//       const storeProducts = await exportFetchStoreProductsByCoordinates(
//         location.lat,
//         location.lng,
//         location.radius,
//         lastDoc
//       );

//       // Ensure storeProducts.products is always an array
//       const productsArray = Object.values(storeProducts.products || {});

//       const filteredStoreProducts = productsArray.filter(
//         (product) => product.brandId === brand.id
//       );

//       return {
//         products: filteredStoreProducts,
//         lastDoc: storeProducts.lastDoc,
//         total: filteredStoreProducts.length,
//       };
//     } catch (error) {
//       console.error('Error searching store products:', error);
//       return {
//         products: [],
//         lastDoc: null,
//         total: 0,
//       };
//     }
//   }

//   async function searchAllUserProducts(brand, lastDoc) {
//     const result = await exportFetchUserProductsByLastDocAsMap(
//       PAGE_SIZE,
//       lastDoc
//     );
//     const products = result.products || [];

//     const filteredProducts = products.filter(
//       (product) => product.brandId === brand.id
//     );

//     return {
//       products: filteredProducts,
//       lastDoc: result.lastDoc,
//       total: filteredProducts.length,
//     };
//   }

//   async function searchAllStoreProducts(brand, lastDoc) {
//     try {
//       const result = await exportFetchStoresByLastDocAsMap(PAGE_SIZE, lastDoc);

//       // Ensure result.products is always an array
//       const productsArray = result.products || [];

//       const filteredProducts = productsArray.filter(
//         (product) => product.brandId === brand.id
//       );

//       return {
//         products: filteredProducts,
//         lastDoc: result.lastDoc,
//         total: filteredProducts.length,
//       };
//     } catch (error) {
//       console.error('Error searching all store products:', error);
//       return {
//         products: [],
//         lastDoc: null,
//         total: 0,
//       };
//     }
//   }

//   const handleProductClick = (productId) => {
//     logViewedProduct(productId);
//   };

//   const handleStoreProductClick = (storeId, productId) => {
//     logViewedStoreProduct(storeId, productId);
//   };

//   const handleUserPageChange = async () => {
//     await handleSearchBrand(searchBrand, userLastDoc, storeLastDoc);
//   };

//   const handleStorePageChange = async () => {
//     await handleSearchBrand(searchBrand, userLastDoc, storeLastDoc);
//   };

//   return (
//     <>
//       <AppBar position="static" color="default" elevation={1}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label={t('navigation.home')}
//             onClick={handleNavigateHome}
//           >
//             <ArrowBackIcon />
//           </IconButton>
//         </Toolbar>
//       </AppBar>
//       <Box sx={{ width: '100%', maxWidth: '100%', mx: 'auto', px: 2 }}>
//         {loading && !products.length && !storeProducts.length ? (
//           <Box
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             minHeight="50vh"
//           >
//             <CircularProgress />
//           </Box>
//         ) : error ? (
//           <Typography color="error">{`${t(
//             'error.errorMessage'
//           )} ${error}`}</Typography>
//         ) : (
//           <>
//             <Typography variant="h4">
//               {t('brandSearchResults.title')}
//             </Typography>
//             <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
//               {t('brandSearchResults.userProducts')}
//             </Typography>
//             <Grid container spacing={2}>
//               {products.length > 0 ? (
//                 products.map((product) => (
//                   <Grid
//                     item
//                     xs={12}
//                     sm={6}
//                     md={4}
//                     lg={3}
//                     xl={2}
//                     key={product.id}
//                   >
//                     <Product
//                       key={product.id}
//                       product={product}
//                       onClick={() => handleProductClick(product.id)}
//                     />
//                   </Grid>
//                 ))
//               ) : (
//                 <Grid item xs={12}>
//                   <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
//                     <Lottie
//                       animationData={noUserProductResults}
//                       style={{ height: 300 }}
//                     />
//                     <Typography>
//                       {t('brandSearchResults.noUserResults')}
//                     </Typography>
//                   </Paper>
//                 </Grid>
//               )}
//             </Grid>
//             {userLastDoc && (
//               <Button onClick={handleUserPageChange}>{t('loadMore')}</Button>
//             )}
//             <Typography variant="h6" sx={{ mt: 4, mb: 4 }}>
//               {t('brandSearchResults.storeProducts')}
//             </Typography>
//             <Grid container spacing={2}>
//               {storeProducts.length > 0 ? (
//                 storeProducts.map((product) => (
//                   <Grid
//                     item
//                     xs={12}
//                     sm={6}
//                     md={4}
//                     lg={3}
//                     xl={2}
//                     key={product.id}
//                   >
//                     <StoreProduct
//                       key={product.id}
//                       product={product}
//                       onClick={() =>
//                         handleStoreProductClick(currentUser?.uid, product.id)
//                       }
//                     />
//                   </Grid>
//                 ))
//               ) : (
//                 <Grid item xs={12}>
//                   <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
//                     <Lottie
//                       animationData={noStoreProductResults}
//                       style={{ height: 300 }}
//                     />
//                     <Typography>
//                       {t('brandSearchResults.noStoreResults')}
//                     </Typography>
//                   </Paper>
//                 </Grid>
//               )}
//             </Grid>
//             {storeLastDoc && (
//               <Button onClick={handleStorePageChange}>{t('loadMore')}</Button>
//             )}
//           </>
//         )}
//       </Box>
//     </>
//   );
// };

// export default ProductBrandSearchResults;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Box,
//   Grid,
//   Typography,
//   CircularProgress,
//   AppBar,
//   Toolbar,
//   IconButton,
//   Paper,
//   Button,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { useTranslation } from 'react-i18next';
// import { makeStyles } from '@mui/styles';
// import Product from './Product';
// import useUserActivity from './useUserActivity';
// import useStoreUserActivity from './Stores/useStoreUserActivity';
// import {
//   exportFetchSelectedTownByUserId,
//   exportFetchUserProductsByCoordinates,
//   exportFetchStoreProductsByCoordinates,
//   exportFetchUserProductsByLastDocAsMap,
//   exportFetchStoresByLastDocAsMap,
// } from './Utils/LocationStoresAndProducts';
// import StoreProduct from './Stores/StoreProduct';
// import Lottie from 'lottie-react';
// import noUserProductResults from './assets/lottie/no-userproduct-results.json';
// import noStoreProductResults from './assets/lottie/no-storeproduct-results.json';

// const ProductBrandSearchResults = () => {
//   const classes = useStyles();
//   const { t } = useTranslation();
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

//   const PAGE_SIZE = isSmallScreen ? 8 : 12; // Dynamically adjust the page size

//   const [products, setProducts] = useState([]);
//   const [storeProducts, setStoreProducts] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const searchBrand = useSelector((state) => state.search.searchBrand);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [userLastDoc, setUserLastDoc] = useState(null);
//   const [storeLastDoc, setStoreLastDoc] = useState(null);

//   const { logViewedProduct } = useUserActivity(currentUser?.uid);
//   const { logViewedStoreProduct } = useStoreUserActivity(currentUser?.uid);

//   useEffect(() => {
//     if (searchBrand) {
//       handleSearchBrand(searchBrand, null, null, true);
//     }
//   }, [searchBrand]);

//   const handleNavigateHome = () => {
//     navigate('/');
//   };

//   async function handleSearchBrand(
//     brand,
//     userLastDoc,
//     storeLastDoc,
//     reset = false
//   ) {
//     setLoading(true);
//     setError(null);

//     try {
//       let location = null;
//       if (currentUser) {
//         location = await exportFetchSelectedTownByUserId(currentUser?.uid);
//       }

//       if (!currentUser || !location) {
//         const allUserProducts = await searchAllUserProducts(brand, userLastDoc);
//         const allStoreProducts = await searchAllStoreProducts(
//           brand,
//           storeLastDoc
//         );

//         if (reset) {
//           setProducts(allUserProducts.products);
//           setStoreProducts(allStoreProducts.products);
//         } else {
//           setProducts((prevProducts) => [
//             ...prevProducts,
//             ...allUserProducts.products,
//           ]);
//           setStoreProducts((prevStoreProducts) => [
//             ...prevStoreProducts,
//             ...allStoreProducts.products,
//           ]);
//         }

//         setUserLastDoc(allUserProducts.lastDoc);
//         setStoreLastDoc(allStoreProducts.lastDoc);
//       } else {
//         const productResults = await searchUserProducts(
//           brand,
//           location,
//           userLastDoc
//         );
//         const storeProductResults = await searchStoreProducts(
//           brand,
//           location,
//           storeLastDoc
//         );

//         if (reset) {
//           setProducts(productResults.products);
//           setStoreProducts(storeProductResults.products);
//         } else {
//           setProducts((prevProducts) => [
//             ...prevProducts,
//             ...productResults.products,
//           ]);
//           setStoreProducts((prevStoreProducts) => [
//             ...prevStoreProducts,
//             ...storeProductResults.products,
//           ]);
//         }

//         setUserLastDoc(productResults.lastDoc);
//         setStoreLastDoc(storeProductResults.lastDoc);
//       }
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   }
//   async function searchUserProducts(brand, location, lastDoc) {
//     const userProductsArray = await exportFetchUserProductsByCoordinates(
//       location.lat,
//       location.lng,
//       location.radius,
//       currentUser.uid,
//       PAGE_SIZE,
//       lastDoc
//     );

//     const filteredProducts = userProductsArray.filter(
//       (product) => product.brandId === brand.id
//     );

//     return {
//       products: filteredProducts,
//       lastDoc: products.lastDoc,
//       total: filteredProducts.length,
//     };
//   }

//   async function searchStoreProducts(brand, location, lastDoc) {
//     try {
//       const storeProducts = await exportFetchStoreProductsByCoordinates(
//         location.lat,
//         location.lng,
//         location.radius,
//         lastDoc
//       );

//       // Ensure storeProducts.products is always an array
//       const productsArray = Object.values(storeProducts.products || {});

//       const filteredStoreProducts = productsArray.filter(
//         (product) => product.brandId === brand.id
//       );

//       return {
//         products: filteredStoreProducts,
//         lastDoc: storeProducts.lastDoc,
//         total: filteredStoreProducts.length,
//       };
//     } catch (error) {
//       console.error('Error searching store products:', error);
//       return {
//         products: [],
//         lastDoc: null,
//         total: 0,
//       };
//     }
//   }

//   async function searchAllUserProducts(brand, lastDoc) {
//     const result = await exportFetchUserProductsByLastDocAsMap(
//       PAGE_SIZE,
//       lastDoc
//     );
//     const products = result.products || [];

//     const filteredProducts = products.filter(
//       (product) => product.brandId === brand.id
//     );

//     return {
//       products: filteredProducts,
//       lastDoc: result.lastDoc,
//       total: filteredProducts.length,
//     };
//   }

//   async function searchAllStoreProducts(brand, lastDoc) {
//     try {
//       const result = await exportFetchStoresByLastDocAsMap(PAGE_SIZE, lastDoc);

//       // Ensure result.products is always an array
//       const productsArray = result.products || [];

//       const filteredProducts = productsArray.filter(
//         (product) => product.brandId === brand.id
//       );

//       return {
//         products: filteredProducts,
//         lastDoc: result.lastDoc,
//         total: filteredProducts.length,
//       };
//     } catch (error) {
//       console.error('Error searching all store products:', error);
//       return {
//         products: [],
//         lastDoc: null,
//         total: 0,
//       };
//     }
//   }
//   const renderEmptyState = (lottieAnimation, text) => (
//     <Paper elevation={3} className={classes.emptyState}>
//       <Lottie animationData={lottieAnimation} className={classes.lottie} />
//       <Typography>{text}</Typography>
//     </Paper>
//   );

//   return (
//     <>
//       <AppBar position="sticky" className={classes.appBar}>
//         <Toolbar className={classes.toolbar}>
//           <IconButton
//             edge="start"
//             aria-label="Back"
//             onClick={handleNavigateHome}
//           >
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="h6">{t('brandSearchResults.title')}</Typography>
//         </Toolbar>
//       </AppBar>

//       <Box className={classes.container}>
//         {loading && !products.length && !storeProducts.length ? (
//           <Box
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             minHeight="50vh"
//           >
//             <CircularProgress />
//           </Box>
//         ) : error ? (
//           <Typography className={classes.errorText}>
//             {t('error.errorMessage')} {error}
//           </Typography>
//         ) : (
//           <>
//             <Typography variant="h5" className={classes.sectionTitle}>
//               {t('brandSearchResults.userProducts')}
//             </Typography>
//             <Grid container spacing={2} className={classes.gridContainer}>
//               {products.length > 0
//                 ? products.map((product) => (
//                     <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
//                       <Product
//                         product={product}
//                         onClick={() => logViewedProduct(product.id)}
//                       />
//                     </Grid>
//                   ))
//                 : renderEmptyState(
//                     noUserProductResults,
//                     t('brandSearchResults.noUserResults')
//                   )}
//             </Grid>

//             {userLastDoc && (
//               <Button
//                 className={classes.loadMoreButton}
//                 onClick={() =>
//                   handleSearchBrand(searchBrand, userLastDoc, null)
//                 }
//               >
//                 {t('loadMore')}
//               </Button>
//             )}

//             <Typography variant="h5" className={classes.sectionTitle}>
//               {t('brandSearchResults.storeProducts')}
//             </Typography>
//             <Grid container spacing={2} className={classes.gridContainer}>
//               {storeProducts.length > 0
//                 ? storeProducts.map((product) => (
//                     <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
//                       <StoreProduct
//                         product={product}
//                         onClick={() =>
//                           logViewedStoreProduct(currentUser?.uid, product.id)
//                         }
//                       />
//                     </Grid>
//                   ))
//                 : renderEmptyState(
//                     noStoreProductResults,
//                     t('brandSearchResults.noStoreResults')
//                   )}
//             </Grid>

//             {storeLastDoc && (
//               <Button
//                 className={classes.loadMoreButton}
//                 onClick={() =>
//                   handleSearchBrand(searchBrand, null, storeLastDoc)
//                 }
//               >
//                 {t('loadMore')}
//               </Button>
//             )}
//           </>
//         )}
//       </Box>
//     </>
//   );
// };
// const useStyles = makeStyles((theme) => ({
//   container: {
//     backgroundColor: theme.palette.background.default,
//     color: theme.palette.text.primary,
//     padding: theme.spacing(2),
//     minHeight: '100vh',
//   },
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//   },
//   toolbar: {
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
//   sectionTitle: {
//     marginTop: theme.spacing(4),
//     marginBottom: theme.spacing(2),
//   },
//   gridContainer: {
//     marginBottom: theme.spacing(4),
//   },
//   emptyState: {
//     textAlign: 'center',
//     padding: theme.spacing(4),
//   },
//   lottie: {
//     height: 300,
//     marginBottom: theme.spacing(2),
//   },
//   loadMoreButton: {
//     marginTop: theme.spacing(2),
//     marginBottom: theme.spacing(4),
//     padding: theme.spacing(1.5),
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.primary.contrastText,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//     },
//   },
//   errorText: {
//     color: theme.palette.error.main,
//     textAlign: 'center',
//     marginTop: theme.spacing(4),
//   },
// }));
// export default ProductBrandSearchResults;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  Paper,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import Product from './Product';
import useUserActivity from './useUserActivity';
import useStoreUserActivity from './Stores/useStoreUserActivity';
import Lottie from 'lottie-react';
import noUserProductResults from './assets/lottie/no-userproduct-results.json';
import noStoreProductResults from './assets/lottie/no-storeproduct-results.json';
import {
  exportFetchSelectedTownByUserId,
  exportFetchUserProductsByCoordinates,
  exportFetchStoreProductsByCoordinates,
  exportFetchUserProductsByLastDocAsMap,
  exportFetchStoresByLastDocAsMap,
} from './Utils/LocationStoresAndProducts';
import NoUserProducts from './NoUserProducts';
import StoreProduct from './Stores/StoreProduct';
import NoStoreProducts from './NoStoreProducts';

const ProductBrandSearchResults = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const PAGE_SIZE = isSmallScreen ? 8 : 12;

  const [products, setProducts] = useState([]);
  const [storeProducts, setStoreProducts] = useState([]);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const searchBrand = useSelector((state) => state.search.searchBrand);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userLastDoc, setUserLastDoc] = useState(null);
  const [storeLastDoc, setStoreLastDoc] = useState(null);

  const { logViewedProduct } = useUserActivity(currentUser?.uid);
  const { logViewedStoreProduct } = useStoreUserActivity(currentUser?.uid);

  useEffect(() => {
    if (searchBrand) {
      handleSearchBrand(searchBrand, null, null, true);
    }
  }, [searchBrand]);

  const handleNavigateHome = () => {
    navigate('/');
  };

  async function handleSearchBrand(
    brand,
    userLastDoc,
    storeLastDoc,
    reset = false
  ) {
    setLoading(true);
    setError(null);

    try {
      let location = null;
      if (currentUser) {
        location = await exportFetchSelectedTownByUserId(currentUser?.uid);
      }

      if (!currentUser || !location) {
        const allUserProducts = await searchAllUserProducts(brand, userLastDoc);
        const allStoreProducts = await searchAllStoreProducts(
          brand,
          storeLastDoc
        );

        if (reset) {
          setProducts(allUserProducts.products);
          setStoreProducts(allStoreProducts.products);
        } else {
          setProducts((prevProducts) => [
            ...prevProducts,
            ...allUserProducts.products,
          ]);
          setStoreProducts((prevStoreProducts) => [
            ...prevStoreProducts,
            ...allStoreProducts.products,
          ]);
        }

        setUserLastDoc(allUserProducts.lastDoc);
        setStoreLastDoc(allStoreProducts.lastDoc);
      } else {
        const productResults = await searchUserProducts(
          brand,
          location,
          userLastDoc
        );
        const storeProductResults = await searchStoreProducts(
          brand,
          location,
          storeLastDoc
        );

        if (reset) {
          setProducts(productResults.products);
          setStoreProducts(storeProductResults.products);
        } else {
          setProducts((prevProducts) => [
            ...prevProducts,
            ...productResults.products,
          ]);
          setStoreProducts((prevStoreProducts) => [
            ...prevStoreProducts,
            ...storeProductResults.products,
          ]);
        }

        setUserLastDoc(productResults.lastDoc);
        setStoreLastDoc(storeProductResults.lastDoc);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }
  async function searchUserProducts(brand, location, lastDoc) {
    const userProductsArray = await exportFetchUserProductsByCoordinates(
      location.lat,
      location.lng,
      location.radius,
      currentUser.uid,
      PAGE_SIZE,
      lastDoc
    );

    const filteredProducts = userProductsArray.filter(
      (product) => product.brandId === brand.id
    );

    return {
      products: filteredProducts,
      lastDoc: products.lastDoc,
      total: filteredProducts.length,
    };
  }

  async function searchStoreProducts(brand, location, lastDoc) {
    try {
      const storeProducts = await exportFetchStoreProductsByCoordinates(
        location.lat,
        location.lng,
        location.radius,
        lastDoc
      );

      // Ensure storeProducts.products is always an array
      const productsArray = Object.values(storeProducts.products || {});

      const filteredStoreProducts = productsArray.filter(
        (product) => product.brandId === brand.id
      );

      return {
        products: filteredStoreProducts,
        lastDoc: storeProducts.lastDoc,
        total: filteredStoreProducts.length,
      };
    } catch (error) {
      console.error('Error searching store products:', error);
      return {
        products: [],
        lastDoc: null,
        total: 0,
      };
    }
  }

  async function searchAllUserProducts(brand, lastDoc) {
    const result = await exportFetchUserProductsByLastDocAsMap(
      PAGE_SIZE,
      lastDoc
    );
    const products = result.products || [];

    const filteredProducts = products.filter(
      (product) => product.brandId === brand.id
    );

    return {
      products: filteredProducts,
      lastDoc: result.lastDoc,
      total: filteredProducts.length,
    };
  }

  async function searchAllStoreProducts(brand, lastDoc) {
    try {
      const result = await exportFetchStoresByLastDocAsMap(PAGE_SIZE, lastDoc);

      // Ensure result.products is always an array
      const productsArray = result.products || [];

      const filteredProducts = productsArray.filter(
        (product) => product.brandId === brand.id
      );

      return {
        products: filteredProducts,
        lastDoc: result.lastDoc,
        total: filteredProducts.length,
      };
    } catch (error) {
      console.error('Error searching all store products:', error);
      return {
        products: [],
        lastDoc: null,
        total: 0,
      };
    }
  }
  // const renderEmptyState1 = (lottieAnimation, text) => (
  //   <Paper elevation={3} className={classes.emptyState}>
  //     <Lottie animationData={lottieAnimation} className={classes.lottie} />
  //     <Typography>{text}</Typography>
  //   </Paper>
  // );
  const renderEmptyState = (lottieAnimation, text) => (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        p: 4,
        bgcolor: theme.palette.background.paper,
        minHeight: 300, // 최소 높이 설정
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: 250, // 높이 설정
        }}
      >
        <Lottie animationData={lottieAnimation} style={{ height: 200 }} />
      </Box>

      <Typography sx={{ color: theme.palette.text.secondary, mt: 2 }}>
        {text}
      </Typography>
    </Paper>
  );

  return (
    <>
      {/* <AppBar
        position="sticky"
        sx={{
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            edge="start"
            aria-label="Back"
            onClick={handleNavigateHome}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">{t('brandSearchResults.title')}</Typography>
        </Toolbar>
      </AppBar> */}

      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          color: theme.palette.text.primary,
          p: 2,
          minHeight: '100vh',
        }}
      >
        {loading && !products.length && !storeProducts.length ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography
            sx={{
              color: theme.palette.error.main,
              textAlign: 'center',
              mt: 4,
            }}
          >
            {t('error.errorMessage')} {error}
          </Typography>
        ) : (
          <>
            {/* 🛍️ 사용자 제품 리스트 */}
            <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
              {t('brandSearchResults.userProducts')}
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{ mb: 4, justifyContent: 'center' }}
            >
              {products.length > 0 ? (
                products.map((product) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                    <Product
                      product={product}
                      onClick={() => logViewedProduct(product.id)}
                    />
                  </Grid>
                ))
              ) : (
                <Grid
                  item
                  xs={8}
                  // sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  {/* {renderEmptyState(
                    noUserProductResults,
                    t('brandSearchResults.noUserResults')
                  )} */}
                  <NoUserProducts />
                </Grid>
              )}
            </Grid>

            {userLastDoc && products.length > 0 && (
              <Button
                sx={{
                  mt: 2,
                  mb: 4,
                  p: 1.5,
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  '&:hover': { bgcolor: theme.palette.primary.dark },
                }}
                onClick={() =>
                  handleSearchBrand(searchBrand, userLastDoc, null)
                }
              >
                {t('loadMore')}
              </Button>
            )}

            {/* 🏪 매장 제품 리스트 */}
            <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
              {t('brandSearchResults.storeProducts')}
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{ mb: 4, justifyContent: 'center' }}
            >
              {storeProducts.length > 0 ? (
                storeProducts.map((product) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                    <StoreProduct
                      product={product}
                      onClick={() => logViewedStoreProduct(product.id)}
                    />
                  </Grid>
                ))
              ) : (
                <Grid
                  item
                  xs={8}
                  // sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  {/* {renderEmptyState(
                    noStoreProductResults,
                    t('brandSearchResults.noStoreResults')
                  )} */}
                  <NoStoreProducts />
                </Grid>
              )}
            </Grid>

            {storeLastDoc && storeProducts.length > 0 && (
              <Button
                sx={{
                  mt: 2,
                  mb: 4,
                  p: 1.5,
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  '&:hover': { bgcolor: theme.palette.primary.dark },
                }}
                onClick={() =>
                  handleSearchBrand(searchBrand, null, storeLastDoc)
                }
              >
                {t('loadMore')}
              </Button>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ProductBrandSearchResults;
