// import React from 'react';
// import { Box, useMediaQuery, useTheme } from '@mui/material';

// const ResponsiveContainer = ({ children }) => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

//   return (
//     <Box
//       sx={{
//         padding: isMobile ? '0 16px' : isTablet ? '0 32px' : '0 64px',
//         margin: '0 auto',
//         width: '100%',
//         // maxWidth: '1500px',
//       }}
//     >
//       {children}
//     </Box>
//   );
// };

// export default ResponsiveContainer;

import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ResponsiveContainer = ({ children }) => {
  const theme = useTheme(); // ThemeContext에서 theme 가져오기
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:960px)');

  return (
    <Box
      sx={{
        // padding: isMobile ? '0 16px' : isTablet ? '0 32px' : '0 64px',
        // margin: '0 auto',
        width: '100%',
        // maxWidth: '1500px',
        backgroundColor: theme?.colors?.backgroundColor || '#FFFFFF', // ThemeContext에서 가져온 배경색
        color: theme?.colors?.textColor || '#000000', // ThemeContext에서 가져온 텍스트 색상
        // border: `1px solid ${theme?.searchInput?.borderColor || '#CCCCCC'}`, // 검색창 테두리 색상
        // '&:hover': {
        //   borderColor: theme?.searchInput?.hoverBorderColor || '#999999', // 검색창 호버 시 테두리 색상
        // },
        // borderRadius: '8px',
        boxShadow: theme?.shadows?.[1] || '0px 4px 6px rgba(0, 0, 0, 0.1)', // 기본값 설정
      }}
    >
      {children}
    </Box>
  );
};

export default ResponsiveContainer;
