// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   Box,
//   Typography,
//   CircularProgress,
//   Button,
//   Card,
//   CardContent,
// } from '@mui/material';
// import { doc, getDoc } from 'firebase/firestore';
// import { db } from '../firebaseConfig';
// import axios from 'axios';
// import { keyframes } from '@emotion/react';
// import styled from '@emotion/styled';
// import { useTranslation } from 'react-i18next';
// import sunny from '../assets/images/weather/sunny.jpg';
// import rain from '../assets/images/weather/rain.jpg';
// import cloud from '../assets/images/weather/cloud.jpg';
// import snow from '../assets/images/weather/snow.jpg';

// // Keyframes for the scrolling text animation
// const scroll = keyframes`
//   0% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// `;

// const ScrollingBar = styled(Typography)`
//   display: inline-block;
//   white-space: nowrap;
//   overflow: hidden;
//   animation: ${scroll} 12s linear infinite;
//   color: white;
//   font-size: 1rem;
// `;

// // Background styling with a darker overlay for better readability
// const BackgroundBox = styled(Box)`
//   position: relative;
//   background-image: ${(props) => props.backgroundImage};
//   background-size: cover;
//   background-position: center;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 20px;

//   &::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(0, 0, 0, 0.5); // Dark overlay for text readability
//     backdrop-filter: blur(4px);
//     z-index: 0;
//   }
// `;

// const ContentWrapper = styled(Box)`
//   position: relative;
//   z-index: 1;
//   text-align: center;
//   color: white;
//   width: 100%;
//   max-width: 600px;
// `;

// function MyTownWeather() {
//   const [loading, setLoading] = useState(false);
//   const [locationName, setLocationName] = useState('');
//   const [weatherData, setWeatherData] = useState(null);
//   const [backgroundImage, setBackgroundImage] = useState();
//   const [clothingAdvice, setClothingAdvice] = useState('');
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const { t } = useTranslation();

//   useEffect(() => {
//     const fetchLocation = async () => {
//       if (currentUser) {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userDoc = await getDoc(userRef);
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           const selectedTown = userData.selectedTown;
//           const locationData =
//             selectedTown === 1 ? userData.town1 : userData.town2;
//           if (locationData) {
//             setLocationName(locationData.name);
//             fetchWeather(locationData.lat, locationData.lng);
//           }
//         }
//       }
//     };

//     const fetchWeather = async (latitude, longitude) => {
//       setLoading(true);
//       try {
//         const serverUrl =
//           'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/getWeatherByLocation';
//         const response = await axios.post(serverUrl, {
//           myLocation: { latitude, longitude },
//         });
//         const weather = response.data;
//         setWeatherData(weather);
//         updateBackgroundAndClothing(weather);
//       } catch (error) {
//         console.error('Failed to fetch weather data', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     const updateBackgroundAndClothing = (weather) => {
//       const skyCondition = weather.skyCondition.toLowerCase();
//       const temp = parseInt(weather.temperature, 10);

//       if (skyCondition.includes('clear') || skyCondition.includes('맑음')) {
//         setBackgroundImage(`url(${sunny})`);
//       } else if (
//         skyCondition.includes('cloud') ||
//         skyCondition.includes('구름')
//       ) {
//         setBackgroundImage(`url(${cloud})`);
//       } else if (skyCondition.includes('rain') || skyCondition.includes('비')) {
//         setBackgroundImage(`url(${rain})`);
//       } else if (skyCondition.includes('snow') || skyCondition.includes('눈')) {
//         setBackgroundImage(`url(${snow})`);
//       } else {
//         setBackgroundImage(`url(${cloud})`);
//       }

//       let advice = '';
//       if (temp > 25) advice = t('weather.hot');
//       else if (temp > 15) advice = t('weather.mild');
//       else if (temp > 5) advice = t('weather.chilly');
//       else advice = t('weather.cold');

//       if (skyCondition.includes('rain')) {
//         advice += ` ${t('weather.umbrella')}`;
//       }
//       setClothingAdvice(advice);
//     };

//     if (currentUser) {
//       fetchLocation();
//     }
//   }, [currentUser, t]);

//   if (loading) {
//     return (
//       <Container maxWidth="sm">
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <BackgroundBox backgroundImage={backgroundImage}>
//       <ContentWrapper>
//         <Typography variant="h5" component="h1" gutterBottom>
//           {locationName ? `${locationName}` : t('weather.setLocation')}
//         </Typography>

//         {weatherData && (
//           <>
//             <Card
//               sx={{
//                 bgcolor: 'rgba(255, 255, 255, 0.1)',
//                 backdropFilter: 'blur(10px)',
//                 p: 2,
//               }}
//             >
//               <CardContent>
//                 <ScrollingBar variant="h6">
//                   🌡 {weatherData.temperature} | 💧 {weatherData.humidity} | ☁️{' '}
//                   {weatherData.skyCondition} | ☔ {weatherData.rainfall}
//                 </ScrollingBar>
//               </CardContent>
//             </Card>

//             <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
//               {clothingAdvice}
//             </Typography>
//           </>
//         )}

//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => navigate('/settings/mytown')}
//           sx={{ mt: 2 }}
//         >
//           {t('myTown.title')}
//         </Button>
//       </ContentWrapper>
//     </BackgroundBox>
//   );
// }

// export default MyTownWeather;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import axios from 'axios';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import sunny from '../assets/images/weather/sunny.jpg';
import rain from '../assets/images/weather/rain.jpg';
import cloud from '../assets/images/weather/cloud.jpg';
import snow from '../assets/images/weather/snow.jpg';

// Styled component for background
const BackgroundBox = styled(Box)`
  position: relative;
  background-image: ${(props) => props.backgroundImage};
  background-size: cover;
  background-position: center;
  min-width: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 0;
  }
`;

const ContentWrapper = styled(Box)`
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  width: 100%;
  max-width: 600px;
`;

// Flexbox for weather info row
const WeatherInfoRow = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 10px 20px;
  white-space: nowrap;
  overflow-x: auto;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
`;

function MyTownWeather() {
  const [loading, setLoading] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [weatherData, setWeatherData] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState();
  const [clothingAdvice, setClothingAdvice] = useState('');
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLocation = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const selectedTown = userData.selectedTown;
          const locationData =
            selectedTown === 1 ? userData.town1 : userData.town2;
          if (locationData) {
            setLocationName(locationData.name);
            fetchWeather(locationData.lat, locationData.lng);
          }
        }
      }
    };

    const fetchWeather = async (latitude, longitude) => {
      setLoading(true);
      try {
        const serverUrl =
          'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/getWeatherByLocation';
        const response = await axios.post(serverUrl, {
          myLocation: { latitude, longitude },
        });
        const weather = response.data;
        setWeatherData(weather);
        updateBackgroundAndClothing(weather);
      } catch (error) {
        console.error('Failed to fetch weather data', error);
      } finally {
        setLoading(false);
      }
    };

    const updateBackgroundAndClothing = (weather) => {
      const skyCondition = weather.skyCondition.toLowerCase();
      const temp = parseInt(weather.temperature, 10);

      if (skyCondition.includes('clear') || skyCondition.includes('맑음')) {
        setBackgroundImage(`url(${sunny})`);
      } else if (
        skyCondition.includes('cloud') ||
        skyCondition.includes('구름')
      ) {
        setBackgroundImage(`url(${cloud})`);
      } else if (skyCondition.includes('rain') || skyCondition.includes('비')) {
        setBackgroundImage(`url(${rain})`);
      } else if (skyCondition.includes('snow') || skyCondition.includes('눈')) {
        setBackgroundImage(`url(${snow})`);
      } else {
        setBackgroundImage(`url(${cloud})`);
      }

      let advice = '';
      if (temp > 25) advice = t('weather.hot');
      else if (temp > 15) advice = t('weather.mild');
      else if (temp > 5) advice = t('weather.chilly');
      else advice = t('weather.cold');

      if (skyCondition.includes('rain')) {
        advice += ` ${t('weather.umbrella')}`;
      }
      setClothingAdvice(advice);
    };

    if (currentUser) {
      fetchLocation();
    }
  }, [currentUser, t]);

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <BackgroundBox backgroundImage={backgroundImage}>
      <ContentWrapper>
        <Typography variant="h5" component="h1" gutterBottom>
          {locationName ? `${locationName}` : t('weather.setLocation')}
        </Typography>

        {weatherData && (
          <>
            {/* 가로형 날씨 정보 */}
            <WeatherInfoRow>
              <Typography variant="h6">🌡 {weatherData.temperature}</Typography>
              <Typography variant="h6">💧 {weatherData.humidity}</Typography>
              <Typography variant="h6">
                ☁️ {weatherData.skyCondition}
              </Typography>
              <Typography variant="h6">☔ {weatherData.rainfall}</Typography>
            </WeatherInfoRow>

            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
              {clothingAdvice}
            </Typography>
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/settings/mytown')}
          sx={{ mt: 2 }}
        >
          {t('myTown.title')}
        </Button>
      </ContentWrapper>
    </BackgroundBox>
  );
}

export default MyTownWeather;
