
// import React, { useEffect, useState, useMemo } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import { Box, Typography, IconButton, Tooltip } from '@mui/material';
// import { ReactComponent as HighIcon } from '../../assets/high-icon.svg';
// import { ReactComponent as LowIcon } from '../../assets/low-icon.svg';
// import { ReactComponent as HumidityIcon } from '../../assets/humidity-icon.svg';
// import { ReactComponent as WindIcon } from '../../assets/wind-icon.svg';
// import { ReactComponent as PressureIcon } from '../../assets/pressure-icon.svg';
// import { changeTempUnit } from '../../Redux/reducers/appReducer';
// import { kmToMile, TempUnit } from '../../Utils/unitConversion';
// import { useTheme, useMediaQuery } from '@mui/material';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import SwapVertIcon from '@mui/icons-material/SwapVert';
// import WeatherIcon from './WeatherIcon';
// import Temperature from './Temperature';
// import WeatherEventDetails from '../WeatherEventDetails';
// import { AppStore } from '../../Redux/store';

// const CurrentWeather = () => {
//   const { t } = useTranslation();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const dispatch = useDispatch();
//   const [modalVisible, setModalVisible] = useState(false);

//   const { weather, degreeType, isInitial, isError } = useSelector((store: AppStore) => ({
//     weather: store.weather.weatherData,
//     degreeType: store.app.tempUnit,
//     isInitial: store.app.isInitial,
//     isError: store.weather.isError,
//   }));

//   useEffect(() => {
//     if (isError) console.error(t('error_loading_weather'));
//   }, [isError, t]);

//   const windSpeed = useMemo(
//     () => (degreeType === TempUnit.CELCIUS ? weather.wind.speed : kmToMile(weather.wind.speed)),
//     [weather.wind.speed, degreeType]
//   );

//   if (isInitial) return null;

//   return (
//     <Box
//       sx={{
//         width: '100%',
//         backgroundColor: theme.palette.background.paper,
//         color: theme.palette.text.primary,
//         padding: 1.5,
//         borderRadius: 2,
//         boxShadow: theme.shadows[2],
//       }}
//     >
//       {/* 🔹 제목 + 버튼 */}
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
//         <Typography variant="subtitle2">{t('weather.today')}</Typography>
//         <Box>
//           <Tooltip title={t('weather.eventDetails')}>
//             <IconButton size="small" onClick={() => setModalVisible(true)}>
//               <InfoOutlinedIcon fontSize="small" />
//             </IconButton>
//           </Tooltip>
//           <Tooltip title={t('weather.toggleTempUnit')}>
//             <IconButton size="small" onClick={() => dispatch(changeTempUnit())}>
//               <SwapVertIcon fontSize="small" />
//             </IconButton>
//           </Tooltip>
//         </Box>
//       </Box>

//       {/* 🔹 날씨 상태 정보 */}
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
//         <Typography variant="caption">{weather.name}</Typography>
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//           <WeatherIcon code={weather.weather.id} />
//           <Typography variant="h5">
//             <Temperature value={weather.main.temp} />
//             <sup>&deg;</sup>
//           </Typography>
//         </Box>
//         <Typography variant="caption" color="text.secondary">
//           {weather.weather.description}
//         </Typography>
//       </Box>

//       {/* 🔹 날씨 상세 정보 (아이콘 크기 & 정렬 개선) */}
//       <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: 1.5 }}>
//         {[
//           { icon: <HighIcon width={18} height={18} fill={theme.palette.text.primary} />, label: t('weather.high'), value: `${weather.main.temp_max}°` },
//           { icon: <LowIcon width={18} height={18} fill={theme.palette.text.primary} />, label: t('weather.low'), value: `${weather.main.temp_min}°` },
//           { icon: <HumidityIcon width={18} height={18} fill={theme.palette.text.primary} />, label: t('weather.humidity'), value: `${weather.main.humidity}%` },
//           { icon: <WindIcon width={18} height={18} fill={theme.palette.text.primary} />, label: t('weather.wind'), value: `${windSpeed} km/h` },
//           { icon: <PressureIcon width={18} height={18} fill={theme.palette.text.primary} />, label: t('weather.pressure'), value: `${weather.main.pressure} hPa` },
//         ].map((item, index) => (
//           <Box
//             key={index}
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               gap: 0.8,
//               p: 0.5,
//               borderRadius: 1,
//             }}
//           >
//             {item.icon}
//             <Typography variant="caption" sx={{ fontSize: '0.85rem' }}>
//               {item.label}: {item.value}
//             </Typography>
//           </Box>
//         ))}
//       </Box>

//       {/* 🔹 날씨 이벤트 상세 모달 */}
//       {modalVisible && <WeatherEventDetails open={modalVisible} onClose={() => setModalVisible(false)} />}
//     </Box>
//   );
// };

// export default CurrentWeather;

import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import { useTheme } from '@mui/material/styles';
import WeatherIcon from './WeatherIcon';
import { AppStore } from '../../Redux/store';
import Temperature from './Temperature';
import { kmToMile, TempUnit } from '../../Utils/unitConversion';
import WeatherEventDetails from '../WeatherEventDetails';
import { ReactComponent as HighIcon } from '../../assets/high-icon.svg';
import { ReactComponent as LowIcon } from '../../assets/low-icon.svg';
import { ReactComponent as HumidityIcon } from '../../assets/humidity-icon.svg';
import { ReactComponent as WindIcon } from '../../assets/wind-icon.svg';
import { ReactComponent as PressureIcon } from '../../assets/pressure-icon.svg';

const CurrentWeather = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [modalVisible, setModalVisible] = useState(false);
  
  const { weather, degreeType, isInitial, isError } = useSelector((store: AppStore) => ({
    weather: store.weather.weatherData,
    degreeType: store.app.tempUnit,
    isInitial: store.app.isInitial,
    isError: store.weather.isError,
  }));

  const windSpeed = useMemo(
    () => (degreeType === TempUnit.CELCIUS ? weather.wind.speed : kmToMile(weather.wind.speed)),
    [weather.wind.speed, degreeType]
  );
  if (isInitial) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        backgroundColor: 'transparent',
        // padding: '8px 16px',
        // borderRadius: 2,
        // boxShadow: theme.shadows[1],
      }}
    >
      {/* 🌍 위치명 */}
      
      {/* 🌦️ 날씨 아이콘 */}
      <WeatherIcon code={weather.weather.id} />
      <Typography variant="h5">
        <Temperature value={weather.main.temp} />
        <sup>&deg;</sup>
      </Typography>
      {/* <Tooltip title={t('weather.eventDetails')}>
        <IconButton size="small" onClick={() => setModalVisible(true)}>
          <InfoOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip> */}
      <Tooltip title={t('weather.eventDetails')} arrow>
        <IconButton
          size="small"
          onClick={() => setModalVisible(true)}
          sx={{
            color: theme.palette.info.main,
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.2)',
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          <InfoOutlinedIcon
 fontSize="small" />
        </IconButton>
      </Tooltip>


      {/* 🔹 날씨 상세 정보 (아이콘 크기 & 정렬 개선) */}
      {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: 1.5 }}>
      {[
        { icon: <HighIcon width={18} height={18} fill={theme.palette.text.primary} />, label: t('weather.high'), value: `${weather.main.temp_max}°` },
        { icon: <LowIcon width={18} height={18} fill={theme.palette.text.primary} />, label: t('weather.low'), value: `${weather.main.temp_min}°` },
        { icon: <HumidityIcon width={18} height={18} fill={theme.palette.text.primary} />, label: t('weather.humidity'), value: `${weather.main.humidity}%` },
        { icon: <WindIcon width={18} height={18} fill={theme.palette.text.primary} />, label: t('weather.wind'), value: `${windSpeed} km/h` },
        { icon: <PressureIcon width={18} height={18} fill={theme.palette.text.primary} />, label: t('weather.pressure'), value: `${weather.main.pressure} hPa` },
      ].map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.8,
            p: 0.5,
            borderRadius: 1,
          }}
        >
          {item.icon}
          <Typography variant="caption" sx={{ fontSize: '0.85rem' }}>
            {item.label}: {item.value}
          </Typography>
        </Box>
      ))}
      </Box> */}
      {/* 🔹 날씨 이벤트 상세 모달 */}
      {modalVisible && <WeatherEventDetails open={modalVisible} onClose={() => setModalVisible(false)} />}
    </Box>
  );
};

export default CurrentWeather;