// import React, { useState, useEffect } from 'react';
// import { collection, getDocs } from 'firebase/firestore';
// import { db } from '../../firebaseConfig'; // Ensure you import your Firebase configuration and SDK properly
// import {
//   Box,
//   Alert,
//   CircularProgress,
//   Typography,
//   Divider,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next'; // i18n import
// import CartList from './CartList'; // Assuming CartList is a component that takes store items
// import { useParams, useNavigate } from 'react-router-dom';

// const AllCartList = () => {
//   const { t, i18n } = useTranslation();
//   const [stores, setStores] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');
//   const { userId } = useParams();

//   useEffect(() => {
//     const fetchStores = async () => {
//       try {
//         setLoading(true);
//         const storesCollection = collection(db, 'stores');
//         const storeSnapshot = await getDocs(storesCollection);
//         const storeData = storeSnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         setStores(storeData);
//       } catch (error) {
//         console.error('Error fetching stores:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchStores();
//   }, []);

//   if (loading) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '100vh',
//         }}
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }
//   if (error) {
//     return (
//       <Box textAlign="center" mt={5}>
//         <Alert severity="error">{error}</Alert>
//       </Box>
//     );
//   }
//   if (!stores.length) {
//     return (
//       <Box textAlign="center" mt={5}>
//         <Typography>No stores found.</Typography>
//       </Box>
//     );
//   }
//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 그림자 효과
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//         }}
//       >
//         <Typography variant="h4" gutterBottom>
//           {t('cartListByStore')}
//         </Typography>
//       </Box>
//       {stores.map((store) => (
//         <Box key={store.id} sx={{ marginBottom: 4 }}>
//           <CartList userIdProp={userId} storeIdProp={store.id} />
//           <Divider sx={{ marginTop: 2 }} />
//         </Box>
//       ))}
//     </Box>
//   );
// };

// export default AllCartList;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  CircularProgress,
  Alert,
  Divider,
  useTheme,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import CartList from './CartList';

const AllCartList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { userId } = useParams();

  useEffect(() => {
    const fetchStores = async () => {
      try {
        setLoading(true);
        const storesCollection = collection(db, 'stores');
        const storeSnapshot = await getDocs(storesCollection);
        const storeData = storeSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStores(storeData);
      } catch (error) {
        console.error('Error fetching stores:', error);
        setError(t('errorFetchingStores')); // 다국어 에러 메시지
      } finally {
        setLoading(false);
      }
    };

    fetchStores();
  }, [t]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" mt={5}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!stores.length) {
    return (
      <Box textAlign="center" mt={5}>
        <Typography color={theme.palette.text.primary}>
          {t('noStores')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      p={3}
      sx={{
        width: '100%',
        margin: 'auto',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      {/* 제목 영역 */}
      <Container
        maxWidth="lg"
        sx={{
          // display: 'inline-block',
          //borderRadius: '12px',
          display: 'flex', // flexbox 사용
          justifyContent: 'center', // 가로 중앙 정렬
          flexDirection: 'column',
          // alignItems: 'center', // 세로 중앙 정렬 (필요한 경우)
          padding: '8px 16px',
          marginBottom: '16px',
          backgroundColor: theme.palette.background.paper,
          // transition: 'transform 0.2s',
          // '&:hover': {
          //   transform: 'scale(1.02)',
          // },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            paddingBottom: theme.spacing(2),
            color: theme.palette.primary.main,
          }}
        >
          {t('cartListByStore')}
        </Typography>

        {/* 장바구니 리스트 */}
        {stores.map((store) => (
          <Box key={store.id} sx={{ marginBottom: 4 }}>
            <CartList userIdProp={userId} storeIdProp={store.id} />
            <Divider
              sx={{ marginTop: 2, backgroundColor: theme.palette.divider }}
            />
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default AllCartList;
