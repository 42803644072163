import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Avatar,
  CircularProgress,
  Container,
  useTheme,
} from '@mui/material';
import { db, doc, getDoc } from './firebaseConfig';
import { useTranslation } from 'react-i18next';
import UserRatings from './UserRating';

function UserProfile() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userId } = useParams();
  const [profile, setProfile] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setProfile(userDoc.data());
        } else {
          const msg = t('userProfileDetails.userNotFound');
          setError(typeof msg === 'string' ? msg : 'User not found');
        }
      } catch (err) {
        const errMsg = t('userProfileDetails.errorFetchingProfile');
        setError(
          (typeof errMsg === 'string' ? errMsg : 'Error fetching profile') +
            ': ' +
            err.message
        );
      } finally {
        setLoadingProfile(false);
      }
    };
    fetchProfile();
  }, [userId, t]);

  if (loadingProfile) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !profile) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography color="error">{error || 'Unknown error'}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}
      >
        <Typography variant="h5" sx={{ mt: 5 }} component="h2" align="center">
          {profile?.email || ''}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            mt: 5,
            mb: 2,
          }}
        >
          <Avatar src={profile?.photoURL || ''} sx={{ width: 140, height: 140 }} />
        </Box>
        <Typography variant="h6" component="h2" align="center">
          {profile?.displayName || ''}
        </Typography>
        {profile?.userType === 2 && (
          <Typography variant="body1" align="center">
            {t('userProfileDetails.storeUser')}
          </Typography>
        )}
        <UserRatings userId={userId} />
      </Container>
    </Box>
  );
}

export default UserProfile;