// import React from 'react';
// import {
//   ListItem,
//   FormControlLabel,
//   Checkbox,
//   Typography,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// const PRODUCT_STATES = [
//   { key: 'selling', i18nKey: 'selling' },
//   { key: 'reserved', i18nKey: 'reserved' },
//   { key: 'sold-out', i18nKey: 'sold-out' },
// ];

// const ProductStatusFilter = ({ selectedStates = [], onChange }) => {
//   const { t } = useTranslation();

//   const handleChange = (stateKey, checked) => {
//     const newSet = new Set(selectedStates);
//     checked ? newSet.add(stateKey) : newSet.delete(stateKey);
//     onChange(Array.from(newSet));
//   };

//   return (
//     <>
//       <ListItem>
//         <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
//           {t('상품 상태')}
//         </Typography>
//       </ListItem>
//       {PRODUCT_STATES.map((state) => (
//         <ListItem key={state.key} disableGutters>
//           <FormControlLabel
//             control={
//               <Checkbox
//                 checked={selectedStates.includes(state.key)}
//                 onChange={(e) => handleChange(state.key, e.target.checked)}
//               />
//             }
//             label={t(state.i18nKey)}
//           />
//         </ListItem>
//       ))}
//     </>
//   );
// };

// export default ProductStatusFilter;

// import React from 'react';
// import {
//   Box,
//   FormControlLabel,
//   Checkbox,
//   Typography,
//   ListItem,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// const PRODUCT_STATES = [
//   { key: 'selling', i18nKey: 'selling' },
//   { key: 'reserved', i18nKey: 'reserved' },
//   { key: 'sold-out', i18nKey: 'sold-out' },
// ];

// const ProductStatusFilter = ({ selectedStates = [], onChange }) => {
//   const { t } = useTranslation();

//   const handleChange = (stateKey, checked) => {
//     const newSet = new Set(selectedStates);
//     checked ? newSet.add(stateKey) : newSet.delete(stateKey);
//     onChange(Array.from(newSet));
//   };

//   return (
//     <>
//       <ListItem disableGutters>
//         {/* <Typography
//           variant="subtitle1"
//           sx={{ fontWeight: 'bold', px: 2, mb: 1 }}
//         > */}
//         <Typography variant="body2" sx={{ px: 2 }}>
//           {t('productStatus')}
//         </Typography>
//       </ListItem>

//       <Box sx={{ display: 'flex', gap: 2, px: 2, flexWrap: 'wrap' }}>
//         {PRODUCT_STATES.map((state) => (
//           <FormControlLabel
//             key={state.key}
//             control={
//               <Checkbox
//                 size="small"
//                 checked={selectedStates.includes(state.key)}
//                 onChange={(e) => handleChange(state.key, e.target.checked)}
//               />
//             }
//             label={<Typography variant="body2">{t(state.i18nKey)}</Typography>}
//           />
//         ))}
//       </Box>
//     </>
//   );
// };

// export default ProductStatusFilter;
// ✅ ProductStatusFilter.jsx
import React from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ProductStatusFilter = ({ selectedStates = [], onChange }) => {
  const { t } = useTranslation();

  const options = [
    { label: t('selling'), value: 'selling' },
    { label: t('reserved'), value: 'reserved' },
    { label: t('sold-out'), value: 'sold-out' },
  ];

  const handleToggle = (value) => {
    let newStates = [...selectedStates];
    if (newStates.includes(value)) {
      newStates = newStates.filter((v) => v !== value);
    } else {
      newStates.push(value);
    }
    onChange(newStates);
  };

  return (
    <FormGroup row sx={{ px: 2 }}>
      {options.map((opt) => (
        <FormControlLabel
          key={opt.value}
          control={
            <Checkbox
              checked={selectedStates.includes(opt.value)}
              onChange={() => handleToggle(opt.value)}
            />
          }
          label={opt.label}
        />
      ))}
    </FormGroup>
  );
};

export default ProductStatusFilter;
