import { db } from '../firebaseConfig';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
} from 'firebase/firestore';

/**
 * Firestore에서 날씨 이벤트 리스트를 가져오는 API 함수
 * @returns {Promise<Array>} - 이벤트 리스트 반환
 */
export const fetchWeatherEvents = async () => {
  try {
    const q = query(
      collection(db, 'events'),
      where('condition', '==', 1),
      orderBy('priority') // 👈 priority 기준 오름차순 정렬
    );

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error('Error fetching weather events:', error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};

/**
 * 특정 ID의 날씨 이벤트 데이터를 가져오는 함수
 * @param {string} weatherEventId - 가져올 이벤트의 ID
 * @returns {Promise<Object|null>} - 이벤트 데이터 반환 (없으면 null)
 */
export const fetchWeatherEvent = async (weatherEventId) => {
  if (!weatherEventId) return null;

  try {
    const eventRef = doc(db, 'events', weatherEventId);
    const eventSnap = await getDoc(eventRef);

    if (eventSnap.exists()) {
      return { id: eventSnap.id, ...eventSnap.data() };
    } else {
      console.warn(`Weather event with ID ${weatherEventId} not found.`);
      return null;
    }
  } catch (error) {
    console.error('Error fetching weather event:', error);
    return null;
  }
};
