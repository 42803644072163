// locationUtils.js

import { buildLocationData, getGeocode } from './GeoCoding';

const DEFAULT_TIMEOUT = 10000; // 10초 타임아웃 설정
const DEFAULT_MAX_AGE = 60000; // 1분 캐시된 위치 정보 사용

export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser.'));
      return;
    }

    const options = {
      enableHighAccuracy: true, // 높은 정확도 요청
      timeout: DEFAULT_TIMEOUT,
      maximumAge: DEFAULT_MAX_AGE,
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            reject(new Error('User denied the request for Geolocation.'));
            break;
          case error.POSITION_UNAVAILABLE:
            reject(new Error('Location information is unavailable.'));
            break;
          case error.TIMEOUT:
            reject(new Error('The request to get user location timed out.'));
            break;
          default:
            reject(new Error('An unknown error occurred.'));
            break;
        }
      },
      options
    );
  });
};
export const createTownId = async ({ latitude, longitude }) => {
  const geocodeResult = await getGeocode(latitude, longitude, 'en');
  const locationData = buildLocationData(geocodeResult);
  const province = locationData.province;
  const city = locationData.city;
  const district = locationData.district;
  const placeId = locationData.placeId;
  const parts = [];

  if (province) parts.push(province);
  if (city) parts.push(city);
  if (district) parts.push(district);

  // 한글 포함 문자열 정제: 문자, 숫자, 하이픈만 남김
  const locationKey = parts
    .join('-')
    .replace(/\s+/g, '') // 공백 제거
    //.replace(/[^\p{L}\p{N}-]/gu, '') // 문자(한글 포함), 숫자, 하이픈만 허용
    .toLowerCase();

  // 위도 경도는 소수점 3자리까지
  // const latLngPart = `${latitude.toFixed(3)}_${longitude.toFixed(3)}`;
  // return `${locationKey}_${latLngPart}`;
  return `${locationKey}`;
};
