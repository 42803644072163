import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  ListItemIcon,
  useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  setSearchCategory,
  setSearchCategoryPath,
  setSearchKeyword,
} from './Redux/reducers/searchReducer';
import moment from 'moment';
import useUserActivity from './useUserActivity';
import useStoreUserActivity from './Stores/useStoreUserActivity';
import { FaSearch, FaMapMarkerAlt } from 'react-icons/fa';
import { useTheme, alpha, styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { db } from './firebaseConfig'; // Firestore 설정 파일 import
import { collection, getDocs } from 'firebase/firestore';
import { fetchWeatherEvents } from './api/WeatherAPI';
import {
  getGeocode,
  buildLocationData,
  getAddressList,
  getNearbyTownsGoogle,
} from './Utils/GeoCoding';

import { useNavigate } from 'react-router-dom';
import SearchBox from './NavBar/SearchBox';
import useOutsideClick from './useOutsideClick';
import { CustomIconButton, CustomNavLink } from './Common/CumtomIconButton';
import {
  SearchContainer,
  SearchIconWrapper,
  StyledInputBase,
} from './NavBar/Search';
import FriendonLogo from './FriendonLogo';
import FriendonNavbar from './FriendonNavbar';
import { getMyIP } from './api/getMyIP';
import { getMyLocation } from './api/getMyLocation';
import LocationSelectModal from './Utils/LocationSelectModal';
import SnowEffect3D from './Weather/SnowEffect3D';
import RainEffect3D from './Weather/RainEffect3D';
import ClearCloudEffect3D from './Weather/ClearCloudEffect3D';
import { getStoredLanguage, changeLanguage } from './i18nUtils';
import {
  updateSearchFrequency,
  calculateNewRank,
  updateSearchRank,
  updateAllRanks,
  getTop20SearchRanks,
} from './api/SearchRankAPI';
import { createTownId } from './Utils/LocationUtils';
import { getRecommendedTowns } from './api/getRecommendedTowns';
import {
  convertCountryNameToCode,
  getCountryCodeFromLanguage,
} from './Utils/i18nToCountryCodeMap';
import WeatherEventTitle from './Components/WeatherEventTitle';
import TownSelectorBox from './Components/TownSelectorBox';
import WeatherEventSelect from './Components/WeatherEventSelect';
import SearchBarWithKeyword from './Components/SearchBarWithKeyword';
import PopularSearchTermList from './Components/PopularSearchTermList';
import { SkeletonProductGrid } from './Skeleton/SkeletonProductGrid';
import { SkeletonFriendonHome } from './Skeleton/SkeletonFriendonHome';

const FriendonHome = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeLanguage, setActiveLanguage] = useState(getStoredLanguage());
  const [selectedTown, setSelectedTown] = useState('');
  const [weatherEvents, setWeatherEvents] = useState([]);
  const [selectedDropdownEvent, setSelectedDropdownEvent] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [searchCategory, setSearchCategory] = useState('used');
  const [loading, setLoading] = useState(true);
  const [currentLocation, setCurrentLocation] = useState('');
  const [locationName, setLocationName] = useState('');
  const [locationError, setLocationError] = useState('');
  const [townId, setTownId] = useState('');
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [realTimeSearches, setRealTimeSearches] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recentSearches, setRecentSearches] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const searchInputRef = useRef();
  const popupRef = useRef();
  const selectRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState(160); // 기본 최소 너비 설정
  const currentUser = useSelector((state) => state.auth.currentUser);
  const darkMode = useSelector((state) => state.app.darkMode);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [filteredTowns, setFilteredTowns] = useState([]);

  const {
    logViewedProduct,
    logPurchasedProduct,
    logSearchedKeyword,
    logSearchedCategory,
    logSearchedBrand,
  } = useUserActivity(currentUser?.uid);
  const {
    logViewedStoreProduct,
    logPurchasedStoreProduct,
    logSearchedStoreKeyword,
    logSearchedStoreCategory,
  } = useStoreUserActivity(currentUser?.uid);

  useEffect(() => {
    const fetchRecommendedTowns = async () => {
      const lang = i18n.language || 'en';
      const countryCode = getCountryCodeFromLanguage(lang);
      const recommendedTowns = await getRecommendedTowns(countryCode);

      setFilteredTowns(recommendedTowns);
    };

    fetchRecommendedTowns();
  }, []);

  // 외부 클릭 시 SearchBox 닫기
  useOutsideClick(popupRef, () => setShowSearchBox(false));
  // i18n 변경 반영
  useEffect(() => {
    if (i18n.language !== activeLanguage.split('-')[0]) {
      changeLanguage(activeLanguage); // 내부적으로 baseLang 처리됨
    }
  }, [activeLanguage, i18n]);

  // i18n 이벤트 리스너로 변경 반영
  useEffect(() => {
    const handleLanguageChanged = (lng) => {
      setActiveLanguage(lng); // full language code로 반영됨
    };
    i18n.on('languageChanged', handleLanguageChanged);
    return () => i18n.off('languageChanged', handleLanguageChanged);
  }, [i18n]);

  // showSearchBox가 true가 되면 SearchBox에 포커스를 줌
  useEffect(() => {
    console.log('SearchBox 상태 변경:', showSearchBox);
    if (showSearchBox && popupRef.current) {
      popupRef.current.focus();
    }
  }, [showSearchBox]);

  useEffect(() => {
    const fetchLocationAndSetState = async (latitude, longitude) => {
      try {
        setCurrentLocation({ latitude, longitude });

        const lang = i18n.language || 'en';
        const geocodeResult = await getGeocode(latitude, longitude, lang);

        if (!geocodeResult) {
          console.warn('❗️Geocode 실패: fallback 위치로 대체');
          await applyFallbackLocation();
          return;
        }

        const locationData = buildLocationData(geocodeResult);
        const townName =
          locationData.district ||
          locationData.sublocality ||
          locationData.city;

        setLocationName(townName);
        setSelectedTown({
          country: locationData.country,
          name: townName,
          province: locationData.province,
          city: locationData.city,
          district: locationData.district,
          latitude,
          longitude,
        });

        const townId = await createTownId({ latitude, longitude });
        setTownId(townId);
      } catch (err) {
        console.error('🔥 fetchLocationAndSetState 예외:', err);
        await applyFallbackLocation();
      }
    };

    const applyFallbackLocation = async () => {
      const lang = i18n.language || 'en';
      const countryCode = getCountryCodeFromLanguage(lang);
      const recommendedTowns = await getRecommendedTowns(countryCode);

      if (recommendedTowns.length > 0) {
        const fallback = recommendedTowns[0];
        setSelectedTown(fallback);
        setLocationName(fallback.district || fallback.city);
        const fallbackTownId = await createTownId({
          latitude: fallback.latitude,
          longitude: fallback.longitude,
        });
        setTownId(fallbackTownId);
      } else {
        console.warn('⚠️ fallback 지역이 비어 있습니다.');
      }
    };

    const initializeLocationAndEvents = async () => {
      try {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            await fetchLocationAndSetState(latitude, longitude);
            setLoading(false);
          },
          async (error) => {
            console.error('📍 Geolocation 권한 실패:', error);
            setLocationError(t('location_permission_denied'));
            try {
              const ipAddress = await getMyIP();
              const ipLocation = await getMyLocation(ipAddress);
              await fetchLocationAndSetState(ipLocation.lat, ipLocation.lon);
            } catch (ipError) {
              console.error('🌐 IP 위치 탐색 실패:', ipError);
              await applyFallbackLocation();
            }
            setLoading(false);
          },
          {
            timeout: 10000,
            maximumAge: 0,
            enableHighAccuracy: true,
          }
        );

        const sortedEvents = await fetchWeatherEvents();
        setWeatherEvents(sortedEvents);
        setCurrentEvent(sortedEvents[0] || null);
        setSelectedDropdownEvent(sortedEvents[0] || null);
      } catch (error) {
        console.error('⚠️ 위치 및 이벤트 초기화 실패:', error);
        setLocationError(t('location_permission_denied'));
        setLoading(false);
      }
    };

    initializeLocationAndEvents();
    fetchTopSearchRanks();
  }, [t]);

  useEffect(() => {
    fetchTopSearchRanks();
  }, [townId]); // ✅ townId가 바뀔 때마다 호출

  // 가장 긴 이벤트 이름의 너비를 계산하는 함수
  useEffect(() => {
    if (selectRef.current) {
      const tempDiv = document.createElement('div');
      tempDiv.style.position = 'absolute';
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.whiteSpace = 'nowrap';
      tempDiv.style.fontSize = '16px'; // MUI 기본 폰트 크기
      document.body.appendChild(tempDiv);

      let maxWidth = 160; // 최소 너비
      weatherEvents.forEach((event) => {
        tempDiv.innerText = event.name[i18n.language] || '이벤트';
        maxWidth = Math.max(maxWidth, tempDiv.offsetWidth + 100); // 추가 여백 포함
      });

      document.body.removeChild(tempDiv);
      setMaxWidth(maxWidth);
    }
  }, [weatherEvents, i18n.language]);
  // 5초마다 상단 타이틀 이벤트만 변경 (반복적으로 순환)
  useEffect(() => {
    if (weatherEvents.length > 0) {
      const interval = setInterval(() => {
        setCurrentEventIndex(
          (prevIndex) => (prevIndex + 1) % weatherEvents.length
        );
        setCurrentEvent(
          weatherEvents[(currentEventIndex + 1) % weatherEvents.length]
        );
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [weatherEvents, currentEventIndex]);

  function fetchTopSearchRanks() {
    getTop20SearchRanks(townId).then((topSearchRanks) => {
      const realTimeSearches = topSearchRanks.map((rank) => ({
        currentRank: rank.currentRank,
        keyword: rank.keyword,
        status: rank.status,
      }));
      setRealTimeSearches(realTimeSearches);
      console.log('Updated search ranks:', realTimeSearches);
    });
  }
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleSearchKeyword = async (keyword) => {
    if (typeof keyword !== 'string') {
      console.warn('검색어가 문자열이 아닙니다:', keyword);
      return;
    }

    const trimmedKeyword = keyword.trim();

    // 1. 빈 문자열이거나 공백만 있을 경우 무시
    if (!trimmedKeyword || trimmedKeyword.length === 0) return;

    // 2. 너무 짧은 검색어 (예: 한 글자 이하)는 검색하지 않도록 제한
    if (trimmedKeyword.length < 2) {
      console.warn('검색어가 너무 짧습니다:', trimmedKeyword);
      return;
    }

    // 3. 동일한 검색어를 중복 실행 방지
    //if (trimmedKeyword === searchQuery) return;
    //실시간 검색어 순위 기록
    await updateSearchFrequency(trimmedKeyword, townId);
    await updateAllRanks(townId);
    const newRank = calculateNewRank(trimmedKeyword, townId); // 새로운 순위 계산 함수
    updateSearchRank(trimmedKeyword, newRank, townId);
    //
    setSearchQuery(trimmedKeyword);
    dispatch(setSearchKeyword(trimmedKeyword));

    const latitude = currentLocation?.latitude || 0;
    const longitude = currentLocation?.longitude || 0;
    const eventId = selectedDropdownEvent?.id || '';

    // 4. 이동
    navigate(
      `/product_search_results?latitude=${latitude}&longitude=${longitude}&keyword=${encodeURIComponent(
        trimmedKeyword
      )}&weatherEventId=${eventId}`
    );

    // 5. 최근 검색어 저장 (중복 제거 + 20개 제한)
    setRecentSearches((prevSearches) => {
      const updatedSearches = [
        trimmedKeyword,
        ...prevSearches.filter((item) => item !== trimmedKeyword),
      ];
      const slicedSearches = updatedSearches.slice(0, 20);
      try {
        localStorage.setItem('recentSearches', JSON.stringify(slicedSearches));
      } catch (err) {
        console.error('로컬스토리지 저장 실패:', err);
      }
      return slicedSearches;
    });

    // 6. 사용자 로그 기록
    if (currentUser?.uid) {
      try {
        logSearchedKeyword(trimmedKeyword);
        logSearchedStoreKeyword(currentUser.uid, trimmedKeyword);
      } catch (err) {
        console.error('검색 기록 로그 실패:', err);
      }
    }
  };
  const handleFocus = () => {
    setShowSearchBox(true);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: isMobile ? '10vh' : '20vh',
      }}
    >
      <Box
        to="/home"
        sx={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          //marginLeft: isMobile ? '10px' : '20px', // ✅ 모바일에서 좌측 여백 감소
        }}
        onClick={(event) => {
          navigate('/home');
        }}
      >
        <FriendonLogo isMainWindow={true} />
      </Box>
      {loading ? (
        <SkeletonFriendonHome /> //<CircularProgress />
      ) : (
        <>
          <WeatherEventTitle
            currentEvent={currentEvent}
            locationName={locationName}
            i18n={i18n}
            t={t}
            theme={theme}
          />

          <Box
            display="flex"
            flexDirection="row"
            gap={isMobile ? 1.5 : 2}
            mb={2}
            width="100%"
            maxWidth={isMobile ? '100%' : 1000}
            alignItems="center"
            mt={isMobile ? '20px' : '30px'}
            px={{ xs: 2, sm: 3, md: 0 }}
          >
            {/* <TownSelectorBox
              selectedTownName={selectedTown?.name || t('select_location')}
              onClick={() => setLocationModalOpen(true)}
              minWidth={isMobile ? '50%' : 160}
            />

            <WeatherEventSelect
              events={weatherEvents}
              selectedEventId={selectedDropdownEvent?.id || ''}
              onSelect={(id) => {
                const selected = weatherEvents.find((e) => e.id === id);
                setSelectedDropdownEvent(selected || null);
              }}
              language={i18n.language}
              height={60}
              minWidth={isMobile ? '50%' : 180}
            /> */}

            <TownSelectorBox
              selectedTownName={selectedTown?.name || t('select_location')}
              onClick={() => setLocationModalOpen(true)}
              minWidth={isMobile ? 'calc(50% - 4px)' : 230}
            />

            <WeatherEventSelect
              events={weatherEvents}
              selectedEventId={selectedDropdownEvent?.id || ''}
              onSelect={(id) => {
                const selected = weatherEvents.find((e) => e.id === id);
                setSelectedDropdownEvent(selected || null);
              }}
              language={i18n.language}
              height={60}
              minWidth={isMobile ? 'calc(50% - 4px)' : 230}
            />

            {!isMobile && (
              <SearchBarWithKeyword
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                onSearch={handleSearchKeyword}
                onFocus={handleFocus}
                showSearchBox={showSearchBox}
                setShowSearchBox={setShowSearchBox}
                townId={townId}
                popupRef={popupRef}
                theme={theme}
              />
            )}
          </Box>

          {isMobile && (
            <Box sx={{ width: '100%', px: 2 }}>
              <SearchBarWithKeyword
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                onSearch={handleSearchKeyword}
                onFocus={handleFocus}
                showSearchBox={showSearchBox}
                setShowSearchBox={setShowSearchBox}
                townId={townId}
                popupRef={popupRef}
                theme={theme}
                sx={{ width: '100%' }}
              />
            </Box>
          )}

          <PopularSearchTermList
            searchTerms={realTimeSearches}
            onSelectKeyword={handleSearchKeyword}
            isMobile={isMobile}
            t={t}
          />
        </>
      )}
      {locationModalOpen && (
        <LocationSelectModal
          open={locationModalOpen}
          onClose={() => setLocationModalOpen(false)}
          onSelectLocation={async (selected) => {
            // alert(JSON.stringify(selected));
            const townName =
              selected.district || selected.city || selected.province || '';
            setSelectedTown({
              name: townName,
              latitude: selected.latitude,
              longitude: selected.longitude,
            });
            setCurrentLocation({
              latitude: selected.latitude,
              longitude: selected.longitude,
            });
            const townId = await createTownId({
              latitude: selected.latitude,
              longitude: selected.longitude,
            });

            setTownId(townId);
            setLocationName(townName);
            setLocationModalOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default FriendonHome;
