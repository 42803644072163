import {
  Box,
  Paper,
  Grow,
  Stack,
  IconButton,
  Fab,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Upload from '@mui/icons-material/CloudUpload';
import Chat from '@mui/icons-material/Chat';
import StoreIcon from '@mui/icons-material/Store';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const HomeFloatingMenu = ({ open, handleUpload, menuRef }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  // 🔹 반응형 위치 조절
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // width < 600px
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 600px ~ 960px
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // width >= 960px

  return (
    <Box
      ref={menuRef}
      sx={{
        position: 'fixed',
        bottom: isMobile ? 64 : isTablet ? 72 : 64, // 📌 화면 크기별 위치 조정
        right: isMobile ? 16 : isTablet ? 24 : 128, // 📌 화면 크기별 위치 조정
        zIndex: 1200,
      }}
    >
      {/* 팝업 메뉴 */}
      <Grow in={open} timeout={300}>
        <Paper
          elevation={4}
          sx={{
            position: 'absolute',
            bottom: isMobile ? 70 : isTablet ? 90 : 110, // 📌 Fab 버튼과 거리 조정
            right: 0,
            minWidth: 'auto',
            width: 'max-content',
            display: open ? 'flex' : 'none',
            flexDirection: 'column',
            gap: 1,
            p: 2,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[6],
            transition: theme.transitions.create(['all'], {
              duration: theme.transitions.duration.short,
              easing: theme.transitions.easing.easeInOut,
            }),
          }}
        >
          <Stack spacing={1}>
            {[
              {
                icon: <Upload sx={{ mr: 1 }} />,
                text: t('uploadUsedProduct'),
                link: '/upload',
                color: 'primary',
              },
              {
                icon: <Chat sx={{ mr: 1 }} />,
                text: t('uploadStoreProduct'),
                link: '/product_upload',
                color: 'secondary',
              },
              {
                icon: <StoreIcon sx={{ mr: 1 }} />,
                text: t('myTown.title'),
                link: '/settings/mytown',
                color: 'info',
              },
            ].map(({ icon, text, link, color }, index) => (
              <IconButton
                key={index}
                component={RouterLink}
                to={link}
                color={color}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'flex-start',
                  px: 2,
                }}
              >
                {icon} {text}
              </IconButton>
            ))}
          </Stack>
        </Paper>
      </Grow>

      {/* Fab 버튼 */}
      <Fab
        onClick={handleUpload}
        color="primary"
        variant="extended"
        sx={{
          position: 'relative',
          background: `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
          color: theme.palette.primary.contrastText,
          px: 3,
          borderRadius: 50,
          '&:hover': {
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
            boxShadow: theme.shadows[8],
            transform: 'scale(1.05)',
          },
          transition: theme.transitions.create(['transform', 'box-shadow'], {
            duration: theme.transitions.duration.shortest,
          }),
        }}
      >
        {open ? (
          <CloseIcon />
        ) : (
          <>
            <AddIcon sx={{ mr: 1 }} /> {t('uploadProduct')}
          </>
        )}
      </Fab>
    </Box>
  );
};

export default HomeFloatingMenu;
