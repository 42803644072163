import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import {
  getGeocode,
  buildLocationData,
  getAddressList,
  searchTownByKeyword,
} from '../Utils/GeoCoding';
import { getRecommendedTowns } from '../api/getRecommendedTowns';
import {
  convertCountryNameToCode,
  getCountryCodeFromLanguage,
} from './i18nToCountryCodeMap';
import { getMyIP } from '../api/getMyIP';
import { getMyLocation } from '../api/getMyLocation';

const LocationSelectModal = ({ open, onClose, onSelectLocation }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');

  const [ipLocation, setIpLocation] = useState(null);
  const [locationError, setLocationError] = useState('');

  const [filteredTowns, setFilteredTowns] = useState([]);

  useEffect(() => {
    const fetchRecommendedTowns = async () => {
      if (!ipLocation) return;
      const countryCode = convertCountryNameToCode(ipLocation.country);
      const recommendedTowns = await getRecommendedTowns(countryCode);
      setFilteredTowns(recommendedTowns);
    };

    fetchRecommendedTowns();
  }, [ipLocation]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            const lang = i18n.language || 'en';
            const geocodeResult = await getGeocode(latitude, longitude, lang);

            if (!geocodeResult) {
              setIpLocation(null);
              const recommendedTowns = await getRecommendedTowns(
                ipLocation?.country || 'en'
              );
              setFilteredTowns(recommendedTowns);
              return;
            }

            const locationData = buildLocationData(geocodeResult);

            const currentTown = {
              country: locationData.country || '',
              province: locationData.province || '',
              city: locationData.city || '',
              district: locationData.district || '',
              fullname:
                `${locationData.province} ${locationData.city} ${locationData.district}`.trim(),
              latitude,
              longitude,
            };

            setIpLocation(currentTown);
          },
          async (error) => {
            console.warn('📍 Geolocation 권한 실패:', error);
            setLocationError(t('error.location_permission_denied'));

            setIpLocation(null);

            try {
              const ipAddress = await getMyIP();
              const ipLocation = await getMyLocation(ipAddress);
              const countryCode = convertCountryNameToCode(ipLocation.country);
              const recommendedTowns = await getRecommendedTowns(countryCode);
              setFilteredTowns(recommendedTowns);
            } catch (ipError) {
              console.error('🌐 IP 위치 탐색 실패:', ipError);
              const lang = i18n.language || 'en';
              const countryCode = getCountryCodeFromLanguage(lang);
              const recommendedTowns = await getRecommendedTowns(countryCode);
              setFilteredTowns(recommendedTowns);
            }
          },
          {
            timeout: 10000,
            maximumAge: 0,
            enableHighAccuracy: true,
          }
        );
      } catch (e) {
        console.error('🚨 예외 발생:', e);
        setIpLocation(null);
        const lang = i18n.language || 'en';
        const countryCode = getCountryCodeFromLanguage(lang);
        const recommendedTowns = await getRecommendedTowns(countryCode);
        setFilteredTowns(recommendedTowns);
      }
    };

    fetchLocation();
  }, [t]);

  const handleSearchChange = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.trim().length > 1) {
      await handleKeywordLocation(term); // 키워드 검색 실행
    } else {
      const recommendedTowns = await getRecommendedTowns(
        ipLocation?.country || 'en'
      );
      setFilteredTowns(recommendedTowns); // 입력이 짧으면 추천 동네 보여주기
    }
  };

  const handleMyLocation = async () => {
    if (!ipLocation) {
      const recommendedTowns = await getRecommendedTowns(
        ipLocation.country || 'en'
      );
      setFilteredTowns(recommendedTowns);
      return;
    }

    const lang = i18n.language || 'en';
    const addressList = await getAddressList(
      ipLocation.latitude,
      ipLocation.longitude,
      lang
    );

    if (!addressList || addressList.length === 0) {
      const recommendedTowns = await getRecommendedTowns(
        ipLocation.country || 'en'
      );
      setFilteredTowns(recommendedTowns);
      return;
    }

    const enrichedList = addressList
      .map((result) => {
        const extracted = buildLocationData(result);
        return {
          province: extracted.province || '',
          city: extracted.city || '',
          district: extracted.district || '',
          fullname:
            `${extracted.province} ${extracted.city} ${extracted.district}`.trim(),
          latitude: extracted.latitude,
          longitude: extracted.longitude,
        };
      })
      .filter((addr) => addr.district);

    const uniqueList = Array.from(
      new Map(enrichedList.map((item) => [item.fullname, item])).values()
    );

    if (uniqueList.length === 0) {
      const recommendedTowns = await getRecommendedTowns(
        ipLocation.country || 'en'
      );
      setFilteredTowns(recommendedTowns);
    } else {
      setFilteredTowns(uniqueList);
    }
  };

  const handleKeywordLocation = async (keyword) => {
    const lang = i18n.language || 'en';
    try {
      const addressList = await searchTownByKeyword(keyword, lang);

      const enrichedList = addressList.map((result) => {
        const extracted = buildLocationData(result);
        return {
          province: extracted.province || '',
          city: extracted.city || '',
          district: extracted.district || '',
          fullname:
            `${extracted.province} ${extracted.city} ${extracted.district}`.trim(),
          latitude: extracted.latitude,
          longitude: extracted.longitude,
        };
      });

      setFilteredTowns(enrichedList);
    } catch (err) {
      console.error('📍 키워드 기반 위치 검색 실패:', err);
    }
  };
  const handleSelectTown = async (town) => {
    if (!town || !town.latitude || !town.longitude) return;

    onSelectLocation(town);
    onClose();
  };
  const handleClose = () => {
    setSearchTerm('');
    setFilteredTowns([]);
    setIpLocation(null);
    setLocationError('');
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {t('select_location')}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <TextField
            fullWidth
            placeholder={t('search_town_placeholder')}
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: <FaSearch style={{ marginRight: 8 }} />,
            }}
          />
        </Box>

        <Button
          fullWidth
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontWeight: 'bold',
            mb: 2,
            '&:hover': { backgroundColor: theme.palette.primary.dark },
          }}
          onClick={() => ipLocation && handleMyLocation(ipLocation)}
        >
          {t('use_my_location')}
        </Button>

        {locationError && (
          <Typography
            variant="body2"
            color="error"
            sx={{
              textAlign: 'center',
              fontWeight: 'medium',
            }}
          >
            📍 {locationError}
          </Typography>
        )}
        <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
          {t('recommended_locations')}
        </Typography>
        <List>
          {filteredTowns.map((town, index) => (
            <ListItem
              key={index}
              button
              sx={{
                borderBottom: '1px solid #ddd',
                cursor: 'pointer',
                '&:hover': { backgroundColor: '#f5f5f5' },
              }}
              onClick={() => handleSelectTown(town)}
            >
              {`${town.province} ${town.city} ${town.district}`}
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationSelectModal;
