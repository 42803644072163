import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Use your custom ThemeContext
import { useTranslation } from 'react-i18next';

const WeatherEventDisplay = ({ weatherEvent, isEventActive }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  if (!weatherEvent || !weatherEvent.name) {
    return null; // weatherEvent가 없거나 name이 배열이 아니면 렌더링하지 않음
  }

  return (
    // <Box
    //   sx={{
    //     px: 0.0,
    //     py: 0.5,
    //     gap: 0.5,
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'flex-start',
    //     backgroundColor: isEventActive ? '2px solid #76FF03' : 'none',
    //     borderRadius: isEventActive ? '8px' : '0px',
    //   }}
    // >

    <Box
      sx={{
        //position: 'absolute',
        display: 'inline-flex',
        color: isEventActive
          ? theme.palette.common.white
          : theme.palette.text.secondary,
        backgroundColor: isEventActive
          ? theme.palette.success.main
          : theme.palette.primary.paper,
        borderRadius: theme.shape.borderRadius,
        px: isEventActive ? 1.0 : 0,
        py: 0.5,
        alignItems: 'center',
        gap: 1.0,
      }}
    >
      <img
        src={weatherEvent.iconUrl}
        alt={weatherEvent.name[i18n.language]}
        style={{ width: 20, height: 20, borderRadius: '50%' }}
      />
      <Typography variant="body2">
        {t(
          isEventActive
            ? 'weather.discount_applied'
            : 'weather.discount_not_applied',
          {
            name: weatherEvent.name[i18n.language],
            discount: weatherEvent.discount,
          }
        )}
      </Typography>
    </Box>
  );
};
export default WeatherEventDisplay;
