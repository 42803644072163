import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { MdImageNotSupported } from 'react-icons/md'; // 기본 아이콘 (아이콘 없을 때 사용)
import { getFileExtension } from './FileHelper';

// Styled Components
const StyledImage = styled('img')({
  width: 40,
  height: 40,
  borderRadius: '50%',
  objectFit: 'cover',
});

const StyledSvg = styled('img')({
  width: 40,
  height: 40,
});

const IconHelper = ({ iconUrl, iconName, theme }) => {
  const extension = iconUrl ? getFileExtension(iconUrl) : null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {iconUrl ? (
        extension === 'svg' ? (
          <StyledSvg src={iconUrl} alt="icon" />
        ) : (
          <StyledImage src={iconUrl} alt="icon" />
        )
      ) : (
        <MdImageNotSupported size={40} color={theme.palette.primary.main} />
      )}
    </Box>
  );
};

export default IconHelper;
