// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   Box,
//   Chip,
//   styled,
//   IconButton,
//   useMediaQuery,
// } from '@mui/material';
// import { useSelector } from 'react-redux';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import StarIcon from '@mui/icons-material/Star';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import StorefrontIcon from '@mui/icons-material/Storefront';
// import { useTranslation } from 'react-i18next';
// import { useTheme } from '@mui/material/styles';
// import StoreRatings from './StoreRatings';

// // 스타일 컴포넌트 생성
// const StyledCard = styled(Card)(({ theme }) => ({
//   borderRadius: '8px',
//   margin: '5px',
//   padding: '5px',
//   boxShadow: theme.shadows[3],
//   overflow: 'visible',
// }));

// const StyledCardMedia = styled(CardMedia)({
//   height: 'auto',
//   paddingTop: '56.25%', // 16:9 비율 유지
//   objectFit: 'cover',
//   backgroundPosition: 'center',
//   borderRadius: '8px',
// });

// const formatTime = (timestamp) => {
//   if (!timestamp || !timestamp.toDate) return 'N/A';
//   const date = timestamp.toDate();
//   const hours = date.getHours().toString().padStart(2, '0');
//   const minutes = date.getMinutes().toString().padStart(2, '0');
//   return `${hours}:${minutes}`;
// };

// // const isOpen = (startTime, endTime) => {
// //   const now = new Date();
// //   const start = new Date(startTime.toDate());
// //   const end = new Date(endTime.toDate());
// //   start.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
// //   end.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());

// //   return now >= start && now <= end;
// // };
// const isOpen = (startTime, endTime) => {
//   if (!startTime || !endTime) {
//     console.error('startTime or endTime is undefined:', { startTime, endTime });
//     return false;
//   }

//   try {
//     const now = new Date();
//     const start = new Date(startTime.toDate ? startTime.toDate() : startTime); // Handle both Timestamp and Date
//     const end = new Date(endTime.toDate ? endTime.toDate() : endTime);
//     start.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
//     end.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());

//     return now >= start && now <= end;
//   } catch (error) {
//     console.error('Error in isOpen function:', error);
//     return false;
//   }
// };

// const Store = ({ store, horizontal }) => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [statusLabel, setStatusLabel] = useState('');

//   useEffect(() => {
//     if (!store) return;

//     const now = new Date();
//     const day = now.getDay();
//     const open =
//       day === 0 || day === 6
//         ? isOpen(store.weekendStartTime, store.weekendEndTime)
//         : isOpen(store.weekdayStartTime, store.weekdayEndTime);

//     setStatusLabel(open ? 'Open' : 'Closed');
//   }, [store]);

//   const handleGoToCart = (store) => {
//     navigate(`/cartlist/${currentUser.uid}/${store.id}`);
//   };

//   return (
//     <StyledCard
//       sx={{
//         maxWidth: '100%',
//         m: '5px',
//         p: '5px',
//         boxShadow: 3,
//         borderRadius: 2,
//         overflow: 'hidden',
//         '@media (max-width: 600px)': {
//           m: '2px',
//           p: '2px',
//         },
//       }}
//     >
//       <CardActionArea component={Link} to={`/store_home/${store.id}`}>
//         {horizontal && isMobile ? (
//           <Box display="flex" flexDirection="row" sx={{ height: '150px' }}>
//             <Box
//               sx={{
//                 width: '40%',
//                 height: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <StyledCardMedia
//                 component="img"
//                 image={
//                   store.mainImages && store.mainImages.length > 0
//                     ? store.mainImages[0]
//                     : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
//                 }
//                 sx={{
//                   width: '100%',
//                   height: '100%',
//                   backgroundImage: `url(${
//                     store.mainImages && store.mainImages.length > 0
//                       ? store.mainImages[0]
//                       : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
//                   })`,
//                   backgroundSize: 'cover',
//                   backgroundPosition: 'center',
//                 }}
//                 alt={t('storeImageAlt', { storeName: store.storeName })}
//               />
//             </Box>
//             <CardContent
//               sx={{
//                 width: '60%',
//                 padding: '8px',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 marginTop: 'auto',
//                 marginBottom: 'auto',
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {store.storeName}
//               </Typography>

//               <Typography
//                 variant="body2"
//                 color="text.secondary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {store.description}
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'flex-end',
//                   justifyContent: 'flex-end',
//                   gap: 2,
//                   mt: 'auto',
//                 }}
//               >
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <StarIcon color="error" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.likes}
//                   </Typography>
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <VisibilityIcon color="action" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.views}
//                   </Typography>
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <ChatIcon color="primary" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.chats}
//                   </Typography>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Box>
//         ) : (
//           <>
//             <StyledCardMedia
//               component="img"
//               image={
//                 store.mainImages && store.mainImages.length > 0
//                   ? store.mainImages[0]
//                   : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
//               }
//               sx={{
//                 height: 0,
//                 paddingTop: '56.25%',
//                 backgroundImage: `url(${
//                   store.mainImages && store.mainImages.length > 0
//                     ? store.mainImages[0]
//                     : process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
//                 })`,
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center',
//                 borderRadius: '8px',
//                 '@media (max-width: 600px)': {
//                   height: '150px',
//                   paddingTop: 0,
//                 },
//               }}
//               alt={t('storeImageAlt', { storeName: store.storeName })}
//             />
//             <CardContent
//               sx={{
//                 paddingTop: '16px',
//                 paddingBottom: '16px',
//               }}
//             >
//               <Typography
//                 gutterBottom
//                 variant="h6"
//                 component="div"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {store.storeName}
//               </Typography>

//               <Typography
//                 variant="body2"
//                 color="text.secondary"
//                 noWrap
//                 sx={{
//                   lineHeight: '1.5',
//                   maxHeight: '3em',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                 }}
//               >
//                 {store.description}
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                   mb: 2,
//                 }}
//               >
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <StarIcon color="error" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.likes}
//                   </Typography>
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <VisibilityIcon color="action" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.views}
//                   </Typography>
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <ChatIcon color="primary" />
//                   <Typography variant="body2" sx={{ ml: 0.5 }}>
//                     {store.chats}
//                   </Typography>
//                 </Box>
//               </Box>
//             </CardContent>
//           </>
//         )}
//       </CardActionArea>
//       <Box
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'space-between',
//           my: 1,
//           paddingLeft: horizontal && isMobile ? '16px' : '2px',
//         }}
//       >
//         <Chip
//           icon={<StorefrontIcon />}
//           label={statusLabel}
//           color={statusLabel === 'Open' ? 'success' : 'error'}
//         />
//         <StoreRatings storeId={store.id} />
//         <IconButton
//           color="primary"
//           aria-label={t('addToCart')}
//           onClick={(event) => {
//             event.stopPropagation();
//             handleGoToCart(store);
//           }}
//         >
//           <ShoppingCartIcon />
//         </IconButton>
//       </Box>
//     </StyledCard>
//   );
// };

// Store.propTypes = {
//   store: PropTypes.object.isRequired,
//   horizontal: PropTypes.bool,
//   isMobile: PropTypes.bool,
// };

// export default Store;

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Chip,
  styled,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useGlobalContext } from '../useGlobalContext';
import StoreRatings from './StoreRatings';
import StoreCard from './StoreCard';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '8px',
  margin: '5px',
  padding: '5px',
  boxShadow: theme.shadows[3],
  overflow: 'visible',
}));

const StyledCardMedia = styled(CardMedia)({
  height: 'auto',
  paddingTop: '56.25%', // 16:9 비율 유지
  objectFit: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px',
});

const isOpen = (startTime, endTime) => {
  if (!startTime || !endTime) {
    console.error('startTime or endTime is undefined:', { startTime, endTime });
    return false;
  }

  try {
    const now = new Date();
    const start = new Date(startTime.toDate ? startTime.toDate() : startTime); // Handle both Timestamp and Date
    const end = new Date(endTime.toDate ? endTime.toDate() : endTime);
    start.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
    end.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());

    return now >= start && now <= end;
  } catch (error) {
    console.error('Error in isOpen function:', error);
    return false;
  }
};
const Store = ({ store, horizontal = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme(); // Access custom theme
  const { defaultAspectRatio, pcAspectRatio, mobileAspectRatio } =
    useGlobalContext();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [statusLabel, setStatusLabel] = useState('');

  useEffect(() => {
    if (!store) return;

    const now = new Date();
    const day = now.getDay();
    const open =
      day === 0 || day === 6
        ? isOpen(store.weekendStartTime, store.weekendEndTime)
        : isOpen(store.weekdayStartTime, store.weekdayEndTime);

    setStatusLabel(open ? t('storeOpen') : t('storeClosed'));
  }, [store, t]);

  const handleGoToCart = (store) => {
    navigate(`/cartlist/${currentUser.uid}/${store.id}`);
  };

  return (
    <StoreCard store={store} />
    // <Card
    //   sx={{
    //     width: '100%',
    //     maxWidth: isMobile ? '95%' : '100%',
    //     backgroundColor: theme.palette.background.paper,
    //     color: theme.palette.text.primary,
    //     overflow: 'hidden',
    //     borderRadius: 2,
    //     boxShadow: theme.shadows[3],
    //     m: 1,
    //   }}
    // >
    //   <CardActionArea component={Link} to={`/store_home/${store.id}`}>
    //     {horizontal && isMobile ? (
    //       <Box display="flex" flexDirection="row" sx={{ height: '150px' }}>

    //         <Box
    //           sx={{
    //             width: '40%',
    //             height: '100%',
    //             display: 'flex',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //             backgroundImage: `url(${
    //               store.mainImages?.[0] ||
    //               process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
    //             })`,
    //             backgroundSize: 'cover',
    //             backgroundPosition: 'center',
    //             borderRadius: '8px 0 0 8px',
    //           }}
    //         />

    //         <CardContent
    //           sx={{
    //             width: '60%',
    //             padding: 2,
    //             display: 'flex',
    //             flexDirection: 'column',
    //             justifyContent: 'center',
    //           }}
    //         >
    //           <Typography
    //             variant="h6"
    //             sx={{
    //               fontWeight: 600,
    //               color: theme.palette.text.primary,
    //               textAlign: 'left',
    //               overflow: 'hidden',
    //               whiteSpace: 'nowrap',
    //               textOverflow: 'ellipsis',
    //             }}
    //           >
    //             {store.storeName}
    //           </Typography>

    //           <Typography
    //             variant="body2"
    //             sx={{
    //               color: theme.palette.text.secondary,
    //               textAlign: 'left',
    //               overflow: 'hidden',
    //               whiteSpace: 'nowrap',
    //               textOverflow: 'ellipsis',
    //             }}
    //           >
    //             {store.description}
    //           </Typography>

    //           <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
    //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //               <StarIcon fontSize="small" color="error" />
    //               <Typography variant="body2" sx={{ ml: 0.5 }}>
    //                 {store.likes}
    //               </Typography>
    //             </Box>
    //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //               <VisibilityIcon fontSize="small" color="action" />
    //               <Typography variant="body2" sx={{ ml: 0.5 }}>
    //                 {store.views}
    //               </Typography>
    //             </Box>
    //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //               <ChatIcon fontSize="small" color="primary" />
    //               <Typography variant="body2" sx={{ ml: 0.5 }}>
    //                 {store.chats}
    //               </Typography>
    //             </Box>
    //           </Box>

    //           <Box
    //             sx={{
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'space-between',
    //               mt: 1,
    //             }}
    //           >
    //             <Chip
    //               icon={<StorefrontIcon />}
    //               label={store.isOpen ? 'Open' : 'Closed'}
    //               color={store.isOpen ? 'success' : 'error'}
    //               size="small"
    //             />
    //             <StoreRatings storeId={store.id} />
    //             <IconButton
    //               color="primary"
    //               onClick={(event) => {
    //                 event.stopPropagation();
    //                 handleGoToCart(store);
    //               }}
    //             >
    //               <ShoppingCartIcon />
    //             </IconButton>
    //           </Box>
    //         </CardContent>
    //       </Box>
    //     ) : (
    //       <>

    //         <Box
    //           sx={{
    //             width: '100%',
    //             height: '200px',
    //             backgroundImage: `url(${
    //               store.mainImages?.[0] ||
    //               process.env.REACT_APP_STORE_DEFAULT_IMAGE_URL
    //             })`,
    //             backgroundSize: 'cover',
    //             backgroundPosition: 'center',
    //             borderRadius: '8px 8px 0 0',
    //           }}
    //         />

    //         <CardContent
    //           sx={{
    //             paddingTop: 2,
    //             paddingBottom: 2,
    //           }}
    //         >
    //           <Typography
    //             variant="h6"
    //             sx={{
    //               fontWeight: 600,
    //               color: theme.palette.text.primary,
    //               textAlign: 'left',
    //               overflow: 'hidden',
    //               whiteSpace: 'nowrap',
    //               textOverflow: 'ellipsis',
    //             }}
    //           >
    //             {store.storeName}
    //           </Typography>

    //           <Typography
    //             variant="body2"
    //             sx={{
    //               color: theme.palette.text.secondary,
    //               textAlign: 'left',
    //               overflow: 'hidden',
    //               whiteSpace: 'nowrap',
    //               textOverflow: 'ellipsis',
    //             }}
    //           >
    //             {store.description}
    //           </Typography>

    //           <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
    //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //               <StarIcon fontSize="small" color="error" />
    //               <Typography variant="body2" sx={{ ml: 0.5 }}>
    //                 {store.likes}
    //               </Typography>
    //             </Box>
    //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //               <VisibilityIcon fontSize="small" color="action" />
    //               <Typography variant="body2" sx={{ ml: 0.5 }}>
    //                 {store.views}
    //               </Typography>
    //             </Box>
    //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //               <ChatIcon fontSize="small" color="primary" />
    //               <Typography variant="body2" sx={{ ml: 0.5 }}>
    //                 {store.chats}
    //               </Typography>
    //             </Box>
    //           </Box>

    //           <Box
    //             sx={{
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'space-between',
    //               mt: 1,
    //             }}
    //           >
    //             <Chip
    //               icon={<StorefrontIcon />}
    //               label={store.isOpen ? 'Open' : 'Closed'}
    //               color={store.isOpen ? 'success' : 'error'}
    //               size="small"
    //             />
    //             <StoreRatings storeId={store.id} />
    //             <IconButton
    //               color="primary"
    //               onClick={(event) => {
    //                 event.stopPropagation();
    //                 handleGoToCart(store);
    //               }}
    //             >
    //               <ShoppingCartIcon />
    //             </IconButton>
    //           </Box>
    //         </CardContent>
    //       </>
    //     )}
    //   </CardActionArea>
    // </Card>
  );
};

Store.propTypes = {
  store: PropTypes.object.isRequired,
  horizontal: PropTypes.bool,
};

export default Store;
