import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Avatar,
  Stack,
  IconButton,
  useMediaQuery,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Menu,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventIcon from '@mui/icons-material/Event';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatState } from './ProductStatus';

export default function ChatHeader({
  isOwner,
  imageUrl,
  title,
  state,
  product,
  onScheduleClick,
  onChangeStatus,
  onPhoneCallClick,
  appointments,
  onImageClick,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [status, setStatus] = useState(state);
  const [shortTitle, setShortTitle] = useState(title);
  const [productState, setProductState] = useState(
    formatState(product.state, t)
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const MOBILE_MAX_TITLE_LENGTH = 3; // 모바일에서의 최대 제목 길이
  const DESKTOP_MAX_TITLE_LENGTH = 40; // 데스크톱에서의 최대 제목 길이

  useEffect(() => {
    const maxTitleLength = isMobile
      ? MOBILE_MAX_TITLE_LENGTH
      : DESKTOP_MAX_TITLE_LENGTH;
    if (title) {
      if (title.length > maxTitleLength) {
        setShortTitle(title.substring(0, maxTitleLength) + '...');
      } else {
        setShortTitle(title);
      }
    }
  }, [title, isMobile]);

  useEffect(() => {
    setProductState(formatState(state, t));
  }, [state, t]);

  const handleStatusChange = (event) => {
    const newState = event.target.value;
    onChangeStatus(newState); // Call the function to update the status
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        padding: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {/* Left Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Avatar
          src={imageUrl}
          alt={title}
          onClick={onImageClick}
          sx={{
            width: 40,
            height: 40,
            cursor: 'pointer',
            mx: 2,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            minWidth: 0, // Ensures the box shrinks if needed
          }}
        >
          <Typography
            variant="h6"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flex: 1,
              minWidth: 0,
              color: theme.palette.text.primary,
              marginRight: theme.spacing(2),
            }}
          >
            {shortTitle}
          </Typography>
          {!isMobile && appointments && (
            <Typography
              variant="body1"
              color={theme.palette.primary.main}
              sx={{ flexShrink: 0 }}
            >
              {t('appointmentTime', { time: appointments })}
            </Typography>
          )}
        </Box>
      </Box>

      {/* Right Section */}
      <Stack direction="row" spacing={2} alignItems="center">
        <Chip
          icon={productState.icon}
          label={productState.text}
          color={productState.color}
          size="small"
          variant="outlined"
        />
        <IconButton onClick={onScheduleClick} disabled={state === 2}>
          <EventIcon />
        </IconButton>
        <IconButton onClick={onPhoneCallClick} disabled={state === 2}>
          <PhoneIcon />
        </IconButton>
        {isOwner && (
          <>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => onChangeStatus(0)}>
                {t('selling')}
              </MenuItem>
              <MenuItem onClick={() => onChangeStatus(1)}>
                {t('reserved')}
              </MenuItem>
              <MenuItem onClick={() => onChangeStatus(2)}>
                {t('sold-out')}
              </MenuItem>
            </Menu>
          </>
        )}
      </Stack>
    </Box>
  );
}
//   return (
//     <Box
//       sx={{
//         padding: '1px',
//         borderBottom: '1px solid #e0e0e0',
//         backgroundColor: '#fff',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'space-between',
//       }}
//     >
//       <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
//         <IconButton onClick={() => navigate(-1)}>
//           <ArrowBackIcon />
//         </IconButton>
//         <Avatar
//           src={imageUrl}
//           alt={title}
//           onClick={onImageClick}
//           sx={{
//             width: 40,
//             height: 40,
//             cursor: 'pointer',
//             mx: 2,
//           }}
//         />
//         <Box
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             flex: 1,
//             minWidth: 0, // Ensures the box shrinks if needed
//           }}
//         >
//           <Typography
//             variant="h6"
//             sx={{
//               overflow: 'hidden',
//               textOverflow: 'ellipsis',
//               whiteSpace: 'nowrap',
//               flex: 1,
//               minWidth: 0, // Ensures the text ellipsis works
//               marginRight: 2,
//             }}
//           >
//             {shortTitle}
//           </Typography>
//           {!isMobile && appointments && (
//             <Typography variant="body1" color="primary" sx={{ flexShrink: 0 }}>
//               {t('appointmentTime', { time: appointments })}
//             </Typography>
//           )}
//         </Box>
//       </Box>
//       <Stack direction="row" spacing={2} alignItems="center">
//         <Chip
//           icon={productState.icon}
//           label={productState.text}
//           color={productState.color}
//           size="small"
//           variant="outlined"
//         />
//         <IconButton onClick={onScheduleClick} disabled={state === 2}>
//           <EventIcon />
//         </IconButton>
//         <IconButton onClick={onPhoneCallClick} disabled={state === 2}>
//           <PhoneIcon />
//         </IconButton>
//         {isOwner && (
//           <>
//             <IconButton onClick={handleMenuClick}>
//               <MoreVertIcon />
//             </IconButton>
//             <Menu
//               anchorEl={anchorEl}
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem onClick={() => onChangeStatus(0)}>
//                 {t('selling')}
//               </MenuItem>
//               <MenuItem onClick={() => onChangeStatus(1)}>
//                 {t('reserved')}
//               </MenuItem>
//               <MenuItem onClick={() => onChangeStatus(2)}>
//                 {t('sold-out')}
//               </MenuItem>
//             </Menu>
//           </>
//         )}
//       </Stack>
//     </Box>
//   );
// }
