import i18n from 'i18next';

const supportedLanguages = ['en', 'ko', 'ja', 'zh'];

export const getStoredLanguage = () =>
  localStorage.getItem('language') || 'en-US';

export const getStoredLanguageCode = () => getStoredLanguage().split('-')[0];

export const getActiveLanguageCode = () =>
  (i18n.language || 'en').split('-')[0];

export const isSupportedLanguage = (code) => supportedLanguages.includes(code);

export const changeLanguage = (fullCode) => {
  const baseCode = fullCode.split('-')[0];
  const validBaseCode = isSupportedLanguage(baseCode) ? baseCode : 'en';

  localStorage.setItem('language', fullCode); // 사용자가 선택한 전체 코드 저장
  i18n.changeLanguage(validBaseCode); // 지원되지 않으면 기본 'en' 적용
};

export const initLanguageSetting = () => {
  const fullCode = getStoredLanguage();
  const baseCode = fullCode.split('-')[0];
  const fallback = isSupportedLanguage(baseCode) ? baseCode : 'en';
  i18n.changeLanguage(fallback);
};
