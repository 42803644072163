import { db } from '../firebaseConfig';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from 'firebase/firestore';
import { googleTranslate } from '../Utils/GoogleTranslation';

export const fetchCategories = async (parentId, lang) => {
  const q = query(
    collection(db, 'categories'),
    where('parentCategoryId', '==', parentId)
  );
  const querySnapshot = await getDocs(q);
  const categories = querySnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      name:
        typeof data.name === 'object'
          ? data.name?.[lang] || data.name?.['en']
          : data.name || '',
      description:
        typeof data.description === 'object'
          ? data.description?.[lang] || data.description?.['en']
          : data.description || '',
    };
  });

  // const translatedCategories = await Promise.all(
  //   categories.map(async (category) => {
  //     const translatedName = await googleTranslate(category.name[lang]);
  //     return { ...category, name: translatedName };
  //   })
  // );

  // return translatedCategories;
  return categories;
};

export const fetchBrands = async (parentId = '', lang) => {
  const q = query(
    collection(db, 'brands'),
    where('parentBrandId', '==', parentId)
  );
  const querySnapshot = await getDocs(q);

  const brands = querySnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      name:
        typeof data.name === 'object'
          ? data.name?.[lang] || data.name?.['en']
          : data.name || '',
      description:
        typeof data.description === 'object'
          ? data.description?.[lang] || data.description?.['en']
          : data.description || '',
    };
  });

  // 각 브랜드의 이름을 번역합니다.
  // const translatedBrands = await Promise.all(
  //   brands.map(async (brand) => {
  //     const translatedName = await googleTranslate(brand.name[i18n.language]);
  //     return { ...brand, name: translatedName };
  //   })
  // );

  // return translatedBrands;
  return brands;
};
