// useSearchFilters.js
import { useSearchParams } from 'react-router-dom';

const useSearchFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectFilter = (key, value) => {
    const params = new URLSearchParams(searchParams.toString());
    const filterHandlers = {
      // keyword: (val) => val && params.set('keyword', val),
      keyword: (val) => {
        if (val && val.trim()) {
          params.set('keyword', val.trim());
        } else {
          params.delete('keyword');
        }
      },
      priceRange: ([min, max]) => {
        params.set('minPrice', min);
        params.set('maxPrice', max);
      },
      radius: (val) => {
        if (val?.location) {
          params.set('radius', val.radius);
          params.set('latitude', val.location.lat);
          params.set('longitude', val.location.lng);
        } else if (val) {
          params.set('radius', val);
        }
      },
      category: (val) => val?.id && params.set('category', val.id),
      brand: (val) => val?.id && params.set('brand', val.id),
      weather: (val) => val?.id && params.set('weather', val.id),
      state: (val) => {
        if (Array.isArray(val) && val.length > 0) {
          params.set('states', val.join(','));
        } else {
          params.delete('states');
        }
      },
      sort: (val) => params.set('sort', val),
      default: (val) => {
        if (typeof val === 'boolean') {
          params.set(key, val);
        } else if (val === null || val === undefined || val === '') {
          params.delete(key);
        } else {
          params.set(key, val);
        }
      },
    };

    const handler = filterHandlers[key] || filterHandlers.default;
    handler(value);
    setSearchParams(params);
  };

  const getFilterParams = () => {
    const keyword = searchParams.get('keyword') || '';
    const category = searchParams.get('category') || null;
    const brand = searchParams.get('brand') || null;
    const weather = searchParams.get('weather') || null;
    const sort = searchParams.get('sort') || 'latest';
    const stateList = searchParams.get('states')
      ? searchParams.get('states').split(',').filter(Boolean)
      : [];
    const minPrice = parseInt(searchParams.get('minPrice'), 10) || 0;
    const maxPrice = parseInt(searchParams.get('maxPrice'), 10) || 100000;
    const radius = parseInt(searchParams.get('radius'), 10);
    const safeRadius = Number.isFinite(radius) ? radius : 3;
    const latitude = searchParams.get('latitude');
    const longitude = searchParams.get('longitude');

    return {
      keyword,
      category,
      brand,
      weather,
      sort,
      states: stateList,
      priceRange: [minPrice, maxPrice],
      radius: safeRadius,
      latitude,
      longitude,
    };
  };

  return {
    searchParams,
    setSearchParams,
    handleSelectFilter,
    getFilterParams,
  };
};

export default useSearchFilters;
