import React from 'react';

import { Box, Typography, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Use your custom ThemeContext

import { formatPrice } from '../Utils/PriceFormatter';

import { useTranslation } from 'react-i18next';

const WeatherPriceDisplay = ({ product, weatherEvent, isEventActive }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const discountedPrice = isEventActive
    ? Math.round(product?.price * (1 - weatherEvent?.discount / 100))
    : product?.price;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      {isEventActive ? (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            {/* 원래 가격 */}
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textDecoration: 'line-through' }}
            >
              {t('original-price')}: {formatPrice(product?.price)}원
            </Typography>

            {/* 할인된 가격 */}
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', color: 'red' }}
            >
              {t('discounted-price')}: {formatPrice(discountedPrice)}원
            </Typography>
          </Box>
        </>
      ) : (
        <>
          {product?.discountedPrice && product?.discountedPrice > 0 ? (
            <>
              <Box>
                {/* 원래 가격 */}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ textDecoration: 'line-through' }}
                >
                  {t('original-price')}: {formatPrice(product?.price)}원
                </Typography>

                {/* 할인된 가격 */}
                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1 }}>
                  {t('discounted-price')}:{' '}
                  {formatPrice(product?.discountedPrice)}원
                </Typography>

                {/* 할인 정보 */}
                {product?.discount?.discountType === 'percentage' ? (
                  <Chip
                    label={`${product?.discount?.discount}% OFF`}
                    sx={{
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.warning.light,
                      px: 1,
                      py: 0.5,
                      borderRadius: '4px',
                      fontSize: '0.8rem',
                      fontWeight: 'bold',
                      mt: 1,
                    }}
                  />
                ) : product?.discount?.discountType === 'amount' ? (
                  <Chip
                    label={`-${formatPrice(product?.discount?.discount)}원`}
                    sx={{
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.error.light,
                      px: 1,
                      py: 0.5,
                      borderRadius: '4px',
                      fontSize: '0.8rem',
                      fontWeight: 'bold',
                      mt: 1,
                    }}
                  />
                ) : null}
              </Box>
            </>
          ) : (
            <>
              {t('price')}: <strong>{formatPrice(product?.price)}원</strong>
            </>
          )}
        </>
      )}
    </Box>
  );
};
export default WeatherPriceDisplay;
