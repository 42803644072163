// import React, { useState, useEffect, useRef } from 'react';
// import {
//   Box,
//   Typography,
//   Container,
//   Divider,
//   useMediaQuery,
//   useTheme,
//   IconButton,
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import useOutsideClick from '../useOutsideClick';
// import { db, collection, getDocs, query, where } from '../firebaseConfig';
// import { useTranslation } from 'react-i18next';
// import { googleTranslate } from '../Utils/GoogleTranslation';
// import './css/ProductCategories.css';
// import './css/CategoryBox.css';
// import i18n from '../i18n';

// const ShowMode = {
//   SINGLE_LINE: 'singleLine',
//   MULTI_LINE: 'multiLine',
// };

// const ProductCategoryBox = ({
//   level,
//   categories,
//   onSelectCategory,
//   onClickCategory,
//   selectedCategoryPath,
//   expandedCategory,
//   setExpandedCategory,
// }) => {
//   const isSelected = (category) => {
//     return (
//       selectedCategoryPath[level] &&
//       selectedCategoryPath[level].id === category.id
//     );
//   };

//   return (
//     <Box
//       sx={{
//         width: level === 0 ? '250px' : '200px',
//         display: 'flex',
//         flexDirection: 'column',
//         mr: 1, // 규칙적인 간격 추가
//       }}
//     >
//       {categories.map((category) => (
//         <Box
//           key={category.id}
//           onClick={(event) => {
//             event.stopPropagation();
//             onClickCategory(category, level, event);
//           }}
//           sx={{
//             mr: 0,
//             padding: '8px 16px',
//             backgroundColor: isSelected(category) ? 'lightblue' : 'transparent',
//             color: isSelected(category) ? 'black' : 'inherit',
//             cursor: 'pointer',
//             '&:hover': {
//               backgroundColor: 'lightblue',
//               color: 'black',
//             },
//             marginBottom: '2px', // 각 박스 간의 간격
//           }}
//         >
//           {category.name[i18n.language]}
//         </Box>
//       ))}
//     </Box>
//   );
// };

// const ProductCategories = ({
//   showMode = ShowMode.SINGLE_LINE,
//   onSelectedCategoryPath,
//   onSelectCategory,
//   onClose,
// }) => {
//   const { t, i18n } = useTranslation();
//   const [categoryPath, setCategoryPath] = useState([]);
//   const [selectedCategoryPath, setSelectedCategoryPath] = useState([]);
//   const [hoveredCategories, setHoveredCategories] = useState([]);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [expandedCategory, setExpandedCategory] = useState(null);

//   const categoryRef = useRef();
//   useOutsideClick(categoryRef, () => setHoveredCategories([]));

//   const fetchCategories = async (parentId) => {
//     const q = query(
//       collection(db, 'categories'),
//       where('parentCategoryId', '==', parentId)
//     );
//     const querySnapshot = await getDocs(q);
//     const categories = querySnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));

//     // 각 카테고리의 이름을 번역합니다.
//     const translatedCategories = await Promise.all(
//       categories.map(async (category) => {
//         const translatedName = await googleTranslate(
//           category.name[i18n.language]
//         );
//         return { ...category, name: translatedName };
//       })
//     );

//     return translatedCategories;
//   };

//   useEffect(() => {
//     const loadCategories = async () => {
//       const categories = await fetchCategories('');
//       setCategoryPath([{ level: 0, categories: categories }]);
//     };
//     loadCategories();
//     return () => {
//       setCategoryPath([]);
//       setSelectedCategoryPath([]);
//       setHoveredCategories([]);
//     };
//   }, []);

//   const handleSelectCategory = async (category, level, event) => {
//     if (showMode === ShowMode.SINGLE_LINE) {
//       await buildCategory(level, category);
//       onClose();
//     } else {
//       onSelectCategory(category);
//       onSelectedCategoryPath(selectedCategoryPath);
//       onClose();
//     }
//   };

//   const handleClickCategory = async (category, level, event) => {
//     if (expandedCategory?.id === category.id) {
//       handleSelectCategory(category, level, event);
//     } else {
//       setExpandedCategory(category);
//       await buildCategory(level, category);
//     }
//   };

//   const buildCategory = async (level, category) => {
//     let newPath = categoryPath.slice(0, level + 1);
//     const subcategories = await fetchCategories(category.id);
//     if (subcategories.length > 0) {
//       newPath.push({ level: level + 1, categories: subcategories });
//     }
//     setCategoryPath(newPath);

//     const updatedSelectedCategoryPath = selectedCategoryPath.slice(
//       0,
//       level + 1
//     );
//     updatedSelectedCategoryPath[level] = category;
//     setSelectedCategoryPath(updatedSelectedCategoryPath);

//     setHoveredCategories([]);
//   };

//   const containerWidth = isMobile
//     ? '100vw'
//     : `${300 + (categoryPath.length - 1) * 200}px`;

//   return (
//     <Container
//       ref={categoryRef}
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100vh',
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         width: containerWidth,
//         backgroundColor: 'white',
//         boxShadow: 3,
//         zIndex: 1000,
//         overflowY: 'auto',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           alignItems: 'center',
//           p: 2,
//           borderBottom: '1px solid #ddd',
//         }}
//       >
//         <Typography variant="h6">{t('categories')}</Typography>
//         <IconButton onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       </Box>
//       <Divider />
//       <Box sx={{ flexGrow: 1, p: 2 }}>
//         <Box
//           sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
//         >
//           {categoryPath.map(({ categories, level }) => (
//             <Box
//               key={level}
//               sx={{
//                 width: isMobile ? '100%' : level === 0 ? '300px' : '200px',
//                 borderRight: isMobile ? 'none' : '1px solid #ddd',
//                 display: 'flex',
//                 flexDirection: 'column',
//               }}
//             >
//               <ProductCategoryBox
//                 level={level}
//                 categories={categories}
//                 onSelectCategory={(category, event) =>
//                   handleSelectCategory(category, level, event)
//                 }
//                 onClickCategory={(category, level, event) =>
//                   handleClickCategory(category, level, event)
//                 }
//                 selectedCategoryPath={selectedCategoryPath}
//                 expandedCategory={expandedCategory}
//                 setExpandedCategory={setExpandedCategory}
//               />
//             </Box>
//           ))}
//           {expandedCategory && hoveredCategories.length > 0 && (
//             <Box sx={{ mt: 2 }}>
//               {hoveredCategories.map(({ categories, level }) => (
//                 <Box
//                   key={level}
//                   sx={{
//                     width: '100%',
//                     borderBottom: '1px solid #ddd',
//                     display: 'flex',
//                     flexDirection: 'column',
//                   }}
//                 >
//                   <ProductCategoryBox
//                     level={level}
//                     categories={categories}
//                     onSelectCategory={(category, event) =>
//                       handleSelectCategory(category, level, event)
//                     }
//                     onClickCategory={(category, level, event) =>
//                       handleClickCategory(category, level, event)
//                     }
//                     selectedCategoryPath={selectedCategoryPath}
//                     expandedCategory={expandedCategory}
//                     setExpandedCategory={setExpandedCategory}
//                   />
//                 </Box>
//               ))}
//             </Box>
//           )}
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default ProductCategories;

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Container,
  Divider,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useOutsideClick from '../useOutsideClick';
import { db, collection, getDocs, query, where } from '../firebaseConfig';
import { useTranslation } from 'react-i18next';
import { googleTranslate } from '../Utils/GoogleTranslation';
import './css/ProductCategories.css';
import './css/CategoryBox.css';
import { fetchCategories } from '../api/SearchAPI';

const ShowMode = {
  SINGLE_LINE: 'singleLine',
  MULTI_LINE: 'multiLine',
};

const ProductCategoryBox = ({
  level,
  categories,
  onClickCategory,
  selectedCategoryPath,
  expandedCategory,
  setExpandedCategory,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const isSelected = (category) =>
    selectedCategoryPath[level] &&
    selectedCategoryPath[level].id === category.id;

  return (
    <Box
      sx={{
        width: level === 0 ? '250px' : '200px',
        display: 'flex',
        flexDirection: 'column',
        mr: 1,
      }}
    >
      {categories.map((category) => (
        <Box
          key={category.id}
          onClick={(event) => {
            event.stopPropagation();
            onClickCategory(category, level, event);
          }}
          sx={{
            padding: '8px 16px',
            backgroundColor: isSelected(category)
              ? theme.palette.primary.light
              : 'transparent',
            color: isSelected(category)
              ? theme.palette.primary.contrastText
              : 'inherit',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.contrastText,
            },
            mb: 0.5,
          }}
        >
          {category.name}
        </Box>
      ))}
    </Box>
  );
};

const ProductCategories = ({
  showMode = ShowMode.SINGLE_LINE,
  onSelectedCategoryPath,
  onSelectCategory,
  onClose,
}) => {
  const { t, i18n } = useTranslation();
  const [categoryPath, setCategoryPath] = useState([]);
  const [selectedCategoryPath, setSelectedCategoryPath] = useState([]);
  const [hoveredCategories, setHoveredCategories] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedCategory, setExpandedCategory] = useState(null);

  const categoryRef = useRef();
  useOutsideClick(categoryRef, () => setHoveredCategories([]));

  useEffect(() => {
    const loadCategories = async () => {
      const categories = await fetchCategories('', i18n.language);
      setCategoryPath([{ level: 0, categories }]);
    };
    loadCategories();

    return () => {
      setCategoryPath([]);
      setSelectedCategoryPath([]);
      setHoveredCategories([]);
    };
  }, []);

  const handleSelectCategory = async (category, level) => {
    if (showMode === ShowMode.SINGLE_LINE) {
      await buildCategory(level, category);
      onClose();
    } else {
      onSelectCategory(category);
      onSelectedCategoryPath(selectedCategoryPath);
      onClose();
    }
  };

  const handleClickCategory = async (category, level) => {
    if (expandedCategory?.id === category.id) {
      handleSelectCategory(category, level);
    } else {
      setExpandedCategory(category);
      await buildCategory(level, category);
    }
  };

  const buildCategory = async (level, category) => {
    let newPath = categoryPath.slice(0, level + 1);
    const subcategories = await fetchCategories(category.id, i18n.language);
    if (subcategories.length > 0) {
      newPath.push({ level: level + 1, categories: subcategories });
    }
    setCategoryPath(newPath);

    const updatedSelectedCategoryPath = selectedCategoryPath.slice(
      0,
      level + 1
    );
    updatedSelectedCategoryPath[level] = category;
    setSelectedCategoryPath(updatedSelectedCategoryPath);

    setHoveredCategories([]);
  };

  return (
    <Container
      ref={categoryRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        width: isMobile
          ? '100vw'
          : `${300 + (categoryPath.length - 1) * 200}px`,
        backgroundColor: theme.palette.background.default,
        boxShadow: theme.shadows[3],
        zIndex: 1000,
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h6">{t('categories')}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Box
          sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
        >
          {categoryPath.map(({ categories, level }) => (
            <Box
              key={level}
              sx={{
                width: isMobile ? '100%' : level === 0 ? '300px' : '200px',
                borderRight: isMobile
                  ? 'none'
                  : `1px solid ${theme.palette.divider}`,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ProductCategoryBox
                level={level}
                categories={categories}
                onClickCategory={handleClickCategory}
                selectedCategoryPath={selectedCategoryPath}
                expandedCategory={expandedCategory}
                setExpandedCategory={setExpandedCategory}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default ProductCategories;
