import React, { useState } from 'react';
import { Paper, Typography, Button, Box } from '@mui/material';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import noStoreResults from './assets/lottie/no-storeproduct-results.json';
import StoreSignUpFlow from './SignUp/StoreSignUpFlow';

const NoStoreFound = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isStoreModal, setIsStoreModal] = useState(false);

  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        minHeight: 250,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 4,
        textAlign: 'center',
        position: 'relative',
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        overflow: 'hidden',
      }}
    >
      {isStoreModal && (
        <StoreSignUpFlow mode={1} closeModal={() => setIsStoreModal(false)} />
      )}

      {/* ✅ 🔹 Lottie 애니메이션 (좌측 상단 배치) */}
      <Box
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          width: 100,
          height: 100,
          opacity: 0.6,
        }}
      >
        <Lottie animationData={noStoreResults} loop={true} />
      </Box>

      {/* 🏪 스토어 아이콘 */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 80,
          height: 80,
          backgroundColor: theme.palette.grey[200],
          borderRadius: '50%',
          mb: 2,
          zIndex: 2,
        }}
      >
        <StorefrontOutlinedIcon
          sx={{ fontSize: 40, color: theme.palette.grey[700] }}
        />
      </Box>

      {/* 📢 "등록된 스토어 없음" 메시지 */}
      <Typography
        variant="h6"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        {t('noStoreFound')}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: theme.palette.text.secondary, mb: 2 }}
      >
        {t('noStoreFoundDescription')}
      </Typography>

      {/* 🎯 액션 버튼: 스토어 등록 */}
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2, width: '80%', zIndex: 2 }}
        onClick={() => setIsStoreModal(true)}
      >
        {t('register.registerStore')}
      </Button>
    </Paper>
  );
};

export default NoStoreFound;
