// import {
//     collection,
//     doc,
//     getDoc,
//     getDocs,
//     setDoc,
//     updateDoc,
//     addDoc,
//     increment,
//     query,
//     where,
//   } from 'firebase/firestore';
//   import { db } from '../firebaseConfig';

//   // ✅ 키워드 검색 빈도 업데이트
//   export const updateSearchFrequency = async (keyword) => {
//     const searchRanksRef = collection(db, 'searchRanks');
//     const keywordLowercase = keyword.toLowerCase();
//     const searchRanksDocRef = doc(searchRanksRef, keywordLowercase);
//     const docSnap = await getDoc(searchRanksDocRef);

//     if (docSnap.exists()) {
//       await updateDoc(searchRanksDocRef, {
//         searchFrequency: increment(1),
//       });
//     } else {
//       await setDoc(searchRanksDocRef, {
//         keyword,
//         keyword_lowercase: keywordLowercase,
//         searchFrequency: 1,
//         currentRank: 0,
//       });
//     }
//   };

//   // ✅ 전체 순위 일괄 업데이트
//   export const updateAllRanks = async () => {
//     const searchRanksRef = collection(db, 'searchRanks');
//     const querySnapshot = await getDocs(searchRanksRef);
//     const keywords = querySnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));

//     keywords.sort((a, b) => b.searchFrequency - a.searchFrequency);

//     for (let rank = 0; rank < keywords.length; rank++) {
//       const keywordDocRef = doc(searchRanksRef, keywords[rank].id);
//       await updateDoc(keywordDocRef, {
//         currentRank: rank + 1,
//       });
//     }
//   };

//   // ✅ 새로운 키워드일 경우 예상 순위 계산
//   export const calculateNewRank = async (inputKeyword) => {
//     try {
//       const searchRanksRef = collection(db, 'searchRanks');
//       const keywordLowercase = inputKeyword.toLowerCase();
//       const q = query(searchRanksRef, where('keyword_lowercase', '==', keywordLowercase));
//       const existingKeywordQuery = await getDocs(q);

//       if (!existingKeywordQuery.empty) {
//         return existingKeywordQuery.docs[0].data().currentRank;
//       } else {
//         const allKeywordsSnapshot = await getDocs(searchRanksRef);
//         const numOfKeywords = allKeywordsSnapshot.docs.length;
//         return numOfKeywords + 1;
//       }
//     } catch (error) {
//       console.error('Error in calculateNewRank:', error);
//       return 1;
//     }
//   };

//   // ✅ 키워드 순위 정보 업데이트
//   export const updateSearchRank = async (keyword, newRankPromise) => {
//     try {
//       const keywordLowercase = keyword.toLowerCase();
//       const newRank = await Promise.resolve(newRankPromise);

//       if (newRank === undefined) {
//         throw new Error('Resolved newRank is undefined');
//       }

//       const searchRanksRef = collection(db, 'searchRanks');
//       const searchRanksDocRef = doc(searchRanksRef, keywordLowercase);
//       const docSnap = await getDoc(searchRanksDocRef);

//       if (docSnap.exists()) {
//         const previousRank = docSnap.data().currentRank;
//         await setDoc(
//           searchRanksDocRef,
//           {
//             keyword,
//             keyword_lowercase: keywordLowercase,
//             currentRank: newRank,
//             previousRank,
//           },
//           { merge: true }
//         );
//       } else {
//         await addDoc(searchRanksRef, {
//           keyword,
//           keyword_lowercase: keywordLowercase,
//           currentRank: newRank,
//           previousRank: newRank,
//         });
//       }
//     } catch (error) {
//       console.error('Error in updateSearchRank:', error);
//       throw error;
//     }
//   };

// import {
//   collection,
//   doc,
//   getDoc,
//   getDocs,
//   setDoc,
//   updateDoc,
//   addDoc,
//   increment,
//   query,
//   where,
//   orderBy,
//   limit,
// } from 'firebase/firestore';
// import { db } from '../firebaseConfig';
// interface SearchRank {
//   id: string;
//   keyword: string;
//   keyword_lowercase: string;
//   searchFrequency: number;
//   currentRank: number;
//   previousRank?: number;
//   townId?: string;
// }
// // ✅ 키워드 검색 빈도 업데이트
// export const updateSearchFrequency = async (keyword: string, townId?: string) => {
//   const keywordLowercase = keyword.toLowerCase();
//   const searchRanksRef = townId
//     ? collection(db, 'searchRanks', townId, 'keywords')
//     : collection(db, 'searchRanks');
//   const searchRanksDocRef = doc(searchRanksRef, keywordLowercase);
//   const docSnap = await getDoc(searchRanksDocRef);

//   if (docSnap.exists()) {
//     await updateDoc(searchRanksDocRef, {
//       searchFrequency: increment(1),
//     });
//   } else {
//     await setDoc(searchRanksDocRef, {
//       keyword,
//       keyword_lowercase: keywordLowercase,
//       searchFrequency: 1,
//       currentRank: 0,
//     });
//   }
// };

// // ✅ 전체 순위 일괄 업데이트
// // export const updateAllRanks = async (townId?: string): Promise<void> => {
// //   const searchRanksRef = townId
// //     ? collection(db, 'searchRanks', townId, 'keywords')
// //     : collection(db, 'searchRanks');
// //   const querySnapshot = await getDocs(searchRanksRef);
// //   const keywords = querySnapshot.docs.map((doc) => ({
// //     id: doc.id,
// //     ...doc.data(),
// //   })) as SearchRank[];

// //   keywords.sort((a, b) => {
// //     const freqA = a.searchFrequency ?? 0;
// //     const freqB = b.searchFrequency ?? 0;
// //     return freqB - freqA;
// //   });

// //   for (let rank = 0; rank < keywords.length; rank++) {
// //     const keywordDocRef = doc(searchRanksRef, keywords[rank].id);
// //     await updateDoc(keywordDocRef, {
// //       currentRank: rank + 1,
// //     });
// //   }
// // };
// export const updateAllRanks = async (townId?: string): Promise<void> => {
//   try {
//     const searchRanksRef = townId
//       ? collection(db, 'searchRanks', townId, 'keywords')
//       : collection(db, 'searchRanks');

//     const querySnapshot = await getDocs(searchRanksRef);

//     const keywords: SearchRank[] = querySnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...(doc.data() as Omit<SearchRank, 'id'>),
//     }));

//     keywords.sort((a, b) => {
//       const freqA = a.searchFrequency ?? 0;
//       const freqB = b.searchFrequency ?? 0;
//       return freqB - freqA;
//     });

//     for (let rank = 0; rank < keywords.length; rank++) {
//       const keywordDocRef = doc(searchRanksRef, keywords[rank].id);
//       await updateDoc(keywordDocRef, {
//         currentRank: rank + 1,
//       });
//     }
//   } catch (error) {
//     console.error('🔥 Failed to update ranks:', error);
//   }
// };
// // ✅ 새로운 키워드일 경우 예상 순위 계산
// export const calculateNewRank = async (inputKeyword: string, townId?: string): Promise<number> => {
//   try {
//     const keywordLowercase = inputKeyword.toLowerCase();
//     const searchRanksRef = townId
//       ? collection(db, 'searchRanks', townId, 'keywords')
//       : collection(db, 'searchRanks');
//     const q = query(searchRanksRef, where('keyword_lowercase', '==', keywordLowercase));
//     const existingKeywordQuery = await getDocs(q);

//     if (!existingKeywordQuery.empty) {
//       return existingKeywordQuery.docs[0].data().currentRank;
//     } else {
//       const allKeywordsSnapshot = await getDocs(searchRanksRef);
//       return allKeywordsSnapshot.docs.length + 1;
//     }
//   } catch (error) {
//     console.error('Error in calculateNewRank:', error);
//     return 1;
//   }
// };

// // ✅ 키워드 순위 정보 업데이트
// export const updateSearchRank = async (
//   keyword: string,
//   newRankPromise: Promise<number> | number,
//   townId?: string
// ) => {
//   try {
//     const keywordLowercase = keyword.toLowerCase();
//     const newRank = await Promise.resolve(newRankPromise);

//     if (newRank === undefined) {
//       throw new Error('Resolved newRank is undefined');
//     }

//     const searchRanksRef = townId
//       ? collection(db, 'searchRanks', townId, 'keywords')
//       : collection(db, 'searchRanks');
//     const searchRanksDocRef = doc(searchRanksRef, keywordLowercase);
//     const docSnap = await getDoc(searchRanksDocRef);

//     if (docSnap.exists()) {
//       const previousRank = docSnap.data().currentRank;
//       await setDoc(
//         searchRanksDocRef,
//         {
//           keyword,
//           keyword_lowercase: keywordLowercase,
//           currentRank: newRank,
//           previousRank,
//         },
//         { merge: true }
//       );
//     } else {
//       await addDoc(searchRanksRef, {
//         keyword,
//         keyword_lowercase: keywordLowercase,
//         currentRank: newRank,
//         previousRank: newRank,
//       });
//     }
//   } catch (error) {
//     console.error('Error in updateSearchRank:', error);
//     throw error;
//   }
// };

// // ✅ 상위 20개 검색어 조회 (townId별 or 전체)
// export const getTop20SearchRanks = async (townId?: string) => {
//   try {
//     const searchRanksRef = townId
//       ? collection(db, 'searchRanks', townId, 'keywords')
//       : collection(db, 'searchRanks');
//     const q = query(searchRanksRef, orderBy('currentRank'), limit(20));
//     const querySnapshot = await getDocs(q);

//     const topSearchRanks = querySnapshot.docs.map((docSnapshot) => {
//       const data = docSnapshot.data();
//       const rankChange = (data.previousRank ?? data.currentRank) - data.currentRank;
//       let status: string;

//       if (rankChange >= 3) status = '↑↑';
//       else if (rankChange >= 1) status = '↑';
//       else if (rankChange === 0) status = '-';
//       else status = '↓';

//       return {
//         keyword: data.keyword,
//         currentRank: data.currentRank,
//         previousRank: data.previousRank,
//         status,
//       };
//     });

//     return topSearchRanks;
//   } catch (error: any) {
//     if (error.code === 'failed-precondition' || error.code === 'unavailable') {
//       console.error('Firestore index error: Please create the required index.', error);
//     } else {
//       console.error('Error fetching top search ranks:', error);
//     }
//     return [];
//   }
// };

import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  increment,
  query,
  where,
  orderBy,
  limit,
  serverTimestamp,
  writeBatch,
} from 'firebase/firestore';
import { db } from '../firebaseConfig';

// ✅ 키워드 검색 빈도 업데이트
// export const updateSearchFrequency = async (keyword, townId) => {
//   const keywordLowercase = keyword.toLowerCase();
//   const searchRanksRef = townId
//     ? collection(db, 'searchRanks', townId, 'keywords')
//     : collection(db, 'searchRanks');
//   const searchRanksDocRef = doc(searchRanksRef, keywordLowercase);
//   const docSnap = await getDoc(searchRanksDocRef);

//   if (docSnap.exists()) {
//     await updateDoc(searchRanksDocRef, {
//       searchFrequency: increment(1),
//     });
//   } else {
//     await setDoc(searchRanksDocRef, {
//       keyword,
//       keyword_lowercase: keywordLowercase,
//       searchFrequency: 1,
//       currentRank: 0,
//     });
//   }
// };
export const updateSearchFrequency = async (keyword, townId = null) => {
  if (!keyword || typeof keyword !== 'string') return;

  const keywordTrimmed = keyword.trim();
  if (!keywordTrimmed) return;

  const keywordLowercase = keywordTrimmed.toLowerCase();

  const searchRanksRef = townId
    ? collection(db, 'searchRanks', townId, 'keywords')
    : collection(db, 'searchRanks');

  const searchRanksDocRef = doc(searchRanksRef, keywordLowercase);

  try {
    const docSnap = await getDoc(searchRanksDocRef);

    if (docSnap.exists()) {
      await updateDoc(searchRanksDocRef, {
        searchFrequency: increment(1),
        updatedAt: serverTimestamp(),
      });
    } else {
      await setDoc(searchRanksDocRef, {
        keyword: keywordTrimmed,
        keyword_lowercase: keywordLowercase,
        searchFrequency: 1,
        currentRank: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    }
  } catch (error) {
    console.error('🔥 updateSearchFrequency error:', error);
  }
};
// ✅ 전체 순위 일괄 업데이트
// export const updateAllRanks = async (townId) => {
//   try {
//     const searchRanksRef = townId
//       ? collection(db, 'searchRanks', townId, 'keywords')
//       : collection(db, 'searchRanks');

//     const querySnapshot = await getDocs(searchRanksRef);

//     const keywords = querySnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));

//     keywords.sort((a, b) => {
//       const freqA = a.searchFrequency ?? 0;
//       const freqB = b.searchFrequency ?? 0;
//       return freqB - freqA;
//     });

//     for (let rank = 0; rank < keywords.length; rank++) {
//       const keywordDocRef = doc(searchRanksRef, keywords[rank].id);
//       await updateDoc(keywordDocRef, {
//         currentRank: rank + 1,
//       });
//     }
//   } catch (error) {
//     console.error('🔥 Failed to update ranks:', error);
//   }
// };
export const updateAllRanks = async (townId = null) => {
  try {
    const searchRanksRef = townId
      ? collection(db, 'searchRanks', townId, 'keywords')
      : collection(db, 'searchRanks');

    const querySnapshot = await getDocs(searchRanksRef);

    const keywords = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // 빈 배열일 경우 종료
    if (keywords.length === 0) return;

    // 검색 빈도 기준 정렬
    keywords.sort(
      (a, b) => (b.searchFrequency ?? 0) - (a.searchFrequency ?? 0)
    );

    // Firestore writeBatch 사용
    const batch = writeBatch(db);

    keywords.forEach((keyword, index) => {
      const keywordDocRef = doc(searchRanksRef, keyword.id);
      batch.update(keywordDocRef, {
        currentRank: index + 1,
      });
    });

    await batch.commit();
    console.log(`✅ Rank update complete (${keywords.length} keywords)`);
  } catch (error) {
    console.error('🔥 Failed to update ranks:', error);
  }
};
// ✅ 새로운 키워드일 경우 예상 순위 계산
export const calculateNewRank = async (inputKeyword, townId) => {
  try {
    const keywordLowercase = inputKeyword.toLowerCase();
    const searchRanksRef = townId
      ? collection(db, 'searchRanks', townId, 'keywords')
      : collection(db, 'searchRanks');
    const q = query(
      searchRanksRef,
      where('keyword_lowercase', '==', keywordLowercase)
    );
    const existingKeywordQuery = await getDocs(q);

    if (!existingKeywordQuery.empty) {
      return existingKeywordQuery.docs[0].data().currentRank;
    }
    const allKeywordsSnapshot = await getDocs(searchRanksRef);
    return allKeywordsSnapshot.docs.length + 1;
  } catch (error) {
    console.error('Error in calculateNewRank:', error);
    return 1;
  }
};

// ✅ 키워드 순위 정보 업데이트
// export const updateSearchRank = async (keyword, newRankPromise, townId) => {
//   try {
//     const keywordLowercase = keyword.toLowerCase();
//     const newRank = await Promise.resolve(newRankPromise);

//     if (newRank === undefined) {
//       throw new Error('Resolved newRank is undefined');
//     }

//     const searchRanksRef = townId
//       ? collection(db, 'searchRanks', townId, 'keywords')
//       : collection(db, 'searchRanks');
//     const searchRanksDocRef = doc(searchRanksRef, keywordLowercase);
//     const docSnap = await getDoc(searchRanksDocRef);

//     if (docSnap.exists()) {
//       const previousRank = docSnap.data().currentRank;
//       await setDoc(
//         searchRanksDocRef,
//         {
//           keyword,
//           keyword_lowercase: keywordLowercase,
//           currentRank: newRank,
//           previousRank,
//         },
//         { merge: true }
//       );
//     } else {
//       await addDoc(searchRanksRef, {
//         keyword,
//         keyword_lowercase: keywordLowercase,
//         currentRank: newRank,
//         previousRank: newRank,
//       });
//     }
//   } catch (error) {
//     console.error('Error in updateSearchRank:', error);
//     throw error;
//   }
// };
export const updateSearchRank = async (
  keyword,
  newRankPromise,
  townId = null
) => {
  try {
    const keywordTrimmed = keyword.trim();
    const keywordLowercase = keywordTrimmed.toLowerCase();
    const newRank = await Promise.resolve(newRankPromise);

    if (newRank === undefined || newRank === null) {
      throw new Error('Resolved newRank is undefined or null');
    }

    const searchRanksRef = townId
      ? collection(db, 'searchRanks', townId, 'keywords')
      : collection(db, 'searchRanks');

    const searchRanksDocRef = doc(searchRanksRef, keywordLowercase);
    const docSnap = await getDoc(searchRanksDocRef);

    if (docSnap.exists()) {
      const previousRank = docSnap.data().currentRank ?? newRank;
      await setDoc(
        searchRanksDocRef,
        {
          keyword: keywordTrimmed,
          keyword_lowercase: keywordLowercase,
          currentRank: newRank,
          previousRank,
        },
        { merge: true }
      );
    } else {
      await setDoc(searchRanksDocRef, {
        keyword: keywordTrimmed,
        keyword_lowercase: keywordLowercase,
        currentRank: newRank,
        previousRank: newRank,
      });
    }
  } catch (error) {
    console.error('🔥 Error in updateSearchRank:', error);
    throw error;
  }
};
// ✅ 상위 20개 검색어 조회 (townId별 or 전체)
export const getTop20SearchRanks = async (townId) => {
  try {
    const searchRanksRef = townId
      ? collection(db, 'searchRanks', townId, 'keywords')
      : collection(db, 'searchRanks');
    const q = query(searchRanksRef, orderBy('currentRank'), limit(20));
    const querySnapshot = await getDocs(q);

    const topSearchRanks = querySnapshot.docs.map((docSnapshot) => {
      const data = docSnapshot.data();
      const rankChange =
        (data.previousRank ?? data.currentRank) - data.currentRank;
      let status;

      if (rankChange >= 3) status = '↑↑';
      else if (rankChange >= 1) status = '↑';
      else if (rankChange === 0) status = '-';
      else status = '↓';

      return {
        keyword: data.keyword,
        currentRank: data.currentRank,
        previousRank: data.previousRank,
        status,
      };
    });

    return topSearchRanks;
  } catch (error) {
    if (error.code === 'failed-precondition' || error.code === 'unavailable') {
      console.error(
        'Firestore index error: Please create the required index.',
        error
      );
    } else {
      console.error('Error fetching top search ranks:', error);
    }
    return [];
  }
};
