// utils/ChatFilter.js
// const serverPort = process.env.REACT_APP_CHATFILTER_SERVER_PORT || 9090;
// const serverHost = process.env.REACT_APP_CHATFILTER_SERVER_HOST || 'localhost';
// const serverUrl = `http://${serverHost}:${serverPort}/api`;
const serverUrl =
  'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/chatFilterServerAPI';

// export const filterMessage = async (message) => {
//   const response = await fetch(`${serverUrl}/api/filter-message`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ message }),
//   });
//   const data = await response.json();
//   return data.isOffensive;
// };

export const filterMessage = async (message) => {
  try {
    const response = await fetch(
      `${serverUrl}/api/filter-message?message=${encodeURIComponent(message)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.isOffensive;
  } catch (error) {
    console.error('Error filtering message:', error);
    throw error;
  }
};

export const filterImage = async (image) => {
  const response = await fetch(`${serverUrl}/api/filter-image`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ image }),
  });
  const data = await response.json();
  return data.isOffensive;
};
