import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalProvider } from './useGlobalContext';
import store from './Redux/store.ts';
import { Provider } from 'react-redux';
import RootComponent from './RootComponent';
import { CategoryProvider } from './Stores/CategoryContext';
import { ThemeProvider } from './ThemeContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icons.min.css';
import { requestForToken, onMessageListener } from './firebaseConfig';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { lightTheme, darkTheme } from './themes';

const AppWithTheme = () => {
  return (
    <GlobalProvider>
      <CategoryProvider>
        {/* <ThemeProvider> */}
        <RootComponent />
        {/* </ThemeProvider> */}
      </CategoryProvider>
    </GlobalProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppWithTheme />
    </Provider>
  </React.StrictMode>
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <GlobalProvider>
//         <CategoryProvider>
//           <ThemeProvider>
//             <RootComponent />
//           </ThemeProvider>
//         </CategoryProvider>
//       </GlobalProvider>
//     </Provider>
//   </React.StrictMode>
// );

// Register the service worker
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    console.log('Service worker update available');
  },
  onSuccess: (registration) => {
    console.log('Service worker registered successfully');
    requestForToken();
    onMessageListener()
      .then((payload) => {
        console.log('Message received. ', payload);
      })
      .catch((err) => console.log('Failed to get message: ', err));
  },
});

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
//     .then(function (registration) {
//       console.log('Registration successful, scope is:', registration.scope);
//       requestForToken();
//       onMessageListener()
//         .then((payload) => {
//           console.log('Message received. ', payload);
//           // Handle the payload (e.g., show a notification)
//         })
//         .catch((err) => console.log('failed: ', err));
//     })
//     .catch(function (err) {
//       console.log('Service worker registration failed, error:', err);
//     });
// }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { GlobalProvider } from './useGlobalContext';
// import store from './Redux/store.ts';
// import { Provider, useSelector } from 'react-redux';
// import RootComponent from './RootComponent';
// import { CategoryProvider } from './Stores/CategoryContext';
// import {
//   useTheme,
//   ThemeProvider as MuiThemeProvider,
//   CssBaseline,
// } from '@mui/material';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'flag-icon-css/css/flag-icons.min.css';
// import { requestForToken, onMessageListener } from './firebaseConfig';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// // import { ThemeProvider, useTheme } from './ThemeContext';
// import { lightTheme, darkTheme } from './themes';

// const AppWithTheme = () => {
//   const theme = useTheme();

//   return (
//     <MuiThemeProvider theme={theme}>
//       <CssBaseline />
//       <GlobalProvider>
//         <CategoryProvider>
//           <RootComponent />
//         </CategoryProvider>
//       </GlobalProvider>
//     </MuiThemeProvider>
//   );
// };

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       {/* <ThemeProvider> */}
//       <AppWithTheme />
//       {/* </ThemeProvider> */}
//     </Provider>
//   </React.StrictMode>
// );

// // Register the service worker
// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     console.log('Service worker update available');
//   },
//   onSuccess: (registration) => {
//     console.log('Service worker registered successfully');
//     requestForToken();
//     onMessageListener()
//       .then((payload) => {
//         console.log('Message received. ', payload);
//       })
//       .catch((err) => console.log('Failed to get message: ', err));
//   },
// });

// // if ('serviceWorker' in navigator) {
// //   navigator.serviceWorker
// //     .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
// //     .then(function (registration) {
// //       console.log('Registration successful, scope is:', registration.scope);
// //       requestForToken();
// //       onMessageListener()
// //         .then((payload) => {
// //           console.log('Message received. ', payload);
// //           // Handle the payload (e.g., show a notification)
// //         })
// //         .catch((err) => console.log('failed: ', err));
// //     })
// //     .catch(function (err) {
// //       console.log('Service worker registration failed, error:', err));
// //     });
// // }
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
