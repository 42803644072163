import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Box,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Favorite as FavoriteIcon,
  Visibility as VisibilityIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../useGlobalContext';
import WeatherEventDisplay from '../Weather/WeatherEventDisplay';
import WeatherPriceDisplay from '../Weather/WeatherPriceDisplay';
import { formatPrice } from '../Utils/PriceFormatter';

const StoreProductCard = ({
  product,
  weatherEvent,
  isWeatherEventActive,
  horizontal = false,
  onClick,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { pcAspectRatio, mobileAspectRatio, productDefaultImage } =
    useGlobalContext();

  // ✅ 상품 이미지 URL 설정 (기본 이미지 적용)
  const imageUrl = product?.imageUrl || productDefaultImage;
  const dateObj = product?.createDate?.toDate();
  const formattedDate = moment(dateObj).fromNow();
  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);
  // ✅ 좋아요, 조회수, 채팅 정보 표시
  const renderProductStats = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 1,
      }}
    >
      <Tooltip title={t('likes')}>
        <IconButton size="small">
          <FavoriteIcon fontSize="small" color="error" />
          <Typography variant="caption" sx={{ ml: 0.5 }}>
            {product.likes || 0}
          </Typography>
        </IconButton>
      </Tooltip>
      <Tooltip title={t('views')}>
        <IconButton size="small">
          <VisibilityIcon fontSize="small" color="action" />
          <Typography variant="caption" sx={{ ml: 0.5 }}>
            {product.views || 0}
          </Typography>
        </IconButton>
      </Tooltip>
      <Tooltip title={t('chat')}>
        <IconButton size="small">
          <ChatIcon fontSize="small" color="primary" />
          <Typography variant="caption" sx={{ ml: 0.5 }}>
            {product.chats || 0}
          </Typography>
        </IconButton>
      </Tooltip>
    </Box>
  );

  // ✅ 이벤트 배지 표시
  const renderEventBadge = () =>
    isWeatherEventActive && weatherEvent ? (
      <Box
        sx={{
          position: 'absolute',
          top: theme.spacing(1),
          left: theme.spacing(1),
          backgroundColor: '#FF5722',
          color: theme.palette.common.white,
          borderRadius: theme.shape.borderRadius,
          px: 0.5,
          py: 0.2,
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <img
          src={weatherEvent.iconUrl}
          alt={weatherEvent.name}
          style={{ width: 20, height: 20, borderRadius: '50%' }}
        />
        <Typography variant="body2">{weatherEvent.discount}% OFF</Typography>
      </Box>
    ) : null;

  // ✅ 모바일 버전 렌더링
  const renderMobileView = () => (
    <Box
      display="flex"
      flexDirection="row"
      sx={{ height: { xs: 'auto', sm: '20vh', md: '22vh' } }}
    >
      {/* 이미지 섹션 */}
      <CardMedia
        component="img"
        image={imageUrl}
        alt={product.productName}
        sx={{
          width: '40%',
          height: '100%',
          objectFit: 'cover',
          aspectRatio: mobileAspectRatio,
          borderRadius: theme.shape.borderRadius,
        }}
      />

      {/* 텍스트 & 정보 섹션 */}
      <CardContent
        sx={{
          width: '60%',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            textAlign: 'left',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {product.productName}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'left',
            color: theme.palette.text.secondary,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {product.description}
        </Typography>

        {/* 수량 정보 */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Typography variant="body2" noWrap sx={{ textAlign: 'left' }}>
            {product.stock} {t('left')}
          </Typography>
          <Typography variant="body2">{formattedDate}</Typography>
        </Box>

        {/* 날씨 할인 이벤트 */}
        <WeatherEventDisplay
          weatherEvent={weatherEvent}
          isEventActive={isWeatherEventActive}
        />
        <WeatherPriceDisplay
          product={product}
          weatherEvent={weatherEvent}
          isEventActive={isWeatherEventActive}
        />

        {renderProductStats()}
      </CardContent>
    </Box>
  );

  // ✅ PC 버전 렌더링
  const renderPCView = () => (
    <>
      <CardMedia
        component="img"
        image={imageUrl}
        alt={product.productName}
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          objectFit: 'cover',
          aspectRatio: pcAspectRatio,
          borderRadius: theme.shape.borderRadius,
        }}
      />

      <CardContent sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            textAlign: 'left',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {product.productName}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'left',
            color: theme.palette.text.secondary,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {product.description}
        </Typography>

        {/* 수량 정보 */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Typography variant="body2">{formattedDate}</Typography>
          <Typography variant="body2" noWrap sx={{ textAlign: 'left' }}>
            {product.stock} {t('left')}
          </Typography>
        </Box>

        {/* 날씨 할인 이벤트 */}
        <WeatherEventDisplay
          weatherEvent={weatherEvent}
          isEventActive={isWeatherEventActive}
        />
        <WeatherPriceDisplay
          product={product}
          weatherEvent={weatherEvent}
          isEventActive={isWeatherEventActive}
        />

        {renderProductStats()}
      </CardContent>
    </>
  );

  return (
    <Card
      sx={{
        width: '100%',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        m: 1,
      }}
    >
      <CardActionArea
        component={RouterLink}
        to={`/product_detail/${product.uid}/${product.id}`}
        onClick={onClick}
      >
        {renderEventBadge()}
        {isMobile ? renderMobileView() : renderPCView()}
      </CardActionArea>
    </Card>
  );
};

export default StoreProductCard;
