// // import React from 'react';
// // import { useParams } from 'react-router-dom';
// // import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
// // import { useTranslation } from 'react-i18next';
// // import { Link } from 'react-router-dom';

// // const MyPage = () => {
// //   const { t, i18n } = useTranslation();
// //   const { userId } = useParams();

// //   return (
// //     <Box
// //       p={3}
// //       sx={{
// //         width: '100%',
// //         maxWidth: '100%',
// //         margin: 'auto',
// //       }}
// //     >
// //       <Box
// //         sx={{
// //           display: 'inline-block',
// //           border: '0px solid #d3d3d3', // 연한 회색 테두리
// //           borderRadius: '16px', // 둥근 테두리
// //           padding: '8px 16px', // 안쪽 여백
// //           marginBottom: '16px', // 아래쪽 여백
// //           backgroundColor: 'transparent', // 연한 회색 배경색
// //           // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 그림자 효과
// //           transition: 'transform 0.2s', // 호버 시 애니메이션
// //           '&:hover': {
// //             transform: 'scale(1.02)', // 호버 시 크기 증가
// //           },
// //         }}
// //       >
// //         <Typography variant="h4" gutterBottom>
// //           {t('myPage')}
// //         </Typography>
// //       </Box>
// //       <nav>
// //         <List>
// //           <ListItem button component={Link} to={`/my_profile/${userId}`}>
// //             <ListItemText primary={t('myProfile')} />
// //           </ListItem>
// //           <ListItem button component={Link} to={`/allcartlist/${userId}`}>
// //             <ListItemText primary={t('allCart')} />
// //           </ListItem>
// //           <ListItem button component={Link} to={`/orders/${userId}`}>
// //             <ListItemText primary={t('myOrders')} />
// //           </ListItem>
// //           <ListItem button component={Link} to={`/returns/${userId}`}>
// //             <ListItemText primary={t('returns')} />
// //           </ListItem>
// //           <ListItem button component={Link} to={`/order_status/${userId}`}>
// //             <ListItemText primary={t('orderStatusChange')} />
// //           </ListItem>
// //           {/* <ListItem button component={Link} to={`/user_points/${userId}`}>
// //             <ListItemText primary={t('points')} />
// //           </ListItem>
// //           <ListItem button component={Link} to={`/user_coupons/${userId}`}>
// //             <ListItemText primary={t('coupons')} />
// //           </ListItem> */}
// //         </List>
// //       </nav>
// //     </Box>
// //   );
// // };

// // export default MyPage;

// import React from 'react';
// import { useParams } from 'react-router-dom';
// import {
//   Box,
//   Container,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
//   useTheme,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';

// const MyPage = () => {
//   const { t } = useTranslation();
//   const theme = useTheme();
//   const { userId } = useParams();

//   return (
//     <Box
//       sx={{
//         width: '100vw', // ✅ 전체 너비
//         minHeight: '100vh', // ✅ 전체 높이
//         backgroundColor: theme.palette.background.default, // ✅ 전체 배경색 적용 (다크모드 지원)
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'flex-start', // 중앙 정렬
//       }}
//     >
//       <Container
//         maxWidth="lg"
//         sx={{ backgroundColor: theme.palette.background.paper, py: 4 }}
//       >
//         {/* 🔹 타이틀 박스 */}
//         <Box
//           sx={{
//             borderRadius: '12px',
//             padding: '12px 2px',
//             mb: 3,
//             backgroundColor: theme.palette.background.paper,
//             boxShadow: theme.shadows[2],
//             transition: 'all 0.2s ease-in-out',
//             '&:hover': {
//               transform: 'scale(1.02)',
//               boxShadow: theme.shadows[4],
//             },
//             textAlign: 'left',
//           }}
//         >
//           <Typography
//             variant="h5"
//             sx={{
//               fontWeight: 600,
//               paddingBottom: theme.spacing(2),
//               color: theme.palette.primary.main,
//             }}
//           >
//             {t('myPage')}
//           </Typography>

//           {/* 네비게이션 리스트 */}
//           <List>
//             <ListItem
//               button
//               component={Link}
//               to={`/my_profile/${userId}`}
//               sx={{
//                 '&:hover': {
//                   backgroundColor: theme.palette.action.hover,
//                 },
//               }}
//             >
//               <ListItemText primary={t('myProfile')} />
//             </ListItem>
//             <ListItem
//               button
//               component={Link}
//               to={`/allcartlist/${userId}`}
//               sx={{
//                 '&:hover': {
//                   backgroundColor: theme.palette.action.hover,
//                 },
//               }}
//             >
//               <ListItemText primary={t('allCart')} />
//             </ListItem>
//             <ListItem
//               button
//               component={Link}
//               to={`/orders/${userId}`}
//               sx={{
//                 '&:hover': {
//                   backgroundColor: theme.palette.action.hover,
//                 },
//               }}
//             >
//               <ListItemText primary={t('myOrders')} />
//             </ListItem>
//             <ListItem
//               button
//               component={Link}
//               to={`/returns/${userId}`}
//               sx={{
//                 '&:hover': {
//                   backgroundColor: theme.palette.action.hover,
//                 },
//               }}
//             >
//               <ListItemText primary={t('returns')} />
//             </ListItem>
//             <ListItem
//               button
//               component={Link}
//               to={`/order_status/${userId}`}
//               sx={{
//                 '&:hover': {
//                   backgroundColor: theme.palette.action.hover,
//                 },
//               }}
//             >
//               <ListItemText primary={t('orderStatusChange')} />
//             </ListItem>
//           </List>
//         </Box>
//       </Container>
//     </Box>
//   );
// };

// export default MyPage;

import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const MyPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userId } = useParams();

  return (
    <Box
      sx={{
        width: '100vw', // ✅ 전체 너비
        minHeight: '100vh', // ✅ 전체 높이
        backgroundColor: theme.palette.background.default, // ✅ 다크 모드 지원
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        py: 3,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          // display: 'inline-block',
          //borderRadius: '12px',
          display: 'flex', // flexbox 사용
          justifyContent: 'center', // 가로 중앙 정렬
          flexDirection: 'column',
          // alignItems: 'center', // 세로 중앙 정렬 (필요한 경우)
          padding: '8px 16px',
          marginBottom: '16px',
          backgroundColor: theme.palette.background.paper,
          // transition: 'transform 0.2s',
          // '&:hover': {
          //   transform: 'scale(1.02)',
          // },
        }}
      >
        {/* 🔹 타이틀 박스 */}
        {/* <Box
          sx={{
            borderRadius: 2,
            padding: 3,
            mb: 3,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              transform: 'scale(1.02)',
              boxShadow: theme.shadows[4],
            },
          }}
        > */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            color: theme.palette.primary.main, // ✅ 다크 모드에서도 가독성 유지
            textAlign: 'left',
            mb: 2,
          }}
        >
          {t('myPage')}
        </Typography>

        {/* 네비게이션 리스트 */}
        <List>
          {[
            { path: `/my_profile/${userId}`, label: 'myProfile' },
            { path: `/allcartlist/${userId}`, label: 'allCart' },
            { path: `/orders/${userId}`, label: 'myOrders' },
            { path: `/returns/${userId}`, label: 'returns' },
            { path: `/order_status/${userId}`, label: 'orderStatusChange' },
          ].map(({ path, label }) => (
            <ListItem
              key={label}
              button
              component={Link}
              to={path}
              sx={{
                '&:hover': {
                  backgroundColor: theme.palette.action.hover, // ✅ Hover 효과 개선
                },
              }}
            >
              <ListItemText
                primary={t(label)}
                sx={{ color: theme.palette.text.primary }} // ✅ 다크 모드에서도 가독성 유지
              />
            </ListItem>
          ))}
        </List>
        {/* </Box> */}
      </Container>
    </Box>
  );
};

export default MyPage;
