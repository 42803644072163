// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { BottomNavigation, BottomNavigationAction } from '@mui/material';
// import HomeIcon from '@mui/icons-material/Home';
// import StoreIcon from '@mui/icons-material/Store';
// import ChatIcon from '@mui/icons-material/Chat';
// import SettingsIcon from '@mui/icons-material/Settings';

// const MobileTabBar = ({ activeMenu }) => {
//   const navigate = useNavigate();
//   const [activeTab, setActiveTab] = useState(activeMenu);

//   useEffect(() => {
//     setActiveTab(activeMenu);
//   }, [activeMenu]);

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//     navigate(newValue);
//   };

//   return (
//     <BottomNavigation
//       value={activeTab}
//       onChange={handleTabChange}
//       showLabels
//       sx={{
//         width: '100%',
//         position: 'fixed',
//         bottom: 0,
//         zIndex: 1000, // 우선순위를 높이는 zIndex 설정
//         backgroundColor: 'white',
//         borderTop: '1px solid #ddd', // 상단 경계선 추가
//       }}
//     >
//       <BottomNavigationAction label="Home" value="/" icon={<HomeIcon />} />
//       <BottomNavigationAction
//         label="Stores"
//         value="/storelist"
//         icon={<StoreIcon />}
//       />
//       <BottomNavigationAction
//         label="Chat"
//         value="/chatlist"
//         icon={<ChatIcon />}
//       />
//       <BottomNavigationAction
//         label="Settings"
//         value="/settings"
//         icon={<SettingsIcon />}
//       />
//     </BottomNavigation>
//   );
// };

// export default MobileTabBar;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';

const MobileTabBar = ({ activeMenu }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(activeMenu);

  useEffect(() => {
    setActiveTab(activeMenu);
  }, [activeMenu]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    navigate(newValue);
  };

  return (
    <BottomNavigation
      value={activeTab}
      onChange={handleTabChange}
      showLabels
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1000,
        backgroundColor: theme.palette.background.paper, // 💡 Theme 적용
        borderTop: `1px solid ${theme.palette.divider}`, // 💡 테마 기반 테두리 색상
        boxShadow: theme.shadows[3], // 💡 테마 기반 그림자
      }}
    >
      <BottomNavigationAction
        label="Home"
        value="/home"
        icon={<HomeIcon />}
        sx={{
          color:
            activeTab === '/home'
              ? theme.palette.primary.main
              : theme.palette.text.secondary,
        }}
      />
      <BottomNavigationAction
        label="Stores"
        value="/storelist"
        icon={<StoreIcon />}
        sx={{
          color:
            activeTab === '/storelist'
              ? theme.palette.primary.main
              : theme.palette.text.secondary,
        }}
      />
      <BottomNavigationAction
        label="Chat"
        value="/chatlist"
        icon={<ChatIcon />}
        sx={{
          color:
            activeTab === '/chatlist'
              ? theme.palette.primary.main
              : theme.palette.text.secondary,
        }}
      />
      <BottomNavigationAction
        label="Settings"
        value="/settings"
        icon={<SettingsIcon />}
        sx={{
          color:
            activeTab === '/settings'
              ? theme.palette.primary.main
              : theme.palette.text.secondary,
        }}
      />
    </BottomNavigation>
  );
};

export default MobileTabBar;
