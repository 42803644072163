// components/TownSelectorBox.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';

interface TownSelectorBoxProps {
  selectedTownName: string;
  onClick: () => void;
  minWidth?: number | string;
}

const TownSelectorBox: React.FC<TownSelectorBoxProps> = ({
  selectedTownName,
  onClick,
  minWidth = 160,
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        minWidth,
        height: 60,
        borderRadius: 16,
        backgroundColor: 'background.paper',
        color: 'text.primary',
        padding: '8px 12px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography variant="body2">
        📍 {selectedTownName}
      </Typography>
    </Box>
  );
};

export default TownSelectorBox;
