// import { useEffect, useState } from 'react';
// import { fetchWeatherEvent } from '../api/WeatherAPI';
// import { db } from '../firebaseConfig'; // Firestore 인스턴스 불러오기
// import { doc, onSnapshot } from 'firebase/firestore';

// export const useWeatherEvent = (weatherEventId) => {
//   const [weatherEvent, setWeatherEvent] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     if (!weatherEventId) return;

//     setLoading(true);

//     // 🔥 Firestore 실시간 리스너 설정
//     const eventRef = doc(db, 'events', weatherEventId);
//     const unsubscribe = onSnapshot(
//       eventRef,
//       (snapshot) => {
//         if (snapshot.exists()) {
//           console.log('✅ Weather event updated:', snapshot.data());
//           setWeatherEvent(snapshot.data());
//         } else {
//           console.warn('⚠️ Weather event not found:', weatherEventId);
//           setWeatherEvent(null);
//         }
//         setLoading(false);
//       },
//       (err) => {
//         console.error('❌ Firestore onSnapshot error:', err);
//         setError(err);
//         setLoading(false);
//       }
//     );

//     // 🔥 리스너 정리 (클린업)
//     return () => unsubscribe();
//   }, [weatherEventId]);

//   return { weatherEvent, loading, error };
// };

import { useEffect, useState } from 'react';
import { db } from '../firebaseConfig'; // Firestore 인스턴스
import { doc, onSnapshot } from 'firebase/firestore';

/**
 * 특정 날씨 이벤트 데이터를 Firestore에서 실시간으로 가져오는 커스텀 훅
 * @param {string} weatherEventId - 가져올 날씨 이벤트의 ID
 * @returns {object} - { weatherEvent, loading, error }
 */
export const useWeatherEvent = (weatherEventId) => {
  const [weatherEvent, setWeatherEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // 🔹 weatherEventId가 없으면 실행하지 않음
    if (!weatherEventId) {
      setWeatherEvent(null);
      setLoading(false);
      setError(null);
      return;
    }

    setLoading(true); // 데이터 로딩 시작

    try {
      // 🔥 Firestore 실시간 리스너 설정
      const eventRef = doc(db, 'events', weatherEventId);
      const unsubscribe = onSnapshot(
        eventRef,
        (snapshot) => {
          if (snapshot.exists()) {
            console.log('✅ Weather event updated:', snapshot.data());
            const eventData = { id: snapshot.id, ...snapshot.data() };
            setWeatherEvent(eventData);
          } else {
            console.warn(`⚠️ Weather event not found: ${weatherEventId}`);
            setWeatherEvent(null);
          }
          setLoading(false);
        },
        (err) => {
          console.error('❌ Firestore onSnapshot error:', err);
          setError(err);
          setLoading(false);
        }
      );

      // ✅ 클린업 함수: 컴포넌트 언마운트 시 Firestore 리스너 정리
      return () => unsubscribe();
    } catch (err) {
      console.error('🔥 Error in useWeatherEvent:', err);
      setError(err);
      setLoading(false);
    }
  }, [weatherEventId]);

  return { weatherEvent, loading, error };
};
