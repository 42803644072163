import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import ImageSlider from '../ImageSlider';
import StoreProduct from './StoreProduct';
import useStoreUserActivity from './useStoreUserActivity';
import useRecommandStoreProducts from './useRecommandStoreProducts';
import useMessageBox from '../Common/useMessageBox';
import VirtualWearViewer from '../glasses/VirtualWearViewer';
import ProductReviewRating from './ProductReviewRating';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
  Paper,
  Grid,
  Typography,
  Box,
  Avatar,
} from '@mui/material';
import {
  likeProduct,
  likeStoreProduct,
  recentlyProductViewed,
  recentlyStoreProductViewed,
} from '../Redux/reducers/floatingSidebarReducer';

import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';

import StoreProductTabs from './StoreProductTabs';
import {
  firebaseApp,
  db,
  auth,
  analytics,
  storage,
  functions,
  collection,
  query,
  where,
  orderBy,
  limit,
  addDoc,
  setDoc,
  arrayUnion,
  arrayRemove,
  increment,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  writeBatch,
  onSnapshot,
  startAfter,
} from '../firebaseConfig';

import '../css/detail.css';
import moment from 'moment';
import { formatPrice } from '../Utils/PriceFormatter';
import {
  getMediaType,
  isImageURL,
  isVideoURL,
  MEDIA_TYPES,
} from '../Utils/FileHelper';
import { fetchUserProfile } from '../Utils/GetUserProfile';
import StoreProductPurshase from './StoreProductPurshase';
import ProductReviewList from './ProductReviewList';
import ProductReviewsTab from './ProductReviewsTab';
import SimilarStoreProducts from '../SimilarStoreProducts';
import InfiniteScroll from 'react-infinite-scroll-component';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import FloatingCartButton from '../FloatingCartButton';
import { useWeather } from '../Weather/WeatherProvider';
import { useWeatherEvent } from '../Weather/useWeatherEvent';
import { useWeatherEvents } from '../Weather/useWeatherEvents';
import { isWeatherEventActive } from '../Weather/WeatherEventRules';
import WeatherEventDisplay from '../Weather/WeatherEventDisplay';
import WeatherPriceDisplay from '../Weather/WeatherPriceDisplay';

const StoreProductDetail = () => {
  const { t, i18n } = useTranslation();
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [storeName, setStoreName] = useState(null);
  const [product, setProduct] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [metaFiles, setMetaFiles] = useState([]);
  const [products, setProducts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMoreUserProducts, setHasMoreUserProducts] = useState(true);
  const [isLiked, setIsLiked] = useState(false);
  const [productOwnerName, setProductOwnerName] = useState('');
  const [productOwnerPhotoURL, setProductOwnerPhotoURL] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { storeId, productId } = useParams();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const productOwnerId = product?.uid;
  const [status, setStatus] = useState({ loading: true, error: null });
  const imageRefs = useRef([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currentWeather = useSelector((state) => state.weather.weatherData);
  const { weatherData } = useWeather();
  const { weatherEvents } = useWeatherEvents();
  const { weatherEvent } = useWeatherEvent(product?.weatherEvent);

  const isEventActive = useMemo(
    () => weatherEvent && isWeatherEventActive(weatherData, weatherEvent),
    [weatherData, weatherEvent]
  );
  // const discountedPrice = isEventActive
  //   ? Math.round(product?.price * (1 - weatherEvent?.discount / 100))
  //   : product.price;
  const {
    logViewedStoreProduct,
    logPurchasedStoreProduct,
    logSearchedStoreKeyword,
  } = useStoreUserActivity(currentUser?.uid);

  const { recommendedProducts, productsViewedByOthers } =
    useRecommandStoreProducts(storeId, product, currentUser?.uid);

  useEffect(() => {
    if (!storeId || !productId) {
      console.warn('StoreProductDetail: Missing storeId or productId', {
        storeId,
        productId,
      });
      return;
    }

    async function fetchData() {
      try {
        const storeRef = doc(db, 'stores', storeId);
        const storeDoc = await getDoc(storeRef);

        if (storeDoc.exists() && storeDoc.data().storeName) {
          setStoreName(storeDoc.data().storeName);
        } else {
          //throw new Error('Store or storeName not found');
          return;
        }

        const productRef = doc(db, 'stores', storeId, 'products', productId);
        const productSnapshot = await getDoc(productRef);
        const productData = productSnapshot.data();

        if (!productData) {
          throw new Error(
            `Product not found (storeId: ${storeId}, productId: ${productId})`
          );
        }

        const formattedData = {
          id: productSnapshot.id,
          ...productData,
          formattedDate: moment(productData.createDate?.toDate()).fromNow(),
        };
        setProduct(formattedData);

        if (productData.discountCode) {
          const discountQuery = query(
            collection(db, 'stores', storeId, 'discounts'),
            where('discountCode', '==', productData.discountCode)
          );
          const discountSnapshot = await getDocs(discountQuery);
          if (!discountSnapshot.empty) {
            const discountData = discountSnapshot.docs[0].data();

            if (discountData) {
              const now = new Date();
              const discountStartDate = discountData.startDate?.toDate();
              const discountEndDate = discountData.endDate?.toDate();

              if (now >= discountStartDate && now <= discountEndDate) {
                if (discountData.discountType === 'percentage') {
                  formattedData.discountedPrice =
                    formattedData.price -
                    formattedData.price * (discountData.discount / 100);
                } else if (discountData.discountType === 'amount') {
                  formattedData.discountedPrice =
                    formattedData.price - discountData.discount;
                }
                setProduct(formattedData);
                setDiscount(discountData);
              }
            }
          }
        }

        const unsubscribe = onSnapshot(productRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const productData = docSnapshot.data();
            const formattedData = {
              id: docSnapshot.id,
              ...productData,
              formattedDate: moment(productData.createDate?.toDate()).fromNow(),
            };

            //setProduct(formattedData);

            const fetchProfile = async () => {
              const profile = await fetchUserProfile(productData.uid);
              if (profile) {
                const newFiles = formattedData.xlarge_images.map(
                  (url, index) => ({
                    index,
                    fileUrl: url,
                    type: getMediaType(url),
                    senderName: profile?.displayName,
                    date: productData.createDate,
                  })
                );
                setMetaFiles(newFiles);
                setProductOwnerName(profile.displayName);
                setProductOwnerPhotoURL(profile.photoURL);
              }
            };
            fetchProfile();

            const isUserLiked =
              productData.likeUsers?.includes(currentUser?.uid) ?? false;
            setIsLiked(isUserLiked);
          } else {
            setStatus({ loading: false, error: '상품을 찾을 수 없습니다.' });
          }
        });

        const isUserLiked =
          productData.likeUsers?.includes(currentUser?.uid) ?? false;
        setIsLiked(isUserLiked);

        return () => unsubscribe();
      } catch (err) {
        if (err.code === 'failed-precondition' || err.code === 'unavailable') {
          console.error('Firestore index error:', err);
          setStatus({
            loading: false,
            error:
              '필요한 인덱스가 없습니다. Firestore 콘솔에서 인덱스를 생성하세요.',
          });
        } else {
          console.error('Error fetching data:', err);
          setStatus({ loading: false, error: err.message });
        }
      } finally {
        setStatus((prev) => ({ ...prev, loading: false }));
      }
    }

    fetchData();
  }, [storeId, productId, currentUser]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (currentUser) {
        addViewsCount();
      }
    }, 3000);
    return () => clearTimeout(timerId);
  }, [productId]);

  // const fetchUserProducts = useCallback(async () => {
  //   if (!product?.uid) return;

  //   const productsQuery = query(
  //     collection(db, 'stores', storeId, 'products'),
  //     where('uid', '==', product.uid),
  //     orderBy('createDate'),
  //     startAfter(lastVisible),
  //     limit(10)
  //   );

  //   const productsSnapshot = await getDocs(productsQuery);
  //   const fetchedProducts = productsSnapshot.docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),
  //   }));

  //   if (fetchedProducts.length === 0) {
  //     setHasMoreUserProducts(false);
  //   } else {
  //     setProducts((prevProducts) => [...prevProducts, ...fetchedProducts]);
  //     setLastVisible(productsSnapshot.docs[productsSnapshot.docs.length - 1]);
  //   }
  // }, [product?.uid, lastVisible, storeId]);

  const fetchUserProducts = useCallback(async () => {
    if (!product?.uid || !storeId) {
      console.error('Product UID or Store ID is undefined');
      return;
    }

    try {
      const productsCollection = collection(db, 'stores', storeId, 'products');
      let productsQuery;

      if (lastVisible) {
        productsQuery = query(
          productsCollection,
          where('uid', '==', product.uid),
          orderBy('createDate'),
          startAfter(lastVisible),
          limit(10)
        );
      } else {
        productsQuery = query(
          productsCollection,
          where('uid', '==', product.uid),
          orderBy('createDate'),
          limit(10)
        );
      }

      const productsSnapshot = await getDocs(productsQuery);
      const fetchedProducts = productsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (fetchedProducts.length === 0) {
        setHasMoreUserProducts(false);
      } else {
        setProducts((prevProducts) => [...prevProducts, ...fetchedProducts]);
        setLastVisible(productsSnapshot.docs[productsSnapshot.docs.length - 1]);
      }
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error('Firestore index error:', error.message);
        // 필요한 경우 사용자에게 알림을 표시하거나 추가 처리를 수행할 수 있습니다.
      } else {
        console.error('Error fetching user products:', error);
      }
    }
  }, [product?.uid, lastVisible, storeId]);

  // useEffect(() => {
  //   fetchUserProducts();
  // }, [fetchUserProducts]);

  const loadMoreUserProducts = () => {
    if (hasMoreUserProducts) {
      fetchUserProducts();
    }
  };

  const handleLike = async () => {
    if (!currentUser) {
      alert(t('login-required'));
      return;
    }
    if (!product) {
      alert('product is null');
      return;
    }

    if (currentUser.uid === product.uid) {
      alert(t('like-own-product'));
      return;
    }

    if (product.likeUsers && product.likeUsers.includes(currentUser.uid)) {
      try {
        await removeLikeFromProduct(currentUser.uid);
        await updateMyLikeList(currentUser.uid, productId, false);
        setIsLiked(false);
        dispatch(likeStoreProduct(productId));
      } catch (error) {
        console.error('Failed to remove the like:', error);
        alert(t('like-remove-failed'));
        return;
      }
    } else {
      const updateObject = {
        likes: increment(1),
        likeUsers: arrayUnion(currentUser.uid),
      };

      try {
        await updateLikesToProduct(updateObject, currentUser.uid);
        setIsLiked(true);
        dispatch(likeStoreProduct(productId));
      } catch (error) {
        console.error('Failed to update the product:', error);
        alert(t('like-update-failed'));
        return;
      }

      try {
        await updateMyLikeList(currentUser.uid, productId, true);
      } catch (error) {
        console.error("Failed to add product to user's likes:", error);
        alert(t('like-add-failed'));
      }
    }
  };

  const removeLikeFromProduct = async (currentUserId) => {
    if (!currentUser) {
      alert(t('login-required'));
      return;
    }
    const productRef = doc(db, 'stores', storeId, 'products', productId);
    try {
      await updateDoc(productRef, {
        likes: increment(-1),
        likeUsers: arrayRemove(currentUserId),
      });

      console.log('Like removed successfully!');

      setProduct((prevState) => ({
        ...prevState,
        likes: prevState.likes - 1,
        likeUsers: prevState.likeUsers.filter((id) => id !== currentUserId),
      }));
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const updateLikesToProduct = async (updateObject, currentUserId) => {
    if (!currentUser) {
      alert(t('login-required'));
      return;
    }
    const productRef = doc(db, 'stores', storeId, 'products', productId);
    const productSnapshot = await getDoc(productRef);
    const productData = productSnapshot.data();

    if (
      productData.likeUsers &&
      productData.likeUsers.includes(currentUserId)
    ) {
      console.log('User already liked this product.');
      return;
    }

    try {
      await updateDoc(productRef, updateObject);
      console.log('Document successfully updated!');

      setProduct((prevState) => ({
        ...prevState,
        likes: prevState.likes + 1,
        likeUsers: Array.isArray(prevState.likeUsers)
          ? [...prevState.likeUsers, currentUserId]
          : [currentUserId],
      }));
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const updateMyLikeList = async (userId, productId, isAdding) => {
    if (!currentUser) {
      alert(t('login-required'));
      return;
    }

    const userLikesRef = doc(db, 'stores', storeId, 'userLikes', userId);

    try {
      const docSnapshot = await getDoc(userLikesRef);

      if (docSnapshot.exists()) {
        const likesProductIds = docSnapshot.data().products || [];

        if (isAdding) {
          if (!likesProductIds.includes(productId)) {
            likesProductIds.push(productId);
            await updateDoc(userLikesRef, { products: likesProductIds });
          }
        } else {
          const index = likesProductIds.indexOf(productId);
          if (index > -1) {
            likesProductIds.splice(index, 1);
            await updateDoc(userLikesRef, { products: likesProductIds });
          }
        }
      } else if (isAdding) {
        await setDoc(userLikesRef, { products: [productId] });
      }
    } catch (err) {
      console.error('Error handling like list:', err);
    }
  };

  const addViewsCount = async () => {
    if (!currentUser) {
      alert(t('login-required'));
      return;
    }
    if (!product) {
      alert('product is null');
      return;
    }

    logViewedStoreProduct(currentUser.uid, product.uid);

    if (currentUser.uid === product.uid) {
      return;
    }
    if (product.viewUsers && product.viewUsers.includes(currentUser.uid)) {
      return;
    }

    const updateObject = {
      views: increment(1),
      viewUsers: arrayUnion(currentUser.uid),
    };
    const productRef = doc(db, 'stores', storeId, 'products', productId);

    try {
      await updateDoc(productRef, updateObject);
    } catch (error) {
      console.error('Error updating product views:', error);
      alert(t('view-update-failed'));
    }
  };

  const goToDelete = async () => {
    if (!currentUser) {
      alert(t('login-required'));
      return;
    }
    if (!window.confirm(t('delete-confirm'))) {
      return;
    }

    try {
      const productRef = doc(db, 'stores', storeId, 'products', productId);
      await deleteDoc(productRef);

      const chatroomQuery = query(
        collection(db, 'stores', storeId, 'chatroom'),
        where('productId', '==', productId)
      );
      const chatrooms = await getDocs(chatroomQuery);

      const batch = writeBatch(db);

      chatrooms.forEach((docSnapshot) => {
        batch.delete(docSnapshot.ref);
      });
      await batch.commit();

      const userLikesQuery = query(
        collection(db, 'stores', storeId, 'userLikes'),
        where('products', 'array-contains', productId)
      );
      const usersWithLikes = await getDocs(userLikesQuery);

      for (const docSnapshot of usersWithLikes.docs) {
        const userRef = doc(db, 'userLikes', docSnapshot.id);
        await updateDoc(userRef, {
          products: arrayRemove(productId),
        });
      }

      alert(t('delete-success'));
      navigate('/');
    } catch (err) {
      console.error('Error deleting product and related chats:', err);
      alert(t('delete-failed'));
    }
  };

  const goToChat = async () => {
    if (!currentUser) {
      alert(t('login-required'));
      return;
    }

    const productOwnerId = product.uid;

    if (currentUser.uid === productOwnerId) {
      alert(t('chat-not-allowed'));
      return;
    }

    const participants = [currentUser.uid, productOwnerId];

    try {
      const chatroomQuery = query(
        collection(db, 'stores', storeId, 'chatroom'),
        where('who', 'array-contains', currentUser.uid)
      );
      const chatroomSnapshot = await getDocs(chatroomQuery);

      const chatroom = chatroomSnapshot.docs.find((doc) =>
        doc.data().who.includes(currentUser.uid)
      );

      let chatRoomId;

      if (chatroom) {
        chatRoomId = chatroom.id;
      } else {
        const chatRoomData = {
          who: participants,
          productId: productId,
          productTitle: product.title,
          date: new Date(),
        };

        try {
          const newChatRoomRef = await addDoc(
            collection(db, 'stores', storeId, 'chatroom'),
            chatRoomData
          );
          chatRoomId = newChatRoomRef.id;
        } catch (error) {
          console.error('Error adding document to chatroom:', error);
        }

        const productRef = doc(db, 'stores', storeId, 'products', productId);
        await updateDoc(productRef, {
          chats: increment(1),
        });
      }

      navigate(`/storechatview/${storeId}/${chatRoomId}`);
    } catch (err) {
      console.error('Error accessing or creating chat room:', err);
      alert(t('start-chat-error'));
    }
  };

  const goToEdit = async () => {
    if (!currentUser) {
      alert(t('login-required'));
      return;
    }
    navigate(`/edit/${productId}`);
  };

  const fileClick = (index) => {
    navigate('/imageviewer', { state: { files: metaFiles, index: index } });
  };

  const thumbnailClick = (index) => {
    setActiveImageIndex(index);
  };

  const onIndexChange = (index) => {
    setActiveImageIndex(index);
  };

  const handleFileClick = (url) => {
    const idx = product.images.findIndex((file) => file === url);
    fileClick(idx);
  };
  const handleAvatarClick = () => {
    navigate(`/user_profile/${productOwnerId}`);
  };
  const handleStoreProductClick = (product) => {
    window.open(
      `/product_detail/${product.uid}/${product.id}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  useEffect(() => {
    imageRefs.current = imageRefs.current.slice(
      0,
      product?.icon_images?.length || 0
    );

    if (imageRefs.current[activeImageIndex]) {
      // Ensure the element exists before applying the style
      imageRefs.current[activeImageIndex].style.border = '2px solid blue';
    }
  }, [activeImageIndex, product?.icon_images]);

  if (status.loading) {
    return <div>{t('loading-product-details')}</div>;
  }

  if (status.error) {
    return (
      <div>
        {t('error.errorMessage')}: {status.error}
      </div>
    );
  }

  return (
    <Box
      p={2}
      sx={{
        maxWidth: '100%',
        margin: isMobile ? 0 : 'auto',
        marginBottom: isMobile ? 0 : '70px',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {Array.isArray(product?.xlarge_images) &&
            product.xlarge_images.length > 0 && (
              <ImageSlider
                activeIndex={activeImageIndex}
                images={product.xlarge_images}
                fileClick={fileClick}
                onIndexChange={onIndexChange}
              />
            )}
          <Paper
            variant="outlined"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
              p: 1,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              maxWidth: '600px',
              margin: isMobile ? 0 : 'auto',
            }}
          >
            {product?.icon_images?.map((image, index) => (
              <IconButton
                key={index}
                onClick={() => setActiveImageIndex(index)}
                sx={{
                  border:
                    index === activeImageIndex ? '2px solid blue' : 'none',
                  width: { xs: 45, sm: 60, md: 80 }, // 반응형 크기 조정
                  height: { xs: 35, sm: 45, md: 60 }, // 반응형 크기 조정
                  borderRadius: 0,
                  padding: '1px',
                  '& img': {
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    margin: 0,
                  },
                }}
              >
                <img src={`${image}`} alt={`Preview ${index}`} />
              </IconButton>
            ))}
          </Paper>
        </Grid>
        <FloatingCartButton />
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">
            {t('store')}: <strong>{storeName}</strong>
          </Typography>
          <Typography variant="subtitle1">
            {t('title')}: <strong>{product?.productName}</strong>
          </Typography>
          <Typography variant="subtitle1">
            {t('content')}: <strong>{product?.description}</strong>
          </Typography>
          <Typography variant="subtitle1">
            {t('date')}: <strong>{product?.formattedDate}</strong>
          </Typography>
          <Typography variant="subtitle1">
            {t('quantity')}: <strong>{product?.stock}</strong>
          </Typography>
          {/*<Typography variant="subtitle1">
             {product?.discountedPrice && product?.discountedPrice > 0 ? (
              <>
                {t('original-price')}:{' '}
                <span
                  style={{
                    textDecoration: 'line-through',
                    color: '#888',
                  }}
                >
                  {formatPrice(product?.price)}원
                </span>
                <br />
                {t('discounted-price')}:{' '}
                <strong>{formatPrice(product?.discountedPrice)}원</strong>
                {discount?.discountType === 'percentage' ? (
                  <>
                    {' '}
                    (<strong>{discount?.discount}%</strong>)
                  </>
                ) : discount?.discountType === 'amount' ? (
                  <>
                    {' '}
                    (<strong>{formatPrice(discount?.discount)}원</strong>)
                  </>
                ) : null}
              </>
            ) : (
              <>
                {t('price')}: <strong>{formatPrice(product?.price)}원</strong>
              </>
            )}
          </Typography> */}
          {/* <Typography variant="subtitle1">
            {isEventActive ? (
              <>
                {t('original-price')}:{' '}
                <span
                  style={{
                    textDecoration: 'line-through',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {formatPrice(product?.price)}원
                </span>
                <br />
                <span
                  style={{
                    color: theme.palette.text.secondary, // 대비 색상 적용
                    backgroundColor: theme.palette.secondary.main, // 배경색 추가
                    padding: '2px 4px',
                    borderRadius: '4px',
                  }}
                >
                  {`${weatherEvent.name[i18n.language]} ${
                    weatherEvent.discount
                  }% OFF`}
                </span>
                <br />
                {t('discounted-price')}:{' '}
                <strong>
                  {formatPrice(
                    isEventActive
                      ? Math.round(
                          product?.price * (1 - weatherEvent?.discount / 100)
                        )
                      : product.price
                  )}
                  원
                </strong>
              </>
            ) : (
              <>
                {product?.discountedPrice && product?.discountedPrice > 0 ? (
                  <>
                    {t('original-price')}:{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: '#888',
                      }}
                    >
                      {formatPrice(product?.price)}원
                    </span>
                    <br />
                    {t('discounted-price')}:{' '}
                    <strong>{formatPrice(product?.discountedPrice)}원</strong>
                    {discount?.discountType === 'percentage' ? (
                      <>
                        {' '}
                        (<strong>{discount?.discount}%</strong>)
                      </>
                    ) : discount?.discountType === 'amount' ? (
                      <>
                        {' '}
                        (<strong>{formatPrice(discount?.discount)}원</strong>)
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    {t('price')}:{' '}
                    <strong>{formatPrice(product?.price)}원</strong>
                  </>
                )}
              </>
            )}
          </Typography>
          {weatherEvent && (
            <Box
              sx={{
                //position: 'absolute',
                color: isEventActive
                  ? theme.palette.common.white
                  : theme.palette.text.secondary,
                backgroundColor: isEventActive
                  ? theme.palette.secondary.main
                  : theme.palette.primary.papper,
                borderRadius: theme.shape.borderRadius,
                px: 1.5,
                py: 0.8,
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              <img
                src={weatherEvent.iconUrl}
                alt={weatherEvent.name[i18n.language]}
                style={{ width: 20, height: 20, borderRadius: '50%' }}
              />

              <Typography variant="subtitle1">
                {t(
                  isEventActive
                    ? 'weather.discount_applied'
                    : 'weather.discount_not_applied',
                  {
                    name: weatherEvent.name[i18n.language],
                    discount: weatherEvent.discount,
                  }
                )}
              </Typography>
            </Box>
          )} */}
          <WeatherEventDisplay
            weatherEvent={weatherEvent}
            isEventActive={isEventActive}
          />
          <WeatherPriceDisplay
            product={product}
            weatherEvent={weatherEvent}
            isEventActive={isEventActive}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Avatar
              src={productOwnerPhotoURL}
              alt={productOwnerName}
              sx={{ width: 40, height: 40, mr: 2 }}
              onClick={handleAvatarClick}
            />
            <Typography variant="subtitle1">
              {t('owner')}: <strong>{storeName}</strong>
            </Typography>
          </Box>
          <StoreProductPurshase
            storeId={storeId}
            product={product}
            userId={currentUser?.uid}
          />
          <Grid container justifyContent="flex-end">
            <IconButton>
              {product?.likes} <FavoriteIcon />
            </IconButton>
            <IconButton>
              {product?.views} <VisibilityIcon />
            </IconButton>
            <IconButton>
              {product?.chats} <ChatIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <StoreProductTabs storeId={storeId} productData={product} />

      <Box sx={{ mt: 5 }}>
        <Typography variant="h6">
          {storeName} {t('products-sold')} ({products.length} {t('items')})
        </Typography>
        <Grid container spacing={2}>
          {products.length === 0 ? (
            <Grid item xs={12}>
              <Typography textAlign="center">{t('no-products')}</Typography>
            </Grid>
          ) : isMobile ? (
            <Box
              id="parentScrollBox"
              sx={{
                marginTop: '20px',
                height: 'calc(80vh - 70px)',
                overflow: 'auto',
                width: '100%', // 추가된 부분
              }}
            >
              <InfiniteScroll
                dataLength={products.length}
                next={loadMoreUserProducts}
                hasMore={hasMoreUserProducts}
                loader={<Typography>{t('loading')}</Typography>}
                endMessage={
                  <Typography variant="body2">{t('noMoreProducts')}</Typography>
                }
                scrollableTarget="parentScrollBox"
              >
                {products.map((product) => (
                  <Box key={product.id} sx={{ mb: 2 }}>
                    <StoreProduct
                      product={product}
                      onClick={() => handleStoreProductClick(product)}
                      horizontal={isMobile} // pass the prop for mobile view
                    />
                  </Box>
                ))}
              </InfiniteScroll>
            </Box>
          ) : (
            products.map((product) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id}>
                <StoreProduct
                  key={product.id}
                  product={product}
                  onClick={() => handleStoreProductClick(product)}
                  horizontal={isMobile}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>

      <SimilarStoreProducts storeId={storeId} />

      <Box
        sx={{
          position: 'fixed',
          bottom: isMobile ? 50 : 0,
          left: 0,
          width: '100%',
          bgcolor: 'background.paper',
          px: 2,
          py: 1,
          boxShadow: 1,
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          {currentUser && currentUser.uid === productOwnerId ? (
            <React.Fragment>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={goToEdit}
                  fullWidth
                >
                  {t('edit')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={goToDelete}
                  fullWidth
                >
                  {t('delete')}
                </Button>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={6}>
                <Button onClick={handleLike} fullWidth>
                  {isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  {t('like')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button startIcon={<ChatIcon />} onClick={goToChat} fullWidth>
                  {t('chat')}
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default StoreProductDetail;
