export const haversineDistance = (coords1, coords2) => {
  const toRad = (value) => (value * Math.PI) / 180;

  const lat1 = coords1.latitude;
  const lon1 = coords1.longitude;
  const lat2 = coords2.latitude;
  const lon2 = coords2.longitude;

  const R = 6371; // Earth's radius in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

export const getBoundingBox = (lat, lng, radius) => {
  const latDiff = radius / 111; // 1 degree ≈ 111km
  const lngDiff = radius / (111 * Math.cos((lat * Math.PI) / 180));

  return {
    minLat: lat - latDiff,
    maxLat: lat + latDiff,
    minLng: lng - lngDiff,
    maxLng: lng + lngDiff,
  };
};
