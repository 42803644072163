// import { createTheme } from '@mui/material/styles';

// export const lightTheme = createTheme({
//   typography: {
//     fontFamily: "'Roboto', 'Arial', sans-serif",
//     fontWeightLight: 300,
//     fontWeightRegular: 400,
//     fontWeightMedium: 500,
//     fontWeightBold: 700, // 이 부분을 기본값으로 설정
//   },
//   palette: {
//     mode: 'light',
//     primary: { main: '#F9FFFF' },
//     secondary: { main: '#dc004e' },
//     text: {
//       primary: '#000000',
//     },
//     background: {
//       paper: '#FFFFFF',
//       default: '#CCCCCC',
//     },
//   },
//   linkColor: '#007AFF',
//   backgroundColor: '#FFFFFF',
//   primaryColor: '#007AFF',
//   textColor: '#222222',
//   buttonColor: '#BB86FC',
//   buttonTextColor: '#E0E0E0',
//   errorTextColor: '#DC3545',
//   iconBackground: '#E5E5E5',
//   secondaryTextColor: '#555555',
//   borderColor: '#E0E0E0',
//   emptyMessageColor: '#999999',
//   progressBarColor: '#007AFF',
//   senderBackground: '#DCF8C6',
//   receiverBackground: '#FFFFFF',
//   failedBorderColor: '#FF4D4D',
//   failedBackground: 'rgba(255, 77, 77, 0.3)', // 🔹 기존보다 어둡게 변경
//   pendingBorderColor: '#FFA500',
//   pendingBackground: 'rgba(243, 156, 18, 0.4)',
//   // Header
//   headerBackground: '#FFFFFF', // 추가
//   headerTextColor: '#222222', // 추가
//   activeTabColor: '#007AFF',
//   inactiveTabColor: '#CCCCCC',
//   tabBackgroundColor: '#FFFFFF',
//   tabBarShadowColor: '#E5E5E5',
//   dividerColor: '#E0E0E0',
//   successColor: '#28A745',
//   warningColor: '#FFC107',
//   errorColor: '#DC3545',
//   infoColor: '#17A2B8',
//   // Button
//   button: {
//     background: '#007AFF',
//     textColor: '#FFFFFF',
//     outlineColor: '#007AFF',
//     disabledBackground: '#E0E0E0',
//     disabledTextColor: '#999999',
//     hoverBackground: '#0056B3',
//     activeBackground: '#003C8F',
//     focusedOutlineColor: '#80CFFF',
//   },

//   // Card & Components
//   card: {
//     background: '#FAFAFA',
//     borderColor: '#E0E0E0',
//     shadowColor: 'rgba(0, 0, 0, 0.1)',
//     priceTextColor: '#007AFF',
//     onSaleBackground: '#E3F2FD',
//     onSaleTextColor: '#FFFFFF',
//     reservedBackground: '#FFF8E1',
//     reservedTextColor: '#000000',
//     soldOutBackground: '#FFEBEE',
//     soldOutTextColor: '#FFFFFF',
//     activeBackground: '#BBDEFB', // 밝은 하늘색 (강조)
//     inactiveBackground: '#F5F5F5', // 밝은 회색 (중립)
//     iconColor: '#888888',
//     iconTextColor: '#222222',
//   },

//   input: {
//     background: '#FFFFFF',
//     text: '#333333',
//     placeholder: '#AAAAAA',
//     borderColor: '#CCCCCC',
//     focusBorderColor: '#007AFF',
//     errorBorderColor: '#FF0000',
//   },
//   inputBackground: '#FFFFFF',
//   inputText: '#333333',
//   inputPlaceholder: '#AAAAAA',
//   inputTextColor: '#333333',
//   inputFieldColor: '#FFFFFF',
//   placeholderColor: '#CCCCCC',
//   sendButtonTextColor: '#007AFF',
//   sendButtonColor: '#FFFFFF',
//   tab: {
//     activeColor: '#007AFF',
//     inactiveColor: '#CCCCCC',
//     background: '#FFFFFF',
//     textColor: '#555555',
//   },

//   notification: {
//     background: '#FFFFFF',
//     textColor: '#333333',
//   },

//   chart: {
//     primaryColor: '#007AFF',
//     secondaryColor: '#E3F2FD',
//     gridLineColor: '#CCCCCC',
//   },
//   temperatureSwitch: {
//     backgroundColor: '#77b1c7',
//     sliderColor: '#fff',
//     textColor: '#fff',
//   },
// });

// export const darkTheme = createTheme({
//   typography: {
//     fontFamily: "'Roboto', 'Arial', sans-serif",
//     fontWeightLight: 300,
//     fontWeightRegular: 400,
//     fontWeightMedium: 500,
//     fontWeightBold: 700, // 이 부분을 기본값으로 설정
//   },
//   palette: {
//     mode: 'dark',
//     primary: { main: '#bb86fc' },
//     secondary: { main: '#03dac6' },
//     background: {
//       paper: '#121212',
//       default: '#020202',
//     },
//     text: {
//       primary: '#FFFFFF',
//     },
//   },
//   linkColor: '#007AFF',
//   inputBorderColor: '#ccc',
//   placeholderTextColor: '#aaa',
//   backgroundColor: '#121212',
//   primaryColor: '#BB86FC',
//   textColor: '#E0E0E0',
//   buttonColor: '#007AFF',
//   buttonTextColor: '#E0E0E0',
//   errorTextColor: '#F44336',
//   iconBackground: '#333333',
//   secondaryTextColor: '#AAAAAA',
//   borderColor: '#444444',
//   emptyMessageColor: '#888888',
//   progressBarColor: '#BB86FC',
//   senderBackground: '#2C3E50',
//   receiverBackground: '#34495E',
//   failedBorderColor: '#E74C3C',
//   failedBackground: 'rgba(231, 76, 60, 0.4)',
//   pendingBorderColor: '#F39C12',
//   pendingBackground: 'rgba(243, 156, 18, 0.6)',
//   activeTabColor: '#BB86FC',
//   inactiveTabColor: '#555555',
//   tabBackgroundColor: '#1C1C1C',
//   tabBarShadowColor: '#000000',
//   dividerColor: '#333333',
//   successColor: '#66BB6A',
//   warningColor: '#FFA000',
//   errorColor: '#F44336',
//   // Header
//   headerBackground: '#1F1F1F', // 추가
//   headerTextColor: '#E0E0E0', // 추가
//   // Button
//   button: {
//     background: '#BB86FC',
//     textColor: '#121212',
//     outlineColor: '#BB86FC',
//     disabledBackground: '#333333',
//     disabledTextColor: '#757575',
//     hoverBackground: '#8854D0',
//     activeBackground: '#5629A3',
//     focusedOutlineColor: '#8854D0',
//   },
//   // Card & Components
//   card: {
//     background: '#1F1F1F',
//     borderColor: '#444444',
//     shadowColor: 'rgba(0, 0, 0, 0.5)',
//     priceTextColor: '#BB86FC',
//     onSaleBackground: '#37474F',
//     onSaleTextColor: '#FFFFFF',
//     reservedBackground: '#5D4037',
//     reservedTextColor: '#FFFFFF',
//     soldOutBackground: '#B71C1C',
//     soldOutTextColor: '#FFFFFF',
//     activeBackground: 'rgba(15, 15, 15, 0.9)', // 완전 블랙 (활성화)
//     inactiveBackground: 'rgba(5, 5, 5, 0.7)', // 거의 검은색 (비활성화)
//     iconColor: '#BBBBBB',
//     iconTextColor: '#FFFFFF',
//   },

//   input: {
//     background: '#1C1C1C',
//     text: '#E0E0E0',
//     placeholder: '#757575',
//     borderColor: '#555555',
//     focusBorderColor: '#BB86FC',
//     errorBorderColor: '#F44336',
//   },
//   inputBackground: '#1C1C1C',
//   inputText: '#E0E0E0',
//   inputPlaceholder: '#757575',
//   inputTextColor: '#FFFFFF',
//   inputFieldColor: '#2C2C2C',
//   placeholderColor: '#BBBBBB',
//   sendButtonColor: '#2C2C2C',
//   sendButtonTextColor: '#FFFFFF',
//   tab: {
//     activeColor: '#BB86FC',
//     inactiveColor: '#555555',
//     background: '#1C1C1C',
//     textColor: '#AAAAAA',
//   },

//   notification: {
//     background: '#2C2C2C',
//     textColor: '#E0E0E0',
//   },

//   chart: {
//     primaryColor: '#BB86FC',
//     secondaryColor: '#5D4037',
//     gridLineColor: '#555555',
//   },
//   temperatureSwitch: {
//     backgroundColor: '#1b3657',
//     sliderColor: '#437abd',
//     textColor: '#fff',
//   },
// });

import { createTheme } from '@mui/material/styles';

// ✅ Light Theme
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#38C8E6' },
    secondary: { main: '#dc004e' },
    background: {
      default: '#F9FFFF',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Arial', sans-serif",
    fontWeightBold: 700,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // 버튼 텍스트 대문자 변환 방지
          fontWeight: 500,
          borderRadius: '8px', // 둥근 모서리
          padding: '10px 20px',
        },
        containedPrimary: {
          backgroundColor: '#38C8E6',
          '&:hover': { backgroundColor: '#2FA5C2' },
        },
        outlinedPrimary: {
          borderColor: '#38C8E6',
          '&:hover': { borderColor: '#2FA5C2' },
        },
      },
    },
  },
});

// ✅ Dark Theme
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#bb86fc' },
    secondary: { main: '#03dac6' },
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Arial', sans-serif",
    fontWeightBold: 700,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          borderRadius: '8px',
          padding: '10px 20px',
        },
        containedPrimary: {
          backgroundColor: '#bb86fc',
          '&:hover': { backgroundColor: '#9e6bd9' },
        },
        outlinedPrimary: {
          borderColor: '#bb86fc',
          '&:hover': { borderColor: '#9e6bd9' },
        },
      },
    },
  },
});
