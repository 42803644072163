// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { db, collection, getDocs, query, where } from './firebaseConfig';
// import Product from './Product';
// import { Grid, Typography, Box } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// export default function SellList() {
//   const { t, i18n } = useTranslation();
//   const [sellingProducts, setSellingProducts] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const fetchMySellingProducts = async () => {
//     if (!currentUser) return [];
//     const sellingProductsQuery = query(
//       collection(db, 'products'),
//       where('uid', '==', currentUser.uid)
//     );
//     const sellingProductsSnapshot = await getDocs(sellingProductsQuery);
//     return sellingProductsSnapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//   };

//   useEffect(() => {
//     fetchMySellingProducts()
//       .then(setSellingProducts)
//       .catch((err) => {
//         console.error('Error fetching selling products:', err);
//       });
//   }, [currentUser]);

//   const handleProductClick = (product) => {
//     window.open(`/detail/${product.id}`, '_blank', 'noopener,noreferrer');
//   };

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//           my: '30px',
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('mySellingProducts')}
//         </Typography>
//       </Box>
//       <Grid container spacing={2}>
//         {sellingProducts.length === 0 ? (
//           <Grid item xs={12}>
//             <Typography textAlign="center">{t('noSellingProducts')}</Typography>
//           </Grid>
//         ) : (
//           sellingProducts.map((product) => (
//             <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id}>
//               <Product
//                 key={product.id}
//                 product={product}
//                 onClick={() => handleProductClick(product)}
//               />
//             </Grid>
//           ))
//         )}
//       </Grid>
//     </Box>
//   );
// }

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  startAfter,
  limit,
} from './firebaseConfig';
import Product from './Product';
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Paper,
  TablePagination,
  useMediaQuery,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@mui/material/styles';

export default function SellList() {
  const { t, i18n } = useTranslation();
  const [sellingProducts, setSellingProducts] = useState([]);
  const [lastProduct, setLastProduct] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const itemsPerPage = 10;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // const fetchMySellingProducts = async (
  //   pageSize = itemsPerPage,
  //   lastDoc = null
  // ) => {
  //   if (!currentUser) return [];
  //   let q = query(
  //     collection(db, 'products'),
  //     where('uid', '==', currentUser.uid),
  //     orderBy('createDate', 'desc'),
  //     limit(pageSize)
  //   );
  //   if (lastDoc) {
  //     q = query(q, startAfter(lastDoc));
  //   }
  //   const snapshot = await getDocs(q);
  //   const products = snapshot.docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),
  //   }));
  //   setLastProduct(snapshot.docs[snapshot.docs.length - 1]);
  //   setHasMore(products.length === pageSize);
  //   return products;
  // };

  const fetchMySellingProducts = async (
    pageSize = itemsPerPage,
    lastDoc = null
  ) => {
    if (!currentUser) return [];

    try {
      let q = query(
        collection(db, 'products'),
        where('uid', '==', currentUser.uid),
        orderBy('createDate', 'desc'),
        limit(pageSize)
      );

      if (lastDoc) {
        q = query(q, startAfter(lastDoc));
      }

      const snapshot = await getDocs(q);

      const products = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setLastProduct(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(products.length === pageSize);

      return products;
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error(
          'Firestore index error: Please create the required index in the Firestore console.',
          error
        );
      } else {
        console.error('Error fetching my selling products:', error);
      }
      return [];
    }
  };

  const loadMoreProducts = async () => {
    const newProducts = await fetchMySellingProducts(itemsPerPage, lastProduct);
    setSellingProducts((prev) => [...prev, ...newProducts]);
  };

  const handlePageChange = async (event, newPage) => {
    setPage(newPage);
    const newProducts = await fetchMySellingProducts(itemsPerPage, lastProduct);
    setSellingProducts(newProducts);
  };

  useEffect(() => {
    fetchMySellingProducts()
      .then((products) => {
        setSellingProducts(products);
        setTotal(products.length);
      })
      .catch((err) => {
        console.error('Error fetching selling products:', err);
      });
  }, [currentUser]);

  const handleProductClick = (product) => {
    window.open(`/detail/${product.id}`, '_blank', 'noopener,noreferrer');
  };
  if (!currentUser) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100vw', // ✅ 전체 너비
        minHeight: '100vh', // ✅ 전체 높이
        backgroundColor: theme.palette.background.default, // ✅ 전체 배경색 적용 (다크모드 지원)
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', // 중앙 정렬
      }}
    >
      <Container
        maxWidth="lg"
        sx={{ backgroundColor: theme.palette.background.paper, py: 4 }}
      >
        {/* 🔹 타이틀 박스 */}
        {/* <Box
          sx={{
            borderRadius: '12px',
            padding: '12px 2px',
            mb: 3,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              transform: 'scale(1.02)',
              boxShadow: theme.shadows[4],
            },
            textAlign: 'left',
          }}
        > */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            paddingBottom: theme.spacing(2),
            color: theme.palette.primary.main,
          }}
        >
          {t('mySellingProducts')}
        </Typography>
        {/* </Box> */}
        <Grid container spacing={2}>
          {sellingProducts.length === 0 ? (
            <Grid item xs={12}>
              <Typography
                textAlign="center"
                sx={{ color: theme.palette.text.secondary }}
              >
                {t('noSellingProducts')}
              </Typography>
            </Grid>
          ) : (
            <>
              {isMobile ? (
                <Box
                  id="parentScrollBox"
                  sx={{
                    marginTop: '20px',
                    height: 'calc(80vh - 70px)',
                    overflow: 'auto',
                  }}
                >
                  <InfiniteScroll
                    dataLength={sellingProducts.length}
                    next={loadMoreProducts}
                    hasMore={hasMore}
                    loader={
                      <Typography sx={{ color: theme.palette.text.secondary }}>
                        {t('loading')}
                      </Typography>
                    }
                    endMessage={
                      <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {t('noMoreProducts')}
                      </Typography>
                    }
                    scrollableTarget="parentScrollBox"
                  >
                    <Grid container spacing={2}>
                      {sellingProducts.map((product) => (
                        <Grid item xs={12} key={product.id}>
                          <Product
                            product={product}
                            onClick={() => handleProductClick(product)}
                            horizontal={isMobile}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </InfiniteScroll>
                </Box>
              ) : (
                <>
                  <Grid container spacing={2}>
                    {sellingProducts.map((product) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={3}
                        key={product.id}
                      >
                        <Product
                          product={product}
                          onClick={() => handleProductClick(product)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <TablePagination
                    component="div"
                    count={total}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={itemsPerPage}
                    rowsPerPageOptions={[itemsPerPage]}
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                      borderTop: `1px solid ${theme.palette.divider}`,
                    }}
                  />
                </>
              )}
            </>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
//   if (!currentUser) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="100vh"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3',
//           borderRadius: '16px',
//           padding: '8px 16px',
//           marginBottom: '16px',
//           backgroundColor: 'transparent',
//           transition: 'transform 0.2s',
//           '&:hover': {
//             transform: 'scale(1.02)',
//           },
//           my: '30px',
//         }}
//       >
//         <Typography variant="h4" component="h3" gutterBottom>
//           {t('mySellingProducts')}
//         </Typography>
//       </Box>
//       <Grid container spacing={2}>
//         {sellingProducts.length === 0 ? (
//           <Grid item xs={12}>
//             <Typography textAlign="center">{t('noSellingProducts')}</Typography>
//           </Grid>
//         ) : (
//           <>
//             {isMobile ? (
//               <Box
//                 id="parentScrollBox"
//                 sx={{
//                   marginTop: '20px',
//                   height: 'calc(80vh - 70px)',
//                   overflow: 'auto',
//                 }}
//               >
//                 <InfiniteScroll
//                   dataLength={sellingProducts.length}
//                   next={loadMoreProducts}
//                   hasMore={hasMore}
//                   loader={<Typography>{t('loading')}</Typography>}
//                   endMessage={
//                     <Typography variant="body2">
//                       {t('noMoreProducts')}
//                     </Typography>
//                   }
//                   scrollableTarget="parentScrollBox"
//                 >
//                   <Grid container spacing={2}>
//                     {sellingProducts.map((product) => (
//                       <Grid item xs={12} key={product.id}>
//                         <Product
//                           product={product}
//                           onClick={() => handleProductClick(product)}
//                           horizontal={isMobile}
//                         />
//                       </Grid>
//                     ))}
//                   </Grid>
//                 </InfiniteScroll>
//               </Box>
//             ) : (
//               <>
//                 <Grid container spacing={2}>
//                   {sellingProducts.map((product) => (
//                     <Grid
//                       item
//                       xs={12}
//                       sm={6}
//                       md={4}
//                       lg={3}
//                       xl={2}
//                       key={product.id}
//                     >
//                       <Product
//                         product={product}
//                         onClick={() => handleProductClick(product)}
//                       />
//                     </Grid>
//                   ))}
//                 </Grid>
//                 <TablePagination
//                   component="div"
//                   count={total}
//                   page={page}
//                   onPageChange={handlePageChange}
//                   rowsPerPage={itemsPerPage}
//                   rowsPerPageOptions={[itemsPerPage]}
//                 />
//               </>
//             )}
//           </>
//         )}
//       </Grid>
//     </Box>
//   );
// }
