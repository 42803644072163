import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  Paper,
  Button,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import Product from './Product';
import useUserActivity from './useUserActivity';
import useStoreUserActivity from './Stores/useStoreUserActivity';
import {
  exportFetchSelectedTownByUserId,
  exportFetchUserProductsByCoordinates,
  exportFetchStoreProductsByCoordinates,
  exportFetchUserProductsByLastDocAsMap,
  exportFetchStoresByLastDocAsMap,
} from './Utils/LocationStoresAndProducts';
import StoreProduct from './Stores/StoreProduct';
import Lottie from 'lottie-react';

import NoStoreProducts from './NoStoreProducts';
import NoUserProducts from './NoUserProducts';

const PAGE_SIZE = 12;

const ProductCategorySearchResults = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [storeProducts, setStoreProducts] = useState([]);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const searchCategory = useSelector((state) => state.search.searchCategory);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userLastDoc, setUserLastDoc] = useState(null);
  const [storeLastDoc, setStoreLastDoc] = useState(null);
  const [userTotalProducts, setUserTotalProducts] = useState(0);
  const [storeTotalProducts, setStoreTotalProducts] = useState(0);
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate('/');
  };

  const { logViewedProduct } = useUserActivity(currentUser?.uid);
  const { logViewedStoreProduct } = useStoreUserActivity(currentUser?.uid);

  useEffect(() => {
    if (searchCategory) {
      handleSearchCategory(searchCategory, null, null, true);
    }
  }, [searchCategory]);

  async function handleSearchCategory(
    category,
    userLastDoc,
    storeLastDoc,
    reset = false
  ) {
    setLoading(true);
    setError(null);

    try {
      let location = null;
      if (currentUser) {
        location = await exportFetchSelectedTownByUserId(currentUser?.uid);
      }

      if (!currentUser || !location) {
        const allUserProducts = await searchAllUserProducts(
          category,
          userLastDoc
        );
        const allStoreProducts = await searchAllStoreProducts(
          category,
          storeLastDoc
        );

        if (reset) {
          setProducts(allUserProducts.products);
          setStoreProducts(allStoreProducts.products);
        } else {
          setProducts((prevProducts) => [
            ...prevProducts,
            ...allUserProducts.products,
          ]);
          setStoreProducts((prevStoreProducts) => [
            ...prevStoreProducts,
            ...allStoreProducts.products,
          ]);
        }

        setUserTotalProducts(allUserProducts.totalCount);
        setStoreTotalProducts(allStoreProducts.totalCount);
        setUserLastDoc(allUserProducts.lastDoc);
        setStoreLastDoc(allStoreProducts.lastDoc);
      } else {
        const productResults = await searchUserProducts(
          category,
          location,
          userLastDoc
        );
        const storeProductResults = await searchStoreProducts(
          category,
          location,
          storeLastDoc
        );

        if (reset) {
          setProducts(productResults.products);
          setStoreProducts(storeProductResults.products);
        } else {
          setProducts((prevProducts) => [
            ...prevProducts,
            ...productResults.products,
          ]);
          setStoreProducts((prevStoreProducts) => [
            ...prevStoreProducts,
            ...storeProductResults.products,
          ]);
        }

        setUserTotalProducts(productResults.totalCount);
        setStoreTotalProducts(storeProductResults.totalCount);
        setUserLastDoc(productResults.lastDoc);
        setStoreLastDoc(storeProductResults.lastDoc);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function searchUserProducts(category, location, lastDoc) {
    try {
      const userProductsArray = await exportFetchUserProductsByCoordinates(
        location.lat,
        location.lng,
        location.radius,
        currentUser.uid,
        PAGE_SIZE,
        lastDoc
      );

      if (!Array.isArray(userProductsArray)) {
        throw new Error('Expected an array but got something else');
      }

      const filteredProducts = userProductsArray.filter(
        (product) => product.categoryId === category.id
      );

      return {
        products: filteredProducts,
        lastDoc: userProductsArray.lastDoc,
        total: filteredProducts.length,
      };
    } catch (error) {
      console.error('Error fetching user products:', error);
      return {
        products: [],
        lastDoc: null,
        total: 0,
      };
    }
  }

  async function searchStoreProducts(category, location, lastDoc) {
    try {
      const storeProducts = await exportFetchStoreProductsByCoordinates(
        location.lat,
        location.lng,
        location.radius,
        currentUser.uid,
        PAGE_SIZE,
        lastDoc
      );

      // Ensure storeProducts.products is always an array
      const productsArray = Object.values(storeProducts.products || {});

      const filteredStoreProducts = productsArray.filter(
        (product) => product.categoryId === category.id
      );

      return {
        products: filteredStoreProducts,
        lastDoc: storeProducts.lastDoc,
        total: filteredStoreProducts.length,
      };
    } catch (error) {
      console.error('Error fetching store products:', error);
      return {
        products: [],
        lastDoc: null,
        total: 0,
      };
    }
  }

  async function searchAllUserProducts(category, lastDoc) {
    try {
      const result = await exportFetchUserProductsByLastDocAsMap(
        PAGE_SIZE,
        lastDoc
      );
      const products = result.products || [];

      const filteredProducts = products.filter(
        (product) => product.categoryId === category.id
      );

      return {
        products: filteredProducts,
        lastDoc: result.lastDoc,
        total: filteredProducts.length,
      };
    } catch (error) {
      console.error('Error fetching all user products:', error);
      return {
        products: [],
        lastDoc: null,
        total: 0,
      };
    }
  }

  async function searchAllStoreProducts(category, lastDoc) {
    try {
      const result = await exportFetchStoresByLastDocAsMap(PAGE_SIZE, lastDoc);

      // Check if result.products is an array
      if (!Array.isArray(result.products)) {
        throw new Error('Expected an array but got something else');
      }

      // Filter products by category ID
      const filteredProducts = result.products.filter(
        (product) => product.categoryId === category.id
      );

      return {
        products: filteredProducts,
        lastDoc: result.lastDoc,
        total: filteredProducts.length,
      };
    } catch (error) {
      console.error('Error fetching all store products:', error);
      return {
        products: [],
        lastDoc: null,
        total: 0,
      };
    }
  }

  const handleProductClick = (productId) => {
    logViewedProduct(productId);
  };

  const handleStoreProductClick = (storeId, productId) => {
    logViewedStoreProduct(storeId, productId);
  };

  const handleUserPageChange = async () => {
    await handleSearchCategory(searchCategory, userLastDoc, storeLastDoc);
  };

  const handleStorePageChange = async () => {
    await handleSearchCategory(searchCategory, userLastDoc, storeLastDoc);
  };

  return (
    <>
      {/* <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label={t('navigation.home')}
            onClick={handleNavigateHome}
          >
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar> */}
      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          color: theme.palette.text.primary,

          mx: 'auto',
          px: { xs: 2, sm: 3, md: 4 }, // ✅ 반응형 패딩 적용
          mt: 3,
          minHeight: '100vh',
        }}
      >
        {loading && !products.length && !storeProducts.length ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
          >
            <Typography color="error">{`${t(
              'error.errorMessage'
            )} ${error}`}</Typography>
          </Box>
        ) : (
          <>
            <Typography variant="h4">
              {t('categorySearchResults.title')}
            </Typography>
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              {t('categorySearchResults.userProducts')}
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{ mb: 4, justifyContent: 'center' }}
            >
              {products.length > 0 ? (
                products.map((product) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={product.id}
                  >
                    <Product
                      key={product.id}
                      product={product}
                      onClick={() => handleProductClick(product.id)}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={8}>
                  <NoUserProducts />
                  {/* <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
                    <Lottie
                      animationData={noUserProductResults}
                      style={{ height: 300 }}
                    />
                    <Typography>
                      {t('categorySearchResults.noUserResults')}
                    </Typography>
                  </Paper> */}
                </Grid>
              )}
            </Grid>
            {userLastDoc && products.length > 0 && (
              <Button onClick={handleUserPageChange}>{t('loadMore')}</Button>
            )}
            <Typography variant="h6" sx={{ mt: 4, mb: 4 }}>
              {t('categorySearchResults.storeProducts')}
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{ mb: 4, justifyContent: 'center' }}
            >
              {storeProducts.length > 0 ? (
                storeProducts.map((product) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={product.id}
                  >
                    <StoreProduct
                      key={product.id}
                      product={product}
                      onClick={() =>
                        handleStoreProductClick(currentUser?.uid, product.id)
                      }
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={8}>
                  <NoStoreProducts />
                  {/* <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
                    <Lottie
                      animationData={noStoreProductResults}
                      style={{ height: 300 }}
                    />
                    
                    <Typography>
                      {t('categorySearchResults.noStoreResults')}
                    </Typography>
                  </Paper> */}
                </Grid>
              )}
            </Grid>
            {storeLastDoc && storeProducts.length > 0 && (
              <Button onClick={handleStorePageChange}>{t('loadMore')}</Button>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ProductCategorySearchResults;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Box,
//   Grid,
//   Typography,
//   CircularProgress,
//   AppBar,
//   Toolbar,
//   IconButton,
//   Paper,
//   Button,
// } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { useTranslation } from 'react-i18next';
// import { makeStyles } from '@mui/styles';
// import Product from './Product';
// import useUserActivity from './useUserActivity';
// import useStoreUserActivity from './Stores/useStoreUserActivity';
// import {
//   exportFetchSelectedTownByUserId,
//   exportFetchUserProductsByCoordinates,
//   exportFetchStoreProductsByCoordinates,
//   exportFetchUserProductsByLastDocAsMap,
//   exportFetchStoresByLastDocAsMap,
// } from './Utils/LocationStoresAndProducts';
// import StoreProduct from './Stores/StoreProduct';
// import Lottie from 'lottie-react';
// import noUserProductResults from './assets/lottie/no-userproduct-results.json';
// import noStoreProductResults from './assets/lottie/no-storeproduct-results.json';

// const PAGE_SIZE = 12;

// const useStyles = makeStyles((theme) => ({
//   container: {
//     backgroundColor: theme.palette.background.default,
//     color: theme.palette.text.primary,
//     padding: theme.spacing(2),
//     minHeight: '100vh',
//   },
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//   },
//   toolbar: {
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
//   sectionTitle: {
//     marginTop: theme.spacing(4),
//     marginBottom: theme.spacing(2),
//   },
//   gridContainer: {
//     marginBottom: theme.spacing(4),
//   },
//   emptyState: {
//     textAlign: 'center',
//     padding: theme.spacing(4),
//   },
//   lottie: {
//     height: 300,
//     marginBottom: theme.spacing(2),
//   },
//   loadMoreButton: {
//     marginTop: theme.spacing(2),
//     marginBottom: theme.spacing(4),
//     padding: theme.spacing(1.5),
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.primary.contrastText,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//     },
//   },
//   errorText: {
//     color: theme.palette.error.main,
//     textAlign: 'center',
//     marginTop: theme.spacing(4),
//   },
// }));

// const ProductCategorySearchResults = () => {
//   const classes = useStyles();
//   const { t } = useTranslation();
//   const [products, setProducts] = useState([]);
//   const [storeProducts, setStoreProducts] = useState([]);
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const searchCategory = useSelector((state) => state.search.searchCategory);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [userLastDoc, setUserLastDoc] = useState(null);
//   const [storeLastDoc, setStoreLastDoc] = useState(null);
//   const navigate = useNavigate();

//   const handleNavigateHome = () => {
//     navigate('/');
//   };

//   const { logViewedProduct } = useUserActivity(currentUser?.uid);
//   const { logViewedStoreProduct } = useStoreUserActivity(currentUser?.uid);

//   useEffect(() => {
//     if (searchCategory) {
//       handleSearchCategory(searchCategory, null, null, true);
//     }
//   }, [searchCategory]);

//   async function handleSearchCategory(
//     category,
//     userLastDoc,
//     storeLastDoc,
//     reset = false
//   ) {
//     setLoading(true);
//     setError(null);

//     try {
//       let location = null;
//       if (currentUser) {
//         location = await exportFetchSelectedTownByUserId(currentUser?.uid);
//       }

//       if (!currentUser || !location) {
//         const allUserProducts = await searchAllUserProducts(
//           category,
//           userLastDoc
//         );
//         const allStoreProducts = await searchAllStoreProducts(
//           category,
//           storeLastDoc
//         );

//         if (reset) {
//           setProducts(allUserProducts.products);
//           setStoreProducts(allStoreProducts.products);
//         } else {
//           setProducts((prevProducts) => [
//             ...prevProducts,
//             ...allUserProducts.products,
//           ]);
//           setStoreProducts((prevStoreProducts) => [
//             ...prevStoreProducts,
//             ...allStoreProducts.products,
//           ]);
//         }

//         setUserLastDoc(allUserProducts.lastDoc);
//         setStoreLastDoc(allStoreProducts.lastDoc);
//       } else {
//         const productResults = await searchUserProducts(
//           category,
//           location,
//           userLastDoc
//         );
//         const storeProductResults = await searchStoreProducts(
//           category,
//           location,
//           storeLastDoc
//         );

//         if (reset) {
//           setProducts(productResults.products);
//           setStoreProducts(storeProductResults.products);
//         } else {
//           setProducts((prevProducts) => [
//             ...prevProducts,
//             ...productResults.products,
//           ]);
//           setStoreProducts((prevStoreProducts) => [
//             ...prevStoreProducts,
//             ...storeProductResults.products,
//           ]);
//         }

//         setUserLastDoc(productResults.lastDoc);
//         setStoreLastDoc(storeProductResults.lastDoc);
//       }
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   }

//   return (
//     <>
//       <AppBar position="sticky" className={classes.appBar}>
//         <Toolbar className={classes.toolbar}>
//           <IconButton
//             edge="start"
//             aria-label="Back"
//             onClick={handleNavigateHome}
//           >
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="h6">
//             {t('categorySearchResults.title')}
//           </Typography>
//         </Toolbar>
//       </AppBar>

//       <Box className={classes.container}>
//         {loading && !products.length && !storeProducts.length ? (
//           <Box
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             minHeight="50vh"
//           >
//             <CircularProgress />
//           </Box>
//         ) : error ? (
//           <Typography className={classes.errorText}>
//             {t('error.errorMessage')} {error}
//           </Typography>
//         ) : (
//           <>
//             <Typography variant="h5" className={classes.sectionTitle}>
//               {t('categorySearchResults.userProducts')}
//             </Typography>
//             <Grid container spacing={2} className={classes.gridContainer}>
//               {products.length > 0 ? (
//                 products.map((product) => (
//                   <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
//                     <Product
//                       product={product}
//                       onClick={() => logViewedProduct(product.id)}
//                     />
//                   </Grid>
//                 ))
//               ) : (
//                 <Paper elevation={3} className={classes.emptyState}>
//                   <Lottie
//                     animationData={noUserProductResults}
//                     className={classes.lottie}
//                   />
//                   <Typography>
//                     {t('categorySearchResults.noUserResults')}
//                   </Typography>
//                 </Paper>
//               )}
//             </Grid>

//             {userLastDoc && (
//               <Button
//                 className={classes.loadMoreButton}
//                 onClick={() =>
//                   handleSearchCategory(searchCategory, userLastDoc, null)
//                 }
//               >
//                 {t('loadMore')}
//               </Button>
//             )}

//             <Typography variant="h5" className={classes.sectionTitle}>
//               {t('categorySearchResults.storeProducts')}
//             </Typography>
//             <Grid container spacing={2} className={classes.gridContainer}>
//               {storeProducts.length > 0 ? (
//                 storeProducts.map((product) => (
//                   <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
//                     <StoreProduct
//                       product={product}
//                       onClick={() =>
//                         logViewedStoreProduct(currentUser?.uid, product.id)
//                       }
//                     />
//                   </Grid>
//                 ))
//               ) : (
//                 <Paper elevation={3} className={classes.emptyState}>
//                   <Lottie
//                     animationData={noStoreProductResults}
//                     className={classes.lottie}
//                   />
//                   <Typography>
//                     {t('categorySearchResults.noStoreResults')}
//                   </Typography>
//                 </Paper>
//               )}
//             </Grid>

//             {storeLastDoc && (
//               <Button
//                 className={classes.loadMoreButton}
//                 onClick={() =>
//                   handleSearchCategory(searchCategory, null, storeLastDoc)
//                 }
//               >
//                 {t('loadMore')}
//               </Button>
//             )}
//           </>
//         )}
//       </Box>
//     </>
//   );
// };

// export default ProductCategorySearchResults;
