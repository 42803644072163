// import React, { useEffect, useRef, useState, useCallback } from 'react';
// import {
//   storage,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from '../firebaseConfig';
// import PreviewImage from './PreviewImage';
// import { Button, Box, IconButton, Typography } from '@mui/material';
// import PhotoCamera from '@mui/icons-material/PhotoCamera';

// const FileUpload = ({
//   onMount,
//   onImageChange,
//   onUploadSuccess,
//   onUploadError,
//   onUploadProgress,
//   uploadPath = process.env.REACT_APP_USER_IMAGE_PATH,
//   multiple = true,
//   accept,
//   buttonText = 'Upload',
// }) => {
//   const [images, setImages] = useState([]);
//   const [previews, setPreviews] = useState([]);
//   const inputId = useRef(
//     `file-input-${Math.random().toString(36).substr(2, 9)}`
//   ); // 고유 ID 생성

//   const handleImageChange = useCallback(
//     (event) => {
//       const selectedImages = Array.from(event.target.files);

//       const readerPromises = selectedImages.map((file) => {
//         return new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = () => resolve(reader.result);
//           reader.onerror = reject;
//           reader.readAsDataURL(file);
//         });
//       });

//       Promise.all(readerPromises).then((results) => {
//         setImages(selectedImages);
//         setPreviews(results);
//         onImageChange && onImageChange(selectedImages, results);
//       });
//     },
//     [onImageChange]
//   );

//   const handleRemoveImage = useCallback((indexToRemove) => {
//     setImages((prevImages) =>
//       prevImages.filter((_, index) => index !== indexToRemove)
//     );
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   }, []);

//   const handleFileUpload = useCallback(async () => {
//     const uploadImage = (image) => {
//       const uploadPathRef = ref(storage, `${uploadPath}/${image.name}`);
//       return new Promise((resolve, reject) => {
//         const uploadTask = uploadBytesResumable(uploadPathRef, image);
//         uploadTask.on(
//           'state_changed',
//           (snapshot) => {
//             const progress =
//               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//             onUploadProgress && onUploadProgress(progress, snapshot);
//           },
//           reject,
//           async () => {
//             try {
//               const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
//               resolve(downloadURL);
//             } catch (error) {
//               reject(error);
//             }
//           }
//         );
//       });
//     };

//     try {
//       const downloadURLs = await Promise.all(images.map(uploadImage));
//       onUploadSuccess && onUploadSuccess(downloadURLs);
//     } catch (error) {
//       onUploadError && onUploadError(error);
//     }
//   }, [images, onUploadSuccess, onUploadError, onUploadProgress, uploadPath]);

//   const handleFileUploadRef = useRef();
//   handleFileUploadRef.current = handleFileUpload;

//   useEffect(() => {
//     if (onMount) {
//       onMount(handleFileUploadRef);
//     }
//   }, [onMount]);

//   return (
//     <Box>
//       <input
//         type="file"
//         multiple={multiple}
//         accept={accept}
//         style={{ display: 'none' }}
//         id={inputId.current}
//         onChange={handleImageChange}
//       />
//       <label htmlFor={inputId.current}>
//         <IconButton
//           color="primary"
//           aria-label="upload picture"
//           component="span"
//         >
//           <PhotoCamera />
//         </IconButton>
//       </label>

//       <Box
//         sx={{
//           display: 'flex',
//           flexWrap: 'wrap', // Enable wrapping
//           gap: 1, // Add a gap between items
//           mt: 2, // Margin top for spacing
//         }}
//       >
//         {previews &&
//           previews.map((preview, index) => (
//             <Box key={index} sx={{ width: 100, height: 100 }}>
//               <PreviewImage
//                 key={index} // Add unique key
//                 preview={preview}
//                 onRemove={() => handleRemoveImage(index)}
//               />
//             </Box>
//           ))}
//       </Box>
//     </Box>
//   );
// };

// export default FileUpload;

import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  db,
  storage,
  query,
  collection,
  doc,
  getDocs,
  where,
  updateDoc,
  arrayRemove,
} from '../firebaseConfig';
import {
  ref as storageRef,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import {
  Button,
  Box,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
import {
  Cancel as CancelIcon,
  CloudUpload as CloudUploadIcon,
  PhotoCamera,
  DeleteIcon,
} from '@mui/icons-material';
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
// import DeleteIcon from '@mui/icons-material/Delete';
import PreviewImage from './PreviewImage';
import { useTranslation } from 'react-i18next';
const FileUpload = forwardRef(
  (
    { initialFiles = {}, onFilesSelected, imageSizes, onUploadComplete },
    ref
  ) => {
    const { t } = useTranslation();
    const [selectedFiles, setSelectedFiles] = useState({});
    const [deletedFiles, setDeletedFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState({});
    const inputRef = useRef();

    // ✅ 초기 파일 설정 (이미 업로드된 파일)
    // useEffect(() => {
    //   if (!initialFiles || Object.keys(initialFiles).length === 0) return;

    //   // ✅ Firestore에서 불러온 이미지 데이터를 변환
    //   const formattedFiles = Object.entries(initialFiles).reduce(
    //     (acc, [collectionName, urls]) => {
    //       acc[collectionName] = urls.map((uri) => ({
    //         uri,
    //         isUploaded: true,
    //         collectionName,
    //       }));
    //       return acc;
    //     },
    //     {}
    //   );

    //   setSelectedFiles(formattedFiles);
    // }, [initialFiles]);
    useEffect(() => {
      if (!initialFiles || Object.keys(initialFiles).length === 0) return;

      console.log('🔄 Processing initialFiles:', initialFiles);

      // ✅ Firestore에서 불러온 이미지 데이터를 변환
      const formattedFiles = Object.entries(initialFiles).reduce(
        (acc, [collectionName, urls]) => {
          acc[collectionName] = urls.map((uri) => ({
            uri, // 🔹 Firestore에서 가져온 URL 그대로 사용
            isUploaded: true, // 🔹 이미 업로드된 상태로 표시
            collectionName,
          }));
          return acc;
        },
        {}
      );
      setSelectedFiles(formattedFiles);
    }, [initialFiles]); // ✅ 객체 비교 문제 해결

    const handleFileSelect = useCallback(async (event, type) => {
      const selectedImages = Array.from(event.target.files);

      if (selectedImages.length === 0) return;

      // ✅ 각 `imageSizes` 별로 변환된 이미지 생성
      const resizedImages = imageSizes.flatMap(
        ({ collectionName, width, height }) => {
          return selectedImages.map((image) => ({
            file: image,
            fileName: `resized_${Date.now()}_${image.name}`,
            collectionName,
            width,
            height,
            isUploaded: false,
          }));
        }
      );

      // ✅ 기존 파일 + 새로운 파일 합쳐서 업데이트
      setSelectedFiles((prevFiles) => {
        const updatedFiles = { ...prevFiles };
        resizedImages.forEach(({ collectionName, ...fileData }) => {
          if (!updatedFiles[collectionName]) updatedFiles[collectionName] = [];
          updatedFiles[collectionName].push(fileData);
        });
        return updatedFiles;
      });

      // ✅ 콜백 실행
      onFilesSelected && onFilesSelected(selectedFiles);
    });
    // ✅ 파일 선택 핸들러
    const handleFileSelect1 = async (event) => {
      const selectedImages = Array.from(event.target.files);

      if (selectedImages.length === 0) return;

      // ✅ 각 `imageSizes` 별로 변환된 이미지 생성
      const resizedImages = imageSizes.flatMap(
        ({ collectionName, width, height }) => {
          return selectedImages.map((image) => ({
            file: image,
            fileName: `resized_${Date.now()}_${image.name}`,
            collectionName,
            width,
            height,
            isUploaded: false,
          }));
        }
      );

      // ✅ 기존 파일 + 새로운 파일 합쳐서 업데이트
      setSelectedFiles((prevFiles) => {
        const updatedFiles = { ...prevFiles };
        resizedImages.forEach(({ collectionName, ...fileData }) => {
          if (!updatedFiles[collectionName]) updatedFiles[collectionName] = [];
          updatedFiles[collectionName].push(fileData);
        });
        return updatedFiles;
      });

      // ✅ 콜백 실행
      onFilesSelected && onFilesSelected(selectedFiles);
    };
    const findRelatedCollection = (currentCollection) => {
      return imageSizes
        .filter((size) => size.collectionName !== currentCollection) // 현재 collection 제외
        .map((size) => size.collectionName); // 상대방 collection 반환
    };

    const handleRemoveFile = useCallback((collectionName, index) => {
      setSelectedFiles((prevFiles) => {
        const updatedFiles = { ...prevFiles };

        // ✅ 현재 collection에서 삭제할 파일 가져오기
        const fileToRemove = updatedFiles[collectionName]?.[index];
        if (!fileToRemove) return prevFiles;

        const { uri, isUploaded } = fileToRemove;

        // ✅ 현재 collection에서 해당 index 삭제
        updatedFiles[collectionName] = updatedFiles[collectionName].filter(
          (_, i) => i !== index
        );

        // ✅ 상대방 collection 찾기
        const relatedCollections = findRelatedCollection(collectionName);

        // ✅ 모든 관련 collection에서 **같은 index**의 파일 삭제
        relatedCollections.forEach((relatedCollection) => {
          if (updatedFiles[relatedCollection]) {
            updatedFiles[relatedCollection] = updatedFiles[
              relatedCollection
            ].filter((_, i) => i !== index);
          }
        });

        // ✅ 빈 collection 정리
        Object.keys(updatedFiles).forEach((col) => {
          if (updatedFiles[col]?.length === 0) {
            delete updatedFiles[col];
          }
        });

        // ✅ 기존 업로드된 파일이면 `deletedFiles` 목록에 추가하여 Firebase 삭제 예정
        if (isUploaded) {
          setDeletedFiles((prevDeleted) => [
            ...prevDeleted,
            { uri, collections: [collectionName, ...relatedCollections] },
          ]);
        }

        console.log('🔄 선택된 파일 업데이트:', updatedFiles);
        return updatedFiles;
      });
    }, []);
    // ✅ 파일 삭제 핸들러
    // const handleRemoveFile = useCallback((collectionName, index) => {
    //   setSelectedFiles((prevFiles) => {
    //     const updatedFiles = { ...prevFiles };
    //     updatedFiles[collectionName] = updatedFiles[collectionName].filter(
    //       (_, i) => i !== index
    //     );

    //     // ✅ 모든 파일이 삭제된 경우 `collectionName` 제거
    //     if (updatedFiles[collectionName].length === 0) {
    //       delete updatedFiles[collectionName];
    //     }

    //     return updatedFiles;
    //   });
    // }, []);

    // ✅ Firebase Storage에서 개별 파일 삭제
    const deleteFileFromStorage = async (uri) => {
      try {
        const fileRef = storageRef(storage, uri);
        await deleteObject(fileRef);
        console.log(`✅ Storage 삭제 완료: ${uri}`);
      } catch (error) {
        console.error(`❌ Storage 삭제 실패: ${error}`);
      }
    };
    // 🔹 Firestore에서 모든 `collectionNames`에 대해 이미지 삭제
    const deleteFileFromFirestore = async (collectionName, uri) => {
      try {
        const productsQuery = query(
          collection(db, 'products'),
          where(collectionName, 'array-contains', uri)
        );
        const querySnapshot = await getDocs(productsQuery);

        querySnapshot.forEach(async (document) => {
          const productRef = doc(db, 'products', document.id);
          await updateDoc(productRef, {
            [collectionName]: arrayRemove(uri), // ✅ 해당 컬렉션에서만 삭제
          });
        });
      } catch (error) {
        console.error('❌ Firestore에서 파일 제거 오류:', error);
      }
      // try {
      //   await Promise.all(
      //     deletedFiles.map(async ({ uri, collectionName }) => {
      //       const productsQuery = query(
      //         collection(db, 'products'),
      //         where(collectionName, 'array-contains', uri)
      //       );
      //       const querySnapshot = await getDocs(productsQuery);

      //       querySnapshot.forEach(async (document) => {
      //         const productRef = doc(db, 'products', document.id);
      //         await updateDoc(productRef, {
      //           [collectionName]: arrayRemove(uri), // ✅ 해당 컬렉션에서만 삭제
      //         });
      //       });
      //     })
      //   );
      // } catch (error) {
      //   console.error('❌ Firestore에서 파일 제거 오류:', error);
      // }
    };
    // ✅ 파일 업로드 핸들러
    // const uploadFilesHandler = async () => {
    //   setUploading(true);
    //   const uploadedResults = {};

    //   try {
    //     // ✅ 삭제된 파일이 있으면 먼저 처리
    //     if (deletedFiles.length > 0) {
    //       console.log('🗑️ 삭제된 파일 처리 시작:', deletedFiles);
    //       await processDeletedFiles();
    //     }
    //     // ✅ `imageSizes` 기준으로 파일 업로드
    //     for (const { collectionName } of imageSizes) {
    //       if (
    //         !selectedFiles[collectionName] ||
    //         selectedFiles[collectionName].length === 0
    //       )
    //         continue;

    //       const uploadedUrls = await Promise.all(
    //         selectedFiles[collectionName].map(async (fileData, index) => {
    //           if (fileData.isUploaded) return fileData.url;

    //           const fileRef = storageRef(
    //             storage,
    //             `uploads/${fileData.fileName}`
    //           );
    //           const uploadTask = uploadBytesResumable(fileRef, fileData.file);

    //           return new Promise((resolve, reject) => {
    //             uploadTask.on(
    //               'state_changed',
    //               (snapshot) => {
    //                 const progress = Math.round(
    //                   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //                 );
    //                 setUploadProgress((prev) => ({
    //                   ...prev,
    //                   [`${collectionName}_${index}`]: progress,
    //                 }));
    //               },
    //               reject,
    //               async () => {
    //                 try {
    //                   const downloadURL = await getDownloadURL(
    //                     uploadTask.snapshot.ref
    //                   );
    //                   resolve(downloadURL);
    //                 } catch (error) {
    //                   reject(error);
    //                 }
    //               }
    //             );
    //           });
    //         })
    //       );

    //       uploadedResults[collectionName] = uploadedUrls;
    //     }

    //     // ✅ 업로드 완료 후 상태 업데이트
    //     setSelectedFiles((prevFiles) => {
    //       const updatedFiles = { ...prevFiles };
    //       Object.keys(uploadedResults).forEach((collectionName) => {
    //         updatedFiles[collectionName] = uploadedResults[collectionName].map(
    //           (url) => ({
    //             url,
    //             isUploaded: true,
    //           })
    //         );
    //       });
    //       return updatedFiles;
    //     });

    //     onUploadComplete && onUploadComplete(uploadedResults);
    //   } catch (error) {
    //     console.error('❌ 파일 업로드 오류:', error);
    //   } finally {
    //     setUploading(false);
    //   }
    // };
    const uploadFilesHandler = async () => {
      setUploading(true);
      const uploadedResults = {}; // ✅ 최종적으로 Firestore에 저장될 데이터

      try {
        // ✅ 1️⃣ 삭제된 파일 먼저 처리
        if (deletedFiles.length > 0) {
          console.log('🗑️ 삭제된 파일 처리 시작:', deletedFiles);
          await processDeletedFiles();
        }

        // ✅ 2️⃣ 새 파일 업로드
        for (const { collectionName } of imageSizes) {
          if (
            !selectedFiles[collectionName] ||
            selectedFiles[collectionName].length === 0
          ) {
            continue; // 🔹 해당 카테고리에 업로드할 파일이 없으면 건너뜀
          }

          const uploadedUrls = await Promise.all(
            selectedFiles[collectionName].map(async (fileData, index) => {
              if (fileData.isUploaded) return fileData.uri; // 🔹 이미 업로드된 파일은 다시 업로드하지 않음

              const fileRef = storageRef(
                storage,
                `uploads/${fileData.fileName}`
              );
              const uploadTask = uploadBytesResumable(fileRef, fileData.file);

              return new Promise((resolve, reject) => {
                uploadTask.on(
                  'state_changed',
                  (snapshot) => {
                    const progress = Math.round(
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setUploadProgress((prev) => ({
                      ...prev,
                      [`${collectionName}_${index}`]: progress,
                    }));
                  },
                  reject,
                  async () => {
                    try {
                      const downloadURL = await getDownloadURL(
                        uploadTask.snapshot.ref
                      );
                      resolve(downloadURL); // 🔹 업로드 완료 후 URL 반환
                    } catch (error) {
                      reject(error);
                    }
                  }
                );
              });
            })
          );

          uploadedResults[collectionName] = uploadedUrls; // 🔹 업로드된 파일 URL 저장
        }

        // ✅ 3️⃣ 상태 업데이트: `selectedFiles`에 새 파일 URL 적용
        setSelectedFiles((prevFiles) => {
          const updatedFiles = { ...prevFiles };
          Object.keys(uploadedResults).forEach((collectionName) => {
            updatedFiles[collectionName] = uploadedResults[collectionName].map(
              (url) => ({
                uri: url,
                isUploaded: true, // 🔹 업로드 완료된 파일로 표시
              })
            );
          });
          return updatedFiles;
        });

        // ✅ 4️⃣ Firestore 업데이트 (콜백 실행)
        onUploadComplete && onUploadComplete(uploadedResults);
      } catch (error) {
        console.error('❌ 파일 업로드 오류:', error);
      } finally {
        setUploading(false);
      }
    };
    // 🔹 서버에서 삭제 실행
    const processDeletedFiles = async () => {
      try {
        // ✅ 서버에 업로드된 파일만 삭제 (isUploaded 체크)
        const filesToDelete = deletedFiles.filter((file) => file.isUploaded);

        if (filesToDelete.length === 0) {
          console.log('⚠️ 삭제할 업로드된 파일이 없음.');
          setDeletedFiles([]); // 리스트 초기화
          return;
        }

        await Promise.all(
          filesToDelete.map(async (file) => {
            await deleteFileFromStorage(file.uri);
            await deleteFileFromFirestore(file.collectionName, file.uri);
          })
        );

        setDeletedFiles([]); // ✅ 삭제 완료 후 목록 초기화
        console.log('✅ 모든 삭제 작업 완료');
      } catch (error) {
        console.error('❌ 삭제 작업 중 오류 발생:', error);
      }
    };
    // ✅ 외부에서 `uploadFilesHandler` 호출 가능하도록 설정
    useImperativeHandle(ref, () => ({
      uploadFilesHandler,
    }));

    return (
      <Box>
        {/* 📸 파일 선택 버튼 */}
        <input
          type="file"
          multiple
          accept="image/*"
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={handleFileSelect1}
        />
        {/* <IconButton color="primary" onClick={() => inputRef.current.click()}>
          <PhotoCamera />
        </IconButton> */}
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
        >
          {t('uploadImage')}
          <input
            type="file"
            hidden
            multiple
            onChange={handleFileSelect}
            ref={inputRef}
          />
        </Button>
        {/* <Typography variant="body2" sx={{ ml: 2 }}>
          {selectedFiles.length} {t('filesSelected')}
        </Typography> */}

        {/* 🖼️ 이미지 미리보기 */}

        {selectedFiles &&
          Object.keys(selectedFiles).length > 0 &&
          Object.keys(selectedFiles).map((collectionName) => (
            // <Box key={collectionName} sx={{ mt: 2 }}>
            //   <Typography variant="subtitle1">{collectionName}</Typography>
            //   <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            //     {selectedFiles[collectionName].map((file, index) => {
            //       // ✅ Firestore URL 또는 로컬 파일 구분
            //       const previewUrl = file.uri || URL.createObjectURL(file.file);
            //       return (
            //         <Box
            //           key={index}
            //           sx={{ width: 100, height: 100, position: 'relative' }}
            //         >
            //           {previewUrl ? (
            //             <PreviewImage
            //               preview={previewUrl}
            //               onRemove={() =>
            //                 handleRemoveFile(collectionName, index)
            //               }
            //             />
            //           ) : (
            //             <Typography variant="body2">Invalid File</Typography>
            //           )}

            //           {/* 📤 업로드 진행률 표시 */}
            //           <Box
            //             sx={{
            //               position: 'absolute',
            //               bottom: 0,
            //               left: '50%',
            //               transform: 'translateX(-50%)',
            //             }}
            //           >
            //             {file.isUploaded ? (
            //               <Typography variant="body2" color="success.main">
            //                 ✔ 100%
            //               </Typography>
            //             ) : (
            //               <CircularProgress
            //                 variant="determinate"
            //                 value={
            //                   uploadProgress[`${collectionName}_${index}`] || 0
            //                 }
            //                 size={20}
            //               />
            //             )}
            //           </Box>
            //         </Box>
            //       );
            //     })}
            //   </Box>
            // </Box>
            <Box key={collectionName} sx={{ mt: 2 }}>
              <Typography variant="subtitle1">{collectionName}</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {selectedFiles[collectionName].map((file, index) => {
                  // ✅ Firestore URL 또는 로컬 파일 구분
                  const previewUrl = file.uri || URL.createObjectURL(file.file);
                  return (
                    <Box
                      key={index}
                      sx={{
                        width: 100,
                        height: 120,
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {/* 🖼️ 이미지 미리보기 */}
                      {previewUrl ? (
                        <PreviewImage
                          preview={previewUrl}
                          onRemove={() =>
                            handleRemoveFile(collectionName, index)
                          }
                        />
                      ) : (
                        <Typography variant="body2">Invalid File</Typography>
                      )}

                      {/* 📤 업로드 진행률 표시 (PreviewImage 아래 배치) */}
                      <Box sx={{ mt: 1, textAlign: 'center', width: '100%' }}>
                        {file.isUploaded ? (
                          <Typography variant="body2" color="success.main">
                            ✔ 100%
                          </Typography>
                        ) : (
                          <CircularProgress
                            variant="determinate"
                            value={
                              uploadProgress[`${collectionName}_${index}`] || 0
                            }
                            size={20}
                          />
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ))}
      </Box>
    );
  }
);

export default FileUpload;
