// components/WeatherEventTitle.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { TFunction } from 'i18next';
import { Theme } from '@mui/material/styles';

type WeatherEventTitleProps = {
  currentEvent: {
    iconUrl?: string;
    name: Record<string, string>;
  } | null;
  locationName: string;
  i18n: {
    language: string;
  };
  t: TFunction;
  theme: Theme;
};
const WeatherEventTitle: React.FC<WeatherEventTitleProps> = ({
  currentEvent,
  locationName,
  i18n,
  t,
  theme, }) => {
  const lang = i18n.language;

  if (!currentEvent) return null;

  const icon = (
    <Box component="img"
      src={currentEvent.iconUrl || ''}
      alt="event icon"
      sx={{ width: 40, height: 40, display: 'inline-block' }}
    />
  );
  const MapIcon = FaMapMarkerAlt as React.FC<React.SVGProps<SVGSVGElement>>;
  const name = currentEvent.name[lang] || t('discount_event');

  return (
    <Typography
      variant="h4"
      sx={{
        fontWeight: 'bold',
        textAlign: 'center',
        mb: { xs: 1, sm: 2 },
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: { xs: 'center', sm: 'center' },
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
        whiteSpace: { xs: 'normal', sm: 'nowrap' },
        gap: { xs: 0.5, sm: 1.5 },
        paddingX: { xs: 2, sm: 0 },
        paddingTop: { xs: '5vh', sm: '8vh' },
        fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
        lineHeight: 1.4,
      }}
    >
      
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          color: theme.palette.primary.main,
        }}
      >
        <MapIcon />
      </Box>
      
      {lang === 'ko' && (
        
        // <Box
        // component="span"
        // sx={{
        //   display: 'inline-flex',
        //   alignItems: 'center',
        //   flexWrap: 'wrap',
        //   whiteSpace: 'nowrap',
        //   gap: 1,
        // }}
        // >
        <>
          <Box component="span">{locationName}</Box>
          <Box component="span">{t('searching_at')}</Box>
          {icon}
          <Box component="span">{name}</Box>
          <Box component="span">{t('searching_for_items')}</Box>
          {/* </Box> */}
        </>
      )}
      {lang === 'en' && (
       
        <>
        <Box component="span" sx={{ display: 'inline' }}>Enjoy</Box>
        <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
          {icon}
        </Box>
        <Box component="span" sx={{ display: 'inline' }}>{name}</Box>
        <Box component="span" sx={{ display: 'inline' }}>Day Special Deals in</Box>
        <Box component="span" sx={{ display: 'inline' }}>{locationName}!</Box>
        </>
      
      )}
      {lang === 'ja' && (
      
        <>
          <Box component="span">{name}</Box>
          <Box component="span">{icon}</Box>
          <Box component="span">は、</Box>
          <Box component="span">{locationName}</Box>
          <Box component="span">でお得にショッピング！</Box>
          </>
       
      )}

      {lang === 'zh' && (
        <>
          <Box component="span">{locationName}</Box>
          <Box component="span">正在进行</Box>
          <Box component="span">{icon}</Box>
          <Box component="span">{name}</Box>
          <Box component="span">优惠！</Box>
          </>
      )}
    </Typography>
  );
};

export default WeatherEventTitle;
