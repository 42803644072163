// SkeletonComponents.js
import React from 'react';
import { Box, Skeleton, Grid, List, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const SkeletonLeftSideMenu = () => {
  return (
    <Box sx={{ width: 300, p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <Skeleton width="80%" />
      </Typography>
      <List>
        {Array.from({ length: 8 }).map((_, idx) => (
          <ListItem key={idx}>
            <Skeleton variant="text" width="100%" height={24} />
          </ListItem>
        ))}
      </List>
      <Box sx={{ mt: 2 }}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={40}
          sx={{ mb: 1 }}
        />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Box>
    </Box>
  );
};

export const SkeletonProductCard = () => {
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.background.paper,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Skeleton variant="rectangular" width="100%" height={220} />
      <Skeleton width="90%" height={28} sx={{ mt: 1 }} />
      <Skeleton width="50%" height={20} />
    </Box>
  );
};

export const SkeletonProductGrid = ({ count = 48 }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        {Array.from({ length: count }).map((_, idx) => (
          <Grid
            key={idx}
            item
            sx={{
              flex: '0 0 25%',
              maxWidth: '25%',
              '@media (max-width:1899px)': {
                flex: '0 0 33.33%',
                maxWidth: '33.33%',
              },
              '@media (max-width:1279px)': {
                flex: '0 0 50%',
                maxWidth: '50%',
              },
              '@media (max-width:899px)': {
                flex: '0 0 100%',
                maxWidth: '100%',
              },
            }}
          >
            <SkeletonProductCard />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export const SkeletonListHeader = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
      <Skeleton width="30%" height={30} />
      <Skeleton width="20%" height={30} />
    </Box>
  );
};
