// components/PopularSearchTermList.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';

interface SearchTerm {
  keyword: string;
}

interface PopularSearchTermListProps {
  searchTerms: SearchTerm[];
  onSelectKeyword: (keyword: string) => void;
  isMobile: boolean;
  t: (key: string) => string;
}

const PopularSearchTermList: React.FC<PopularSearchTermListProps> = ({
  searchTerms,
  onSelectKeyword,
  isMobile,
  t,
}) => {
  return (
    <Box
    mt={isMobile ? 2 : 0} 
      width="100%"
      maxWidth={isMobile ? '100%' : 900}
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={{ xs: 2, sm: 0 }}
      sx={{ overflow: 'hidden' }}
      gap={1}
    >
      <Typography
        variant="body2"
        sx={{
          fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px' },
          mb: { xs: 1, sm: 2, md: 2 },
          textAlign: 'center',
          display: 'inline-block',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
        }}
        color="gray"
      >
        🔥 {t('popularSearchTerms')}
      </Typography>

      <Box
        display="flex"
        flexWrap="nowrap"
        overflow="auto"
        sx={{
          width: '100%',
          maxWidth: '900px',
          whiteSpace: 'nowrap',
          overflowX: 'auto',
          flexGrow: 1,
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            height: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: '10px',
          },
        }}
        gap={1}
      >
        {searchTerms.map((search, index) => (
          <Typography
            key={index}
            variant="body2"
            sx={{
              fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px' },
              cursor: 'pointer',
              '&:hover': { textDecoration: 'underline' },
              mb: { xs: 1, sm: 2, md: 2 },
            }}
            color="gray"
            onClick={() => onSelectKeyword(search.keyword)}
          >
            {search.keyword}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default PopularSearchTermList;
