import axios from 'axios';

// export const getGeocode = async (lat, lng, language = 'en') => {
//   const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
//   const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}&language=${language}`;

//   try {
//     const response = await fetch(url);

//     // 네트워크 또는 HTTP 에러 체크
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const data = await response.json();

//     // Google API 응답 상태 체크
//     if (data.status === 'OK' && data.results.length > 0) {
//       return data.results[0];
//     } else {
//       throw new Error(`Geocoding failed: ${data.status}`);
//     }
//   } catch (error) {
//     console.error('🔥 getGeocode error:', error);
//     // 필요 시 fallback을 위한 null 반환 가능
//     return null;
//   }
// };

export const getGeocode = async (lat, lng, language = 'en') => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}&language=${language}`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (data.status === 'OK' && data.results.length > 0) {
      return data.results[0];
    } else {
      console.warn('Geocoding failed:', data.status);
      return null;
    }
  } catch (error) {
    console.error('🔥 getGeocode error:', error);
    return null;
  }
};
export const getAddressList = async (lat, lng, language) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}&language=${language}`;

  try {
    const response = await axios.get(url);
    const results = response.data.results;

    if (results.length > 0) {
      // 👇 전체 결과 객체를 반환해야 함!
      return results;
    } else {
      return [];
    }
  } catch (error) {
    console.error('주소 리스트 가져오기 실패:', error);
    return [];
  }
};

export const buildLocationData = (result) => {
  const components = Array.isArray(result?.address_components)
    ? result.address_components
    : [];

  const getComponent = (...typesList) => {
    for (const types of typesList) {
      const found = components.find((c) =>
        types.every((type) => c.types.includes(type))
      );
      if (found) return found.long_name;
    }
    return '';
  };

  const country = getComponent(['country']);
  const province = getComponent(['administrative_area_level_1']); // 경기도
  const city =
    getComponent(['administrative_area_level_2']) || // 고양시
    getComponent(['locality']); // (서울특별시 같은 경우도 있음)

  const district =
    getComponent(['sublocality_level_1']) || // 일산서구
    getComponent(['sublocality_level_2']) || // 주로 '동' 단위
    getComponent(['sublocality_level_3']) ||
    getComponent(['sublocality']) ||
    getComponent(['neighborhood']) || // 일부 지역에서는 사용
    '';

  const formattedAddress = result?.formatted_address || '';
  const placeId = result?.place_id || '';
  const location = result?.geometry?.location || {};
  const latitude = location.lat;
  const longitude = location.lng;

  const fullname = [country, province, city, district]
    .filter(Boolean)
    .join(' ');

  return {
    country,
    province,
    city,
    district,
    fullname,
    formattedAddress,
    placeId,
    latitude,
    longitude,
  };
};

export const searchTownByKeyword = async (keyword, language) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  if (!keyword || keyword.trim().length === 0) return [];

  const encodedKeyword = encodeURIComponent(keyword);

  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedKeyword}&language=${language}&key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const results = response.data.results;

    if (response.data.status === 'OK' && results.length > 0) {
      return results; // 전체 결과 리스트 반환
    } else {
      console.warn('📍 지오코딩 결과 없음:', response.data.status);
      return [];
    }
  } catch (error) {
    console.error('❌ 지오코딩 요청 실패:', error);
    return [];
  }
};

export const getNearbyTownsGoogle = async (latitude, longitude) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const url = 'https://places.googleapis.com/v1/places:searchNearby';

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': apiKey,
        'X-Goog-FieldMask': 'places.displayName',
      },
      body: JSON.stringify({
        includedTypes: ['locality'],
        maxResultCount: 10,
        locationRestriction: {
          circle: {
            center: {
              latitude,
              longitude,
            },
            radius: 25000.0, // 반경 25km 내 검색
          },
        },
      }),
    });

    const data = await response.json();

    if (data.places && data.places.length > 0) {
      const nearbyTowns = data.places.map((place) => place.displayName.text);
      return nearbyTowns;
    }

    return [];
  } catch (error) {
    console.error('📍 Google Nearby Towns Fetch Error:', error);
    return [];
  }
};
export const getNearbyTownsKakao = async (latitude, longitude) => {
  try {
    const res = await fetch(
      `https://dapi.kakao.com/v2/local/geo/coord2regioncode.json?x=${longitude}&y=${latitude}`,
      {
        headers: {
          Authorization: `KakaoAK YOUR_KAKAO_REST_API_KEY`,
        },
      }
    );
    const data = await res.json();

    if (data.documents.length > 0) {
      const primaryTown = data.documents[0].region_2depth_name; // 예: "강남구"
      const secondaryTown = data.documents[0].region_3depth_name; // 예: "역삼동"
      return { primaryTown, secondaryTown };
    }
    return { primaryTown: '', secondaryTown: '' };
  } catch (error) {
    console.error('Error fetching nearby towns:', error);
    return { primaryTown: '', secondaryTown: '' };
  }
};
