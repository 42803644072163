import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import IconHelper from '../Utils/IconHelper';
import { useWeatherEvents } from './useWeatherEvents';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const WeatherEventSelector = ({
  selectedWeatherEvent,
  onSelectWeatherEvent,
}) => {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const { weatherEvents } = useWeatherEvents();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [numColumns, setNumColumns] = useState(isMobile ? 3 : 5);

  useEffect(() => {
    setNumColumns(isMobile ? 3 : 5);
  }, [isMobile]);

  return (
    <Box sx={{ width: '100%', p: 1, marginLeft: 1, marginRight: 1 }}>
      {/* 🔗 Breadcrumbs - 날씨 이벤트 경로 */}
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          color="inherit"
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
          sx={{ cursor: 'pointer' }}
        >
          {/* {t('weatherEvents')} */}
        </Link>
      </Breadcrumbs>

      {/* 🌦️ 날씨 이벤트 선택 버튼 */}
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          flexWrap: 'wrap',
        }}
      >
        {weatherEvents.map((event) => (
          <Button
            key={event.id}
            variant={
              selectedWeatherEvent === event.id ? 'contained' : 'outlined'
            }
            color="primary"
            onClick={() => onSelectWeatherEvent(event.id)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              borderRadius: 16,
              border: '1px solid',
              borderColor: 'primary.main',
              textTransform: 'none',
              padding: '5px 15px',
              margin: '5px',
              backgroundColor:
                selectedWeatherEvent === event.id
                  ? theme.palette.primary.main
                  : 'transparent',
              color:
                selectedWeatherEvent === event.id
                  ? theme.palette.primary.contrastText
                  : theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
                borderColor: theme.palette.primary.dark,
              },
            }}
          >
            {/* ☁️ 아이콘 표시 */}
            {event.iconUrl && (
              <img
                src={event.iconUrl}
                alt={event.name}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
            )}
            {event.name}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default WeatherEventSelector;
