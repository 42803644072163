import React from 'react';
import { useSelector } from 'react-redux';
import App from './App';
import { CssBaseline, ThemeProvider } from '@mui/material';
import useAuthCheckRedux from './useAuthCheckRedux';
import { darkTheme, lightTheme } from './themes';
import { WeatherProvider } from './Weather/WeatherProvider';

const RootComponent = () => {
  useAuthCheckRedux();
  const darkMode = useSelector((state) => state.app.darkMode);
  const currentTheme = darkMode ? darkTheme : lightTheme;
  return (
    <ThemeProvider theme={currentTheme}>
      <WeatherProvider>
        <CssBaseline />
        <App />
      </WeatherProvider>
    </ThemeProvider>
  );
};

export default RootComponent;
