import { useState, useEffect, useMemo } from 'react';

/**
 * 특정 날씨 이벤트가 활성화되었는지 확인
 */
export const isWeatherEventActive = (weatherData, weatherEvent) => {
  if (!weatherData || !weatherEvent) return false;

  const { descKey, nameKey } = weatherEvent;
  if (!descKey || !nameKey) return false;

  const eventCheckFunctions = {
    isClearEvent,
    isRainEvent,
    isHeavyRainEvent,
    isHeatwaveEvent,
    isWindyEvent,
    isHurricaneEvent,
    isSnowEvent,
    isHeavySnowEvent,
    isFogEvent,
    isFrostEvent,
    isThunderstormEvent,
    isDustEvent,
    isPM25Event,
    isPM10Event,
  };

  const checkFunction = eventCheckFunctions[nameKey];
  return checkFunction ? checkFunction(weatherData, descKey) : false;
};

/**
 * 맑은 날씨 감지
 */
export const isClearEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  const {
    weather = [],
    minTemp,
    maxTemp,
    minHumidity,
    maxHumidity,
    minWindSpeed,
    maxWindSpeed,
    activeHours,
  } = descKey;

  const currentWeather = weatherData.weather?.[0]?.main || '';
  const currentTempCelsius = weatherData.main?.temp;
  const currentHumidity = weatherData.main?.humidity ?? null;
  const currentWindSpeed = weatherData.wind?.speed ?? null;
  const currentHour = new Date().getHours();

  return (
    weather.includes(currentWeather) &&
    (minTemp === undefined || currentTempCelsius >= minTemp) &&
    (maxTemp === undefined || currentTempCelsius <= maxTemp) &&
    (minHumidity === undefined || currentHumidity >= minHumidity) &&
    (maxHumidity === undefined || currentHumidity <= maxHumidity) &&
    (minWindSpeed === undefined || currentWindSpeed >= minWindSpeed) &&
    (maxWindSpeed === undefined || currentWindSpeed <= maxWindSpeed) &&
    (!activeHours ||
      (currentHour >= activeHours[0] && currentHour <= activeHours[1]))
  );
};

/**
 * 비오는 날씨 감지
 */
export const isRainEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) {
    console.warn('🚨 Missing weatherData or descKey!');
    return false;
  }

  const {
    weather = [],
    minPrecipitation,
    maxPrecipitation,
    minTemp,
    maxTemp,
    minHumidity,
    maxWindSpeed,
    minWindSpeed,
    activeHours,
  } = descKey;

  const currentWeather = weatherData.weather?.[0]?.main || '';
  const precipitation = weatherData.rain?.['1h'] ?? 0;
  const currentTempCelsius = weatherData.main?.temp;
  // const currentTempCelsius = weatherData.main?.temp - 273.15;
  const currentHumidity = weatherData.main?.humidity ?? null;
  const currentWindSpeed = weatherData.wind?.speed ?? null;
  const currentHour = new Date().getHours();

  console.log('🌧 Checking Rain Event Conditions:');
  console.log('👉 Current Weather:', currentWeather);
  console.log('👉 Expected Weather Conditions:', JSON.stringify(weather));
  console.log(
    '👉 Precipitation (mm):',
    precipitation,
    `(Min: ${minPrecipitation}, Max: ${maxPrecipitation})`
  );
  console.log(
    '👉 Temperature (°C):',
    currentTempCelsius,
    `(Min: ${minTemp}, Max: ${maxTemp})`
  );
  console.log('👉 Humidity (%):', currentHumidity, `(Min: ${minHumidity})`);
  console.log(
    '👉 Wind Speed (m/s):',
    currentWindSpeed,
    `(Min: ${minWindSpeed}, Max: ${maxWindSpeed})`
  );
  console.log(
    '👉 Active Hours:',
    JSON.stringify(activeHours),
    `Current Hour: ${currentHour}`
  );

  if (!weather.includes(currentWeather)) {
    console.warn(`❌ Failed: Weather condition does not match.`);
    console.warn(`🔹 Current Weather: "${currentWeather}"`);
    console.warn(`🔹 Expected Weather Conditions: ${JSON.stringify(weather)}`);
    return false;
  }

  if (minPrecipitation !== undefined && precipitation < minPrecipitation) {
    console.warn('❌ Failed: Precipitation is too low.');
    return false;
  }

  if (maxPrecipitation !== undefined && precipitation > maxPrecipitation) {
    console.warn('❌ Failed: Precipitation is too high.');
    return false;
  }

  if (minTemp !== undefined && currentTempCelsius < minTemp) {
    console.warn('❌ Failed: Temperature is too low.');
    return false;
  }

  if (maxTemp !== undefined && currentTempCelsius > maxTemp) {
    console.warn('❌ Failed: Temperature is too high.');
    return false;
  }

  if (minHumidity !== undefined && currentHumidity < minHumidity) {
    console.warn('❌ Failed: Humidity is too low.');
    return false;
  }

  if (minWindSpeed !== undefined && currentWindSpeed < minWindSpeed) {
    console.warn('❌ Failed: Wind speed is too low.');
    return false;
  }

  if (maxWindSpeed !== undefined && currentWindSpeed > maxWindSpeed) {
    console.warn('❌ Failed: Wind speed is too high.');
    return false;
  }

  if (
    activeHours?.length === 2 &&
    (currentHour < activeHours[0] || currentHour > activeHours[1])
  ) {
    console.warn('❌ Failed: Not within active hours.');
    return false;
  }

  console.log('✅ Rain Event Conditions Met!');
  return true;
};

/**
 * 강한 비 감지
 */
export const isHeavyRainEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  return (
    isRainEvent(weatherData, descKey) &&
    weatherData.rain?.['1h'] >= (descKey.minPrecipitation || 0)
  );
};

/**
 * 폭염 감지
 */
export const isHeatwaveEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  const { minTemp, maxHumidity, minFeelsLike, minUVIndex, activeHours } =
    descKey;

  const currentTemp = weatherData.main?.temp;
  const currentFeelsLike = weatherData.main?.feels_like;
  const currentHumidity = weatherData.main?.humidity;
  const currentUVIndex = weatherData.uvi;
  const currentHour = new Date().getHours();

  return (
    (minTemp === undefined || currentTemp >= minTemp) &&
    (minFeelsLike === undefined || currentFeelsLike >= minFeelsLike) &&
    (maxHumidity === undefined || currentHumidity <= maxHumidity) &&
    (minUVIndex === undefined || currentUVIndex >= minUVIndex) &&
    (!activeHours ||
      (currentHour >= activeHours[0] && currentHour <= activeHours[1]))
  );
};

/**
 * 강풍 감지
 */
export const isWindyEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  const { minWindSpeed, maxWindSpeed } = descKey;
  const currentWindSpeed = weatherData.wind?.speed || 0;

  return (
    (minWindSpeed === undefined || currentWindSpeed >= minWindSpeed) &&
    (maxWindSpeed === undefined || currentWindSpeed <= maxWindSpeed)
  );
};

export const isHurricaneEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  const {
    weather = [],
    minWindSpeed,
    maxWindSpeed,
    minPressure,
    maxPressure,
    minPrecipitation,
    maxPrecipitation,
    minHumidity,
    alertLevel,
    activeHours,
  } = descKey;

  const currentWeather = weatherData.weather?.[0]?.main || '';
  const currentWindSpeed = weatherData.wind?.speed || 0;
  const currentPressure = weatherData.main?.pressure || 1013;
  const precipitation = weatherData.rain?.['1h'] ?? 0;
  const currentHumidity = weatherData.main?.humidity ?? null;
  const currentAlertLevel = weatherData.alerts?.level ?? 0;
  const currentHour = new Date().getHours();

  return (
    weather.includes(currentWeather) &&
    (minWindSpeed === undefined || currentWindSpeed >= minWindSpeed) &&
    (maxWindSpeed === undefined || currentWindSpeed <= maxWindSpeed) &&
    (minPressure === undefined || currentPressure >= minPressure) &&
    (maxPressure === undefined || currentPressure <= maxPressure) &&
    (minPrecipitation === undefined || precipitation >= minPrecipitation) &&
    (maxPrecipitation === undefined || precipitation <= maxPrecipitation) &&
    (minHumidity === undefined || currentHumidity >= minHumidity) &&
    (alertLevel === undefined || currentAlertLevel >= alertLevel) &&
    (!activeHours ||
      (currentHour >= activeHours[0] && currentHour <= activeHours[1]))
  );
};
export const isSnowEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  const {
    weather = [],
    minSnowfall,
    maxSnowfall,
    minTemp,
    maxTemp,
    minHumidity,
    maxHumidity,
    minWindSpeed,
    maxWindSpeed,
    activeHours,
  } = descKey;

  const currentWeather = weatherData.weather?.[0]?.main || '';
  const snowfall = weatherData.snow?.['1h'] || 0;
  const currentTempCelsius = weatherData.main?.temp;
  const currentHumidity = weatherData.main?.humidity ?? null;
  const currentWindSpeed = weatherData.wind?.speed ?? null;
  const currentHour = new Date().getHours();

  return (
    weather.includes(currentWeather) &&
    (minSnowfall === undefined || snowfall >= minSnowfall) &&
    (maxSnowfall === undefined || snowfall <= maxSnowfall) &&
    (minTemp === undefined || currentTempCelsius >= minTemp) &&
    (maxTemp === undefined || currentTempCelsius <= maxTemp) &&
    (minHumidity === undefined || currentHumidity >= minHumidity) &&
    (maxHumidity === undefined || currentHumidity <= maxHumidity) &&
    (minWindSpeed === undefined || currentWindSpeed >= minWindSpeed) &&
    (maxWindSpeed === undefined || currentWindSpeed <= maxWindSpeed) &&
    (!activeHours ||
      (currentHour >= activeHours[0] && currentHour <= activeHours[1]))
  );
};

export const isHeavySnowEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  return (
    isSnowEvent(weatherData, descKey) &&
    (descKey.minSnowfall === undefined ||
      weatherData.snow?.['1h'] >= descKey.minSnowfall) &&
    (descKey.maxSnowfall === undefined ||
      weatherData.snow?.['1h'] <= descKey.maxSnowfall)
  );
};

export const isFogEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  const { weather = [] } = descKey;
  const currentWeather = weatherData.weather?.[0]?.main || '';

  return weather.includes(currentWeather);
};

export const isFrostEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  const { minTemp } = descKey;
  const currentTempCelsius = weatherData.main?.temp;

  return minTemp !== undefined && currentTempCelsius <= minTemp;
};

export const isThunderstormEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  const {
    weather = [],
    minPrecipitation,
    maxPrecipitation,
    minTemp,
    maxTemp,
    minHumidity,
    maxHumidity,
    minWindSpeed,
    maxWindSpeed,
    activeHours,
  } = descKey;

  const currentWeather = weatherData.weather?.[0]?.main || '';
  const precipitation = weatherData.snow?.['1h'] || 0;
  const currentTempCelsius = weatherData.main?.temp;
  const currentHumidity = weatherData.main?.humidity ?? null;
  const currentWindSpeed = weatherData.wind?.speed ?? null;
  const currentHour = new Date().getHours();

  return (
    weather.includes(currentWeather) &&
    (minPrecipitation === undefined || precipitation >= minPrecipitation) &&
    (maxPrecipitation === undefined || precipitation <= maxPrecipitation) &&
    (minTemp === undefined || currentTempCelsius >= minTemp) &&
    (maxTemp === undefined || currentTempCelsius <= maxTemp) &&
    (minHumidity === undefined || currentHumidity >= minHumidity) &&
    (maxHumidity === undefined || currentHumidity <= maxHumidity) &&
    (minWindSpeed === undefined || currentWindSpeed >= minWindSpeed) &&
    (maxWindSpeed === undefined || currentWindSpeed <= maxWindSpeed) &&
    (!activeHours ||
      (currentHour >= activeHours[0] && currentHour <= activeHours[1]))
  );

  console.log('✅ descKey 값이 올바릅니다.');
  return true;
};

export const isDustEvent = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  const { minPM10, maxPM10, minPM25, maxPM25 } = descKey;
  const currentPM10 = weatherData.airQuality?.pm10 || null;
  const currentPM25 = weatherData.airQuality?.pm2_5 || null;

  return (
    (minPM10 === undefined || currentPM10 >= minPM10) &&
    (maxPM10 === undefined || currentPM10 <= maxPM10) &&
    (minPM25 === undefined || currentPM25 >= minPM25) &&
    (maxPM25 === undefined || currentPM25 <= maxPM25)
  );
};

/**
 * 대기 오염 감지 (미세먼지 PM10)
 */
export const isPM10Event = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  const { minPM10, maxPM10, activeHours } = descKey;

  const currentPM10 = weatherData.airQuality?.pm10 || null;
  const currentHour = new Date().getHours();

  return (
    (minPM10 === undefined || currentPM10 >= minPM10) &&
    (maxPM10 === undefined || currentPM10 <= maxPM10) &&
    (!activeHours ||
      (currentHour >= activeHours[0] && currentHour <= activeHours[1]))
  );
};

/**
 * 대기 오염 감지 (초미세먼지 PM2.5)
 */

export const isPM25Event = (weatherData, descKey) => {
  if (!weatherData || !descKey) return false;

  const { minPM25, maxPM25, activeHours } = descKey;

  const currentPM25 = weatherData.airQuality?.pm2_5 || null;
  const currentHour = new Date().getHours();

  return (
    (minPM25 === undefined || currentPM25 >= minPM25) &&
    (maxPM25 === undefined || currentPM25 <= maxPM25) &&
    (!activeHours ||
      (currentHour >= activeHours[0] && currentHour <= activeHours[1]))
  );
};
