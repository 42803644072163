// // GlobalContext.js
// import React, { createContext, useContext, useState, useEffect } from 'react';

// const GlobalContext = createContext();

// export const useGlobalContext = () => {
//   return useContext(GlobalContext);
// };

// export const GlobalProvider = ({ children }) => {
//   const [navbarHeight, setNavbarHeight] = useState('90px');
//   const [tabbarHeight, setTabbarHeight] = useState('0px');
//   const [textInputHeight, setTextInputHeight] = useState('70px');

//   useEffect(() => {
//     const updateDimensions = () => {
//       setNavbarHeight(window.innerWidth <= 768 ? '90px' : '110px');
//       setTabbarHeight(window.innerWidth <= 768 ? '50px' : '0px'); // Adjusted tabbarHeight for mobile
//       setTextInputHeight(window.innerWidth <= 768 ? '50px' : '70px');
//     };

//     updateDimensions(); // Initial call to set dimensions
//     window.addEventListener('resize', updateDimensions);

//     return () => {
//       window.removeEventListener('resize', updateDimensions);
//     };
//   }, []);

//   return (
//     <GlobalContext.Provider
//       value={{ navbarHeight, tabbarHeight, textInputHeight }}
//     >
//       {children}
//     </GlobalContext.Provider>
//   );
// };

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

// 이미지 import (웹에서는 require() 대신 import 사용)
import userProfileImage from './assets/images/user.png';
import productDefaultImage from './assets/images/no-image.jpg';
import storeDefaultImage from './assets/images/no-image2.jpg';

const GlobalContext = createContext();

// ✅ Context를 쉽게 사용할 수 있도록 만든 커스텀 훅
export const useGlobalContext = () => useContext(GlobalContext);

export function GlobalProvider({ children }) {
  // ✅ 기본 화면 크기 설정
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [navbarHeight, setNavbarHeight] = useState(90);
  const [tabbarHeight, setTabbarHeight] = useState(0);
  const [textInputHeight, setTextInputHeight] = useState(70);
  const [numColumns, setNumColumns] = useState(2);
  const [baseColumnWidth, setBaseColumnWidth] = useState(150);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const theme = useTheme(); // ✅ MUI 테마 가져오기
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // <600px
  const isPad = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 600px~900px
  const isPc = useMediaQuery(theme.breakpoints.up('md')); // >=900px

  // ✅ 고정된 기본 Aspect Ratio
  const defaultAspectRatio = 4 / 3;
  const pcAspectRatio = 16 / 9;
  const mobileAspectRatio = 1; // 4 / 4
  const padAspectRatio = 4 / 3;

  // ✅ 이미지 크기 정보
  const imageSizes = [
    {
      width: 1024,
      height: Math.round(1024 / defaultAspectRatio),
      collectionName: 'xlarge_images',
      prefix: 'xlarge_',
    },
    {
      width: 213,
      height: Math.round(213 / defaultAspectRatio),
      collectionName: 'icon_images',
      prefix: 'icon_',
    },
  ];

  // ✅ 브라우저 화면 크기 감지 및 업데이트
  useEffect(() => {
    const updateWindowDimensions = () => {
      const width = window.innerWidth;
      setWindowWidth(width);
      const baseWidth = getBaseWidth(width);

      setNavbarHeight(width <= 768 ? 90 : 110);
      setTextInputHeight(width <= 768 ? 50 : 70);
      setBaseColumnWidth(baseWidth);
      setNumColumns(Math.floor(width / baseWidth));
    };

    // 초기 업데이트
    updateWindowDimensions();

    // ✅ 창 크기가 변경될 때 이벤트 리스너 추가
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  // ✅ 화면 크기에 따른 컬럼 너비 설정
  const getBaseWidth = (width) => {
    if (width >= 1600) return 250;
    if (width >= 1024) return 200;
    if (width >= 768) return 180;
    if (width >= 540) return 160;
    if (width >= 375) return 140;
    return 120;
  };

  // ✅ Context 값 메모이제이션
  const value = useMemo(
    () => ({
      navbarHeight,
      tabbarHeight,
      textInputHeight,
      windowWidth,
      baseColumnWidth,
      numColumns,
      itemsPerPage,
      imageSizes,
      isMobile,
      isPad,
      isPc,
      defaultAspectRatio,
      pcAspectRatio,
      padAspectRatio,
      mobileAspectRatio,
      userProfileImage,
      productDefaultImage,
      storeDefaultImage,
    }),
    [
      navbarHeight,
      tabbarHeight,
      textInputHeight,
      windowWidth,
      baseColumnWidth,
      numColumns,
      itemsPerPage,
      imageSizes,
      isMobile,
      isPad,
      isPc,
      defaultAspectRatio,
      padAspectRatio,
      pcAspectRatio,
      mobileAspectRatio,
      userProfileImage,
      productDefaultImage,
      storeDefaultImage,
    ]
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}
