import React, { useState, useEffect, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Typography,
  Box,
  InputBase,
  Divider,
  Avatar,
  TextField,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

export const SearchContainer = styled('div')(({ theme }) => {
  const borderColor = theme?.searchInput?.borderColor || '#ccc'; // 기본값 설정
  const hoverBorderColor = theme?.searchInput?.hoverBorderColor || '#999'; // 기본값 설정

  return {
    position: 'relative',
    borderRadius: '32px', // 라운드 처리
    backgroundColor: 'transparent', // 내부 컬러 제거
    border: `1px solid ${
      theme.palette.mode === 'dark' ? borderColor : alpha(borderColor, 0.5)
    }`,
    '&:hover': {
      border: `1px solid ${
        theme.palette.mode === 'dark'
          ? hoverBorderColor
          : alpha(hoverBorderColor, 0.75)
      }`,
    },
    marginRight: 0,
    marginLeft: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    minHeight: '55px',
    minWidth: '200px', // ✅ 최소 너비 설정
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      width: '60%',
    },
  };
});

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color:
    theme.palette.mode === 'dark'
      ? theme?.searchInput?.placeholderColor || '#fff' // 다크 모드: 기본 흰색
      : theme?.searchInput?.placeholderColor || '#000', // 라이트 모드: 기본 텍스트 색상
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  flexGrow: 1, // ✅ 추가: 입력 필드가 가변적으로 크기 조절되도록 설정
  minWidth: 0, // ✅ 추가: 너무 넓어지지 않도록 제한
  color: theme.palette.text.primary || '#000', // 기본 텍스트 색상
  flexGrow: 1,
  '& .MuiInputBase-input': {
    color: theme?.searchInput?.color || '#727E8E', // 입력 텍스트 색상 기본값
    padding: theme.spacing(1),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    '&::placeholder': {
      color: theme?.searchInput?.placeholderColor || '#ccc', // Placeholder 기본 색상
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));
