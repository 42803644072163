// import React from 'react';

// import {
//   Typography,
//   Box,
//   MenuItem,
//   Select,
//   ToggleButton,
//   ToggleButtonGroup,
// } from '@mui/material';

// import { useTheme } from '@mui/material/styles';
// import { useTranslation } from 'react-i18next';

// const ListHeader = ({
//   title,
//   showFilter = false,
//   sortOption,
//   handleSortChange,
// }) => {
//   const { t } = useTranslation();
//   const theme = useTheme();

//   return (
//     <Box sx={styles.listHeader}>
//       <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
//         {title}
//       </Typography>
//       {showFilter && (
//         <ToggleButtonGroup
//           value={sortOption}
//           exclusive
//           onChange={(e, newValue) => newValue && handleSortChange(newValue)}
//           sx={styles.selectBox}
//         >
//           <ToggleButton value="latest">{t('latest')}</ToggleButton>
//           <ToggleButton value="popularity">{t('popularity')}</ToggleButton>
//           <ToggleButton value="lowPrice">{t('lowPrice')}</ToggleButton>
//           <ToggleButton value="highPrice">{t('highPrice')}</ToggleButton>
//         </ToggleButtonGroup>
//       )}
//     </Box>
//   );
// };
// const styles = {
//   root: {
//     width: '100%',
//     backgroundColor: (theme) => theme.palette.background.default,
//     pb: 10,
//   },
//   mainContainer: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     textAlign: 'center',
//     py: { xs: 3, sm: 4 },
//     px: 2,
//     maxWidth: 'lg',
//     margin: '0 auto',
//     color: (theme) => theme.palette.primary.main,
//     borderRadius: '16px',
//     boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)',
//   },
//   sectionTitle: {
//     fontWeight: 'bold',
//     letterSpacing: '0.5px',
//     textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
//   },
//   weatherBanner: {
//     border: '2px solid #FF5722',
//     borderRadius: '8px',
//     padding: 2,
//     boxShadow: 1,
//   },
//   listHeader: {
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     textAlign: 'left',
//     py: { xs: 1, sm: 2 },
//     maxWidth: 'lg',
//   },
//   selectBox: {
//     display: 'flex',
//     gap: 1, // 버튼 간격
//     '& .MuiToggleButton-root': {
//       borderRadius: '8px',
//       fontSize: '0.9rem',
//       padding: '6px 12px',
//     },
//   },
//   scrollBox: {
//     height: 'calc(80vh - 70px)',
//     overflow: 'auto',
//   },
// };
// export default ListHeader;

import React from 'react';
import {
  Typography,
  Box,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const ListHeader = ({
  title,
  showFilter = false,
  sortOption,
  handleSortChange,
  type, // 'userProducts', 'stores', 'storeProducts'
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={styles.listHeader}>
      <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
        {title}
      </Typography>
      {showFilter && (
        <ToggleButtonGroup
          value={sortOption}
          exclusive
          onChange={(e, newValue) => newValue && handleSortChange(newValue)}
          sx={styles.selectBox}
        >
          <ToggleButton value="latest">{t('latest')}</ToggleButton>
          <ToggleButton value="popularity">{t('popularity')}</ToggleButton>
          {(type === 'userProducts' || type === 'storeProducts') && (
            <>
              <ToggleButton value="lowPrice">{t('lowPrice')}</ToggleButton>
              <ToggleButton value="highPrice">{t('highPrice')}</ToggleButton>
            </>
          )}
          <ToggleButton value="distance">{t('distance')}</ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};

const styles = {
  listHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'left',
    py: { xs: 1, sm: 2 },
    //maxWidth: 'lg',
  },
  selectBox: {
    display: 'flex',
    gap: { xs: 0.5, sm: 1 }, // 모바일 gap 줄임
    '& .MuiToggleButton-root': {
      borderRadius: '8px',
      fontSize: { xs: '0.75rem', sm: '0.9rem' }, // 모바일 작게
      padding: { xs: '4px 8px', sm: '6px 12px' }, // 모바일 패딩 작게
      textTransform: 'none',
    },
  },
};

export default ListHeader;
