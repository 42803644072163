import axios from 'axios';


// ✅ IP 기반 위치 정보를 가져오는 함수
interface IpApiResponse {
  status: 'success' | 'fail';
  city?: string;
  region?: string;
  country?: string;
  lat?: number;
  lon?: number;
  message?: string; // 실패 시 오류 메시지 포함 가능
}


export const getMyLocation = async (ipAddress: string): Promise<IpApiResponse> => {
  try {
    const response = await axios.get<IpApiResponse>(`http://ip-api.com/json/${ipAddress}?fields=status,lat,lon,city,region,country`);
    
    if (response.data.status === 'success') {
      // const { city, region, country, lat, lon } = response.data;
      // return `${country} ${region} ${city} ${lat} ${lon}`;
      return response.data;
    } else {
      return {
        status: 'fail',
        message: '위치 정보를 가져올 수 없음',
      };
    }
  } catch (error) {
    console.error('IP 기반 위치 조회 오류:', error);
    return {
      status: 'fail',
      message: '위치 정보를 가져올 수 없음',
    };
  }
};