// import React from 'react';
// import {
//   AppBar,
//   Toolbar,
//   Menu,
//   MenuItem,
//   IconButton,
//   Box,
//   useTheme,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import LanguageIcon from '@mui/icons-material/Language';

// const FriendonNavbar = () => {
//   const { t, i18n } = useTranslation();
//   const theme = useTheme(); // ✅ MUI 테마 적용
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const handleLanguageMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleLanguageMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const changeLanguage = (lng) => {
//     i18n.changeLanguage(lng);
//     handleLanguageMenuClose();
//   };

//   return (
//     <Box
//       position="fixed"
//       sx={{
//         width: '100%', // ✅ 전체 너비 차지
//         backgroundColor: theme.palette.background.default,
//         color: theme.palette.text.primary,
//         boxShadow: 'none',
//         border: 'none',
//         // backdropFilter: 'blur(10px)', // ✅ 모던한 블러 효과
//       }}
//     >
//       <Toolbar
//         sx={{
//           display: 'flex',
//           justifyContent: 'flex-end', // ✅ 버튼을 오른쪽으로 정렬
//           alignItems: 'center',
//           paddingRight: '10px', // ✅ 오른쪽 여백 추가
//         }}
//       >
//         <IconButton
//           onClick={handleLanguageMenuClick}
//           sx={{
//             color: theme.palette.text.primary, // ✅ 테마 기반 텍스트 컬러
//             marginRight: '10px', // ✅ 오른쪽 간격 조정
//           }}
//         >
//           <LanguageIcon />
//         </IconButton>
//         <Menu
//           anchorEl={anchorEl}
//           open={Boolean(anchorEl)}
//           onClose={handleLanguageMenuClose}
//         >
//           <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
//           <MenuItem onClick={() => changeLanguage('ko')}>한국어</MenuItem>
//           <MenuItem onClick={() => changeLanguage('ja')}>日本語</MenuItem>
//           <MenuItem onClick={() => changeLanguage('zh')}>中文</MenuItem>
//         </Menu>
//       </Toolbar>
//     </Box>
//   );
// };

// export default FriendonNavbar;

import React, { useState, useEffect } from 'react';
import {
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Box,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import {
  getStoredLanguage,
  changeLanguage,
  isSupportedLanguage,
} from './i18nUtils'; // ✅ 유틸 함수 import
import countries from './Country/countries.json'; // ✅ code, name, flag 포함된 국가 정보

const FriendonNavbar = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeLanguage, setActiveLanguage] = useState(getStoredLanguage());

  const handleLanguageMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (code) => {
    setActiveLanguage(code);
    changeLanguage(code); // ✅ 변경된 코드로 적용
    handleLanguageMenuClose();
  };

  useEffect(() => {
    const onLanguageChanged = (lng) => {
      setActiveLanguage(lng);
    };
    i18n.on('languageChanged', onLanguageChanged);
    return () => i18n.off('languageChanged', onLanguageChanged);
  }, [i18n]);

  return (
    <Box
      position="fixed"
      sx={{
        width: '100%',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        boxShadow: 'none',
        border: 'none',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: '10px',
        }}
      >
        <IconButton
          onClick={handleLanguageMenuClick}
          sx={{
            color: theme.palette.text.primary,
            marginRight: '10px',
          }}
        >
          <LanguageIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleLanguageMenuClose}
        >
          {countries
            .filter((country) => isSupportedLanguage(country.code))
            .map((country) => (
              <MenuItem
                key={country.code}
                selected={activeLanguage === country.code}
                onClick={() => handleLanguageChange(country.code)}
              >
                <span
                  className={`flag-icon flag-icon-${country.flag.toLowerCase()}`}
                  style={{ marginRight: 8 }}
                ></span>
                {country.name}
              </MenuItem>
            ))}
        </Menu>
      </Toolbar>
    </Box>
  );
};

export default FriendonNavbar;
