import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import ImageSlider from '../ImageSlider';
import StoreProduct from './StoreProduct';
import {
  db,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  doc,
  increment,
  arrayUnion,
  arrayRemove,
  updateDoc,
  setDoc,
  addDoc,
  limit,
} from '../firebaseConfig';
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Avatar,
} from '@mui/material';
import {
  Favorite as FavoriteIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import useMessageBox from '../Common/useMessageBox';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PromotionDisplay from './Promotion/PromotionDisplay';
import CouponDisplay from './Promotion/Coupon/CouponDisplay';
import DiscountDisplay from './Promotion/Discount/DiscountDisplay';
import useStoreUserActivity from '../Stores/useStoreUserActivity';
import { getMediaType } from '../Utils/FileHelper';
import StoreRatingForm from './StoreRatingForm';
import StoreRatings from './StoreRatings';
import GroupIcon from '@mui/icons-material/Group';

const convertFirestoreTimestampToDate = (timestamp) => {
  if (timestamp && timestamp.seconds && timestamp.nanoseconds) {
    return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  }
  return new Date(); // 기본값으로 현재 날짜 반환
};

const StoreHome = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState([]);
  const [activeMainImageIndex, setActiveMainImageIndex] = useState(0);
  const [activeSubImageIndex, setActiveSubImageIndex] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isRatingFormOpen, setIsRatingFormOpen] = useState(false);
  const { storeId } = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { logViewedStoreProduct } = useStoreUserActivity(currentUser?.uid);
  const [weekdayStartTime, setWeekdayStartTime] = useState(new Date());
  const [weekdayEndTime, setWeekdayEndTime] = useState(new Date());
  const [weekendStartTime, setWeekendStartTime] = useState(new Date());
  const [weekendEndTime, setWeekendEndTime] = useState(new Date());
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const storeRef = doc(db, 'stores', storeId);
        const storeDoc = await getDoc(storeRef);
        if (storeDoc.exists()) {
          const storeData = storeDoc.data();
          setWeekdayStartTime(
            storeData.weekdayStartTime
              ? convertFirestoreTimestampToDate(storeData.weekdayStartTime)
              : new Date()
          );
          setWeekdayEndTime(
            storeData.weekdayEndTime
              ? convertFirestoreTimestampToDate(storeData.weekdayEndTime)
              : new Date()
          );
          setWeekendStartTime(
            storeData.weekendStartTime
              ? convertFirestoreTimestampToDate(storeData.weekendStartTime)
              : new Date()
          );
          setWeekendEndTime(
            storeData.weekendEndTime
              ? convertFirestoreTimestampToDate(storeData.weekendEndTime)
              : new Date()
          );
          setStore(storeData);
          setSubscribersCount(storeData.subscribersCount || 0); // Set subscribers count
          setIsSubscribed(storeData.subscribers?.includes(currentUser?.uid)); // Set subscription status
        } else {
          setError('Store not found');
        }
      } catch (err) {
        setError(`${t('storehome.errorFetchingStore')}${err.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchStore();
  }, [storeId]);

  useEffect(() => {
    if (!store) return;
    const fetchProducts = async () => {
      const productsCollection = collection(db, 'stores', storeId, 'products');
      const q = query(productsCollection, where('uid', '==', storeId));
      const querySnapshot = await getDocs(q);
      const productsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsData);
    };
    fetchProducts();
  }, [store]);

  const handleLike = async () => {
    if (!currentUser) {
      MessageBoxInform(t('storehome.loginRequired'));
      return;
    }
    if (currentUser.uid === storeId) {
      MessageBoxInform(t('storehome.cannotLikeOwnStore'));
      return;
    }

    if (store.likeUsers && store.likeUsers.includes(currentUser.uid)) {
      // 좋아요 취소 로직
      try {
        await removeLikeFromStore(currentUser.uid);
        await updateMyLikeList(currentUser.uid, storeId, false); // 좋아요 취소
        setIsLiked(false); // 좋아요 상태 업데이트
      } catch (error) {
        console.error('Failed to remove the like:', error);
        MessageBoxInform(t('storehome.failedToRemoveLike'));
        return;
      }
    } else {
      // 좋아요 추가 로직

      const updateObject = {
        likes: increment(1),
        likeUsers: arrayUnion(currentUser.uid),
      };

      try {
        await updateLikesToStore(updateObject, currentUser.uid);
        setIsLiked(true); // 좋아요 상태 업데이트
      } catch (error) {
        console.error('Failed to update the product:', error);
        MessageBoxInform(t('storehome.failedToUpdateLike'));
        return;
      }

      try {
        await updateMyLikeList(currentUser.uid, storeId, true); // 좋아요 추가
      } catch (error) {
        console.error("Failed to add store to user's likes:", error);
        MessageBoxInform(t('storehome.failedToAddToFavorites'));
      }
    }
  };

  const removeLikeFromStore = async (currentUserId) => {
    if (!currentUser) {
      MessageBoxInform(t('storehome.loginRequired'));
      return;
    }
    const storeRef = doc(db, 'stores', storeId);
    try {
      await updateDoc(storeRef, {
        likes: increment(-1),
        likeUsers: arrayRemove(currentUserId),
      });
      console.log('Like removed successfully!');

      setStore((prevState) => ({
        ...prevState,
        likes: prevState.likes - 1,
        likeUsers: prevState.likeUsers.filter((id) => id !== currentUserId),
      }));
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const updateLikesToStore = async (updateObject, currentUserId) => {
    if (!currentUser) {
      MessageBoxInform(t('storehome.loginRequired'));
      return;
    }
    const storeRef = doc(db, 'stores', storeId);
    const storeSnapshot = await getDoc(storeRef);
    const storeData = storeSnapshot.data();

    if (storeData.likeUsers && storeData.likeUsers.includes(currentUserId)) {
      console.log('User already liked this product.');
      return;
    }

    try {
      await updateDoc(storeRef, updateObject);
      console.log('Document successfully updated!');

      setStore((prevState) => ({
        ...prevState,
        likes: prevState.likes + 1,
        likeUsers: Array.isArray(prevState.likeUsers)
          ? [...prevState.likeUsers, currentUserId]
          : [currentUserId],
      }));
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const updateMyLikeList = async (userId, storeId, isAdding) => {
    if (!currentUser) {
      MessageBoxInform(t('storehome.loginRequired'));
      return;
    }

    const userLikesRef = doc(db, 'stores', storeId, 'userLikes', userId);

    try {
      const docSnapshot = await getDoc(userLikesRef);

      if (docSnapshot.exists()) {
        const likesStoreIds = docSnapshot.data().stores || [];

        if (isAdding) {
          if (!likesStoreIds.includes(storeId)) {
            likesStoreIds.push(storeId);
            await updateDoc(userLikesRef, { stores: likesStoreIds });
          }
        } else {
          const index = likesStoreIds.indexOf(storeId);
          if (index > -1) {
            likesStoreIds.splice(index, 1);
            await updateDoc(userLikesRef, { stores: likesStoreIds });
          }
        }
      } else if (isAdding) {
        await setDoc(userLikesRef, { stores: [storeId] });
      }
    } catch (err) {
      console.error('Error handling like list:', err);
    }
  };

  const handleSubscription = async () => {
    if (!currentUser) {
      MessageBoxInform(t('storehome.loginRequired'));
      return;
    }

    const storeRef = doc(db, 'stores', storeId);

    try {
      if (isSubscribed) {
        await updateDoc(storeRef, {
          subscribers: arrayRemove(currentUser.uid),
          subscribersCount: increment(-1),
        });
        setIsSubscribed(false);
        setSubscribersCount((prevCount) => prevCount - 1);
      } else {
        await updateDoc(storeRef, {
          subscribers: arrayUnion(currentUser.uid),
          subscribersCount: increment(1),
        });
        setIsSubscribed(true);
        setSubscribersCount((prevCount) => prevCount + 1);
      }
    } catch (error) {
      console.error('Error updating subscription:', error);
      MessageBoxInform(t('storehome.subscriptionError'));
    }
  };

  const addViewsCount = async () => {
    if (!currentUser) {
      MessageBoxInform(t('storehome.loginRequired'));
      return;
    }
    if (currentUser.uid === store.uid) {
      return;
    }
    if (store.viewUsers && store.viewUsers.includes(currentUser.uid)) {
      return;
    }
    const updateObject = {
      views: increment(1),
      viewUsers: arrayUnion(currentUser.uid),
    };
    const storeRef = doc(db, 'stores', storeId);

    try {
      await updateDoc(storeRef, updateObject);
    } catch (error) {
      console.error('Error updating store views:', error);
      MessageBoxInform(t('storehome.errorUpdatingViews'));
    }
  };

  const updateViewsStore = async (updateObject, currentUserId) => {
    if (!currentUser) {
      MessageBoxInform(t('storehome.loginRequired'));
      return;
    }
    try {
      const storeRef = doc(db, 'stores', storeId, 'products', storeId);
      const storeSnapshot = await getDoc(storeRef);

      if (!storeSnapshot.exists()) return;

      const storeData = storeSnapshot.data();

      if (storeData.viewUsers && storeData.viewUsers.includes(currentUserId)) {
        console.log('User already viewed this product.');
        return;
      }

      await updateDoc(storeRef, updateObject);
      console.log('Document successfully updated!');

      setStore((prevState) => ({
        ...prevState,
        views: prevState.views + 1,
        viewUsers: Array.isArray(prevState.viewUsers)
          ? [...prevState.viewUsers, currentUserId]
          : [currentUserId],
      }));
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const goToChat = async () => {
    if (!currentUser) {
      MessageBoxInform(t('storehome.loginRequired'));
      return;
    }

    if (currentUser.uid === storeId) {
      MessageBoxInform(t('storehome.cannotChatWithOwnStore'));
      return;
    }

    const participants = [currentUser.uid, storeId];

    try {
      const chatroomQuery = query(
        collection(db, 'stores', storeId, 'chatroom'),
        limit(1)
      );
      const chatroomSnapshot = await getDocs(chatroomQuery);

      const chatroom = chatroomSnapshot.docs.find((doc) =>
        doc.data().who.includes(currentUser.uid)
      );

      let chatRoomId;

      if (chatroom) {
        chatRoomId = chatroom.id;
      } else {
        const chatRoomData = {
          who: participants,
          storeId: storeId,
          date: new Date(),
        };

        const newChatRoomRef = await addDoc(
          collection(db, 'stores', storeId, 'chatroom'),
          chatRoomData
        );
        chatRoomId = newChatRoomRef.id;

        const storeRef = doc(db, 'stores', storeId);
        await updateDoc(storeRef, { chats: increment(1) });
      }

      navigate(`/storechatview/${storeId}/${chatRoomId}`);
    } catch (err) {
      console.error(
        '채팅방에 접근하거나 생성하는 중에 오류가 발생했습니다:',
        err
      );
      alert(t('storehome.chatStartError'));
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleStoreProductClick = (product) => {
    window.open(
      `/product_detail/${product.uid}/${product.id}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  const mainImagesClick = (index) => {
    setActiveMainImageIndex(index);

    const metaFiles = store.mainImages.map((url, index) => ({
      index,
      fileUrl: url,
      type: getMediaType(url),
      senderName: store?.storeName,
      date: store.updateDate,
    }));

    navigate('/imageviewer', {
      state: { files: metaFiles, index: index },
    });
  };

  const subImagesClick = (index) => {
    setActiveSubImageIndex(index);
    const metaFiles = store.subImages.map((url, index) => ({
      index,
      fileUrl: url,
      type: getMediaType(url),
      senderName: store?.storeName,
      date: store.updateDate,
    }));
    navigate('/imageviewer', {
      state: { files: metaFiles, index: index },
    });
  };

  const onMainIndexChange = (index) => {
    setActiveMainImageIndex(index);
  };

  const onSubIndexChange = (index) => {
    setActiveSubImageIndex(index);
  };

  const handleOpenRatingForm = async () => {
    if (!currentUser) {
      const isConfirmed = await MessageBoxConfirm(
        t('login-required'),
        t('goto-login')
      );
      if (isConfirmed) {
        navigate('/login');
      }
      return false;
    }

    setIsRatingFormOpen(true);
  };

  const handleCloseRatingForm = () => {
    setIsRatingFormOpen(false);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{`Error: ${error}`}</Typography>;
  const formatTime = (date) => {
    if (!date || !date.toDate) return 'N/A';
    const d = date.toDate();
    const hours = d.getHours().toString().padStart(2, '0');
    const minutes = d.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  return (
    <Box sx={{ bgcolor: theme.palette.background.default, pb: 8 }}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box
          sx={{
            border: '0px solid #d3d3d3',
            borderRadius: '16px',
            marginBottom: '16px',
            backgroundColor: 'transparent',
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.02)',
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                sx={{
                  color: theme.palette.text.primary,
                  textAlign: 'center',
                }}
              >
                {store?.storeName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {store.mainImages && store.mainImages.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <ImageSlider
                      activeIndex={activeMainImageIndex}
                      images={store.mainImages}
                      fileClick={mainImagesClick}
                      onIndexChange={onMainIndexChange}
                    />
                  </Grid>
                )}
                {store.subImages && store.subImages.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <ImageSlider
                      activeIndex={activeSubImageIndex}
                      images={store.subImages}
                      fileClick={subImagesClick}
                      onIndexChange={onSubIndexChange}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {/* 스토어 정보 (왼쪽) */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={store?.mainImages[0]}
                  alt={store?.storeName}
                  sx={{ width: 56, height: 56, mr: 2 }}
                />

                <Box>
                  {/* 스토어 이름 */}
                  <Typography
                    variant="h6"
                    sx={{ color: theme.palette.text.primary }}
                  >
                    {store?.storeName}
                  </Typography>

                  {/* 구독자 수 */}
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.text.secondary }}
                    color="theme.palette.secondary"
                  >
                    {subscribersCount} {t('storehome.subscribers')}
                  </Typography>
                </Box>

                <Box sx={{ ml: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubscription}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      borderRadius: '32px',
                      fontSize: '0.875rem',
                    }}
                  >
                    {isSubscribed
                      ? t('storehome.subscribed')
                      : t('storehome.subscribe')}
                  </Button>
                </Box>
              </Box>

              {/* 좋아요, 조회수, 채팅 수 (오른쪽) */}
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Box sx={{ ml: 2 }}>
                  <Typography sx={{ color: theme.palette.text.primary }}>
                    💖 {store?.likes || 0}
                  </Typography>
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography sx={{ color: theme.palette.text.primary }}>
                    👁️ {store?.views || 0}
                  </Typography>
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography sx={{ color: theme.palette.text.primary }}>
                    💬 {store?.chats || 0}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} container alignItems="center" spacing={2}>
              <Grid item xs={12} container justifyContent="center">
                <StoreRatings storeId={storeId} />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: '32px',
                    fontSize: '0.875rem',
                  }}
                  onClick={handleOpenRatingForm}
                >
                  {t('storehome.rateThisStore')}
                </Button>
                <StoreRatingForm
                  open={isRatingFormOpen}
                  handleClose={handleCloseRatingForm}
                  ratedStoreId={storeId}
                  raterUserId={currentUser?.uid}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box mt={2}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {t('storehome.weekday')}
                  </Typography>
                  <Typography variant="body2">
                    {formatTime(store.weekdayStartTime)} ~{' '}
                    {formatTime(store.weekdayEndTime)}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {t('storehome.weekend')}
                  </Typography>
                  <Typography variant="body2">
                    {formatTime(store.weekendStartTime)} ~{' '}
                    {formatTime(store.weekendEndTime)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {MessageBoxRender()}
            <Grid item xs={12}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                centered
                variant="fullWidth"
                TabIndicatorProps={{
                  style: {
                    height: '3px',
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                sx={{
                  mt: 4,
                  borderBottom: `2px solid ${theme.palette.divider}`,
                }}
              >
                <Tab label={t('storehome.products')} sx={{ flex: 1 }} />
                <Tab label={t('storehome.promotions')} sx={{ flex: 1 }} />
                <Tab label={t('storehome.coupons')} sx={{ flex: 1 }} />
                <Tab label={t('storehome.discounts')} sx={{ flex: 1 }} />
              </Tabs>
              <Box>
                {activeTab === 0 && (
                  <Grid container spacing={2}>
                    {products.length === 0 ? (
                      <Typography>{t('storehome.noProductsFound')}</Typography>
                    ) : isMobile ? (
                      <Box
                        id="parentScrollBox"
                        sx={{
                          marginTop: '20px',
                          height: 'calc(80vh - 70px)',
                          overflow: 'auto',
                          width: '100%',
                        }}
                      >
                        <InfiniteScroll
                          dataLength={products.length}
                          next={() => {}}
                          hasMore={false}
                          loader={
                            <Typography>{t('storehome.loading')}</Typography>
                          }
                          endMessage={
                            <Typography variant="body2">
                              {t('storehome.noMoreProducts')}
                            </Typography>
                          }
                          scrollableTarget="parentScrollBox"
                        >
                          <Grid container spacing={2}>
                            {products.map((product) => (
                              <Grid item xs={12} key={product.id}>
                                <StoreProduct
                                  product={product}
                                  onClick={() =>
                                    handleStoreProductClick(product)
                                  }
                                  horizontal={isMobile}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </InfiniteScroll>
                      </Box>
                    ) : (
                      products.map((product) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          key={product.id}
                          sx={{ mt: 2 }}
                        >
                          <StoreProduct product={product} />
                        </Grid>
                      ))
                    )}
                  </Grid>
                )}
                {activeTab === 1 && (
                  <Box
                    className="product-info"
                    sx={{
                      padding: '25px',
                      borderTop: '2px solid #e0e0e0',
                      borderBottom: '2px solid #e0e0e0',
                      marginBottom: '30px',
                      width: '100%',
                    }}
                  >
                    <PromotionDisplay storeId={storeId} />
                  </Box>
                )}
                {activeTab === 2 && (
                  <Box
                    className="product-info"
                    sx={{
                      padding: '25px',
                      borderTop: '2px solid #e0e0e0',
                      borderBottom: '2px solid #e0e0e0',
                      marginBottom: '30px',
                      width: '100%',
                    }}
                  >
                    <CouponDisplay storeId={storeId} />
                  </Box>
                )}
                {activeTab === 3 && (
                  <Box
                    className="product-info"
                    sx={{
                      padding: '25px',
                      borderTop: '2px solid #e0e0e0',
                      borderBottom: '2px solid #e0e0e0',
                      marginBottom: '30px',
                      width: '100%',
                    }}
                  >
                    <DiscountDisplay storeId={storeId} />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
          paddingBottom: isMobile ? '56px' : 0, // ensure it does not overlap with the tab bar on mobile
        }}
      >
        <Button
          fullWidth
          startIcon={isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          onClick={handleLike}
          sx={{
            flex: 1,
            borderRadius: 0,
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          {t('like')}
        </Button>
        <Button
          fullWidth
          startIcon={<ChatIcon />}
          onClick={goToChat}
          sx={{
            flex: 1,
            borderRadius: 0,
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          {t('storehome.chat')}
        </Button>
      </Box>
    </Box>
  );
};

export default StoreHome;
