import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { getCurrentWeather } from './getCurrentWeather';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Alert } from '@mui/material';

// 🔹 WeatherContext 생성
const WeatherContext = createContext(null);

export const WeatherProvider = ({ children }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const selectedTownKey = useSelector((state) => state.town.selectedTown);

  const [selectedTown, setSelectedTown] = useState(null);
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // 🔹 Firestore에서 선택한 동네 정보 가져오기
  useEffect(() => {
    let isMounted = true;

    const fetchSelectedTown = async () => {
      if (!currentUser) {
        console.warn('🔴 [WeatherProvider] 로그인되지 않음.');
        if (isMounted) {
          setSelectedTown(null);
          setWeatherData(null);
          setLoading(false);
          setError(true);
        }
        return;
      }

      if (!selectedTownKey) {
        console.warn('⚠️ [WeatherProvider] 선택된 동네 정보 없음.');
        if (isMounted) {
          setSelectedTown(null);
          setLoading(false);
        }
        return;
      }

      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const townKey = Number(selectedTownKey);
          const townData = userData[townKey === 1 ? 'town1' : 'town2'];

          //   console.log('📌 Available Keys in userData:', Object.keys(userData));
          if (townData) {
            if (isMounted) {
              setSelectedTown(townData);
              setLoading(false);
            }
            return;
          }
        }

        console.warn('⚠️ [WeatherProvider] 동네 데이터 없음.');
        if (isMounted) {
          setSelectedTown(null);
          setLoading(false);
        }
      } catch (error) {
        console.error(
          '❌ [WeatherProvider] Firestore 데이터 가져오기 실패:',
          error
        );
        if (isMounted) {
          setError(true);
          setLoading(false);
        }
      }
    };

    fetchSelectedTown();

    return () => {
      isMounted = false;
    };
  }, [currentUser, selectedTownKey]);

  // 🔹 날씨 데이터 가져오기
  const fetchWeatherData = async () => {
    if (!selectedTown) {
      console.warn('⚠️ [WeatherProvider] 유효한 동네 정보 없음.');
      setWeatherData(null);
      setLoading(false);
      return;
    }

    let retryCount = 0;
    setLoading(true);

    while (retryCount < 3) {
      try {
        const data = await getCurrentWeather(selectedTown);
        if (!data || typeof data !== 'object') {
          throw new Error('API 응답이 올바르지 않음');
        }

        setWeatherData(data);
        setError(false);
        setLoading(false);
        return;
      } catch (err) {
        console.error(
          `❌ [WeatherProvider] 날씨 데이터 가져오기 실패 (재시도 ${
            retryCount + 1
          }/3):`,
          err
        );

        if (err.message.includes('Network request failed')) {
          retryCount++;
          await new Promise((res) => setTimeout(res, 5000)); // 5초 대기 후 재시도
        } else {
          setError(true);
          setWeatherData(null);
          break;
        }
      }
    }

    setLoading(false);
  };

  // 🔹 10분마다 날씨 데이터 갱신
  useEffect(() => {
    let isMounted = true;

    const fetchWeather = async () => {
      if (isMounted) await fetchWeatherData();
    };

    fetchWeather();
    const interval = setInterval(fetchWeather, 10 * 60 * 1000);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [selectedTown]);

  // 🔹 캐싱된 데이터 제공
  const value = useMemo(
    () => ({
      weatherData,
      loading,
      error,
      fetchWeather: fetchWeatherData,
    }),
    [weatherData, loading, error, selectedTown]
  );

  return (
    <WeatherContext.Provider value={value}>{children}</WeatherContext.Provider>
  );
};

// 🔹 Context를 사용하기 위한 훅
export const useWeather = () => useContext(WeatherContext);
