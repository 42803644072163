import { Paper, Typography, Button, Box } from '@mui/material';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useNavigate } from 'react-router-dom'; // ✅ 페이지 이동을 위한 훅
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import noStoreProductResults from './assets/lottie/no-storeproduct-results.json';
import noStoreResults from './assets/lottie/no-store-results.json';

const NoStoreProducts = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 4,
        textAlign: 'center',
        position: 'relative', // ✅ Lottie 배치를 위한 상대 위치 설정
        backgroundColor: theme.palette.background.default, // ✅ 다크모드 지원
        borderRadius: 2, // ✅ 살짝 둥근 모서리
        overflow: 'hidden', // ✅ Lottie가 넘치지 않도록
      }}
    >
      {/* bottom: 0,left: 0, // ✅ 좌측 하단 배치로 변경 */}
      <Box
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          width: 100, // ✅ 크기 조절
          height: 100,
          opacity: 0.6, // ✅ 투명도 조절 (너무 강조되지 않도록)
        }}
      >
        <Lottie animationData={noStoreProductResults} loop={true} />
      </Box>
      {/* 🛒 쇼핑백 아이콘 */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 80,
          height: 80,
          backgroundColor: theme.palette.grey[200], // ✅ 아이콘 배경 컬러
          borderRadius: '50%',
          mb: 2,
          zIndex: 2, // ✅ 텍스트보다 위에 위치
        }}
      >
        <ShoppingBagOutlinedIcon
          sx={{ fontSize: 40, color: theme.palette.grey[700] }}
        />
      </Box>

      {/* 📢 "상품 없음" 메시지 */}
      <Typography
        variant="h6"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        {t('noProducts')}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: theme.palette.text.secondary, mb: 2 }}
      >
        {t('noProductsDescription')} {/* 예: "현재 등록된 상품이 없습니다." */}
      </Typography>

      {/* 🎯 액션 버튼: 상품 등록 or 둘러보기 */}
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2, width: '20%', zIndex: 2 }} // ✅ Lottie보다 위에 위치
        onClick={() => navigate('/product_upload')}
      >
        {t('uploadStoreProduct')} {/* 예: "상품 둘러보기" */}
      </Button>
    </Paper>
  );
};

export default NoStoreProducts;
