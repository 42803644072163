// import React, { useRef, useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   storage,
//   collection,
//   doc,
//   addDoc,
//   updateDoc,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
//   arrayUnion,
// } from './firebaseConfig';

// import Region from './Common/Region';
// import { useNavigate } from 'react-router-dom';
// import {
//   formatPrice,
//   numberToKorean,
//   getByteLength,
// } from './Utils/PriceFormatter';
// import './css/upload.css';
// import { RADIUS, CIRCUMFERENCE } from './Common/Constants';
// import useMessageBox from './Common/useMessageBox';
// import axios from 'axios';
// import ProductCategorySelector from './Stores/ProductCategorySelector';
// import ProductBrandSelector from './Stores/ProductBrandSelector';
// import { resizeImage } from './Utils/ImageHelper';
// import PreviewImage from './Common/PreviewImage';

// const Upload = () => {
//   const fileInputRef = useRef(null);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();

//   const [title, setTitle] = useState('');
//   const [price, setPrice] = useState('0');
//   const [content, setContent] = useState('');
//   // const [images, setImages] = useState([]); // 여러 이미지를 관리하기 위한 상태
//   const [imageFiles, setImageFiles] = useState([]);
//   const [previews, setPreviews] = useState([]); // 여러 이미지의 미리보기 URL을 위한 상태
//   const [koreanPrice, setKoreanPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState([]);
//   const [isCompleted, setIsCompleted] = useState(false);

//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [fileUrls, setFileUrls] = useState([]);
//   const [uploadProgress, setUploadProgress] = useState([]);
//   const [uploadingFiles, setUploadingFiles] = useState([]);
//   const [completedCount, setCompletedCount] = useState(0);
//   const [totalFileSize, setTotalFileSize] = useState(uploadingFiles.length);
//   const [uploadedFileSize, setUploadedFileSize] = useState(0);
//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState([]);

//   useEffect(() => {
//     // Generate previews
//     imageFiles.forEach((file) => {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setPreviews((prev) => [...prev, reader.result]);
//       };
//       reader.readAsDataURL(file);
//     });

//     return () => {
//       // Cleanup previews
//       setPreviews([]);
//     };
//   }, [imageFiles]);

//   const handleImagesSelected = (files) => {
//     setImageFiles(files);
//   };

//   const handleImageRemoved = (index) => {
//     setImageFiles((curr) => curr.filter((_, idx) => idx !== index));
//     setPreviews((curr) => curr.filter((_, idx) => idx !== index));
//   };

//   const handleSaveArea = (savedArea) => {
//     // savedArea 값을 서버에 저장하는 로직
//     setSelectedArea([...savedArea], () => {
//       console.log('Updated selectedArea:', selectedArea);
//     });
//     setIsCompleted(true);
//   };
//   useEffect(() => {
//     console.log('selectedArea updated:', selectedArea);
//   }, [selectedArea]);
//   // 가격이 변경될 때 한글로 된 가격도 함께 변경
//   useEffect(() => {
//     setKoreanPrice(numberToKorean(price));
//   }, [price]);
//   // 가격 입력 관리
//   const handlePriceChange = useCallback((e) => {
//     let priceValue = e.target.value.replace(/[^0-9]/g, '');
//     if (priceValue === '0' || priceValue === '') {
//       priceValue = '0';
//     } else {
//       while (priceValue.startsWith('0')) {
//         priceValue = priceValue.substring(1);
//       }
//     }
//     setPrice(priceValue);
//   }, []);

//   // 컴포넌트가 언마운트될 때 입력 필드 초기화
//   useEffect(() => {
//     return () => {
//       resetFileInput();
//     };
//   }, []);

//   const handleContentChange = useCallback(
//     (e) => {
//       if (getByteLength(e.target.value) <= 200) {
//         setContent(e.target.value);
//       }
//     },
//     [content]
//   );

//   const contentByteSize = getByteLength(content);

//   // 파일 입력 필드 초기화
//   const resetFileInput = () => {
//     if (fileInputRef.current) fileInputRef.current.value = '';
//   };

//   const handleImageChange = useCallback(
//     (event) => {
//       const tmpSelectedFiles = Array.from(event.target.files);

//       if (!tmpSelectedFiles.length) {
//         resetFileInput();
//         return;
//       }

//       if (tmpSelectedFiles.length > process.env.REACT_APP_SEND_IMAGE_MAX_LEN) {
//         MessageBoxInform(
//           `최대 ${process.env.REACT_APP_SEND_IMAGE_MAX_LEN}개의 이미지를 선택할 수 있습니다.`
//         );
//         resetFileInput();
//         return;
//       }

//       let totalSize = 0;
//       tmpSelectedFiles.forEach((file) => {
//         totalSize += file.size;
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           setPreviews((prevPreviews) => [...prevPreviews, reader.result]);
//           setTotalFileSize(totalSize);
//           setSelectedFiles((prevImages) => [...prevImages, file]);
//         };
//         reader.onerror = () => {
//           MessageBoxInform('파일을 읽는 도중 오류가 발생했습니다.');
//           // 여기서 추가적인 에러 처리 로직을 구현할 수 있습니다.
//         };
//         reader.readAsDataURL(file);
//       });
//     },
//     [previews, selectedFiles] // 의존성 배열 수정
//   );

//   const handleRemoveImage = useCallback((indexToRemove) => {
//     setSelectedFiles((prevImages) =>
//       prevImages.filter((_, index) => index !== indexToRemove)
//     );
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   }, []);

//   const uploadImageFiles = async (productId, width, height, prefix) => {
//     let paths = [];

//     for (const file of selectedFiles) {
//       const resizedFile = await resizeImage(file, width, height);
//       const filePath = `${
//         process.env.REACT_APP_USER_IMAGE_PATH
//       }/${productId}/${prefix}${Date.now()}_${file.name}`;
//       const uploadUrl = await uploadImageFile(productId, resizedFile, filePath);
//       paths.push(uploadUrl);
//     }
//     return paths;
//   };

//   const uploadImageFile = async (productId, file, filePath) => {
//     const fileRef = ref(storage, filePath);
//     const metadata = {
//       customMetadata: {
//         productId: productId,
//       },
//     };

//     try {
//       const uploadTask = uploadBytesResumable(fileRef, file, metadata);

//       uploadTask.on('state_changed', (snapshot) => {
//         const progress =
//           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         updateProgress(file.name, progress);

//         // setUploadProgress((prevProgress) => ({
//         //   ...prevProgress,
//         //   [file.name]: progress,
//         // }));
//       });

//       await uploadTask;

//       // 업로드가 완료되면 상태를 업데이트합니다.
//       setCompletedCount((prev) => prev + 1);
//       setUploadedFileSize((prev) => prev + file.size);
//       const downloadURL = await getDownloadURL(fileRef);
//       return downloadURL;
//     } catch (error) {
//       console.error('Upload failed:', error);
//       throw new Error('Failed to upload image');
//     }
//   };

//   const updateProgress = (fileName, progress) => {
//     setUploadProgress((prevProgress) => ({
//       ...prevProgress,
//       [fileName]: {
//         ...prevProgress[fileName],
//         progress: progress,
//       },
//     }));
//   };
//   const handleUpload = useCallback(async () => {
//     // 입력 검증 및 초기 설정
//     const showAlertIfEmpty = (value, message) => {
//       if (!value.trim()) {
//         MessageBoxInform(message);
//         throw new Error(message);
//       }
//     };

//     try {
//       showAlertIfEmpty(title, '제목을 입력해주세요.');
//       showAlertIfEmpty(content, '내용을 입력해주세요.');

//       if (!selectedCategory || selectedCategory.length === 0) {
//         MessageBoxInform('카테고리를 선택해주세요.');
//         throw new Error('카테고리를 선택해주세요.');
//       }

//       // if (!selectedBrand || selectedBrand.length === 0) {
//       //   MessageBoxInform('브랜드를 선택해주세요.');
//       //   throw new Error('브랜드를 선택해주세요.');
//       // }

//       if (!selectedArea || selectedArea.length === 0) {
//         MessageBoxInform('알림받을 지역을 선택해주세요.');
//         throw new Error('알림받을 지역을 선택해주세요.');
//       }

//       if (!selectedFiles.length) {
//         MessageBoxInform('이미지를 업로드해주세요.');
//         throw new Error('이미지를 업로드해주세요.');
//       }

//       const isConfirmed = await MessageBoxConfirm(
//         '다음 내용으로 상품을 업로드하시겠습니까?',
//         `제목: ${title}\n내용: ${content}\n가격: ${formatPrice(
//           price
//         )} (${koreanPrice} 원)`
//       );
//       if (!isConfirmed) return;

//       setUploadingFiles(selectedFiles);
//       // Firestore에 먼저 데이터 저장
//       const productRef = await addDoc(collection(db, 'products'), {
//         title,
//         price,
//         content,
//         categoryId: selectedCategory?.id || null,
//         brandId: selectedBrand?.id || null,
//         areaId: selectedArea?.id || null,
//         uid: currentUser.uid,
//         author: currentUser.displayName,
//         createDate: new Date(),
//         likes: 0,
//         views: 0,
//         chats: 0,
//         state: 0,
//       });

//       const imageSizes = [
//         {
//           width: 1920,
//           height: 1080,
//           collectionName: 'xlarge_images',
//           prefix: 'xlarge_',
//         }, // 매우 큰 화면용
//         {
//           width: 1366,
//           height: 768,
//           collectionName: 'large_images',
//           prefix: 'large_',
//         }, // 노트북 대형 화면용
//         {
//           width: 854,
//           height: 480,
//           collectionName: 'medium_images',
//           prefix: 'medium_',
//         }, // 일반적인 데스크탑 화면용
//         {
//           width: 640,
//           height: 360,
//           collectionName: 'small_images',
//           prefix: 'small_',
//         }, // 태블릿 대형 화면용
//         {
//           width: 426,
//           height: 240,
//           collectionName: 'xsmall_images',
//           prefix: 'xsmall_',
//         }, // 스마트폰 대형 화면용
//         {
//           width: 213,
//           height: 120,
//           collectionName: 'icon_images',
//           prefix: 'icon_',
//         }, // 아이콘 사이즈
//       ];

//       for (const imageSize of imageSizes) {
//         const fileUrls = await uploadImageFiles(
//           productRef.id,
//           imageSize.width,
//           imageSize.height,
//           imageSize.prefix
//         );
//         const imageUpdates = {};
//         imageUpdates[imageSize.collectionName] = fileUrls;
//         // Firestore 문서 업데이트
//         await updateDoc(productRef, imageUpdates);
//       }

//       await MessageBoxInform('판매물건이 업로드 되었습니다.');
//       navigate('/');
//     } catch (error) {
//       console.error('Error:', error);
//     } finally {
//       setUploadingFiles([]);
//       setSelectedFiles([]); // 파일들이 모두 업로드되면 상태 초기화
//       setUploadProgress([]); // Reset progress for all files
//       setTotalFileSize(0);
//       setUploadedFileSize(0);
//       setPreviews([]);
//       resetFileInput();
//     }
//   }, [
//     title,
//     content,
//     selectedFiles,
//     selectedArea,
//     currentUser,
//     formatPrice,
//     koreanPrice,
//     navigate,
//   ]);

//   const handleCancel = () => {
//     navigate(-1);
//   };

//   const onSelectCategory = async (selectCategory) => {
//     setSelectedCategory(selectCategory);
//   };

//   const onSelectBrand = async (selectBrand) => {
//     setSelectedBrand(selectBrand);
//   };

//   const ImageUploader = ({ onImagesSelected, onImageRemoved }) => {
//     const fileInputRef = useRef(null);

//     const handleImageChange = useCallback(
//       (event) => {
//         const files = Array.from(event.target.files);
//         const imageFiles = files.filter((file) =>
//           file.type.startsWith('image/')
//         );

//         if (imageFiles.length > 5) {
//           alert('You can only upload up to 5 images.');
//           fileInputRef.current.value = '';
//           return;
//         }

//         onImagesSelected(imageFiles);
//       },
//       [onImagesSelected]
//     );

//     return (
//       <div className="image-input-container">
//         <input
//           type="file"
//           multiple
//           onChange={handleImageChange}
//           ref={fileInputRef}
//           accept="image/*"
//         />
//         <div className="preview-container">
//           {imageFiles.map((file, index) => (
//             <PreviewImage
//               key={index}
//               file={file}
//               onRemove={() => onImageRemoved(index)}
//             />
//           ))}
//         </div>
//       </div>
//     );
//   };
//   return (
//     <div className="upload">
//       <div className="container">
//         <div className="d-flex justify-content-between align-items-center mb-4">
//           <h3>업로드</h3>
//         </div>
//         <div className="form-section">
//           <label className="title-label" for="title">
//             제목
//           </label>
//           <input
//             type="text"
//             id="title"
//             name="title"
//             className="form-control mt-2"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             placeholder="제목을 입력하세요"
//           />
//         </div>

//         <div className="form-section">
//           <label className="title-label" for="title">
//             카테고리 설정
//           </label>
//           <ProductCategorySelector onSelectCategory={onSelectCategory} />
//         </div>

//         {selectedCategory && (
//           <div className="form-section">
//             <label className="title-label" for="title">
//               브랜드 설정
//             </label>
//             <ProductBrandSelector onSelectBrand={onSelectBrand} />
//           </div>
//         )}

//         <div className="mt-2 position-relative">
//           <p className="mb-4"></p>
//           <div className="form-section">
//             <label className="content-label" for="content">
//               내용
//             </label>
//             <textarea
//               id="content"
//               name="content"
//               className="form-control"
//               rows="5"
//               value={content}
//               onChange={handleContentChange}
//               placeholder="내용을 입력하세요"
//             ></textarea>
//           </div>
//           <div className="position-absolute top-0 end-0">
//             <p>{contentByteSize}/200 바이트 입력됨</p>
//           </div>
//         </div>

//         <div className="form-section">
//           <label className="price-label" for="price">
//             가격
//           </label>
//           <input
//             type="text"
//             id="price"
//             name="price"
//             className="form-control mt-2"
//             placeholder="Price (e.g. 1,000원)"
//             value={formatPrice(price)}
//             onChange={handlePriceChange}
//           />
//           <span className="korean-price-label">
//             {koreanPrice !== '0' ? `${koreanPrice}원` : '무료 나눔'}
//           </span>
//         </div>

//         {uploadingFiles && uploadingFiles.length > 0 && (
//           <div className="progress-container">
//             {/* 전체 진행률 표시 */}
//             <div className="circular-progress">
//               <svg viewBox="0 0 100 100">
//                 <circle
//                   className="circle-bg"
//                   cx="50"
//                   cy="50"
//                   r={RADIUS}
//                 ></circle>
//                 <circle
//                   className="circle"
//                   cx="50"
//                   cy="50"
//                   r={RADIUS}
//                   strokeDasharray={`${
//                     (uploadedFileSize / totalFileSize) * CIRCUMFERENCE
//                   }, ${CIRCUMFERENCE}`}
//                   strokeDashoffset={`${
//                     CIRCUMFERENCE -
//                     (uploadedFileSize / totalFileSize) * CIRCUMFERENCE
//                   }`}
//                 ></circle>
//               </svg>
//               {/* <div className="progress-text">{`${uploadedFileSize}/${totalFileSize} bytes`}</div> */}
//               <div className="progress-text">{`${Math.round(
//                 (uploadedFileSize / totalFileSize) * 100
//               )}%`}</div>
//             </div>
//           </div>
//         )}
//         {MessageBoxRender()}
//         <div className="form-section">
//           <label className="title-label" htmlFor="file-upload">
//             이미지 업로드
//           </label>
//           <div className="mt-2">
//             <progress
//               value={uploadProgress}
//               max="100"
//               className="w-100"
//             ></progress>
//             <span>{Math.round(uploadProgress)}%</span>
//           </div>

//           <div className="image-input-container">
//             <input
//               className="form-control mt-2"
//               id="file-upload"
//               type="file"
//               multiple
//               onChange={handleImageChange}
//               ref={fileInputRef}
//             />
//             <span className="image-count">c: {selectedFiles.length}</span>
//           </div>

//           <div className="preview-container">
//             {previews &&
//               previews.map((preview, index) => (
//                 <PreviewImage
//                   key={index}
//                   preview={preview}
//                   onRemove={() => handleRemoveImage(index)}
//                 />
//               ))}
//           </div>
//         </div>
//         {/* <button onClick={() => setShowMap(true)}>보여질 동네 선택</button> */}
//         {/* {showMap && (<Region onSave={handleSaveArea} />)} */}
//         {/* <div className="form-section">
//           <label className="title-label" for="title">위치 지정</label>
//           { <Region onSave={handleSaveArea} mode='region' />}
//         </div> */}
//         <div className="region-container">
//           <label className="title-label" htmlFor="title">
//             위치 지정
//           </label>
//           {isCompleted === false ? (
//             <label className="content-label" htmlFor="title">
//               지도에서 영역을 지정해주세요.
//             </label>
//           ) : (
//             <label className="content-label" htmlFor="title">
//               배달가능 영역이 설정되었습니다. 업로드를 진행해주세요.
//             </label>
//           )}
//           {<Region onSave={handleSaveArea} mode="region" />}
//         </div>
//         <div className="bottom-buttons">
//           <button
//             className="btn btn-outline-primary action-button"
//             onClick={handleCancel}
//           >
//             취소하기
//           </button>
//           <button
//             className="btn btn-outline-danger action-button"
//             onClick={handleUpload}
//           >
//             올리기
//           </button>
//         </div>

//         <br />
//       </div>
//     </div>
//   );
// };

// export default Upload;

// import React, { useRef, useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   storage,
//   collection,
//   doc,
//   addDoc,
//   updateDoc,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
//   arrayUnion,
// } from './firebaseConfig';

// import {
//   Box,
//   Container,
//   Button,
//   Stack,
//   TextField,
//   Typography,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   IconButton,
//   Grid,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import { styled } from '@mui/system';

// import GoogleMapRegion from './Common/GoogleMapRegion';
// import { useNavigate } from 'react-router-dom';
// import {
//   formatPrice,
//   numberToKorean,
//   getByteLength,
// } from './Utils/PriceFormatter';
// import './css/upload.css';
// import { RADIUS, CIRCUMFERENCE } from './Common/Constants';
// import useMessageBox from './Common/useMessageBox';
// import axios from 'axios';
// import ProductCategorySelector from './Stores/ProductCategorySelector';
// import ProductBrandSelector from './Stores/ProductBrandSelector';
// import { resizeImage } from './Utils/ImageHelper';
// import PreviewImage from './Common/PreviewImage';
// import CloseIcon from '@mui/icons-material/Close';

// const Input = styled('input')({
//   display: 'none',
// });
// const Upload = () => {
//   const { t, i18n } = useTranslation();
//   const fileInputRef = useRef(null);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();

//   const [title, setTitle] = useState('');
//   const [price, setPrice] = useState('0');
//   const [content, setContent] = useState('');
//   // const [images, setImages] = useState([]); // 여러 이미지를 관리하기 위한 상태
//   const [imageFiles, setImageFiles] = useState([]);
//   const [previews, setPreviews] = useState([]); // 여러 이미지의 미리보기 URL을 위한 상태
//   const [koreanPrice, setKoreanPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState([]);
//   const [isCompleted, setIsCompleted] = useState(false);

//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [fileUrls, setFileUrls] = useState([]);
//   const [uploadProgress, setUploadProgress] = useState([]);
//   const [uploadingFiles, setUploadingFiles] = useState([]);
//   const [completedCount, setCompletedCount] = useState(0);
//   const [totalFileSize, setTotalFileSize] = useState(uploadingFiles.length);
//   const [uploadedFileSize, setUploadedFileSize] = useState(0);
//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');

//   const handleSnackbarClose = () => setSnackbarOpen(false);

//   useEffect(() => {
//     // Generate previews
//     imageFiles.forEach((file) => {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setPreviews((prev) => [...prev, reader.result]);
//       };
//       reader.readAsDataURL(file);
//     });

//     return () => {
//       // Cleanup previews
//       setPreviews([]);
//     };
//   }, [imageFiles]);

//   const handleImagesSelected = (files) => {
//     setImageFiles(files);
//   };

//   const handleImageRemoved = (index) => {
//     setImageFiles((curr) => curr.filter((_, idx) => idx !== index));
//     setPreviews((curr) => curr.filter((_, idx) => idx !== index));
//   };

//   const handleSaveArea = (savedArea) => {
//     // savedArea 값을 서버에 저장하는 로직
//     setSelectedArea([...savedArea], () => {
//       console.log('Updated selectedArea:', selectedArea);
//     });
//     setIsCompleted(true);
//   };
//   useEffect(() => {
//     console.log('selectedArea updated:', selectedArea);
//   }, [selectedArea]);
//   // 가격이 변경될 때 한글로 된 가격도 함께 변경
//   useEffect(() => {
//     setKoreanPrice(numberToKorean(price));
//   }, [price]);

//   // 컴포넌트가 언마운트될 때 입력 필드 초기화
//   useEffect(() => {
//     return () => {
//       resetFileInput();
//     };
//   }, []);

//   // 가격 입력 관리
//   const handlePriceChange = useCallback((e) => {
//     let priceValue = e.target.value.replace(/[^0-9]/g, '');
//     if (priceValue === '0' || priceValue === '') {
//       priceValue = '0';
//     } else {
//       while (priceValue.startsWith('0')) {
//         priceValue = priceValue.substring(1);
//       }
//     }
//     setPrice(priceValue);

//     // Update the displayed Korean price
//     if (priceValue === '0' || priceValue === '') {
//       setKoreanPrice('무료 나눔');
//     } else {
//       // const formattedPrice = numberWithCommas(priceValue);
//       // setKoreanPrice(`${formattedPrice}원`);
//       setKoreanPrice(numberToKorean(price));
//     }
//   }, []);
//   const handleContentChange = useCallback(
//     (e) => {
//       if (getByteLength(e.target.value) <= 200) {
//         setContent(e.target.value);
//       }
//     },
//     [content]
//   );

//   const contentByteSize = getByteLength(content);

//   // 파일 입력 필드 초기화
//   const resetFileInput = () => {
//     if (fileInputRef.current) fileInputRef.current.value = '';
//   };

//   const handleImageChange = useCallback(
//     (event) => {
//       const tmpSelectedFiles = Array.from(event.target.files);

//       if (!tmpSelectedFiles.length) {
//         resetFileInput();
//         return;
//       }

//       if (tmpSelectedFiles.length > process.env.REACT_APP_SEND_IMAGE_MAX_LEN) {
//         MessageBoxInform(
//           `최대 ${process.env.REACT_APP_SEND_IMAGE_MAX_LEN}개의 이미지를 선택할 수 있습니다.`
//         );
//         resetFileInput();
//         return;
//       }

//       let totalSize = 0;
//       tmpSelectedFiles.forEach((file) => {
//         totalSize += file.size;
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           setPreviews((prevPreviews) => [...prevPreviews, reader.result]);
//           setTotalFileSize(totalSize);
//           setSelectedFiles((prevImages) => [...prevImages, file]);
//         };
//         reader.onerror = () => {
//           MessageBoxInform('파일을 읽는 도중 오류가 발생했습니다.');
//           // 여기서 추가적인 에러 처리 로직을 구현할 수 있습니다.
//         };
//         reader.readAsDataURL(file);
//       });
//     },
//     [previews, selectedFiles] // 의존성 배열 수정
//   );

//   const handleRemoveImage = useCallback((indexToRemove) => {
//     setSelectedFiles((prevImages) =>
//       prevImages.filter((_, index) => index !== indexToRemove)
//     );
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   }, []);

//   const uploadImageFiles = async (productId, width, height, prefix) => {
//     let paths = [];

//     for (const file of selectedFiles) {
//       const resizedFile = await resizeImage(file, width, height);
//       const filePath = `${
//         process.env.REACT_APP_USER_IMAGE_PATH
//       }/${productId}/${prefix}${Date.now()}_${file.name}`;
//       const uploadUrl = await uploadImageFile(productId, resizedFile, filePath);
//       paths.push(uploadUrl);
//     }
//     return paths;
//   };

//   const uploadImageFile = async (productId, file, filePath) => {
//     const fileRef = ref(storage, filePath);
//     const metadata = { customMetadata: { productId } };
//     const uploadTask = uploadBytesResumable(fileRef, file, metadata);

//     return new Promise((resolve, reject) => {
//       uploadTask.on(
//         'state_changed',
//         (snapshot) => {
//           const progress =
//             (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//           updateProgress(file.name, progress, snapshot.totalBytes);
//         },
//         reject,
//         () => {
//           setCompletedCount((prev) => prev + 1);
//           setUploadedFileSize((prev) => prev + file.size);
//           const downloadURL = getDownloadURL(uploadTask.snapshot.ref).then(
//             resolve,
//             reject
//           );
//           return downloadURL;
//         }
//       );
//     });
//   };

//   const updateProgress = (fileName, progress, fileSize) => {
//     setUploadProgress((prevProgress) => ({
//       ...prevProgress,
//       [fileName]: { progress, fileSize },
//     }));
//   };
//   // const uploadImageFile = async (productId, file, filePath) => {
//   //   const fileRef = ref(storage, filePath);
//   //   const metadata = {
//   //     customMetadata: {
//   //       productId: productId,
//   //     },
//   //   };

//   //   try {
//   //     const uploadTask = uploadBytesResumable(fileRef, file, metadata);

//   //     uploadTask.on('state_changed', (snapshot) => {
//   //       const progress =
//   //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//   //       updateProgress(file.name, progress);

//   //       // setUploadProgress((prevProgress) => ({
//   //       //   ...prevProgress,
//   //       //   [file.name]: progress,
//   //       // }));
//   //     });

//   //     await uploadTask;

//   //     // 업로드가 완료되면 상태를 업데이트합니다.
//   //     setCompletedCount((prev) => prev + 1);
//   //     setUploadedFileSize((prev) => prev + file.size);
//   //     const downloadURL = await getDownloadURL(fileRef);
//   //     return downloadURL;
//   //   } catch (error) {
//   //     console.error('Upload failed:', error);
//   //     throw new Error('Failed to upload image');
//   //   }
//   // };

//   // const updateProgress = (fileName, progress) => {
//   //   setUploadProgress((prevProgress) => ({
//   //     ...prevProgress,
//   //     [fileName]: {
//   //       ...prevProgress[fileName],
//   //       progress: progress,
//   //     },
//   //   }));
//   // };

//   const handleUpload = useCallback(async () => {
//     // 입력 검증 및 초기 설정
//     const showAlertIfEmpty = (value, message) => {
//       if (!value.trim()) {
//         MessageBoxInform(message);
//         throw new Error(message);
//       }
//     };

//     try {
//       showAlertIfEmpty(title, '제목을 입력해주세요.');
//       showAlertIfEmpty(content, '내용을 입력해주세요.');

//       if (!selectedCategory || selectedCategory.length === 0) {
//         MessageBoxInform('카테고리를 선택해주세요.');
//         throw new Error('카테고리를 선택해주세요.');
//       }

//       if (!selectedBrand || selectedBrand.length === 0) {
//         MessageBoxInform('브랜드를 선택해주세요.');
//         throw new Error('브랜드를 선택해주세요.');
//       }

//       if (!selectedArea || selectedArea.length === 0) {
//         MessageBoxInform('알림받을 지역을 선택해주세요.');
//         throw new Error('알림받을 지역을 선택해주세요.');
//       }

//       if (!selectedFiles.length) {
//         MessageBoxInform('이미지를 업로드해주세요.');
//         throw new Error('이미지를 업로드해주세요.');
//       }

//       const isConfirmed = await MessageBoxConfirm(
//         '다음 내용으로 상품을 업로드하시겠습니까?',
//         `제목: ${title}\n내용: ${content}\n가격: ${formatPrice(
//           price
//         )} (${koreanPrice} 원)`
//       );
//       if (!isConfirmed) return;

//       setUploadingFiles(selectedFiles);
//       // Firestore에 먼저 데이터 저장
//       const productRef = await addDoc(collection(db, 'products'), {
//         title,
//         price,
//         content,
//         categoryId: selectedCategory?.id || null,
//         brandId: selectedBrand?.id || null,
//         areaId: selectedArea?.id || null,
//         uid: currentUser.uid,
//         createDate: new Date(),
//         likes: 0,
//         views: 0,
//         chats: 0,
//         state: 0,
//       });

//       const imageSizes = [
//         {
//           width: 1920,
//           height: 1080,
//           collectionName: 'xlarge_images',
//           prefix: 'xlarge_',
//         }, // 매우 큰 화면용
//         {
//           width: 1366,
//           height: 768,
//           collectionName: 'large_images',
//           prefix: 'large_',
//         }, // 노트북 대형 화면용
//         {
//           width: 854,
//           height: 480,
//           collectionName: 'medium_images',
//           prefix: 'medium_',
//         }, // 일반적인 데스크탑 화면용
//         {
//           width: 640,
//           height: 360,
//           collectionName: 'small_images',
//           prefix: 'small_',
//         }, // 태블릿 대형 화면용
//         {
//           width: 426,
//           height: 240,
//           collectionName: 'xsmall_images',
//           prefix: 'xsmall_',
//         }, // 스마트폰 대형 화면용
//         {
//           width: 213,
//           height: 120,
//           collectionName: 'icon_images',
//           prefix: 'icon_',
//         }, // 아이콘 사이즈
//       ];

//       for (const imageSize of imageSizes) {
//         const fileUrls = await uploadImageFiles(
//           productRef.id,
//           imageSize.width,
//           imageSize.height,
//           imageSize.prefix
//         );
//         const imageUpdates = {};
//         imageUpdates[imageSize.collectionName] = fileUrls;
//         // Firestore 문서 업데이트
//         await updateDoc(productRef, imageUpdates);
//       }

//       await MessageBoxInform('판매물건이 업로드 되었습니다.');
//       navigate('/');
//     } catch (error) {
//       console.error('Error:', error);
//     } finally {
//       setUploadingFiles([]);
//       setSelectedFiles([]); // 파일들이 모두 업로드되면 상태 초기화
//       setUploadProgress([]); // Reset progress for all files
//       setTotalFileSize(0);
//       setUploadedFileSize(0);
//       setPreviews([]);
//       resetFileInput();
//     }
//   }, [
//     title,
//     content,
//     selectedFiles,
//     selectedArea,
//     currentUser,
//     formatPrice,
//     koreanPrice,
//     navigate,
//   ]);

//   const handleCancel = () => {
//     navigate(-1);
//   };

//   const onSelectCategory = async (selectCategory) => {
//     setSelectedCategory(selectCategory);
//   };

//   const onSelectBrand = async (selectBrand) => {
//     setSelectedBrand(selectBrand);
//   };

//   const ImageUploader = ({ onImagesSelected, onImageRemoved }) => {
//     const fileInputRef = useRef(null);

//     const handleImageChange = useCallback(
//       (event) => {
//         const files = Array.from(event.target.files);
//         const imageFiles = files.filter((file) =>
//           file.type.startsWith('image/')
//         );

//         if (imageFiles.length > 5) {
//           alert('You can only upload up to 5 images.');
//           fileInputRef.current.value = '';
//           return;
//         }

//         onImagesSelected(imageFiles);
//       },
//       [onImagesSelected]
//     );

//     return (
//       <div className="image-input-container">
//         <input
//           type="file"
//           multiple
//           onChange={handleImageChange}
//           ref={fileInputRef}
//           accept="image/*"
//         />
//         <div className="preview-container">
//           {imageFiles.map((file, index) => (
//             <PreviewImage
//               key={index}
//               file={file}
//               onRemove={() => onImageRemoved(index)}
//             />
//           ))}
//         </div>
//       </div>
//     );
//   };
//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '80%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3', // 연한 회색 테두리
//           borderRadius: '16px', // 둥근 테두리
//           padding: '8px 16px', // 안쪽 여백
//           marginBottom: '16px', // 아래쪽 여백
//           backgroundColor: 'transparent', // 연한 회색 배경색
//           // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 그림자 효과
//           transition: 'transform 0.2s', // 호버 시 애니메이션
//           '&:hover': {
//             transform: 'scale(1.02)', // 호버 시 크기 증가
//           },
//         }}
//       >
//         <Typography variant="h4" gutterBottom>
//           {t('uploadUsedProduct')}
//         </Typography>
//       </Box>
//       <Stack spacing={2} component="form" noValidate autoComplete="off">
//         <TextField
//           fullWidth
//           label="Title"
//           value={title}
//           onChange={(e) => setTitle(e.target.value)}
//         />
//         <TextField
//           fullWidth
//           label="Description"
//           value={content}
//           multiline
//           rows={4}
//           onChange={handleContentChange}
//         />
//         <TextField
//           fullWidth
//           label="Price"
//           value={formatPrice(price)}
//           onChange={handlePriceChange}
//           placeholder="Price (e.g. 1,000)"
//           type="text"
//           // InputProps={{
//           //   endAdornment: (
//           //     <Typography variant="body2">{koreanPrice}원</Typography>
//           //   ),
//           // }}
//         />
//         <Typography
//           variant="body2"
//           className="korean-price-label"
//           sx={{ mt: 1 }}
//         >
//           {koreanPrice}원
//         </Typography>

//         <Grid container spacing={0}>
//           <Typography variant="h6" gutterBottom>
//             {t('setCategory')}
//           </Typography>
//           <Grid item xs={12}>
//             <ProductCategorySelector onSelectCategory={onSelectCategory} />
//           </Grid>
//           <Typography variant="h6" gutterBottom>
//             {t('setBrand')}
//           </Typography>
//           <Grid item xs={12}>
//             <ProductBrandSelector onSelectBrand={onSelectBrand} />
//           </Grid>
//         </Grid>
//         <Typography variant="h6" gutterBottom>
//           {t('uploadImage')}
//         </Typography>
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
//           <Button variant="outlined" component="label">
//             {t('chooseFiles')}
//             <input type="file" hidden multiple onChange={handleImageChange} />
//           </Button>
//         </Box>
//         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
//           {previews.map((src, index) => (
//             <Box
//               key={index}
//               sx={{ position: 'relative', width: 100, height: 100 }}
//             >
//               <img
//                 src={src}
//                 alt={`Preview ${index}`}
//                 style={{ width: '100%', height: '100%', borderRadius: '8px' }}
//               />
//               <IconButton
//                 onClick={() => handleRemoveImage(index)}
//                 sx={{
//                   position: 'absolute',
//                   top: 0,
//                   right: 0,
//                   color: 'error.main',
//                 }}
//               >
//                 <CloseIcon />
//               </IconButton>
//             </Box>
//           ))}
//         </Box>

//         {uploadingFiles && uploadingFiles.length > 0 && (
//           <Box
//             sx={{
//               position: 'fixed',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               zIndex: 1300,
//             }}
//           >
//             <CircularProgress
//               variant="determinate"
//               value={(uploadedFileSize / totalFileSize) * 100}
//               size={100}
//               thickness={5}
//               sx={{ color: 'primary.main' }}
//             />
//             <Box
//               sx={{
//                 top: 0,
//                 left: 0,
//                 bottom: 0,
//                 right: 0,
//                 position: 'absolute',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <Typography
//                 variant="caption"
//                 component="div"
//                 color="text.secondary"
//               >
//                 {`${Math.round((uploadedFileSize / totalFileSize) * 100)}%`}
//               </Typography>
//             </Box>
//           </Box>
//         )}
//         {MessageBoxRender()}

//         <Typography variant="h6" sx={{ mt: 2 }}>
//           {t('setDeliveryArea')}
//         </Typography>
//         <GoogleMapRegion onSave={handleSaveArea} isCompleted={isCompleted} />
//       </Stack>
//       <Box
//         sx={{
//           position: 'fixed',
//           bottom: 0,
//           left: 0,
//           right: 0,
//           bgcolor: 'background.paper',
//           boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
//           py: 2,
//           px: 3,
//           display: 'flex',
//           justifyContent: 'space-between',
//         }}
//       >
//         <Button variant="outlined" onClick={handleCancel} fullWidth>
//           {t('cancel')}
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleUpload}
//           fullWidth
//         >
//           {t('upload')}
//         </Button>
//       </Box>

//       <Snackbar open={false} autoHideDuration={6000} onClose={() => {}}>
//         <Alert severity="info">{t('someMessage')}</Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default Upload;

// import React, { useRef, useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   storage,
//   collection,
//   doc,
//   addDoc,
//   updateDoc,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from './firebaseConfig';

// import {
//   Box,
//   Container,
//   Button,
//   Stack,
//   TextField,
//   Typography,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   IconButton,
//   Grid,
//   LinearProgress,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import { styled } from '@mui/system';

// import GoogleMapRegion from './Common/GoogleMapRegion';
// import { useNavigate } from 'react-router-dom';
// import { getByteLength } from './Utils/PriceFormatter';
// import './css/upload.css';
// import useMessageBox from './Common/useMessageBox';
// import ProductCategorySelector from './Stores/ProductCategorySelector';
// import ProductBrandSelector from './Stores/ProductBrandSelector';
// import { resizeImage } from './Utils/ImageHelper';
// import PreviewImage from './Common/PreviewImage';
// import CloseIcon from '@mui/icons-material/Close';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';

// const Input = styled('input')({
//   display: 'none',
// });

// const MAX_IMAGE_UPLOAD = process.env.REACT_APP_SEND_IMAGE_MAX_LEN || 5;

// const Upload = () => {
//   const { t, i18n } = useTranslation();
//   const fileInputRef = useRef(null);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();

//   const [title, setTitle] = useState('');
//   const [price, setPrice] = useState('0');
//   const [content, setContent] = useState('');
//   const [imageFiles, setImageFiles] = useState([]);
//   const [previews, setPreviews] = useState([]);
//   const [formattedPrice, setFormattedPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState([]);
//   const [isCompleted, setIsCompleted] = useState(false);

//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [totalFileSize, setTotalFileSize] = useState(0);
//   const [uploadedFileSize, setUploadedFileSize] = useState(0);
//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [uploadingFiles, setUploadingFiles] = useState([]);

//   const theme = useTheme();
//   // Define media queries for mobile and tablet
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const isPad = useMediaQuery(theme.breakpoints.between('sm', 'md'));

//   const handleSnackbarClose = () => setSnackbarOpen(false);

//   useEffect(() => {
//     imageFiles.forEach((file) => {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setPreviews((prev) => [...prev, reader.result]);
//       };
//       reader.readAsDataURL(file);
//     });

//     return () => {
//       setPreviews([]);
//     };
//   }, [imageFiles]);

//   const handleImagesSelected = (files) => {
//     setImageFiles(files);
//   };

//   const handleImageRemoved = (index) => {
//     setImageFiles((curr) => curr.filter((_, idx) => idx !== index));
//     setPreviews((curr) => curr.filter((_, idx) => idx !== index));
//   };

//   const handleSaveArea = (savedArea) => {
//     setSelectedArea(savedArea); // savedArea is now an object with center and coordinates
//     console.log('Updated selectedArea:', savedArea);
//     setIsCompleted(true);
//   };

//   useEffect(() => {
//     setFormattedPrice(
//       new Intl.NumberFormat(i18n.language, {
//         style: 'currency',
//         currency: getCurrencyByLocale(i18n.language),
//       }).format(price)
//     );
//   }, [price, i18n.language]);

//   useEffect(() => {
//     return () => {
//       resetFileInput();
//     };
//   }, []);

//   const getCurrencyByLocale = (locale) => {
//     switch (locale) {
//       case 'ko':
//         return 'KRW';
//       case 'en':
//         return 'USD';
//       case 'jp':
//         return 'JPY';
//       case 'zh':
//         return 'CNY';
//       default:
//         return 'USD';
//     }
//   };

//   const handlePriceChange = useCallback(
//     (e) => {
//       let priceValue = e.target.value.replace(/[^0-9]/g, '');
//       if (priceValue === '0' || priceValue === '') {
//         priceValue = '0';
//       } else {
//         while (priceValue.startsWith('0')) {
//           priceValue = priceValue.substring(1);
//         }
//       }
//       setPrice(priceValue);

//       setFormattedPrice(
//         new Intl.NumberFormat(i18n.language, {
//           style: 'currency',
//           currency: getCurrencyByLocale(i18n.language),
//         }).format(priceValue)
//       );
//     },
//     [i18n.language]
//   );

//   const handleContentChange = useCallback(
//     (e) => {
//       if (getByteLength(e.target.value) <= 200) {
//         setContent(e.target.value);
//       }
//     },
//     [content]
//   );

//   const contentByteSize = getByteLength(content);

//   const resetFileInput = () => {
//     if (fileInputRef.current) fileInputRef.current.value = '';
//   };

//   const handleImageChange = useCallback(
//     (event) => {
//       const tmpSelectedFiles = Array.from(event.target.files);

//       if (!tmpSelectedFiles.length) {
//         resetFileInput();
//         return;
//       }

//       if (tmpSelectedFiles.length + imageFiles.length > MAX_IMAGE_UPLOAD) {
//         MessageBoxInform(
//           `최대 ${MAX_IMAGE_UPLOAD}개의 이미지를 선택할 수 있습니다.`
//         );
//         resetFileInput();
//         return;
//       }

//       let totalSize = 0;
//       tmpSelectedFiles.forEach((file) => {
//         totalSize += file.size;
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           setPreviews((prevPreviews) => [...prevPreviews, reader.result]);
//           setTotalFileSize(totalSize);
//           setImageFiles((prevImages) => [...prevImages, file]);
//         };
//         reader.onerror = () => {
//           MessageBoxInform('파일을 읽는 도중 오류가 발생했습니다.');
//         };
//         reader.readAsDataURL(file);
//       });
//     },
//     [imageFiles]
//   );

//   const handleRemoveImage = useCallback((indexToRemove) => {
//     setImageFiles((prevImages) =>
//       prevImages.filter((_, index) => index !== indexToRemove)
//     );
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   }, []);

//   const uploadImageFiles = async (productId, width, height, prefix) => {
//     let paths = [];

//     for (const file of imageFiles) {
//       const resizedFile = await resizeImage(file, width, height);
//       const filePath = `${
//         process.env.REACT_APP_USER_IMAGE_PATH
//       }/${productId}/${prefix}${Date.now()}_${file.name}`;
//       const uploadUrl = await uploadImageFile(productId, resizedFile, filePath);
//       paths.push(uploadUrl);
//     }
//     return paths;
//   };

//   const uploadImageFile = async (productId, file, filePath) => {
//     const fileRef = ref(storage, filePath);
//     const metadata = { customMetadata: { productId } };
//     const uploadTask = uploadBytesResumable(fileRef, file, metadata);

//     return new Promise((resolve, reject) => {
//       uploadTask.on(
//         'state_changed',
//         (snapshot) => {
//           const progress =
//             (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//           updateProgress(file.name, progress, snapshot.totalBytes);
//         },
//         reject,
//         () => {
//           setUploadedFileSize((prev) => prev + file.size);
//           getDownloadURL(uploadTask.snapshot.ref).then(resolve, reject);
//         }
//       );
//     });
//   };

//   const updateProgress = (fileName, progress, fileSize) => {
//     setUploadProgress((prevProgress) => ({
//       ...prevProgress,
//       [fileName]: { progress, fileSize },
//     }));
//   };

//   const handleUpload = useCallback(async () => {
//     const showAlertIfEmpty = (value, message) => {
//       if (!value.trim()) {
//         MessageBoxInform(message);
//         throw new Error(message);
//       }
//     };

//     try {
//       showAlertIfEmpty(title, t('enterTitle'));
//       showAlertIfEmpty(content, t('enterContent'));

//       if (!selectedCategory || selectedCategory.length === 0) {
//         MessageBoxInform(t('selectCategory'));
//         throw new Error(t('selectCategory'));
//       }

//       if (!selectedBrand || selectedBrand.length === 0) {
//         MessageBoxInform(t('selectBrand'));
//         throw new Error(t('selectBrand'));
//       }

//       if (!selectedArea || !selectedArea.center || !selectedArea.coordinates) {
//         MessageBoxInform(t('selectArea'));
//         throw new Error(t('selectArea'));
//       }

//       if (!imageFiles.length) {
//         MessageBoxInform(t('uploadImages'));
//         throw new Error(t('uploadImages'));
//       }

//       const isConfirmed = await MessageBoxConfirm(
//         t('confirmUpload'),
//         `${t('title')}: ${title}\n${t('description')}: ${content}\n${t(
//           'price'
//         )}: ${formattedPrice}`
//       );
//       if (!isConfirmed) return;

//       setUploadingFiles(imageFiles);

//       const productRef = await addDoc(collection(db, 'products'), {
//         title,
//         price,
//         content,
//         categoryId: selectedCategory?.id || null,
//         brandId: selectedBrand?.id || null,
//         areaId: selectedArea?.id || null,
//         uid: currentUser.uid,
//         createDate: new Date(),
//         likes: 0,
//         views: 0,
//         chats: 0,
//         state: 0,
//       });

//       const imageSizes = [
//         {
//           width: 1920,
//           height: 1080,
//           collectionName: 'xlarge_images',
//           prefix: 'xlarge_',
//         },
//         {
//           width: 1366,
//           height: 768,
//           collectionName: 'large_images',
//           prefix: 'large_',
//         },
//         {
//           width: 854,
//           height: 480,
//           collectionName: 'medium_images',
//           prefix: 'medium_',
//         },
//         {
//           width: 640,
//           height: 360,
//           collectionName: 'small_images',
//           prefix: 'small_',
//         },
//         {
//           width: 426,
//           height: 240,
//           collectionName: 'xsmall_images',
//           prefix: 'xsmall_',
//         },
//         {
//           width: 213,
//           height: 120,
//           collectionName: 'icon_images',
//           prefix: 'icon_',
//         },
//       ];

//       for (const imageSize of imageSizes) {
//         const fileUrls = await uploadImageFiles(
//           productRef.id,
//           imageSize.width,
//           imageSize.height,
//           imageSize.prefix
//         );
//         const imageUpdates = {};
//         imageUpdates[imageSize.collectionName] = fileUrls;
//         await updateDoc(productRef, imageUpdates);
//       }

//       await MessageBoxInform(t('productUploaded'));
//       navigate('/');
//     } catch (error) {
//       console.error('Error:', error);
//     } finally {
//       setUploadingFiles([]);
//       setImageFiles([]);
//       setUploadProgress(0);
//       setTotalFileSize(0);
//       setUploadedFileSize(0);
//       setPreviews([]);
//       resetFileInput();
//     }
//   }, [
//     title,
//     content,
//     imageFiles,
//     selectedArea,
//     currentUser,
//     i18n.language,
//     navigate,
//   ]);

//   const handleCancel = () => {
//     navigate(-1);
//   };

//   const onSelectCategory = async (selectCategory) => {
//     setSelectedCategory(selectCategory);
//   };

//   const onSelectBrand = async (selectBrand) => {
//     setSelectedBrand(selectBrand);
//   };

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3',
//           borderRadius: '16px',
//           padding: '8px 16px',
//           marginBottom: '16px',
//           backgroundColor: 'transparent',
//           transition: 'transform 0.2s',
//           '&:hover': {
//             transform: 'scale(1.02)',
//           },
//         }}
//       >
//         <Typography variant="h4" gutterBottom>
//           {t('uploadUsedProduct')}
//         </Typography>
//       </Box>
//       <Stack spacing={2} component="form" noValidate autoComplete="off">
//         <TextField
//           fullWidth
//           label={t('title')}
//           value={title}
//           onChange={(e) => setTitle(e.target.value)}
//         />
//         <TextField
//           fullWidth
//           label={t('description')}
//           value={content}
//           multiline
//           rows={4}
//           onChange={handleContentChange}
//         />
//         <TextField
//           fullWidth
//           label={t('price')}
//           value={price}
//           onChange={handlePriceChange}
//           placeholder="Price (e.g. 1,000)"
//           type="text"
//         />
//         <Typography
//           variant="body2"
//           className="korean-price-label"
//           sx={{ mt: 1 }}
//         >
//           {formattedPrice}
//         </Typography>

//         <Grid container spacing={0}>
//           <Typography variant="h6" gutterBottom>
//             {t('setCategory')}
//           </Typography>
//           <Grid item xs={12}>
//             <ProductCategorySelector onSelectCategory={onSelectCategory} />
//           </Grid>
//           <Typography variant="h6" gutterBottom>
//             {t('setBrand')}
//           </Typography>
//           <Grid item xs={12}>
//             <ProductBrandSelector onSelectBrand={onSelectBrand} />
//           </Grid>
//         </Grid>
//         <Typography variant="h6" gutterBottom>
//           {t('uploadImage')}
//         </Typography>
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
//           <Button variant="outlined" component="label">
//             {t('chooseFiles')}
//             <input type="file" hidden multiple onChange={handleImageChange} />
//           </Button>
//         </Box>
//         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
//           {previews.map((src, index) => (
//             <Box
//               key={index}
//               sx={{ position: 'relative', width: 100, height: 100 }}
//             >
//               <img
//                 src={src}
//                 alt={`Preview ${index}`}
//                 style={{ width: '100%', height: '100%', borderRadius: '8px' }}
//               />
//               <IconButton
//                 onClick={() => handleRemoveImage(index)}
//                 sx={{
//                   position: 'absolute',
//                   top: 0,
//                   right: 0,
//                   color: 'error.main',
//                 }}
//               >
//                 <CloseIcon />
//               </IconButton>
//             </Box>
//           ))}
//         </Box>

//         {uploadingFiles.length > 0 && (
//           <Box
//             sx={{
//               position: 'fixed',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               zIndex: 1300,
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//             }}
//           >
//             <CircularProgress
//               variant="determinate"
//               value={(uploadedFileSize / totalFileSize) * 100}
//               size={100}
//               thickness={5}
//               sx={{ color: 'primary.main' }}
//             />
//             <Box
//               sx={{
//                 top: 0,
//                 left: 0,
//                 bottom: 0,
//                 right: 0,
//                 position: 'absolute',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <Typography
//                 variant="caption"
//                 component="div"
//                 color="text.secondary"
//               >{`${Math.round(
//                 (uploadedFileSize / totalFileSize) * 100
//               )}%`}</Typography>
//             </Box>
//           </Box>
//         )}
//         {MessageBoxRender()}

//         <Typography variant="h6" sx={{ mt: 2 }}>
//           {t('setDeliveryArea')}
//         </Typography>
//         <GoogleMapRegion onSave={handleSaveArea} />
//       </Stack>
//       <Box
//         sx={{
//           position: 'fixed',
//           bottom: isMobile ? 50 : isPad ? 30 : 0,
//           left: 0,
//           right: 0,
//           bgcolor: 'background.paper',
//           boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
//           py: 2,
//           px: 3,
//           display: 'flex',
//           justifyContent: 'space-between',
//         }}
//       >
//         <Button variant="outlined" onClick={handleCancel} fullWidth>
//           {t('cancel')}
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleUpload}
//           fullWidth
//         >
//           {t('upload')}
//         </Button>
//       </Box>

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//       >
//         <Alert severity="info">{snackbarMessage}</Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default Upload;

// import React, { useRef, useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   storage,
//   collection,
//   doc,
//   addDoc,
//   updateDoc,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from './firebaseConfig';

// import {
//   Box,
//   Container,
//   Button,
//   Stack,
//   TextField,
//   Typography,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   IconButton,
//   Grid,
//   Tooltip,
//   Modal,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import { styled } from '@mui/system';

// import GoogleMapRegion from './Common/GoogleMapRegion';
// import { useNavigate } from 'react-router-dom';
// import { getByteLength } from './Utils/PriceFormatter';
// import './css/upload.css';
// import useMessageBox from './Common/useMessageBox';
// import ProductCategorySelector from './Stores/ProductCategorySelector';
// import ProductBrandSelector from './Stores/ProductBrandSelector';
// import { resizeImage } from './Utils/ImageHelper';
// import PreviewImage from './Common/PreviewImage';
// import CloseIcon from '@mui/icons-material/Close';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
// import InfoIcon from '@mui/icons-material/Info';

// const Input = styled('input')({
//   display: 'none',
// });

// const MAX_IMAGE_UPLOAD = process.env.REACT_APP_SEND_IMAGE_MAX_LEN || 5;

// const modalStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

// const Upload = () => {
//   const { t, i18n } = useTranslation();
//   const fileInputRef = useRef(null);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();

//   const [title, setTitle] = useState('');
//   const [price, setPrice] = useState('0');
//   const [content, setContent] = useState('');
//   const [imageFiles, setImageFiles] = useState([]);
//   const [previews, setPreviews] = useState([]);
//   const [formattedPrice, setFormattedPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState([]);
//   const [isCompleted, setIsCompleted] = useState(false);

//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [totalFileSize, setTotalFileSize] = useState(0);
//   const [uploadedFileSize, setUploadedFileSize] = useState(0);
//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [uploadingFiles, setUploadingFiles] = useState([]);

//   const theme = useTheme();
//   // Define media queries for mobile and tablet
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const isPad = useMediaQuery(theme.breakpoints.between('sm', 'md'));

//   const handleSnackbarClose = () => setSnackbarOpen(false);

//   useEffect(() => {
//     imageFiles.forEach((file) => {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setPreviews((prev) => [...prev, reader.result]);
//       };
//       reader.readAsDataURL(file);
//     });

//     return () => {
//       setPreviews([]);
//     };
//   }, [imageFiles]);

//   const handleImagesSelected = (files) => {
//     setImageFiles(files);
//   };

//   const handleImageRemoved = (index) => {
//     setImageFiles((curr) => curr.filter((_, idx) => idx !== index));
//     setPreviews((curr) => curr.filter((_, idx) => idx !== index));
//   };

//   const handleSaveArea = (savedArea) => {
//     setSelectedArea(savedArea); // savedArea is now an object with center and coordinates
//     console.log('Updated selectedArea:', savedArea);
//     setIsCompleted(true);
//   };

//   useEffect(() => {
//     setFormattedPrice(
//       new Intl.NumberFormat(i18n.language, {
//         style: 'currency',
//         currency: getCurrencyByLocale(i18n.language),
//       }).format(price)
//     );
//   }, [price, i18n.language]);

//   useEffect(() => {
//     return () => {
//       resetFileInput();
//     };
//   }, []);

//   const getCurrencyByLocale = (locale) => {
//     switch (locale) {
//       case 'ko':
//         return 'KRW';
//       case 'en':
//         return 'USD';
//       case 'jp':
//         return 'JPY';
//       case 'zh':
//         return 'CNY';
//       default:
//         return 'USD';
//     }
//   };

//   const handlePriceChange = useCallback(
//     (e) => {
//       let priceValue = e.target.value.replace(/[^0-9]/g, '');
//       if (priceValue === '0' || priceValue === '') {
//         priceValue = '0';
//       } else {
//         while (priceValue.startsWith('0')) {
//           priceValue = priceValue.substring(1);
//         }
//       }
//       setPrice(priceValue);

//       setFormattedPrice(
//         new Intl.NumberFormat(i18n.language, {
//           style: 'currency',
//           currency: getCurrencyByLocale(i18n.language),
//         }).format(priceValue)
//       );
//     },
//     [i18n.language]
//   );

//   const handleContentChange = useCallback(
//     (e) => {
//       if (getByteLength(e.target.value) <= 200) {
//         setContent(e.target.value);
//       }
//     },
//     [content]
//   );

//   const contentByteSize = getByteLength(content);

//   const resetFileInput = () => {
//     if (fileInputRef.current) fileInputRef.current.value = '';
//   };

//   const handleImageChange = useCallback(
//     (event) => {
//       const tmpSelectedFiles = Array.from(event.target.files);

//       if (!tmpSelectedFiles.length) {
//         resetFileInput();
//         return;
//       }

//       if (tmpSelectedFiles.length + imageFiles.length > MAX_IMAGE_UPLOAD) {
//         MessageBoxInform(
//           `최대 ${MAX_IMAGE_UPLOAD}개의 이미지를 선택할 수 있습니다.`
//         );
//         resetFileInput();
//         return;
//       }

//       let totalSize = 0;
//       tmpSelectedFiles.forEach((file) => {
//         totalSize += file.size;
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           setPreviews((prevPreviews) => [...prevPreviews, reader.result]);
//           setTotalFileSize(totalSize);
//           setImageFiles((prevImages) => [...prevImages, file]);
//         };
//         reader.onerror = () => {
//           MessageBoxInform('파일을 읽는 도중 오류가 발생했습니다.');
//         };
//         reader.readAsDataURL(file);
//       });
//     },
//     [imageFiles]
//   );

//   const handleRemoveImage = useCallback((indexToRemove) => {
//     setImageFiles((prevImages) =>
//       prevImages.filter((_, index) => index !== indexToRemove)
//     );
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   }, []);

//   const uploadImageFiles = async (productId, width, height, prefix) => {
//     let paths = [];

//     for (const file of imageFiles) {
//       const resizedFile = await resizeImage(file, width, height);
//       const filePath = `${
//         process.env.REACT_APP_USER_IMAGE_PATH
//       }/${productId}/${prefix}${Date.now()}_${file.name}`;
//       const uploadUrl = await uploadImageFile(productId, resizedFile, filePath);
//       paths.push(uploadUrl);
//     }
//     return paths;
//   };

//   const uploadImageFile = async (productId, file, filePath) => {
//     const fileRef = ref(storage, filePath);
//     const metadata = { customMetadata: { productId } };
//     const uploadTask = uploadBytesResumable(fileRef, file, metadata);

//     return new Promise((resolve, reject) => {
//       uploadTask.on(
//         'state_changed',
//         (snapshot) => {
//           const progress =
//             (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//           updateProgress(file.name, progress, snapshot.totalBytes);
//         },
//         reject,
//         () => {
//           setUploadedFileSize((prev) => prev + file.size);
//           getDownloadURL(uploadTask.snapshot.ref).then(resolve, reject);
//         }
//       );
//     });
//   };

//   const updateProgress = (fileName, progress, fileSize) => {
//     setUploadProgress((prevProgress) => ({
//       ...prevProgress,
//       [fileName]: { progress, fileSize },
//     }));
//   };

//   const handleUpload = useCallback(async () => {
//     const showAlertIfEmpty = (value, message) => {
//       if (!value.trim()) {
//         MessageBoxInform(message);
//         throw new Error(message);
//       }
//     };

//     try {
//       showAlertIfEmpty(title, t('enterTitle'));
//       showAlertIfEmpty(content, t('enterContent'));

//       if (!selectedCategory || selectedCategory.length === 0) {
//         MessageBoxInform(t('selectCategory'));
//         throw new Error(t('selectCategory'));
//       }

//       if (!selectedBrand || selectedBrand.length === 0) {
//         MessageBoxInform(t('selectBrand'));
//         throw new Error(t('selectBrand'));
//       }

//       if (!selectedArea || !selectedArea.center || !selectedArea.coordinates) {
//         MessageBoxInform(t('selectArea'));
//         throw new Error(t('selectArea'));
//       }

//       if (!imageFiles.length) {
//         MessageBoxInform(t('uploadImages'));
//         throw new Error(t('uploadImages'));
//       }

//       const isConfirmed = await MessageBoxConfirm(
//         t('confirmUpload'),
//         `${t('title')}: ${title}\n${t('description')}: ${content}\n${t(
//           'price'
//         )}: ${formattedPrice}`
//       );
//       if (!isConfirmed) return;

//       setUploadingFiles(imageFiles);

//       const productRef = await addDoc(collection(db, 'products'), {
//         title,
//         price,
//         content,
//         categoryId: selectedCategory?.id || null,
//         brandId: selectedBrand?.id || null,
//         areaId: selectedArea?.id || null,
//         uid: currentUser.uid,
//         createDate: new Date(),
//         likes: 0,
//         views: 0,
//         chats: 0,
//         state: 0,
//       });

//       const imageSizes = [
//         {
//           width: 1920,
//           height: 1080,
//           collectionName: 'xlarge_images',
//           prefix: 'xlarge_',
//         },
//         {
//           width: 1366,
//           height: 768,
//           collectionName: 'large_images',
//           prefix: 'large_',
//         },
//         {
//           width: 854,
//           height: 480,
//           collectionName: 'medium_images',
//           prefix: 'medium_',
//         },
//         {
//           width: 640,
//           height: 360,
//           collectionName: 'small_images',
//           prefix: 'small_',
//         },
//         {
//           width: 426,
//           height: 240,
//           collectionName: 'xsmall_images',
//           prefix: 'xsmall_',
//         },
//         {
//           width: 213,
//           height: 120,
//           collectionName: 'icon_images',
//           prefix: 'icon_',
//         },
//       ];

//       for (const imageSize of imageSizes) {
//         const fileUrls = await uploadImageFiles(
//           productRef.id,
//           imageSize.width,
//           imageSize.height,
//           imageSize.prefix
//         );
//         const imageUpdates = {};
//         imageUpdates[imageSize.collectionName] = fileUrls;
//         await updateDoc(productRef, imageUpdates);
//       }

//       await MessageBoxInform(t('productUploaded'));
//       navigate('/');
//     } catch (error) {
//       console.error('Error:', error);
//     } finally {
//       setUploadingFiles([]);
//       setImageFiles([]);
//       setUploadProgress(0);
//       setTotalFileSize(0);
//       setUploadedFileSize(0);
//       setPreviews([]);
//       resetFileInput();
//     }
//   }, [
//     title,
//     content,
//     imageFiles,
//     selectedArea,
//     currentUser,
//     i18n.language,
//     navigate,
//   ]);

//   const handleCancel = () => {
//     navigate(-1);
//   };

//   const onSelectCategory = async (selectCategory) => {
//     setSelectedCategory(selectCategory);
//   };

//   const onSelectBrand = async (selectBrand) => {
//     setSelectedBrand(selectBrand);
//   };

//   const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);
//   const [areaSelectionModalOpen, setAreaSelectionModalOpen] = useState(false);

//   const handleImageUploadModalOpen = () => setImageUploadModalOpen(true);
//   const handleImageUploadModalClose = () => setImageUploadModalOpen(false);

//   const handleAreaSelectionModalOpen = () => setAreaSelectionModalOpen(true);
//   const handleAreaSelectionModalClose = () => setAreaSelectionModalOpen(false);

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3',
//           borderRadius: '16px',
//           padding: '8px 16px',
//           marginBottom: '16px',
//           backgroundColor: 'transparent',
//           transition: 'transform 0.2s',
//           '&:hover': {
//             transform: 'scale(1.02)',
//           },
//         }}
//       >
//         <Typography variant="h4" gutterBottom>
//           {t('uploadUsedProduct')}
//         </Typography>
//       </Box>
//       <Stack spacing={2} component="form" noValidate autoComplete="off">
//         <TextField
//           fullWidth
//           label={t('title')}
//           value={title}
//           onChange={(e) => setTitle(e.target.value)}
//         />
//         <TextField
//           fullWidth
//           label={t('description')}
//           value={content}
//           multiline
//           rows={4}
//           onChange={handleContentChange}
//         />
//         <TextField
//           fullWidth
//           label={t('price')}
//           value={price}
//           onChange={handlePriceChange}
//           placeholder="Price (e.g. 1,000)"
//           type="text"
//         />
//         <Typography
//           variant="body2"
//           className="korean-price-label"
//           sx={{ mt: 1 }}
//         >
//           {formattedPrice}
//         </Typography>

//         <Grid container spacing={0}>
//           <Typography variant="h6" gutterBottom>
//             {t('setCategory')}
//           </Typography>
//           <Grid item xs={12}>
//             <ProductCategorySelector onSelectCategory={onSelectCategory} />
//           </Grid>
//           <Typography variant="h6" gutterBottom>
//             {t('setBrand')}
//           </Typography>
//           <Grid item xs={12}>
//             <ProductBrandSelector onSelectBrand={onSelectBrand} />
//           </Grid>
//         </Grid>
//         <Typography variant="h6" gutterBottom>
//           {t('uploadImage')}
//           <Tooltip title={t('imageUploadExplanation')}>
//             <IconButton onClick={handleImageUploadModalOpen}>
//               <InfoIcon />
//             </IconButton>
//           </Tooltip>
//         </Typography>
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
//           <Button variant="outlined" component="label">
//             {t('chooseFiles')}
//             <input type="file" hidden multiple onChange={handleImageChange} />
//           </Button>
//         </Box>
//         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
//           {previews.map((src, index) => (
//             <Box
//               key={index}
//               sx={{ position: 'relative', width: 100, height: 100 }}
//             >
//               <img
//                 src={src}
//                 alt={`Preview ${index}`}
//                 style={{ width: '100%', height: '100%', borderRadius: '8px' }}
//               />
//               <IconButton
//                 onClick={() => handleRemoveImage(index)}
//                 sx={{
//                   position: 'absolute',
//                   top: 0,
//                   right: 0,
//                   color: 'error.main',
//                 }}
//               >
//                 <CloseIcon />
//               </IconButton>
//             </Box>
//           ))}
//         </Box>

//         {uploadingFiles.length > 0 && (
//           <Box
//             sx={{
//               position: 'fixed',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               zIndex: 1300,
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//             }}
//           >
//             <CircularProgress
//               variant="determinate"
//               value={(uploadedFileSize / totalFileSize) * 100}
//               size={100}
//               thickness={5}
//               sx={{ color: 'primary.main' }}
//             />
//             <Box
//               sx={{
//                 top: 0,
//                 left: 0,
//                 bottom: 0,
//                 right: 0,
//                 position: 'absolute',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <Typography
//                 variant="caption"
//                 component="div"
//                 color="text.secondary"
//               >{`${Math.round(
//                 (uploadedFileSize / totalFileSize) * 100
//               )}%`}</Typography>
//             </Box>
//           </Box>
//         )}
//         {MessageBoxRender()}

//         <Typography variant="h6" sx={{ mt: 2 }}>
//           {t('setDeliveryArea')}
//           <Tooltip title={t('areaSelectionExplanation')}>
//             <IconButton onClick={handleAreaSelectionModalOpen}>
//               <InfoIcon />
//             </IconButton>
//           </Tooltip>
//         </Typography>
//         <GoogleMapRegion onSave={handleSaveArea} />
//       </Stack>
//       <Box
//         sx={{
//           position: 'fixed',
//           bottom: isMobile ? 50 : isPad ? 30 : 0,
//           left: 0,
//           right: 0,
//           bgcolor: 'background.paper',
//           boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
//           py: 2,
//           px: 3,
//           display: 'flex',
//           justifyContent: 'space-between',
//         }}
//       >
//         <Button variant="outlined" onClick={handleCancel} fullWidth>
//           {t('cancel')}
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleUpload}
//           fullWidth
//         >
//           {t('upload')}
//         </Button>
//       </Box>

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//       >
//         <Alert severity="info">{snackbarMessage}</Alert>
//       </Snackbar>

//       <Modal
//         open={imageUploadModalOpen}
//         onClose={handleImageUploadModalClose}
//         aria-labelledby="image-upload-modal-title"
//         aria-describedby="image-upload-modal-description"
//       >
//         <Box sx={modalStyle}>
//           <Typography id="image-upload-modal-title" variant="h6" component="h2">
//             {t('imageUploadTitle')}
//           </Typography>
//           <Typography id="image-upload-modal-description" sx={{ mt: 2 }}>
//             {t('imageUploadExplanation')}
//           </Typography>
//         </Box>
//       </Modal>

//       <Modal
//         open={areaSelectionModalOpen}
//         onClose={handleAreaSelectionModalClose}
//         aria-labelledby="area-selection-modal-title"
//         aria-describedby="area-selection-modal-description"
//       >
//         <Box sx={modalStyle}>
//           <Typography
//             id="area-selection-modal-title"
//             variant="h6"
//             component="h2"
//           >
//             {t('areaSelectionTitle')}
//           </Typography>
//           <Typography id="area-selection-modal-description" sx={{ mt: 2 }}>
//             {t('areaSelectionExplanation')}
//           </Typography>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default Upload;

// import React, { useRef, useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   storage,
//   collection,
//   doc,
//   addDoc,
//   updateDoc,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from './firebaseConfig';

// import {
//   Box,
//   Container,
//   Button,
//   Stack,
//   TextField,
//   Typography,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   IconButton,
//   Grid,
//   Tooltip,
//   Modal,
//   LinearProgress,
//   List,
//   ListItem,
//   ListItemText,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import { styled } from '@mui/system';

// import GoogleMapRegion from './Common/GoogleMapRegion';
// import { useNavigate } from 'react-router-dom';
// import { getByteLength } from './Utils/PriceFormatter';
// import './css/upload.css';
// import useMessageBox from './Common/useMessageBox';
// import ProductCategorySelector from './Stores/ProductCategorySelector';
// import ProductBrandSelector from './Stores/ProductBrandSelector';
// import { resizeImage } from './Utils/ImageHelper';
// import PreviewImage from './Common/PreviewImage';
// import CloseIcon from '@mui/icons-material/Close';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
// import InfoIcon from '@mui/icons-material/Info';

// const Input = styled('input')({
//   display: 'none',
// });

// const MAX_IMAGE_UPLOAD = process.env.REACT_APP_SEND_IMAGE_MAX_LEN || 5;

// const modalStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

// const Upload = () => {
//   const { t, i18n } = useTranslation();
//   const fileInputRef = useRef(null);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();

//   const [title, setTitle] = useState('');
//   const [price, setPrice] = useState('0');
//   const [content, setContent] = useState('');
//   const [imageFiles, setImageFiles] = useState([]);
//   const [previews, setPreviews] = useState([]);
//   const [formattedPrice, setFormattedPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState([]);
//   const [isCompleted, setIsCompleted] = useState(false);

//   const [uploadProgress, setUploadProgress] = useState({});
//   const [totalFileSize, setTotalFileSize] = useState(0);
//   const [uploadedFileSize, setUploadedFileSize] = useState(0);
//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [uploadingFiles, setUploadingFiles] = useState([]);

//   const theme = useTheme();
//   // Define media queries for mobile and tablet
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const isPad = useMediaQuery(theme.breakpoints.between('sm', 'md'));

//   const handleSnackbarClose = () => setSnackbarOpen(false);

//   useEffect(() => {
//     imageFiles.forEach((file) => {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setPreviews((prev) => [...prev, reader.result]);
//       };
//       reader.readAsDataURL(file);
//     });

//     return () => {
//       setPreviews([]);
//     };
//   }, [imageFiles]);

//   const handleImagesSelected = (files) => {
//     setImageFiles(files);
//   };

//   const handleImageRemoved = (index) => {
//     setImageFiles((curr) => curr.filter((_, idx) => idx !== index));
//     setPreviews((curr) => curr.filter((_, idx) => idx !== index));
//   };

//   const handleSaveArea = (savedArea) => {
//     setSelectedArea(savedArea); // savedArea is now an object with center and coordinates
//     console.log('Updated selectedArea:', savedArea);
//     setIsCompleted(true);
//   };

//   useEffect(() => {
//     setFormattedPrice(
//       new Intl.NumberFormat(i18n.language, {
//         style: 'currency',
//         currency: getCurrencyByLocale(i18n.language),
//       }).format(price)
//     );
//   }, [price, i18n.language]);

//   useEffect(() => {
//     return () => {
//       resetFileInput();
//     };
//   }, []);

//   const getCurrencyByLocale = (locale) => {
//     switch (locale) {
//       case 'ko':
//         return 'KRW';
//       case 'en':
//         return 'USD';
//       case 'jp':
//         return 'JPY';
//       case 'zh':
//         return 'CNY';
//       default:
//         return 'USD';
//     }
//   };

//   const handlePriceChange = useCallback(
//     (e) => {
//       let priceValue = e.target.value.replace(/[^0-9]/g, '');
//       if (priceValue === '0' || priceValue === '') {
//         priceValue = '0';
//       } else {
//         while (priceValue.startsWith('0')) {
//           priceValue = priceValue.substring(1);
//         }
//       }
//       setPrice(priceValue);

//       setFormattedPrice(
//         new Intl.NumberFormat(i18n.language, {
//           style: 'currency',
//           currency: getCurrencyByLocale(i18n.language),
//         }).format(priceValue)
//       );
//     },
//     [i18n.language]
//   );

//   const handleContentChange = useCallback(
//     (e) => {
//       if (getByteLength(e.target.value) <= 200) {
//         setContent(e.target.value);
//       }
//     },
//     [content]
//   );

//   const contentByteSize = getByteLength(content);

//   const resetFileInput = () => {
//     if (fileInputRef.current) fileInputRef.current.value = '';
//   };

//   const handleImageChange = useCallback(
//     async (event) => {
//       const tmpSelectedFiles = Array.from(event.target.files);

//       if (!tmpSelectedFiles.length) {
//         resetFileInput();
//         return;
//       }

//       if (tmpSelectedFiles.length + imageFiles.length > MAX_IMAGE_UPLOAD) {
//         await MessageBoxInform(
//           `최대 ${MAX_IMAGE_UPLOAD}개의 이미지를 선택할 수 있습니다.`
//         );
//         resetFileInput();
//         return;
//       }

//       let totalSize = 0;
//       tmpSelectedFiles.forEach((file) => {
//         totalSize += file.size;
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           setPreviews((prevPreviews) => [...prevPreviews, reader.result]);
//           setTotalFileSize(totalSize);
//           setImageFiles((prevImages) => [...prevImages, file]);
//         };
//         reader.onerror = async () => {
//           await MessageBoxInform('파일을 읽는 도중 오류가 발생했습니다.');
//         };
//         reader.readAsDataURL(file);
//       });
//     },
//     [imageFiles]
//   );

//   const handleRemoveImage = useCallback((indexToRemove) => {
//     setImageFiles((prevImages) =>
//       prevImages.filter((_, index) => index !== indexToRemove)
//     );
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   }, []);

//   const uploadImageFiles = async (productId, width, height, prefix) => {
//     let paths = [];

//     for (const file of imageFiles) {
//       const resizedFile = await resizeImage(file, width, height);
//       const filePath = `${
//         process.env.REACT_APP_USER_IMAGE_PATH
//       }/${productId}/${prefix}${Date.now()}_${file.name}`;
//       const uploadUrl = await uploadImageFile(productId, resizedFile, filePath);
//       paths.push(uploadUrl);
//     }
//     return paths;
//   };

//   const uploadImageFile = async (productId, file, filePath) => {
//     const fileRef = ref(storage, filePath);
//     const metadata = { customMetadata: { productId } };
//     const uploadTask = uploadBytesResumable(fileRef, file, metadata);

//     return new Promise((resolve, reject) => {
//       uploadTask.on(
//         'state_changed',
//         (snapshot) => {
//           const progress =
//             (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//           updateProgress(file.name, progress, snapshot.totalBytes);
//         },
//         reject,
//         () => {
//           setUploadedFileSize((prev) => prev + file.size);
//           getDownloadURL(uploadTask.snapshot.ref).then(resolve, reject);
//         }
//       );
//     });
//   };

//   const updateProgress = (fileName, progress, fileSize) => {
//     setUploadProgress((prevProgress) => ({
//       ...prevProgress,
//       [fileName]: { progress, fileSize },
//     }));
//   };

//   const isLoggedIn = async () => {
//     if (!currentUser) {
//       const isConfirmed = await MessageBoxConfirm(
//         t('login-required'),
//         t('goto-login')
//       );
//       if (isConfirmed) {
//         navigate('/login');
//       }
//       return false;
//     }
//     return true;
//   };

//   const handleUpload = useCallback(async () => {
//     if (!(await isLoggedIn())) return;
//     const showAlertIfEmpty = async (value, message) => {
//       if (!value.trim()) {
//         await MessageBoxInform(message);
//         throw new Error(message);
//       }
//     };

//     try {
//       showAlertIfEmpty(title, t('enterTitle'));
//       showAlertIfEmpty(content, t('enterContent'));

//       if (!selectedCategory || selectedCategory.length === 0) {
//         await MessageBoxInform(t('selectCategory'));
//         throw new Error(t('selectCategory'));
//       }

//       if (!selectedBrand || selectedBrand.length === 0) {
//         MessageBoxInform(t('selectBrand'));
//         throw new Error(t('selectBrand'));
//       }

//       // if (!selectedArea || !selectedArea.center || !selectedArea.coordinates) {
//       //   MessageBoxInform(t('selectArea'));
//       //   throw new Error(t('selectArea'));
//       // }

//       if (!imageFiles.length) {
//         MessageBoxInform(t('uploadImages'));
//         throw new Error(t('uploadImages'));
//       }

//       const isConfirmed = await MessageBoxConfirm(
//         t('confirmUpload'),
//         `${t('title')}: ${title}\n${t('description')}: ${content}\n${t(
//           'price'
//         )}: ${formattedPrice}`
//       );
//       if (!isConfirmed) return;

//       setUploadingFiles(imageFiles);

//       const productRef = await addDoc(collection(db, 'products'), {
//         title,
//         price,
//         content,
//         categoryId: selectedCategory?.id || null,
//         brandId: selectedBrand?.id || null,
//         areaId: selectedArea?.id || null,
//         uid: currentUser.uid,
//         createDate: new Date(),
//         likes: 0,
//         views: 0,
//         chats: 0,
//         state: 0,
//       });

//       const imageSizes = [
//         {
//           width: 1920,
//           height: 1080,
//           collectionName: 'xlarge_images',
//           prefix: 'xlarge_',
//         },
//         // {
//         //   width: 1366,
//         //   height: 768,
//         //   collectionName: 'large_images',
//         //   prefix: 'large_',
//         // },
//         // {
//         //   width: 854,
//         //   height: 480,
//         //   collectionName: 'medium_images',
//         //   prefix: 'medium_',
//         // },
//         // {
//         //   width: 640,
//         //   height: 360,
//         //   collectionName: 'small_images',
//         //   prefix: 'small_',
//         // },
//         // {
//         //   width: 426,
//         //   height: 240,
//         //   collectionName: 'xsmall_images',
//         //   prefix: 'xsmall_',
//         // },
//         {
//           width: 213,
//           height: 120,
//           collectionName: 'icon_images',
//           prefix: 'icon_',
//         },
//       ];

//       for (const imageSize of imageSizes) {
//         const fileUrls = await uploadImageFiles(
//           productRef.id,
//           imageSize.width,
//           imageSize.height,
//           imageSize.prefix
//         );
//         const imageUpdates = {};
//         imageUpdates[imageSize.collectionName] = fileUrls;
//         await updateDoc(productRef, imageUpdates);
//       }

//       await MessageBoxInform(t('productUploaded'));
//       navigate('/');
//     } catch (error) {
//       console.error('Error:', error);
//     } finally {
//       setUploadingFiles([]);
//       setImageFiles([]);
//       setUploadProgress({});
//       setTotalFileSize(0);
//       setUploadedFileSize(0);
//       setPreviews([]);
//       resetFileInput();
//     }
//   }, [
//     title,
//     content,
//     imageFiles,
//     // selectedArea,
//     currentUser,
//     i18n.language,
//     navigate,
//   ]);

//   const handleCancel = () => {
//     navigate(-1);
//   };

//   const onSelectCategory = async (selectCategory) => {
//     setSelectedCategory(selectCategory);
//   };

//   const onSelectBrand = async (selectBrand) => {
//     setSelectedBrand(selectBrand);
//   };

//   const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);
//   const [areaSelectionModalOpen, setAreaSelectionModalOpen] = useState(false);

//   const handleImageUploadModalOpen = () => setImageUploadModalOpen(true);
//   const handleImageUploadModalClose = () => setImageUploadModalOpen(false);

//   const handleAreaSelectionModalOpen = () => setAreaSelectionModalOpen(true);
//   const handleAreaSelectionModalClose = () => setAreaSelectionModalOpen(false);

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3',
//           borderRadius: '16px',
//           padding: '8px 16px',
//           marginBottom: '16px',
//           backgroundColor: 'transparent',
//           transition: 'transform 0.2s',
//           '&:hover': {
//             transform: 'scale(1.02)',
//           },
//         }}
//       >
//         <Typography variant="h4" gutterBottom>
//           {t('uploadUsedProduct')}
//         </Typography>
//       </Box>
//       <Stack spacing={2} component="form" noValidate autoComplete="off">
//         <TextField
//           fullWidth
//           label={t('title')}
//           value={title}
//           onChange={(e) => setTitle(e.target.value)}
//         />
//         <TextField
//           fullWidth
//           label={t('description')}
//           value={content}
//           multiline
//           rows={4}
//           onChange={handleContentChange}
//         />
//         <TextField
//           fullWidth
//           label={t('price')}
//           value={price}
//           onChange={handlePriceChange}
//           placeholder="Price (e.g. 1,000)"
//           type="text"
//         />
//         <Typography
//           variant="body2"
//           className="korean-price-label"
//           sx={{ mt: 1 }}
//         >
//           {formattedPrice}
//         </Typography>

//         <Grid container spacing={0}>
//           <Typography variant="h6" gutterBottom>
//             {t('setCategory')}
//           </Typography>
//           <Grid item xs={12}>
//             <ProductCategorySelector onSelectCategory={onSelectCategory} />
//           </Grid>
//           <Typography variant="h6" gutterBottom>
//             {t('setBrand')}
//           </Typography>
//           <Grid item xs={12}>
//             <ProductBrandSelector onSelectBrand={onSelectBrand} />
//           </Grid>
//         </Grid>
//         <Typography variant="h6" gutterBottom>
//           {t('uploadImage')}
//           <Tooltip title={t('imageUploadExplanation')}>
//             <IconButton onClick={handleImageUploadModalOpen}>
//               <InfoIcon />
//             </IconButton>
//           </Tooltip>
//         </Typography>
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
//           <Button variant="outlined" component="label">
//             {t('chooseFiles')}
//             <input type="file" hidden multiple onChange={handleImageChange} />
//           </Button>
//         </Box>
//         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
//           {previews.map((src, index) => (
//             <Box
//               key={index}
//               sx={{ position: 'relative', width: 100, height: 100 }}
//             >
//               <img
//                 src={src}
//                 alt={`Preview ${index}`}
//                 style={{ width: '100%', height: '100%', borderRadius: '8px' }}
//               />
//               <IconButton
//                 onClick={() => handleRemoveImage(index)}
//                 sx={{
//                   position: 'absolute',
//                   top: 0,
//                   right: 0,
//                   color: 'error.main',
//                 }}
//               >
//                 <CloseIcon />
//               </IconButton>
//             </Box>
//           ))}
//         </Box>

//         {uploadingFiles.length > 0 && (
//           <Box
//             sx={{
//               position: 'fixed',
//               top: 0,
//               left: 0,
//               right: 0,
//               bottom: 0,
//               backgroundColor: 'rgba(0,0,0,0.5)',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               zIndex: 1300,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '80%',
//                 maxWidth: '400px',
//                 backgroundColor: '#fff',
//                 padding: '20px',
//                 borderRadius: '8px',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 gap: 2,
//               }}
//             >
//               <Typography variant="h6">{t('uploadProgress')}</Typography>
//               <List>
//                 {uploadingFiles.map(({ name }, index) => (
//                   <ListItem key={index}>
//                     <ListItemText
//                       primary={name}
//                       secondary={`${Math.round(
//                         uploadProgress[name]?.progress || 0
//                       )}%`}
//                     />
//                     <LinearProgress
//                       variant="determinate"
//                       value={uploadProgress[name]?.progress || 0}
//                       sx={{ width: '100px', marginLeft: '10px' }}
//                     />
//                   </ListItem>
//                 ))}
//               </List>
//               <Button
//                 variant="contained"
//                 onClick={() => setUploadingFiles([])}
//                 disabled={uploadingFiles.some(
//                   ({ name }) => (uploadProgress[name]?.progress || 0) < 100
//                 )}
//               >
//                 {t('close')}
//               </Button>
//             </Box>
//           </Box>
//         )}
//         {MessageBoxRender()}

//         {/* 배송가능지역 설정을 하지 않기로 함
//         <Typography variant="h6" sx={{ mt: 2 }}>
//           {t('setDeliveryArea')}
//           <Tooltip title={t('areaSelectionExplanation')}>
//             <IconButton onClick={handleAreaSelectionModalOpen}>
//               <InfoIcon />
//             </IconButton>
//           </Tooltip>
//         </Typography>
//         <GoogleMapRegion onSave={handleSaveArea} /> */}
//       </Stack>
//       <Box
//         sx={{
//           position: 'fixed',
//           bottom: isMobile ? 50 : isPad ? 30 : 0,
//           left: 0,
//           right: 0,
//           bgcolor: 'background.paper',
//           boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
//           py: 2,
//           px: 3,
//           display: 'flex',
//           justifyContent: 'space-between',
//         }}
//       >
//         <Button variant="outlined" onClick={handleCancel} fullWidth>
//           {t('cancel')}
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleUpload}
//           fullWidth
//         >
//           {t('upload')}
//         </Button>
//       </Box>

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//       >
//         <Alert severity="info">{snackbarMessage}</Alert>
//       </Snackbar>

//       <Modal
//         open={imageUploadModalOpen}
//         onClose={handleImageUploadModalClose}
//         aria-labelledby="image-upload-modal-title"
//         aria-describedby="image-upload-modal-description"
//       >
//         <Box sx={modalStyle}>
//           <Typography id="image-upload-modal-title" variant="h6" component="h2">
//             {t('imageUploadTitle')}
//           </Typography>
//           <Typography id="image-upload-modal-description" sx={{ mt: 2 }}>
//             {t('imageUploadExplanation')}
//           </Typography>
//         </Box>
//       </Modal>

//       <Modal
//         open={areaSelectionModalOpen}
//         onClose={handleAreaSelectionModalClose}
//         aria-labelledby="area-selection-modal-title"
//         aria-describedby="area-selection-modal-description"
//       >
//         <Box sx={modalStyle}>
//           <Typography
//             id="area-selection-modal-title"
//             variant="h6"
//             component="h2"
//           >
//             {t('areaSelectionTitle')}
//           </Typography>
//           <Typography id="area-selection-modal-description" sx={{ mt: 2 }}>
//             {t('areaSelectionExplanation')}
//           </Typography>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default Upload;

// import React, { useRef, useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   db,
//   storage,
//   collection,
//   doc,
//   addDoc,
//   updateDoc,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
//   getDocs,
// } from './firebaseConfig';

// import {
//   Box,
//   Container,
//   Button,
//   Stack,
//   TextField,
//   Typography,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   IconButton,
//   Grid,
//   Tooltip,
//   Modal,
//   LinearProgress,
//   List,
//   ListItem,
//   ListItemText,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   FormControlLabel,
//   Checkbox,
//   FormGroup,
// } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import { styled } from '@mui/system';

// import GoogleMapRegion from './Common/GoogleMapRegion';
// import { useNavigate } from 'react-router-dom';
// import { getByteLength } from './Utils/PriceFormatter';
// import './css/upload.css';
// import useMessageBox from './Common/useMessageBox';
// import ProductCategorySelector from './Stores/ProductCategorySelector';
// import ProductBrandSelector from './Stores/ProductBrandSelector';
// import { resizeImage } from './Utils/ImageHelper';
// import PreviewImage from './Common/PreviewImage';
// import CloseIcon from '@mui/icons-material/Close';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
// import InfoIcon from '@mui/icons-material/Info';

// const Input = styled('input')({
//   display: 'none',
// });

// const MAX_IMAGE_UPLOAD = process.env.REACT_APP_SEND_IMAGE_MAX_LEN || 5;

// const modalStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

// const Upload = () => {
//   const { t, i18n } = useTranslation();
//   const fileInputRef = useRef(null);
//   const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
//     useMessageBox();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();

//   const [title, setTitle] = useState('');
//   const [price, setPrice] = useState('0');
//   const [content, setContent] = useState('');
//   const [imageFiles, setImageFiles] = useState([]);
//   const [previews, setPreviews] = useState([]);
//   const [formattedPrice, setFormattedPrice] = useState('0');
//   const [showMap, setShowMap] = useState(false);
//   const [selectedArea, setSelectedArea] = useState([]);
//   const [isCompleted, setIsCompleted] = useState(false);

//   const [uploadProgress, setUploadProgress] = useState({});
//   const [totalFileSize, setTotalFileSize] = useState(0);
//   const [uploadedFileSize, setUploadedFileSize] = useState(0);
//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [uploadingFiles, setUploadingFiles] = useState([]);

//   const [weatherEvents, setWeatherEvents] = useState([]);
//   const [selectedWeatherEvent, setSelectedWeatherEvent] = useState('');

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const isPad = useMediaQuery(theme.breakpoints.between('sm', 'md'));

//   const handleSnackbarClose = () => setSnackbarOpen(false);

//   useEffect(() => {
//     const fetchEvents = async () => {
//       const eventCollection = collection(db, 'weatherEvents');
//       const eventSnapshot = await getDocs(eventCollection);
//       const eventList = eventSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setWeatherEvents(eventList);
//     };

//     fetchEvents();
//   }, []);

//   useEffect(() => {
//     imageFiles.forEach((file) => {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setPreviews((prev) => [...prev, reader.result]);
//       };
//       reader.readAsDataURL(file);
//     });

//     return () => {
//       setPreviews([]);
//     };
//   }, [imageFiles]);

//   const handleImagesSelected = (files) => {
//     setImageFiles(files);
//   };

//   const handleImageRemoved = (index) => {
//     setImageFiles((curr) => curr.filter((_, idx) => idx !== index));
//     setPreviews((curr) => curr.filter((_, idx) => idx !== index));
//   };

//   const handleSaveArea = (savedArea) => {
//     setSelectedArea(savedArea);
//     console.log('Updated selectedArea:', savedArea);
//     setIsCompleted(true);
//   };

//   useEffect(() => {
//     setFormattedPrice(
//       new Intl.NumberFormat(i18n.language, {
//         style: 'currency',
//         currency: getCurrencyByLocale(i18n.language),
//       }).format(price)
//     );
//   }, [price, i18n.language]);

//   useEffect(() => {
//     return () => {
//       resetFileInput();
//     };
//   }, []);

//   const getCurrencyByLocale = (locale) => {
//     switch (locale) {
//       case 'ko':
//         return 'KRW';
//       case 'en':
//         return 'USD';
//       case 'jp':
//         return 'JPY';
//       case 'zh':
//         return 'CNY';
//       default:
//         return 'USD';
//     }
//   };

//   const handlePriceChange = useCallback(
//     (e) => {
//       let priceValue = e.target.value.replace(/[^0-9]/g, '');
//       if (priceValue === '0' || priceValue === '') {
//         priceValue = '0';
//       } else {
//         while (priceValue.startsWith('0')) {
//           priceValue = priceValue.substring(1);
//         }
//       }
//       setPrice(priceValue);

//       setFormattedPrice(
//         new Intl.NumberFormat(i18n.language, {
//           style: 'currency',
//           currency: getCurrencyByLocale(i18n.language),
//         }).format(priceValue)
//       );
//     },
//     [i18n.language]
//   );

//   const handleContentChange = useCallback(
//     (e) => {
//       if (getByteLength(e.target.value) <= 200) {
//         setContent(e.target.value);
//       }
//     },
//     [content]
//   );

//   const contentByteSize = getByteLength(content);

//   const resetFileInput = () => {
//     if (fileInputRef.current) fileInputRef.current.value = '';
//   };

//   const handleImageChange = useCallback(
//     async (event) => {
//       const tmpSelectedFiles = Array.from(event.target.files);

//       if (!tmpSelectedFiles.length) {
//         resetFileInput();
//         return;
//       }

//       if (tmpSelectedFiles.length + imageFiles.length > MAX_IMAGE_UPLOAD) {
//         await MessageBoxInform(
//           `최대 ${MAX_IMAGE_UPLOAD}개의 이미지를 선택할 수 있습니다.`
//         );
//         resetFileInput();
//         return;
//       }

//       let totalSize = 0;
//       tmpSelectedFiles.forEach((file) => {
//         totalSize += file.size;
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           setPreviews((prevPreviews) => [...prevPreviews, reader.result]);
//           setTotalFileSize(totalSize);
//           setImageFiles((prevImages) => [...prevImages, file]);
//         };
//         reader.onerror = async () => {
//           await MessageBoxInform('파일을 읽는 도중 오류가 발생했습니다.');
//         };
//         reader.readAsDataURL(file);
//       });
//     },
//     [imageFiles]
//   );

//   const handleRemoveImage = useCallback((indexToRemove) => {
//     setImageFiles((prevImages) =>
//       prevImages.filter((_, index) => index !== indexToRemove)
//     );
//     setPreviews((prevPreviews) =>
//       prevPreviews.filter((_, index) => index !== indexToRemove)
//     );
//   }, []);

//   const uploadImageFiles = async (productId, width, height, prefix) => {
//     let paths = [];

//     for (const file of imageFiles) {
//       const resizedFile = await resizeImage(file, width, height);
//       const filePath = `${
//         process.env.REACT_APP_USER_IMAGE_PATH
//       }/${productId}/${prefix}${Date.now()}_${file.name}`;
//       const uploadUrl = await uploadImageFile(productId, resizedFile, filePath);
//       paths.push(uploadUrl);
//     }
//     return paths;
//   };

//   const uploadImageFile = async (productId, file, filePath) => {
//     const fileRef = ref(storage, filePath);
//     const metadata = { customMetadata: { productId } };
//     const uploadTask = uploadBytesResumable(fileRef, file, metadata);

//     return new Promise((resolve, reject) => {
//       uploadTask.on(
//         'state_changed',
//         (snapshot) => {
//           const progress =
//             (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//           updateProgress(file.name, progress, snapshot.totalBytes);
//         },
//         reject,
//         () => {
//           setUploadedFileSize((prev) => prev + file.size);
//           getDownloadURL(uploadTask.snapshot.ref).then(resolve, reject);
//         }
//       );
//     });
//   };

//   const updateProgress = (fileName, progress, fileSize) => {
//     setUploadProgress((prevProgress) => ({
//       ...prevProgress,
//       [fileName]: { progress, fileSize },
//     }));
//   };

//   const isLoggedIn = async () => {
//     if (!currentUser) {
//       const isConfirmed = await MessageBoxConfirm(
//         t('login-required'),
//         t('goto-login')
//       );
//       if (isConfirmed) {
//         navigate('/login');
//       }
//       return false;
//     }
//     return true;
//   };

//   const handleUpload = useCallback(async () => {
//     if (!(await isLoggedIn())) return;
//     const showAlertIfEmpty = async (value, message) => {
//       if (!value.trim()) {
//         await MessageBoxInform(message);
//         throw new Error(message);
//       }
//     };

//     try {
//       showAlertIfEmpty(title, t('enterTitle'));
//       showAlertIfEmpty(content, t('enterContent'));

//       if (!selectedCategory || selectedCategory.length === 0) {
//         await MessageBoxInform(t('selectCategory'));
//         throw new Error(t('selectCategory'));
//       }

//       if (!selectedBrand || selectedBrand.length === 0) {
//         MessageBoxInform(t('selectBrand'));
//         throw new Error(t('selectBrand'));
//       }

//       if (!imageFiles.length) {
//         MessageBoxInform(t('uploadImages'));
//         throw new Error(t('uploadImages'));
//       }

//       const isConfirmed = await MessageBoxConfirm(
//         t('confirmUpload'),
//         `${t('title')}: ${title}\n${t('description')}: ${content}\n${t(
//           'price'
//         )}: ${formattedPrice}`
//       );
//       if (!isConfirmed) return;

//       setUploadingFiles(imageFiles);

//       const selectedEventData = weatherEvents.find(
//         (event) => event.id === selectedWeatherEvent
//       );

//       const productRef = await addDoc(collection(db, 'products'), {
//         title,
//         price,
//         content,
//         categoryId: selectedCategory?.id || null,
//         brandId: selectedBrand?.id || null,
//         areaId: selectedArea?.id || null,
//         uid: currentUser.uid,
//         createDate: new Date(),
//         likes: 0,
//         views: 0,
//         chats: 0,
//         state: 0,
//         event: selectedEventData
//           ? {
//               id: selectedEventData.id,
//               name: selectedEventData.name,
//               discount: selectedEventData.discount,
//             }
//           : null,
//       });

//       const imageSizes = [
//         {
//           width: 1920,
//           height: 1080,
//           collectionName: 'xlarge_images',
//           prefix: 'xlarge_',
//         },
//         {
//           width: 213,
//           height: 120,
//           collectionName: 'icon_images',
//           prefix: 'icon_',
//         },
//       ];

//       for (const imageSize of imageSizes) {
//         const fileUrls = await uploadImageFiles(
//           productRef.id,
//           imageSize.width,
//           imageSize.height,
//           imageSize.prefix
//         );
//         const imageUpdates = {};
//         imageUpdates[imageSize.collectionName] = fileUrls;
//         await updateDoc(productRef, imageUpdates);
//       }

//       await MessageBoxInform(t('productUploaded'));
//       navigate('/');
//     } catch (error) {
//       console.error('Error:', error);
//     } finally {
//       setUploadingFiles([]);
//       setImageFiles([]);
//       setUploadProgress({});
//       setTotalFileSize(0);
//       setUploadedFileSize(0);
//       setPreviews([]);
//       resetFileInput();
//     }
//   }, [
//     title,
//     content,
//     imageFiles,
//     currentUser,
//     i18n.language,
//     navigate,
//     selectedWeatherEvent,
//     weatherEvents,
//     selectedCategory,
//     selectedBrand,
//   ]);

//   const handleCancel = () => {
//     navigate(-1);
//   };

//   const onSelectCategory = async (selectCategory) => {
//     setSelectedCategory(selectCategory);
//   };

//   const onSelectBrand = async (selectBrand) => {
//     setSelectedBrand(selectBrand);
//   };

//   const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);
//   const [areaSelectionModalOpen, setAreaSelectionModalOpen] = useState(false);

//   const handleImageUploadModalOpen = () => setImageUploadModalOpen(true);
//   const handleImageUploadModalClose = () => setImageUploadModalOpen(false);

//   const handleAreaSelectionModalOpen = () => setAreaSelectionModalOpen(true);
//   const handleAreaSelectionModalClose = () => setAreaSelectionModalOpen(false);

//   return (
//     <Box
//       p={3}
//       sx={{
//         width: '100%',
//         maxWidth: '100%',
//         margin: 'auto',
//         marginBottom: '80px',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'inline-block',
//           border: '0px solid #d3d3d3',
//           borderRadius: '16px',
//           padding: '8px 16px',
//           marginBottom: '16px',
//           backgroundColor: 'transparent',
//           transition: 'transform 0.2s',
//           '&:hover': {
//             transform: 'scale(1.02)',
//           },
//         }}
//       >
//         <Typography variant="h4" gutterBottom>
//           {t('uploadUsedProduct')}
//         </Typography>
//       </Box>
//       <Stack spacing={2} component="form" noValidate autoComplete="off">
//         <TextField
//           fullWidth
//           label={t('title')}
//           value={title}
//           onChange={(e) => setTitle(e.target.value)}
//         />
//         <TextField
//           fullWidth
//           label={t('description')}
//           value={content}
//           multiline
//           rows={4}
//           onChange={handleContentChange}
//         />
//         <TextField
//           fullWidth
//           label={t('price')}
//           value={price}
//           onChange={handlePriceChange}
//           placeholder="Price (e.g. 1,000)"
//           type="text"
//         />
//         <Typography
//           variant="body2"
//           className="korean-price-label"
//           sx={{ mt: 1 }}
//         >
//           {formattedPrice}
//         </Typography>

//         <Grid container spacing={0}>
//           <Typography variant="h6" gutterBottom>
//             {t('setCategory')}
//           </Typography>
//           <Grid item xs={12}>
//             <ProductCategorySelector onSelectCategory={onSelectCategory} />
//           </Grid>
//           <Typography variant="h6" gutterBottom>
//             {t('setBrand')}
//           </Typography>
//           <Grid item xs={12}>
//             <ProductBrandSelector onSelectBrand={onSelectBrand} />
//           </Grid>
//         </Grid>

//         <Typography variant="h6" gutterBottom>
//           {t('uploadImage')}
//           <Tooltip title={t('imageUploadExplanation')}>
//             <IconButton onClick={handleImageUploadModalOpen}>
//               <InfoIcon />
//             </IconButton>
//           </Tooltip>
//         </Typography>
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
//           <Button variant="outlined" component="label">
//             {t('chooseFiles')}
//             <input type="file" hidden multiple onChange={handleImageChange} />
//           </Button>
//         </Box>
//         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
//           {previews.map((src, index) => (
//             <Box
//               key={index}
//               sx={{ position: 'relative', width: 100, height: 100 }}
//             >
//               <img
//                 src={src}
//                 alt={`Preview ${index}`}
//                 style={{ width: '100%', height: '100%', borderRadius: '8px' }}
//               />
//               <IconButton
//                 onClick={() => handleRemoveImage(index)}
//                 sx={{
//                   position: 'absolute',
//                   top: 0,
//                   right: 0,
//                   color: 'error.main',
//                 }}
//               >
//                 <CloseIcon />
//               </IconButton>
//             </Box>
//           ))}
//         </Box>

//         {uploadingFiles.length > 0 && (
//           <Box
//             sx={{
//               position: 'fixed',
//               top: 0,
//               left: 0,
//               right: 0,
//               bottom: 0,
//               backgroundColor: 'rgba(0,0,0,0.5)',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               zIndex: 1300,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '80%',
//                 maxWidth: '400px',
//                 backgroundColor: '#fff',
//                 padding: '20px',
//                 borderRadius: '8px',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 gap: 2,
//               }}
//             >
//               <Typography variant="h6">{t('uploadProgress')}</Typography>
//               <List>
//                 {uploadingFiles.map(({ name }, index) => (
//                   <ListItem key={index}>
//                     <ListItemText
//                       primary={name}
//                       secondary={`${Math.round(
//                         uploadProgress[name]?.progress || 0
//                       )}%`}
//                     />
//                     <LinearProgress
//                       variant="determinate"
//                       value={uploadProgress[name]?.progress || 0}
//                       sx={{ width: '100px', marginLeft: '10px' }}
//                     />
//                   </ListItem>
//                 ))}
//               </List>
//               <Button
//                 variant="contained"
//                 onClick={() => setUploadingFiles([])}
//                 disabled={uploadingFiles.some(
//                   ({ name }) => (uploadProgress[name]?.progress || 0) < 100
//                 )}
//               >
//                 {t('close')}
//               </Button>
//             </Box>
//           </Box>
//         )}
//         {MessageBoxRender()}

//         <Typography variant="h6" gutterBottom>
//           {t('selectEvent')}
//         </Typography>
//         <FormControl fullWidth>
//           <InputLabel>{t('selectEvent')}</InputLabel>
//           <Select
//             value={selectedWeatherEvent}
//             onChange={(e) => setSelectedWeatherEvent(e.target.value)}
//           >
//             {weatherEvents.map((event) => (
//               <MenuItem key={event.id} value={event.id}>
//                 {event.name} - {event.discount}%
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       </Stack>
//       <Box
//         sx={{
//           position: 'fixed',
//           bottom: isMobile ? 50 : isPad ? 30 : 0,
//           left: 0,
//           right: 0,
//           bgcolor: 'background.paper',
//           boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
//           py: 2,
//           px: 3,
//           display: 'flex',
//           justifyContent: 'space-between',
//         }}
//       >
//         <Button variant="outlined" onClick={handleCancel} fullWidth>
//           {t('cancel')}
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleUpload}
//           fullWidth
//         >
//           {t('upload')}
//         </Button>
//       </Box>

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//       >
//         <Alert severity="info">{snackbarMessage}</Alert>
//       </Snackbar>

//       <Modal
//         open={imageUploadModalOpen}
//         onClose={handleImageUploadModalClose}
//         aria-labelledby="image-upload-modal-title"
//         aria-describedby="image-upload-modal-description"
//       >
//         <Box sx={modalStyle}>
//           <Typography id="image-upload-modal-title" variant="h6" component="h2">
//             {t('imageUploadTitle')}
//           </Typography>
//           <Typography id="image-upload-modal-description" sx={{ mt: 2 }}>
//             {t('imageUploadExplanation')}
//           </Typography>
//         </Box>
//       </Modal>

//       <Modal
//         open={areaSelectionModalOpen}
//         onClose={handleAreaSelectionModalClose}
//         aria-labelledby="area-selection-modal-title"
//         aria-describedby="area-selection-modal-description"
//       >
//         <Box sx={modalStyle}>
//           <Typography
//             id="area-selection-modal-title"
//             variant="h6"
//             component="h2"
//           >
//             {t('areaSelectionTitle')}
//           </Typography>
//           <Typography id="area-selection-modal-description" sx={{ mt: 2 }}>
//             {t('areaSelectionExplanation')}
//           </Typography>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default Upload;

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  db,
  storage,
  collection,
  doc,
  addDoc,
  updateDoc,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getDocs,
} from './firebaseConfig';

import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Snackbar,
  Alert,
  IconButton,
  Grid,
  Tooltip,
  Modal,
  Container,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';

import GoogleMapRegion from './Common/GoogleMapRegion';
import { useNavigate } from 'react-router-dom';
import { getByteLength } from './Utils/PriceFormatter';
import './css/upload.css';
import useMessageBox from './Common/useMessageBox';
import ProductCategorySelector from './Stores/ProductCategorySelector';
import ProductBrandSelector from './Stores/ProductBrandSelector';
import { resizeImage } from './Utils/ImageHelper';
import PreviewImage from './Common/PreviewImage';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import { useWeatherEvents } from './Weather/useWeatherEvents';
import WeatherEventSelector from './Weather/WeatherEventSelector';
import FileUpload from './Common/FileUpload';
import { useGlobalContext } from './useGlobalContext';
import { formatPrice, numberToKorean } from './Utils/PriceFormatter';

const Input = styled('input')({
  display: 'none',
});

const MAX_IMAGE_UPLOAD = process.env.REACT_APP_SEND_IMAGE_MAX_LEN || 5;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Upload = () => {
  const { t, i18n } = useTranslation();
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const fileUploadRef = useRef();
  const { imageSizes } = useGlobalContext();
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('0');
  const [koreanPrice, setKoreanPrice] = useState('0');
  const [content, setContent] = useState('');
  const [imageFiles, setImageFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [formattedPrice, setFormattedPrice] = useState('0');
  const [showMap, setShowMap] = useState(false);
  const [selectedArea, setSelectedArea] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);

  const [uploadProgress, setUploadProgress] = useState({});
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [uploadedFileSize, setUploadedFileSize] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedWeatherEvent, setSelectedWeatherEvent] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { weatherEvents } = useWeatherEvents();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPad = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const handleSnackbarClose = () => setSnackbarOpen(false);

  // useEffect(() => {
  //   imageFiles.forEach((file) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setPreviews((prev) => [...prev, reader.result]);
  //     };
  //     reader.readAsDataURL(file);
  //   });

  //   return () => {
  //     setPreviews([]);
  //   };
  // }, [imageFiles]);

  const handleImagesSelected = (files) => {
    setImageFiles(files);
  };

  const handleImageRemoved = (index) => {
    setImageFiles((curr) => curr.filter((_, idx) => idx !== index));
    setPreviews((curr) => curr.filter((_, idx) => idx !== index));
  };

  const handleSaveArea = (savedArea) => {
    setSelectedArea(savedArea);
    console.log('Updated selectedArea:', savedArea);
    setIsCompleted(true);
  };

  useEffect(() => {
    setFormattedPrice(
      new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: getCurrencyByLocale(i18n.language),
      }).format(price)
    );
  }, [price, i18n.language]);

  useEffect(() => {
    return () => {
      resetFileInput();
    };
  }, []);

  const getCurrencyByLocale = (locale) => {
    switch (locale) {
      case 'ko':
        return 'KRW';
      case 'en':
        return 'USD';
      case 'jp':
        return 'JPY';
      case 'zh':
        return 'CNY';
      default:
        return 'USD';
    }
  };

  const handlePriceChange = useCallback(
    (e) => {
      let priceValue = e.target.value.replace(/[^0-9]/g, '');
      if (priceValue === '0' || priceValue === '') {
        priceValue = '0';
      } else {
        while (priceValue.startsWith('0')) {
          priceValue = priceValue.substring(1);
        }
      }
      setPrice(priceValue);

      setFormattedPrice(
        new Intl.NumberFormat(i18n.language, {
          style: 'currency',
          currency: getCurrencyByLocale(i18n.language),
        }).format(priceValue)
      );
    },
    [i18n.language]
  );
  // 가격이 변경될 때 한글로 된 가격도 함께 변경
  useEffect(() => {
    setKoreanPrice(numberToKorean(price));
  }, [price]);
  const handleContentChange = useCallback(
    (e) => {
      if (getByteLength(e.target.value) <= 200) {
        setContent(e.target.value);
      }
    },
    [content]
  );
  const handleImageFiles = async (files) => {
    setImageFiles(files);
  };
  const handleFileUploadComplete = async (uploadedUrls) => {
    setUploading(true);
    try {
      const selectedEventData = weatherEvents.find(
        (event) => event.id === selectedWeatherEvent
      );

      const addProductData = {
        title,
        content,
        price: parseFloat(price),
        categoryId: selectedCategory?.id || null,
        brandId: selectedBrand?.id || null,
        areaId: selectedArea?.id || null,
        uid: currentUser.uid,
        createDate: new Date(),
        updateDate: new Date(),
        likes: 0,
        views: 0,
        chats: 0,
        state: 0,
        weatherEvent: selectedEventData?.id || null,
        eventId: selectedEventData
          ? {
              id: selectedEventData.id,
            }
          : null,
      };
      // 🔹 imageSizes에 있는 컬렉션 이름을 기준으로 자동 매핑
      imageSizes.forEach(({ collectionName }) => {
        addProductData[collectionName] = uploadedUrls?.[collectionName] ?? [];
      });
      const productRef = await addDoc(
        collection(db, 'products'),
        addProductData
      );
      await MessageBoxInform(t('upload.productUploaded'));
      navigate(-1);
    } catch (error) {
      console.error('Error updating product:', error);
      alert('Failed to update product.');
    } finally {
      setUploading(false);
    }
  };
  const contentByteSize = getByteLength(content);

  const resetFileInput = () => {
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  const handleImageChange = useCallback(
    async (event) => {
      const tmpSelectedFiles = Array.from(event.target.files);

      if (!tmpSelectedFiles.length) {
        resetFileInput();
        return;
      }

      if (tmpSelectedFiles.length + imageFiles.length > MAX_IMAGE_UPLOAD) {
        await MessageBoxInform(
          `최대 ${MAX_IMAGE_UPLOAD}개의 이미지를 선택할 수 있습니다.`
        );
        resetFileInput();
        return;
      }

      let totalSize = 0;
      tmpSelectedFiles.forEach((file) => {
        totalSize += file.size;
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviews((prevPreviews) => [...prevPreviews, reader.result]);
          setTotalFileSize(totalSize);
          setImageFiles((prevImages) => [...prevImages, file]);
        };
        reader.onerror = async () => {
          await MessageBoxInform('파일을 읽는 도중 오류가 발생했습니다.');
        };
        reader.readAsDataURL(file);
      });
    },
    [imageFiles]
  );

  const handleRemoveImage = useCallback((indexToRemove) => {
    setImageFiles((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
    setPreviews((prevPreviews) =>
      prevPreviews.filter((_, index) => index !== indexToRemove)
    );
  }, []);

  const isLoggedIn = async () => {
    if (!currentUser) {
      const isConfirmed = await MessageBoxConfirm(
        t('upload.login-required'),
        t('upload.goto-login')
      );
      if (isConfirmed) {
        navigate('/login');
      }
      return false;
    }
    return true;
  };

  const handleUpload = useCallback(async () => {
    if (!(await isLoggedIn())) return;
    const showAlertIfEmpty = async (value, message) => {
      if (!value.trim()) {
        await MessageBoxInform(message);
        throw new Error(message);
      }
    };

    try {
      showAlertIfEmpty(title, t('upload.enterTitle'));
      showAlertIfEmpty(content, t('upload.enterContent'));

      if (!selectedCategory || selectedCategory.length === 0) {
        await MessageBoxInform(t('upload.selectCategory'));
        throw new Error(t('upload.selectCategory'));
      }

      if (!selectedBrand || selectedBrand.length === 0) {
        MessageBoxInform(t('upload.selectBrand'));
        throw new Error(t('upload.selectBrand'));
      }

      if (!imageFiles || imageFiles.length == 0) {
        MessageBoxInform(t('upload.uploadImages'));
        throw new Error(t('upload.uploadImages'));
      }

      const isConfirmed = await MessageBoxConfirm(
        t('upload.confirmUpload'),
        `${t('upload.title')}: ${title}\n${t(
          'upload.description'
        )}: ${content}\n${t('upload.price')}: ${formattedPrice}`
      );
      if (!isConfirmed) return;

      setUploadingFiles(imageFiles);

      if (fileUploadRef.current) {
        fileUploadRef.current.uploadFilesHandler();
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setUploadingFiles([]);
      setImageFiles([]);
      setUploadProgress({});
      setTotalFileSize(0);
      setUploadedFileSize(0);
      setPreviews([]);
      resetFileInput();
    }
  }, [
    title,
    content,
    imageFiles,
    currentUser,
    i18n.language,
    navigate,
    selectedWeatherEvent,
    weatherEvents,
    selectedCategory,
    selectedBrand,
  ]);

  const uploadImagesBySize = async (productId, imageFiles, imageSizes) => {
    try {
      const uploadedUrls = {};

      for (const imageSize of imageSizes) {
        const { width, height, collectionName, prefix } = imageSize;
        uploadedUrls[collectionName] = []; // 각 사이즈별 업로드 경로 저장

        for (const file of imageFiles) {
          const resizedFile = await resizeImage(file, width, height); // 이미지 리사이즈
          const filePath = `uploads/${productId}/${prefix}${Date.now()}_${
            file.name
          }`;
          const storageRef = ref(storage, filePath);
          const metadata = { customMetadata: { productId } };

          // 업로드
          const uploadTask = uploadBytesResumable(
            storageRef,
            resizedFile,
            metadata
          );
          await new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload for ${prefix} is ${progress}% done`);
                updateProgress(file.name, progress, snapshot.totalBytes);
              },
              (error) => {
                console.error('Upload failed:', error);
                reject(error);
              },
              async () => {
                const downloadUrl = await getDownloadURL(
                  uploadTask.snapshot.ref
                );
                uploadedUrls[collectionName].push(downloadUrl); // 각 사이즈별 URL 추가
                resolve();
              }
            );
          });
        }
      }

      console.log('All images uploaded by size:', uploadedUrls);
      return uploadedUrls;
    } catch (error) {
      console.error('Error uploading images by size:', error);
      throw error;
    }
  };

  const uploadImageFiles = async (productId, width, height, prefix) => {
    let paths = [];

    for (const file of imageFiles) {
      const resizedFile = await resizeImage(file, width, height);
      const filePath = `${
        process.env.REACT_APP_USER_IMAGE_PATH
      }/${productId}/${prefix}${Date.now()}_${file.name}`;
      const uploadUrl = await uploadImageFile(productId, resizedFile, filePath);
      paths.push(uploadUrl);
    }
    return paths;
  };

  const uploadImageFile = async (productId, file, filePath) => {
    const fileRef = ref(storage, filePath);
    const metadata = { customMetadata: { productId } };
    const uploadTask = uploadBytesResumable(fileRef, file, metadata);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          updateProgress(file.name, progress, snapshot.totalBytes);
        },
        reject,
        () => {
          setUploadedFileSize((prev) => prev + file.size);
          getDownloadURL(uploadTask.snapshot.ref).then(resolve, reject);
        }
      );
    });
  };

  const updateProgress = (fileName, progress, fileSize) => {
    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      [fileName]: { progress, fileSize },
    }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const onSelectCategory = async (selectCategory) => {
    setSelectedCategory(selectCategory);
  };

  const onSelectBrand = async (selectBrand) => {
    setSelectedBrand(selectBrand);
  };

  const onSelectWeatherEvent = async (selectEvent) => {
    setSelectedWeatherEvent(selectEvent);
  };

  return (
    <Box
      sx={{
        maxWidth: '100%',
        margin: 'auto',
        p: 3,
        bgcolor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          maxWidth: 'lg',
          margin: 'auto',
          p: 9,
          bgcolor: theme.palette.background.paper,
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[3],
          mb: (theme) => `calc(${theme.spacing(8)} + 16px)`,
        }}
      >
        {/* <Box
        sx={{
          display: 'inline-block',
          border: '0px solid #d3d3d3',
          borderRadius: '16px',
          padding: '8px 16px',
          marginBottom: '16px',
          backgroundColor: 'transparent',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('upload.uploadUsedProduct')}
        </Typography>
      </Box> */}

        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            paddingBottom: theme.spacing(2),
            color: theme.palette.primary.main,
          }}
        >
          {t('upload.uploadUsedProduct')}
        </Typography>
        <Stack spacing={2} component="form" noValidate autoComplete="off">
          {/* <TextField
          fullWidth
          label={t('upload.title')}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        /> 
        <TextField
          fullWidth
          label={t('upload.description')}
          value={content}
          multiline
          rows={4}
          onChange={handleContentChange}
        />
        <TextField
          fullWidth
          label={t('upload.price')}
          value={price}
          onChange={handlePriceChange}
          placeholder="Price (e.g. 1,000)"
          type="text"
        />*/}
          <TextField
            fullWidth
            label={t('upload.title')}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
          />

          <TextField
            fullWidth
            label={t('upload.description')}
            value={content}
            multiline
            rows={4}
            onChange={(e) => setContent(e.target.value)}
            variant="outlined"
          />
          <TextField
            fullWidth
            label={t('price')}
            value={formatPrice(price)}
            onChange={handlePriceChange}
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t('currency')}
                </InputAdornment>
              ),
            }}
            helperText={koreanPrice !== '0' ? `${koreanPrice}원` : t('free')}
          />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {/* 카테고리 설정 */}
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 'bold', color: 'text.primary', mb: 1 }}
              >
                {t('upload.setCategory')}
              </Typography>
              <ProductCategorySelector onSelectCategory={onSelectCategory} />
            </Grid>

            {/* 브랜드 설정 */}
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 'bold', color: 'text.primary', mb: 1 }}
              >
                {t('upload.setBrand')}
              </Typography>
              <ProductBrandSelector onSelectBrand={onSelectBrand} />
            </Grid>

            {/* 날씨 이벤트 설정 */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: 'text.primary' }}
                >
                  {t('upload.selectWeatherEvent')}
                </Typography>

                <Tooltip
                  title={
                    <React.Fragment>
                      <Box
                        sx={{
                          mt: 2,
                          p: 2,
                          bgcolor: 'background.paper',
                          borderRadius: 1,
                        }}
                      >
                        {weatherEvents.map((event) => (
                          <Typography
                            key={event.id}
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 1 }}
                          >
                            {event.description}
                            <span
                              style={{
                                color: theme.palette.primary.main,
                                fontWeight: 'bold',
                              }}
                            >
                              ({event.discount}%)
                            </span>
                          </Typography>
                        ))}
                      </Box>
                    </React.Fragment>
                  }
                >
                  <IconButton
                    sx={{
                      color: 'text.secondary',
                      '&:hover': { color: 'primary.main' },
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                  mt: 2,
                }}
              >
                <WeatherEventSelector
                  selectedWeatherEvent={selectedWeatherEvent}
                  onSelectWeatherEvent={onSelectWeatherEvent}
                />
                {selectedWeatherEvent &&
                  (() => {
                    const findWeatherEvent = weatherEvents.find(
                      (event) => event.id === selectedWeatherEvent
                    );

                    return (
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          color: 'text.primary',
                          mt: 2,
                        }}
                      >
                        {t('upload.selectWeatherEvent')}:{' '}
                        {findWeatherEvent?.name || findWeatherEvent}
                        {findWeatherEvent?.discount ? (
                          <span style={{ color: theme.palette.primary.main }}>
                            {' '}
                            ({findWeatherEvent.discount}%)
                          </span>
                        ) : null}
                      </Typography>
                    );
                  })()}
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 'bold', color: 'text.primary' }}
              >
                {t('upload.selectFiles')}
              </Typography>
              <FileUpload
                ref={fileUploadRef}
                initialFiles={{}}
                onFilesSelected={handleImageFiles}
                imageSizes={imageSizes}
                onUploadComplete={handleFileUploadComplete}
              />
            </Box>
          </Grid>

          {/* <Typography variant="h6" gutterBottom>
          {t('upload.uploadImage')}
          <Tooltip title={t('upload.imageUploadExplanation')}>
            <IconButton onClick={handleImageUploadModalOpen}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Button variant="outlined" component="label">
            {t('upload.chooseFiles')}
            <input type="file" hidden multiple onChange={handleImageChange} />
          </Button>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {previews.map((src, index) => (
            <Box
              key={index}
              sx={{ position: 'relative', width: 100, height: 100 }}
            >
              <img
                src={src}
                alt={`Preview ${index}`}
                style={{ width: '100%', height: '100%', borderRadius: '8px' }}
              />
              <IconButton
                onClick={() => handleRemoveImage(index)}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  color: 'error.main',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ))}
        </Box>

        {uploadingFiles.length > 0 && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0,0,0,0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1300,
            }}
          >
            <Box
              sx={{
                width: '80%',
                maxWidth: '400px',
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="h6">{t('upload.uploadProgress')}</Typography>
              <List>
                {uploadingFiles.map(({ name }, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={name}
                      secondary={`${Math.round(
                        uploadProgress[name]?.progress || 0
                      )}%`}
                    />
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress[name]?.progress || 0}
                      sx={{ width: '100px', marginLeft: '10px' }}
                    />
                  </ListItem>
                ))}
              </List>
              <Button
                variant="contained"
                onClick={() => setUploadingFiles([])}
                disabled={uploadingFiles.some(
                  ({ name }) => (uploadProgress[name]?.progress || 0) < 100
                )}
              >
                {t('upload.close')}
              </Button>
            </Box>
          </Box>
        )} */}
          {MessageBoxRender()}

          {/* <Typography variant="h6" gutterBottom>
          {t('upload.selectEvent')}
          <Tooltip
            title={
              <React.Fragment>
                {weatherEvents.map((event) => (
                  <div key={event.id}>
                    {t(`upload.${event.descKey[i18n.language]}`)}
                  </div>
                ))}
              </React.Fragment>
            }
          >
            <IconButton onClick={handleSelectEventModalOpen}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Typography> 

        <FormControl fullWidth>
          <Select
            value={selectedWeatherEvent}
            onChange={(e) => setSelectedWeatherEvent(e.target.value)}
            sx={{
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              '& .MuiInputBase-root': {
                backgroundColor: theme.palette.background.default,
                borderRadius: '4px',
                transition: 'box-shadow 0.3s',
              },
              '& .Mui-focused': {
                boxShadow: `0 0 5px ${theme.palette.primary.main}`,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.text.secondary,
              },
              '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: theme.palette.primary.light,
                },
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
              },
            }}
          >
            {weatherEvents.map((event) => (
              <MenuItem key={event.id} value={event.id}>
                <Tooltip title={t(`upload.${event.descKey}`)} placement="right">
                  <span>
                    {t(`upload.${event.nameKey}`)} - {event.discount}%
                  </span>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        */}
        </Stack>
        {/* <Box
        sx={{
          position: 'fixed',
          bottom: isMobile ? 50 : isPad ? 30 : 0,
          left: 0,
          right: 0,
          bgcolor: 'background.paper',
          boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
          py: 2,
          px: 3,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button variant="outlined" onClick={handleCancel} fullWidth>
          {t('upload.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          fullWidth
        >
          {t('upload.upload')}
        </Button>
      </Box> */}
        <Box
          sx={{
            position: 'fixed',
            bottom: { xs: 50, sm: 30, md: 0 }, // 반응형 조정
            left: 0,
            right: 0,
            bgcolor: 'background.paper',
            boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
            py: 2,
            px: 3,
            display: 'flex',
            justifyContent: 'center', // 중앙 정렬
            gap: 2, // 버튼 간격 조정
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCancel}
            fullWidth
            sx={{
              maxWidth: 180, // 버튼 크기 제한
              fontWeight: 'bold',
              '&:hover': {
                borderColor: 'error.main',
                color: 'error.main',
              },
            }}
          >
            {t('upload.cancel')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            fullWidth
            sx={{
              maxWidth: 180, // 버튼 크기 제한
              fontWeight: 'bold',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
            }}
          >
            {t('upload.upload')}
          </Button>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert severity="info">{snackbarMessage}</Alert>
        </Snackbar>
        {/* 
        <Modal
          open={imageUploadModalOpen}
          onClose={handleImageUploadModalClose}
          aria-labelledby="image-upload-modal-title"
          aria-describedby="image-upload-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography
              id="image-upload-modal-title"
              variant="h6"
              component="h2"
            >
              {t('upload.imageUploadTitle')}
            </Typography>
            <Typography id="image-upload-modal-description" sx={{ mt: 2 }}>
              {t('upload.imageUploadExplanation')}
            </Typography>
          </Box>
        </Modal>

        <Modal
          open={areaSelectionModalOpen}
          onClose={handleAreaSelectionModalClose}
          aria-labelledby="area-selection-modal-title"
          aria-describedby="area-selection-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography
              id="area-selection-modal-title"
              variant="h6"
              component="h2"
            >
              {t('upload.areaSelectionTitle')}
            </Typography>
            <Typography id="area-selection-modal-description" sx={{ mt: 2 }}>
              {t('upload.areaSelectionExplanation')}
            </Typography>
          </Box>
        </Modal> */}
      </Box>
    </Box>
  );
};

export default Upload;
