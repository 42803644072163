// import React, { useEffect, useState, useRef } from 'react';
// import { useSelector } from 'react-redux';
// import { Link as RouterLink } from 'react-router-dom';
// import {
//   Box,
//   Tooltip,
//   Link,
//   IconButton,
//   Typography,
//   Collapse,
// } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import StoreIcon from '@mui/icons-material/Store';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import {
//   db,
//   collection,
//   query,
//   where,
//   getDoc,
//   getDocs,
//   orderBy,
//   doc,
// } from './firebaseConfig';
// import { useTranslation } from 'react-i18next';

// const MAX_ITEMS_DISPLAYED = 5;

// const FloatingSidebar = () => {
//   const { t } = useTranslation();
//   const [favorites, setFavorites] = useState([]);
//   const [recentlyViewed, setRecentlyViewed] = useState([]);
//   const [recentlyViewedStore, setRecentlyViewedStore] = useState([]);
//   const [storeFavorites, setStoreFavorites] = useState([]);
//   const [isOpen, setIsOpen] = useState(false);

//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const likedProducts = useSelector(
//     (state) => state.floatingSidebar.likedProducts
//   );
//   const likedStoreProducts = useSelector(
//     (state) => state.floatingSidebar.likedStoreProducts
//   );
//   const sidebarRef = useRef(null);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (!sidebarRef.current) return;

//       const windowHeight = window.innerHeight;
//       const sidebarHeight = sidebarRef.current.clientHeight;
//       const centerOffset = (windowHeight - sidebarHeight) / 2;

//       window.requestAnimationFrame(() => {
//         let newTopOffset = window.scrollY + centerOffset;
//         newTopOffset = Math.max(newTopOffset, 20);
//         const maxOffset =
//           window.document.documentElement.scrollHeight - sidebarHeight - 20;
//         newTopOffset = Math.min(newTopOffset, maxOffset);
//         sidebarRef.current.style.top = `${newTopOffset}px`;
//       });
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   useEffect(() => {
//     const loadFavoritesAndViews = async () => {
//       await fetchFavorites();
//       await fetchRecentlyViewed();
//     };

//     loadFavoritesAndViews();
//   }, [currentUser, likedProducts]);

//   useEffect(() => {
//     const loadStoreFavoritesAndViews = async () => {
//       await fetchStoreFavorites();
//       await fetchRecentlyViewedStore();
//     };

//     loadStoreFavoritesAndViews();
//   }, [currentUser, likedStoreProducts]);

//   const fetchStoreFavorites = async () => {
//     if (!currentUser) {
//       console.error('No current user found');
//       return;
//     }

//     try {
//       const storesRef = collection(db, 'stores');
//       const storesSnapshot = await getDocs(storesRef);
//       if (storesSnapshot.empty) {
//         console.log('No stores found');
//         setStoreFavorites([]);
//         return;
//       }

//       const storeFavoriteProductsPromises = storesSnapshot.docs.map(
//         async (storeDoc) => {
//           const userLikesRef = doc(
//             db,
//             'stores',
//             storeDoc.id,
//             'userLikes',
//             currentUser.uid
//           );
//           const userLikesDoc = await getDoc(userLikesRef);
//           if (userLikesDoc.exists()) {
//             const likedProductsIds = userLikesDoc.data()?.products || [];
//             if (likedProductsIds.length === 0) {
//               console.log(
//                 `No liked products in store ${storeDoc.id} for user ${currentUser.uid}`
//               );
//               return [];
//             }
//             return Promise.all(
//               likedProductsIds.map((productId) =>
//                 fetchStoreProducts(storeDoc.id, productId)
//               )
//             );
//           } else {
//             console.log(`User likes not found in store ${storeDoc.id}`);
//             return [];
//           }
//         }
//       );

//       const storeFavoriteProductsArrays = await Promise.all(
//         storeFavoriteProductsPromises
//       );
//       const storeFavoriteProducts = storeFavoriteProductsArrays
//         .flat()
//         .filter((product) => product !== null);
//       setStoreFavorites(storeFavoriteProducts);
//     } catch (err) {
//       console.error('Error fetching store favorites:', err);
//       setStoreFavorites([]);
//     }
//   };

//   async function fetchStoreProducts(storeId, productId) {
//     const productRef = doc(db, 'stores', storeId, 'products', productId);
//     const productDoc = await getDoc(productRef);
//     if (productDoc.exists()) {
//       return { id: productDoc.id, ...productDoc.data() };
//     }
//     return null;
//   }

//   const fetchFavorites = async () => {
//     if (!currentUser) {
//       console.error('No current user found');
//       return;
//     }

//     try {
//       const userLikesRef = doc(db, 'userLikes', currentUser.uid);
//       const userLikesDoc = await getDoc(userLikesRef);

//       if (!userLikesDoc.exists()) {
//         console.log('No likes found for this user');
//         setFavorites([]);
//         return;
//       }

//       const likedProductsIds = userLikesDoc.data()?.products;
//       if (!likedProductsIds || likedProductsIds.length === 0) {
//         console.log('User has no liked products');
//         setFavorites([]);
//         return;
//       }

//       const favoriteProducts = await Promise.all(
//         likedProductsIds.map(fetchProducts)
//       );

//       const validProducts = favoriteProducts.filter(
//         (product) => product !== null
//       );
//       setFavorites(validProducts);
//     } catch (err) {
//       console.error('Error fetching favorites products:', err);
//       setFavorites([]);
//     }
//   };

//   const fetchProducts = async (id) => {
//     const productRef = doc(db, 'products', id);
//     const productDoc = await getDoc(productRef);
//     if (productDoc.exists()) {
//       return {
//         id: productDoc.id,
//         ...productDoc.data(),
//       };
//     } else {
//       return null;
//     }
//   };

//   const fetchRecentlyViewed = async () => {
//     try {
//       const userActivitiesRef = collection(db, 'userActivities');
//       const q = query(
//         userActivitiesRef,
//         where('userId', '==', currentUser.uid),
//         where('type', '==', 'view'),
//         orderBy('timestamp', 'desc')
//       );
//       const querySnapshot = await getDocs(q);
//       if (querySnapshot.empty) return;

//       const viewActivities = querySnapshot.docs.map((doc) => doc.data());
//       const productIds = viewActivities.map(
//         (activity) => activity.data.productId
//       );
//       const uniqueProductIds = [...new Set(productIds)];

//       const productsQuery = query(
//         collection(db, 'products'),
//         where('id', 'in', uniqueProductIds)
//       );
//       const productsSnapshot = await getDocs(productsQuery);
//       const recentlyViewedProducts = productsSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setRecentlyViewed(recentlyViewedProducts);
//     } catch (error) {
//       console.error('Error fetching recently viewed products:', error);
//     }
//   };

//   const fetchRecentlyViewedStore = async () => {
//     if (!currentUser) {
//       console.error('No current user found');
//       return;
//     }

//     try {
//       const storesRef = collection(db, 'stores');
//       const storesSnapshot = await getDocs(storesRef);

//       const allRecentlyViewedStoreProductsPromises = storesSnapshot.docs.map(
//         async (storeDoc) => {
//           const userActivitiesRef = collection(
//             db,
//             `stores/${storeDoc.id}/userActivities`
//           );
//           const q = query(
//             userActivitiesRef,
//             where('userId', '==', currentUser.uid),
//             where('type', '==', 'view'),
//             orderBy('timestamp', 'desc')
//           );
//           const querySnapshot = await getDocs(q);
//           if (querySnapshot.empty) {
//             return [];
//           }

//           const viewedProductIds = querySnapshot.docs.flatMap(
//             (doc) => doc.data().viewedProducts || []
//           );
//           const uniqueProductIds = [...new Set(viewedProductIds)];

//           return Promise.all(
//             uniqueProductIds.map((productId) =>
//               fetchStoreProducts(storeDoc.id, productId)
//             )
//           );
//         }
//       );

//       const allRecentlyViewedStoreProducts = await Promise.all(
//         allRecentlyViewedStoreProductsPromises
//       );
//       const recentlyViewedStoreProducts = allRecentlyViewedStoreProducts
//         .flat()
//         .filter((product) => product !== null);
//       setRecentlyViewedStore(recentlyViewedStoreProducts);
//     } catch (error) {
//       console.error('Error fetching recently viewed store products:', error);
//     }
//   };

//   return (
//     <>
//       <IconButton
//         onClick={() => setIsOpen(!isOpen)}
//         sx={{
//           position: 'fixed',
//           right: isOpen ? 250 : 20, // 사이드바의 기본 위치를 왼쪽으로 이동
//           top: '50%',
//           transform: 'translateY(-50%)',
//           backgroundColor: 'white',
//           boxShadow: 3,
//           zIndex: 1000,
//         }}
//       >
//         {isOpen ? <CloseIcon /> : <MenuIcon />}
//       </IconButton>
//       <Box
//         ref={sidebarRef}
//         sx={{
//           position: 'fixed',
//           right: isOpen ? 0 : -250, // 사이드바의 기본 위치를 왼쪽으로 이동
//           top: '50%',
//           transform: 'translateY(-50%)',
//           zIndex: 1000,
//           backgroundColor: 'white',
//           p: 2,
//           boxShadow: 3,
//           overflowY: 'auto',
//           maxWidth: '250px',
//           maxHeight: 'calc(100vh - 100px)',
//           transition: 'right 0.3s ease-out',
//         }}
//       >
//         <Collapse in={isOpen}>
//           <Box
//             sx={{
//               mt: 1,
//               mb: 1,
//               border: '1px solid #ddd',
//               borderRadius: 1,
//               p: 1,
//             }}
//           >
//             <Tooltip title={t('likedUsedProducts')}>
//               <IconButton>
//                 <FavoriteIcon />
//               </IconButton>
//             </Tooltip>
//             {favorites.length === 0 ? (
//               <Typography variant="body2">{t('none')}</Typography>
//             ) : (
//               favorites.slice(0, MAX_ITEMS_DISPLAYED).map((product) => (
//                 <Link
//                   component={RouterLink}
//                   to={`/detail/${product.id}`}
//                   key={product.id}
//                   sx={{
//                     display: 'block',
//                     mb: 1,
//                     overflow: 'hidden',
//                     whiteSpace: 'nowrap',
//                     textOverflow: 'ellipsis',
//                     fontSize: '0.575rem',
//                   }}
//                 >
//                   {product.title}
//                 </Link>
//               ))
//             )}
//           </Box>
//           <Box
//             sx={{
//               mt: 1,
//               mb: 1,
//               border: '1px solid #ddd',
//               borderRadius: 1,
//               p: 1,
//             }}
//           >
//             <Tooltip title={t('likedStoreProducts')}>
//               <IconButton>
//                 <StoreIcon />
//               </IconButton>
//             </Tooltip>
//             {storeFavorites.length === 0 ? (
//               <Typography variant="body2">{t('none')}</Typography>
//             ) : (
//               storeFavorites.slice(0, MAX_ITEMS_DISPLAYED).map((product) => (
//                 <Link
//                   component={RouterLink}
//                   to={`/product_detail/${product.id}`}
//                   key={product.id}
//                   sx={{
//                     display: 'block',
//                     mb: 1,
//                     overflow: 'hidden',
//                     whiteSpace: 'nowrap',
//                     textOverflow: 'ellipsis',
//                     fontSize: '0.575rem',
//                   }}
//                 >
//                   {product.productName}
//                 </Link>
//               ))
//             )}
//           </Box>
//           <Box
//             sx={{
//               mt: 1,
//               mb: 1,
//               border: '1px solid #ddd',
//               borderRadius: 1,
//               p: 1,
//             }}
//           >
//             <Tooltip title={t('scrollToTop')}>
//               <IconButton
//                 onClick={(e) => {
//                   e.preventDefault();
//                   window.scrollTo(0, 0);
//                 }}
//               >
//                 <ArrowUpwardIcon />
//               </IconButton>
//             </Tooltip>
//           </Box>
//         </Collapse>
//       </Box>
//     </>
//   );
// };

// export default FloatingSidebar;

import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Tooltip,
  Link,
  IconButton,
  Typography,
  Collapse,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StoreIcon from '@mui/icons-material/Store';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {
  db,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  orderBy,
  doc,
} from './firebaseConfig';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
const MAX_ITEMS_DISPLAYED = 5;

const FloatingSidebar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [favorites, setFavorites] = useState([]);
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [recentlyViewedStore, setRecentlyViewedStore] = useState([]);
  const [storeFavorites, setStoreFavorites] = useState([]);
  const [visitorCounts, setVisitorCounts] = useState({
    todayCount: 0,
    totalCount: 0,
  });
  const [isOpen, setIsOpen] = useState(false);

  const currentUser = useSelector((state) => state.auth.currentUser);
  const likedProducts = useSelector(
    (state) => state.floatingSidebar.likedProducts
  );
  const likedStoreProducts = useSelector(
    (state) => state.floatingSidebar.likedStoreProducts
  );
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!sidebarRef.current) return;

      const windowHeight = window.innerHeight;
      const sidebarHeight = sidebarRef.current.clientHeight;
      const centerOffset = (windowHeight - sidebarHeight) / 2;

      window.requestAnimationFrame(() => {
        let newTopOffset = window.scrollY + centerOffset;
        newTopOffset = Math.max(newTopOffset, 20);
        const maxOffset =
          window.document.documentElement.scrollHeight - sidebarHeight - 20;
        newTopOffset = Math.min(newTopOffset, maxOffset);
        sidebarRef.current.style.top = `${newTopOffset}px`;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const loadFavoritesAndViews = async () => {
      await fetchFavorites();
      await fetchRecentlyViewed();
    };

    loadFavoritesAndViews();
  }, [currentUser, likedProducts]);

  useEffect(() => {
    const fetchVisitorCounts = async () => {
      const visitorDocRef = doc(db, 'visitorCounts', 'count');
      const visitorDoc = await getDoc(visitorDocRef);
      if (visitorDoc.exists()) {
        setVisitorCounts(visitorDoc.data());
      }
    };

    fetchVisitorCounts();
  }, []);

  useEffect(() => {
    const loadStoreFavoritesAndViews = async () => {
      await fetchStoreFavorites();
      await fetchRecentlyViewedStore();
    };

    loadStoreFavoritesAndViews();
  }, [currentUser, likedStoreProducts]);

  const fetchStoreFavorites = async () => {
    const userId = currentUser
      ? currentUser.uid
      : sessionStorage.getItem('userId');
    if (!userId) {
      console.error('No current user or user ID found');
      return;
    }

    try {
      const storesRef = collection(db, 'stores');
      const storesSnapshot = await getDocs(storesRef);
      if (storesSnapshot.empty) {
        console.log('No stores found');
        setStoreFavorites([]);
        return;
      }

      const storeFavoriteProductsPromises = storesSnapshot.docs.map(
        async (storeDoc) => {
          const userLikesRef = doc(
            db,
            'stores',
            storeDoc.id,
            'userLikes',
            userId
          );
          const userLikesDoc = await getDoc(userLikesRef);
          if (userLikesDoc.exists()) {
            const likedProductsIds = userLikesDoc.data()?.products || [];
            if (likedProductsIds.length === 0) {
              console.log(
                `No liked products in store ${storeDoc.id} for user ${userId}`
              );
              return [];
            }
            return Promise.all(
              likedProductsIds.map((productId) =>
                fetchStoreProducts(storeDoc.id, productId)
              )
            );
          } else {
            console.log(`User likes not found in store ${storeDoc.id}`);
            return [];
          }
        }
      );

      const storeFavoriteProductsArrays = await Promise.all(
        storeFavoriteProductsPromises
      );
      const storeFavoriteProducts = storeFavoriteProductsArrays
        .flat()
        .filter((product) => product !== null);
      setStoreFavorites(storeFavoriteProducts);
    } catch (err) {
      console.error('Error fetching store favorites:', err);
      setStoreFavorites([]);
    }
  };

  const fetchStoreProducts = async (storeId, productId) => {
    try {
      const productRef = doc(db, 'stores', storeId, 'products', productId);
      const productSnapshot = await getDoc(productRef);
      if (productSnapshot.exists()) {
        return { id: productSnapshot.id, ...productSnapshot.data() };
      } else {
        return null;
      }
    } catch (error) {
      console.error(
        `Error fetching product ${productId} from store ${storeId}:`,
        error
      );
      return null;
    }
  };
  const fetchFavorites = async () => {
    const userId = currentUser
      ? currentUser.uid
      : sessionStorage.getItem('userId');
    if (!userId) {
      console.error('No current user or user ID found');
      return;
    }

    try {
      const userLikesRef = doc(db, 'userLikes', userId);
      const userLikesDoc = await getDoc(userLikesRef);

      if (!userLikesDoc.exists()) {
        console.log('No likes found for this user');
        setFavorites([]);
        return;
      }

      const likedProductsIds = userLikesDoc.data()?.products;
      if (!likedProductsIds || likedProductsIds.length === 0) {
        console.log('User has no liked products');
        setFavorites([]);
        return;
      }

      const favoriteProducts = await Promise.all(
        likedProductsIds.map(fetchProducts)
      );

      const validProducts = favoriteProducts.filter(
        (product) => product !== null
      );
      setFavorites(validProducts);
    } catch (err) {
      console.error('Error fetching favorites products:', err);
      setFavorites([]);
    }
  };

  const fetchProducts = async (id) => {
    const productRef = doc(db, 'products', id);
    const productDoc = await getDoc(productRef);
    if (productDoc.exists()) {
      return {
        id: productDoc.id,
        ...productDoc.data(),
      };
    } else {
      return null;
    }
  };

  // const fetchRecentlyViewed = async () => {
  //   const userId = currentUser
  //     ? currentUser.uid
  //     : sessionStorage.getItem('userId');
  //   if (!userId) {
  //     console.error('No current user or user ID found');
  //     return;
  //   }

  //   try {
  //     const userActivitiesRef = collection(db, 'userActivities');
  //     const q = query(
  //       userActivitiesRef,
  //       where('userId', '==', userId),
  //       where('type', '==', 'view'),
  //       orderBy('timestamp', 'desc')
  //     );
  //     const querySnapshot = await getDocs(q);
  //     if (querySnapshot.empty) return;

  //     const viewActivities = querySnapshot.docs.map((doc) => doc.data());
  //     const productIds = viewActivities.map(
  //       (activity) => activity.data.productId
  //     );
  //     const uniqueProductIds = [...new Set(productIds)];

  //     const productsQuery = query(
  //       collection(db, 'products'),
  //       where('id', 'in', uniqueProductIds)
  //     );
  //     const productsSnapshot = await getDocs(productsQuery);
  //     const recentlyViewedProducts = productsSnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     setRecentlyViewed(recentlyViewedProducts);
  //   } catch (error) {
  //     console.error('Error fetching recently viewed products:', error);
  //   }
  // };

  // const fetchRecentlyViewedStore = async () => {
  //   const userId = currentUser
  //     ? currentUser.uid
  //     : sessionStorage.getItem('userId');
  //   if (!userId) {
  //     console.error('No current user or user ID found');
  //     return;
  //   }

  //   try {
  //     const storesRef = collection(db, 'stores');
  //     const storesSnapshot = await getDocs(storesRef);

  //     const allRecentlyViewedStoreProductsPromises = storesSnapshot.docs.map(
  //       async (storeDoc) => {
  //         const userActivitiesRef = collection(
  //           db,
  //           `stores/${storeDoc.id}/userActivities`
  //         );
  //         const q = query(
  //           userActivitiesRef,
  //           where('userId', '==', userId),
  //           where('type', '==', 'view'),
  //           orderBy('timestamp', 'desc')
  //         );
  //         const querySnapshot = await getDocs(q);
  //         if (querySnapshot.empty) {
  //           return [];
  //         }

  //         const viewedProductIds = querySnapshot.docs.flatMap(
  //           (doc) => doc.data().viewedProducts || []
  //         );
  //         const uniqueProductIds = [...new Set(viewedProductIds)];

  //         return Promise.all(
  //           uniqueProductIds.map((productId) =>
  //             fetchStoreProducts(storeDoc.id, productId)
  //           )
  //         );
  //       }
  //     );

  //     const allRecentlyViewedStoreProducts = await Promise.all(
  //       allRecentlyViewedStoreProductsPromises
  //     );
  //     const recentlyViewedStoreProducts = allRecentlyViewedStoreProducts
  //       .flat()
  //       .filter((product) => product !== null);
  //     setRecentlyViewedStore(recentlyViewedStoreProducts);
  //   } catch (error) {
  //     console.error('Error fetching recently viewed store products:', error);
  //   }
  // };
  const fetchRecentlyViewed = async () => {
    const userId = currentUser
      ? currentUser.uid
      : sessionStorage.getItem('userId');
    if (!userId) {
      console.error('No current user or user ID found');
      return;
    }

    try {
      const userActivitiesRef = collection(db, 'userActivities');
      const q = query(
        userActivitiesRef,
        where('userId', '==', userId),
        where('type', '==', 'view'),
        orderBy('timestamp', 'desc')
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) return;

      const viewActivities = querySnapshot.docs.map((doc) => doc.data());
      const productIds = viewActivities.map(
        (activity) => activity.data.productId
      );
      const uniqueProductIds = [...new Set(productIds)];

      if (uniqueProductIds.length === 0) {
        setRecentlyViewed([]);
        return;
      }

      const productsQuery = query(
        collection(db, 'products'),
        where('id', 'in', uniqueProductIds)
      );
      const productsSnapshot = await getDocs(productsQuery);
      const recentlyViewedProducts = productsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecentlyViewed(recentlyViewedProducts);
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error(
          'Firestore index error: Please create the required index in the Firestore console.',
          error
        );
      } else {
        console.error('Error fetching recently viewed products:', error);
      }
    }
  };

  const fetchRecentlyViewedStore = async () => {
    const userId = currentUser
      ? currentUser.uid
      : sessionStorage.getItem('userId');
    if (!userId) {
      console.error('No current user or user ID found');
      return;
    }

    try {
      const storesRef = collection(db, 'stores');
      const storesSnapshot = await getDocs(storesRef);

      const allRecentlyViewedStoreProductsPromises = storesSnapshot.docs.map(
        async (storeDoc) => {
          const userActivitiesRef = collection(
            db,
            `stores/${storeDoc.id}/userActivities`
          );
          const q = query(
            userActivitiesRef,
            where('userId', '==', userId),
            where('type', '==', 'view'),
            orderBy('timestamp', 'desc')
          );
          const querySnapshot = await getDocs(q);
          if (querySnapshot.empty) {
            return [];
          }

          const viewedProductIds = querySnapshot.docs.flatMap(
            (doc) => doc.data().viewedProducts || []
          );
          const uniqueProductIds = [...new Set(viewedProductIds)];

          return Promise.all(
            uniqueProductIds.map((productId) =>
              fetchStoreProducts(storeDoc.id, productId)
            )
          );
        }
      );

      const allRecentlyViewedStoreProducts = await Promise.all(
        allRecentlyViewedStoreProductsPromises
      );
      const recentlyViewedStoreProducts = allRecentlyViewedStoreProducts
        .flat()
        .filter((product) => product !== null);
      setRecentlyViewedStore(recentlyViewedStoreProducts);
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error(
          'Firestore index error: Please create the required index in the Firestore console.',
          error
        );
      } else {
        console.error('Error fetching recently viewed store products:', error);
      }
    }
  };

  return (
    <>
      <IconButton
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          position: 'fixed',
          right: isOpen ? 250 : 20, // 사이드바 위치 조정
          top: '50%',
          transform: 'translateY(-50%)',
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.primary.main,
          boxShadow: 3,
          zIndex: 1000,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>

      <Box
        ref={sidebarRef}
        sx={{
          position: 'fixed',
          right: isOpen ? 0 : -250, // 사이드바 위치 조정
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1000,
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          p: 2,
          boxShadow: 3,
          overflowY: 'auto',
          maxWidth: '250px',
          maxHeight: 'calc(100vh - 100px)',
          transition: 'right 0.3s ease-out',
          borderRadius: '10px',
        }}
      >
        <Collapse in={isOpen}>
          <Box
            sx={{
              mt: 1,
              mb: 1,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              p: 1,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Tooltip title={t('likedUsedProducts')}>
              <IconButton color="primary">
                <FavoriteIcon />
              </IconButton>
            </Tooltip>
            {favorites.length === 0 ? (
              <Typography variant="body2" color="textSecondary">
                {t('none')}
              </Typography>
            ) : (
              favorites.slice(0, MAX_ITEMS_DISPLAYED).map((product) => (
                <Link
                  component={RouterLink}
                  to={`/detail/${product.id}`}
                  key={product.id}
                  sx={{
                    display: 'block',
                    mb: 1,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '0.575rem',
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {product.title}
                </Link>
              ))
            )}
          </Box>

          <Box
            sx={{
              mt: 1,
              mb: 1,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              p: 1,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Tooltip title={t('likedStoreProducts')}>
              <IconButton color="primary">
                <StoreIcon />
              </IconButton>
            </Tooltip>
            {storeFavorites.length === 0 ? (
              <Typography variant="body2" color="textSecondary">
                {t('none')}
              </Typography>
            ) : (
              storeFavorites.slice(0, MAX_ITEMS_DISPLAYED).map((product) => (
                <Link
                  component={RouterLink}
                  to={`/product_detail/${product.id}`}
                  key={product.id}
                  sx={{
                    display: 'block',
                    mb: 1,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '0.575rem',
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {product.productName}
                </Link>
              ))
            )}
          </Box>

          <Box
            sx={{
              mt: 1,
              mb: 1,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              p: 1,
              backgroundColor: theme.palette.background.paper,
              textAlign: 'center',
            }}
          >
            <Tooltip title={t('scrollToTop')}>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo(0, 0);
                }}
              >
                <ArrowUpwardIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Box mt={2} textAlign="center">
            <Typography variant="body2" color="textSecondary">
              {t('settings.todayVisitors')}: {visitorCounts.todayCount}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t('settings.totalVisitors')}: {visitorCounts.totalCount}
            </Typography>
          </Box>
        </Collapse>
      </Box>
    </>
  );
};
//   return (
//     <>
//       <IconButton
//         onClick={() => setIsOpen(!isOpen)}
//         sx={{
//           position: 'fixed',
//           right: isOpen ? 250 : 20, // 사이드바의 기본 위치를 왼쪽으로 이동
//           top: '50%',
//           transform: 'translateY(-50%)',
//           backgroundColor: 'white',
//           boxShadow: 3,
//           zIndex: 1000,
//         }}
//       >
//         {isOpen ? <CloseIcon /> : <MenuIcon />}
//       </IconButton>
//       <Box
//         ref={sidebarRef}
//         sx={{
//           position: 'fixed',
//           right: isOpen ? 0 : -250, // 사이드바의 기본 위치를 왼쪽으로 이동
//           top: '50%',
//           transform: 'translateY(-50%)',
//           zIndex: 1000,
//           backgroundColor: 'white',
//           p: 2,
//           boxShadow: 3,
//           overflowY: 'auto',
//           maxWidth: '250px',
//           maxHeight: 'calc(100vh - 100px)',
//           transition: 'right 0.3s ease-out',
//         }}
//       >
//         <Collapse in={isOpen}>
//           <Box
//             sx={{
//               mt: 1,
//               mb: 1,
//               border: '1px solid #ddd',
//               borderRadius: 1,
//               p: 1,
//             }}
//           >
//             <Tooltip title={t('likedUsedProducts')}>
//               <IconButton>
//                 <FavoriteIcon />
//               </IconButton>
//             </Tooltip>
//             {favorites.length === 0 ? (
//               <Typography variant="body2">{t('none')}</Typography>
//             ) : (
//               favorites.slice(0, MAX_ITEMS_DISPLAYED).map((product) => (
//                 <Link
//                   component={RouterLink}
//                   to={`/detail/${product.id}`}
//                   key={product.id}
//                   sx={{
//                     display: 'block',
//                     mb: 1,
//                     overflow: 'hidden',
//                     whiteSpace: 'nowrap',
//                     textOverflow: 'ellipsis',
//                     fontSize: '0.575rem',
//                   }}
//                 >
//                   {product.title}
//                 </Link>
//               ))
//             )}
//           </Box>
//           <Box
//             sx={{
//               mt: 1,
//               mb: 1,
//               border: '1px solid #ddd',
//               borderRadius: 1,
//               p: 1,
//             }}
//           >
//             <Tooltip title={t('likedStoreProducts')}>
//               <IconButton>
//                 <StoreIcon />
//               </IconButton>
//             </Tooltip>
//             {storeFavorites.length === 0 ? (
//               <Typography variant="body2">{t('none')}</Typography>
//             ) : (
//               storeFavorites.slice(0, MAX_ITEMS_DISPLAYED).map((product) => (
//                 <Link
//                   component={RouterLink}
//                   to={`/product_detail/${product.id}`}
//                   key={product.id}
//                   sx={{
//                     display: 'block',
//                     mb: 1,
//                     overflow: 'hidden',
//                     whiteSpace: 'nowrap',
//                     textOverflow: 'ellipsis',
//                     fontSize: '0.575rem',
//                   }}
//                 >
//                   {product.productName}
//                 </Link>
//               ))
//             )}
//           </Box>
//           <Box
//             sx={{
//               mt: 1,
//               mb: 1,
//               border: '1px solid #ddd',
//               borderRadius: 1,
//               p: 1,
//             }}
//           >
//             <Tooltip title={t('scrollToTop')}>
//               <IconButton
//                 onClick={(e) => {
//                   e.preventDefault();
//                   window.scrollTo(0, 0);
//                 }}
//               >
//                 <ArrowUpwardIcon />
//               </IconButton>
//             </Tooltip>
//           </Box>

//           <Box mt={2}>
//             <Typography variant="body2">
//               {t('settings.todayVisitors')}: {visitorCounts.todayCount}
//             </Typography>
//             <Typography variant="body2">
//               {t('settings.totalVisitors')}: {visitorCounts.totalCount}
//             </Typography>
//           </Box>
//         </Collapse>
//       </Box>
//     </>
//   );
// };

export default FloatingSidebar;
