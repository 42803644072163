// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   Box,
//   Typography,
//   Button,
//   CircularProgress,
// } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import { doc, getDoc } from 'firebase/firestore';
// import { db } from '../firebaseConfig.js';
// import { fetchWeather } from './fetchWeather';
// import { useTranslation } from 'react-i18next';
// import MyLocationIcon from '@mui/icons-material/MyLocation';
// import Header from './Header/Header';
// import CurrentWeather from './CurrentWeather/CurrentWeather';
// import TimeForecast from './Forecast/TimeForecast';
// import DayForecast from './Forecast/DayForecast';
// import WeatherEventDetails from './WeatherEventDetails';
// import LoginRequired from '../LoginRequired.js';

// function WeatherBanner() {
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { t } = useTranslation();
//   const [locationName, setLocationName] = useState('');
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const loading = useSelector((state) => state.app.isLoading);

//   useEffect(() => {
//     const fetchLocation = async () => {
//       if (currentUser) {
//         try {
//           const userRef = doc(db, 'users', currentUser.uid);
//           const userDoc = await getDoc(userRef);
//           if (userDoc.exists()) {
//             const userData = userDoc.data();
//             const selectedTown =
//               userData.selectedTown === 1 ? userData.town1 : userData.town2;
//             if (selectedTown) {
//               setLocationName(selectedTown.name);
//               dispatch(
//                 fetchWeather({
//                   lat: selectedTown.lat,
//                   lng: selectedTown.lng,
//                 })
//               );
//             }
//           }
//         } catch (error) {
//           console.error('Error fetching user location:', error);
//         }
//       }
//     };

//     if (currentUser) {
//       fetchLocation();
//     }
//   }, [currentUser, dispatch]);

//   if (!currentUser) {
//     return (
//       <Container
//         maxWidth="sm"
//         sx={{
//           // height: '10vh',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           textAlign: 'center',
//           color: theme.palette.text.primary,
//         }}
//       >
//         {/* <LoginRequired /> */}
//       </Container>
//     );
//   }

//   return (
//     <Box
//       sx={{
//         width: '100%',
//         //minHeight: '100vh',
//         backgroundColor: theme.palette.background.default,
//         color: theme.palette.text.primary,
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'flex-start',
//       }}
//     >
//       {/* 🏡 위치 설정 */}
//       <Container maxWidth="lg" sx={{ textAlign: 'center', py: 4 }}>
//         <Button
//           variant="contained"
//           startIcon={<MyLocationIcon />}
//           onClick={() => navigate('/settings/mytown')}
//           sx={{
//             mt: 2,
//             px: { xs: 2, sm: 3, md: 4 }, // 반응형 패딩
//             py: { xs: 1, sm: 1.5 },
//             fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' }, // 반응형 글자 크기
//             fontWeight: 'bold',
//             borderRadius: '30px', // 둥근 버튼
//             textTransform: 'none',
//             boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
//             transition: 'all 0.3s ease-in-out',
//             background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
//             color: theme.palette.primary.contrastText,
//             '&:hover': {
//               background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
//               boxShadow: '0 6px 15px rgba(0,0,0,0.2)',
//               transform: 'scale(1.05)', // 약간 커지는 효과
//             },
//             '&:active': {
//               transform: 'scale(0.98)', // 클릭 시 살짝 눌리는 효과
//             },
//           }}
//         >
//           {t('myTown.title')}
//         </Button>

//         <Typography
//           variant={locationName ? 'h5' : 'body2'}
//           gutterBottom={!!locationName}
//           sx={{
//             mt: locationName ? 2 : 1,
//             color: locationName
//               ? theme.palette.text.primary
//               : theme.palette.text.secondary,
//             fontWeight: locationName ? 'bold' : 'normal',
//           }}
//         >
//           {locationName ? locationName : t('setLocationHint')}
//         </Typography>

//         {/* 🌍 날씨 정보 & 예보 */}
//         {loading ? (
//           <CircularProgress sx={{ my: 4 }} />
//         ) : (
//           <>
//             {/* <Header /> */}
//             <CurrentWeather />

//             {/* <TimeForecast /> */}
//             {/* <DayForecast /> */}
//           </>
//         )}
//       </Container>
//     </Box>
//   );
// }

// export default WeatherBanner;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import { fetchWeather } from './fetchWeather';
import CurrentWeather from './CurrentWeather/CurrentWeather';

function WeatherBanner() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locationName, setLocationName] = useState('');
  const currentUser = useSelector((state) => state.auth.currentUser);
  const weather = useSelector((state) => state.weather.weatherData);
  const loading = useSelector((state) => state.app.isLoading);

  useEffect(() => {
    const fetchLocation = async () => {
      if (currentUser) {
        try {
          const userRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const selectedTown =
              userData.selectedTown === 1 ? userData.town1 : userData.town2;
            if (selectedTown) {
              setLocationName(selectedTown.name);
              dispatch(
                fetchWeather({
                  lat: selectedTown.lat,
                  lng: selectedTown.lng,
                })
              );
            }
          }
        } catch (error) {
          console.error('Error fetching user location:', error);
        }
      }
    };

    if (currentUser) {
      fetchLocation();
    }
  }, [currentUser]);

  if (!currentUser) return null; // 로그인 안 된 사용자 숨김

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        backgroundColor: theme.palette.background.default,
        padding: '10px 16px',
        // borderRadius: 2,
        // boxShadow: theme.shadows[1],
      }}
    >
      {/* 🌍 위치명 */}
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {locationName || t('setLocationHint')}
      </Typography>

      {/* 🌦️ 날씨 아이콘 */}
      {loading ? null : <CurrentWeather />}
    </Box>
  );
}

export default WeatherBanner;
