// import React from 'react';
// import Card from '@mui/material/Card';
// import CardMedia from '@mui/material/CardMedia';
// import IconButton from '@mui/material/IconButton';
// import CancelIcon from '@mui/icons-material/Cancel';

// const PreviewImage = ({ preview, onRemove }) => {
//   return (
//     <Card sx={{ width: 100, position: 'relative', margin: 1 }}>
//       <CardMedia
//         component="img"
//         height="100"
//         image={preview}
//         alt="Preview"
//         sx={{ borderRadius: 0.5 }}
//       />
//       <IconButton
//         onClick={onRemove}
//         sx={{
//           position: 'absolute',
//           top: 0,
//           right: 0,
//           color: 'rgba(255, 255, 255, 0.5)',
//           backgroundColor: 'rgba(0, 0, 0, 0.5)',
//           '&:hover': {
//             backgroundColor: 'rgba(0, 0, 0, 0.7)',
//           },
//           borderRadius: '50%',
//         }}
//         size="small"
//       >
//         <CancelIcon />
//       </IconButton>
//     </Card>
//   );
// };
// export default PreviewImage;

import React from 'react';
import { Card, CardMedia, IconButton, CardActionArea } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const PreviewImage = ({ preview, onRemove }) => {
  return (
    <Card sx={{ width: 100, position: 'relative', m: 1 }}>
      {/* CardActionArea added for accessibility and better click feedback */}
      <CardActionArea>
        <CardMedia
          component="img"
          height="100" // Consider using 'auto' or handling aspect ratio more dynamically
          image={preview}
          alt="Preview"
          sx={{
            objectFit: 'cover', // Ensures the image covers the area without stretching
            height: 100, // Fixed height to match the card height
            borderRadius: 0.5, // Slight rounding of corners
          }}
        />
      </CardActionArea>
      <IconButton
        onClick={onRemove}
        sx={{
          position: 'absolute',
          top: 8, // Slightly lowered for better aesthetics
          right: 8, // Consistent spacing from the corner
          color: 'common.white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Using theme palette for better theme integration
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Darker on hover for better visibility
          },
          borderRadius: '50%', // Fully rounded
          padding: '6px', // Padding around the icon for better touch area
        }}
        size="small"
      >
        <CancelIcon />
      </IconButton>
    </Card>
  );
};

export default PreviewImage;
