import axios from 'axios';
import i18n from 'i18next'; // i18next에서 현재 언어 가져오기

const baseUrl = 'https://api.openweathermap.org/data/2.5';

// 환경 변수에서 API 키 불러오기
const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;

/**
 * 현재 날씨 데이터를 가져오는 함수
 * @param {Object} selectedTown 사용자가 선택한 위치 정보 (lat, lng)
 * @returns {Promise<Object|null>} 날씨 데이터 또는 null 반환
 */
export const getCurrentWeather = async (selectedTown) => {
  try {
    let location = selectedTown
      ? { latitude: selectedTown.lat, longitude: selectedTown.lng }
      : await getCurrentLocation();

    const defaultLocation = { latitude: 37.7749, longitude: -122.4194 }; // 샌프란시스코 기본값
    const { latitude, longitude } = location || defaultLocation;
    const lang = i18n.language || 'en'; // 기본 언어 설정

    const url = `${baseUrl}/weather?lat=${latitude}&lon=${longitude}&lang=${lang}&units=metric&appid=${API_KEY}`;

    const response = await axios.get(url);
    return response.data; // 날씨 데이터 반환
  } catch (error) {
    console.error('❌ Error fetching weather data:', error);
    return null;
  }
};

/**
 * 현재 위치를 가져오는 함수 (웹 환경에서 사용)
 * @returns {Promise<{latitude: number, longitude: number}|null>}
 */
const getCurrentLocation = async () => {
  return new Promise((resolve) => {
    if (!navigator.geolocation) {
      console.warn('⚠️ Geolocation is not supported by this browser.');
      resolve(null);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        console.error('❌ Geolocation error:', error);
        resolve(null);
      }
    );
  });
};
