// export const getMediaType = (url) => isImageURL(url) ? 'image' : isVideoURL(url) ? 'video' : 'other';

// // Constants for media types
// export const MEDIA_TYPES = {
//     IMAGE: 'image',
//     VIDEO: 'video',
//     OTHER: 'other',
// };
// // URL 타입 확인을 위한 helper 함수
// export const isImageURL = (url) => {
//     const pathname = new URL(url).pathname;
//     return /\.(jpg|jpeg|png|gif)$/i.test(pathname);
// }

// export const isVideoURL = (url) => {
//     const pathname = new URL(url).pathname;
//     return /\.(mp4|webm|ogg)$/i.test(pathname);
// }

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// 🔹 미디어 타입 식별
export const getMediaType = (url) =>
  isImageURL(url) ? 'image' : isVideoURL(url) ? 'video' : 'other';

export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  OTHER: 'other',
};

// 🔹 이미지 URL 확인 함수
export const isImageURL = (url) => {
  try {
    if (typeof url !== 'string' || !url.trim()) {
      throw new Error('Invalid URL: URL is not a string or is empty.');
    }

    // ✅ 이미지 확장자 검사 (HEIC 추가)
    const imagePattern = /\.(jpg|jpeg|png|gif|bmp|webp|svg|heic|heif)$/i;

    // ✅ 로컬 파일 여부 검사
    const isLocalFile =
      url.startsWith('blob:') || url.startsWith('data:image/');

    if (isLocalFile) return true; // ✅ 로컬 이미지 파일로 간주

    // ✅ 원격 URL 처리: Query 파라미터 제거 후 검사
    const path = url.split('?')[0];

    return imagePattern.test(path); // ✅ 확장자 패턴 검사
  } catch (error) {
    console.error('❌ Invalid Image URL:', url, error);
    return false;
  }
};

// 🔹 비디오 URL 확인 함수
export const isVideoURL = (url) => {
  try {
    if (typeof url !== 'string' || !url.trim()) {
      throw new Error('Invalid URL: URL is not a string or is empty.');
    }

    // ✅ 비디오 확장자 검사
    const videoPattern = /\.(mp4|webm|ogg|avi|mov|wmv|flv|mkv)$/i;
    const isLocalFile =
      url.startsWith('blob:') || url.startsWith('data:video/');

    if (isLocalFile) return true; // ✅ 로컬 비디오 파일로 간주

    // ✅ 원격 URL 처리
    const path = url.split('?')[0].toLowerCase();

    return videoPattern.test(path);
  } catch (error) {
    console.error('❌ Invalid Video URL:', url, error);
    return false;
  }
};

// 🔹 파일 확장자 추출 함수
export const getFileExtension = (url) => {
  return url?.split('.').pop().toLowerCase() || ''; // 🔹 URL에서 확장자 추출
};

// 🔹 Firebase Storage 이미지 업로드 함수
export const uploadImageToStorage = async (file) => {
  try {
    if (!file) throw new Error('No file provided for upload.');

    const storage = getStorage();
    const filename = `${Date.now()}_${file.name}`;
    const storageRef = ref(storage, `uploads/${filename}`);

    // ✅ 파일 업로드 실행
    await uploadBytes(storageRef, file);

    // ✅ 다운로드 URL 반환
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error('❌ Error uploading image:', error);
    throw error;
  }
};

// 🔹 여러 개의 이미지 업로드
export const uploadMultipleImages = async (files) => {
  const uploadPromises = files.map((file) => uploadImageToStorage(file));
  return Promise.all(uploadPromises);
};
