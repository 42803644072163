import React, { useState, useEffect } from 'react';
import {
  db,
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from '../../firebaseConfig';
import {
  Container,
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Breadcrumbs,
  Link,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { googleTranslate } from '../../Utils/GoogleTranslation';
import axios from 'axios';

function TermsList() {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [versions, setVersions] = useState([]);
  const [version, setVersion] = useState(null);
  const [terms, setTerms] = useState([]);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    const fetchVersions = async () => {
      const termsCollection = collection(db, 'terms');
      const termsSnapshot = await getDocs(termsCollection);
      const allVersions = termsSnapshot.docs.map((doc) => doc.data().version);
      const uniqueVersions = [...new Set(allVersions)].sort((a, b) => b - a);
      setVersions(uniqueVersions);
      if (uniqueVersions.length > 0) {
        setVersion(uniqueVersions[0]);
      }
    };
    fetchVersions();
  }, []);

  useEffect(() => {
    if (version != null) {
      fetchTerms(version);
    }
  }, [version]);

  // const fetchTerms = async (version) => {
  //   const termsCollection = collection(db, 'terms');
  //   const q = query(
  //     termsCollection,
  //     where('version', '==', version),
  //     orderBy('created_at')
  //   );
  //   const termsSnapshot = await getDocs(q);
  //   const termsData = termsSnapshot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //     items: doc
  //       .data()
  //       .items.map((item, index) => ({ ...item, id: `${doc.id}_${index}` })),
  //   }));
  //   setTerms(termsData);

  //   if (termsData.length > 0) {
  //     setSelectedTermId(termsData[0].id); // 첫 번째 용어 자동 선택
  //     if (termsData[0].items.length > 0) {
  //       setSelectedItemId(termsData[0].items[0].id); // 첫 번째 용어의 첫 번째 아이템 자동 선택
  //     }
  //   }
  // };
  // const fetchTerms = async (version) => {
  //   const termsCollection = collection(db, 'terms');
  //   const q = query(
  //     termsCollection,
  //     where('version', '==', version),
  //     orderBy('created_at')
  //   );
  //   const termsSnapshot = await getDocs(q);

  //   const termsData = await Promise.all(
  //     termsSnapshot.docs.map(async (doc) => {
  //       const translatedItems = await Promise.all(
  //         doc.data().items.map(async (item, index) => {
  //           const translatedTitle = await googleTranslate(item.title, i18n);
  //           const translatedContent = await googleTranslate(item.content, i18n);
  //           return {
  //             ...item,
  //             title: translatedTitle,
  //             content: translatedContent,
  //             id: `${doc.id}_${index}`,
  //           };
  //         })
  //       );

  //       const translatedTermName = await googleTranslate(doc.data().name, i18n);

  //       return {
  //         ...doc.data(),
  //         name: translatedTermName,
  //         id: doc.id,
  //         items: translatedItems,
  //       };
  //     })
  //   );

  //   setTerms(termsData);

  //   if (termsData.length > 0) {
  //     setSelectedTermId(termsData[0].id); // 첫 번째 용어 자동 선택
  //     if (termsData[0].items.length > 0) {
  //       setSelectedItemId(termsData[0].items[0].id); // 첫 번째 용어의 첫 번째 아이템 자동 선택
  //     }
  //   }
  // };

  const fetchTerms = async (version) => {
    try {
      const termsCollection = collection(db, 'terms');
      const q = query(
        termsCollection,
        where('version', '==', version),
        orderBy('created_at')
      );
      const termsSnapshot = await getDocs(q);

      const termsData = await Promise.all(
        termsSnapshot.docs.map(async (doc) => {
          const translatedItems = await Promise.all(
            doc.data().items.map(async (item, index) => {
              try {
                const translatedTitle = await googleTranslate(item.title, i18n);
                const translatedContent = await googleTranslate(
                  item.content,
                  i18n
                );
                return {
                  ...item,
                  title: translatedTitle,
                  content: translatedContent,
                  id: `${doc.id}_${index}`,
                };
              } catch (translateError) {
                console.error('Error translating item:', translateError);
                return {
                  ...item,
                  id: `${doc.id}_${index}`,
                  translationError: true, // 추가적인 오류 플래그
                };
              }
            })
          );

          try {
            const translatedTermName = await googleTranslate(
              doc.data().name,
              i18n
            );
            return {
              ...doc.data(),
              name: translatedTermName,
              id: doc.id,
              items: translatedItems,
            };
          } catch (translateError) {
            console.error('Error translating term name:', translateError);
            return {
              ...doc.data(),
              id: doc.id,
              items: translatedItems,
              translationError: true, // 추가적인 오류 플래그
            };
          }
        })
      );

      setTerms(termsData);

      if (termsData.length > 0) {
        setSelectedTermId(termsData[0].id); // 첫 번째 용어 자동 선택
        if (termsData[0].items.length > 0) {
          setSelectedItemId(termsData[0].items[0].id); // 첫 번째 용어의 첫 번째 아이템 자동 선택
        }
      }
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error('Firestore index error:', error.message);
        // 필요한 경우 사용자에게 알림을 표시하거나 추가 처리를 수행할 수 있습니다.
      } else {
        console.error('Error fetching terms:', error);
      }
    }
  };

  const handleVersionChange = (event) => {
    setVersion(event.target.value);
    setSelectedTermId(null);
    setSelectedItemId(null);
  };

  const handleItemClick = (itemId) => {
    setSelectedItemId(itemId);
  };

  const selectedTerm = terms.find((term) => term.id === selectedTermId);
  const selectedItem = selectedTerm?.items.find(
    (item) => item.id === selectedItemId
  );

  return (
    <Box
      p={3}
      sx={{
        width: '100%',
        margin: 'auto',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          // display: 'inline-block',
          //borderRadius: '12px',
          display: 'flex', // flexbox 사용
          justifyContent: 'center', // 가로 중앙 정렬
          flexDirection: 'column',
          // alignItems: 'center', // 세로 중앙 정렬 (필요한 경우)
          padding: '8px 16px',
          marginBottom: '16px',
          //backgroundColor: theme.palette.primary.light,
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        {/* 제목 영역 */}
        <Typography variant="h4" gutterBottom>
          {t('termslist')}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, mt: 2 }}>
            <Link
              color="inherit"
              href="/"
              onClick={(event) => event.preventDefault()}
            >
              {t('home')}
            </Link>
            <Link
              color="inherit"
              href="/support"
              onClick={(event) => event.preventDefault()}
            >
              {t('customer-service.title')}
            </Link>
            <Typography color="textPrimary">
              {t('terms-and-policies.title')}
            </Typography>
          </Breadcrumbs>
        </Box>

        {selectedTerm && (
          <Paper elevation={0} sx={{ mt: 1, p: 0 }}>
            <Typography variant="h6">{selectedTerm.title}</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              {selectedTerm.items.map((item) => (
                <Button
                  key={item.id}
                  onClick={() => handleItemClick(item.id)}
                  variant={
                    item.id === selectedItemId ? 'contained' : 'outlined'
                  }
                  sx={{
                    flexGrow: 1,
                    margin: '4px',
                    flexBasis: `calc(${
                      100 / selectedTerm.items.length
                    }% - 10px)`,
                  }}
                >
                  {item.title}
                </Button>
              ))}
            </Box>
          </Paper>
        )}

        {selectedItem && (
          <Paper
            elevation={2}
            sx={{
              mt: 2,
              p: 2,
              whiteSpace: 'pre-wrap',
              border: '1px solid #ccc',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ textDecoration: 'underline' }}
            >
              {selectedItem.title}
            </Typography>
            <Typography>{selectedItem.content}</Typography>
          </Paper>
        )}
        <FormControl fullWidth margin="normal">
          <InputLabel>Version</InputLabel>
          <Select
            value={version || ''}
            onChange={handleVersionChange}
            label="Version"
          >
            {versions.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Container>
    </Box>
  );
}

export default TermsList;
