import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig'; // Firestore 인스턴스
import { collection, onSnapshot } from 'firebase/firestore';
import i18n from 'i18next'; // 다국어 지원

export const useWeatherEvents = () => {
  const [weatherEvents, setWeatherEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const eventsCollection = collection(db, 'events'); // ✅ Firestore 컬렉션 참조

    // ✅ Firestore 실시간 업데이트 (onSnapshot 사용)
    const unsubscribe = onSnapshot(
      eventsCollection,
      (snapshot) => {
        const events = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // ✅ 현재 언어 설정 (i18n 사용)
        const currentLang = i18n.language || 'en';

        const localizedEvents = events.map((event) => ({
          ...event,
          name: event.name?.[currentLang] || event.name?.en || 'Unknown Event',
          description:
            event.description?.[currentLang] ||
            event.description?.en ||
            'No description available',
          priority: event.priority ?? Infinity,
        }));

        // ✅ priority 기준 오름차순 정렬
        const sortedEvents = localizedEvents.sort(
          (a, b) => a.priority - b.priority
        );
        setWeatherEvents(sortedEvents);
        setLoading(false);
      },
      (error) => {
        setWeatherEvents([]);
        console.error('❌ Firestore error fetching weather events:', error);
        setError(true);
        setLoading(false);
      }
    );

    return () => unsubscribe(); // ✅ Firestore 리스너 정리
  }, []);

  return {
    weatherEvents: Array.isArray(weatherEvents) ? weatherEvents : [],
    loading,
    error,
  };
};
